import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Col,
  Button,
} from 'reactstrap';
import Switch from '@mui/material/Switch';
import DatePicker from '../../../components/Common/DatePicker';
import {
  capitalizeFirstLetter,
  getGroupName,
  getToken,
  GKApi,
} from '../../../components/config';
// import { withRouter } from 'react-router-dom';
import BuyReportMKT from './buyReport';
import SellReportMKT from './sellReport';
import CollectReportMKT from './collectReport';
import ActiveUserReportMKT from './activeUserReport';
import InactiveUserReportMKT from './inactiveUserReport';
import NewUserReportMKT from './newUserReport';

const MarketingReport = (props) => {
  const [reportMenu, setReportMenu] = useState(false);
  const [report, setReport] = useState('Select');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [dailySummaryChecked, setDailySummaryChecked] = useState(false);
  const [pageSizeMenu, setPageSizeMenu] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [isLoading, setIsLoading] = useState(false);
  const [mktData, setMktData] = useState([]);
  const [totalPage, setTotalPage] = useState(100);
  const [isMkt, setIsMkt] = useState(false);
  const [isSuper, setIsSuper] = useState(false);
  

  const handleDailySummaryCheckedChange = (event) => {
    setDailySummaryChecked(event.target.checked);
  };

  const handleSelectedStartDateChange = (event) => {
    setSelectedStartDate(event);
  };

  const handleSelectedEndDateChange = (event) => {
    setSelectedEndDate(event);
  };

  //On Click function
  const onClick = async (event) => {
    event.preventDefault();
    const token = getToken();
    setIsLoading(true);
    //get api call with needed parameters
    let submitData = await GKApi.get(
      `/marketing/report?reportType=${report}&isSummary=${dailySummaryChecked}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&pageSize=${pageSize}&pageNumber=1`,
      {
        headers: {
          Authorization: token,
          cmpId: 10,
        },
      }
    );

    if (submitData.data) {
      setIsLoading(false);
      setTotalPage(submitData.data.paginationInfo.totalPage);
      setSelectedStartDate(submitData.data.startDate);
      setSelectedEndDate(submitData.data.endDate);
      setMktData(submitData.data.data);
    }

    /*report based on the report state parameters,
  and pushing the route to the specific report */

    
  };
  const onClickSummary = async (event) => {
    event.preventDefault();
    const token = getToken();
    setIsLoading(true);
    //get api call with needed parameters
    if (report === 'active' || report === 'inactive' || report === 'new') {
      let submitData = await GKApi.get(
        `/marketing/report?reportType=${report}&isSummary=true&pageSize=${pageSize}&pageNumber=1`,
        {
          headers: {
            Authorization: token,
            cmpId: 10,
          },
        }
      );
      if (submitData) {
        setIsLoading(false);
        setTotalPage(submitData.data.paginationInfo.totalPage);
        setMktData(submitData.data.data);

        
      }
      
    } else {
      let submitData = await GKApi.get(
        `/marketing/report?reportType=${report}&isSummary=${dailySummaryChecked}&pageSize=${pageSize}&pageNumber=1`,
        {
          headers: {
            Authorization: token,
            cmpId: 10,
          },
        }
      );
      if (submitData.data) {
        setIsLoading(false);
        setTotalPage(submitData.data.paginationInfo.totalPage);
        setMktData(submitData.data.data);
      }
      
    }

  };
  useEffect(()=>{
		let groupName = getGroupName();
  if (groupName.length > 0) {
    groupName = groupName.split(',');
  }
  let gN = new Set();
  groupName.forEach((i) => {
    let name = i.split('_')[0];
    gN.add(name);
    if (name === 'MKT') {
      setIsMkt(true);
    } else if (name === 'SUPER') {
      setIsSuper(true);
    }
  });
	}, [])

  
  return (
    <div className="page-content">
        {isSuper === true || isMkt === true ? (
      <div className="container-fluid">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Marketing Reports</h4>
              <div className="d-grid gap-3">
                <div>
                  <div className="d-flex align-items-center">
                    <Col xs={6}>
                      <h5>Select Report Type</h5>
                    </Col>
                    <Col xs={6}>
                      <Dropdown
                        isOpen={reportMenu}
                        toggle={(event) => setReportMenu(!reportMenu)}
                      >
                        <DropdownToggle className="px-5 py-2" color="primary">
                          {(report === 'active' ||
                            report === 'inactive' ||
                            report === 'new') &&
                            `${capitalizeFirstLetter(report)} User Report`}
                          {(report === 'buy' ||
                            report === 'sell' ||
                            report === 'collect' ||
                            report === 'Select') &&
                            `${capitalizeFirstLetter(report)} Report`}

                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={(event) => {
                              setReport('active');
                            }}
                          >
                            Active User Report
                          </DropdownItem>

                          <DropdownItem
                            onClick={(event) => setReport('inactive')}
                          >
                            Inactive User Report
                          </DropdownItem>

                          <DropdownItem onClick={(event) => setReport('new')}>
                            New User Report
                          </DropdownItem>
                          <DropdownItem onClick={(event) => setReport('buy')}>
                            Buy Report
                          </DropdownItem>
                          <DropdownItem onClick={(event) => setReport('sell')}>
                            Sell Report
                          </DropdownItem>

                          <DropdownItem
                            onClick={(event) => setReport('collect')}
                          >
                            Collect Report
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Col xs={6}>
                    <h5>Choose page size</h5>
                  </Col>
                  <Col xs={6} className="d-flex  gap-3">
                    <Dropdown
                      isOpen={pageSizeMenu}
                      toggle={(event) => setPageSizeMenu(!pageSizeMenu)}
                    >
                      <DropdownToggle className="px-5 py-2" color="primary">
                        {pageSize}

                        <i className="mdi mdi-chevron-down"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(event) => {
                            setPageSize(50);
                          }}
                        >
                          50
                        </DropdownItem>

                        <DropdownItem onClick={(event) => setPageSize(100)}>
                          100
                        </DropdownItem>

                        <DropdownItem onClick={(event) => setPageSize(500)}>
                          500
                        </DropdownItem>
                        <DropdownItem onClick={(event) => setPageSize(1000)}>
                          1000
                        </DropdownItem>
                        <DropdownItem onClick={(event) => setPageSize(5000)}>
                          5000
                        </DropdownItem>

                        <DropdownItem onClick={(event) => setPageSize(10000)}>
                          10000
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </div>
                {report === 'active' ||
                report === 'inactive' ||
                report === 'new' ? (
                  <div></div>
                ) : (
                  <div className="d-flex align-items-center">
                    <Col xs={6}>
                      <h5>Summary</h5>
                    </Col>
                    <Col xs={6} className="d-flex  gap-3">
                      <Switch
                        checked={dailySummaryChecked}
                        onChange={handleDailySummaryCheckedChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Col>
                  </div>
                )}
                {dailySummaryChecked === false &&
                  (report === 'active' ||
                  report === 'inactive' ||
                  report === 'new' ? (
                    <div></div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <Col xs={6}>
                        {report ? (
                          <h5>
                            Select the Date range for{' '}
                            {(report === 'active' ||
                              report === 'inactive' ||
                              report === 'new') &&
                              `${capitalizeFirstLetter(report)} User Report`}
                            {(report === 'buy' ||
                              report === 'sell' ||
                              report === 'collect' ||
                              report === 'Select') &&
                              `${capitalizeFirstLetter(report)} Report`}
                          </h5>
                        ) : (
                          ''
                        )}
                      </Col>
                      <Col xs={6} className="d-flex gap-3">
                        <div
                          style={{
                            border: '1px solid',
                            borderRadius: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '4px',
                            paddingLeft: '4px',
                          }}
                          className="d-flex gap-3 align-content-center"
                        >
                          <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                            Start Date
                          </p>
                          <DatePicker
                            selectedDate={selectedStartDate}
                            handleSelectedDateChange={
                              handleSelectedStartDateChange
                            }
                          />
                        </div>
                        <div
                          style={{
                            border: '1px solid',
                            borderRadius: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '5px',
                            paddingLeft: '5px',
                          }}
                          className="d-flex d-grid gap-3"
                        >
                          <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                            End Date
                          </p>
                          <DatePicker
                            selectedDate={selectedEndDate}
                            handleSelectedDateChange={
                              handleSelectedEndDateChange
                            }
                            className="mx-3"
                          />
                        </div>
                      </Col>
                    </div>
                  ))}
                {/* {dailySummaryChecked === true && report !== 'Select' ? (
                <div className="d-flex">
                  <Col xs={6}>
                    <h5>Phone numbers</h5>
                  </Col>
                  <Col xs={6}>
                    <Input
                      type="text"
                      className="form-control"
                      value={phoneNumber}
                      id="basicpill-firstname-input1"
                      onChange={(e) => {
                        handlePhoneNumberChange(e);
                      }}
                    />
                    <p className="py-2">
                      ***Please input multiple numbers without spaces and
                      include comma after each number
                    </p>
                  </Col>
                </div>
              ) : (
                ''
              )} */}

                {report!=='Select'?<div>
                  {report === 'active' ||
                  report === 'inactive' ||
                  report === 'new' ? (
                    <div className="d-flex justify-content-center">
                      <Button
                        onClick={onClickSummary}
                        style={{
                          width: '50%',
                        }}
                      >
                        {isLoading && (
                          <span className="spinner-grow spinner-grow-sm"></span>
                        )}
                        {isLoading
                          ? 'Generating Report, Please Wait!!'
                          : 'View Report'}
                      </Button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      {dailySummaryChecked === false &&
                      selectedStartDate &&
                      selectedEndDate ? (
                        <Button
                          onClick={onClick}
                          style={{
                            width: '50%',
                          }}
                        >
                          {isLoading && (
                            <span className="spinner-grow spinner-grow-sm"></span>
                          )}
                          {isLoading
                            ? 'Generating Report, Please Wait!!'
                            : 'View Report'}
                        </Button>
                      ) : dailySummaryChecked === true ? (
                        <Button
                          onClick={onClickSummary}
                          style={{
                            width: '50%',
                          }}
                        >
                          {isLoading && (
                            <span className="spinner-grow spinner-grow-sm"></span>
                          )}
                          {isLoading
                            ? 'Generating Report, Please Wait!!'
                            : 'View Report'}
                        </Button>
                      ) : (
                        'Please select the parameters for report generation'
                      )}
                    </div>
                  )}
                </div>:<h6 className='d-flex justify-content-center py-2'>Please select report type</h6>}
              </div>
            </CardBody>
          </Card>
        
        {report === 'buy' && mktData.length > 0 && (
          <BuyReportMKT
            report={report}
            data={mktData}
            summary={dailySummaryChecked}
            pageSize={pageSize}
            totalPage={totalPage}
            startDate={selectedStartDate}
            endDate={selectedEndDate}
          />
        )}
        {report === 'sell' && mktData.length > 0 && (
          <SellReportMKT
            report={report}
            data={mktData}
            summary={dailySummaryChecked}
            pageSize={pageSize}
            totalPage={totalPage}
            startDate={selectedStartDate}
            endDate={selectedEndDate}
          />
        )}
        {report === 'collect' && mktData.length > 0 && (
          <CollectReportMKT
            report={report}
            data={mktData}
            summary={dailySummaryChecked}
            pageSize={pageSize}
            totalPage={totalPage}
            startDate={selectedStartDate}
            endDate={selectedEndDate}
          />
        )}
        {report === 'active' && mktData.length > 0 && (
          <ActiveUserReportMKT
            report={report}
            data={mktData}
            summary={true}
            pageSize={pageSize}
            totalPage={totalPage}
          />
        )}
        {report === 'inactive' && mktData.length > 0 && (
          <InactiveUserReportMKT
            report={report}
            data={mktData}
            summary={true}
            pageSize={pageSize}
            totalPage={totalPage}
          />
        )}
        {report === 'new' && mktData.length > 0 && (
          <NewUserReportMKT
            report={report}
            data={mktData}
            summary={true}
            pageSize={pageSize}
            totalPage={totalPage}
          />
        )}
      </div>
       ) : (
        <h5>Sorry you don't have permission to view this</h5>
      )} 
    </div>
  );
};

export default MarketingReport;

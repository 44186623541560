import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import {
  Col,
  Button,
  Card,
  CardBody,
} from 'reactstrap';
import { getToken, GKApi } from '../../../components/config';
import DatePicker from '../../../components/Common/DatePicker';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table';
class AllUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ledgerData: {},
      searchLoading: false,
      selectedStartDate: null,
      selectedEndDate: null,
    };
  }
  handleSelectedStartDateChange = (event) => {
    this.setState({ selectedStartDate: event });
  };

  handleSelectedEndDateChange = (event) => {
    this.setState({ selectedEndDate: event });
  };

  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();
    };
    const columns = [
      {
        field: 'customerName',
        title: 'Customer Name',
      },
      {
        field: 'customerPhone',
        title: 'Customer Phone',
      },
      {
        field: 'transactionDate',
        title: 'Transaction Date',
        render: (row) => (
          <div>
            {row.transactionDate
              ? moment(row.transactionDate, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'marketPrice',
        title: 'Market Price',
      },
      {
        field: 'goldQuantity',
        title: 'Gold Quantity',
        render: (row) => <div>{row.goldQuantity.toFixedDown(4)}</div>,
      },
      {
        field: 'amountInBDT',
        title: 'Gold of Value (in BDT)',
        render: (row) => <div>{row.amountInBDT.toFixedDown(4)}</div>,
      },
      {
        field: 'transactionType',
        title: 'Transaction Type',
      },

      {
        field: 'payChannel',
        title: 'Payment Channel',
        render: (row) => (
          <div>{row.payChannel==='bKash' ? 'bKash' : row.payChannel==='Card' ? 'Card': row.payChannel==='Bank' ? 'Bank': row.payChannel==='Nagad' ? 'Nagad': row.payChannel===0 ? 'Not Available':'Not Applicable'}</div>
        ),
      },
      {
        title: 'MFS/Bank Account',
        render: (row) => {
          return <div>{['Buy', 'Sell', 'Collect'].includes(row.transactionType) ? (row.payerNo || row.payeeNo || 'Not Available') : 'Not Applicable'}</div>
        }
      },
      {
        field: 'transactionStatus',
        title: 'Transaction Status',
      },
      {
        field: 'description',
        title: 'Description',
        render: (row) => (
          <div>{row.description ? row.description : 'Not Available'}</div>
        ),
      },
    ];

    const downloadExcel = () => {
      const newData = this.state.ledgerData.ledger.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Ledger');
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(
        workBook,
        `Ledger Report ${
          this.state.selectedStartDate
            ? `from ${moment(
                this.state.selectedStartDate,
                'YYYY-MM-DD HH:mm:ss'
              ).format('DD-MM-YYYY')}`
            : ''
        } ${
          this.state.selectedEndDate
            ? `to ${moment(this.state.selectedEndDate, 'YYYY-MM-DD HH:mm:ss').format(
                'DD-MM-YYYY'
              )}`
            : ''
        }.xlsx`
      );
    };
    return (
      <Col xl="12">
        <div className="d-flex gap-3 align-items-center mx-3 my-3">
          <h5>Select date range for ledger:</h5>
          <Col xs={8} className="d-flex gap-3">
            <div
              style={{
                border: '1px solid',
                borderRadius: '5px',
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingRight: '4px',
                paddingLeft: '4px',
              }}
              className="d-flex gap-3 align-content-center"
            >
              <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                Start Date
              </p>
              <DatePicker
                selectedDate={this.state.selectedStartDate}
                handleSelectedDateChange={this.handleSelectedStartDateChange}
              />
            </div>
            <div
              style={{
                border: '1px solid',
                borderRadius: '5px',
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingRight: '5px',
                paddingLeft: '5px',
              }}
              className="d-flex d-grid gap-3"
            >
              <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                End Date
              </p>
              <DatePicker
                selectedDate={this.state.selectedEndDate}
                handleSelectedDateChange={this.handleSelectedEndDateChange}
                className="mx-3"
              />
            </div>
          </Col>
        </div>

        {this.state.selectedStartDate && this.state.selectedEndDate ? (
          <div className="d-flex align-items-center gap-3 justify-content-center">
            <Button
              style={{
                color: 'white',
                backgroundColor: '#4E6360',
              }}
              onClick={async () => {
                this.setState({ searchLoading: true });
                const token = getToken();

                const response = await GKApi.get(
                  `/finance/ledger/all-user?startDate=${this.state.selectedStartDate}&endDate=${this.state.selectedEndDate}`,
                  {
                    headers: {
                      Authorization: token,
                      cmpId: 10,
                    },
                  }
                );
                this.setState(
                  {
                    ledgerData: response.data,
                  },
                  () => {}
                );
                if (response) {
                  this.setState({ searchLoading: false });
                }
              }}
            >
              {this.state.searchLoading
                ? 'Generating Ledger Report'
                : 'Generate Ledger Report'}
            </Button>
            <Button
              style={{
                color: 'white',
                backgroundColor: '#4E6360',
              }}
              onClick={async () => {
                this.setState({
                  ledgerData: {},
                  selectedEndDate: null,
                  selectedStartDate: null,
                });
              }}
            >
              {'Clear Ledger Report'}
            </Button>
          </div>
        ) : (
          ''
        )}

        {Object.keys(this.state.ledgerData).length > 0 && (
          <div className="py-2">
            <p className='py-2'>* Please keep in mind, once you download the report, the parameters will reset.</p>
            <MaterialTable
              title={`Ledger Report from ${moment(
                this.state.selectedStartDate,
                'YYYY-MM-DD HH:mm:ss'
              ).format('DD-MM-YYYY')} to ${moment(
                this.state.selectedEndDate,
                'YYYY-MM-DD HH:mm:ss'
              ).format('DD-MM-YYYY')}`}
              columns={columns}
              data={this.state.ledgerData.ledger}
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />

                    <h4 className="d-flex justify-content-center py-2">
                      {' '}
                      Summary
                    </h4>

                    <div className="d-flex justify-content-center">
                      <div className="d-flex gap-3">
                        <Card>
                          <CardBody>
                            <div className="d-flex gap-3 py-2">
                              <h5>Buy:</h5>
                              <h5>
                                {this.state.ledgerData.summary.buy.toFixedDown(
                                  4
                                )}{' '}
                                GM
                              </h5>
                            </div>
                            <div className="d-flex gap-3">
                              <h5>Sell:</h5>
                              <h5>
                                {this.state.ledgerData.summary.sell.toFixedDown(
                                  4
                                )}{' '}
                                GM
                              </h5>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <div className="d-flex gap-3 py-2">
                              <h5>Collect:</h5>
                              <h5>
                                {this.state.ledgerData.summary.collect.toFixedDown(
                                  4
                                )}{' '}
                                GM
                              </h5>
                            </div>
                            <div className="d-flex gap-3">
                              <h5>Refer:</h5>
                              <h5>
                                {this.state.ledgerData.summary.refer.toFixedDown(
                                  4
                                )}{' '}
                                GM
                              </h5>
                            </div>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <div className="d-flex gap-3 py-2">
                              <h5>Gift Sent:</h5>
                              <h5>
                                {this.state.ledgerData.summary.giftSend.toFixedDown(
                                  4
                                )}{' '}
                                GM
                              </h5>
                            </div>
                            <div className="d-flex gap-3">
                              <h5>Gift Received:</h5>
                              <h5>
                                {this.state.ledgerData.summary.giftReceive.toFixedDown(
                                  4
                                )}{' '}
                                GM
                              </h5>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <div className="d-flex gap-3 py-2">
                              <h5>On Hold:</h5>
                              <h5>
                                {this.state.ledgerData.summary.onHold.toFixedDown(
                                  4
                                )}{' '}
                                GM
                              </h5>
                            </div>
                            <div className="d-flex gap-3">
                              <h5>Dispute:</h5>
                              <h5>
                                {this.state.ledgerData.summary.dispute.toFixedDown(
                                  4
                                )}{' '}
                                GM
                              </h5>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </div>
                ),
              }}
              actions={[
                {
                  icon: () => <Button>Export All</Button>, // you can pass icon too
                  tooltip: 'Export to Excel',
                  onClick: () => {downloadExcel();  this.setState({
                    
                    ledgerData: {},
                    selectedEndDate: null,
                    selectedStartDate: null,
                    
                  });},
                  isFreeAction: true,
                },
              ]}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                pageSize: 20,
                columnsButton: true,
              }}
              localization={{
                toolbar: {
                  exportCSVName: 'Export as CSV',
                },
              }}
            />
          </div>
        )}
      </Col>
    );
  }
}

export default AllUser;

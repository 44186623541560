import React, { Component } from 'react';
import MaterialTable from 'material-table';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Delete } from '@material-ui/icons';
import { getGroupName, getToken, GKApi } from '../../../components/config';
import moment from 'moment';
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

class GoldHoldingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '#' },
        { title: 'Gold Holding Report', link: '#' },
      ],
      goldHoldData: [],
      canViewHoldingReport: 0,
      pageNumber: 1,
      delete_modal: false,
      totalPageCount: 0,
      isLoading: false,
      id: '',
      timeDiff: 0,
      canRemoveRecord: 0,
      customerName:''
    };
  }

  //change handlers
  deleteRecordModalToggle = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
    this.removeBodyCss();
  };

  removeBodyCss() {
    document.body.classList.add('no_padding');
  }
  // get call for refer disbursement list
  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    // GET request using axios with async/await

    const token = getToken();
    let response = await GKApi.get(
      `/finance/gold-holding-report?pageSize=10&pageNumber=1`,
      {
        headers: {
          Authorization: token,
          cmpId: 10,
        },
      }
    );
    this.setState({
      goldHoldData: response.data.data,
      pageNumber: response.data.paginationInfo.pageNumber,
      totalPageCount: response.data.paginationInfo.totalPage,
    });

    // getting permission data from api
    const getPermission = await GKApi.get(`/role/permission?cmpId=10`, {
      headers: {
        Authorization: token,
      },
    });

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    this.setState({
      canViewHoldingReport: permissionData[10].canView,
      canRemoveRecord: permissionData[10].canAuth,
    });
  }

  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();
    };
    const columns = [
      {
        field: 'customerName',
        title: 'Customer Name',
      },
      {
        field: 'customerPhone',
        title: 'Customer Phone',
      },
      {
        field: 'dateTime',
        title: 'Date',
        render: (row) => (
          <div>
            {row.dateTime
              ? moment(row.dateTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'goldQunatityGM',
        title: 'Gold Quantity (in GM)',
        render: (row) => <div>{row.goldQunatityGM.toFixedDown(4)}</div>,
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content" id="10">
          {this.state.isFin === true || this.state.isSuper ? (
            <div>
              {this.state.canViewHoldingReport === 1 ? (
                <div className="container-fluid">
                  <Breadcrumbs
                    title="Gold Holding Report"
                    breadcrumbItems={this.state.breadcrumbItems}
                  />
                  <MaterialTable
                    title="Gold Holding Report"
                    columns={columns}
                    data={this.state.goldHoldData}
                    actions={[
                      (rowData) =>
                        this.state.canRemoveRecord === 1 && {
                          icon: Delete,
                          tooltip: 'Delete Record',
                          onClick: (event) => {
                            let timeDiff =
                              new Date().getTime() -
                              new Date(rowData.dateTime).getTime();
                            let timeDifference = timeDiff / 1000;

                            this.setState({
                              delete_modal: true,
                              id: rowData.buyID,
                              customerName:rowData.customerName,
                              timeDiff: timeDifference,
                            });
                          },
                        },
                    ]}
                    options={{
                      cellStyle: {
                        borderRight: '1px solid #eee',
                        whiteSpace: 'nowrap',
                        textAlign: 'left',
                      },

                      //style for the header
                      search: false,
                      paging: false,
                      headerStyle: {
                        whiteSpace: 'nowrap',

                        textAlign: 'left',
                        top: 0,
                      },

                      showSelectAllCheckbox: false,
                      actionsColumnIndex: -1,
                      //   exportButton: { csv: true, pdf: true },
                      //   exportFileName: 'Holding Report',
                      pageSize: 10,
                      columnsButton: true,
                    }}
                    localization={{
                      toolbar: {
                        exportCSVName: 'Export as CSV',
                        exportPDFName: 'Export as PDF',
                      },
                    }}
                  />
                  <Modal
                    isOpen={this.state.delete_modal}
                    toggle={this.deleteGroupModalToggle}
                  >
                    <ModalHeader
                      toggle={() => {
                        this.setState({
                          delete_modal: false,
                        });
                      }}
                    >
                      Confirmation
                    </ModalHeader>

                    <ModalBody>
                      <Col xl={12}>
                        {this.state.timeDiff > 3600 ? (
                          <Row className="mb-3 mx-2">
                            Are you sure you want to Delete{' '}
                            {this.state.customerName}'s gold holding record ?
                          </Row>
                        ) : (
                          <Row className="mb-3 mx-2">
                            Sorry this record was created less than an hour ago.
                            The record needs to be at least an hour old to be
                            deleted.
                          </Row>
                        )}
                      </Col>
                    </ModalBody>

                    {this.state.timeDiff > 3600 && (
                      <ModalFooter>
                        <Button
                          type="button"
                          color="primary"
                          className="waves-effect waves-light"
                          onClick={async (event) => {
                            event.preventDefault();
                            const token = getToken();
                            let sendData = {
                              buyID: this.state.id,
                            };
                            await GKApi.put(
                              `/finance/gold-hold/remove`,
                              sendData,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 10,
                                },
                              }
                            );
                            let response = await GKApi.get(
                              `/finance/gold-holding-report?pageSize=10&pageNumber=1`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 10,
                                },
                              }
                            );
                            this.setState({
                              goldHoldData: response.data.data,
                              pageNumber:
                                response.data.paginationInfo.pageNumber,
                              totalPageCount:
                                response.data.paginationInfo.totalPage,
                            });
                            this.deleteRecordModalToggle();
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          type="button"
                          onClick={this.deleteRecordModalToggle}
                          color="light"
                          className="waves-effect"
                        >
                          No
                        </Button>
                      </ModalFooter>
                    )}
                  </Modal>
                  {this.state.isLoading ? (
                    <h5
                      className="d-flex justify-content-center my-3
gap-5"
                    >
                      <CircularProgress color="inherit" />
                    </h5>
                  ) : (
                    <div>
                      <div className="my-3 d-flex justify-content-center gap-5">
                        <Button
                          onClick={async () => {
                            let token = getToken();
                            //get api call with needed parameters
                            this.setState({ isLoading: true });
  
                            let submitData = await GKApi.get(
                              `/finance/gold-holding-report?pageSize=10&pageNumber=${
                                this.state.pageNumber - 1
                              }`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 10,
                                },
                              }
                            );
                            this.setState(
                              {
                                goldHoldData: submitData.data.data,
                                pageNumber:
                                  submitData.data.paginationInfo.pageNumber,
                              },
                              () => {}
                            );
                            if (submitData) {
                              this.setState({
                                isLoading: false,
                              });
                            }
                          }}
                          disabled={this.state.pageNumber === 1 ? true : false}
                        >
                          Previous page
                        </Button>{' '}
                        <Button
                          onClick={async () => {
                            let token = getToken();
                            this.setState({ isLoading: true });
                            //get api call with needed parameters
  
                            let submitData = await GKApi.get(
                              `/finance/gold-holding-report?pageNumber=${
                                this.state.pageNumber + 1
                              }&pageSize=10`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 10,
                                },
                              }
                            );
  
                            this.setState(
                              {
                                goldHoldData: submitData.data.data,
                                pageNumber:
                                  submitData.data.paginationInfo.pageNumber,
                                totalPageCount:
                                  submitData.data.paginationInfo.totalPage,
                              },
                              () => {}
                            );
                            if (submitData) {
                              this.setState({
                                isLoading: false,
                              });
                            }
                          }}
                          disabled={
                            this.state.pageNumber === this.state.totalPageCount ||
                            this.state.totalPageCount === 0
                          }
                        >
                          {this.state.isLoading
                            ? 'Loading Next Page'
                            : 'Next Page'}
                        </Button>
                      </div>
                      <div className="my-2 d-flex justify-content-center">
                <h5>
                  Page {this.state.pageNumber} of {this.state.totalPageCount}
                </h5>
              </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <h5>Sorry you don't have permission to view this</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default GoldHoldingReport;

import React, { Component } from 'react';

import {
  Row,
  Col,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Card,
  CardBody,
} from 'reactstrap';

import classnames from 'classnames';

import { getGroupName } from '../../../components/config';
import SingleUserSelection from './singleUserSelection';
import AllUser from './allUser';

class FinanceLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      activeTabJustify: '1',
      isSuper:false,
      isFin:false,
    };
  }

  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };
  async componentDidMount(){
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });let groupNames = [...gN];

    this.setState({ groupName: groupNames });
  }

  render() {
    return (
      
      <div className="page-content">
        
              {this.state.isFin===true||this.state.isSuper===true?
          
              
                  <Row>
                    <Col xl="12">
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {/*Single User Tab  */}
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: this.state.activeTabJustify === '1',
                            })}
                            onClick={async () => {
                              this.toggleCustomJustified('1');
                            }}
                          >
                            <span className="d-none d-sm-block fw-bold">
                              Single User
                            </span>
                          </NavLink>
                        </NavItem>
        
                        
        
                        {/*All User Tab  */}
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: this.state.activeTabJustify === '2',
                            })}
                            onClick={async () => {
                              this.toggleCustomJustified('2');
                            }}
                          >
                            <span className="d-none d-sm-block fw-bold">All User</span>
                          </NavLink>
                        </NavItem>
        
                        
                      </Nav>
                      <Card>
                <CardBody>
                      <TabContent activeTab={this.state.activeTabJustify}>
                        <TabPane tabId="1" >
                        <SingleUserSelection/>
                        </TabPane>
                        <TabPane tabId="2" >
                          <AllUser/>
                        </TabPane>
                        
                      </TabContent>
                      </CardBody>
              </Card>
                    </Col>
                  </Row>
                
            
              :
              <h5>Sorry you don't have permission to view this</h5>
              }
          
      </div >
      
    );
  }
}

export default FinanceLedger;

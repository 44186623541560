import React, { Component } from 'react';
import { Container, Row, Col} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

//Import Components
import MiniWidgets from './MiniWidgets';
import EarningReports from './EarningReports';
// import Sources from './Sources';
// import RecentlyActivity from './RecentlyActivity';
// import RevenueByLocations from './RevenueByLocations';
// import ChatBox from './ChatBox';
// import LatestTransactions from './LatestTransactions';

import GoldPriceTrend from './GoldPriceTrend';
import FinanceReport from '../Finance-Reports';
import GoldReserveStatus from './GoldReservetStatus';
import DailyBuySell from './DaillyBuySell';
import Top5Buy from './Top5Buy';
import Top5Sell from './Top5Sell';
import Top5Collect from './Top5Collect';
import DailySFRC from './DailySFRC';
import { getGroupName, getToken, GKApi } from '../../../components/config';

class FinanceDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '/' },
        { title: 'Finance-Dashboard', link: '#' },
      ],
      highlightsViewAccess: 1,
      dailyBuySellViewAccess: 1,
      sfrcViewAccess: 1,
      goldPriceTrendViewAccess: 1,
      goldReserveStatusViewAccess: 1,
      top5BuyViewAccess: 1,
      top5SellViewAccess: 1,
      top5CollectViewAccess: 1,
      bankingVSMFSViewAccess: 1,
      reportViewAccess: 1,
      isFin: false,
      isSuper: false,
    };
  }
  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    const token = getToken();
    // const groupId = getGroupId();
    // getting permission data from api
    const getPermission = await GKApi.get(
      `/role/permission?cmpId=1,2,3,4,5,6,7,8,9,10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    this.setState({
      highlightsViewAccess: permissionData[1].canView,
      dailyBuySellViewAccess: permissionData[2].canView,
      sfrcViewAccess: permissionData[3].canView,
      goldPriceTrendViewAccess: permissionData[4].canView,
      goldReserveStatusViewAccess: permissionData[5].canView,
      top5BuyViewAccess: permissionData[6].canView,
      top5SellViewAccess: permissionData[7].canView,
      top5CollectViewAccess: permissionData[8].canView,
      bankingVSMFSViewAccess: permissionData[9].canView,
      reportViewAccess: permissionData[10].canView,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isSuper === true || this.state.isFin === true ? (
            <Container fluid>
              <Breadcrumbs
                title="Finance Dashboard"
                breadcrumbItems={this.state.breadcrumbItems}
              />
              <Row>
                <Col xl={8}>
                  
                  <Row>
                    <div id="1">
                      {this.state.highlightsViewAccess === 1 ? (
                        <MiniWidgets />
                      ) : (
                        ''
                      )}
                    </div>
                  </Row>

                  {/* revenue Analytics */}
                  <div id="2">
                    {this.state.dailyBuySellViewAccess === 1 ? (
                      <DailyBuySell />
                    ) : (
                      ''
                    )}
                  </div>
                  <div id="3">
                    {this.state.sfrcViewAccess === 1 ? <DailySFRC /> : ''}
                  </div>
                  <div id="4">
                    {this.state.goldPriceTrendViewAccess === 1 ? (
                      <GoldPriceTrend />
                    ) : (
                      ''
                    )}
                  </div>
                </Col>

                <Col xl={4}>
                  {/* Gold Reserve Status */}
                  <div id="5">
                    {this.state.goldPriceTrendViewAccess === 1 ? (
                      <GoldReserveStatus />
                    ) : (
                      ''
                    )}
                  </div>

                  {/* Top 5 activity */}
                  <div id="6">
                    {this.state.top5BuyViewAccess === 1 ? <Top5Buy /> : ''}
                  </div>
                  <div id="7">
                    {this.state.top5SellViewAccess === 1 ? <Top5Sell /> : ''}
                  </div>
                  <div id="8">
                    {this.state.top5CollectViewAccess === 1 ? (
                      <Top5Collect />
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
                <Col xl={12}>
                  {/* earning reports */}
                  <div id="9">
                    {this.state.bankingVSMFSViewAccess === 1 ? (
                      <EarningReports />
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  {' '}
                  {/* Finance report */}
                  <div id="10">
                    {this.state.reportViewAccess === 1 ? <FinanceReport /> : ''}
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <h5>Sorry you don't have permission to view this</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default FinanceDashboard;

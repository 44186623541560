import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import {
  getGroupName,
  getToken,
  getUser,
  GKApi,
} from '../../../components/config';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { deviceInfo } from '../../../helpers/device_info_helper';

class BkashDisbursementSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '#' },
        { title: 'Bkash Disbursement Settings', link: '#' },
      ],
      softDisbursementChange: false,
      softDisbursementDefault: 0,
      softDisbursement: 0,
      hardDisbursementChange: false,
      hardDisbursementDefault: 0,
      hardDisbursement: 0,
      availableBalance: 0,
      disbursementInfo: {},
      success: '',
      custom_div1: false,
      modal_standard: false,
      modal_standard4: false,
      modal_standard5: false,
      deviceInfo: {},
      canViewBkashSettings: 0,
      isFin: false,
      isSuper: false,
    };
  }
  // modal toggles
  removeBodyCss = () => {
    document.body.classList.add('no_padding');
  };

  tog_standard4 = () => {
    this.setState((prevState) => ({
      modal_standard4: !prevState.modal_standard4,
    }));
    this.removeBodyCss();
  };

  // api calls on page load

  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });


    
    // GET request using axios with async/await
    const token = getToken();

    const getPermission = await GKApi.get(`/role/permission?cmpId=172`, {
      headers: {
        Authorization: token,
      },
    });
    // getting permission data from api

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    // setting permissions to their states of their own

    this.setState({
      canViewBkashSettings: permissionData[172].canView,
    });
    //get request for input data
    const response = await GKApi.get('/finance/operation-input/disbursement', {
      headers: {
        Authorization: token,
        cmpId: '172',
      },
    });


    this.setState({
      disbursementInfo: response.data.getDisInputsInfo,
    });
    //setting input data to wait

    this.setState({
      softDisbursementDefault: this.state.disbursementInfo.bkashSoftLimit,
      softDisbursement: this.state.disbursementInfo.bkashSoftLimit,
      hardDisbursementDefault: this.state.disbursementInfo.bkashHardLimit,
      hardDisbursement: this.state.disbursementInfo.bkashHardLimit,
      availableBalance: this.state.disbursementInfo.bkashAvailableBalance,
    });

   
    //function status api call

    // calling geo location api call for the device info
    const res = deviceInfo();
    this.setState({ deviceInfo: res });
  }

  //  change handlers

  handleSoftDisbursementLimitChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        softDisbursement: parseFloat(event.target.value),
        softDisbursementChange: true,
      });
    }
  };

  handleHardDisbursementLimitChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        hardDisbursement: parseFloat(event.target.value),
        hardDisbursementChange: true,
      });
    }
  };

  //change handlers

  handleSubmit = async (event) => {
    event.preventDefault();
    this.tog_standard4()

  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isFin === true || this.state.isSuper === true ? (
            <Container fluid>
              <Breadcrumbs
                title="Bkash Disbursement Settings"
                breadcrumbItems={this.state.breadcrumbItems}
              />

              <Col xl={12}>
                {/* finance inputs with the previous data and proposed data. */}
                {this.state.canViewBkashSettings === 1 ? (
                  <Form onSubmit={this.handleSubmit} id="18">
                    <FormGroup>
                      <Row>
                        <Col>
                          <Card>
                            <CardBody>
                              <h4 className="card-title">
                                Bkash Disbursement Settings
                              </h4>
                              <p className="card-title-desc">
                                Please input Bkash Disbursement Settings values
                                here.
                              </p>
                              <div className="d-flex mb-3">
                                <Col xs={4}></Col>
                                <Col xs={4}>
                                  <h5>Present Value</h5>
                                </Col>
                                <Col xs={4}>
                                  <h5>Proposed Value</h5>
                                </Col>
                              </div>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="Soft Disbursement Limit Vat-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Soft Disbursement Limit (in BDT){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.softDisbursementDefault}
                                    placeholder="Please input the value for Soft Disbursement Limit"
                                    id="Soft Disbursement Limit Vat-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.softDisbursement}
                                    placeholder="Please input the value for buy service charge vat"
                                    id="Buy Service Charge Vat-input"
                                    onChange={
                                      this.handleSoftDisbursementLimitChange
                                    }
                                    style={{
                                      backgroundColor:
                                        this.state.softDisbursementChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="Hard Disbursement Limit-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Hard Disbursement Limit (in BDT){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.hardDisbursementDefault}
                                    placeholder="Please input the value for hard disbursement limit"
                                    id="Hard Disbursement Limit-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.hardDisbursement}
                                    placeholder="Please input the value for hard disbursement limit"
                                    id="Hard Disbursement Limit Vat-input"
                                    onChange={
                                      this.handleHardDisbursementLimitChange
                                    }
                                    style={{
                                      backgroundColor:
                                        this.state.hardDisbursementChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="Hard Disbursement Limit-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Available Balance
                                </Label>
                                <Col md={4} className="">
                                  <Input
                                    value={this.state.availableBalance}
                                    disabled
                                  />
                                </Col>
                              </Row>
                              <Row
                                className="d-flex justify-content-end mx-1"
                                id="foo"
                              >
                                <Button
                                  type="submit"
                                  style={{
                                    width: '35%',
                                  }}
                                >
                                  Submit
                                </Button>

                                <Modal
                                  isOpen={this.state.modal_standard4}
                                  toggle={this.tog_standard4}
                                >
                                  <ModalHeader
                                    toggle={() => {
                                      this.setState({
                                        modal_standard4: false,
                                      });
                                    }}
                                  >
                                    Confirmation
                                  </ModalHeader>

                                  <ModalBody>
                                    <Col xl={12}>
                                      <Row className="mb-3 mx-2">
                                        Are you sure you want Submit this
                                        request?
                                      </Row>
                                      <Row className="mb-3">
                                        <Col md={6}><h6>Bkash Disbursement Soft Limit</h6><p>{this.state.softDisbursement.toLocaleString()} BDT</p></Col>
                                        <Col md={6}><h6>Bkash Disbursement Hard Limit</h6><p>{this.state.hardDisbursement.toLocaleString()} BDT</p></Col>
                                      </Row>
                                      <Row className="mb-3">
                                        <Col md={6}><h6>Bkash Available Balance</h6><p>{this.state.availableBalance.toLocaleString()} BDT</p></Col>
                                        
                                      </Row>
                                    </Col>
                                  </ModalBody>

                                  <ModalFooter>
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="waves-effect waves-light"
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        let sendData = {
                                          bkashSoftLimit: this.state.softDisbursement,
                                          bkashHardLimit: this.state.hardDisbursement,
                                          staffId: getUser(),
                                          
                                        };
                                        const token = getToken();
                                        await GKApi.post(
                                          '/finance/operation-input/disbursement',
                                          sendData,
                                          {
                                            headers: {
                                              Authorization: token,
                                              cmpId: '172',
                                            },
                                          }
                                        );
                                        
                                        const response = await GKApi.get('/finance/operation-input/disbursement', {
                                          headers: {
                                            Authorization: token,
                                            cmpId: '172',
                                          },
                                        });
                                    
                                        
                                        this.setState(
                                          {
                                            disbursementInfo: response.data.getDisInputsInfo,
                                          },
                                          () => {}
                                        );
                                    
                                        this.setState({
                                          softDisbursementDefault: this.state.disbursementInfo.bkashSoftLimit,
                                          softDisbursement: this.state.disbursementInfo.bkashSoftLimit,
                                          hardDisbursementDefault: this.state.disbursementInfo.bkashHardLimit,
                                          hardDisbursement: this.state.disbursementInfo.bkashHardLimit,
                                          availableBalance: this.state.disbursementInfo.bkashAvailableBalance,
                                        });
                                        this.tog_standard4();
                                      }}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      type="button"
                                      onClick={this.tog_standard4}
                                      color="light"
                                      className="waves-effect"
                                    >
                                      No
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                ) : (
                  <h5>Sorry you don't have permission to view this.</h5>
                )}
              </Col>
            </Container>
          ) : (
            <h5>Sorry you don't have permission to view this.</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default BkashDisbursementSettings;

import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';

import { Button, Card, CardBody } from 'reactstrap';
import * as XLSX from 'xlsx';
import moment from 'moment';

// Table data

class SingleUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_standard: false,
      // data: this.props.ledgerData.ledger,
    };
  }

  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();
    };
    const columns = [
      {
        field: 'transactionDate',
        title: 'Transaction Date',
        render: (row) => (
          <div>
            {row.transactionDate
              ? moment(row.transactionDate, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'marketPrice',
        title: 'Market Price',
      },
      {
        field: 'goldQuantity',
        title: 'Gold Quantity',
        render: (row) => <div>{row.goldQuantity.toFixedDown(4)}</div>,
      },
      {
        field: 'amountInBDT',
        title: 'Gold of Value (in BDT)',
        render: (row) => <div>{row.amountInBDT.toFixedDown(4)}</div>,
      },
      {
        field: 'transactionType',
        title: 'Transaction Type',
      },

      {
        field: 'payChannel',
        title: 'Payment Channel',
        render: (row) => (
          <div>{row.payChannel==='bKash' ? 'bKash' : row.payChannel==='Card' ? 'Card': row.payChannel==='Bank' ? 'Bank': row.payChannel==='Nagad' ? 'Nagad': row.payChannel===0 ? 'Not Available':'Not Applicable'}</div>
        ),
      },
      {
        title: 'MFS/Bank Account',
        render: (row) => {
          return <div>{['Buy', 'Sell', 'Collect'].includes(row.transactionType) ? (row.payerNo || row.payeeNo || 'Not Available') : 'Not Applicable'}</div>
        }
      },
      {
        field: 'transactionStatus',
        title: 'Transaction Status',
      },
      {
        field: 'description',
        title: 'Description',
        render: (row) => (
          <div>{row.description ? row.description : 'Not Available'}</div>
        ),
      },
    ];

    let ledgerData = this.props.ledgerData;

    const downloadExcel = () => {
      const newData = ledgerData.ledger.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Ledger');
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(
        workBook,
        `${ledgerData.customerInfo.name}'s Ledger Report ${
          this.props.startDate
            ? `from ${moment(
                this.props.startDate,
                'YYYY-MM-DD HH:mm:ss'
              ).format('DD-MM-YYYY')}`
            : ''
        } ${
          this.props.endDate
            ? `to ${moment(this.props.endDate, 'YYYY-MM-DD HH:mm:ss').format(
                'DD-MM-YYYY'
              )}`
            : ''
        }.xlsx`
      );
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">

            <p className='py-2'>* Please keep in mind, once you download the report, the parameters will reset.</p>
            <MaterialTable
              title={`${ledgerData.customerInfo.name}'s Ledger Report ${
                this.props.startDate
                  ? `from ${moment(
                      this.props.startDate,
                      'YYYY-MM-DD HH:mm:ss'
                    ).format('DD-MM-YYYY')}`
                  : ''
              } ${
                this.props.endDate
                  ? `to ${moment(
                      this.props.endDate,
                      'YYYY-MM-DD HH:mm:ss'
                    ).format('DD-MM-YYYY')}`
                  : ''
              }`}
              columns={columns}
              data={ledgerData.ledger}
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                    <h4 className="d-flex justify-content-center py-2">
                      User information
                    </h4>
                    <div className="d-flex justify-content-center">
                      <div>
                        <div className="d-flex gap-3 py-2">
                          <h5>Email:</h5>
                          <h5>{ledgerData.customerInfo.email}</h5>
                        </div>
                        <div className="d-flex gap-3">
                          <h5>Joining Date:</h5>
                          <h5>{ledgerData.customerInfo.joiningDate}</h5>
                        </div>
                      </div>
                    </div>
                    <h4 className="d-flex justify-content-center py-2">
                      {' '}
                      Summary
                    </h4>
                    <Card>
                      <CardBody>
                        <div className="d-flex justify-content-center gap-3 py-2">
                          <h5>Gold balance:</h5>
                          <h5>
                            {ledgerData.summary.balance.toFixedDown(4)} GM
                          </h5>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="d-flex justify-content-center">
                      <div className="d-flex gap-3">
                        <Card>
                          <CardBody>
                            <div className="d-flex gap-3 py-2">
                              <h5>Buy:</h5>
                              <h5>
                                {ledgerData.summary.buy.toFixedDown(4)} GM
                              </h5>
                            </div>
                            <div className="d-flex gap-3">
                              <h5>Sell:</h5>
                              <h5>
                                {ledgerData.summary.sell.toFixedDown(4)} GM
                              </h5>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <div className="d-flex gap-3 py-2">
                              <h5>Collect:</h5>
                              <h5>
                                {ledgerData.summary.collect.toFixedDown(4)} GM
                              </h5>
                            </div>
                            <div className="d-flex gap-3">
                              <h5>Refer:</h5>
                              <h5>
                                {ledgerData.summary.refer.toFixedDown(4)} GM
                              </h5>
                            </div>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <div className="d-flex gap-3 py-2">
                              <h5>Gift Sent:</h5>
                              <h5>
                                {ledgerData.summary.giftSend.toFixedDown(4)} GM
                              </h5>
                            </div>
                            <div className="d-flex gap-3">
                              <h5>Gift Received:</h5>
                              <h5>
                                {ledgerData.summary.giftReceive.toFixedDown(4)}{' '}
                                GM
                              </h5>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <div className="d-flex gap-3 py-2">
                              <h5>On Hold:</h5>
                              <h5>
                                {ledgerData.summary.onHold.toFixedDown(4)} GM
                              </h5>
                            </div>
                            <div className="d-flex gap-3">
                              <h5>Dispute:</h5>
                              <h5>
                                {ledgerData.summary.dispute.toFixedDown(4)} GM
                              </h5>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </div>
                ),
              }}
              actions={[
                {
                  icon: () => <Button>Export All</Button>, // you can pass icon too
                  tooltip: 'Export to Excel',
                  onClick: () => {downloadExcel(); this.props.clear()},
                  isFreeAction: true,
                },
              ]}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                pageSize: 20,
                columnsButton: true,
              }}
              localization={{
                toolbar: {
                  exportCSVName: 'Export as CSV',
                },
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SingleUser;

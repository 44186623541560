import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Input,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  CardText,
  Button,
  Label,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import {
  capitalizeFirstLetter,
  getToken,
  getUser,
  GKApi,
} from '../../../components/config';
// import TimePickerComponent from '../../../components/Common/TimePicker';
import moment from 'moment';
import DatePickerComponent from '../../../components/Common/DatePicker';
import { deviceInfo } from '../../../helpers/device_info_helper';

class CreateCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        // { title: 'Forms', link: '#' },
        // { title: 'Create Campaign', link: '#' },
      ],
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 25,
      isOpen: false,
      commType: 'Select General Communication Type',
      radio: 'App Banner',
      radio1Campaign: 1,
      radio1General: 'All Users',
      selectedFiles: [],
      selectedFilesBanner: [],
      generalCampaignDocId: '',
      campaignDocId: '',
      campaignSelectedStartDate: null,
      campaignSelectedStartTime: '',
      campaignSelectedEndDate: null,
      campaignSelectedEndTime: '',
      generalSelectedStartDate: null,
      generalSelectedStartTime: '',
      generalSelectedEndDate: null,
      generalSelectedEndTime: '',
      isOpen1: false,
      menu1: 'General',
      isOpen2: false,
      presetId: 'Select preset Audience',
      activeTabJustify: '5',
      offerPercentage: 0,
      campaignBudget: 0,
      modal_standard: false,
      generalCommunicationTitle: '',
      campaignTitle: '',
      modalityValues: [],
      maxTrxCountCampaignDuration: 0,
      maxTrxCountPerDay: 0,
      maxTrxAmountCampaignDuration: 0,
      maxTrxAmountPerDay: 0,
      singleTrxMaxAmountCap: 0,

      ticketSize: 0,
      totalTrxAmount: 0,
      totalTrxCount: 0,
      newCustomerAcquisition: 0,
      activeCustomerPercentage: 0,
      ticketSizeKPI: 0,
      generalAppBannerUrl: '',
      generalAppBannerTargetLink: '',
      generalPushNotificationTitle: '',
      generalPushNotificationDescription: '',
      generalPushNotificationTargetLink: '',
      generalInboxTitle: '',
      generalInboxDescription: '',
      generalInboxTargetLink: '',
      campaignAppBannerUrl: '',
      campaignAppBannerTargetLink: '',
      campaignPushNotificationTitle: '',
      campaignPushNotificationDescription: '',
      campaignPushNotificationTargetLink: '',
      campaignInboxTitle: '',
      campaignInboxDescription: '',
      campaignInboxTargetLink: '',
      newKPIData: [{ kpiTitle: '', kpiValue: 0 }],
      deviceInfo: {},
      communicationType: [],
      audienceType: [],
      audType: '',
      audTypeCode: 1,
      commCode: 1,
      generalBannerLinkTypeMenu: false,
      generalBannerLinkType: 'Select Link Type',
      generalBannerInternalLinkTypeMenu: false,

      generalPushNotificationLinkTypeMenu: false,
      generalPushNotificationLinkType: 'Select Link Type',
      generalInboxLinkTypeMenu: false,
      generalInboxLinkType: 'Select Link Type',
      campaignBannerLinkTypeMenu: false,
      campaignBannerLinkType: 'Select Link Type',
      campaignBannerInternalLinkTypeMenu: false,

      bannerInternalLinkList: [],
      campaignPushNotificationLinkTypeMenu: false,
      campaignPushNotificationLinkType: 'Select Link Type',
      campaignInboxLinkTypeMenu: false,
      campaignInboxLinkType: 'Select Link Type',
      campaignOfferTypeMenu: false,
      campaignOfferType: 'Select Offer Type',
      downloadAudienceData: [],
      presetData: [],
      presetName: 'Select preset',
      presetTotalUser: null,
      modalityType: [],
      modalityCode: 1,
      campaignType: [],
      selectedCampaignTypeCode: 0,
      selectedCampaignType: 'Select Campaign Type',
      promoCode: '',
      disableModality1: false,
      disableModality2: false,
      disableModality3: false,
      disableModality4: false,
      disableModality5: false,
      disableModality6: false,
    };
    // resetState = () => {
    //   this.setState(this.menu1());
    // };
    this.toggleTab.bind(this);
    this.toggleTabProgress.bind(this);
  }

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();

    const commType = await GKApi.get('/marketing/general-com/type', {
      headers: {
        Authorization: token,
      },
    });

    this.setState({
      communicationType: commType.data,
    });

    const campaignType = await GKApi.get('/marketing/campaign/type', {
      headers: {
        Authorization: token,
      },
    });

    this.setState({
      campaignType: campaignType.data,
    });

    const audType = await GKApi.get('/marketing/general-com/audience-type', {
      headers: {
        Authorization: token,
      },
    });
    this.setState({
      audienceType: audType.data,
    });

    const getDownloadAudience = await GKApi.get(
      '/marketing/download-audience',
      {
        headers: {
          Authorization: token,
        },
      }
    );
    this.setState({
      downloadAudienceData: getDownloadAudience.data,
    });

    const getModalityType = await GKApi.get('/marketing/modality/type', {
      headers: {
        Authorization: token,
      },
    });
    let tempModalityType = getModalityType.data.map((item) => {
      return { ...item, show: true };
    });
    this.setState({
      modalityType: tempModalityType,
    });


    //internal link api call

    const internalLinkList = await GKApi.get('/marketing/banner/target-link', {
      headers: {
        Authorization: token,
      },
    });
    this.setState({ bannerInternalLinkList: internalLinkList.data.data });

    const res = deviceInfo();
    this.setState({ deviceInfo: res }, () => {});
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTabProgress: tab,
        });

        if (tab === 1) {
          this.setState({ progressValue: 25 });
        }
        if (tab === 2) {
          this.setState({ progressValue: 50 });
        }
        if (tab === 3) {
          this.setState({ progressValue: 75 });
        }
        if (tab === 4) {
          this.setState({ progressValue: 100 });
        }
      }
    }
  }

  removeBodyCss = () => {
    document.body.classList.add('no_padding');
  };

  handleGeneralCommunicationTitleChange = (e) => {
    this.setState({ generalCommunicationTitle: e.target.value });
  };

  handleGeneralAppBannerTargetLinkChange = (e) => {
    this.setState({ generalAppBannerTargetLink: e.target.value });
  };
  handleGeneralPushNotificationTitleChange = (e) => {
    this.setState({ generalPushNotificationTitle: e.target.value });
  };
  handleGeneralPushNotificationDescriptionChange = (e) => {
    this.setState({ generalPushNotificationDescription: e.target.value });
  };
  handleGeneralPushNotificationTargetLinkChange = (e) => {
    this.setState({ generalPushNotificationTargetLink: e.target.value });
  };
  handleGeneralInboxTitleChange = (e) => {
    this.setState({ generalInboxTitle: e.target.value });
  };
  handleGeneralInboxDescriptionChange = (e) => {
    this.setState({ generalInboxDescription: e.target.value });
  };
  handleGeneralInboxTargetLinkChange = (e) => {
    this.setState({ generalInboxTargetLink: e.target.value });
  };

  handleCampaignAppBannerTargetLinkChange = (e) => {
    this.setState({ campaignAppBannerTargetLink: e.target.value });
  };
  handleCampaignPushNotificationTitleChange = (e) => {
    this.setState({ campaignPushNotificationTitle: e.target.value });
  };
  handleCampaignPushNotificationDescriptionChange = (e) => {
    this.setState({ campaignPushNotificationDescription: e.target.value });
  };
  handleCampaignPushNotificationTargetLinkChange = (e) => {
    this.setState({ campaignPushNotificationTargetLink: e.target.value });
  };
  handleCampaignInboxTitleChange = (e) => {
    this.setState({ campaignInboxTitle: e.target.value });
  };
  handleCampaignInboxDescriptionChange = (e) => {
    this.setState({ campaignInboxDescription: e.target.value });
  };
  handleCampaignInboxTargetLinkChange = (e) => {
    this.setState({ campaignInboxTargetLink: e.target.value });
  };

  handleCampaignTitleChange = (e) => {
    this.setState({ campaignTitle: e.target.value });
  };

  handlePromoCodeChange = (e) => {
    this.setState({ promoCode: e.target.value.toUpperCase() });
  };

  handleOfferPercentageChange = (e) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (e.target.value === '' || re.test(e.target.value)) &&
      parseFloat(e.target.value) <= 100
    ) {
      this.setState({ offerPercentage: e.target.value });
    }
  };

  handleCampaignBudgetChange = (e) => {
    this.setState({ campaignBudget: e.target.value });
  };

  handleCampaignSelectedStartDateChange = (e) => {
    this.setState({ campaignSelectedStartDate: e });
  };

  handleCampaignSelectedStartTimeChange = (e) => {
    this.setState({ campaignSelectedStartTime: e.target.value });
  };

  handleCampaignSelectedEndDateChange = (e) => {
    this.setState({ campaignSelectedEndDate: e });
  };

  handleCampaignSelectedEndTimeChange = (e) => {
    this.setState({ campaignSelectedEndTime: e.target.value });
  };

  handleGeneralSelectedStartDateChange = (e) => {
    this.setState({ generalSelectedStartDate: e });
  };

  handleGeneralSelectedStartTimeChange = (e) => {
    this.setState({ generalSelectedStartTime: e.target.value });
  };

  handleGeneralSelectedEndDateChange = (e) => {
    this.setState({ generalSelectedEndDate: e });
  };

  handleGeneralSelectedEndTimeChange = (e) => {
    this.setState({ generalSelectedEndTime: e.target.value });
  };

  handleMaxTrxCountCampaignDurationChange = (e) => {
    this.setState({ maxTrxCountCampaignDuration: e.target.value });
  };

  handleMaxTrxCountPerDayChange = (e) => {
    this.setState({ maxTrxCountPerDay: e.target.value });
  };

  handleMaxTrxAmountCampaignDurationChange = (e) => {
    this.setState({ maxTrxAmountCampaignDuration: e.target.value });
  };

  handleMaxTrxAmountPerDayChange = (e) => {
    this.setState({ maxTrxAmountPerDay: e.target.value });
  };
  handleSingleTrxMaxAmountCap = (e) => {
    this.setState({ singleTrxMaxAmountCap: e.target.value });
  };

  handleTicketSizeChange = (e) => {
    this.setState({ ticketSize: e.target.value });
  };

  handleTotalTrxCountChange = (e) => {
    this.setState({ totalTrxCount: e.target.value });
  };
  handleTotalTrxAmountChange = (e) => {
    this.setState({ totalTrxAmount: e.target.value });
  };
  handleNewCustomerAcquisitionChange = (e) => {
    this.setState({ newCustomerAcquisition: e.target.value });
  };
  handleActiveCustomerPercentageChange = (e) => {
    this.setState({ activeCustomerPercentage: e.target.value });
  };
  handleTicketSizeKPIChange = (e) => {
    this.setState({ ticketSizeKPI: e.target.value });
  };

  //   file upload
  handleAcceptedFiles = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };

  handleAcceptedFilesBanner = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFilesBanner: files });
  };
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  //   for radio button
  onRadioChange = (e) => {
    this.setState({
      radio: e.currentTarget.value,
    });
  };
  onRadioChange1Campaign = (e) => {
    this.setState({
      radio1Campaign: e.currentTarget.value,
    });
  };

  onRadioChange1General = (e) => {
    this.setState({
      radio1General: e.currentTarget.value,
    });
  };

  toggleCustomJustified(tab) {
    if (this.state.activeTabJustify !== tab) {
      this.setState({
        activeTabJustify: tab,
      });
    }
  }

  uploadDoc = async () => {
    const token = getToken(); //get token from local storage
    const response = await GKApi.post(
      '/marketing/proposal-doc',
      {
        staffId: getUser(),
        fileName:
          'proposal.' + this.state.selectedFiles[0].name.split('.').pop(),
        campaignTitle: this.state.generalCommunicationTitle
          ? this.state.generalCommunicationTitle
          : 'General',
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // eslint-disable-next-line
    this.state.generalCampaignDocId = response.data.docId;
    await axios.put(
      response.data.url,
      this.state.selectedFiles[0]
    );
  };

  uploadBanner = async () => {
    const token = getToken(); //get token from local storage
    const response = await GKApi.post(
      '/marketing/banner',
      {
        staffId: getUser(),
        fileName:
          'banner.' + this.state.selectedFilesBanner[0].name.split('.').pop(),
        campaignTitle: this.state.generalCommunicationTitle
          ? this.state.generalCommunicationTitle
          : 'General',
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // eslint-disable-next-line
    this.state.generalAppBannerUrl = response.data.key;
    
    await axios.put(
      response.data.url,
      this.state.selectedFilesBanner[0]
    );
  };

  uploadDocCampaign = async () => {
    const token = getToken(); //get token from local storage
    const response = await GKApi.post(
      '/marketing/proposal-doc',
      {
        staffId: getUser(),
        fileName:
          'proposal.' + this.state.selectedFiles[0].name.split('.').pop(),
        campaignTitle: this.state.campaignTitle
          ? this.state.campaignTitle
          : 'Campaign',
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // this.setState({ campaignDocId: response.data.docId });
    // eslint-disable-next-line
    this.state.campaignDocId = response.data.docId;
    await axios.put(
      response.data.url,
      this.state.selectedFiles[0]
    );
  };

  uploadBannerCampaign = async () => {
    const token = getToken(); //get token from local storage
    const response = await GKApi.post(
      '/marketing/banner',
      {
        staffId: getUser(),
        fileName:
          'banner.' + this.state.selectedFilesBanner[0].name.split('.').pop(),
        campaignTitle: this.state.campaignTitle
          ? this.state.campaignTitle
          : 'Campaign',
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // this.setState({ campaignAppBannerUrl: response.data.key });
    // eslint-disable-next-line
    this.state.campaignAppBannerUrl = response.data.key;
    await axios.put(
      response.data.url,
      this.state.selectedFilesBanner[0]
    );
  };

  handleGeneralCommunicationSubmit = async () => {
    // let staffId = getUser();
    const token = getToken(); //get token from local storage
    // upload banner
    if (this.state.selectedFilesBanner.length > 0) {
      await this.uploadBanner();
    }
    // upload doc
    if (this.state.selectedFiles.length > 0) {
      await this.uploadDoc();
    }

    const submitData = {
      isDraft: 0,
      campaignTitle: this.state.generalCommunicationTitle,
      cmpId: 37,
      deviceInfo: {
        ip: this.state.deviceInfo.IPv4,
      },
      startDate: this.state.generalSelectedStartDate
        ? moment(this.state.generalSelectedStartDate).format(
            'DD-MM-YYYY'
          )+this.state.generalSelectedStartTime : null,
      endDate: this.state.generalSelectedEndDate
        ? moment(this.state.generalSelectedEndDate).format(
          'DD-MM-YYYY'
        )+this.state.generalSelectedEndTime : null,
      comType: this.state.commCode,
      docId:
        this.state.generalCampaignDocId === ''
          ? null
          : this.state.generalCampaignDocId,
      notificationTitle:
        this.state.generalPushNotificationTitle === ''
          ? null
          : this.state.generalPushNotificationTitle,
      notificationMsg:
        this.state.generalPushNotificationDescription === ''
          ? null
          : this.state.generalPushNotificationDescription,
      notificationLink:
        this.state.generalPushNotificationTargetLink === ''
          ? null
          : this.state.generalPushNotificationTargetLink,
      notificationLinkType:
        this.state.generalPushNotificationLinkType === 'Select Link Type'
          ? null
          : this.state.generalPushNotificationLinkType,
      bannerUrl:
        this.state.generalAppBannerUrl === ''
          ? null
          : this.state.generalAppBannerUrl,
      bannerLink:
        this.state.generalAppBannerTargetLink === ''
          ? null
          : this.state.generalAppBannerTargetLink,
      bannerLinkType:
        this.state.generalBannerLinkType === 'Select Link Type'
          ? null
          : this.state.generalBannerLinkType,
      audienceType: this.state.audTypeCode,
      customerPresetId:
        this.state.presetId === 'Select preset Audience'
          ? null
          : this.state.presetId,
      inboxTitle:
        this.state.generalInboxTitle === ''
          ? null
          : this.state.generalInboxTitle,
      inboxMsg:
        this.state.generalInboxDescription === ''
          ? null
          : this.state.generalInboxDescription,
      isBannner: this.state.selectedFilesBanner.length > 0 ? 1 : 0,
      isNotification:
        this.state.generalPushNotificationDescription !== '' ? 1 : 0,
      isInbox: this.state.generalInboxDescription !== '' ? 1 : 0,
    };
    await GKApi.post('/marketing/general-com', submitData, {
      headers: {
        Authorization: token,
      },
    });

    await this.props.fetchCampaign()
  };

  handleGeneralCommunicationDraftSave = async () => {
    const token = getToken(); //get token from local storage
    // upload banner
    if (this.state.selectedFilesBanner.length > 0) {
      await this.uploadBanner();
    }
    // upload doc
    if (this.state.selectedFiles.length > 0) {
      await this.uploadDoc();
    }

    const submitData = {
      isDraft: 1,
      campaignTitle: this.state.generalCommunicationTitle,
      cmpId: 37,
      deviceInfo: {
        ip: this.state.deviceInfo.IPv4,
      },
      startDate: this.state.generalSelectedStartDate
        ? moment(this.state.generalSelectedStartDate).format(
          'DD-MM-YYYY '
        )+this.state.generalSelectedStartTime : null,
      endDate: this.state.generalSelectedEndDate
        ? moment(this.state.generalSelectedEndDate).format(
          'DD-MM-YYYY '
        )+this.state.generalSelectedEndTime : null,
      comType: this.state.commCode,
      docId:
        this.state.generalCampaignDocId === ''
          ? null
          : this.state.generalCampaignDocId,
      notificationTitle:
        this.state.generalPushNotificationTitle === ''
          ? null
          : this.state.generalPushNotificationTitle,
      notificationMsg:
        this.state.generalPushNotificationDescription === ''
          ? null
          : this.state.generalPushNotificationDescription,
      notificationLink:
        this.state.generalPushNotificationTargetLink === ''
          ? null
          : this.state.generalPushNotificationTargetLink,
      notificationLinkType:
        this.state.generalPushNotificationLinkType === 'Select Link Type'
          ? null
          : this.state.generalPushNotificationLinkType,
      bannerUrl:
        this.state.generalAppBannerUrl === ''
          ? null
          : this.state.generalAppBannerUrl,
      bannerLink:
        this.state.generalAppBannerTargetLink === ''
          ? null
          : this.state.generalAppBannerTargetLink,
      bannerLinkType:
        this.state.generalBannerLinkType === 'Select Link Type'
          ? null
          : this.state.generalBannerLinkType,
      audienceType: this.state.audTypeCode,
      customerPresetId:
        this.state.presetId === 'Select preset Audience'
          ? null
          : this.state.presetId,
      inboxTitle:
        this.state.generalInboxTitle === ''
          ? null
          : this.state.generalInboxTitle,
      inboxMsg:
        this.state.generalInboxDescription === ''
          ? null
          : this.state.generalInboxDescription,
      isBannner: this.state.selectedFilesBanner.length > 0 ? 1 : 0,
      isNotification:
        this.state.generalPushNotificationDescription !== '' ? 1 : 0,
      isInbox: this.state.generalInboxDescription !== '' ? 1 : 0,
    };
    await GKApi.post('/marketing/general-com', submitData, {
      headers: {
        Authorization: token,
      },
    });
    await this.props.fetchCampaign()
  };

  handleCampaignCommunicationSubmit = async () => {
    // let staffId = getUser();
    const token = getToken(); //get token from local storage
    // upload banner
    if (this.state.selectedFilesBanner.length > 0) {
      await this.uploadBannerCampaign();
    }
    // upload doc
    if (this.state.selectedFiles.length > 0) {
      await this.uploadDocCampaign();
    }

    const submitData = {
      isDraft: 0,
      campaignTitle: this.state.campaignTitle,
      cmpId: 37,
      deviceInfo: {
        ip: this.state.deviceInfo.IPv4,
      },
      startDate: this.state.campaignSelectedStartDate
        ? moment(this.state.campaignSelectedStartDate).format(
            'DD-MM-YYYY '
          )+this.state.campaignSelectedStartTime
        : null,
      endDate: this.state.campaignSelectedEndDate
        ? moment(this.state.campaignSelectedEndDate).format(
            'DD-MM-YYYY '
          )+this.state.campaignSelectedEndTime
        : null,
      comType: this.state.selectedCampaignTypeCode,
      promoCode:
        this.state.selectedCampaignTypeCode === 2 ||
        this.state.selectedCampaignTypeCode === 4 ||
        this.state.selectedCampaignTypeCode === 6
          ? this.state.promoCode
          : null,
      docId: this.state.campaignDocId === '' ? null : this.state.campaignDocId,
      notificationTitle:
        this.state.campaignPushNotificationTitle === ''
          ? null
          : this.state.campaignPushNotificationTitle,
      notificationMsg:
        this.state.campaignPushNotificationDescription === ''
          ? null
          : this.state.campaignPushNotificationDescription,
      notificationLink:
        this.state.campaignPushNotificationTargetLink === ''
          ? null
          : this.state.campaignPushNotificationTargetLink,
      notificationLinkType:
        this.state.campaignPushNotificationLinkType === 'Select Link Type'
          ? null
          : this.state.campaignPushNotificationLinkType,
      bannerUrl:
        this.state.campaignAppBannerUrl === ''
          ? null
          : this.state.campaignAppBannerUrl,
      bannerLink:
        this.state.campaignAppBannerTargetLink === ''
          ? null
          : this.state.campaignAppBannerTargetLink,
      bannerLinkType:
        this.state.campaignBannerLinkType === 'Select Link Type'
          ? null
          : this.state.campaignBannerLinkType,
      audienceType: this.state.audTypeCode,
      customerPresetId:
        this.state.presetId === 'Select preset Audience'
          ? null
          : this.state.presetId,
      inboxTitle:
        this.state.campaignInboxTitle === ''
          ? null
          : this.state.campaignInboxTitle,
      inboxMsg:
        this.state.campaignInboxDescription === ''
          ? null
          : this.state.campaignInboxDescription,
      isBannner: this.state.selectedFilesBanner.length > 0 ? 1 : 0,
      isNotification:
        this.state.campaignPushNotificationDescription !== '' ? 1 : 0,
      isInbox: this.state.campaignInboxDescription !== '' ? 1 : 0,
      budget: this.state.campaignBudget,
      offerType:
        this.state.campaignOfferType === 'Select Offer Type'
          ? null
          : this.state.campaignOfferType,
      offerValue: this.state.offerPercentage,
      kpi: [
        this.state.totalTrxCount !== 0 && {
          id: 1,
          value: this.state.totalTrxCount,
        },
        this.state.totalTrxAmount !== 0 && {
          id: 2,
          value: this.state.totalTrxAmount,
        },
        this.state.newCustomerAcquisition !== 0 && {
          id: 3,
          value: this.state.newCustomerAcquisition,
        },
        this.state.activeCustomerPercentage !== 0 && {
          id: 4,
          value: this.state.activeCustomerPercentage,
        },
        this.state.ticketSizeKPI !== 0 && {
          id: 5,
          value: this.state.ticketSizeKPI,
        },
      ],
      modality: [
        this.state.ticketSize !== 0 && {
          id: 1,
          value: this.state.ticketSize,
        },
        this.state.singleTrxMaxAmountCap !== 0 && {
          id: 2,
          value: this.state.singleTrxMaxAmountCap,
        },
        this.state.maxTrxAmountCampaignDuration !== 0 && {
          id: 3,
          value: this.state.maxTrxAmountCampaignDuration,
        },
        this.state.maxTrxAmountPerDay !== 0 && {
          id: 4,
          value: this.state.maxTrxAmountPerDay,
        },
        this.state.maxTrxCountCampaignDuration !== 0 && {
          id: 5,
          value: this.state.maxTrxCountCampaignDuration,
        },
        this.state.maxTrxCountPerDay !== 0 && {
          id: 6,
          value: this.state.maxTrxCountPerDay,
        },
      ],
    };
    await GKApi.post('/marketing/campaign', submitData, {
      headers: {
        Authorization: token,
      },
    });
    await this.props.fetchCampaign()
  };

  handleCampaignCommunicationDraftSave = async () => {
    const token = getToken(); //get token from local storage

    // upload banner
    if (this.state.selectedFilesBanner.length > 0) {
      await this.uploadBannerCampaign();
    }
    // upload doc
    if (this.state.selectedFiles.length > 0) {
      await this.uploadDocCampaign();
    }

    const submitData = {
      isDraft: 1,
      campaignTitle: this.state.campaignTitle,
      cmpId: 37,
      deviceInfo: {
        ip: this.state.deviceInfo.IPv4,
      },
      startDate: this.state.campaignSelectedStartDate
        ? moment(this.state.campaignSelectedStartDate).format(
            'DD-MM-YYYY '
          )+this.state.campaignSelectedStartTime
        : null,
      endDate: this.state.campaignSelectedEndDate
        ? moment(this.state.campaignSelectedEndDate).format(
            'DD-MM-YYYY '
          )+this.state.campaignSelectedEndTime
        : null,
      comType: this.state.selectedCampaignTypeCode,
      promoCode:
        this.state.selectedCampaignTypeCode === 2 ||
        this.state.selectedCampaignTypeCode === 4 ||
        this.state.selectedCampaignTypeCode === 6
          ? this.state.promoCode
          : null,
      docId: this.state.campaignDocId === '' ? null : this.state.campaignDocId,
      notificationTitle:
        this.state.campaignPushNotificationTitle === ''
          ? null
          : this.state.campaignPushNotificationTitle,
      notificationMsg:
        this.state.campaignPushNotificationDescription === ''
          ? null
          : this.state.campaignPushNotificationDescription,
      notificationLink:
        this.state.campaignPushNotificationTargetLink === ''
          ? null
          : this.state.campaignPushNotificationTargetLink,
      notificationLinkType:
        this.state.campaignPushNotificationLinkType === 'Select Link Type'
          ? null
          : this.state.campaignPushNotificationLinkType,
      bannerUrl:
        this.state.campaignAppBannerUrl === ''
          ? null
          : this.state.campaignAppBannerUrl,
      bannerLink:
        this.state.campaignAppBannerTargetLink === ''
          ? null
          : this.state.campaignAppBannerTargetLink,
      bannerLinkType:
        this.state.campaignBannerLinkType === 'Select Link Type'
          ? null
          : this.state.campaignBannerLinkType,
      audienceType: this.state.audTypeCode,
      customerPresetId:
        this.state.presetId === 'Select preset Audience'
          ? null
          : this.state.presetId,
      inboxTitle:
        this.state.campaignInboxTitle === ''
          ? null
          : this.state.campaignInboxTitle,
      inboxMsg:
        this.state.campaignInboxDescription === ''
          ? null
          : this.state.campaignInboxDescription,
      isBannner: this.state.selectedFilesBanner.length > 0 ? 1 : 0,
      isNotification:
        this.state.campaignPushNotificationDescription !== '' ? 1 : 0,
      isInbox: this.state.campaignInboxDescription !== '' ? 1 : 0,
      budget: this.state.campaignBudget,
      offerType: 'PERCENTAGE',
      offerValue: this.state.offerPercentage,
      kpi: [
        this.state.totalTrxCount !== 0 && {
          id: 1,
          value: this.state.totalTrxCount,
        },
        this.state.totalTrxAmount !== 0 && {
          id: 2,
          value: this.state.totalTrxAmount,
        },
        this.state.newCustomerAcquisition !== 0 && {
          id: 3,
          value: this.state.newCustomerAcquisition,
        },
        this.state.activeCustomerPercentage !== 0 && {
          id: 4,
          value: this.state.activeCustomerPercentage,
        },
        this.state.ticketSizeKPI !== 0 && {
          id: 5,
          value: this.state.ticketSizeKPI,
        },
      ],
      modality: [
        this.state.ticketSize !== 0 && {
          id: 1,
          value: this.state.ticketSize,
        },
        this.state.singleTrxMaxAmountCap !== 0 && {
          id: 2,
          value: this.state.singleTrxMaxAmountCap,
        },
        this.state.maxTrxAmountCampaignDuration !== 0 && {
          id: 3,
          value: this.state.maxTrxAmountCampaignDuration,
        },
        this.state.maxTrxAmountPerDay !== 0 && {
          id: 4,
          value: this.state.maxTrxAmountPerDay,
        },
        this.state.maxTrxCountCampaignDuration !== 0 && {
          id: 5,
          value: this.state.maxTrxCountCampaignDuration,
        },
        this.state.maxTrxCountPerDay !== 0 && {
          id: 6,
          value: this.state.maxTrxCountPerDay,
        },
      ],
    };
    await GKApi.post('/marketing/campaign', submitData, {
      headers: {
        Authorization: token,
      },
    });
    await this.props.fetchCampaign()
  };
  render() {
    return (
      <React.Fragment>
        <div>
          <Row>
            <Col xl={5}>
              <h6 className="fw-bold pt-2">Select Communication Type</h6>
            </Col>
            <Col xl={7}>
              <div>
                <Dropdown
                  isOpen={this.state.isOpen1}
                  toggle={() =>
                    this.setState({
                      isOpen1: !this.state.isOpen1,
                    })
                  }
                >
                  <DropdownToggle
                    className="d-flex justify-content-between"
                    color="light"
                    caret
                    style={{
                      backgroundColor: '#f1f1f1',
                      border: '1px solid #BDBDBD',
                      borderRadius: '4px',
                      width: '50%',
                    }}
                  >
                    {this.state.menu1}
                    <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={(event) => this.setState({ menu1: 'General' })}
                    >
                      General
                    </DropdownItem>
                    <DropdownItem
                      onClick={(event) =>
                        this.setState({ menu1: 'CampaignSpecific' })
                      }
                    >
                      Campaign Specific
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
          </Row>

          {/* @desc Campaign communication create */}

          {this.state.menu1 === 'CampaignSpecific' && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 1,
                            })}
                            onClick={() => {
                              this.toggleTab(1);
                            }}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">Details</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 2,
                            })}
                            onClick={() => {
                              this.toggleTab(2);
                            }}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">Content</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 3,
                            })}
                            onClick={() => {
                              this.toggleTab(3);
                            }}
                          >
                            <span className="step-number">03</span>
                            <span className="step-title">KPI</span>
                          </NavLink>
                        </NavItem>
                      </ul>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <h6 className="fw-bold">Campaign Title</h6>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    value={this.state.campaignTitle}
                                    onChange={(e) => {
                                      this.handleCampaignTitleChange(e);
                                    }}
                                  />
                                  <p className="pb-2">
                                    * Please at least fill the title field to
                                    proceed to the next step
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <div>
                              {' '}
                              <h6 className="fw-bold">Campaign Tenure</h6>
                              <p>
                                Select your campaign start date and end date
                              </p>
                            </div>
                            <Row>
                            <Col lg="6" className='d-flex gap-2'>
                                <Col lg='2'><p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Start Date
                                  </p></Col>
                                <Col lg='4'>
                                  <div
                                    style={{
                                      border: '1px solid gray',
                                      borderRadius: '5px',
                                      paddingTop: '2.5px',
                                      paddingBottom: '2.5px',
                                      paddingRight: '4px',
                                      paddingLeft: '4px',
                                    }}
                                  >
                                    
                                    <DatePickerComponent
                                      selectedDate={
                                        this.state.campaignSelectedStartDate
                                      }
                                      handleSelectedDateChange={
                                        this.handleCampaignSelectedStartDateChange
                                      }
                                    />
  
  </div>
                                </Col >
<Col lg='4'>
  <Input
                            type="time"
                            value={this.state.campaignSelectedStartTime}
                            id="add-sku-deliveryNote-input"
                            onChange={this.handleCampaignSelectedStartTimeChange}
                          />
</Col >
                              </Col>
                              <Col lg="6" className='d-flex gap-2'>
                                <Col lg='2'><p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    End Date
                                  </p></Col>
                                <Col lg='4'>
                                  <div
                                    style={{
                                      border: '1px solid gray',
                                      borderRadius: '5px',
                                      paddingTop: '2.5px',
                                      paddingBottom: '2.5px',
                                      paddingRight: '4px',
                                      paddingLeft: '4px',
                                    }}
                                  >
                                    
                                    <DatePickerComponent
                                      selectedDate={
                                        this.state.campaignSelectedEndDate
                                      }
                                      handleSelectedDateChange={
                                        this.handleCampaignSelectedEndDateChange
                                      }
                                    />
  
  </div>
                                </Col >
<Col lg='4'>
  <Input
                            type="time"
                            value={this.state.campaignSelectedEndTime}
                            id="add-sku-deliveryNote-input"
                            onChange={this.handleCampaignSelectedEndTimeChange}
                          />
</Col >
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="12" className="my-3">
                                <h6 className="fw-bold">Campaign Type</h6>

                                <Dropdown
                                  isOpen={this.state.isOpen}
                                  toggle={() =>
                                    this.setState({
                                      isOpen: !this.state.isOpen,
                                    })
                                  }
                                  className="mt-4 mt-sm-0"
                                >
                                  <DropdownToggle
                                    className="d-flex justify-content-between"
                                    color="light"
                                    caret
                                    style={{
                                      backgroundColor: '#fff',
                                      border: '1px solid #BDBDBD',
                                      borderRadius: '4px',
                                      width: '100%',
                                    }}
                                  >
                                    {this.state.selectedCampaignType}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {this.state.campaignType.map((ct) => (
                                      <DropdownItem
                                        onClick={(event) =>
                                          this.setState({
                                            selectedCampaignType: ct.title,
                                            selectedCampaignTypeCode: ct.code,
                                          })
                                        }
                                      >
                                        {ct.title}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            </Row>
                            {(this.state.selectedCampaignTypeCode === 2 ||
                              this.state.selectedCampaignTypeCode === 4 ||
                              this.state.selectedCampaignTypeCode === 6) && (
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <h6 className="fw-bold">Promo Code</h6>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-firstname-input1"
                                      value={this.state.promoCode}
                                      onChange={(e) => {
                                        this.handlePromoCodeChange(e);
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            )}

                            <Row>
                              <Col lg="12" className="my-3">
                                <h6 className="fw-bold">Offer Type</h6>

                                <Dropdown
                                  isOpen={this.state.campaignOfferTypeMenu}
                                  toggle={() =>
                                    this.setState({
                                      campaignOfferTypeMenu:
                                        !this.state.campaignOfferTypeMenu,
                                    })
                                  }
                                  className="mt-4 mt-sm-0"
                                >
                                  <DropdownToggle
                                    className="d-flex justify-content-between"
                                    color="light"
                                    caret
                                    style={{
                                      backgroundColor: '#fff',
                                      border: '1px solid #BDBDBD',
                                      borderRadius: '4px',
                                      width: '100%',
                                    }}
                                  >
                                    {this.state.campaignOfferType}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={(event) =>
                                        this.setState({
                                          campaignOfferType: 'PERCENTAGE',
                                        })
                                      }
                                    >
                                      Percentage
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) =>
                                        this.setState({
                                          campaignOfferType: 'FLAT',
                                        })
                                      }
                                    >
                                      Flat
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl={12}>
                                <div className="mb-3">
                                  <h6 className="fw-bold">Offer Value</h6>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="Please input Offer percentage"
                                    value={this.state.offerPercentage}
                                    onChange={(e) => {
                                      this.handleOfferPercentageChange(e);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div className="mb-3">
                                  <h6 className="fw-bold">
                                    Campaign Budget (BDT)
                                  </h6>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="Please input Offer percentage"
                                    value={this.state.campaignBudget}
                                    onChange={(e) => {
                                      this.handleCampaignBudgetChange(e);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="my-3">
                              <Row className="mb-3">
                                <h6 className="fw-bold">Modalities</h6>
                              </Row>
                              <Row className="mb-3">
                                {this.state.modalityType.map((modality) => (
                                  <Row className="mb-3">
                                    <Label
                                      htmlFor="email-input"
                                      className="col-md-8 col-form-label"
                                    >
                                      {modality.title}
                                    </Label>
                                    <Col md={3}>
                                      {modality.code === 1 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={this.state.ticketSize}
                                            placeholder="Please input Ticket Size"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleTicketSizeChange(e);
                                            }}
                                            disabled={
                                              this.state.disableModality1
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality1 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality1: true,
                                                  ticketSize: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality1 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality1: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                      {modality.code === 2 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={
                                              this.state.singleTrxMaxAmountCap
                                            }
                                            placeholder="Please input Single Transaction Max Amount Cap"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleSingleTrxMaxAmountCap(
                                                e
                                              );
                                            }}
                                            disabled={
                                              this.state.disableModality2
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality2 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality2: true,
                                                  singleTrxMaxAmountCap: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality2 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality2: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}

                                      {modality.code === 3 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={
                                              this.state
                                                .maxTrxAmountCampaignDuration
                                            }
                                            placeholder="Please input Max Transaction Amount in Campaign Duration"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleMaxTrxAmountCampaignDurationChange(
                                                e
                                              );
                                            }}
                                            disabled={
                                              this.state.disableModality3
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality3 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality3: true,
                                                  maxTrxAmountCampaignDuration: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality3 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality3: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}

                                      {modality.code === 4 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={
                                              this.state.maxTrxAmountPerDay
                                            }
                                            placeholder="Please input Max Transaction Amount per Day"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleMaxTrxAmountPerDayChange(
                                                e
                                              );
                                            }}
                                            disabled={
                                              this.state.disableModality4
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality4 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality4: true,
                                                  maxTrxAmountPerDay: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality4 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality4: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                      {modality.code === 5 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={
                                              this.state
                                                .maxTrxCountCampaignDuration
                                            }
                                            placeholder="Please input Max Transaction Count in Campaign Duration"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleMaxTrxCountCampaignDurationChange(
                                                e
                                              );
                                            }}
                                            disabled={
                                              this.state.disableModality5
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality5 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality5: true,
                                                  maxTrxCountCampaignDuration: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality5 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality5: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                      {modality.code === 6 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={this.state.maxTrxCountPerDay}
                                            placeholder="Please input Max Transaction Count per Day"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleMaxTrxCountPerDayChange(
                                                e
                                              );
                                            }}
                                            disabled={
                                              this.state.disableModality6
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality6 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality6: true,
                                                  maxTrxCountPerDay: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality6 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality6: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                ))}
                              </Row>
                            </Row>
                            <Row>
                              <Form>
                                <h6 className="fw-bold">
                                  Upload Campaign Proposal/Documents
                                </h6>
                                <Dropzone
                                  accept="image/*,.pdf"
                                  onDrop={(acceptedFiles) =>
                                    this.handleAcceptedFiles(acceptedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                      <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                        </div>
                                        <h4>
                                          Upload Campaign Proposal/Documents
                                        </h4>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {this.state.selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + '-file'}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted fw-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {f.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    );
                                  })}
                                </div>
                              </Form>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Form>
                            <Col xl={12}>
                              <Card>
                                <CardBody>
                                  <Nav
                                    tabs
                                    className="nav-tabs-custom nav-justified"
                                  >
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={classnames({
                                          active:
                                            this.state.activeTabJustify === '5',
                                        })}
                                        onClick={() => {
                                          this.toggleCustomJustified('5');
                                        }}
                                      >
                                        <span className="d-none d-sm-block">
                                          App Banner
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={classnames({
                                          active:
                                            this.state.activeTabJustify === '6',
                                        })}
                                        onClick={() => {
                                          this.toggleCustomJustified('6');
                                        }}
                                      >
                                        <span className="d-none d-sm-block">
                                          Push Notifications
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={classnames({
                                          active:
                                            this.state.activeTabJustify === '7',
                                        })}
                                        onClick={() => {
                                          this.toggleCustomJustified('7');
                                        }}
                                      >
                                        <span className="d-none d-sm-block">
                                          Inbox
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>

                                  <TabContent
                                    activeTab={this.state.activeTabJustify}
                                  >
                                    <TabPane tabId="5" className="p-3">
                                      <Row>
                                        <Col sm="12">
                                          <CardText>
                                            <div className="border border-1 rounded p-4">
                                              <Row className="py-2 px-4">
                                                <Dropzone
                                                  accept="image/*"
                                                  onDrop={(acceptedFiles) =>
                                                    this.handleAcceptedFilesBanner(
                                                      acceptedFiles
                                                    )
                                                  }
                                                >
                                                  {({
                                                    getRootProps,
                                                    getInputProps,
                                                  }) => (
                                                    <div className="dropzone">
                                                      <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                      >
                                                        <input
                                                          {...getInputProps()}
                                                        />
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>Upload banner</h4>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Dropzone>
                                                <div
                                                  className="dropzone-previews mt-3"
                                                  id="file-previews"
                                                >
                                                  {this.state.selectedFilesBanner.map(
                                                    (f, i) => {
                                                      return (
                                                        <Card
                                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                          key={i + '-file'}
                                                        >
                                                          <div className="p-2">
                                                            <Row className="align-items-center">
                                                              <Col className="col-auto">
                                                                <img
                                                                  data-dz-thumbnail=""
                                                                  height="80"
                                                                  className="avatar-sm rounded bg-light"
                                                                  alt={f.name}
                                                                  src={
                                                                    f.preview
                                                                  }
                                                                />
                                                              </Col>
                                                              <Col>
                                                                <Link
                                                                  to="#"
                                                                  className="text-muted fw-bold"
                                                                >
                                                                  {f.name}
                                                                </Link>
                                                                <p className="mb-0">
                                                                  <strong>
                                                                    {
                                                                      f.formattedSize
                                                                    }
                                                                  </strong>
                                                                </p>
                                                              </Col>
                                                            </Row>
                                                          </div>
                                                        </Card>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </Row>

                                              <Row className="py-2">
                                                <span>
                                                  <h6 className="fw-bold">
                                                    {' '}
                                                    Link Type
                                                  </h6>
                                                </span>
                                                <span>
                                                  <Col>
                                                    <Dropdown
                                                      isOpen={
                                                        this.state
                                                          .campaignBannerLinkTypeMenu
                                                      }
                                                      toggle={() =>
                                                        this.setState({
                                                          campaignBannerLinkTypeMenu:
                                                            !this.state
                                                              .campaignBannerLinkTypeMenu,
                                                        })
                                                      }
                                                      className="mt-4 mt-sm-0"
                                                    >
                                                      <DropdownToggle
                                                        className="d-flex justify-content-between"
                                                        color="light"
                                                        caret
                                                        style={{
                                                          backgroundColor:
                                                            '#fff',
                                                          border:
                                                            '1px solid #BDBDBD',
                                                          borderRadius: '4px',
                                                          width: '100%',
                                                        }}
                                                      >
                                                        {this.state
                                                          .campaignBannerLinkType ===
                                                        1
                                                          ? 'Internal'
                                                          : this.state
                                                              .campaignBannerLinkType ===
                                                            2
                                                          ? 'External'
                                                          : 'Select Link Type'}
                                                        <i className="mdi mdi-chevron-down"></i>
                                                      </DropdownToggle>
                                                      <DropdownMenu>
                                                        <DropdownItem
                                                          onClick={(event) =>
                                                            this.setState({
                                                              campaignBannerLinkType: 1,
                                                            })
                                                          }
                                                        >
                                                          Internal
                                                        </DropdownItem>
                                                        <DropdownItem
                                                          onClick={(event) =>
                                                            this.setState({
                                                              campaignBannerLinkType: 2,
                                                            })
                                                          }
                                                        >
                                                          External
                                                        </DropdownItem>
                                                      </DropdownMenu>
                                                    </Dropdown>
                                                  </Col>
                                                </span>
                                              </Row>
                                              <Row className="py-2">
                                                <span>
                                                  <h6 className="fw-bold">
                                                    {' '}
                                                    Target Link
                                                  </h6>
                                                </span>
                                                <span>
                                                  <Col>
                                                    {this.state
                                                      .campaignBannerLinkType ===
                                                    1 ? (
                                                      <Dropdown
                                                        isOpen={
                                                          this.state
                                                            .campaignBannerInternalLinkTypeMenu
                                                        }
                                                        toggle={() =>
                                                          this.setState({
                                                            campaignBannerInternalLinkTypeMenu:
                                                              !this.state
                                                                .campaignBannerInternalLinkTypeMenu,
                                                          })
                                                        }
                                                        className="mt-4 mt-sm-0"
                                                      >
                                                        <DropdownToggle
                                                          className="d-flex justify-content-between"
                                                          color="light"
                                                          caret
                                                          style={{
                                                            backgroundColor:
                                                              '#fff',
                                                            border:
                                                              '1px solid #BDBDBD',
                                                            borderRadius: '4px',
                                                            width: '100%',
                                                          }}
                                                        >
                                                          {
                                                            this.state
                                                              .campaignAppBannerTargetLink
                                                          }
                                                          <i className="mdi mdi-chevron-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                          {this.state.bannerInternalLinkList.map(
                                                            (links) => (
                                                              <DropdownItem
                                                                onClick={(
                                                                  event
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      campaignAppBannerTargetLink:
                                                                        links,
                                                                    }
                                                                  )
                                                                }
                                                              >
                                                                {links}
                                                              </DropdownItem>
                                                            )
                                                          )}
                                                        </DropdownMenu>
                                                      </Dropdown>
                                                    ) : (
                                                      <div>
                                                        <Input
                                                          type="text"
                                                          value={
                                                            this.state
                                                              .campaignAppBannerTargetLink
                                                          }
                                                          placeholder="PLease input App Banner Target Link"
                                                          id="gold-price-input"
                                                          onChange={
                                                            this
                                                              .handleCampaignAppBannerTargetLinkChange
                                                          }
                                                        />
                                                        <p className="py-2">
                                                          *External link example
                                                          format :
                                                          https://www.goldkinen.com
                                                        </p>
                                                      </div>
                                                    )}
                                                  </Col>
                                                </span>
                                              </Row>
                                            </div>
                                          </CardText>
                                        </Col>
                                      </Row>
                                    </TabPane>
                                    <TabPane tabId="6" className="p-3">
                                      <Row>
                                        <Col sm="12">
                                          <CardText>
                                            <div className="border border-1 rounded p-4">
                                              <div className="py-4">
                                                <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Title
                                                  </h5>
                                                  <Input
                                                    type="text"
                                                    value={
                                                      this.state
                                                        .campaignPushNotificationTitle
                                                    }
                                                    placeholder="PLease input Push Notification Title"
                                                    onChange={
                                                      this
                                                        .handleCampaignPushNotificationTitleChange
                                                    }
                                                  />
                                                </div>
                                                <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Description
                                                  </h5>
                                                  <div>
                                                    <TextField
                                                      className="w-full"
                                                      variant="outlined"
                                                      type="text"
                                                      multiline
                                                      minRows={2}
                                                      style={{
                                                        width: '100%',
                                                      }}
                                                      placeholder="Please input Push Notification Description"
                                                      value={
                                                        this.state
                                                          .campaignPushNotificationDescription
                                                      }
                                                      onChange={
                                                        this
                                                          .handleCampaignPushNotificationDescriptionChange
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                {/* <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Target Link
                                                  </h5>
                                                  <Input
                                                    type="text"
                                                    value={
                                                      this.state
                                                        .campaignPushNotificationTargetLink
                                                    }
                                                    placeholder="Https:/goldkinen.com/referral"
                                                    id="gold-price-input"
                                                    onChange={
                                                      this
                                                        .handleCampaignPushNotificationTargetLinkChange
                                                    }
                                                  />
                                                </div>
                                                <Row className="py-2">
                                                  <span>
                                                    <h6 className="fw-bold">
                                                      {' '}
                                                      Link Type
                                                    </h6>
                                                  </span>
                                                  <span>
                                                    <Col>
                                                      <Dropdown
                                                        isOpen={
                                                          this.state
                                                            .campaignPushNotificationLinkTypeMenu
                                                        }
                                                        toggle={() =>
                                                          this.setState({
                                                            campaignPushNotificationLinkTypeMenu:
                                                              !this.state
                                                                .campaignPushNotificationLinkTypeMenu,
                                                          })
                                                        }
                                                        className="mt-4 mt-sm-0"
                                                      >
                                                        <DropdownToggle
                                                          className="d-flex justify-content-between"
                                                          color="light"
                                                          caret
                                                          style={{
                                                            backgroundColor:
                                                              '#fff',
                                                            border:
                                                              '1px solid #BDBDBD',
                                                            borderRadius: '4px',
                                                            width: '100%',
                                                          }}
                                                        >
                                                          {this.state
                                                            .campaignPushNotificationLinkType ===
                                                          1
                                                            ? 'Internal'
                                                            : 'External'}
                                                          <i className="mdi mdi-chevron-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                          <DropdownItem
                                                            onClick={(event) =>
                                                              this.setState({
                                                                campaignPushNotificationLinkType: 1,
                                                              })
                                                            }
                                                          >
                                                            Internal
                                                          </DropdownItem>
                                                          <DropdownItem
                                                            onClick={(event) =>
                                                              this.setState({
                                                                campaignPushNotificationLinkType: 2,
                                                              })
                                                            }
                                                          >
                                                            External
                                                          </DropdownItem>
                                                        </DropdownMenu>
                                                      </Dropdown>
                                                    </Col>
                                                  </span>
                                                </Row> */}
                                              </div>
                                            </div>
                                          </CardText>
                                        </Col>
                                      </Row>
                                    </TabPane>
                                    <TabPane tabId="7" className="p-3">
                                      <Row>
                                        <Col sm="12">
                                          <CardText>
                                            <div className="border border-1 rounded p-4">
                                              <div className="py-4">
                                                {/* <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Title
                                                  </h5>
                                                  <Input
                                                    type="text"
                                                    value={
                                                      this.state
                                                        .campaignInboxTitle
                                                    }
                                                    placeholder="Please input Inbox title"
                                                    onChange={
                                                      this
                                                        .handleCampaignInboxTitleChange
                                                    }
                                                  />
                                                </div> */}
                                                <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Description
                                                  </h5>
                                                  <div>
                                                    <TextField
                                                      className="w-full"
                                                      variant="outlined"
                                                      type="text"
                                                      multiline
                                                      minRows={2}
                                                      style={{
                                                        width: '100%',
                                                      }}
                                                      placeholder="Please input Inbox Description"
                                                      value={
                                                        this.state
                                                          .campaignInboxDescription
                                                      }
                                                      onChange={
                                                        this
                                                          .handleCampaignInboxDescriptionChange
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                {/* <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Target Link
                                                  </h5>
                                                  <Input
                                                    type="text"
                                                    value={
                                                      this.state
                                                        .campaignInboxTargetLink
                                                    }
                                                    placeholder="Https:/goldkinen.com/referral"
                                                    id="gold-price-input"
                                                    onChange={
                                                      this
                                                        .handleCampaignInboxTargetLinkChange
                                                    }
                                                  />
                                                </div>
                                                <Row className="py-2">
                                                  <span>
                                                    <h6 className="fw-bold">
                                                      {' '}
                                                      Link Type
                                                    </h6>
                                                  </span>
                                                  <span>
                                                    <Col>
                                                      <Dropdown
                                                        isOpen={
                                                          this.state
                                                            .campaignInboxLinkTypeMenu
                                                        }
                                                        toggle={() =>
                                                          this.setState({
                                                            campaignInboxLinkTypeMenu:
                                                              !this.state
                                                                .campaignInboxLinkTypeMenu,
                                                          })
                                                        }
                                                        className="mt-4 mt-sm-0"
                                                      >
                                                        <DropdownToggle
                                                          className="d-flex justify-content-between"
                                                          color="light"
                                                          caret
                                                          style={{
                                                            backgroundColor:
                                                              '#fff',
                                                            border:
                                                              '1px solid #BDBDBD',
                                                            borderRadius: '4px',
                                                            width: '100%',
                                                          }}
                                                        >
                                                          {this.state
                                                            .campaignInboxLinkType ===
                                                          1
                                                            ? 'Internal'
                                                            : 'External'}
                                                          <i className="mdi mdi-chevron-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                          <DropdownItem
                                                            onClick={(event) =>
                                                              this.setState({
                                                                campaignInboxLinkType: 1,
                                                              })
                                                            }
                                                          >
                                                            Internal
                                                          </DropdownItem>
                                                          <DropdownItem
                                                            onClick={(event) =>
                                                              this.setState({
                                                                campaignInboxLinkType: 2,
                                                              })
                                                            }
                                                          >
                                                            External
                                                          </DropdownItem>
                                                        </DropdownMenu>
                                                      </Dropdown>
                                                    </Col>
                                                  </span>
                                                </Row> */}
                                              </div>
                                            </div>
                                          </CardText>
                                        </Col>
                                      </Row>
                                    </TabPane>
                                  </TabContent>
                                </CardBody>
                              </Card>
                            </Col>
                            <Row className="py-2">
                              <h6 className="fw-bold">Campaign Audience</h6>
                              {this.state.audienceType.map((at, id) => (
                                <Col xl={4}>
                                  <div
                                    className={`border border-1 px-4 py-1  rounded d-flex ${
                                      this.state.radio1Campaign === at.title
                                        ? 'twitter-bs-wizard-border-radio'
                                        : ''
                                    }`}
                                  >
                                    <div className="mt-3">
                                      <label className="d-flex gap-2 px-4">
                                        <input
                                          type="radio"
                                          value={at.title}
                                          checked={
                                            this.state.radio1Campaign ===
                                            at.title
                                          }
                                          onChange={(e) => {
                                            this.onRadioChange1Campaign(e);
                                            this.setState({
                                              audTypeCode: at.code,
                                            });
                                          }}
                                        />
                                        <p className="px-4">{at.title}</p>
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                              {/* <Col xl={4}>
                                <div
                                  className={`border border-1 px-4 py-1  rounded d-flex ${
                                    this.state.radio1 === 'All New Users'
                                      ? 'twitter-bs-wizard-border-radio'
                                      : ''
                                  }`}
                                >
                                  <div className="mt-3">
                                    <input
                                      type="radio"
                                      value="All New Users"
                                      checked={
                                        this.state.radio1 === 'All New Users'
                                      }
                                      onChange={this.onRadioChange1}
                                    />
                                  </div>
                                  <p className="px-2 mt-3">All New Users</p>
                                </div>
                              </Col>

                              <Col xl={4}>
                                <div
                                  className={`d-flex border border-1 px-4 py-1  rounded-lg ${
                                    this.state.radio1 === 'Upload Custom'
                                      ? 'twitter-bs-wizard-border-radio rounded'
                                      : ''
                                  }`}
                                >
                                  <div className="mt-3">
                                    <input
                                      type="radio"
                                      value="Upload Custom"
                                      checked={
                                        this.state.radio1 === 'Upload Custom'
                                      }
                                      onChange={this.onRadioChange1}
                                    />
                                  </div>
                                  <p className="px-2 mt-3">Upload Custom</p>
                                </div>
                              </Col> */}
                            </Row>
                            {this.state.radio1Campaign === 'Custom Users' && (
                              <div className="border border-1 rounded p-4">
                                <Row className="py-2">
                                  <h6 className="fw-bold">
                                    Preset Audience Name
                                  </h6>
                                  <Dropdown
                                    isOpen={this.state.isOpen2}
                                    toggle={() =>
                                      this.setState({
                                        isOpen2: !this.state.isOpen2,
                                      })
                                    }
                                    className="mt-4 mt-sm-0"
                                  >
                                    <DropdownToggle
                                      className="d-flex justify-content-between"
                                      color="light"
                                      caret
                                      style={{
                                        backgroundColor: '#fff',
                                        border: '1px solid #f1f1f1',
                                        borderRadius: '4px',
                                        width: '100%',
                                      }}
                                    >
                                      {this.state.presetName}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem disabled>
                                        Select Audience preset
                                      </DropdownItem>

                                      {this.state.downloadAudienceData.map(
                                        (dad, id) => (
                                          <DropdownItem
                                            key={id}
                                            onClick={async (event) => {
                                              const token = getToken();
                                              const res = await GKApi.get(
                                                `/marketing/download-audience?id=${dad.id}`,
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                  },
                                                }
                                              );
                                              this.setState(
                                                {
                                                  presetId: dad.id,
                                                  presetName: dad.name,
                                                  presetTotalUser:
                                                    res.data.total_user,
                                                  presetData: JSON.parse(
                                                    dad.jsonData
                                                  ),
                                                },
                                                () => {}
                                              );
                                            }}
                                          >
                                            {dad.name}
                                          </DropdownItem>
                                        )
                                      )}
                                    </DropdownMenu>
                                  </Dropdown>
                                  <div className="my-3">
                                    <h6>Preset Details</h6>

                                    {this.state.presetData.map((pd) => (
                                      <Row>
                                        <Col xl={3}>{pd.displayName}</Col>
                                        <Col xl={7}>
                                          {pd.operator === 'range' ? (
                                            <div className="d-flex gap-2">
                                              <p>From</p>
                                              <p>{pd.data.from}</p>
                                              <p>To</p>
                                              <p>{pd.data.to}</p>
                                            </div>
                                          ) : pd.operator === 'equal' ? (
                                            <div className="d-flex gap-2">
                                              <p>=</p>
                                              <p>{pd.data.value}</p>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </Col>
                                      </Row>
                                    ))}

                                    {this.state.presetTotalUser && (
                                      <Row>
                                        <Col xl={3}>Potential User</Col>
                                        <Col xl={7}>
                                          {this.state.presetTotalUser}
                                        </Col>
                                      </Row>
                                    )}
                                  </div>
                                </Row>
                              </div>
                            )}
                          </Form>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div>
                            <Card>
                              <CardBody className="border-bottom">
                                <div>
                                  <Row>
                                    <Col lg="12">
                                      <div className="mb-3">
                                        <h6 className="fw-bold">
                                          Total Transaction Amount
                                        </h6>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={this.state.totalTrxAmount}
                                          id="basicpill-firstname-input1"
                                          onChange={(e) => {
                                            this.handleTotalTrxAmountChange(e);
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="12">
                                      <div className="mb-3">
                                        <h6 className="fw-bold">
                                          Total Transaction Count
                                        </h6>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={this.state.totalTrxCount}
                                          id="basicpill-firstname-input1"
                                          onChange={(e) => {
                                            this.handleTotalTrxCountChange(e);
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="12">
                                      <div className="mb-3">
                                        <h6 className="fw-bold">
                                          New Customer Acquisition
                                        </h6>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={
                                            this.state.newCustomerAcquisition
                                          }
                                          id="basicpill-firstname-input1"
                                          onChange={(e) => {
                                            this.handleNewCustomerAcquisitionChange(
                                              e
                                            );
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="12">
                                      <div className="mb-3">
                                        <h6 className="fw-bold">
                                          Active Customer Percentage
                                        </h6>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={
                                            this.state.activeCustomerPercentage
                                          }
                                          id="basicpill-firstname-input1"
                                          onChange={(e) => {
                                            this.handleActiveCustomerPercentageChange(
                                              e
                                            );
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="12">
                                      <div className="mb-3">
                                        <h6 className="fw-bold">Ticket size</h6>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={this.state.ticketSizeKPI}
                                          id="basicpill-firstname-input1"
                                          onChange={(e) => {
                                            this.handleTicketSizeKPIChange(e);
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  {/* <Button
                                    onClick={() => {
                                      const temp = this.state.newKPIData;
                                      temp.push({
                                        kpiTitle: '',
                                        kpiValue: 0,
                                      });

                                      this.setState({
                                        newKPIData: temp,
                                      });
                                    }}
                                  >
                                    Add
                                  </Button>

                                  {this.state.newKPIData.map((item, i) => {
                                    return (
                                      <div
                                        className="d-flex gap-3 my-2"
                                        key={i}
                                      >
                                        <div>
                                          <Label>KPI Title</Label>
                                          <Input
                                            onChange={(e) => {
                                              let temp = this.state.newKPIData;
                                              temp[i].kpiTitle = e.target.value;
                                              this.setState({
                                                newKPIData: temp,
                                              });
                                            }}
                                            value={item.value}
                                            type="text"
                                          />
                                        </div>
                                        <div>
                                          <Label>KPI value</Label>
                                          <Input
                                            onChange={(e) => {
                                              let temp = this.state.newKPIData;
                                              temp[i].kpiValue = e.target.value;
                                              this.setState({
                                                newKPIData: temp,
                                              });
                                            }}
                                            value={item.value}
                                            type="number"
                                          />
                                        </div>
                                        <Button
                                          disabled={
                                            this.state.newKPIData.length === 1
                                          }
                                          onClick={() => {
                                            let temp = this.state.newKPIData;
                                            temp.splice(i, 1);
                                            this.setState({ newKPIData: temp });
                                          }}
                                          style={{
                                            width: 'auto',
                                            height: 'auto',
                                          }}
                                        >
                                          Remove
                                        </Button>
                                      </div>
                                    );
                                  })} */}
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        </TabPane>
                      </TabContent>
                      <div className="twitter-bs-wizard-pager-link">
                        <div className="d-flex justify-content-between">
                          <button
                            className="border-0"
                            style={{
                              color: '#FF0000',
                              backgroundColor: '#fff',
                            }}
                            onClick={() => {
                              this.props.modalClose();
                            }}
                          >
                            <h6
                              className="fw-bold"
                              style={{
                                color: '#FF0000',
                                backgroundColor: '#fff',
                              }}
                            >
                              Discard
                            </h6>
                          </button>
                          <div className="d-flex px-4 border-0">
                            <button
                              className="border-0 fw-bold rounded px-2"
                              onClick={(e) => {
                                this.handleCampaignCommunicationDraftSave(e);
                                this.props.modalClose();
                              }}
                              disabled={
                                this.state.campaignTitle !== '' ? false : true
                              }
                            >
                              <h6 className="fw-bold mt-2">Save as draft</h6>
                            </button>

                            <div className="px-2">
                              <li
                                className={`${
                                  this.state.activeTab === 4
                                    ? 'next disabled'
                                    : 'next'
                                } `}
                              >
                                {this.state.activeTab !== 3 && (
                                  <Button
                                    onClick={() => {
                                      this.toggleTab(this.state.activeTab + 1);
                                    }}
                                    className="fw-bold"
                                    style={{
                                      color: '#081939',
                                      backgroundColor: '#c2b56f',
                                    }}
                                    disabled={
                                      this.state.campaignTitle !== '' &&
                                      this.state.selectedFiles.length > 0
                                        ? false
                                        : true
                                    }
                                  >
                                    <h6 className="fw-bold mt-2">
                                      {this.state.campaignTitle !== '' &&
                                      this.state.selectedFiles.length > 0
                                        ? 'Next'
                                        : 'Communication must have a title and proposal doc'}
                                    </h6>
                                  </Button>
                                )}
                                {this.state.activeTab === 3 && (
                                  <Button
                                    style={{
                                      color: '#081939',
                                      backgroundColor: '#c2b56f',
                                    }}
                                    onClick={(e) => {
                                      this.handleCampaignCommunicationSubmit(e);
                                      this.toggleTab(this.state.activeTab + 1);

                                      this.props.modalClose();
                                    }}
                                    disabled={
                                      this.state.campaignTitle !== '' &&
                                      this.state.selectedFiles.length > 0
                                        ? false
                                        : true
                                    }
                                  >
                                    <h6 className="fw-bold mt-2">
                                      {this.state.campaignTitle !== '' &&
                                      this.state.selectedFiles.length > 0
                                        ? 'Submit'
                                        : 'Communication must have a title and proposal doc'}
                                    </h6>
                                  </Button>
                                )}
                              </li>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <li
                              className={`${
                                this.state.activeTab === 1
                                  ? 'previous disabled'
                                  : 'previous'
                              } px-2`}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  this.toggleTab(this.state.activeTab - 1);
                                }}
                              >
                                Previous
                              </Link>
                            </li> */}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* @desc general communication create */}

          {this.state.menu1 === 'General' && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 1,
                            })}
                            onClick={() => {
                              this.toggleTab(1);
                            }}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">Details</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 2,
                            })}
                            onClick={() => {
                              this.toggleTab(2);
                            }}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">Content</span>
                          </NavLink>
                        </NavItem>
                      </ul>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <h6 className="fw-bold">
                                    General communication Title
                                  </h6>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    value={this.state.generalCommunicationTitle}
                                    onChange={(e) => {
                                      this.handleGeneralCommunicationTitleChange(
                                        e
                                      );
                                    }}
                                  />
                                  <p className="pb-2">
                                    * Please at least fill the title field to
                                    proceed to the next step
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <div>
                              {' '}
                              <h6 className="fw-bold">
                                General communication Tenure
                              </h6>
                              <p>
                                Select your General Communication start date and
                                end date
                              </p>
                            </div>
                            <Row>
                              <Col lg="6" className='d-flex gap-2'>
                                <Col lg='2'><p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Start Date
                                  </p></Col>
                                <Col lg='4'>
                                  <div
                                    style={{
                                      border: '1px solid gray',
                                      borderRadius: '5px',
                                      paddingTop: '2.5px',
                                      paddingBottom: '2.5px',
                                      paddingRight: '4px',
                                      paddingLeft: '4px',
                                    }}
                                  >
                                    
                                    <DatePickerComponent
                                      selectedDate={
                                        this.state.generalSelectedStartDate
                                      }
                                      handleSelectedDateChange={
                                        this.handleGeneralSelectedStartDateChange
                                      }
                                    />
  
  </div>
                                </Col >
<Col lg='4'>
  <Input
                            type="time"
                            value={this.state.generalSelectedStartTime}
                            id="add-sku-deliveryNote-input"
                            onChange={this.handleGeneralSelectedStartTimeChange}
                          />
</Col >
                              </Col>
                              <Col lg="6" className='d-flex gap-2'>
                                <Col lg='2'><p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    End Date
                                  </p></Col>
                                <Col lg='4'>
                                  <div
                                    style={{
                                      border: '1px solid gray',
                                      borderRadius: '5px',
                                      paddingTop: '2.5px',
                                      paddingBottom: '2.5px',
                                      paddingRight: '4px',
                                      paddingLeft: '4px',
                                    }}
                                  >
                                    
                                    <DatePickerComponent
                                      selectedDate={
                                        this.state.generalSelectedEndDate
                                      }
                                      handleSelectedDateChange={
                                        this.handleGeneralSelectedEndDateChange
                                      }
                                    />
  
  </div>
                                </Col >
<Col lg='4'>
  <Input
                            type="time"
                            value={this.state.generalSelectedEndTime}
                            id="add-sku-deliveryNote-input"
                            onChange={this.handleGeneralSelectedEndTimeChange}
                          />
</Col >
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="12" className="my-3">
                                <h6 className="fw-bold">
                                  {' '}
                                  General Communication Type
                                </h6>

                                <Dropdown
                                  isOpen={this.state.isOpen}
                                  toggle={() =>
                                    this.setState({
                                      isOpen: !this.state.isOpen,
                                    })
                                  }
                                  className="mt-4 mt-sm-0"
                                >
                                  <DropdownToggle
                                    className="d-flex justify-content-between"
                                    color="light"
                                    caret
                                    style={{
                                      backgroundColor: '#fff',
                                      border: '1px solid #BDBDBD',
                                      borderRadius: '4px',
                                      width: '100%',
                                    }}
                                  >
                                    {capitalizeFirstLetter(this.state.commType)}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {this.state.communicationType.map(
                                      (ct, id) => (
                                        <DropdownItem
                                          key={id}
                                          onClick={(event) => {
                                            this.setState(
                                              {
                                                commType: ct.title,
                                                commCode: ct.code,
                                              },
                                              () => {}
                                            );
                                          }}
                                        >
                                          {ct.title}
                                        </DropdownItem>
                                      )
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            </Row>

                            <Row>
                              <Form>
                                <h6 className="fw-bold">
                                  Upload General Communication
                                  Proposal/Documents
                                </h6>
                                <Dropzone
                                  accept="image/*,.pdf"
                                  onDrop={(acceptedFiles) =>
                                    this.handleAcceptedFiles(acceptedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                      <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                        </div>
                                        <h4>Drag & Drop Your File Here</h4>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {this.state.selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + '-file'}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted fw-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {f.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    );
                                  })}
                                </div>
                              </Form>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Form>
                            <Col xl={12}>
                              <Card>
                                <CardBody>
                                  <Nav
                                    tabs
                                    className="nav-tabs-custom nav-justified"
                                  >
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={classnames({
                                          active:
                                            this.state.activeTabJustify === '5',
                                        })}
                                        onClick={() => {
                                          this.toggleCustomJustified('5');
                                        }}
                                      >
                                        <span className="d-none d-sm-block">
                                          App Banner
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={classnames({
                                          active:
                                            this.state.activeTabJustify === '6',
                                        })}
                                        onClick={() => {
                                          this.toggleCustomJustified('6');
                                        }}
                                      >
                                        <span className="d-none d-sm-block">
                                          Push Notifications
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={classnames({
                                          active:
                                            this.state.activeTabJustify === '7',
                                        })}
                                        onClick={() => {
                                          this.toggleCustomJustified('7');
                                        }}
                                      >
                                        <span className="d-none d-sm-block">
                                          Inbox
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>

                                  <TabContent
                                    activeTab={this.state.activeTabJustify}
                                  >
                                    <TabPane tabId="5" className="p-3">
                                      <Row>
                                        <Col sm="12">
                                          <CardText>
                                            <div className="border border-1 rounded p-4">
                                              <Row className="py-2 px-4">
                                                <Dropzone
                                                  accept="image/*"
                                                  onDrop={(acceptedFiles) =>
                                                    this.handleAcceptedFilesBanner(
                                                      acceptedFiles
                                                    )
                                                  }
                                                >
                                                  {({
                                                    getRootProps,
                                                    getInputProps,
                                                  }) => (
                                                    <div className="dropzone">
                                                      <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                      >
                                                        <input
                                                          {...getInputProps()}
                                                        />
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>Upload banner</h4>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Dropzone>
                                                <div
                                                  className="dropzone-previews mt-3"
                                                  id="file-previews"
                                                >
                                                  {this.state.selectedFilesBanner.map(
                                                    (f, i) => {
                                                      return (
                                                        <Card
                                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                          key={i + '-file'}
                                                        >
                                                          <div className="p-2">
                                                            <Row className="align-items-center">
                                                              <Col className="col-auto">
                                                                <img
                                                                  data-dz-thumbnail=""
                                                                  height="80"
                                                                  className="avatar-sm rounded bg-light"
                                                                  alt={f.name}
                                                                  src={
                                                                    f.preview
                                                                  }
                                                                />
                                                              </Col>
                                                              <Col>
                                                                <Link
                                                                  to="#"
                                                                  className="text-muted fw-bold"
                                                                >
                                                                  {f.name}
                                                                </Link>
                                                                <p className="mb-0">
                                                                  <strong>
                                                                    {
                                                                      f.formattedSize
                                                                    }
                                                                  </strong>
                                                                </p>
                                                              </Col>
                                                            </Row>
                                                          </div>
                                                        </Card>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </Row>
                                              <Row className="py-2">
                                                <span>
                                                  <h6 className="fw-bold">
                                                    {' '}
                                                    Link Type
                                                  </h6>
                                                </span>
                                                <span>
                                                  <Col>
                                                    <Dropdown
                                                      isOpen={
                                                        this.state
                                                          .generalBannerLinkTypeMenu
                                                      }
                                                      toggle={() =>
                                                        this.setState({
                                                          generalBannerLinkTypeMenu:
                                                            !this.state
                                                              .generalBannerLinkTypeMenu,
                                                        })
                                                      }
                                                      className="mt-4 mt-sm-0"
                                                    >
                                                      <DropdownToggle
                                                        className="d-flex justify-content-between"
                                                        color="light"
                                                        caret
                                                        style={{
                                                          backgroundColor:
                                                            '#fff',
                                                          border:
                                                            '1px solid #BDBDBD',
                                                          borderRadius: '4px',
                                                          width: '100%',
                                                        }}
                                                      >
                                                        {this.state
                                                          .generalBannerLinkType ===
                                                        1
                                                          ? 'Internal'
                                                          : this.state
                                                              .generalBannerLinkType ===
                                                            2
                                                          ? 'External'
                                                          : 'Select Link Type'}
                                                        <i className="mdi mdi-chevron-down"></i>
                                                      </DropdownToggle>
                                                      <DropdownMenu>
                                                        <DropdownItem
                                                          onClick={(event) =>
                                                            this.setState({
                                                              generalBannerLinkType: 1,
                                                            })
                                                          }
                                                        >
                                                          Internal
                                                        </DropdownItem>
                                                        <DropdownItem
                                                          onClick={(event) =>
                                                            this.setState({
                                                              generalBannerLinkType: 2,
                                                            })
                                                          }
                                                        >
                                                          External
                                                        </DropdownItem>
                                                      </DropdownMenu>
                                                    </Dropdown>
                                                  </Col>
                                                </span>
                                              </Row>
                                              <Row className="py-2">
                                                <span>
                                                  <h6 className="fw-bold">
                                                    {' '}
                                                    Target Link
                                                  </h6>
                                                </span>
                                                <span>
                                                  <Col>
                                                    <Col>
                                                      {this.state
                                                        .generalBannerLinkType ===
                                                      1 ? (
                                                        <Dropdown
                                                          isOpen={
                                                            this.state
                                                              .generalBannerInternalLinkTypeMenu
                                                          }
                                                          toggle={() =>
                                                            this.setState({
                                                              generalBannerInternalLinkTypeMenu:
                                                                !this.state
                                                                  .generalBannerInternalLinkTypeMenu,
                                                            })
                                                          }
                                                          className="mt-4 mt-sm-0"
                                                        >
                                                          <DropdownToggle
                                                            className="d-flex justify-content-between"
                                                            color="light"
                                                            caret
                                                            style={{
                                                              backgroundColor:
                                                                '#fff',
                                                              border:
                                                                '1px solid #BDBDBD',
                                                              borderRadius:
                                                                '4px',
                                                              width: '100%',
                                                            }}
                                                          >
                                                            {
                                                              this.state
                                                                .generalAppBannerTargetLink
                                                            }
                                                            <i className="mdi mdi-chevron-down"></i>
                                                          </DropdownToggle>
                                                          <DropdownMenu>
                                                            {this.state.bannerInternalLinkList.map(
                                                              (links) => (
                                                                <DropdownItem
                                                                  onClick={(
                                                                    event
                                                                  ) =>
                                                                    this.setState(
                                                                      {
                                                                        generalAppBannerTargetLink:
                                                                          links,
                                                                      }
                                                                    )
                                                                  }
                                                                >
                                                                  {links}
                                                                </DropdownItem>
                                                              )
                                                            )}
                                                          </DropdownMenu>
                                                        </Dropdown>
                                                      ) : (
                                                        <div>
                                                          <Input
                                                            type="text"
                                                            value={
                                                              this.state
                                                                .generalAppBannerTargetLink
                                                            }
                                                            placeholder="Please input App Banner Target Link"
                                                            id="gold-price-input"
                                                            onChange={
                                                              this
                                                                .handleGeneralAppBannerTargetLinkChange
                                                            }
                                                          />
                                                          <p className="py-2">
                                                            *External link
                                                            example format :
                                                            https://www.goldkinen.com
                                                          </p>
                                                        </div>
                                                      )}
                                                    </Col>
                                                  </Col>
                                                </span>
                                              </Row>
                                            </div>
                                          </CardText>
                                        </Col>
                                      </Row>
                                    </TabPane>
                                    <TabPane tabId="6" className="p-3">
                                      <Row>
                                        <Col sm="12">
                                          <CardText>
                                            <div className="border border-1 rounded p-4">
                                              <div className="py-4">
                                                <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Title
                                                  </h5>
                                                  <Input
                                                    type="text"
                                                    value={
                                                      this.state
                                                        .generalPushNotificationTitle
                                                    }
                                                    placeholder="PLease input Push Notification Title"
                                                    onChange={
                                                      this
                                                        .handleGeneralPushNotificationTitleChange
                                                    }
                                                  />
                                                </div>
                                                <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Description
                                                  </h5>
                                                  <div>
                                                    <TextField
                                                      className="w-full"
                                                      variant="outlined"
                                                      type="text"
                                                      multiline
                                                      minRows={2}
                                                      style={{
                                                        width: '100%',
                                                      }}
                                                      placeholder="Please input Push Notification Description"
                                                      value={
                                                        this.state
                                                          .generalPushNotificationDescription
                                                      }
                                                      onChange={
                                                        this
                                                          .handleGeneralPushNotificationDescriptionChange
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                {/* <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Target Link
                                                  </h5>
                                                  <Input
                                                    type="text"
                                                    value={
                                                      this.state
                                                        .generalPushNotificationTargetLink
                                                    }
                                                    placeholder="Https:/goldkinen.com/referral"
                                                    id="gold-price-input"
                                                    onChange={
                                                      this
                                                        .handleGeneralPushNotificationTargetLinkChange
                                                    }
                                                  />
                                                </div>
                                                <Row className="py-2">
                                                  <span>
                                                    <h6 className="fw-bold">
                                                      {' '}
                                                      Link Type
                                                    </h6>
                                                  </span>
                                                  <span>
                                                    <Col>
                                                      <Dropdown
                                                        isOpen={
                                                          this.state
                                                            .generalPushNotificationLinkTypeMenu
                                                        }
                                                        toggle={() =>
                                                          this.setState({
                                                            generalPushNotificationLinkTypeMenu:
                                                              !this.state
                                                                .generalPushNotificationLinkTypeMenu,
                                                          })
                                                        }
                                                        className="mt-4 mt-sm-0"
                                                      >
                                                        <DropdownToggle
                                                          className="d-flex justify-content-between"
                                                          color="light"
                                                          caret
                                                          style={{
                                                            backgroundColor:
                                                              '#fff',
                                                            border:
                                                              '1px solid #BDBDBD',
                                                            borderRadius: '4px',
                                                            width: '100%',
                                                          }}
                                                        >
                                                          {
                                                            this.state
                                                              .generalPushNotificationLinkType===1?'Internal':'External'
                                                          }
                                                          <i className="mdi mdi-chevron-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                          <DropdownItem
                                                            onClick={(event) =>
                                                              this.setState({
                                                                generalPushNotificationLinkType: 1,
                                                              })
                                                            }
                                                          >
                                                            Internal
                                                          </DropdownItem>
                                                          <DropdownItem
                                                            onClick={(event) =>
                                                              this.setState({
                                                                generalPushNotificationLinkType: 2,
                                                              })
                                                            }
                                                          >
                                                            External
                                                          </DropdownItem>
                                                        </DropdownMenu>
                                                      </Dropdown>
                                                    </Col>
                                                  </span>
                                                </Row> */}
                                              </div>
                                            </div>
                                          </CardText>
                                        </Col>
                                      </Row>
                                    </TabPane>
                                    <TabPane tabId="7" className="p-3">
                                      <Row>
                                        <Col sm="12">
                                          <CardText>
                                            <div className="border border-1 rounded p-4">
                                              <div className="py-4">
                                                {/* <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Title
                                                  </h5>
                                                  <Input
                                                    type="text"
                                                    value={
                                                      this.state
                                                        .generalInboxTitle
                                                    }
                                                    placeholder="Please input Inbox title"
                                                    onChange={
                                                      this
                                                        .handleGeneralInboxTitleChange
                                                    }
                                                  />
                                                </div> */}
                                                <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Description
                                                  </h5>
                                                  <div>
                                                    <TextField
                                                      className="w-full"
                                                      variant="outlined"
                                                      type="text"
                                                      multiline
                                                      minRows={2}
                                                      style={{
                                                        width: '100%',
                                                      }}
                                                      placeholder="Please input Inbox Description"
                                                      value={
                                                        this.state
                                                          .generalInboxDescription
                                                      }
                                                      onChange={
                                                        this
                                                          .handleGeneralInboxDescriptionChange
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                {/* <div className="pb-2">
                                                  <h5 className="card-title">
                                                    Target Link
                                                  </h5>
                                                  <Input
                                                    type="text"
                                                    value={
                                                      this.state
                                                        .generalInboxTargetLink
                                                    }
                                                    placeholder="Https:/goldkinen.com/referral"
                                                    id="gold-price-input"
                                                    onChange={
                                                      this
                                                        .handleGeneralInboxTargetLinkChange
                                                    }
                                                  />
                                                </div>
                                                <Row className="py-2">
                                                  <span>
                                                    <h6 className="fw-bold">
                                                      {' '}
                                                      Link Type
                                                    </h6>
                                                  </span>
                                                  <span>
                                                    <Col>
                                                      <Dropdown
                                                        isOpen={
                                                          this.state
                                                            .generalInboxLinkTypeMenu
                                                        }
                                                        toggle={() =>
                                                          this.setState({
                                                            generalInboxLinkTypeMenu:
                                                              !this.state
                                                                .generalInboxLinkTypeMenu,
                                                          })
                                                        }
                                                        className="mt-4 mt-sm-0"
                                                      >
                                                        <DropdownToggle
                                                          className="d-flex justify-content-between"
                                                          color="light"
                                                          caret
                                                          style={{
                                                            backgroundColor:
                                                              '#fff',
                                                            border:
                                                              '1px solid #BDBDBD',
                                                            borderRadius: '4px',
                                                            width: '100%',
                                                          }}
                                                        >
                                                          {
                                                            this.state
                                                              .generalInboxLinkType===1?'Internal':'External'
                                                          }
                                                          <i className="mdi mdi-chevron-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                          <DropdownItem
                                                            onClick={(event) =>
                                                              this.setState({
                                                                generalInboxLinkType: 1,
                                                              })
                                                            }
                                                          >
                                                            Internal
                                                          </DropdownItem>
                                                          <DropdownItem
                                                            onClick={(event) =>
                                                              this.setState({
                                                                generalInboxLinkType: 2,
                                                              })
                                                            }
                                                          >
                                                            External
                                                          </DropdownItem>
                                                        </DropdownMenu>
                                                      </Dropdown>
                                                    </Col>
                                                  </span>
                                                </Row> */}
                                              </div>
                                            </div>
                                          </CardText>
                                        </Col>
                                      </Row>
                                    </TabPane>
                                  </TabContent>
                                </CardBody>
                              </Card>
                            </Col>
                            <Row className="py-2">
                              <h6 className="fw-bold">Campaign Audience</h6>
                              {this.state.audienceType.map((at, id) => (
                                <Col xl={4}>
                                  <div
                                    className={`border border-1 px-4 py-1  rounded d-flex ${
                                      this.state.radio1General === at.title
                                        ? 'twitter-bs-wizard-border-radio'
                                        : ''
                                    }`}
                                  >
                                    <div className="mt-3">
                                      <label className="d-flex gap-2 px-4">
                                        <input
                                          type="radio"
                                          value={at.title}
                                          checked={
                                            this.state.radio1General ===
                                            at.title
                                          }
                                          onChange={(e) => {
                                            this.onRadioChange1General(e);
                                            this.setState({
                                              audTypeCode: at.code,
                                            });
                                          }}
                                        />
                                        <p className="px-2">{at.title}</p>
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                              {/* <Col xl={4}>
                                <div
                                  className={`border border-1 px-4 py-1  rounded d-flex ${
                                    this.state.radio1 === 'All New Users'
                                      ? 'twitter-bs-wizard-border-radio'
                                      : ''
                                  }`}
                                >
                                  <div className="mt-3">
                                    <input
                                      type="radio"
                                      value="All New Users"
                                      checked={
                                        this.state.radio1 === 'All New Users'
                                      }
                                      onChange={this.onRadioChange1}
                                    />
                                  </div>
                                  <p className="px-2 mt-3">All New Users</p>
                                </div>
                              </Col>

                              <Col xl={4}>
                                <div
                                  className={`d-flex border border-1 px-4 py-1  rounded-lg ${
                                    this.state.radio1 === 'Upload Custom'
                                      ? 'twitter-bs-wizard-border-radio rounded'
                                      : ''
                                  }`}
                                >
                                  <div className="mt-3">
                                    <input
                                      type="radio"
                                      value="Upload Custom"
                                      checked={
                                        this.state.radio1 === 'Upload Custom'
                                      }
                                      onChange={this.onRadioChange1}
                                    />
                                  </div>
                                  <p className="px-2 mt-3">Upload Custom</p>
                                </div>
                              </Col> */}
                            </Row>
                            {this.state.radio1General === 'Custom Users' && (
                              <div className="border border-1 rounded p-4">
                                <Row className="py-2">
                                  <h6 className="fw-bold">
                                    Preset Audience Name
                                  </h6>
                                  <Dropdown
                                    isOpen={this.state.isOpen2}
                                    toggle={() =>
                                      this.setState({
                                        isOpen2: !this.state.isOpen2,
                                      })
                                    }
                                    className="mt-4 mt-sm-0"
                                  >
                                    <DropdownToggle
                                      className="d-flex justify-content-between"
                                      color="light"
                                      caret
                                      style={{
                                        backgroundColor: '#fff',
                                        border: '1px solid #f1f1f1',
                                        borderRadius: '4px',
                                        width: '100%',
                                      }}
                                    >
                                      {this.state.presetName}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {this.state.downloadAudienceData.map(
                                        (dad, id) => (
                                          <DropdownItem
                                            key={id}
                                            onClick={async (event) => {
                                              const token = getToken();
                                              const res = await GKApi.get(
                                                `/marketing/download-audience?id=${dad.id}`,
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                  },
                                                }
                                              );
                                              this.setState(
                                                {
                                                  presetId: dad.id,
                                                  presetName: dad.name,
                                                  presetTotalUser:
                                                    res.data.total_user,
                                                  presetData: JSON.parse(
                                                    dad.jsonData
                                                  ),
                                                },
                                                () => {}
                                              );
                                            }}
                                          >
                                            {dad.name}
                                          </DropdownItem>
                                        )
                                      )}
                                    </DropdownMenu>
                                  </Dropdown>

                                  <div className="my-3">
                                    <h6>Preset Details</h6>

                                    {this.state.presetData.map((pd) => (
                                      <Row>
                                        <Col xl={3}>{pd.displayName}</Col>
                                        <Col xl={7}>
                                          {pd.operator === 'range' ? (
                                            <div className="d-flex gap-2">
                                              <p>From</p>
                                              <p>{pd.data.from}</p>
                                              <p>To</p>
                                              <p>{pd.data.to}</p>
                                            </div>
                                          ) : pd.operator === 'equal' ? (
                                            <div className="d-flex gap-2">
                                              <p>=</p>
                                              <p>{pd.data.value}</p>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </Col>
                                      </Row>
                                    ))}

                                    {this.state.presetTotalUser && (
                                      <Row>
                                        <Col xl={3}>Potential User</Col>
                                        <Col xl={7}>
                                          {this.state.presetTotalUser}
                                        </Col>
                                      </Row>
                                    )}
                                  </div>
                                </Row>
                              </div>
                            )}
                          </Form>
                        </TabPane>
                      </TabContent>
                      <div className="twitter-bs-wizard-pager-link">
                        <div className="d-flex justify-content-between">
                          <button
                            className="border-0"
                            style={{
                              color: '#FF0000',
                              backgroundColor: '#fff',
                            }}
                            onClick={() => {
                              this.props.modalClose();
                            }}
                          >
                            <h6
                              className="fw-bold"
                              style={{
                                color: '#FF0000',
                                backgroundColor: '#fff',
                              }}
                            >
                              Discard
                            </h6>
                          </button>
                          <div className="d-flex px-4 border-0">
                            <button
                              className="border-0 fw-bold rounded px-2"
                              onClick={(e) => {
                                this.handleGeneralCommunicationDraftSave(e);
                                this.props.modalClose();
                              }}
                              disabled={
                                this.state.generalCommunicationTitle !== ''
                                  ? false
                                  : true
                              }
                            >
                              <h6 className="fw-bold mt-2">Save as draft</h6>
                            </button>

                            <div className="px-2">
                              <li
                                className={`${
                                  this.state.activeTab === 4
                                    ? 'next disabled'
                                    : 'next'
                                } `}
                              >
                                {this.state.activeTab !== 2 && (
                                  <Button
                                    onClick={() => {
                                      this.toggleTab(this.state.activeTab + 1);
                                    }}
                                    className="fw-bold px-5"
                                    style={{
                                      color: '#081939',
                                      backgroundColor: '#c2b56f',
                                    }}
                                    disabled={
                                      this.state.generalCommunicationTitle !==
                                        '' &&
                                      this.state.selectedFiles.length > 0
                                        ? false
                                        : true
                                    }
                                  >
                                    <h6 className="fw-bold mt-2">
                                      {this.state.generalCommunicationTitle !==
                                        '' &&
                                      this.state.selectedFiles.length > 0
                                        ? 'Next'
                                        : 'Communication must have a title and proposal doc'}
                                    </h6>
                                  </Button>
                                )}
                                {this.state.activeTab === 2 && (
                                  <Button
                                    style={{
                                      color: '#081939',
                                      backgroundColor: '#c2b56f',
                                    }}
                                    onClick={(e) => {
                                      this.handleGeneralCommunicationSubmit(e);
                                      this.props.modalClose();
                                    }}
                                    disabled={
                                      this.state.generalCommunicationTitle !==
                                        '' &&
                                      this.state.selectedFiles.length > 0
                                        ? false
                                        : true
                                    }
                                  >
                                    <h6 className="fw-bold mt-2">
                                      {this.state.generalCommunicationTitle !==
                                        '' &&
                                      this.state.selectedFiles.length > 0
                                        ? 'Submit'
                                        : 'Communication must have a title and proposal doc'}
                                    </h6>
                                  </Button>
                                )}
                              </li>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default CreateCampaign;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
import { Button } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { roundToTwo } from '../../../components/config';

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  };
  return date.toLocaleString('en-US', options);
}

// Table data

class SalesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '#' },
        { title: 'Finance-Dashboard', link: '#' },
        { title: 'Sales Report', link: '#' },
      ],
      visible: false,
      modal_standard: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();
    };
    const columns = [
      {
        field: ['SL No'],
        title: 'SL No',
      },
      {
        field: ['Transaction Reference No'],
        title: 'Transaction Reference No',
        render: (row) => (
          <div>
            {row['Transaction Reference No'] === ' '
              ? 'Not available'
              : row['Transaction Reference No']}
          </div>
        ),
      },
      {
        field: ['Sales Order ID'],
        title: 'Sales Order ID',
      },
      {
        field: ['Transaction Date'],
        title: 'Transaction Date',
      },
      {
        field: ['Transaction Time'],
        title: 'Transaction Time',
      },
      {
        field: ['Checkpoint'],
        title: 'Checkpoint',
        render: (row) => (
          <div>
            {row['Checkpoint'] === ' ' ? 'Not available' : row['Checkpoint']}
          </div>
        ),
      },
      {
        field: ['MFS/Bank Transaction ID'],
        title: 'MFS/Bank Transaction ID',
        render: (row) => (
          <div>
            {row['MFS/Bank Transaction ID'] === ' '
              ? 'Not available'
              : row['MFS/Bank Transaction ID']}
          </div>
        ),
      },
      {
        title: 'MFS/Bank Account',
        render: (row) => {
          return <div>{(row['MFS/Bank Account No'] || 'Not Available')}</div>
        }
      },
      {
        field: ['Customer Name'],
        title: 'Customer Name',
      },
      {
        field: ['Joining Date'],
        title: 'Joining Date',
        render: (row) => (
          <div>{formatTimestamp(row['Joining Date'])}</div>
        ),
      },
      {
        field: ['Address'],
        title: 'Address',
      },
      {
        field: ['NID'],
        title: 'NID',
      },
      {
        field: ['MSISDN'],
        title: 'MSISDN',
      },
      {
        field: ['Per Unit Price (Live Market Price)'],
        title: 'Per Unit Price (Live Market Price)',
        render: (row) => (
          <div>{roundToTwo(row['Per Unit Price (Live Market Price)'])}</div>
        ),
      },
      {
        field: ['Buy Type'],
        title: 'Buy Type',
      },
      {
        field: ['Sale Gold (Gm)'],
        title: 'Sale Gold (Gm)',
        render: (row) => <div>{row['Sale Gold (Gm)'].toFixedDown(4)}</div>,
      },
      {
        field: ['Principal Amount'],
        title: 'Principal Amount',
        render: (row) => <div>{roundToTwo(row['Principal Amount'])}</div>,
      },
      {
        field: ['Discount Amount (On Gold)'],
        title: 'Discount Amount (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Gold)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Gold)'],
        title: 'Promo Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Promo Amount (On Gold)'])}</div>,
      },
      {
        field: ['Other Additions (On Gold)'],
        title: 'Other Additions (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Gold)'])}</div>
        ),
      },
      {
        field: ['Net Amount (On Gold)'],
        title: 'Net Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Net Amount (On Gold)'])}</div>,
      },
      {
        field: ['VAT Rate (On Gold)'],
        title: 'VAT Rate (On Gold)',
        render: (row) => <div>{roundToTwo(row['VAT Rate (On Gold)'])}</div>,
      },
      {
        field: ['VAT Amount (On Gold)'],
        title: 'VAT Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['VAT Amount (On Gold)'])}</div>,
      },
      {
        field: ['Total Price Including VAT (On Gold)'],
        title: 'Total Price Including VAT (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Total Price Including VAT (On Gold)'])}</div>
        ),
      },
      {
        field: ['Service Fee Rate (Including VAT)'],
        title: 'Service Fee Rate (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Service Fee Rate (Including VAT)'])}</div>
        ),
      },
      {
        field: ['Service Fee (Including VAT)'],
        title: 'Service Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Service Fee (Including VAT)'])}</div>
        ),
      },

      {
        field: ['Discount Amount (On Service Fee)'],
        title: 'Discount Amount (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Service Fee)'],
        title: 'Promo Amount (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Promo Amount (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Other Additions (On Service Fee)'],
        title: 'Other Additions (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Net Service Fee (Including VAT)'],
        title: 'Net Service Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Net Service Fee (Including VAT)'])}</div>
        ),
      },
      {
        field: ['VAT Rate (On Service Fee)'],
        title: 'VAT Rate (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['VAT Rate (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Amount of VAT (On Service Fee)'],
        title: 'Amount of VAT (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Amount of VAT (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Total Service Fee (Excluding VAT)'],
        title: 'Total Service Fee (Excluding VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Total Service Fee (Excluding VAT)'])}</div>
        ),
      },
      {
        field: ['Grand Total'],
        title: 'Grand Total',
        render: (row) => <div>{roundToTwo(row['Grand Total'])}</div>,
      },
      {
        field: ['Payment Before Settlement Fee'],
        title: 'Payment Before Settlement Fee',
        render: (row) => (
          <div>{roundToTwo(row['Payment Before Settlement Fee'])}</div>
        ),
      },
      {
        field: ['Settlement Fee Rate'],
        title: 'Settlement Fee Rate',
        render: (row) => <div>{roundToTwo(row['Settlement Fee Rate'])}</div>,
      },
      {
        field: ['Settlement Fee Amount'],
        title: 'Settlement Fee Amount',
        render: (row) => <div>{roundToTwo(row['Settlement Fee Amount'])}</div>,
      },
      {
        field: ['Payment Medium'],
        title: 'Payment Medium',
      },
      {
        field: ['Net Collection Amount'],
        title: 'Net Collection Amount',
        render: (row) => <div>{roundToTwo(row['Net Collection Amount'])}</div>,
      },
      {
        field: ['Promo Gold Quantity in Gram'],
        title: 'Promo Gold Quantity in Gram',
        render: (row) => (
          <div>{row['Promo Gold Quantity in Gram'].toFixedDown(4)}</div>
        ),
      },
      {
        field: ['Promo Gold Value'],
        title: 'Promo Gold Value',
        render: (row) => <div>{roundToTwo(row['Promo Gold Value'])}</div>,
      },
      {
        field: ['Bonus Gold Quantity in Gram'],
        title: 'Bonus Gold Quantity in Gram',
        render: (row) => (
          <div>{row['Bonus Gold Quantity in Gram'].toFixedDown(4)}</div>
        ),
      },
      {
        field: ['Bonus Gold Value'],
        title: 'Bonus Gold Value',
        render: (row) => <div>{roundToTwo(row['Bonus Gold Value'])}</div>,
      },
      {
        field: ['Total Gold Quantity Including Promo/Bonus'],
        title: 'Total Gold Quantity Including Promo/Bonus',
        render: (row) => (
          <div>
            {row['Total Gold Quantity Including Promo/Bonus'].toFixedDown(4)}
          </div>
        ),
      },
      {
        field: ['Total Gold Value Including Promo/Bonus'],
        title: 'Total Gold Value Including Promo/Bonus',
        render: (row) => (
          <div>{roundToTwo(row['Total Gold Value Including Promo/Bonus'])}</div>
        ),
      },
      {
        field: ['Residue Amount'],
        title: 'Residue Amount',
        render: (row) => <div>{roundToTwo(row['Residue Amount'])}</div>,
      },
    ];
    const summaryColumns = [
      {
        field: ['Date'],
        title: 'Date',
      },
      {
        field: ['Number of Sales'],
        title: 'Number of Sales',
      },
      {
        field: ['Sale Gold (Gm)'],
        title: 'Sale Gold (Gm)',
        render: (row) => <div>{row['Sale Gold (Gm)'].toFixedDown(4)}</div>,
      },
      {
        field: ['Principal Amount'],
        title: 'Principal Amount',
        render: (row) => <div>{roundToTwo(row['Principal Amount'])}</div>,
      },
      {
        field: ['Discount Amount (On Gold)'],
        title: 'Discount Amount (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Gold)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Gold)'],
        title: 'Promo Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Promo Amount (On Gold)'])}</div>,
      },
      {
        field: ['Other Additions (On Gold)'],
        title: 'Other Additions (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Gold)'])}</div>
        ),
      },
      {
        field: ['Net Amount (On Gold)'],
        title: 'Net Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Net Amount (On Gold)'])}</div>,
      },

      {
        field: ['VAT Amount (On Gold)'],
        title: 'VAT Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['VAT Amount (On Gold)'])}</div>,
      },
      {
        field: ['Total Price Including VAT (On Gold)'],
        title: 'Total Price Including VAT (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Total Price Including VAT (On Gold)'])}</div>
        ),
      },
      {
        field: ['Service Fee (Including VAT)'],
        title: 'Service Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Service Fee (Including VAT)'])}</div>
        ),
      },

      {
        field: ['Discount Amount (On Service Fee)'],
        title: 'Discount Amount (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Service Fee)'],
        title: 'Promo Amount (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Promo Amount (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Other Additions (On Service Fee)'],
        title: 'Other Additions (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Net Service Fee (Including VAT)'],
        title: 'Net Service Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Net Service Fee (Including VAT)'])}</div>
        ),
      },
      {
        field: ['Amount of VAT (On Service Fee)'],
        title: 'Amount of VAT (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Amount of VAT (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Total Service Fee (Excluding VAT)'],
        title: 'Total Service Fee (Excluding VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Total Service Fee (Excluding VAT)'])}</div>
        ),
      },
      {
        field: ['Grand Total'],
        title: 'Grand Total',
        render: (row) => <div>{roundToTwo(row['Grand Total'])}</div>,
      },
      {
        field: ['Payment Before Settlement Fee'],
        title: 'Payment Before Settlement Fee',
        render: (row) => (
          <div>{roundToTwo(row['Payment Before Settlement Fee'])}</div>
        ),
      },

      {
        field: ['Settlement Fee Amount'],
        title: 'Settlement Fee Amount',
        render: (row) => <div>{roundToTwo(row['Settlement Fee Amount'])}</div>,
      },

      {
        field: ['Net Collection Amount'],
        title: 'Net Collection Amount',
        render: (row) => <div>{roundToTwo(row['Net Collection Amount'])}</div>,
      },
      {
        field: ['Promo Gold Quantity in Gram'],
        title: 'Promo Gold Quantity in Gram',
        render: (row) => (
          <div>{row['Promo Gold Quantity in Gram'].toFixedDown(4)}</div>
        ),
      },
      {
        field: ['Promo Gold Value'],
        title: 'Promo Gold Value',
        render: (row) => <div>{roundToTwo(row['Promo Gold Value'])}</div>,
      },
      {
        field: ['Bonus Gold Quantity in Gram'],
        title: 'Bonus Gold Quantity in Gram',
        render: (row) => (
          <div>{row['Bonus Gold Quantity in Gram'].toFixedDown(4)}</div>
        ),
      },
      {
        field: ['Bonus Gold Value'],
        title: 'Bonus Gold Value',
        render: (row) => <div>{roundToTwo(row['Bonus Gold Value'])}</div>,
      },
      {
        field: ['Total Gold Quantity Including Promo/Bonus'],
        title: 'Total Gold Quantity Including Promo/Bonus',
        render: (row) => (
          <div>
            {row['Total Gold Quantity Including Promo/Bonus'].toFixedDown(4)}
          </div>
        ),
      },
      {
        field: ['Total Gold Value Including Promo/Bonus'],
        title: 'Total Gold Value Including Promo/Bonus',
        render: (row) => (
          <div>{roundToTwo(row['Total Gold Value Including Promo/Bonus'])}</div>
        ),
      },
      {
        field: ['Total Auto Pay Residue Amount'],
        title: 'Total Auto Pay Residue Amount',
        render: (row) => (
          <div>{roundToTwo(row['Total Auto Pay Residue Amount'])}</div>
        ),
      },
    ];
    const downloadExcel = () => {
      const newData = this.props.location.state.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Sales Report');
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download

      this.props.location.state.summary === false
        ? XLSX.writeFile(workBook, 'Sales Report.xlsx')
        : XLSX.writeFile(workBook, 'Sales Report Summarized.xlsx');
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Finance"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <MaterialTable
              title={
                this.props.location.state.summary === false
                  ? 'Sales Report'
                  : 'Sales Report Summarized'
              }
              columns={
                this.props.location.state.summary === false
                  ? columns
                  : summaryColumns
              }
              data={this.props.location.state.data}
              actions={[
                {
                  icon: () => <Button>Export All</Button>, // you can pass icon too
                  tooltip: 'Export to Excel',
                  onClick: () => downloadExcel(),
                  isFreeAction: true,
                },
              ]}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                exportButton: {
                  csv: true,
                  pdf: false,
                },
                exportPDF: {
                  headerStyle: {
                    whiteSpace: 'nowrap',
                  },
                },
                exportFileName:
                  this.props.location.state.summary === false
                    ? 'Sales Report'
                    : 'Sales Report Summarized',
                pageSize: 20,
                columnsButton: true,
              }}
              localization={{
                toolbar: {
                  exportCSVName: 'Export as CSV',
                },
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SalesReport;

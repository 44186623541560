import React, { Component } from 'react';
import Switch from '@mui/material/Switch';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  Card,
  CardBody,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getGroupName, getToken, GKApi } from '../../components/config';
import { deviceInfo } from '../../helpers/device_info_helper';

// Table data

class AppVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Admin', link: '#' },
        { title: 'App Version', link: '#' },
      ],
      isLoading: true,
      deviceInfo: {},
      version: '',
      isCritical: false,
      title: '',
      description: '',
      canAuthAppVersionInput: 1,
      isSuper:false,
    };
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  handleAppVersionChange = (e) => {
    this.setState({ version: e.target.value });
  };

  handleIsCriticalChange = (e) => {
    this.setState({ isCritical: e.target.checked });
  };

  handleTitleChange = (e) => {
    this.setState({ title: e.target.value });
  };
  handleDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  };

  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
       if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    // GET request using axios with async/await
    const token = getToken(); // getting token for api call

    const getPermission = await GKApi.get(`/role/permission?cmpId=40`, {
      headers: {
        Authorization: token,
      },
    });

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    // setting permissions to their states of their own

    this.setState({
      canAuthAppVersionInput: permissionData[40].canAuth,
    });

    try {
      // the api call
      const response = await GKApi.get('/role/app-version', {
        headers: {
          Authorization: token,
          cmpId: 40,
        },
      });

      this.setState({
        isCritical: response.data.isCritical === 'False' ? false : true,
        title: response.data.title,
        version: response.data.version,
        description: response.data.description,
      });

      const res = deviceInfo();
      this.setState({ deviceInfo: res }, () => { });
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isSuper===true?<div className="container-fluid">
            <Breadcrumbs
              title="App Version Control"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={7}>
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Label
                        htmlFor="app-version"
                        className="col-md-3 col-form-label"
                      >
                        Version
                      </Label>
                      <Col md={5} className="d-flex gap-5">
                        <Input
                          value={this.state.version}
                          placeholder="Please input the value for App Version"
                          id="app-version-input"
                          onChange={(e) => {
                            this.handleAppVersionChange(e);
                          }}
                          style={
                            {
                              // backgroundColor:
                              //   this.state.dailyGoldPriceChange === true
                              //     ? '#D3D3D3'
                              //     : '',
                            }
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="title"
                        className="col-md-3 col-form-label"
                      >
                        Title
                      </Label>
                      <Col md={5} className="d-flex gap-5">
                        <Input
                          value={this.state.title}
                          placeholder="Please input the title"
                          id="title"
                          onChange={(e) => {
                            this.handleTitleChange(e);
                          }}
                          style={
                            {
                              // backgroundColor:
                              //   this.state.dailyGoldPriceChange === true
                              //     ? '#D3D3D3'
                              //     : '',
                            }
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="description"
                        className="col-md-3 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={5} className="d-flex gap-5">
                        <Input
                          value={this.state.description}
                          placeholder="Please input description"
                          id="description-input"
                          onChange={(e) => {
                            this.handleDescriptionChange(e);
                          }}
                          style={
                            {
                              // backgroundColor:
                              //   this.state.dailyGoldPriceChange === true
                              //     ? '#D3D3D3'
                              //     : '',
                            }
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="criticality"
                        className="col-md-3 col-form-label"
                      >
                        Critical Version
                      </Label>
                      <Col md={5} className="d-flex gap-5">
                        <Switch
                          checked={this.state.isCritical}
                          onChange={(e) => {
                            this.handleIsCriticalChange(e);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Col>
                    </Row>
                    <Button
                      onClick={() => {
                        this.setState({ modal_standard: true });
                      }}
                    >
                      Edit App Version
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* UPDATE USER GROUP */}
            <Modal
              size="xl"
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_standard: false })}
              >
                Confirmation
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col>
                    <p>Are you sure you want to update the App Version?</p>
                  </Col>
                </Row>
              </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  this.tog_standard();
                }}
              >
                No
              </Button>
              <Button
                onClick={async (event) => {
                  event.preventDefault();
                  const token = getToken();
                  let sendData = {
                    cmpId: 40,
                    version: this.state.version,
                    isCritical: this.state.isCritical,
                    title: this.state.title,
                    description: this.state.description,
                    deviceInfo: this.state.deviceInfo,
                  };

                  await GKApi.post(
                    '/role/app-version',
                    sendData,
                    {
                      headers: {
                        Authorization: token,
                      },
                    }
                  );


                  this.tog_standard();
                }}
              >
                Confirm
              </Button>
            </ModalFooter>
            </Modal>
          </div>:<h5>Sorry you don't have permission to view this.</h5>}
        </div>
      </React.Fragment>
    );
  }
}

export default AppVersion;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  NavLink,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getToken, GKApi } from '../../../components/config';
import { Add, Delete } from '@material-ui/icons';

// Table data

class Presets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadAudienceData: [],
      visible: false,
      modal_standard: false,
      presetData: [],
      user: 0,
      delete_modal: false,
      enablePreset: false,
      presetDetails: {},
      canDeletePreset: 0,
      activeTabJustify: '1',
      disabledPresetList: [],
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_DeleteModal = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
    this.removeBodyCss();
  };

  tog_EnableModal = () => {
    this.setState((prevState) => ({
      enablePreset: !prevState.enablePreset,
    }));
    this.removeBodyCss();
  };
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }
  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };
  async componentDidMount() {
    const token = getToken();
    const getDownloadAudience = await GKApi.get(
      '/marketing/download-audience',
      {
        headers: {
          Authorization: token,
        },
      }
    );
    this.setState({
      downloadAudienceData: getDownloadAudience.data,
    });

    const getPermission = await GKApi.get(`/role/permission?cmpId=25`, {
      headers: {
        Authorization: token,
      },
    });
    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    this.setState({
      canDeletePreset: permissionData[25].canDelete,
    });
  }

  render() {
    const columns = [
      {
        field: 'id',
        title: 'Preset id',
      },
      {
        field: 'name',
        title: 'Preset Name',
      },
    ];

    return (
      <React.Fragment>
        <Nav tabs className="nav-tabs-custom nav-justified">
          {/*Profile Info Tab  */}
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: this.state.activeTabJustify === '1',
              })}
              onClick={async () => {
                const token = getToken();
                const getDownloadAudience = await GKApi.get(
                  '/marketing/download-audience',
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                );
                this.setState({
                  downloadAudienceData: getDownloadAudience.data,
                });
                this.toggleCustomJustified('1');
              }}
            >
              <span className="d-none d-sm-block fw-bold">Enabled Preset</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: this.state.activeTabJustify === '2',
              })}
              onClick={async () => {
                const token = getToken();
                const getDownloadAudience = await GKApi.get(
                  '/marketing/download-audience?status=0',
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                );
                this.setState({
                  disabledPresetList: getDownloadAudience.data,
                });

                this.toggleCustomJustified('2');
              }}
            >
              <span className="d-none d-sm-block fw-bold">Disabled Preset</span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTabJustify}>
          <TabPane tabId="1" className="p-3">
            <MaterialTable
              title="Download Audience Enabled Presets"
              columns={columns}
              data={this.state.downloadAudienceData}
              actions={[
                (rowData) => ({
                  icon: VisibilityIcon,
                  tooltip: 'Click to View Preset Details',
                  onClick: async (event, row) => {
                    this.setState({ presetDetails: rowData });
                    const token = getToken();
                    const getAudienceData = await GKApi.get(
                      `/marketing/download-audience?id=${rowData.id}`,

                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    if (getAudienceData?.data?.jsonData) {
                      this.setState({
                        presetData: JSON.parse(getAudienceData?.data?.jsonData),
                        user: getAudienceData.data.total_user,
                        modal_standard: true,
                      });
                    }
                  },
                }),

                (rowData) =>
                  this.state.canDeletePreset === 1 && {
                    icon: Delete,
                    tooltip: 'Delete Preset',
                    onClick: (event) => {
                      this.setState({
                        delete_modal: true,
                        presetId: rowData.id,
                      });
                    },
                  },
              ]}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                actionsColumnIndex: -1,
                exportFileName: 'User',
                pageSize: 20,
                columnsButton: true,
              }}
              localization={{
                toolbar: {},
              }}
            />
          </TabPane>
          <TabPane tabId="2" className="p-3">
            <MaterialTable
              title="Download Audience Disabled Presets"
              columns={columns}
              data={this.state.disabledPresetList}
              actions={[
                (rowData) => ({
                  icon: VisibilityIcon,
                  tooltip: 'Click to View Preset Details',
                  onClick: async (event, row) => {
                    this.setState({ presetDetails: rowData });
                    const token = getToken();
                    const getAudienceData = await GKApi.get(
                      `/marketing/download-audience?id=${rowData.id}&status=0`,

                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );


                    if (getAudienceData?.data?.jsonData) {
                      this.setState({
                        presetData: JSON.parse(getAudienceData?.data?.jsonData),
                        user: getAudienceData.data.total_user,
                        modal_standard: true,
                      });
                    }
                  },
                }),

                (rowData) =>
                  this.state.canDeletePreset === 1 && {
                    icon: Add,
                    tooltip: 'Delete Preset',
                    onClick: (event) => {
                      this.setState({
                        enablePreset: true,
                        presetId: rowData.id,
                      });
                    },
                  },
              ]}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                actionsColumnIndex: -1,
                exportFileName: 'User',
                pageSize: 20,
                columnsButton: true,
              }}
              localization={{
                toolbar: {},
              }}
            />
          </TabPane>
        </TabContent>

        <Modal isOpen={this.state.modal_standard} toggle={this.tog_standard}>
          <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
            Preset {this.state.presetDetails.name} details
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
              {this.state.presetData.length > 0 &&
                this.state.presetData.map((pd) => (
                  <Row>
                    <Col xl={5}>{pd.displayName}</Col>
                    <Col xl={7}>
                      {pd.operator === 'range' ? (
                        <div className="d-flex gap-2">
                          <p>From</p>
                          <p>{pd.data.from}</p>
                          <p>To</p>
                          <p>{pd.data.to}</p>
                        </div>
                      ) : pd.operator === 'equal' ? (
                        <div className="d-flex gap-2">
                          <p>=</p>
                          <p>{pd.data.value}</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                ))}
              <div className="fw-bold d-flex gap-2 font-size-18 py-2">
                <p>Potential user reach:</p>
                <p>{this.state.user}</p>
              </div>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={this.tog_standard}
              color="light"
              className="waves-effect"
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.delete_modal} toggle={this.tog_DeleteModal}>
          <ModalHeader toggle={() => this.setState({ delete_modal: false })}>
            Preset Delete Confirmation
          </ModalHeader>
          <ModalBody>
            {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

            <Row className="mb-3">
              Are you sure you want to delete this preset?
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={async () => {
                const token = getToken();
                await GKApi.patch(
                  `/marketing/download-audience`,
                  {
                    id: this.state.presetId,
                    status: '0', // "0"
                  },
                  {
                    headers: {
                      Authorization: token,
                      cmpId: 25,
                    },
                  }
                );

                const getDownloadAudience = await GKApi.get(
                  '/marketing/download-audience',
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                );
                this.setState({
                  downloadAudienceData: getDownloadAudience.data,
                });
                this.tog_DeleteModal();
              }}
              color="light"
              className="waves-effect"
            >
              Confirm
            </Button>
            <Button
              type="button"
              onClick={this.tog_DeleteModal}
              color="light"
              className="waves-effect"
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.enablePreset} toggle={this.tog_EnableModal}>
          <ModalHeader toggle={() => this.setState({ enablePreset: false })}>
            Preset Enable Confirmation
          </ModalHeader>
          <ModalBody>
            {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

            <Row className="mb-3">
              Are you sure you want to Enable this preset?
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={async () => {
                const token = getToken();
                await GKApi.patch(
                  `/marketing/download-audience`,
                  {
                    id: this.state.presetId,
                    status: '1', // "0"
                  },
                  {
                    headers: {
                      Authorization: token,
                      cmpId: 25,
                    },
                  }
                );

                const getDownloadAudience = await GKApi.get(
                  '/marketing/download-audience?status=0',
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                );
                this.setState({
                  disabledPresetList: getDownloadAudience.data,
                });
                this.tog_EnableModal();
              }}
              color="light"
              className="waves-effect"
            >
              Confirm
            </Button>
            <Button
              type="button"
              onClick={this.tog_EnableModal}
              color="light"
              className="waves-effect"
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Presets;

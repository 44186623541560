import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  // Button,
  // ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import './dashboard.scss';
import classnames from 'classnames';
import SalesAnalyticsTrxCount from './SalesAnalyticsTrxCount';
import SalesAnalyticsTrxAmount from './SalesAnalyticsTrxAmount';
import { getToken, GKApi } from '../../../components/config';
import moment from 'moment';

class TabSalesAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analyticsMenu: false,
      month: 'Select month',
      monthlyLabels: [],
      activeTab: '1',
      analyticsData: {},
      analyticsDetails: {},
      count:0
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();

    const monthlyLabels = [];

    const response = await GKApi.get('/marketing/trx-analytics', {
      headers: {
        Authorization: token,
        cmpId: 4,
      },
    });

    this.setState(
      {
        analyticsData: response.data.data,
      },
      () => {}
    );
    let tempAnalyticsDetails = {};
    for (const obj of response.data.data) {
      monthlyLabels.push(moment(obj.date).format('MMM YY'));
      tempAnalyticsDetails[moment(obj.date).format('MMM YY')] = obj;
    }


    this.setState(
      {
        analyticsData: tempAnalyticsDetails,
      },
      () => {}
    );
    this.setState({ monthlyLabels: monthlyLabels }, () => {});
    
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody className="shadow-lg">
            <div className="float-end d-none d-md-inline-block">
              <Dropdown
                isOpen={this.state.analyticsMenu}
                toggle={() =>
                  this.setState(
                    {
                      analyticsMenu: !this.state.analyticsMenu,
                    },
                    () => {}
                  )
                }
              >
                <DropdownToggle color="light" caret>
                  {this.state.month} <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {this.state.monthlyLabels.map((ms, index) => (
                    <DropdownItem
                      key={index}
                      onClick={async () => {
                        this.setState(
                          {
                            month: ms,
                            analyticsDetails: this.state.analyticsData[ms],
                            count:this.state.count+1
                          },
                          () => {}
                        );
                       
                      }}
                    >
                      {ms}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            <h4 className="card-title mb-4 fw-bold">Transaction Analytics</h4>

            {this.state.month === 'Select month' ? (
              ''
            ) : (
              <div>
                <Row>
                  <Col xl={6}>
                    <div>
                      <p className="fw-bold">Total TRX Amount</p>

                      <h4 className="fw-bold">
                        {this.state.analyticsDetails?.totalTrxAmount} bdt
                      </h4>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div>
                      <p className="fw-bold">Total TRX Count</p>
                      <h4 className="fw-bold">
                        {this.state.analyticsDetails?.totalTrxCount}{' '}
                      </h4>
                    </div>
                  </Col>
                </Row>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTab === '1',
                      })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      TRX Amount
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTab === '2',
                      })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      TRX Count
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1" className="p-3">
                    <Row>
                      <Col sm="12">
                        <SalesAnalyticsTrxAmount
                          buyAmountPercentage={
                            this.state.analyticsDetails.buyAmountPercentage
                          }
                          sellAmountPercentage={
                            this.state.analyticsDetails.sellAmountPercentage
                          }
                          collectAmountPercentage={
                            this.state.analyticsDetails.collectAmountPercentage
                          }
                          giftAmountPercentage={
                            this.state.analyticsDetails.giftAmountPercentage
                          }
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2" className="p-3">
                    <Row>
                      <Col sm="12">
                        <SalesAnalyticsTrxCount
                          buyCountPercentage={
                            this.state.analyticsDetails.buyCountPercentage
                          }
                          sellCountPercentage={
                            this.state.analyticsDetails.sellCountPercentage
                          }
                          collectCountPercentage={
                            this.state.analyticsDetails.collectCountPercentage
                          }
                          giftCountPercentage={
                            this.state.analyticsDetails.giftCountPercentage
                          }
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default TabSalesAnalytics;

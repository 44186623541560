import moment from 'moment';
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, Row, Col, Button, ButtonGroup } from 'reactstrap';
import { getToken, GKApi, roundToTwo } from '../../../components/config';
import DatePicker from '../../../components/Common/DatePicker';

class AverageTicketSize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: 'Average Ticket Size',
          type: 'column',
          data: [],
        },
        {
          name: 'Number of Transactions',
          type: 'line',
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4],
          curve: 'smooth',
        },
        title: {
          text: '',
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [],
        // xaxis: {
        //   type: 'datetime',
        // },
        yaxis: [
          {
            title: {
              text: 'Average ticket Size',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Number Of Transactions',
            },
          },
        ],
      },
      display: 'daily',
      selectedStartDate: null,
      selectedEndDate: null,
      trxType: 'Buy',
    };
  }

  handleSelectedStartDateChange = (event) => {
    this.setState({ selectedStartDate: event });
  };

  handleSelectedEndDateChange = (event) => {
    this.setState({ selectedEndDate: event });
  };

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();
    const averageTicketData = [];
    const totalTrxData = [];
    const labelData = [];
    const startDate = moment(new Date()).format('YYYY/MM/DD');
    const endDate = moment(new Date()).format('YYYY/MM/DD');

    const response = await GKApi.get(
      `/marketing/average-ticket-size?startDate=${startDate}&endDate=${endDate}&type=${this.state.display}&trxType=${this.state.trxType}`,
      {
        headers: {
          Authorization: token,
          cmpId: 2,
        },
      }
    );

    for (const obj of response.data.data) {
      averageTicketData.push(roundToTwo(obj.averageTicketSize));
      totalTrxData.push(roundToTwo(obj.totalTrxCount));
      labelData.push(moment(obj.hour, ['HH']).format('hh A'));
    }

    this.setState({
      series: [
        {
          name: 'Average Ticket Size',
          type: 'column',
          data: averageTicketData,
        },
        {
          name: 'Total Transactions Count',
          type: 'line',
          data: totalTrxData,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4],
          curve: 'smooth',
        },
        title: {
          text: '',
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: labelData,
        // xaxis: {
        //   type: 'datetime',
        // },
        yaxis: [
          {
            title: {
              text: 'Average Ticket Size',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Number Of Transactions',
            },
          },
        ],
      },
    });
  }

  render() {
    return (
      <div id="chart">
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody className="shadow-lg">
                <Row>
                  <Col xl={4}>
                    <h6 className="mb-4 fw-bold">
                      Average Ticket Size App Performance
                    </h6>
                  </Col>
                  <Col xl={4}>
                    <div className="float-end d-none d-md-inline-block">
                      <ButtonGroup className="mb-2">
                        <Button
                          size="sm"
                          color={
                            this.state.trxType === 'Buy' ? 'secondary' : 'light'
                          }
                          type="button"
                          onClick={async () => {
                            this.setState(
                              {
                                ...this.state,
                                trxType: 'Buy',
                                display: 'daily',
                              },
                              () => {}
                            );
                            const token = getToken();
                            const averageTicketData = [];
                            const totalTrxData = [];
                            const labelData = [];
                            const startDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );
                            const endDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );

                            const response = await GKApi.get(
                              `/marketing/average-ticket-size?startDate=${startDate}&endDate=${endDate}&type=daily&trxType=Buy`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 2,
                                },
                              }
                            );

                            for (const obj of response.data.data) {
                              averageTicketData.push(
                                roundToTwo(obj.averageTicketSize)
                              );
                              totalTrxData.push(roundToTwo(obj.totalTrxCount));
                              labelData.push(
                                moment(obj.hour, ['HH']).format('hh A')
                              );
                            }

                            this.setState({
                              series: [
                                {
                                  name: 'Average Ticket Size',
                                  type: 'column',
                                  data: averageTicketData,
                                },
                                {
                                  name: 'Total Transactions Count',
                                  type: 'line',
                                  data: totalTrxData,
                                },
                              ],
                              options: {
                                chart: {
                                  height: 350,
                                  type: 'line',
                                },
                                stroke: {
                                  width: [0, 4],
                                  curve: 'smooth',
                                },
                                title: {
                                  text: '',
                                },
                                dataLabels: {
                                  enabled: true,
                                  enabledOnSeries: [1],
                                },
                                labels: labelData,
                                // xaxis: {
                                //   type: 'datetime',
                                // },
                                yaxis: [
                                  {
                                    title: {
                                      text: 'Average Ticket Size',
                                    },
                                  },
                                  {
                                    opposite: true,
                                    title: {
                                      text: 'Number Of Transactions',
                                    },
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          Buy
                        </Button>
                        <Button
                          size="sm"
                          color={
                            this.state.trxType === 'Sell'
                              ? 'secondary'
                              : 'light'
                          }
                          type="button"
                          onClick={async () => {
                            this.setState(
                              {
                                ...this.state,
                                trxType: 'Sell',
                                display: 'daily',
                              },
                              () => {}
                            );

                            const token = getToken();
                            const averageTicketData = [];
                            const totalTrxData = [];
                            const labelData = [];
                            const startDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );
                            const endDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );

                            const response = await GKApi.get(
                              `/marketing/average-ticket-size?startDate=${startDate}&endDate=${endDate}&type=daily&trxType=Sell`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 2,
                                },
                              }
                            );

                            for (const obj of response.data.data) {
                              averageTicketData.push(
                                roundToTwo(obj.averageTicketSize)
                              );
                              totalTrxData.push(roundToTwo(obj.totalTrxCount));
                              labelData.push(
                                moment(obj.hour, ['HH']).format('hh A')
                              );
                            }

                            this.setState({
                              series: [
                                {
                                  name: 'Average Ticket Size',
                                  type: 'column',
                                  data: averageTicketData,
                                },
                                {
                                  name: 'Total Transactions Count',
                                  type: 'line',
                                  data: totalTrxData,
                                },
                              ],
                              options: {
                                chart: {
                                  height: 350,
                                  type: 'line',
                                },
                                stroke: {
                                  width: [0, 4],
                                  curve: 'smooth',
                                },
                                title: {
                                  text: '',
                                },
                                dataLabels: {
                                  enabled: true,
                                  enabledOnSeries: [1],
                                },
                                labels: labelData,
                                // xaxis: {
                                //   type: 'datetime',
                                // },
                                yaxis: [
                                  {
                                    title: {
                                      text: 'Average Ticket Size',
                                    },
                                  },
                                  {
                                    opposite: true,
                                    title: {
                                      text: 'Number Of Transactions',
                                    },
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          Sell
                        </Button>
                        <Button
                          size="sm"
                          color={
                            this.state.trxType === 'Collect'
                              ? 'secondary'
                              : 'light'
                          }
                          type="button"
                          onClick={async () => {
                            this.setState(
                              {
                                ...this.state,
                                trxType: 'Collect',
                                display: 'daily',
                              },
                              () => {}
                            );

                            const token = getToken();
                            const averageTicketData = [];
                            const totalTrxData = [];
                            const labelData = [];
                            const startDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );
                            const endDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );

                            const response = await GKApi.get(
                              `/marketing/average-ticket-size?startDate=${startDate}&endDate=${endDate}&type=daily&trxType=Collect`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 2,
                                },
                              }
                            );

                            for (const obj of response.data.data) {
                              averageTicketData.push(
                                roundToTwo(obj.averageTicketSize)
                              );
                              totalTrxData.push(roundToTwo(obj.totalTrxCount));
                              labelData.push(
                                moment(obj.hour, ['HH']).format('hh A')
                              );
                            }

                            this.setState({
                              series: [
                                {
                                  name: 'Average Ticket Size',
                                  type: 'column',
                                  data: averageTicketData,
                                },
                                {
                                  name: 'Total Transactions Count',
                                  type: 'line',
                                  data: totalTrxData,
                                },
                              ],
                              options: {
                                chart: {
                                  height: 350,
                                  type: 'line',
                                },
                                stroke: {
                                  width: [0, 4],
                                  curve: 'smooth',
                                },
                                title: {
                                  text: '',
                                },
                                dataLabels: {
                                  enabled: true,
                                  enabledOnSeries: [1],
                                },
                                labels: labelData,
                                // xaxis: {
                                //   type: 'datetime',
                                // },
                                yaxis: [
                                  {
                                    title: {
                                      text: 'Average Ticket Size',
                                    },
                                  },
                                  {
                                    opposite: true,
                                    title: {
                                      text: 'Number Of Transactions',
                                    },
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          Collect
                        </Button>
                        <Button
                          size="sm"
                          color={
                            this.state.trxType === 'Gift'
                              ? 'secondary'
                              : 'light'
                          }
                          type="button"
                          onClick={async () => {
                            this.setState(
                              {
                                ...this.state,
                                trxType: 'Gift',
                                display: 'daily',
                              },
                              () => {}
                            );

                            const token = getToken();
                            const averageTicketData = [];
                            const totalTrxData = [];
                            const labelData = [];
                            const startDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );
                            const endDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );

                            const response = await GKApi.get(
                              `/marketing/average-ticket-size?startDate=${startDate}&endDate=${endDate}&type=daily&trxType=Gift`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 2,
                                },
                              }
                            );

                            for (const obj of response.data.data) {
                              averageTicketData.push(
                                roundToTwo(obj.averageTicketSize)
                              );
                              totalTrxData.push(roundToTwo(obj.totalTrxCount));
                              labelData.push(
                                moment(obj.hour, ['HH']).format('hh A')
                              );
                            }

                            this.setState({
                              series: [
                                {
                                  name: 'Average Ticket Size',
                                  type: 'column',
                                  data: averageTicketData,
                                },
                                {
                                  name: 'Total Transactions Count',
                                  type: 'line',
                                  data: totalTrxData,
                                },
                              ],
                              options: {
                                chart: {
                                  height: 350,
                                  type: 'line',
                                },
                                stroke: {
                                  width: [0, 4],
                                  curve: 'smooth',
                                },
                                title: {
                                  text: '',
                                },
                                dataLabels: {
                                  enabled: true,
                                  enabledOnSeries: [1],
                                },
                                labels: labelData,
                                // xaxis: {
                                //   type: 'datetime',
                                // },
                                yaxis: [
                                  {
                                    title: {
                                      text: 'Average Ticket Size',
                                    },
                                  },
                                  {
                                    opposite: true,
                                    title: {
                                      text: 'Number Of Transactions',
                                    },
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          Gift
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="float-end d-none d-md-inline-block">
                      <ButtonGroup className="mb-2">
                        <Button
                          size="sm"
                          color={
                            this.state.display === 'daily'
                              ? 'secondary'
                              : 'light'
                          }
                          type="button"
                          onClick={async () => {
                            this.setState(
                              {
                                ...this.state,
                                display: 'daily',
                              },
                              () => {}
                            );
                            const token = getToken();
                            const averageTicketData = [];
                            const totalTrxData = [];
                            const labelData = [];
                            const startDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );
                            const endDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );

                            const response = await GKApi.get(
                              `/marketing/average-ticket-size?startDate=${startDate}&endDate=${endDate}&type=daily&trxType=${this.state.trxType}`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 2,
                                },
                              }
                            );

                            for (const obj of response.data.data) {
                              averageTicketData.push(
                                roundToTwo(obj.averageTicketSize)
                              );
                              totalTrxData.push(roundToTwo(obj.totalTrxCount));
                              labelData.push(
                                moment(obj.hour, ['HH']).format('hh A')
                              );
                            }

                            this.setState({
                              series: [
                                {
                                  name: 'Average Ticket Size',
                                  type: 'column',
                                  data: averageTicketData,
                                },
                                {
                                  name: 'Total Transactions Count',
                                  type: 'line',
                                  data: totalTrxData,
                                },
                              ],
                              options: {
                                chart: {
                                  height: 350,
                                  type: 'line',
                                },
                                stroke: {
                                  width: [0, 4],
                                  curve: 'smooth',
                                },
                                title: {
                                  text: '',
                                },
                                dataLabels: {
                                  enabled: true,
                                  enabledOnSeries: [1],
                                },
                                labels: labelData,
                                // xaxis: {
                                //   type: 'datetime',
                                // },
                                yaxis: [
                                  {
                                    title: {
                                      text: 'Average Ticket Size',
                                    },
                                  },
                                  {
                                    opposite: true,
                                    title: {
                                      text: 'Number Of Transactions',
                                    },
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          Daily
                        </Button>
                        <Button
                          size="sm"
                          color={
                            this.state.display === 'weekly'
                              ? 'secondary'
                              : 'light'
                          }
                          type="button"
                          onClick={async () => {
                            this.setState(
                              {
                                ...this.state,
                                display: 'weekly',
                              },
                              () => {}
                            );

                            const startDate = moment(new Date())
                              .subtract(6, 'days')
                              .format('YYYY/MM/DD');
                            const endDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );
                            const token = getToken();
                            const averageTicketData = [];
                            const totalTrxData = [];
                            const labelData = [];

                            const response = await GKApi.get(
                              `/marketing/average-ticket-size?startDate=${startDate}&endDate=${endDate}&type=weekly&trxType=${this.state.trxType}`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 2,
                                },
                              }
                            );

                            for (const obj of response.data.data) {
                              averageTicketData.push(
                                roundToTwo(obj.averageTicketSize)
                              );
                              totalTrxData.push(roundToTwo(obj.totalTrxCount));
                              labelData.push(moment(obj.date).format('MMM Do'));
                            }

                            this.setState({
                              series: [
                                {
                                  name: 'Average Ticket Size',
                                  type: 'column',
                                  data: averageTicketData,
                                },
                                {
                                  name: 'Total Transactions Count',
                                  type: 'line',
                                  data: totalTrxData,
                                },
                              ],
                              options: {
                                chart: {
                                  height: 350,
                                  type: 'line',
                                },
                                stroke: {
                                  width: [0, 4],
                                  curve: 'smooth',
                                },
                                title: {
                                  text: '',
                                },
                                dataLabels: {
                                  enabled: true,
                                  enabledOnSeries: [1],
                                },
                                labels: labelData,
                                // xaxis: {
                                //   type: 'datetime',
                                // },
                                yaxis: [
                                  {
                                    title: {
                                      text: 'Average Ticket Size',
                                    },
                                  },
                                  {
                                    opposite: true,
                                    title: {
                                      text: 'Number Of Transactions',
                                    },
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          Weekly
                        </Button>
                        <Button
                          size="sm"
                          color={
                            this.state.display === 'monthly'
                              ? 'secondary'
                              : 'light'
                          }
                          type="button"
                          onClick={async () => {
                            this.setState(
                              {
                                ...this.state,
                                display: 'monthly',
                              },
                              () => {}
                            );

                            const startDate = moment(new Date())
                              .subtract(29, 'days')
                              .format('YYYY/MM/DD');
                            const endDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );
                            const token = getToken();
                            const averageTicketData = [];
                            const totalTrxData = [];
                            const labelData = [];

                            const response = await GKApi.get(
                              `/marketing/average-ticket-size?startDate=${startDate}&endDate=${endDate}&type=monthly&trxType=${this.state.trxType}`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 2,
                                },
                              }
                            );

                            for (const obj of response.data.data) {
                              averageTicketData.push(
                                roundToTwo(obj.averageTicketSize)
                              );
                              totalTrxData.push(roundToTwo(obj.totalTrxCount));
                              labelData.push(moment(obj.date).format('MMM Do'));
                            }

                            this.setState({
                              series: [
                                {
                                  name: 'Average Ticket Size',
                                  type: 'column',
                                  data: averageTicketData,
                                },
                                {
                                  name: 'Total Transactions Count',
                                  type: 'line',
                                  data: totalTrxData,
                                },
                              ],
                              options: {
                                chart: {
                                  height: 350,
                                  type: 'line',
                                },
                                stroke: {
                                  width: [0, 4],
                                  curve: 'smooth',
                                },
                                title: {
                                  text: '',
                                },
                                dataLabels: {
                                  enabled: true,
                                  enabledOnSeries: [1],
                                },
                                labels: labelData,
                                // xaxis: {
                                //   type: 'datetime',
                                // },
                                yaxis: [
                                  {
                                    title: {
                                      text: 'Average Ticket Size',
                                    },
                                  },
                                  {
                                    opposite: true,
                                    title: {
                                      text: 'Number Of Transactions',
                                    },
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          Monthly
                        </Button>
                        <Button
                          size="sm"
                          color={
                            this.state.display === 'yearly'
                              ? 'secondary'
                              : 'light'
                          }
                          type="button"
                          onClick={async () => {
                            this.setState(
                              {
                                ...this.state,
                                display: 'yearly',
                              },
                              () => {}
                            );

                            const startDate = moment(new Date())
                              .subtract(365, 'days')
                              .format('YYYY/MM/DD');
                            const endDate = moment(new Date()).format(
                              'YYYY/MM/DD'
                            );
                            const token = getToken();
                            const averageTicketData = [];
                            const totalTrxData = [];
                            const labelData = [];

                            const response = await GKApi.get(
                              `/marketing/average-ticket-size?startDate=${startDate}&endDate=${endDate}&type=yearly&trxType=${this.state.trxType}`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 2,
                                },
                              }
                            );

                            for (const obj of response.data.data) {
                              averageTicketData.push(
                                roundToTwo(obj.averageTicketSize)
                              );
                              totalTrxData.push(roundToTwo(obj.totalTrxCount));
                              labelData.push(moment(obj.date).format('MMM'));
                            }

                            this.setState({
                              series: [
                                {
                                  name: 'Average Ticket Size',
                                  type: 'column',
                                  data: averageTicketData,
                                },
                                {
                                  name: 'Total Transactions Count',
                                  type: 'line',
                                  data: totalTrxData,
                                },
                              ],
                              options: {
                                chart: {
                                  height: 350,
                                  type: 'line',
                                },
                                stroke: {
                                  width: [0, 4],
                                  curve: 'smooth',
                                },
                                title: {
                                  text: '',
                                },
                                dataLabels: {
                                  enabled: true,
                                  enabledOnSeries: [1],
                                },
                                labels: labelData,
                                // xaxis: {
                                //   type: 'datetime',
                                // },
                                yaxis: [
                                  {
                                    title: {
                                      text: 'Average Ticket Size',
                                    },
                                  },
                                  {
                                    opposite: true,
                                    title: {
                                      text: 'Number Of Transactions',
                                    },
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          Yearly
                        </Button>
                        <Button
                          size="sm"
                          color={
                            this.state.display === 'custom'
                              ? 'secondary'
                              : 'light'
                          }
                          type="button"
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              display: 'custom',
                            })
                          }
                        >
                          Custom
                        </Button>
                      </ButtonGroup>
                      {this.state.display === 'custom' && (
                        <div className="d-flex gap-3">
                          <div
                            style={{
                              border: '1px solid',
                              borderRadius: '5px',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              paddingRight: '4px',
                              paddingLeft: '4px',
                            }}
                            className="d-flex gap-3 align-content-center"
                          >
                            <p
                              className="mt-1"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Start Date
                            </p>
                            <DatePicker
                              selectedDate={this.state.selectedStartDate}
                              handleSelectedDateChange={
                                this.handleSelectedStartDateChange
                              }
                            />
                          </div>
                          <div
                            style={{
                              border: '1px solid',
                              borderRadius: '5px',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              paddingRight: '5px',
                              paddingLeft: '5px',
                            }}
                            className="d-flex d-grid gap-3"
                          >
                            <p
                              className="mt-1"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              End Date
                            </p>
                            <DatePicker
                              selectedDate={this.state.selectedEndDate}
                              handleSelectedDateChange={
                                this.handleSelectedEndDateChange
                              }
                              className="mx-3"
                            />
                          </div>

                          <Button
                            onClick={async () => {
                              const token = getToken();
                              const averageTicketData = [];
                              const totalTrxData = [];
                              const labelData = [];

                              const response = await GKApi.get(
                                `/marketing/average-ticket-size?startDate=${this.state.selectedStartDate}&endDate=${this.state.selectedEndDate}&type=custom&trxType=${this.state.trxType}`,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 2,
                                  },
                                }
                              );

                              for (const obj of response.data.data) {
                                averageTicketData.push(
                                  roundToTwo(obj.averageTicketSize)
                                );
                                totalTrxData.push(
                                  roundToTwo(obj.totalTrxCount)
                                );
                                labelData.push(
                                  moment(obj.date).format('MMM Do')
                                );
                              }

                              this.setState({
                                series: [
                                  {
                                    name: 'Average Ticket Size',
                                    type: 'column',
                                    data: averageTicketData,
                                  },
                                  {
                                    name: 'Total Transactions Count',
                                    type: 'line',
                                    data: totalTrxData,
                                  },
                                ],
                                options: {
                                  chart: {
                                    height: 350,
                                    type: 'line',
                                  },
                                  stroke: {
                                    width: [0, 4],
                                    curve: 'smooth',
                                  },
                                  title: {
                                    text: '',
                                  },
                                  dataLabels: {
                                    enabled: true,
                                    enabledOnSeries: [1],
                                  },
                                  labels: labelData,
                                  // xaxis: {
                                  //   type: 'datetime',
                                  // },
                                  yaxis: [
                                    {
                                      title: {
                                        text: 'Average Ticket Size',
                                      },
                                    },
                                    {
                                      opposite: true,
                                      title: {
                                        text: 'Number Of Transactions',
                                      },
                                    },
                                  ],
                                },
                              });
                            }}
                          >
                            Generate Chart
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="line"
                  height={350}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AverageTicketSize;

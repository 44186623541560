import React, { Component } from 'react';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
  FormGroup,
  Form,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Edit } from '@material-ui/icons';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  capitalizeFirstLetter,
  getGroupName,
  getToken,
  getUser,
  GKApi,
} from '../../components/config';
import DatePickerComponent from '../../components/Common/DatePicker';
import { Auth } from 'aws-amplify';
import MaterialInput from '@material-ui/core/Input';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';

class AdminUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      breadcrumbItems: [
        { title: 'Tables', link: '#' },
        { title: 'Admin User Table', link: '#' },
      ],
      userData: [],
      // fullName: '',
      genderMenu: false,
      profileGenderMenu: false,
      email: '',
      name: '',
      dob: null,
      gender: 'Select Gender',
      designation: '',
      nid: '',
      phone: '',
      profileEmail: '',
      profileName: '',
      profileDob: null,
      profileGender: 'Select Gender',
      profileDesignation: '',
      profileNid: '',
      profilePhone: '',
      // phoneNumber: '',
      // role: null,
      visible: false,
      custom_div1: false,
      modal_standard: false,
      modal_standard2: false,
      modal_standard3: false,
      modal_standard4: false,
      adminUserData: [],
      key: null,
      groupName: '',
      presetName: '',
      errors: {
        profileNid: false,
        profileName: false,
        profilePhone: false,
      },
      presetData: [
        {
          master: 'Select Master',
          masterDropDown: false,
          branch: 'Select Branch',
          branchDropDown: false,
          component: 'Select Component',
          componentDropDown: false,
          componentId: null,
          role: 'Select Role',
          roleDropDown: false,
          roleId: null,
        },
      ],
      componentList: null,
      roleList: null,
      disableCreateGroupBtn: false,
      isSuper: false,
      forgetPasswordModal: false,
      resetPassAlert: false,
      resetPassFailed: false,
    };

    // binding the function calls
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_standard2 = this.tog_standard2.bind(this);
    this.tog_standard3 = this.tog_standard3.bind(this);
  }

  //modal closing options

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_standard2() {
    this.setState((prevState) => ({
      modal_standard2: !prevState.modal_standard2,
    }));
    this.removeBodyCss();
  }

  tog_standard3() {
    this.setState((prevState) => ({
      modal_standard3: !prevState.modal_standard3,
    }));
    this.removeBodyCss();
  }

  tog_standard4 = () => {
    this.setState((prevState) => ({
      modal_standard4: !prevState.modal_standard4,
    }));
    this.removeBodyCss();
  };

  tog_ForgetPasswordModal = () => {
    this.setState((prevState) => ({
      forgetPasswordModal: !prevState.forgetPasswordModal,
    }));
    this.removeBodyCss();
  };
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  // change handlers

  handleNameChange = (event) => {
    this.setState({ ...this.state, name: event.target.value });
  };

  handleEmailChange = (event) => {
    this.setState({ ...this.state, email: event.target.value });
  };

  handlePhoneChange = (event) => {
    this.setState({ ...this.state, phone: event.target.value });
  };

  handleDobChange = (event) => {
    this.setState({ ...this.state, dob: event });
  };

  handleGenderChange = (event) => {
    this.setState({ ...this.state, gender: event.target.value });
  };

  handleDesignationChange = (event) => {
    this.setState({ ...this.state, designation: event.target.value });
  };
  handleNidChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({ ...this.state, nid: event.target.value });
    }
  };

  handleProfileNameChange = (event) => {
    const pattern = new RegExp('^[-_ a-zA-Z]+$');
    this.setState({ profileName: event.target.value });
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        profileName: !pattern.test(event.target.value),
      },
    }));
  };

  handleProfileEmailChange = (event) => {
    this.setState({ ...this.state, profileEmail: event.target.value });
  };

  handleProfilePhoneChange = (event) => {
    const pattern = new RegExp('^01[3-9]{1}[0-9]{8}$');
    this.setState({ profilePhone: event.target.value });
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        profilePhone: !pattern.test(event.target.value),
      },
    }));
  };

  handleProfileDobChange = (event) => {
    this.setState({ ...this.state, profileDob: event });
  };

  handleProfileGenderChange = (event) => {
    this.setState({ ...this.state, profileGender: event.target.value });
  };

  handleProfileDesignationChange = (event) => {
    this.setState({ ...this.state, profileDesignation: event.target.value });
  };
  handleProfileNidChange = (event) => {
    const pattern = new RegExp('^([0-9]{10})$|^([0-9]{13})$|^([0-9]{17})$');
    this.setState({ profileNid: event.target.value });
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        profileNid: !pattern.test(event.target.value),
      },
    }));
  };

  handleRoleChange = (role) => {
    this.setState({ role });
  };

  handleGroupnameChange = (event) => {
    this.setState({ ...this.state, groupName: event.target.value });
  };

  //fetch user list call

  async componentDidMount() {
    // GET request using axios with async/await
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    const token = getToken(); // getting token for api call
    this.setState({ isLoading: true });
    try {
      // the api call
      const response = await GKApi.get('/staff?pageNumber=1&pageSize=1000', {
        headers: {
          Authorization: token,
        },
      });

      // the api call
      const userGroupResponse = await GKApi.get('/role/assign', {
        headers: {
          Authorization: token,
        },
      });

      let tempAdminUserData = response.data.data;
      for (let i = 0; i < tempAdminUserData.length; i++) {
        const user = tempAdminUserData[i];
        let tempRole = [];
        userGroupResponse.data.forEach((item) => {
          if (item.staffId === user.userId) {
            tempRole.push(item.groupName);
          }
        });
        user.role = tempRole.join(', ');
      }

      // the api call
      const preset_group_list_response = await GKApi.get('/role/components', {
        headers: {
          Authorization: token,
        },
      });
      const role_list_response = await GKApi.get('/role/roles', {
        headers: {
          Authorization: token,
        },
      });
      this.setState({
        adminUserData: tempAdminUserData,
        componentList: preset_group_list_response.data,
        roleList: role_list_response.data,
      });
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // create admin user call

  handleSubmit = async (event) => {
    event.preventDefault();
    const token = getToken();
    let sendData = {
      email: this.state.email,
      name: this.state.name,
      dob: moment(this.state.dob).format('DD/MM/YYYY'),
      gender: this.state.gender,
      designation: this.state.designation,
      nid: this.state.nid,
      phone: this.state.phone,
    };

    await GKApi.post('/staff', sendData, {
      headers: {
        Authorization: token,
      },
    });
    // if (submitData) {
    //   this.setState({
    //     ...this.state,
    //     // success: 'Finance Inputs Updated Successfully',
    //     custom_div1: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({ custom_div1: false });
    //   }, 5000);
    // }
    // the api call
    const response = await GKApi.get('/staff?pageNumber=1&pageSize=1000', {
      headers: {
        Authorization: token,
      },
    });

    // the api call
    const userGroupResponse = await GKApi.get('/role/assign', {
      headers: {
        Authorization: token,
      },
    });

    let tempAdminUserData = response.data.data;
    for (let i = 0; i < tempAdminUserData.length; i++) {
      const user = tempAdminUserData[i];
      let tempRole = [];
      userGroupResponse.data.forEach((item) => {
        if (item.staffId === user.userId) {
          tempRole.push(item.groupName);
        }
      });
      user.role = tempRole.join(', ');
    }

    this.setState({ adminUserData: tempAdminUserData });

    this.tog_standard3();
  };

  render() {
    if (
      this.state.presetData[0].master !== 'Select Master' &&
      this.state.presetData[0].branch !== 'Select Branch'
    );
    const columns = [
      {
        field: 'email',
        title: 'Email',
      },
      {
        field: 'name',
        title: 'Name',
      },
      {
        field: 'dob',
        title: 'Date of Birth',

        //formatting the date of birth of the admin user
        render: (row) => (
          <div>
            {row.dob ? moment(row.dob).format('DD-MM-YYYY') : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'designation',
        title: 'Designation',
      },
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'role',
        title: 'Group(s)',

        //formatting the date of birth of the admin user
        render: (row) => <div>{row.role ? row.role : 'Not in any group'}</div>,
      },
    ];

    // excel download option for the user list

    const downloadExcel = () => {
      const newData = this.state.adminUserData.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Admin Users');
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(workBook, 'Admin Users.xlsx');
    };
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isSuper === true ? (
            <div className="container-fluid">
              <Breadcrumbs
                title="Admin User Table"
                breadcrumbItems={this.state.breadcrumbItems}
              />
              <div id="40">
                <MaterialTable
                  title={'List of Admin Users'}
                  columns={columns}
                  data={this.state.adminUserData}
                  actions={[
                    {
                      icon: Edit,
                      tooltip: 'Click to edit user data',
                      onClick: (event, row) => {
                        this.setState({
                          modal_standard: true,
                          profileName: row.name,
                          profileEmail: row.email,
                          profileDob: row.dob,
                          profileDesignation: row.designation,
                          profileNid: row.nid,
                          profilePhone: row.phone,
                          profileGender: row.gender,
                        });
                        this.setState({ key: row });
                      },
                    },
                    (rowData) => ({
                      icon: () => <i className="fas fa-user-alt px-1" />,
                      tooltip: 'Click to Enable user',
                      hidden:
                        rowData.active === 1 || rowData.userId === getUser(),
                      onClick: async (event, row) => {
                        this.setState({ modal_standard2: true, key: row });
                      },
                    }),
                    (rowData) => ({
                      icon: () => <i className="fas fa-user-slash" />,
                      tooltip: 'Click to Disable user',
                      hidden:
                        rowData.active === 0 ||
                        rowData.active === null ||
                        rowData.userId === getUser(),
                      onClick: async (event, row) => {
                        this.setState({ modal_standard2: true, key: row });
                      },
                    }),
                    {
                      icon: () => <Button>Export All</Button>, // you can pass icon too
                      tooltip: 'Export to Excel',
                      onClick: () => downloadExcel(),
                      isFreeAction: true,
                    },
                    {
                      icon: () => <Button>Add User</Button>, // you can pass icon too
                      tooltip: 'Add a new Admin User',
                      onClick: () => this.setState({ modal_standard3: true }),
                      isFreeAction: true,
                    },
                    {
                      icon: () => <Button>Create User Group</Button>, // you can pass icon too
                      tooltip: 'Add a new User Group',
                      onClick: () => this.setState({ modal_standard4: true }),
                      isFreeAction: true,
                    },
                  ]}
                  options={{
                    //cell style for the table
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'left',
                    },

                    //style for the header

                    headerStyle: {
                      position: 'sticky',
                      textAlign: 'left',
                      top: 0,
                    },
                    actionsColumnIndex: -1,
                    exportButton: { csv: true, pdf: false },
                    exportFileName: 'Admin User',
                    pageSize: 20,
                    columnsButton: true,
                  }}
                  localization={{
                    toolbar: {
                      exportCSVName: 'Export as CSV',
                    },
                  }}
                />
              </div>

              {/* checking if the user data exists */}

              <div id="42">
                {this.state.adminUserData.length ? (
                  //modal for user details update
                  <Modal
                    isOpen={this.state.modal_standard}
                    toggle={this.tog_standard}
                  >
                    <ModalHeader
                      toggle={() =>
                        this.setState({
                          modal_standard: false,
                        })
                      }
                    >
                      {this.state.key?.name}'s information Edit
                    </ModalHeader>

                    <ModalBody>
                      <Row className="mb-3">
                        <Label
                          htmlFor="edit-admin-email-input"
                          className="col-md-2 col-form-label"
                        >
                          Email
                        </Label>
                        <Col md={10}>
                          <Input
                            type="email"
                            defaultValue={this.state.profileEmail}
                            id="edit-admin-email-input"
                            onChange={this.handleProfileEmailChange}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Label
                          htmlFor="edit-admin-name-input"
                          className="col-md-2 col-form-label"
                        >
                          Name
                        </Label>
                        <Col md={10}>
                          <Input
                            type="text"
                            value={this.state.profileName}
                            placeholder="Please input name of the admin user"
                            id="edit-admin-name-input"
                            title="Only alphabets and spaces are allowed"
                            onChange={this.handleProfileNameChange}
                          />
                          {this.state.errors?.profileName && (
                            <p>Name Invalid</p>
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Label
                          htmlFor="edit-admin-NID-input"
                          className="col-md-2 col-form-label"
                        >
                          Nid
                        </Label>
                        <Col md={10}>
                          <Input
                            type="text"
                            value={this.state.profileNid}
                            id="edit-admin-NID-input"
                            onChange={(e) => this.handleProfileNidChange(e)}
                          />
                          {this.state.errors?.profileNid && (
                            <p style={{ color: '#FF0000' }}>NID Invalid</p>
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Label
                          htmlFor="edit-admin-gender-dropdown"
                          className="col-md-2 col-form-label"
                        >
                          Gender
                        </Label>
                        <Col md={9}>
                          <Dropdown
                            isOpen={this.state.profileGenderMenu}
                            toggle={(event) =>
                              this.setState({
                                profileGenderMenu:
                                  !this.state.profileGenderMenu,
                              })
                            }
                          >
                            <DropdownToggle className="px-5 py-2" color="light">
                              {capitalizeFirstLetter(this.state.profileGender)}{' '}
                              <i className="mdi mdi-chevron-down"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              {/* <DropdownItem
                                onClick={(event) => {
                                  this.setState({ gender: 'Select' });
                                }}
                              >
                                Select Gender
                              </DropdownItem> */}
                              <DropdownItem
                                onClick={(event) => {
                                  this.setState({ profileGender: 'Male' });
                                }}
                              >
                                Male
                              </DropdownItem>

                              <DropdownItem
                                onClick={(event) => {
                                  this.setState({ profileGender: 'Female' });
                                }}
                              >
                                Female
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Label
                          htmlFor="edit-admin-date-of-birth-input"
                          className="col-md-2 col-form-label"
                        >
                          Date of Birth
                        </Label>
                        <Col md={10}>
                          <div
                            style={{
                              border: '1px solid gray',
                              borderRadius: '5px',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              paddingRight: '4px',
                              paddingLeft: '4px',
                            }}
                          >
                            <DatePickerComponent
                              selectedDate={this.state.profileDob}
                              handleSelectedDateChange={
                                this.handleProfileDobChange
                              }
                              // defaultValue={
                              //   this.state.key?.profileInfo.nomineeDateOfBirth
                              // }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Label
                          htmlFor="edit-admin-designation-input"
                          className="col-md-2 col-form-label"
                        >
                          Designation
                        </Label>
                        <Col md={10}>
                          <Input
                            type="text"
                            defaultValue={this.state.profileDesignation}
                            id="edit-admin-designation-input"
                            onChange={this.handleProfileDesignationChange}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Label
                          htmlFor="edit-admin-phone-number-input"
                          className="col-md-2 col-form-label"
                        >
                          Phone Number
                        </Label>
                        <Col md={10}>
                          <Input
                            value={this.state.profilePhone}
                            className="form-control input-color placeholder-gray-500"
                            onChange={this.handleProfilePhoneChange}
                          />
                          {this.state.errors?.profilePhone && (
                            <p style={{ color: '#FF0000' }}>
                              Phone number Invalid
                            </p>
                          )}
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={10}>
                          <Button
                            onClick={() => {
                              this.tog_ForgetPasswordModal();
                            }}
                            color="danger"
                          >
                            Reset Password
                          </Button>
                        </Col>
                      </Row>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        type="button"
                        onClick={this.tog_standard}
                        color="light"
                        className="waves-effect"
                      >
                        Close
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        className="waves-effect waves-light"
                        onClick={async (event) => {
                          event.preventDefault();
                          const token = getToken();
                          let sendData = {
                            userId: this.state.key?.userId,
                            // email: this.state.profileEmail,
                            name: this.state.profileName,
                            dob: moment(this.state.profileDob).format(
                              'DD/MM/YYYY'
                            ),
                            gender: this.state.profileGender,
                            designation: this.state.profileDesignation,
                            nid: this.state.profileNid,
                            phone: this.state.profilePhone,
                          };

                          await GKApi.put('/staff', sendData, {
                            headers: {
                              Authorization: token,
                            },
                          });
                          // if (submitData) {
                          //   this.setState({
                          //     ...this.state,
                          //     // success: 'Finance Inputs Updated Successfully',
                          //     custom_div1: true,
                          //   });
                          //   setTimeout(() => {
                          //     this.setState({ custom_div1: false });
                          //   }, 5000);
                          // }
                          // the api call
                          const response = await GKApi.get(
                            '/staff?pageNumber=1&pageSize=1000',
                            {
                              headers: {
                                Authorization: token,
                              },
                            }
                          );

                          // the api call
                          const userGroupResponse = await GKApi.get(
                            '/role/assign',
                            {
                              headers: {
                                Authorization: token,
                              },
                            }
                          );

                          let tempAdminUserData = response.data.data;
                          for (let i = 0; i < tempAdminUserData.length; i++) {
                            const user = tempAdminUserData[i];
                            let tempRole = [];
                            userGroupResponse.data.forEach((item) => {
                              if (item.staffId === user.userId) {
                                tempRole.push(item.groupName);
                              }
                            });
                            user.role = tempRole.join(', ');
                          }

                          this.setState({ adminUserData: tempAdminUserData });

                          this.tog_standard();
                        }}
                        disabled={
                          this.state.errors?.profileNid ||
                          this.state.errors?.profilePhone ||
                          this.state.errors?.profileName
                            ? true
                            : false
                        }
                      >
                        Update information
                      </Button>
                    </ModalFooter>
                  </Modal>
                ) : (
                  ''
                )}
              </div>
              <Modal
                isOpen={this.state.forgetPasswordModal}
                toggle={this.tog_ForgetPasswordModal}
              >
                <ModalHeader
                  toggle={() => this.setState({ forgetPasswordModal: false })}
                >
                  Remarks Confirmation
                </ModalHeader>

                <ModalBody>
                  <Row className="mb-3">
                    <Col md={12} className="mb-3">
                      <h5 className="d-flex justify-content-center">
                        Are you s ure you want to reset password for{' '}
                        {this.state.key?.name}'s account?
                      </h5>
                    </Col>
                  </Row>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_ForgetPasswordModal}
                    color="light"
                    className="waves-effect"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="waves-effect"
                    color="primary"
                    onClick={async () => {
                      try {
                        const data = await Auth.forgotPassword(
                          this.state.profileEmail
                        );
                        console.log(data);
                        this.setState({ resetPassAlert: true });
                      } catch (err) {
                        console.log(err);
                        this.setState({ resetPassFailed: true });
                      }

                      this.tog_ForgetPasswordModal();
                    }}
                  >
                    Confirm
                  </Button>
                </ModalFooter>
              </Modal>
              {this.state.resetPassAlert ? (
                <SweetAlert
                  title="A verification code has been sent!!"
                  timeout={2500}
                  style={{
                    top: '0px',
                    right: '0px',
                  }}
                  showCloseButton={false}
                  showConfirm={false}
                  success
                  onConfirm={() => this.setState({ resetPassAlert: false })}
                ></SweetAlert>
              ) : (
                ''
              )}

              {this.state.resetPassFailed ? (
                <SweetAlert
                  title="Something went wrong!!"
                  timeout={2500}
                  style={{
                    top: '0px',
                    right: '0px',
                  }}
                  showCloseButton={false}
                  showConfirm={false}
                  error
                  onConfirm={() => this.setState({ resetPassFailed: false })}
                ></SweetAlert>
              ) : (
                ''
              )}
              {/* modal fro user status */}
              <div>
                <Modal
                  isOpen={this.state.modal_standard2}
                  toggle={this.tog_standard2}
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_standard2: false })}
                  >
                    {this.state.key?.name}'s status Edit
                  </ModalHeader>

                  <ModalBody>
                    {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

                    <Row className="mb-3">
                      Are you sure you want to{' '}
                      {this.state.key?.active === 1 ? 'Disable' : 'Enable'}{' '}
                      {this.state.key?.name}'s profile?
                    </Row>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_standard2}
                      color="light"
                      className="waves-effect"
                    >
                      No
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={async (event) => {
                        event.preventDefault();
                        const token = getToken();
                        var statusData =
                          this.state.key?.active === 1 ? 'disable' : 'enable';
                        await GKApi.put(
                          `/staff/status`,
                          {
                            userId: this.state.key?.userId,
                            status: statusData,
                          },
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        this.tog_standard2();
                        const response = await GKApi.get(
                          '/staff?pageNumber=1&pageSize=1000',
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        const userGroupResponse = await GKApi.get(
                          '/role/assign',
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        let tempAdminUserData = response.data.data;
                        for (let i = 0; i < tempAdminUserData.length; i++) {
                          const user = tempAdminUserData[i];
                          let tempRole = [];
                          userGroupResponse.data.forEach((item) => {
                            if (item.staffId === user.userId) {
                              tempRole.push(item.groupName);
                            }
                          });
                          user.role = tempRole.join(', ');
                        }

                        this.setState({ adminUserData: tempAdminUserData });
                      }}
                    >
                      Yes
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>

              {/* modal for creating new admin */}
              <div id="41">
                <Modal
                  isOpen={this.state.modal_standard3}
                  toggle={this.tog_standard3}
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_standard3: false })}
                  >
                    Add a new Admin
                  </ModalHeader>

                  <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <ModalBody>
                        <Row className="mb-3">
                          <Label
                            htmlFor="create-admin-email-input"
                            className="col-md-3 col-form-label"
                          >
                            Email
                          </Label>
                          <Col md={9}>
                            <Input
                              type="email"
                              value={this.state.email}
                              placeholder="Please input email address of the admin user"
                              id="create-admin-email-input"
                              onChange={this.handleEmailChange}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Label
                            htmlFor="create-admin-name-input"
                            className="col-md-3 col-form-label"
                          >
                            Name
                          </Label>
                          <Col md={9}>
                            <Input
                              type="text"
                              value={this.state.name}
                              placeholder="Please input name of the admin user"
                              id="create-admin-name-input"
                              pattern="^[a-zA-z]+([\s][a-zA-Z]+)*$"
                              title="Only alphabets and spaces are allowed"
                              onChange={this.handleNameChange}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Label
                            htmlFor="create-admin-date-of-birth-input"
                            className="col-md-3 col-form-label"
                          >
                            Date of Birth
                          </Label>
                          <Col md={9}>
                            <div
                              style={{
                                border: '1px solid',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                              }}
                              className="d-flex gap-3 align-content-center"
                            >
                              <DatePickerComponent
                                selectedDate={this.state.dob}
                                handleSelectedDateChange={this.handleDobChange}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Label
                            htmlFor="create-admin-gender-dropdown"
                            className="col-md-3 col-form-label"
                          >
                            Gender
                          </Label>
                          <Col md={9}>
                            <Dropdown
                              isOpen={this.state.genderMenu}
                              toggle={(event) =>
                                this.setState({
                                  genderMenu: !this.state.genderMenu,
                                })
                              }
                            >
                              <DropdownToggle
                                className="px-5 py-2"
                                color="light"
                              >
                                {capitalizeFirstLetter(this.state.gender)}{' '}
                                <i className="mdi mdi-chevron-down"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={(event) => {
                                    this.setState({ gender: 'Male' });
                                  }}
                                >
                                  Male
                                </DropdownItem>

                                <DropdownItem
                                  onClick={(event) => {
                                    this.setState({ gender: 'Female' });
                                  }}
                                >
                                  Female
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Label
                            htmlFor="create-admin-designation-input"
                            className="col-md-3 col-form-label"
                          >
                            Designation
                          </Label>
                          <Col md={9}>
                            <Input
                              type="text"
                              value={this.state.designation}
                              placeholder="Please input Designation of the admin user"
                              id="create-admin-designation-input"
                              onChange={this.handleDesignationChange}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Label
                            htmlFor="create-admin-nid-input"
                            className="col-md-3 col-form-label"
                          >
                            NID
                          </Label>
                          <Col md={9}>
                            <Input
                              type="text"
                              value={this.state.nid}
                              placeholder="Please input NID of the admin user"
                              id="create-admin-nid-input"
                              pattern="^([0-9]{10})$|^([0-9]{13})$|^([0-9]{17})$"
                              title="NID must be of 10 or 13 or 17 numbers"
                              minLength={10}
                              maxLength={17}
                              onChange={this.handleNidChange}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Label
                            htmlFor="create-admin-phone-number-input"
                            className="col-md-3 col-form-label"
                          >
                            Phone Number
                          </Label>
                          <Col md={9}>
                            <Input
                              pattern="^01[3-9]{1}[0-9]{8}$"
                              value={this.state.phone}
                              className="form-control input-color placeholder-gray-500"
                              onChange={this.handlePhoneChange}
                              placeholder="Please input phone number of the admin user"
                            >
                              {(inputProps) => (
                                <MaterialInput
                                  {...inputProps}
                                  type="tel"
                                  disableUnderline
                                />
                              )}
                            </Input>
                          </Col>
                        </Row>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          type="button"
                          onClick={this.tog_standard3}
                          color="light"
                          className="waves-effect"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          className="waves-effect waves-light"
                        >
                          Add Admin User
                        </Button>
                      </ModalFooter>
                    </FormGroup>
                  </Form>
                </Modal>
              </div>

              {/* modal for creating new user group */}
              <div>
                <Modal
                  size="xl"
                  isOpen={this.state.modal_standard4}
                  toggle={this.tog_standard4}
                >
                  <ModalHeader
                    toggle={() => {
                      this.setState({ modal_standard4: false });
                    }}
                  >
                    Add a new User group
                  </ModalHeader>

                  <Form>
                    <FormGroup>
                      <ModalBody>
                        <Row className="mb-3">
                          <Label className="col-md-2 col-form-label">
                            Group Name
                          </Label>
                          <Col md={3}>
                            <Input
                              type="text"
                              value={this.state.presetName}
                              placeholder="Please input name of the group"
                              onChange={(e) =>
                                this.setState({ presetName: e.target.value })
                              }
                            />
                          </Col>
                        </Row>

                        {this.state.presetData.map((item, index) => {
                          return (
                            <Row className="mb-3">
                              <Label className="col-md-1 col-form-label">
                                Master
                              </Label>
                              <Col md={2}>
                                <Dropdown
                                  isOpen={item.masterDropDown}
                                  toggle={(event) => {
                                    let temp = this.state.presetData;
                                    temp[index].masterDropDown =
                                      !temp[index].masterDropDown;
                                    this.setState({
                                      presetData: temp,
                                    });
                                  }}
                                >
                                  <DropdownToggle
                                    className="px-2 py-1"
                                    color="light"
                                  >
                                    {capitalizeFirstLetter(item.master)}{' '}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {Object.keys(this.state.componentList).map(
                                      (item) => (
                                        <DropdownItem
                                          onClick={(event) => {
                                            let temp = this.state.presetData;
                                            temp[index].master = item;
                                            temp[index].branch =
                                              'Select Branch';
                                            temp[index].component =
                                              'Select Component';
                                            this.setState({ presetData: temp });
                                          }}
                                        >
                                          {item}
                                        </DropdownItem>
                                      )
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                              <Label className="col-md-1 col-form-label">
                                Branch
                              </Label>
                              <Col md={2}>
                                <Dropdown
                                  isOpen={item.branchDropDown}
                                  toggle={(event) => {
                                    let temp = this.state.presetData;
                                    temp[index].branchDropDown =
                                      !temp[index].branchDropDown;
                                    this.setState({
                                      presetData: temp,
                                    });
                                  }}
                                >
                                  <DropdownToggle
                                    className="px-2 py-1"
                                    color="light"
                                  >
                                    {capitalizeFirstLetter(item.branch)}{' '}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {this.state?.componentList[item.master] &&
                                      Object.keys(
                                        this.state?.componentList[item.master]
                                      ).map((item) => (
                                        <DropdownItem
                                          onClick={(event) => {
                                            let temp = this.state.presetData;
                                            temp[index].branch = item;
                                            temp[index].component =
                                              'Select Component';
                                            this.setState({ presetData: temp });
                                          }}
                                        >
                                          {item}
                                        </DropdownItem>
                                      ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>

                              <Label className="col-md-1 col-form-label">
                                Component
                              </Label>
                              <Col md={2}>
                                <Dropdown
                                  isOpen={item.componentDropDown}
                                  toggle={(event) => {
                                    let temp = this.state.presetData;
                                    temp[index].componentDropDown =
                                      !temp[index].componentDropDown;
                                    this.setState({
                                      presetData: temp,
                                    });
                                  }}
                                >
                                  <DropdownToggle
                                    className="px-2 py-1"
                                    color="light"
                                  >
                                    {capitalizeFirstLetter(item.component)}{' '}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {item.master !== 'Select Master' &&
                                      item.branch !== 'Select Branch' &&
                                      this.state?.componentList[item.master][
                                        item.branch
                                      ].map((item) => (
                                        <DropdownItem
                                          onClick={(event) => {
                                            let temp = this.state.presetData;
                                            temp[index].component =
                                              item.componentName;
                                            temp[index].componentId =
                                              item.cmpId;
                                            this.setState({ presetData: temp });
                                          }}
                                        >
                                          {item.componentName}
                                        </DropdownItem>
                                      ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                              <Label className="col-md-1 col-form-label">
                                Role
                              </Label>
                              <Col md={1}>
                                <Dropdown
                                  isOpen={item.roleDropDown}
                                  toggle={(event) => {
                                    let temp = this.state.presetData;
                                    temp[index].roleDropDown =
                                      !temp[index].roleDropDown;
                                    this.setState({
                                      presetData: temp,
                                    });
                                  }}
                                >
                                  <DropdownToggle
                                    className="px-2 py-1"
                                    color="light"
                                  >
                                    {capitalizeFirstLetter(item.role)}{' '}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {this.state.roleList.map((item) => (
                                      <DropdownItem
                                        onClick={(event) => {
                                          let temp = this.state.presetData;
                                          temp[index].role = item.title;
                                          temp[index].roleId = item.roleId;
                                          this.setState({ presetData: temp });
                                        }}
                                      >
                                        {item.title}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                              <Col md={1}>
                                <Button
                                  disabled={this.state.presetData.length === 1}
                                  onClick={() => {
                                    let temp = this.state.presetData;
                                    temp.splice(index, 1);
                                    this.setState({ presetData: temp });
                                  }}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          );
                        })}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingRight: '40px',
                          }}
                        >
                          <Button
                            disabled={
                              this.state.presetData[
                                this.state.presetData.length - 1
                              ]?.componentId === null ||
                              this.state.presetData[
                                this.state.presetData.length - 1
                              ]?.roleId === null
                            }
                            onClick={() => {
                              let temp = this.state.presetData;
                              temp.push({
                                master: 'Select Master',
                                masterDropDown: false,
                                branch: 'Select Branch',
                                branchDropDown: false,
                                component: 'Select Component',
                                componentDropDown: false,
                                componentId: null,
                                role: 'Select Role',
                                roleDropDown: false,
                                roleId: null,
                              });
                              this.setState({ presetData: temp });
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          type="button"
                          onClick={this.tog_standard4}
                          color="light"
                          className="waves-effect"
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={this.state.disableCreateGroupBtn}
                          type="submit"
                          color="primary"
                          className="waves-effect waves-light"
                          onClick={async (e) => {
                            e.preventDefault();
                            if (this.state.presetName === '') {
                              return;
                            }
                            let roles = [];
                            this.state.presetData.forEach((item) => {
                              if (
                                item.roleId !== null &&
                                item.componentId !== null
                              )
                                roles.push({
                                  cmpId: item.componentId,
                                  roleId: item.roleId,
                                });
                            });
                            if (roles.length === 0) {
                              return;
                            }
                            let data = {
                              groupName: this.state.presetName,
                              roles: roles,
                            };
                            try {
                              this.setState({ disableCreateGroupBtn: true });
                              const token = getToken(); // getting token for api call
                              const role_list_response = await GKApi.post(
                                '/role/group',
                                data,
                                {
                                  headers: {
                                    Authorization: token,
                                  },
                                }
                              );
                              if (role_list_response) {
                                this.tog_standard4();
                              }
                            } catch (error) {
                            } finally {
                              this.setState({ disableCreateGroupBtn: false });
                            }
                          }}
                        >
                          Add User Group
                        </Button>
                      </ModalFooter>
                    </FormGroup>
                  </Form>
                </Modal>
              </div>
            </div>
          ) : (
            <h5>Sorry you don't have permission to view this</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default AdminUsers;

import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import MiniWidgets from './MiniWidgets';
import TodayDateAndUpcomingEvent from './TodayDateAndUpcomingEvent';
import TabSalesAnalytics from './TabSalesAnalytics';
import TrxAmountAppPerformance from './TrxAmountAppPerformance';
import TrxCountAppPerformance from './TrxCountAppPerformance';
import AverageTicketSize from './AverageTicketSize';
import DownloadAudience from './DownloadAudience';
import { getGroupName, getToken, GKApi } from '../../../components/config';
import NewUserEntry from './NewUserEntry';
class MarketingDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        // { title: 'Nazox', link: '/' },
        // { title: 'Marketing-Dashboard', link: '#' },
      ],
      canViewMktDashboard: '',
      isMkt: false,
      isSuper: false,
    };
  }
  async componentDidMount() {
    const token = getToken();
    // const gId = getGroupId();

    // getting permission data from api
    const getPermission = await GKApi.get(`/role/permission?cmpId=2`, {
      headers: {
        Authorization: token,
      },
    });

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    // setting permissions to their states of their own


    this.setState({
      canViewMktDashboard: permissionData[2].groupName,
    });

    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'MKT') {
        this.setState({
          isMkt: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    
    let groupNames = [...gN];
    this.setState({ groupName: groupNames });
    
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="DASHBOARD"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* {this.state.canViewMktDashboard === 'N_A' ? (
              <h5>Sorry you are not authorized</h5>
            ) : ( */}
            {this.state.isMkt === true || this.state.isSuper === true ? (
              <div>
                <Row>
                  <Col xl={10}>
                    <Row>
                      <MiniWidgets />
                    </Row>
                  </Col>
                  <Col xl={2}>
                    <TodayDateAndUpcomingEvent />
                  </Col>{' '}
                  <Col xl={12}>
                    <AverageTicketSize />
                  </Col>
                  <Col xl={12}>
                    <TrxAmountAppPerformance />
                  </Col>
                  <Col xl={12}>
                    <TrxCountAppPerformance />
                  </Col>
                  <Col xl={12}>
                    <NewUserEntry />
                  </Col>
                </Row>
                <Row>
                  <Col xl={7}>
                    <DownloadAudience />
                  </Col>
                  <Col xl={5}>
                    <TabSalesAnalytics />
                  </Col>
                </Row>
              </div>
            ) : (
              <h5>Sorry you don't have permission to view this</h5>
            )}
            {/* )} */}
            {/* <Row>
              <Col xl={8}>
                <CampaignReport />
              </Col>
              <Col xl={4}>
                <SocialMedia />
              </Col>
            </Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default MarketingDashboard;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
// import * as XLSX from 'xlsx';
import classnames from 'classnames';
import {
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Button,
  Row,
  Col,
  Card,
  CardText,
  Input,
  CardBody,
} from 'reactstrap';
import { TextField } from '@material-ui/core';
//Import Breadcrumb
// import Breadcrumbs from '../../../components/Common/Breadcrumb';
// import Dropzone from 'react-dropzone';
// import { Link } from 'react-router-dom';
import CreateCampaign from './CreateCampaign';
import { getGroupName, getToken, GKApi } from '../../../components/config';
import moment from 'moment';
import EditDraftGeneral from './EditDraftGeneral';
import EditDraftCampaign from './EditDraftCampaign';
import { deviceInfo } from '../../../helpers/device_info_helper';

class AllSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Marketing', link: '#' },
        { title: 'Marketing-Communication', link: '#' },
      ],
      selectedFiles: [],
      selectedFilesBanner: [],
      visible: false,
      modal_standard: false,
      modal_standard2: false,
      activeTabJustify: '1',
      activeTab: '5',
      allGeneralCommunicationListData: [],
      allCampaignCommunicationListData: [],
      pushNotificationGeneralCommunicationListData: [],
      pushNotificationCampaignCommunicationListData: [],
      bannerAdsGeneralCommunicationListData: [],
      bannerAdsCampaignCommunicationListData: [],
      inboxGeneralCommunicationListData: [],
      inboxCampaignCommunicationListData: [],
      draftGeneralCommunicationListData: [],
      draftCampaignCommunicationListData: [],
      generalCampaignDetailsData: {},
      customerPresetData: [],
      deviceInfo: {},
      campaignApproveModal: false,
      campaignRejectionModal: false,
      generalCampaignApproveModal: false,
      generalCampaignRejectionModal: false,
      reasonForRejection: '',
      campaignDetailsData: {},
      campaignDetailsModal: false,
      communicationApprove: 0,
      communicationSubmit: 0,
      communicationReject: 0,
      communicationCreate: 0,
      notificationAndInboxStop: 0,
      isMkt: false,
      isSuper: false,
      userAuthState: 1,
      compAuthState: 1,
      pushNotificationComDetailsModal: false,
      pushNotificationComDetails: {},
      inboxComDetailsModal: false,
      inboxComDetails: {},
      bannerComDetailsModal: false,
      bannerComDetails: {},
      bannerUrl: '',
      modalDraftGeneral: false,
      modalDraftCampaign: false,
      stopNotificationModal: false,
      stopInboxModal: false,
      playPauseStatus: 'play',
      playPauseCampaignModal: false,
      playPauseId:'',
      canPlayPauseCampaign:0
    };
    this.tog_standard = this.tog_standard.bind(this);
    this.handleFetchCampaign = this.handleFetchCampaign.bind(this);
  }

  handleReasonChange = (e) => {
    this.setState({ reasonForRejection: e.target.value });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      if (tab >= 5 && tab <= 7) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  };
  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  tog_standard2 = () => {
    this.setState((prevState) => ({
      modal_standard2: !prevState.modal_standard2,
    }));
    this.removeBodyCss();
  };
  tog_PlayPauseCampaign = () => {
    this.setState((prevState) => ({
      playPauseCampaignModal: !prevState.playPauseCampaignModal,
    }));
    this.removeBodyCss();
  };
  tog_campaignApproval = () => {
    this.setState((prevState) => ({
      campaignApproveModal: !prevState.campaignApproveModal,
    }));
    this.removeBodyCss();
  };

  tog_campaignRejection = () => {
    this.setState((prevState) => ({
      campaignRejectionModal: !prevState.campaignRejectionModal,
    }));
    this.removeBodyCss();
  };

  tog_generalCampaignApproval = () => {
    this.setState((prevState) => ({
      generalCampaignApproveModal: !prevState.generalCampaignApproveModal,
    }));
    this.removeBodyCss();
  };

  tog_generalCampaignRejection = () => {
    this.setState((prevState) => ({
      generalCampaignRejectionModal: !prevState.generalCampaignRejectionModal,
    }));
    this.removeBodyCss();
  };

  tog_campaignDetails = () => {
    this.setState((prevState) => ({
      campaignDetailsModal: !prevState.campaignDetailsModal,
    }));
    this.removeBodyCss();
  };

  tog_ComDetails = () => {
    this.setState((prevState) => ({
      pushNotificationComDetailsModal:
        !prevState.pushNotificationComDetailsModal,
    }));
    this.removeBodyCss();
  };

  tog_InboxDetails = () => {
    this.setState((prevState) => ({
      inboxComDetailsModal: !prevState.inboxComDetailsModal,
    }));
    this.removeBodyCss();
  };

  tog_BannerDetails = () => {
    this.setState((prevState) => ({
      bannerComDetailsModal: !prevState.bannerComDetailsModal,
    }));
    this.removeBodyCss();
  };

  tog_ModalDraftGeneral = () => {
    this.setState((prevState) => ({
      modalDraftGeneral: !prevState.modalDraftGeneral,
    }));
    this.removeBodyCss();
  };

  tog_ModalDraftCampaign = () => {
    this.setState((prevState) => ({
      modalDraftCampaign: !prevState.modalDraftCampaign,
    }));
    this.removeBodyCss();
  };

  tog_stopNotificationModal = () => {
    this.setState((prevState) => ({
      stopNotificationModal: !prevState.stopNotificationModal,
    }));
    this.removeBodyCss();
  };

  tog_stopInboxModal = () => {
    this.setState((prevState) => ({
      stopInboxModal: !prevState.stopInboxModal,
    }));
    this.removeBodyCss();
  };

  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };

  async handleFetchCampaign() {
    const token = getToken();
    const allScheduleList = await GKApi.get(
      '/marketing/campaign/list?type=all',
      {
        headers: {
          Authorization: token,
        },
      }
    );

    let generalCommunicationAllList = [];
    let campaignCommunicationAllList = [];
    allScheduleList.data.forEach((al) => {
      if (al.type === 'GENERAL') {
        generalCommunicationAllList.push(al);
      } else {
        campaignCommunicationAllList.push(al);
      }
    });
    this.setState({
      allGeneralCommunicationListData: generalCommunicationAllList,
      allCampaignCommunicationListData: campaignCommunicationAllList,
    });
  }

  async componentDidMount() {
    const token = getToken();
    const allScheduleList = await GKApi.get(
      '/marketing/campaign/list?type=all',
      {
        headers: {
          Authorization: token,
        },
      }
    );

    let generalCommunicationAllList = [];
    let campaignCommunicationAllList = [];
    allScheduleList.data.forEach((al) => {
      if (al.type === 'GENERAL') {
        generalCommunicationAllList.push(al);
      } else {
        campaignCommunicationAllList.push(al);
      }
    });
    this.setState({
      allGeneralCommunicationListData: generalCommunicationAllList,
      allCampaignCommunicationListData: campaignCommunicationAllList,
    });

    // const groupId = getGroupId();
    const getPermission = await GKApi.get(`/role/permission?cmpId=141,37,171,143`, {
      headers: {
        Authorization: token,
      },
    });
    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    this.setState({
      notificationAndInboxStop: permissionData[171].canAuth,
      communicationApprove: permissionData[141].canAuth,
      communicationSubmit: permissionData[141].canSubmit,
      communicationReject: permissionData[141].canReject,
      communicationCreate: permissionData[37].canInsert,
      userAuthState: permissionData[141].authState,
      canPlayPauseCampaign: permissionData[143].canAuth,
    });

    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'MKT') {
        this.setState({
          isMkt: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });

    const res = deviceInfo();
    this.setState({ deviceInfo: res }, () => {});
  }

  render() {
    const columnsAllScheduleGeneral = [
      {
        field: 'title',
        title: 'Communication Title',
      },
      {
        field: 'isBanner',
        title: 'Banner',
        render: (row) => (
          <div>
            {row.isBanner === 0
              ? 'Not Available'
              : row.isBanner === 1
              ? 'Available'
              : ''}
          </div>
        ),
      },
      {
        field: 'isInbox',
        title: 'Inbox',
        render: (row) => (
          <div>
            {row.isInbox === 0
              ? 'Not Available'
              : row.isInbox === 1
              ? 'Available'
              : ''}
          </div>
        ),
      },
      {
        field: 'isNotification',
        title: 'Notification',
        render: (row) => (
          <div>
            {row.isNotification === 0
              ? 'Not Available'
              : row.isNotification === 1
              ? 'Available'
              : ''}
          </div>
        ),
      },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },
      // {
      //   field: 'documents',
      //   title: 'Documents',
      // },
      {
        field: 'status',
        title: 'Status',
      },
      
    ];
    const columnsAllScheduleCampaign = [
      {
        field: 'title',
        title: 'Communication Title',
      },
      {
        field: 'isBanner',
        title: 'Banner',
        render: (row) => (
          <div>
            {row.isBanner === 0
              ? 'Not Available'
              : row.isBanner === 1
              ? 'Available'
              : ''}
          </div>
        ),
      },
      {
        field: 'isInbox',
        title: 'Inbox',
        render: (row) => (
          <div>
            {row.isInbox === 0
              ? 'Not Available'
              : row.isInbox === 1
              ? 'Available'
              : ''}
          </div>
        ),
      },
      {
        field: 'isNotification',
        title: 'Notification',
        render: (row) => (
          <div>
            {row.isNotification === 0
              ? 'Not Available'
              : row.isNotification === 1
              ? 'Available'
              : ''}
          </div>
        ),
      },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },
      // {
      //   field: 'documents',
      //   title: 'Documents',
      // },
      {
        field: 'status',
        title: 'Status',
      },
      {
        field: 'campaignStatus',
        title: 'Play Pause Status',
        render: (row) => (
          <div>
            {row.campaignStatus === 1
              ? 'Playing'
              : row.campaignStatus === 2
              ? 'Paused'
              : row.campaignStatus === 3
              ? 'Stopped':'Not Available'}
          </div>
        ),
      },
    ];
    const columnsBannerAdsGeneral = [
      {
        field: 'communicationTitle',
        title: 'Communication Title',
      },
      {
        field: 'id',
        title: 'Banner Communication Id',
      },
      {
        field: 'link',
        title: 'Banner target link',
      },
      // {
      //   field: 'bannerPosition',
      //   title: 'Banner Position',
      // },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },

      {
        field: 'status',
        title: 'Status',
      },
    ];
    const columnsBannerAdsCampaign = [
      {
        field: 'communicationTitle',
        title: 'Communication Title',
      },
      {
        field: 'id',
        title: 'Banner Communication Id',
      },
      {
        field: 'link',
        title: 'Banner target link',
      },
      // {
      //   field: 'bannerPosition',
      //   title: 'Banner Position',
      // },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },

      {
        field: 'status',
        title: 'Status',
      },
    ];
    const columnsPushNotificationGeneral = [
      {
        field: 'communicationTitle',
        title: 'Communication Title',
      },
      {
        field: 'title',
        title: 'Push Notification Title',
      },
      {
        field: 'message',
        title: 'Message',
      },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },

      {
        field: 'status',
        title: 'Status',
      },
    ];
    const columnsPushNotificationCampaign = [
      {
        field: 'communicationTitle',
        title: 'Communication Title',
      },
      {
        field: 'title',
        title: 'Push Notification Title',
      },
      {
        field: 'message',
        title: 'Message',
      },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },

      {
        field: 'status',
        title: 'Status',
      },
    ];
    const columnsInboxGeneral = [
      // {
      //   field: 'title',
      //   title: 'Inbox Title',
      // },
      {
        field: 'communicationTitle',
        title: 'Communication Title',
      },
      {
        field: 'message',
        title: 'Message',
      },

      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },
      {
        field: 'status',
        title: 'Status',
      },
    ];
    const columnsInboxCampaign = [
      {
        field: 'communicationTitle',
        title: 'Communication Title',
      },
      // {
      //   field: 'title',
      //   title: 'Inbox Title',
      // },
      {
        field: 'message',
        title: 'Message',
      },

      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },
      {
        field: 'status',
        title: 'Status',
      },
    ];

    const columnsDraftScheduleGeneral = [
      {
        field: 'title',
        title: 'Communication Title',
      },
      {
        field: 'isBanner',
        title: 'Banner',
        render: (row) => (
          <div>
            {row.isBanner === 0 ? (
              'N/A'
            ) : row.isBanner === 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        field: 'isInbox',
        title: 'Inbox',
        render: (row) => (
          <div>
            {row.isInbox === 0 ? (
              'N/A'
            ) : row.isInbox === 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        field: 'isNotification',
        title: 'Notification',
        render: (row) => (
          <div>
            {row.isNotification === 0 ? (
              'N/A'
            ) : row.isNotification === 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            <div>
              {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
              {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
            </div>
          </div>
        ),
      },
    ];
    const columnsDraftScheduleCampaign = [
      {
        field: 'title',
        title: 'Communication Title',
      },
      {
        field: 'isBanner',
        title: 'Banner',
        render: (row) => (
          <div>
            {row.isBanner === 0 ? (
              'N/A'
            ) : row.isBanner === 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        field: 'isInbox',
        title: 'Inbox',
        render: (row) => (
          <div>
            {row.isInbox === 0 ? (
              'N/A'
            ) : row.isInbox === 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        field: 'isNotification',
        title: 'Notification',
        render: (row) => (
          <div>
            {row.isNotification === 0 ? (
              'N/A'
            ) : row.isNotification === 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },
    ];
    return (
      <React.Fragment>
        {this.state.isMkt === true || this.state.isSuper === true ? (
          <div>
            <Nav tabs className="nav-tabs-custom nav-justified">
              {/*All communication  */}
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: this.state.activeTabJustify === '1',
                  })}
                  onClick={async () => {
                    this.toggleCustomJustified('1');
                  }}
                >
                  <span className="d-none d-sm-block fw-bold">
                    All Schedule
                  </span>
                </NavLink>
              </NavItem>

              {/*Push Notification communication  */}
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: this.state.activeTabJustify === '2',
                  })}
                  onClick={async () => {
                    this.toggleCustomJustified('2');
                    const token = getToken();
                    const pushNotificationScheduleList = await GKApi.get(
                      '/marketing/campaign/list?type=notification',
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    let generalCommunicationPushNotificationList = [];
                    let campaignCommunicationPushNotificationList = [];
                    pushNotificationScheduleList.data.forEach((al) => {
                      if (al.type === 'GENERAL') {
                        generalCommunicationPushNotificationList.push(al);
                      } else {
                        campaignCommunicationPushNotificationList.push(al);
                      }
                    });
                    this.setState({
                      pushNotificationGeneralCommunicationListData:
                        generalCommunicationPushNotificationList,
                      pushNotificationCampaignCommunicationListData:
                        campaignCommunicationPushNotificationList,
                    });
                  }}
                >
                  <span className="d-none d-sm-block fw-bold">
                    Push Notification
                  </span>
                </NavLink>
              </NavItem>

              {/*banner adds communication  */}
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: this.state.activeTabJustify === '3',
                  })}
                  onClick={async () => {
                    this.toggleCustomJustified('3');
                    const token = getToken();
                    const BannerAdsScheduleList = await GKApi.get(
                      '/marketing/campaign/list?type=banner',
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    let generalCommunicationBannerAdsList = [];
                    let campaignCommunicationBannerAdsList = [];
                    BannerAdsScheduleList.data.forEach((al) => {
                      if (al.type === 'GENERAL') {
                        generalCommunicationBannerAdsList.push(al);
                      } else {
                        campaignCommunicationBannerAdsList.push(al);
                      }
                    });
                    this.setState({
                      bannerAdsGeneralCommunicationListData:
                        generalCommunicationBannerAdsList,
                      bannerAdsCampaignCommunicationListData:
                        campaignCommunicationBannerAdsList,
                    });
                  }}
                >
                  <span className="d-none d-sm-block fw-bold">Banner Adds</span>
                </NavLink>
              </NavItem>

              {/*inbox communication  */}
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: this.state.activeTabJustify === '4',
                  })}
                  onClick={async () => {
                    this.toggleCustomJustified('4');
                    const token = getToken();
                    const InboxScheduleList = await GKApi.get(
                      '/marketing/campaign/list?type=inbox',
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    let generalCommunicationInboxList = [];
                    let campaignCommunicationInboxList = [];
                    InboxScheduleList.data.forEach((al) => {
                      if (al.type === 'GENERAL') {
                        generalCommunicationInboxList.push(al);
                      } else {
                        campaignCommunicationInboxList.push(al);
                      }
                    });
                    this.setState({
                      inboxGeneralCommunicationListData:
                        generalCommunicationInboxList,
                      inboxCampaignCommunicationListData:
                        campaignCommunicationInboxList,
                    });
                  }}
                >
                  <span className="d-none d-sm-block fw-bold">
                    Inbox Messages
                  </span>
                </NavLink>
              </NavItem>

              {/*draft communication Tab  */}
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: this.state.activeTabJustify === '5',
                  })}
                  onClick={async () => {
                    this.toggleCustomJustified('5');
                    const token = getToken();
                    const DraftScheduleList = await GKApi.get(
                      '/marketing/campaign/list?type=draft',
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    let generalCommunicationDraftList = [];
                    let campaignCommunicationDraftList = [];
                    DraftScheduleList.data.forEach((al) => {
                      if (al.type === 'GENERAL') {
                        generalCommunicationDraftList.push(al);
                      } else {
                        campaignCommunicationDraftList.push(al);
                      }
                    });
                    this.setState({
                      draftGeneralCommunicationListData:
                        generalCommunicationDraftList,
                      draftCampaignCommunicationListData:
                        campaignCommunicationDraftList,
                    });
                  }}
                >
                  <span className="d-none d-sm-block fw-bold">Draft</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTabJustify}>
              <TabPane tabId="1" className="p-3">
                <MaterialTable
                  title="General Communication"
                  columns={columnsAllScheduleGeneral}
                  data={this.state.allGeneralCommunicationListData}
                  actions={[
                    this.state.communicationCreate === 1 && {
                      icon: () => (
                        <Button
                          style={{
                            color: '#081939',
                            backgroundColor: '#c2b56f',
                          }}
                        >
                          Create Communication
                        </Button>
                      ), // you can pass icon too

                      onClick: () => this.setState({ modal_standard: true }),
                      isFreeAction: true,
                    },
                    (rowData) => ({
                      icon: () => <i className="fas fa-eye fa-xs" />,
                      tooltip: 'Click to view details',

                      onClick: async (event, row) => {
                        const token = getToken();

                        const generalCampaignData = await GKApi.get(
                          `/marketing/general-com?id=${rowData.id}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        this.setState(
                          {
                            modal_standard2: true,
                            generalCampaignDetailsData:
                              generalCampaignData.data,
                            compAuthState: generalCampaignData.data.authLevel,
                          },
                          () => {}
                        );

                        const getAudienceData = await GKApi.get(
                          `/marketing/download-audience?id=${generalCampaignData.data.customerPresetId}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        if (getAudienceData?.data?.jsonData) {
                          this.setState({
                            customerPresetData: JSON.parse(
                              getAudienceData?.data?.jsonData
                            ),
                          });
                        }
                      },
                    }),
                    
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                    actionsColumnIndex: -1,
                    pageSize: 5,
                  }}
                />

                

                <MaterialTable
                  title="Campaign Communication"
                  columns={columnsAllScheduleCampaign}
                  data={this.state.allCampaignCommunicationListData}
                  actions={[
                    (rowData) => ({
                      icon: () => <i className="fas fa-eye fa-xs" />,
                      tooltip: 'Click to view details',

                      onClick: async (event, row) => {
                        const token = getToken();

                        const CampaignData = await GKApi.get(
                          `/marketing/campaign?id=${rowData.id}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        this.setState(
                          {
                            campaignDetailsModal: true,
                            campaignDetailsData: CampaignData.data,
                            compAuthState: CampaignData.data.authLevel,
                          },
                          () => {}
                        );

                        const getAudienceData = await GKApi.get(
                          `/marketing/download-audience?id=${CampaignData.data.customerPresetId}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        if (getAudienceData?.data?.jsonData) {
                          this.setState({
                            customerPresetData: JSON.parse(
                              getAudienceData?.data?.jsonData
                            ),
                          });
                        }
                      },
                    }),
                    (rowData) => this.state.canPlayPauseCampaign===1&& ({
                      icon: () =>  <i
                      className="fas fa-play fa-xs"
                      style={{
                        color: '#00FF00',
                      }}
                      
                    ></i>,
                      tooltip: 'Click to Play Communication',
                      hidden:rowData.status!=='Approved' || moment(new Date ()).isAfter(rowData.endDate) || rowData.campaignStatus===1 ||rowData.campaignStatus===3,
                      onClick: async (event, row) => {
                        event.preventDefault();

                        this.setState({
                          playPauseStatus: 'play',
                          playPauseCampaignModal: true,
                          playPauseId:row.id
                        });
                      },
                    }),
                    (rowData) => this.state.canPlayPauseCampaign===1&&({
                      icon: () =>  <i
                      className="fas fa-pause fa-xs"
                      style={{
                        color: '#ff0000',
                      }}
                      
                    ></i>,
                      tooltip: 'Click to Pause Communication',
                      hidden:rowData.status!=='Approved' || moment(new Date()).isAfter(rowData.endDate)|| rowData.campaignStatus===2 ||rowData.campaignStatus===3,
                      onClick: async (event, row) => {
                        event.preventDefault();

                        this.setState({
                          playPauseStatus: 'pause',
                          playPauseCampaignModal: true,
                          playPauseId:row.id
                        });
                      },
                    }),
                    (rowData) => this.state.canPlayPauseCampaign===1&&({
                      icon: () =>  <i
                      className="fas fa-stop fa-xs"
                      style={{
                        color: '#ff0000',
                      }}
                      
                    ></i>,
                      tooltip: 'Click to Stop Communication',
                      hidden:rowData.status!=='Approved' || moment(new Date ()).isAfter(rowData.endDate)|| rowData.campaignStatus===3,
                      onClick: async (event, row) => {
                        event.preventDefault();

                        this.setState({
                          playPauseStatus: 'stop',
                          playPauseCampaignModal: true,
                          playPauseId:row.id
                        });
                      },
                    }),
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                    actionsColumnIndex: -1,
                    pageSize: 5,
                  }}
                  localization={{
                    toolbar: {
                      exportCSVName: 'Export as CSV',
                      exportPDFName: 'Export as PDF',
                    },
                  }}
                />

                {/* Play pause modal */}
                <Modal
                  isOpen={this.state.playPauseCampaignModal}
                  toggle={this.tog_PlayPauseCampaign}
                >
                  <ModalHeader
                    toggle={() => {
                      this.setState({ playPauseCampaignModal: false });
                    }}
                  >
                    Confirmation
                  </ModalHeader>

                  <ModalBody>
                    <Col xl={12}>
                      <Row className="mb-3 mx-2">
                        Are you sure you want to {this.state.playPauseStatus}{' '}
                        this Campaign?
                      </Row>
                    </Col>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={async (event) => {
                        event.preventDefault();
                        const token = getToken();
                        const submit = await GKApi.patch(
                          `/marketing/campaign/paly-pause/${this.state.playPauseId}`,
                          {
                            status: this.state.playPauseStatus,
                            deviceInfo: this.state.deviceInfo,
                          },
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 143,
                            },
                          }
                        );
                        if (submit.status === 200) {
                          this.handleFetchCampaign()
                        }
                        this.setState((prevState) => ({
                          playPauseCampaignModal:
                            !prevState.playPauseCampaignModal,
                        }));
                        this.removeBodyCss();
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      type="button"
                      onClick={this.tog_PlayPauseCampaign}
                      color="light"
                      className="waves-effect"
                    >
                      No
                    </Button>
                  </ModalFooter>
                </Modal>
              </TabPane>
              <TabPane tabId="2" className="p-3">
                <MaterialTable
                  title="General Communication"
                  columns={columnsPushNotificationGeneral}
                  data={this.state.pushNotificationGeneralCommunicationListData}
                  actions={[
                    (rowData) => ({
                      icon: () => <i className="fas fa-eye fa-xs" />,
                      tooltip: 'Click to view details',

                      onClick: async (event, row) => {
                        this.setState(
                          {
                            pushNotificationComDetailsModal: true,
                            pushNotificationComDetails: rowData,
                          },
                          () => {}
                        );
                      },
                    }),
                    (rowData) => ({
                      icon: () => (
                        <i
                          className="fas fa-stop fa-xs"
                          style={{ color: 'red' }}
                        />
                      ),
                      tooltip: 'Click to stop general notification',
                      hidden:
                        this.state.notificationAndInboxStop === 0 ||
                        moment(new Date()).isAfter(rowData.startDate),
                      onClick: async (event, row) => {
                        
                        this.setState(
                          {
                            stopNotificationModal: true,
                            pushNotificationComDetails: rowData,
                          },
                          () => {}
                        );
                      },
                    }),
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                    actionsColumnIndex: -1,
                    pageSize: 5,
                  }}
                />


                <MaterialTable
                  title="Campaign Communication"
                  columns={columnsPushNotificationCampaign}
                  data={
                    this.state.pushNotificationCampaignCommunicationListData
                  }
                  actions={[
                    (rowData) => ({
                      icon: () => <i className="fas fa-eye fa-xs" />,
                      tooltip: 'Click to view details',

                      onClick: async (event, row) => {
                        this.setState(
                          {
                            pushNotificationComDetailsModal: true,
                            pushNotificationComDetails: rowData,
                          },
                          () => {}
                        );
                      },
                    }),
                    (rowData) => ({
                      icon: () => <i className="fas fa-stop fa-xs" style={{ color: 'red' }} />,
                      tooltip: 'Click to stop Campaign notification',
                      hidden: this.state.notificationAndInboxStop === 0 ||
                      moment(new Date()).isAfter(rowData.startDate),
                      onClick: async (event, row) => {
                        this.setState(
                          {
                            stopNotificationModal: true,
                            pushNotificationComDetails: rowData,
                          },
                          () => {}
                        );
                      },
                    }),
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                    actionsColumnIndex: -1,
                    pageSize: 5,
                  }}
                  localization={{
                    toolbar: {
                      exportCSVName: 'Export as CSV',
                      exportPDFName: 'Export as PDF',
                    },
                  }}
                />

                <Modal
                  isOpen={this.state.stopNotificationModal}
                  toggle={this.tog_stopNotificationModal}
                  size="lg"
                  // style={{ maxWidth: '900px', width: '100%' }}
                >
                  <ModalHeader
                    toggle={() =>
                      this.setState({ stopNotificationModal: false })
                    }
                  >
                    Stop Notification
                  </ModalHeader>
                  {/* Edit */}
                  <ModalBody>
                    <Row>
                      <div>
                        Are you sure you wan to stop notification for this
                        communication?
                      </div>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={async () => {
                        let token = getToken();
                        await GKApi.put(
                          `/marketing/communication/event-status/disable`,
                          {
                            cmpId: 171,
                            communicationId:
                              this.state.pushNotificationComDetails.id,
                            eventRuleId:
                              this.state.pushNotificationComDetails.eventRuleId,
                            communicationType:
                              this.state.pushNotificationComDetails.type ===
                              'GENERAL'
                                ? 'general'
                                : 'campaign',
                            eventType: 'Notification',
                            isNotification:
                              this.state.pushNotificationComDetails
                                .isNotification,
                            isInbox:
                              this.state.pushNotificationComDetails.isInbox,
                            deviceInfo: this.state.deviceInfo,
                          },
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        const pushNotificationScheduleList = await GKApi.get(
                          '/marketing/campaign/list?type=notification',
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        let generalCommunicationPushNotificationList = [];
                        let campaignCommunicationPushNotificationList = [];
                        pushNotificationScheduleList.data.forEach((al) => {
                          if (al.type === 'GENERAL') {
                            generalCommunicationPushNotificationList.push(al);
                          } else {
                            campaignCommunicationPushNotificationList.push(al);
                          }
                        });
                        this.setState({
                          pushNotificationGeneralCommunicationListData:
                            generalCommunicationPushNotificationList,
                          pushNotificationCampaignCommunicationListData:
                            campaignCommunicationPushNotificationList,
                        });
                        this.tog_stopNotificationModal();
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() => {
                        this.tog_stopNotificationModal();
                      }}
                    >
                      No
                    </Button>
                  </ModalFooter>
                </Modal>
              </TabPane>
              <TabPane tabId="3" className="p-3">
                <MaterialTable
                  title="General Communication"
                  columns={columnsBannerAdsGeneral}
                  data={this.state.bannerAdsGeneralCommunicationListData}
                  actions={[
                    (rowData) => ({
                      icon: () => <i className="fas fa-eye fa-xs" />,
                      tooltip: 'Click to view details',

                      onClick: async (event, row) => {
                        const token = getToken();

                        const generalCampaignData = await GKApi.get(
                          `/marketing/general-com?id=${rowData.id}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        this.setState(
                          {
                            bannerComDetailsModal: true,
                            bannerComDetails: row,
                            bannerUrl: generalCampaignData.data.bannerUrl,
                          },
                          () => {}
                        );
                      },
                    }),
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                    actionsColumnIndex: -1,
                    pageSize: 5,
                  }}
                />

                <MaterialTable
                  title="Campaign Communication"
                  columns={columnsBannerAdsCampaign}
                  data={this.state.bannerAdsCampaignCommunicationListData}
                  actions={[
                    (rowData) => ({
                      icon: () => <i className="fas fa-eye fa-xs" />,
                      tooltip: 'Click to view details',

                      onClick: async (event, row) => {
                        const token = getToken();

                        const CampaignData = await GKApi.get(
                          `/marketing/campaign?id=${rowData.id}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        this.setState(
                          {
                            bannerComDetailsModal: true,
                            bannerComDetails: row,
                            bannerUrl: CampaignData.data.bannerUrl,
                          },
                          () => {}
                        );
                      },
                    }),
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                    actionsColumnIndex: -1,
                    pageSize: 5,
                  }}
                  localization={{
                    toolbar: {
                      exportCSVName: 'Export as CSV',
                      exportPDFName: 'Export as PDF',
                    },
                  }}
                />
              </TabPane>
              <TabPane tabId="4" className="p-3">
                <MaterialTable
                  title="General Communication"
                  columns={columnsInboxGeneral}
                  data={this.state.inboxGeneralCommunicationListData}
                  actions={[
                    (rowData) => ({
                      icon: () => <i className="fas fa-eye fa-xs" />,
                      tooltip: 'Click to view details',

                      onClick: async (event, row) => {
                        this.setState(
                          {
                            inboxComDetailsModal: true,
                            inboxComDetails: rowData,
                          },
                          () => {}
                        );
                      },
                    }),
                    (rowData) => ({
                      icon: () => (
                        <i
                          className="fas fa-stop fa-xs"
                          style={{ color: 'red' }}
                        />
                      ),
                      tooltip: 'Click to stop general inbox',
                      hidden:
                        this.state.notificationAndInboxStop === 0 ||
                        moment(new Date()).isAfter(rowData.startDate),
                      onClick: async (event, row) => {
                        
                        this.setState(
                          {
                            stopInboxModal: true,
                            inboxComDetails: rowData,
                          },
                          () => {}
                        );
                      },
                    }),
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                    actionsColumnIndex: -1,
                    pageSize: 5,
                  }}
                />

                

                <MaterialTable
                  title="Campaign Communication"
                  columns={columnsInboxCampaign}
                  data={this.state.inboxCampaignCommunicationListData}
                  actions={[
                    (rowData) => ({
                      icon: () => <i className="fas fa-eye fa-xs" />,
                      tooltip: 'Click to view details',

                      onClick: async (event, row) => {
                        this.setState(
                          {
                            inboxComDetailsModal: true,
                            inboxComDetails: rowData,
                          },
                          () => {}
                        );
                      },
                    }),
                    (rowData) => ({
                      icon: () => (
                        <i
                          className="fas fa-stop fa-xs"
                          style={{ color: 'red' }}
                        />
                      ),
                      tooltip: 'Click to stop campaign inbox',
                      hidden:
                        this.state.notificationAndInboxStop === 0 ||
                        moment(new Date()).isAfter(rowData.startDate),
                      onClick: async (event, row) => {
                        
                        this.setState(
                          {
                            stopInboxModal: true,
                            inboxComDetails: rowData,
                          },
                          () => {}
                        );
                      },
                    }),
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                    actionsColumnIndex: -1,
                    pageSize: 5,
                  }}
                  localization={{
                    toolbar: {
                      exportCSVName: 'Export as CSV',
                      exportPDFName: 'Export as PDF',
                    },
                  }}
                />
                <Modal
                  isOpen={this.state.stopInboxModal}
                  toggle={this.tog_stopInboxModal}
                  size="lg"
                  // style={{ maxWidth: '900px', width: '100%' }}
                >
                  <ModalHeader
                    toggle={() => this.setState({ stopInboxModal: false })}
                  >
                    Stop Inbox
                  </ModalHeader>
                  {/* Edit */}
                  <ModalBody>
                    <Row>
                      <div>
                        Are you sure you wan to stop inbox for this
                        communication?
                      </div>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={async () => {
                        let token = getToken();
                        await GKApi.put(
                          `/marketing/communication/event-status/disable`,
                          {
                            cmpId: 171,
                            communicationId: this.state.inboxComDetails.id,
                            eventRuleId: this.state.inboxComDetails.eventRuleId,
                            communicationType:
                              this.state.inboxComDetails.type === 'GENERAL'
                                ? 'general'
                                : 'campaign',
                            eventType: 'Inbox',
                            isNotification:
                              this.state.inboxComDetails.isNotification,
                            isInbox: this.state.inboxComDetails.isInbox,
                            deviceInfo: this.state.deviceInfo,
                          },
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        const InboxScheduleList = await GKApi.get(
                          '/marketing/campaign/list?type=inbox',
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        let generalCommunicationInboxList = [];
                        let campaignCommunicationInboxList = [];
                        InboxScheduleList.data.forEach((al) => {
                          if (al.type === 'GENERAL') {
                            generalCommunicationInboxList.push(al);
                          } else {
                            campaignCommunicationInboxList.push(al);
                          }
                        });
                        this.setState({
                          inboxGeneralCommunicationListData:
                            generalCommunicationInboxList,
                          inboxCampaignCommunicationListData:
                            campaignCommunicationInboxList,
                        });
                        this.tog_stopInboxModal();
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() => {
                        this.tog_stopInboxModal();
                      }}
                    >
                      No
                    </Button>
                  </ModalFooter>
                </Modal>
              </TabPane>
              <TabPane tabId="5" className="p-3">
                <MaterialTable
                  title="General Communication"
                  columns={columnsDraftScheduleGeneral}
                  data={this.state.draftGeneralCommunicationListData}
                  actions={[
                    (rowData) => ({
                      icon: () => <i className="fas fa-eye fa-xs" />,
                      tooltip: 'Click to Edit Draft',

                      onClick: async (event, row) => {
                        const token = getToken();

                        const generalCampaignData = await GKApi.get(
                          `/marketing/general-com?id=${rowData.id}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        this.setState(
                          {
                            modalDraftGeneral: true,
                            generalCampaignDetailsData:
                              generalCampaignData.data,
                            compAuthState: generalCampaignData.data.authLevel,
                          },
                          () => {}
                        );

                        // const getAudienceData = await GKApi.get(
                        //   `/marketing/download-audience?id=${generalCampaignData.data.customerPresetId}`,

                        //   {
                        //     headers: {
                        //       Authorization: token,
                        //     },
                        //   }
                        // );

                        // if (getAudienceData?.data?.jsonData) {
                        //   this.setState({
                        //     customerPresetData: JSON.parse(
                        //       getAudienceData?.data?.jsonData
                        //     ),
                        //   });
                        // }
                      },
                    }),
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                    actionsColumnIndex: -1,
                    pageSize: 5,
                  }}
                />

                <MaterialTable
                  title="Campaign Communication"
                  columns={columnsDraftScheduleCampaign}
                  data={this.state.draftCampaignCommunicationListData}
                  actions={[
                    (rowData) => ({
                      icon: () => <i className="fas fa-eye fa-xs" />,
                      tooltip: 'Click to Edit Draft',

                      onClick: async (event, row) => {
                        const token = getToken();

                        const CampaignData = await GKApi.get(
                          `/marketing/campaign?id=${rowData.id}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        this.setState(
                          {
                            modalDraftCampaign: true,
                            campaignDetailsData: CampaignData.data,
                            compAuthState: CampaignData.data.authLevel,
                          },
                          () => {}
                        );
                      },
                    }),
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                    actionsColumnIndex: -1,
                    pageSize: 5,
                  }}
                  localization={{
                    toolbar: {
                      exportCSVName: 'Export as CSV',
                      exportPDFName: 'Export as PDF',
                    },
                  }}
                />
              </TabPane>
            </TabContent>
            <Modal
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}
              size="xl"
              // style={{ maxWidth: '900px', width: '100%' }}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_standard: false })}
              >
                Create Communication
              </ModalHeader>
              {/* Edit */}
              <ModalBody>
                <CreateCampaign
                  modalClose={this.tog_standard}
                  fetchCampaign={this.handleFetchCampaign}
                />
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modalDraftGeneral}
              toggle={this.tog_ModalDraftGeneral}
              size="xl"
              // style={{ maxWidth: '900px', width: '100%' }}
            >
              <ModalHeader
                toggle={() => this.setState({ modalDraftGeneral: false })}
              >
                Edit draft Communication
              </ModalHeader>
              {/* Edit */}
              <ModalBody>
                <EditDraftGeneral
                  modalClose={this.tog_ModalDraftGeneral}
                  generalDraftData={this.state.generalCampaignDetailsData}
                  fetchCampaign={this.handleFetchCampaign}
                />
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modalDraftCampaign}
              toggle={this.tog_ModalDraftCampaign}
              size="xl"
              // style={{ maxWidth: '900px', width: '100%' }}
            >
              <ModalHeader
                toggle={() => this.setState({ modalDraftCampaign: false })}
              >
                Edit draft Communication
              </ModalHeader>
              {/* Edit */}
              <ModalBody>
                <EditDraftCampaign
                  modalClose={this.tog_ModalDraftCampaign}
                  campaignDraftData={this.state.campaignDetailsData}
                  fetchCampaign={this.handleFetchCampaign}
                />
              </ModalBody>
            </Modal>

            {/* General Communication details */}
            <Modal
              isOpen={this.state.modal_standard2}
              toggle={this.tog_standard2}
              size="lg"
              style={{ maxWidth: '700px', width: '100%' }}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_standard2: false })}
              >
                General Communication Details
              </ModalHeader>

              <ModalBody className="px-5">
                <h4>{this.state.generalCampaignDetailsData.title}</h4>

                <Card>
                  <CardBody>
                    <h5>Campaign Status</h5>
                    <div className="my-5 d-flex gap-4">
                      <div>
                        <Button
                          style={{
                            color:
                              this.state.generalCampaignDetailsData
                                .authLevel === 1 && '#081939',
                            backgroundColor:
                              this.state.generalCampaignDetailsData
                                .authLevel === 1 && '#c2b56f',
                          }}
                        >
                          <div>
                            {/* <div
                            style={{
                              borderTop: '5px solid #fff ',
                              borderRadius: '5px !important',
                            }}
                          ></div> */}
                            <h6>Initiator</h6>
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{
                            color:
                              this.state.generalCampaignDetailsData
                                .authLevel === 2 && '#081939',
                            backgroundColor:
                              this.state.generalCampaignDetailsData
                                .authLevel === 2 && '#c2b56f',
                          }}
                        >
                          <div>
                            {/* <div
                            style={{
                              borderTop: '5px solid #fff ',
                              borderRadius: '5px !important',
                            }}
                          ></div> */}
                            <h6>Reviewer</h6>
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{
                            color:
                              this.state.generalCampaignDetailsData
                                .authLevel === 3 && '#081939',
                            backgroundColor:
                              this.state.generalCampaignDetailsData
                                .authLevel === 3 && '#c2b56f',
                          }}
                        >
                          <div>
                            {/* <div
                            style={{
                              borderTop: '5px solid #fff ',
                              borderRadius: '5px !important',
                            }}
                          ></div> */}
                            <h6>CCO</h6>
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{
                            color:
                              this.state.generalCampaignDetailsData
                                .authLevel === 4 && '#081939',
                            backgroundColor:
                              this.state.generalCampaignDetailsData
                                .authLevel === 4 && '#c2b56f',
                          }}
                        >
                          <div>
                            {/* <div
                            style={{
                              borderTop: '5px solid #fff ',
                              borderRadius: '5px !important',
                            }}
                          ></div> */}
                            <h6>CFO</h6>
                          </div>
                        </Button>
                      </div>
                      {/* <div>
                        <Button
                          style={{
                            color:
                              this.state.generalCampaignDetailsData
                                .authLevel === 5 && '#081939',
                            backgroundColor:
                              this.state.generalCampaignDetailsData
                                .authLevel === 5 && '#c2b56f',
                          }}
                        >
                          <div>
                            
                            <h6>CTO</h6>
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{
                            color:
                              this.state.generalCampaignDetailsData
                                .authLevel === 6 && '#081939',
                            backgroundColor:
                              this.state.generalCampaignDetailsData
                                .authLevel === 6 && '#c2b56f',
                          }}
                        >
                          <div>
                            
                            <h6>CEO</h6>
                          </div>
                        </Button>
                      </div> */}
                    </div>
                    <h6 className="fw-bold">Details</h6>
                    <Row>
                      <Row className="my-2">
                        <Col xl={4}>
                          <h6 className="fw-bold">Communication Title</h6>
                          <p>
                            {
                              this.state.generalCampaignDetailsData
                                .campaignTitle
                            }
                          </p>
                        </Col>
                        <Col xl={4}>
                          <h6 className="fw-bold">Campaign Tenure</h6>
                          <p>
                            {moment(
                              this.state.generalCampaignDetailsData.startDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm A Do MMM')}{' '}
                            -{' '}
                            {moment(
                              this.state.generalCampaignDetailsData.endDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm A Do MMM')}
                          </p>
                        </Col>
                        <Col xl={4}>
                          <h6 className="fw-bold">Campaign Type</h6>
                          {this.state.generalCampaignDetailsData.comType ===
                          1 ? (
                            <p>Greeting</p>
                          ) : this.state.generalCampaignDetailsData.comType ===
                            2 ? (
                            <p>Announcement</p>
                          ) : this.state.generalCampaignDetailsData.comType ===
                            3 ? (
                            <p>Reminder</p>
                          ) : (
                            <p>Others</p>
                          )}
                        </Col>
                      </Row>
                      <Row className="my-2">
                        {/* <Col xl={4}>
                        <h6 className="fw-bold">Campaign Budget</h6>
                        <p>BDT 10,000</p>
                      </Col> */}
                        <Col xl={4}>
                          <h6 className="fw-bold">Uploaded Documents</h6>

                          <a
                            rel="noopener noreferrer"
                            href={this.state.generalCampaignDetailsData.docKey}
                            target="_blank"
                          >
                            View Document
                          </a>
                        </Col>
                        <Col xl={4}>
                          <h6 className="fw-bold">Submitted By</h6>
                          <p>
                            {this.state.generalCampaignDetailsData.staffName}
                          </p>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col xl={4}>
                          <h6 className="fw-bold">Date Submitted</h6>
                          <p>
                            {moment(
                              this.state.generalCampaignDetailsData.submitDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('DD/MM/YYYY')}
                          </p>
                        </Col>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>
                <h7 className="fw-bold">Content</h7>
                <Row>
                  <Card>
                    <CardBody>
                      <h6 className="fw-bold">Campaign Channel</h6>
                      <Row>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: this.state.activeTab === '5',
                              })}
                              onClick={() => {
                                this.toggleTab('5');
                              }}
                            >
                              <span className="d-none d-sm-block">
                                App Banner
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: this.state.activeTab === '6',
                              })}
                              onClick={() => {
                                this.toggleTab('6');
                              }}
                            >
                              <span className="d-none d-sm-block">
                                Push Notifications
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: this.state.activeTab === '7',
                              })}
                              onClick={() => {
                                this.toggleTab('7');
                              }}
                            >
                              <span className="d-none d-sm-block">Inbox</span>
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="5" className="p-3">
                            <Row>
                              <Col sm="12">
                                <CardText>
                                  <div className="border border-1 rounded p-4">
                                    <Row className="py-2 px-4">
                                      {/* <Dropzone
                                      onDrop={(acceptedFiles) =>
                                        this.handleAcceptedFiles(acceptedFiles)
                                      }
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone> */}
                                      {/* <div style={{width:"5rem", height: "10"}}>
  
                                    </div> */}
                                      {this.state.generalCampaignDetailsData
                                        ?.bannerUrl && (
                                        <img
                                          data-dz-thumbnail=""
                                          // height="50%"
                                          // width="50%"
                                          // className="avatar-sm rounded bg-light"
                                          alt={'app banner'}
                                          src={
                                            this.state
                                              .generalCampaignDetailsData
                                              ?.bannerUrl
                                          }
                                        />
                                      )}
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      >
                                        {/* {this.state.selectedFilesBanner.map(
                                        (f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + '-file'}
                                            >
                                              <div className="p-2">
                                                
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={this.state.generalCampaignDetailsData?.bannerUrl}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted fw-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>
                                                        {f.formattedSize}
                                                      </strong>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        }
                                      )} */}
                                      </div>
                                    </Row>

                                    <Row className="py-2">
                                      <span>
                                        <h6 className="fw-bold">
                                          {' '}
                                          Target Link
                                        </h6>
                                      </span>
                                      <span>
                                        <Col>
                                          <Input
                                            type="text"
                                            value={
                                              this.state
                                                .generalCampaignDetailsData
                                                ?.bannerLink
                                            }
                                            placeholder="Https:/goldkinen.com/referral"
                                            id="gold-price-input"
                                            // onChange={handleGoldPriceChange}
                                            disabled={true}
                                          />
                                        </Col>
                                      </span>
                                    </Row>
                                  </div>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="6" className="p-3">
                            <Row>
                              <Col sm="12">
                                <CardText>
                                  <div className="border border-1 rounded p-4">
                                    <div className="py-4">
                                      <div className="pb-2">
                                        <h5 className="card-title">Title</h5>
                                        <Input
                                          type="text"
                                          value={
                                            this.state
                                              .generalCampaignDetailsData
                                              ?.notificationTitle
                                          }
                                          id="gold-price-input"
                                          disabled
                                        />
                                      </div>
                                      <div className="pb-2">
                                        <h5 className="card-title">
                                          Description
                                        </h5>
                                        <div>
                                          <TextField
                                            className="w-full"
                                            variant="outlined"
                                            type="text"
                                            multiline
                                            minRows={2}
                                            style={{
                                              width: '100%',
                                            }}
                                            value={
                                              this.state
                                                .generalCampaignDetailsData
                                                ?.notificationMsg
                                            }
                                            disabled
                                            
                                          />
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="7" className="p-3">
                            <Row>
                              <Col sm="12">
                                <CardText>
                                  <div className="border border-1 rounded p-4">
                                    <div className="py-4">
                                      
                                      <div className="pb-2">
                                        <h5 className="card-title">
                                          Description
                                        </h5>
                                        <div>
                                          <TextField
                                            className="w-full"
                                            variant="outlined"
                                            type="text"
                                            multiline
                                            minRows={2}
                                            style={{
                                              width: '100%',
                                            }}
                                            value={
                                              this.state
                                                .generalCampaignDetailsData
                                                ?.inboxMsg
                                            }
                                            disabled
                                           
                                          />
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <h6 className="fw-bold">Campaign Audience</h6>
                      <div className="my-2">
                        {this.state.generalCampaignDetailsData.audienceType ===
                        1
                          ? 'All Users'
                          : this.state.generalCampaignDetailsData
                              .audienceType === 2
                          ? 'All New Users'
                          : 'Custom User'}
                      </div>
                      {this.state.customerPresetData.length > 0 &&
                        this.state.customerPresetData.map((pd) => (
                          <Row>
                            <Col xl={5}>{pd.displayName}</Col>
                            <Col xl={7}>
                              {pd.operator === 'range' ? (
                                <div className="d-flex gap-2">
                                  <p>From</p>
                                  <p>{pd.data.from}</p>
                                  <p>To</p>
                                  <p>{pd.data.to}</p>
                                </div>
                              ) : pd.operator === 'equal' ? (
                                <div className="d-flex gap-2">
                                  <p>=</p>
                                  <p>{pd.data.value}</p>
                                </div>
                              ) :  pd.operator === 'in' ? (
                                <div className="d-flex gap-2">
                                  <p>=</p>
                                  <p>{pd.data.value}</p>
                                </div>
                              ) :(
                                ''
                              )}
                            </Col>
                          </Row>
                        ))}
                    </CardBody>
                  </Card>
                </Row>
              </ModalBody>
              {this.state.userAuthState - this.state.compAuthState === 1 && (
                <div>
                  {this.state.generalCampaignDetailsData.status ===
                    'Pending' && (
                    <ModalFooter>
                      {(this.state.communicationApprove === 1 ||
                        this.state.communicationSubmit === 1) && (
                        <Button
                          onClick={async () => {
                            this.tog_standard2();
                            this.tog_generalCampaignApproval();
                          }}
                        >
                          Approve
                        </Button>
                      )}
                      {this.state.communicationReject === 1 && (
                        <Button
                          onClick={() => {
                            this.tog_standard2();
                            this.tog_generalCampaignRejection();
                          }}
                        >
                          Reject
                        </Button>
                      )}
                    </ModalFooter>
                  )}
                </div>
              )}
            </Modal>
            {/* campaign Communication details */}
            <Modal
              isOpen={this.state.campaignDetailsModal}
              toggle={this.tog_campaignDetails}
              size="lg"
              style={{ maxWidth: '700px', width: '100%' }}
            >
              <ModalHeader
                toggle={() => this.setState({ campaignDetailsModal: false })}
              >
                Campaign Communication Details
              </ModalHeader>

              <ModalBody className="px-5">
                <h4>{this.state.campaignDetailsData.campaignTitle}</h4>

                <Card>
                  <CardBody>
                    <h5>Campaign Status</h5>
                    <div className="my-5 d-flex gap-4">
                      <div>
                        <Button
                          style={{
                            color:
                              this.state.campaignDetailsData.authLevel === 1 &&
                              '#081939',
                            backgroundColor:
                              this.state.campaignDetailsData.authLevel === 1 &&
                              '#c2b56f',
                          }}
                        >
                          <div>
                            {/* <div
                            style={{
                              borderTop: '5px solid #fff ',
                              borderRadius: '5px !important',
                            }}
                          ></div> */}
                            <h6>Initiator</h6>
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{
                            color:
                              this.state.campaignDetailsData.authLevel === 2 &&
                              '#081939',
                            backgroundColor:
                              this.state.campaignDetailsData.authLevel === 2 &&
                              '#c2b56f',
                          }}
                        >
                          <div>
                            {/* <div
                            style={{
                              borderTop: '5px solid #fff ',
                              borderRadius: '5px !important',
                            }}
                          ></div> */}
                            <h6>Reviewer</h6>
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{
                            color:
                              this.state.campaignDetailsData.authLevel === 3 &&
                              '#081939',
                            backgroundColor:
                              this.state.campaignDetailsData.authLevel === 3 &&
                              '#c2b56f',
                          }}
                        >
                          <div>
                            {/* <div
                            style={{
                              borderTop: '5px solid #fff ',
                              borderRadius: '5px !important',
                            }}
                          ></div> */}
                            <h6>CCO</h6>
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{
                            color:
                              this.state.campaignDetailsData.authLevel === 4 &&
                              '#081939',
                            backgroundColor:
                              this.state.campaignDetailsData.authLevel === 4 &&
                              '#c2b56f',
                          }}
                        >
                          <div>
                            {/* <div
                            style={{
                              borderTop: '5px solid #fff ',
                              borderRadius: '5px !important',
                            }}
                          ></div> */}
                            <h6>CFO</h6>
                          </div>
                        </Button>
                      </div>
                      {/* <div>
                        <Button
                          style={{
                            color:
                              this.state.campaignDetailsData.authLevel === 5 &&
                              '#081939',
                            backgroundColor:
                              this.state.campaignDetailsData.authLevel === 5 &&
                              '#c2b56f',
                          }}
                        >
                          <div>
                            
                            <h6>CTO</h6>
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{
                            color:
                              this.state.campaignDetailsData.authLevel === 6 &&
                              '#081939',
                            backgroundColor:
                              this.state.campaignDetailsData.authLevel === 6 &&
                              '#c2b56f',
                          }}
                        >
                          <div>
                            
                            <h6>CEO</h6>
                          </div>
                        </Button>
                      </div> */}
                    </div>
                    <h6 className="fw-bold">Details</h6>
                    <Row>
                      <Row className="my-2">
                        <Col xl={4}>
                          <h6 className="fw-bold">Communication Title</h6>
                          <p>{this.state.campaignDetailsData.title}</p>
                        </Col>
                        <Col xl={4}>
                          <h6 className="fw-bold">Campaign Tenure</h6>
                          <p>
                            {moment(
                              this.state.campaignDetailsData.startDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm A Do MMM')}{' '}
                            -{' '}
                            {moment(
                              this.state.campaignDetailsData.endDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm A Do MMM')}
                          </p>
                        </Col>
                        <Col xl={4}>
                          <h6 className="fw-bold">Campaign Type</h6>
                          {this.state.campaignDetailsData.campaignType}
                        </Col>
                      </Row>
                      <Row className="my-2">
                        {(this.state.campaignDetailsData.campaignType ===
                          'Buy - Promo' ||
                          this.state.campaignDetailsData.campaignType ===
                            'Sell - Promo' ||
                          this.state.campaignDetailsData.campaignType ===
                            'Collect - Promo') && (
                          <Col xl={4}>
                            <h6 className="fw-bold">Promo Code</h6>
                            <p>{this.state.campaignDetailsData.promoCode}</p>
                          </Col>
                        )}
                        <Col xl={4}>
                          <h6 className="fw-bold">Uploaded Documents</h6>

                          <a
                            rel="noopener noreferrer"
                            href={this.state.campaignDetailsData.docUrl}
                            target="_blank"
                          >
                            View Document
                          </a>
                        </Col>
                        <Col xl={4}>
                          <h6 className="fw-bold">Campaign Budget</h6>
                          <p>BDT {this.state.campaignDetailsData.budget}</p>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col xl={4}>
                          <h6 className="fw-bold">Offer type</h6>
                          <p>{this.state.campaignDetailsData.offerType}</p>
                        </Col>
                        <Col xl={4}>
                          <h6 className="fw-bold">Offer Value</h6>
                          <p>
                            {this.state.campaignDetailsData.offerType !==
                              'PERCENTAGE' && 'BDT '}
                            {this.state.campaignDetailsData.offerValue}
                            {this.state.campaignDetailsData.offerType ===
                              'PERCENTAGE' && '%'}
                          </p>
                        </Col>
                        <Col xl={4}>
                          <h6 className="fw-bold">Submitted By</h6>
                          <p>{this.state.campaignDetailsData.staffName}</p>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col xl={4}>
                          <h6 className="fw-bold">Date Submitted</h6>
                          <p>
                            {moment(
                              this.state.campaignDetailsData.submitDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('DD/MM/YYYY')}
                          </p>
                        </Col>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>
                <h6 className="fw-bold py-4">KPI</h6>
                {this.state.campaignDetailsData.kpi?.map((kpi) => (
                  <Row className="py-2">
                    <Col xl={5}>{kpi.title}</Col>
                    <Col xl={7}>
                      {kpi.value}{' '}
                      {kpi.title === 'Active Customer Percentage'
                        ? '%'
                        : ''}
                    </Col>
                  </Row>
                ))}

                <div className="py-2">
                  <h6 className="fw-bold py-4">Modality</h6>
                  {this.state.campaignDetailsData.modality?.map((modality) => (
                    <Row className="py-2">
                      <Col xl={5}>{modality.title}</Col>
                      <Col xl={7}>{modality.value}</Col>
                    </Row>
                  ))}
                </div>
                <h7 className="fw-bold">Content</h7>
                <Row>
                  <Card>
                    <CardBody>
                      <h6 className="fw-bold">Campaign Channel</h6>
                      <Row>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: this.state.activeTab === '5',
                              })}
                              onClick={() => {
                                this.toggleTab('5');
                              }}
                            >
                              <span className="d-none d-sm-block">
                                App Banner
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: this.state.activeTab === '6',
                              })}
                              onClick={() => {
                                this.toggleTab('6');
                              }}
                            >
                              <span className="d-none d-sm-block">
                                Push Notifications
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: this.state.activeTab === '7',
                              })}
                              onClick={() => {
                                this.toggleTab('7');
                              }}
                            >
                              <span className="d-none d-sm-block">Inbox</span>
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="5" className="p-3">
                            <Row>
                              <Col sm="12">
                                <CardText>
                                  <div className="border border-1 rounded p-4">
                                    <Row className="py-2 px-4">
                                      {this.state.campaignDetailsData
                                        ?.bannerUrl && (
                                        <img
                                          data-dz-thumbnail=""
                                          // height="50%"
                                          // width="50%"
                                          // className="avatar-sm rounded bg-light"
                                          alt={'app banner'}
                                          src={
                                            this.state.campaignDetailsData
                                              ?.bannerUrl
                                          }
                                        />
                                      )}
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      ></div>
                                    </Row>

                                    <Row className="py-2">
                                      <span>
                                        <h6 className="fw-bold">
                                          {' '}
                                          Target Link
                                        </h6>
                                      </span>
                                      <span>
                                        <Col>
                                          <Input
                                            type="text"
                                            value={
                                              this.state.campaignDetailsData
                                                ?.bannerLink
                                            }
                                            id="gold-price-input"
                                            disabled={true}
                                            // onChange={handleGoldPriceChange}
                                          />
                                        </Col>
                                      </span>
                                    </Row>
                                  </div>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="6" className="p-3">
                            <Row>
                              <Col sm="12">
                                <CardText>
                                  <div className="border border-1 rounded p-4">
                                    <div className="py-4">
                                      <div className="pb-2">
                                        <h5 className="card-title">Title</h5>
                                        <Input
                                          type="text"
                                          value={
                                            this.state.campaignDetailsData
                                              ?.notificationTitle
                                          }
                                          id="gold-price-input"
                                          disabled={true}
                                        />
                                      </div>
                                      <div className="pb-2">
                                        <h5 className="card-title">
                                          Description
                                        </h5>
                                        <div>
                                          <TextField
                                            className="w-full"
                                            variant="outlined"
                                            type="text"
                                            multiline
                                            minRows={2}
                                            style={{
                                              width: '100%',
                                            }}
                                            value={
                                              this.state.campaignDetailsData
                                                ?.notificationMsg
                                            }
                                            disabled={true}
                                            // placeholder="Enter your comment here ..."
                                            // {...register('comment')}
                                          />
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="7" className="p-3">
                            <Row>
                              <Col sm="12">
                                <CardText>
                                  <div className="border border-1 rounded p-4">
                                    <div className="py-4">
                                      
                                      <div className="pb-2">
                                        <h5 className="card-title">
                                          Description
                                        </h5>
                                        <div>
                                          <TextField
                                            className="w-full"
                                            variant="outlined"
                                            type="text"
                                            multiline
                                            minRows={2}
                                            style={{
                                              width: '100%',
                                            }}
                                            value={
                                              this.state.campaignDetailsData
                                                ?.inboxMsg
                                            }
                                            disabled={true}
                                            
                                          />
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <h6 className="fw-bold">Campaign Audience</h6>
                      <div className="my-2">
                        {this.state.campaignDetailsData.audienceType === 1
                          ? 'All Users'
                          : this.state.campaignDetailsData.audienceType === 2
                          ? 'All New Users'
                          : 'Custom User'}
                      </div>
                      {this.state.customerPresetData.length > 0 &&
                        this.state.customerPresetData.map((pd) => (
                          <Row>
                            <Col xl={5}>{pd.displayName}</Col>
                            <Col xl={7}>
                              {pd.operator === 'range' ? (
                                <div className="d-flex gap-2">
                                  <p>From</p>
                                  <p>{pd.data.from}</p>
                                  <p>To</p>
                                  <p>{pd.data.to}</p>
                                </div>
                              ) : pd.operator === 'equal' ? (
                                <div className="d-flex gap-2">
                                  <p>=</p>
                                  <p>{pd.data.value}</p>
                                </div>
                              ) :  pd.operator === 'in' ? (
                                <div className="d-flex gap-2">
                                  <p>=</p>
                                  <p>{pd.data.value}</p>
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                        ))}
                    </CardBody>
                  </Card>
                </Row>
              </ModalBody>
              {this.state.userAuthState - this.state.compAuthState === 1 && (
                <div>
                  {this.state.campaignDetailsData.status === 'Pending' && (
                    <ModalFooter>
                      {(this.state.communicationApprove === 1 ||
                        this.state.communicationSubmit === 1) && (
                        <Button
                          onClick={async () => {
                            this.tog_campaignDetails();
                            this.tog_campaignApproval();
                          }}
                        >
                          Approve
                        </Button>
                      )}
                      {this.state.communicationReject === 1 && (
                        <Button
                          onClick={() => {
                            this.tog_campaignDetails();
                            this.tog_campaignRejection();
                          }}
                        >
                          Reject
                        </Button>
                      )}
                    </ModalFooter>
                  )}
                </div>
              )}
            </Modal>

            {/*campaign approval modal */}
            <Modal
              isOpen={this.state.campaignApproveModal}
              toggle={this.tog_campaignApproval}
              size="lg"
              // style={{ maxWidth: '900px', width: '100%' }}
            >
              <ModalHeader
                toggle={() => this.setState({ campaignApproveModal: false })}
              >
                Approve Communication
              </ModalHeader>
              {/* Edit */}
              <ModalBody>
                <Row>
                  <div>Are you sure you wan to approve the communication?</div>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={async () => {
                    let token = getToken();
                    await GKApi.put(
                      `/marketing/communication/approve`,
                      {
                        cmpId: 141,
                        id: this.state.campaignDetailsData.id,
                        type: 'campaign',
                        deviceInfo: this.state.deviceInfo,
                      },
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );
                    const allScheduleList = await GKApi.get(
                      '/marketing/campaign/list?type=all',
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    let generalCommunicationAllList = [];
                    let campaignCommunicationAllList = [];
                    allScheduleList.data.forEach((al) => {
                      if (al.type === 'GENERAL') {
                        generalCommunicationAllList.push(al);
                      } else {
                        campaignCommunicationAllList.push(al);
                      }
                    });
                    this.setState({
                      allGeneralCommunicationListData:
                        generalCommunicationAllList,
                      allCampaignCommunicationListData:
                        campaignCommunicationAllList,
                    });
                    this.tog_campaignApproval();
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    this.tog_campaignApproval();
                  }}
                >
                  No
                </Button>
              </ModalFooter>
            </Modal>
            {/*campaign rejection modal */}
            <Modal
              isOpen={this.state.campaignRejectionModal}
              toggle={this.tog_campaignRejection}
              size="lg"
              // style={{ maxWidth: '900px', width: '100%' }}
            >
              <ModalHeader
                toggle={() => this.setState({ campaignRejectionModal: false })}
              >
                Reject Communication
              </ModalHeader>
              {/* Edit */}
              <ModalBody>
                <Row className="mb-2">
                  <div>Please sate the reason for rejecting.</div>
                </Row>
                <Row>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    value={this.state.reasonForRejection}
                    onChange={(e) => {
                      this.handleReasonChange(e);
                    }}
                  />
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={async () => {
                    let token = getToken();
                    await GKApi.put(
                      `/marketing/communication/reject`,
                      {
                        cmpId: 141,
                        id: this.state.campaignDetailsData.id,
                        type: 'campaign',
                        reason: this.state.reasonForRejection,
                        deviceInfo: this.state.deviceInfo,
                      },
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    const allScheduleList = await GKApi.get(
                      '/marketing/campaign/list?type=all',
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    let generalCommunicationAllList = [];
                    let campaignCommunicationAllList = [];
                    allScheduleList.data.forEach((al) => {
                      if (al.type === 'GENERAL') {
                        generalCommunicationAllList.push(al);
                      } else {
                        campaignCommunicationAllList.push(al);
                      }
                    });
                    this.setState({
                      allGeneralCommunicationListData:
                        generalCommunicationAllList,
                      allCampaignCommunicationListData:
                        campaignCommunicationAllList,
                    });
                    this.tog_campaignRejection();
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    this.tog_campaignRejection();
                  }}
                >
                  No
                </Button>
              </ModalFooter>
            </Modal>

            {/*general approval modal */}
            <Modal
              isOpen={this.state.generalCampaignApproveModal}
              toggle={this.tog_generalCampaignApproval}
              size="lg"
              // style={{ maxWidth: '900px', width: '100%' }}
            >
              <ModalHeader
                toggle={() =>
                  this.setState({ generalCampaignApproveModal: false })
                }
              >
                Approve Communication
              </ModalHeader>
              {/* Edit */}
              <ModalBody>
                <Row>
                  <div>Are you sure you wan to approve the communication?</div>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={async () => {
                    let token = getToken();
                    await GKApi.put(
                      `/marketing/communication/approve`,
                      {
                        cmpId: 141,
                        id: this.state.generalCampaignDetailsData.id,
                        type: 'general',
                        deviceInfo: this.state.deviceInfo,
                      },
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );
                    const allScheduleList = await GKApi.get(
                      '/marketing/campaign/list?type=all',
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    let generalCommunicationAllList = [];
                    let campaignCommunicationAllList = [];
                    allScheduleList.data.forEach((al) => {
                      if (al.type === 'GENERAL') {
                        generalCommunicationAllList.push(al);
                      } else {
                        campaignCommunicationAllList.push(al);
                      }
                    });
                    this.setState({
                      allGeneralCommunicationListData:
                        generalCommunicationAllList,
                      allCampaignCommunicationListData:
                        campaignCommunicationAllList,
                    });
                    this.tog_generalCampaignApproval();
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    this.tog_generalCampaignApproval();
                  }}
                >
                  No
                </Button>
              </ModalFooter>
            </Modal>
            {/*general rejection modal */}
            <Modal
              isOpen={this.state.generalCampaignRejectionModal}
              toggle={this.tog_generalCampaignRejection}
              size="lg"
              // style={{ maxWidth: '900px', width: '100%' }}
            >
              <ModalHeader
                toggle={() =>
                  this.setState({ generalCampaignRejectionModal: false })
                }
              >
                Reject Communication
              </ModalHeader>
              {/* Edit */}
              <ModalBody>
                <Row className="mb-2">
                  <div>Please sate the reason for rejecting.</div>
                </Row>
                <Row>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    value={this.state.reasonForRejection}
                    onChange={(e) => {
                      this.handleReasonChange(e);
                    }}
                  />
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={async () => {
                    let token = getToken();
                    await GKApi.put(
                      `/marketing/communication/reject`,
                      {
                        cmpId: 141,
                        id: this.state.generalCampaignDetailsData.id,
                        type: 'general',
                        reason: this.state.reasonForRejection,
                        deviceInfo: this.state.deviceInfo,
                      },
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    const allScheduleList = await GKApi.get(
                      '/marketing/campaign/list?type=all',
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );

                    let generalCommunicationAllList = [];
                    let campaignCommunicationAllList = [];
                    allScheduleList.data.forEach((al) => {
                      if (al.type === 'GENERAL') {
                        generalCommunicationAllList.push(al);
                      } else {
                        campaignCommunicationAllList.push(al);
                      }
                    });
                    this.setState({
                      allGeneralCommunicationListData:
                        generalCommunicationAllList,
                      allCampaignCommunicationListData:
                        campaignCommunicationAllList,
                    });
                    this.tog_generalCampaignRejection();
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    this.tog_generalCampaignRejection();
                  }}
                >
                  No
                </Button>
              </ModalFooter>
            </Modal>

            {/* Push notification Communication details */}
            <Modal
              isOpen={this.state.pushNotificationComDetailsModal}
              toggle={this.tog_ComDetails}
              size="lg"
              style={{ maxWidth: '700px', width: '100%' }}
            >
              <ModalHeader
                toggle={() =>
                  this.setState({ pushNotificationComDetailsModal: false })
                }
              >
                {this.state.pushNotificationComDetails.communicationTitle} Push
                Notification Details
              </ModalHeader>

              <ModalBody className="px-5">
                <h4>
                  {this.state.pushNotificationComDetails.communicationTitle}
                </h4>

                <Card>
                  <CardBody>
                    <h6 className="fw-bold">Details</h6>
                    <Row>
                      <Row className="my-2">
                        <Col xl={6}>
                          <h6 className="fw-bold">Communication Title</h6>
                          <p>
                            {
                              this.state.pushNotificationComDetails
                                .communicationTitle
                            }
                          </p>
                        </Col>
                        <Col xl={6}>
                          <h6 className="fw-bold">Communication Tenure</h6>
                          <p>
                            {moment(
                              this.state.pushNotificationComDetails.startDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm A Do MMM')}{' '}
                            -{' '}
                            {moment(
                              this.state.pushNotificationComDetails.endDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm A Do MMM')}
                          </p>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col xl={6}>
                          <h6 className="fw-bold">Status</h6>
                          <p>{this.state.pushNotificationComDetails.status}</p>
                        </Col>
                        <Col xl={6}>
                          <h6 className="fw-bold">Communication Type</h6>
                          <p>{this.state.pushNotificationComDetails.type}</p>
                        </Col>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>

                <h6 className="fw-bold">Notification Details</h6>

                <Row>
                  <Col sm="12">
                    <CardText>
                      <div className="border border-1 rounded p-4">
                        <div className="py-4">
                          <div className="pb-2">
                            <h5 className="card-title">Title</h5>
                            <Input
                              type="text"
                              value={
                                this.state.pushNotificationComDetails?.title
                              }
                              id="com-title"
                              disabled
                            />
                          </div>
                          <div className="pb-2">
                            <h5 className="card-title">Description</h5>
                            <div>
                              <TextField
                                className="w-full"
                                variant="outlined"
                                type="text"
                                multiline
                                minRows={2}
                                style={{
                                  width: '100%',
                                }}
                                value={
                                  this.state.pushNotificationComDetails?.message
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardText>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            {/* Inbox Communication details */}
            <Modal
              isOpen={this.state.inboxComDetailsModal}
              toggle={this.tog_InboxDetails}
              size="lg"
              style={{ maxWidth: '700px', width: '100%' }}
            >
              <ModalHeader
                toggle={() => this.setState({ inboxComDetailsModal: false })}
              >
                {this.state.inboxComDetails.communicationTitle} Inbox Details
              </ModalHeader>

              <ModalBody className="px-5">
                <h4>{this.state.inboxComDetails.communicationTitle}</h4>

                <Card>
                  <CardBody>
                    <h6 className="fw-bold">Details</h6>
                    <Row>
                      <Row className="my-2">
                        <Col xl={6}>
                          <h6 className="fw-bold">Communication Title</h6>
                          <p>{this.state.inboxComDetails.communicationTitle}</p>
                        </Col>
                        <Col xl={6}>
                          <h6 className="fw-bold">Communication Tenure</h6>
                          <p>
                            {moment(
                              this.state.inboxComDetails.startDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm A Do MMM')}{' '}
                            -{' '}
                            {moment(
                              this.state.inboxComDetails.endDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm A Do MMM')}
                          </p>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col xl={6}>
                          <h6 className="fw-bold">Status</h6>
                          <p>{this.state.inboxComDetails.status}</p>
                        </Col>
                        <Col xl={6}>
                          <h6 className="fw-bold">Communication Type</h6>
                          <p>{this.state.inboxComDetails.type}</p>
                        </Col>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>

                <h6 className="fw-bold">Inbox Details</h6>

                <Row>
                  <Col sm="12">
                    <CardText>
                      <div className="border border-1 rounded p-4">
                        <div className="py-4">
                          <div className="pb-2">
                            <h5 className="card-title">Description</h5>
                            <div>
                              <TextField
                                className="w-full"
                                variant="outlined"
                                type="text"
                                multiline
                                minRows={2}
                                style={{
                                  width: '100%',
                                }}
                                value={this.state.inboxComDetails?.message}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardText>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            {/* Banner Communication details */}
            <Modal
              isOpen={this.state.bannerComDetailsModal}
              toggle={this.tog_BannerDetails}
              size="lg"
              style={{ maxWidth: '700px', width: '100%' }}
            >
              <ModalHeader
                toggle={() => this.setState({ bannerComDetailsModal: false })}
              >
                {this.state.bannerComDetails.communicationTitle} Banner Details
              </ModalHeader>

              <ModalBody className="px-5">
                <h4>{this.state.bannerComDetails.communicationTitle}</h4>

                <Card>
                  <CardBody>
                    <h6 className="fw-bold">Details</h6>
                    <Row>
                      <Row className="my-2">
                        <Col xl={6}>
                          <h6 className="fw-bold">Communication Title</h6>
                          <p>
                            {this.state.bannerComDetails.communicationTitle}
                          </p>
                        </Col>
                        <Col xl={6}>
                          <h6 className="fw-bold">Communication Tenure</h6>
                          <p>
                            {moment(
                              this.state.bannerComDetails.startDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm A Do MMM')}{' '}
                            -{' '}
                            {moment(
                              this.state.bannerComDetails.endDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm A Do MMM')}
                          </p>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col xl={6}>
                          <h6 className="fw-bold">Status</h6>
                          <p>{this.state.bannerComDetails.status}</p>
                        </Col>
                        <Col xl={6}>
                          <h6 className="fw-bold">Communication Type</h6>
                          <p>{this.state.bannerComDetails.type}</p>
                        </Col>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>

                <h6 className="fw-bold">Banner Details</h6>

                <Row>
                  <Col sm="12">
                    <CardText>
                      <div className="border border-1 rounded p-4">
                        <Row className="py-2 px-4">
                          {this.state.bannerUrl && (
                            <img
                              data-dz-thumbnail=""
                              // height="50%"
                              // width="50%"
                              // className="avatar-sm rounded bg-light"
                              alt={'app banner'}
                              src={this.state.bannerUrl}
                            />
                          )}
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          ></div>
                        </Row>

                        <Row className="py-2">
                          <span>
                            <h6 className="fw-bold"> Target Link</h6>
                          </span>
                          <span>
                            <Col>
                              <Input
                                type="text"
                                value={this.state.bannerComDetails?.link}
                                id="gold-price-input"
                                disabled={true}
                                // onChange={handleGoldPriceChange}
                              />
                            </Col>
                          </span>
                        </Row>
                      </div>
                    </CardText>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          <h5>Sorry you don't have permission to view this</h5>
        )}
      </React.Fragment>
    );
  }
}

export default AllSchedule;

import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {} from '../../store/actions';
import data from '../../version.json';
//Simple bar
import SimpleBar from 'simplebar-react';

import SidebarContent from './SidebarContent';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            {this.props.type !== 'condensed' ? (
              <SimpleBar style={{ maxHeight: '100%' }}>
                <SidebarContent />
              </SimpleBar>
            ) : (
              <SidebarContent />
            )}
            <div
              className="fw-bold px-4 font-size-14"
              style={{ color: '#ffffffff' }}
            >
              {' '}
              Version: {data.version_number.slice(1)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));

import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, Row, Col} from 'reactstrap';

class KPIChart extends Component {
  componentDidMount() {
  }
  componentDidUpdate() {
    
    if (
      this.props.KPIData &&
      Object.keys(this.props.KPIData).length !== 0 &&
      this.state.kpiData === undefined
    )
      this.updateSeries(this.props.KPIData);
  }
  constructor(props) {
    super(props);
    this.state = {
      series1: [
        {
          name: 'Achieve Total TRX Amount',
          type: 'column',
          data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
        },
        {
          name: 'Target Total TRX Amount',
          type: 'line',
          data: [30],
        },
      ],
      series2: [
        {
          name: 'Achieve Total TRX Count',
          type: 'column',
          data: [25, 18, 19, 22, 10, 25, 35, 25, 40, 20, 25],
        },

        {
          name: 'Target Total TRX Count',
          type: 'line',
          data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
        },
      ],
      series3: [
        {
          name: 'Achieved New Customer Acquisition',
          type: 'column',
          data: [29, 15, 26, 23, 15, 28, 32, 28, 35, 25, 35],
        },

        {
          name: 'Target New Customer Acquisition',
          type: 'line',
          data: [40, 35, 46, 40, 45, 38, 54, 42, 49, 30, 42],
        },
      ],
      series4: [
        {
          name: 'Achieved Active Customer Percentage',
          type: 'column',
          data: [20, 13, 22, 25, 17, 20, 30, 18, 38, 27, 40],
        },
        {
          name: 'Target Active Customer Percentage',
          type: 'line',
          data: [25, 22, 33, 34, 25, 30, 44, 32, 40, 33, 49],
        },
      ],
      options1: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },

        stroke: {
          width: 3,
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },

        fill: {
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [
          '01/01/2022',
          '02/01/2022',
          '03/01/2022',
          '04/01/2022',
          '05/01/2022',
          '06/01/2022',
          '07/01/2022',
          '08/01/2022',
          '09/01/2022',
          '10/01/2022',
          '11/01/2022',
        ],
        markers: {
          size: 0,
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          title: {
            text: 'TRX Amount(BDT)',
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' points';
              }
              return y;
            },
          },
        },
      },
      options2: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        colors: ['#556ee6', '#f46a6a'],
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },

        fill: {
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [
          '01/01/2003',
          '02/01/2003',
          '03/01/2003',
          '04/01/2003',
          '05/01/2003',
          '06/01/2003',
          '07/01/2003',
          '08/01/2003',
          '09/01/2003',
          '10/01/2003',
          '11/01/2003',
        ],
        markers: {
          size: 0,
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          title: {
            text: 'Number of TRX',
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' points';
              }
              return y;
            },
          },
        },
      },
      options3: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        colors: ['#f46a6a', '#34c38f'],
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },

        fill: {
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [
          '01/01/2003',
          '02/01/2003',
          '03/01/2003',
          '04/01/2003',
          '05/01/2003',
          '06/01/2003',
          '07/01/2003',
          '08/01/2003',
          '09/01/2003',
          '10/01/2003',
          '11/01/2003',
        ],
        markers: {
          size: 0,
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          title: {
            text: 'Number Of New Customer',
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' points';
              }
              return y;
            },
          },
        },
      },
      options4: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        colors: ['#FA8072', '#556ee6'],
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },

        fill: {
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [
          '01/01/2003',
          '02/01/2003',
          '03/01/2003',
          '04/01/2003',
          '05/01/2003',
          '06/01/2003',
          '07/01/2003',
          '08/01/2003',
          '09/01/2003',
          '10/01/2003',
          '11/01/2003',
        ],
        markers: {
          size: 0,
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          title: {
            text: 'Number Of Active Customer',
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' points';
              }
              return y;
            },
          },
        },
      },
    };
  }
  updateSeries = (KPIData) => {
    const KPIName = {
      TRANSACTIONCOUNT: 'Transaction Count',
      TRANSACTIONAMOUNT: 'Transaction Amount',
      GOLDQUANTITY: 'Gold Quantity',
      TICKETSIZE: 'Ticket Size',
      MAXTICKET: 'Max Ticket',
      MINTICKET: 'Min Ticket',
      NEWCUSTOMERACQUISITON: 'New Customer',
      ACTIVECUSTOMERPERCENTAGE: 'Active Customer Percent',
    };
    let seriesData = KPIData.activeKpi.map((element) => {
      let achieve = {
        name: 'Achieve ' + KPIName[element.kpiTitle],
        type: 'column',
        data: KPIData.kpiData.map((data) => data[element.kpiTitle]),
      };
      let target = {
        name: 'Target ' + KPIName[element.kpiTitle],
        type: 'line',
        data: KPIData.kpiData.map((data) => element.kpiValue),
      };
      const series = [achieve, target];
      const options = {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        colors: ['#556ee6', '#f46a6a'],
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },

        fill: {
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: KPIData.kpiData.map((data) => data.DAY),
        markers: {
          size: 0,
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          title: {
            text: KPIName[element.kpiTitle],
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' points';
              }
              return y;
            },
          },
        },
      };

      return { series, options, title: KPIName[element.kpiTitle] };
    });
    
    this.setState({ ...this.state, kpiData: seriesData });
  };

  render() {
    if (this.props.KPIData && Object.keys(this.props.KPIData).length === 0)
      return <></>;
    
    
    if (this.state.kpiData)
      return (
        <>
          <div>
            <Row>
              {this.state.kpiData.map((element) => (
                <Col xl={6}>
                  <Card>
                    <CardBody className="shadow-lg">
                      {/* <div className="float-end d-none d-md-inline-block">
                        <ButtonGroup className="mb-2">
                          <Button
                            active
                            size="sm"
                            color="light"
                            type="button"
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                display: 'daily',
                              })
                            }
                          >
                            Daily
                          </Button>
                          <Button
                            size="sm"
                            color="light"
                            type="button"
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                display: 'weekly',
                              })
                            }
                          >
                            Weekly
                          </Button>
                          <Button
                            size="sm"
                            color="light"
                            type="button"
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                display: 'monthly',
                              })
                            }
                          >
                            Monthly
                          </Button>
                        </ButtonGroup>
                      </div> */}
                      <h6 className="mb-4 fw-bold">{element.title}</h6>
                      <ReactApexChart
                        options={element.options}
                        series={element.series}
                        type="line"
                        height={350}
                      />
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </>
      );
    return <>Loading</>;
    // return (
    //   <div id="chart">
    //     <Row>
    //       <Col xl={6}>
    //         <Card>
    //           <CardBody className="shadow-lg">
    //             <div className="float-end d-none d-md-inline-block">
    //               <ButtonGroup className="mb-2">
    //                 <Button
    //                   active
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'daily',
    //                     })
    //                   }
    //                 >
    //                   Daily
    //                 </Button>
    //                 <Button
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'weekly',
    //                     })
    //                   }
    //                 >
    //                   Weekly
    //                 </Button>
    //                 <Button
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'monthly',
    //                     })
    //                   }
    //                 >
    //                   Monthly
    //                 </Button>
    //               </ButtonGroup>
    //             </div>
    //             <h6 className="mb-4 fw-bold">Total TRX Amount KPI</h6>
    //             <ReactApexChart
    //               options={this.state.options1}
    //               series={this.state.series1}
    //               type="line"
    //               height={350}
    //             />
    //           </CardBody>
    //         </Card>
    //       </Col>
    //       <Col xl={6}>
    //         <Card>
    //           <CardBody className="shadow-lg">
    //             <div className="float-end d-none d-md-inline-block">
    //               <ButtonGroup className="mb-2">
    //                 <Button
    //                   active
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'daily',
    //                     })
    //                   }
    //                 >
    //                   Daily
    //                 </Button>
    //                 <Button
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'weekly',
    //                     })
    //                   }
    //                 >
    //                   Weekly
    //                 </Button>
    //                 <Button
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'monthly',
    //                     })
    //                   }
    //                 >
    //                   Monthly
    //                 </Button>
    //               </ButtonGroup>
    //             </div>
    //             <h6 className="mb-4 fw-bold">Total Customer Acquisition KPI</h6>
    //             <ReactApexChart
    //               options={this.state.options3}
    //               series={this.state.series3}
    //               type="line"
    //               height={350}
    //             />
    //           </CardBody>
    //         </Card>
    //       </Col>
    //     </Row>
    //     <Row>
    //       <Col xl={6}>
    //         <Card>
    //           <CardBody className="shadow-lg">
    //             <div className="float-end d-none d-md-inline-block">
    //               <ButtonGroup className="mb-2">
    //                 <Button
    //                   active
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'daily',
    //                     })
    //                   }
    //                 >
    //                   Daily
    //                 </Button>
    //                 <Button
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'weekly',
    //                     })
    //                   }
    //                 >
    //                   Weekly
    //                 </Button>
    //                 <Button
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'monthly',
    //                     })
    //                   }
    //                 >
    //                   Monthly
    //                 </Button>
    //               </ButtonGroup>
    //             </div>
    //             <h6 className="mb-4 fw-bold">Total TRX Count KPI</h6>
    //             <ReactApexChart
    //               options={this.state.options2}
    //               series={this.state.series2}
    //               type="line"
    //               height={350}
    //             />
    //           </CardBody>
    //         </Card>
    //       </Col>

    //       <Col xl={6}>
    //         <Card>
    //           <CardBody className="shadow-lg">
    //             <div className="float-end d-none d-md-inline-block">
    //               <ButtonGroup className="mb-2">
    //                 <Button
    //                   active
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'daily',
    //                     })
    //                   }
    //                 >
    //                   Daily
    //                 </Button>
    //                 <Button
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'weekly',
    //                     })
    //                   }
    //                 >
    //                   Weekly
    //                 </Button>
    //                 <Button
    //                   size="sm"
    //                   color="light"
    //                   type="button"
    //                   onClick={() =>
    //                     this.setState({
    //                       ...this.state,
    //                       display: 'monthly',
    //                     })
    //                   }
    //                 >
    //                   Monthly
    //                 </Button>
    //               </ButtonGroup>
    //             </div>
    //             <h6 className="mb-4 fw-bold">
    //               Total Active Customer Percentage KPI
    //             </h6>
    //             <ReactApexChart
    //               options={this.state.options4}
    //               series={this.state.series4}
    //               type="line"
    //               height={350}
    //             />
    //           </CardBody>
    //         </Card>
    //       </Col>
    //     </Row>
    //   </div>
    // );
  }
}

export default KPIChart;

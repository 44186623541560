import React, { Component } from 'react';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
import {
  
  Button,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

// Table data

class UserReferReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '#' },
        { title: 'Finance-Refer-Report', link: '#' },
        { title: 'User Refer Report', link: '#' },
      ],
      visible: false,
      modal_standard: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  render() {

   
   
    const columns = [
      
      {
        field: 'CUSTOMER_NAME',
        title: 'CUSTOMER NAME',
      },
      {
        field: 'PHONE',
        title: 'PHONE',
      },
      {
        field: 'DOB',
        title: 'Date Of Birth',
      },
      {
        field: 'GENDER',
        title: 'GENDER',
      },
      {
        field: 'NID',
        title: 'NID',
      },
      {
        field: 'REFER_CODE',
        title: 'REFER CODE',
      },

      {
        field: 'JOINING_DATE',
        title: 'JOINING DATE',
        
      },
      
      {
        field: 'SOURCE_REFER_CODE',
        title: 'SOURCE REFER CODE',
        
      },
      {
        field: 'SOURCE_REFER_TYPE',
        title: 'SOURCE REFER TYPE',
        
      },
      {
        field: 'REFER_CODE_TITLE',
        title: 'REFER CODE TITLE',
        
      },
      {
        field: 'TRX_DATE',
        title: 'TRX DATE',
       
      },
      {
        field: 'TRX_TYPE',
        title: 'TRX TYPE',
       
      },
      {
        field: 'MARKET_PRICE',
        title: 'MARKET PRICE',
       
      },
      {
        field: 'GOLD_QTY',
        title: 'GOLD QTY',
       
      },
      {
        field: 'SUB_TOTAL',
        title: 'SUB TOTAL',
       
      },
      {
        field: 'GRAND_TOTAL',
        title: 'GRAND TOTAL',
       
      },
      {
        field: 'PAY_METHOD',
        title: 'PAY METHOD',
       
      },
    ];

    const summaryColumns = [
      
      {
        field: 'TRX_DATE',
        title: 'TRX DATE',
      },
      {
        field: 'TOTAL_TRX_COUNT',
        title: 'TOTAL TRX COUNT',
      },
      {
        field: 'BUY_GOLD_VALUE',
        title: 'BUY GOLD VALUE',
      },
      {
        field: 'BUY_GOLD_QTY',
        title: 'BUY GOLD QTY',
      },
      {
        field: 'BUY_TRX_COUNT',
        title: 'BUY TRX COUNT',
      },
      {
        field: 'AVG_BUY_GOLD_VALUE',
        title: 'AVG BUY GOLD VALUE',
      },
      {
        field: 'BUY_COUNT_BKASH',
        title: 'BUY COUNT BKASH',
      },
      {
        field: 'BUY_COUNT_CARD',
        title: 'BUY COUNT CARD',
      },
      {
        field: 'BUY_GOLD_VALUE_BKASH',
        title: 'BUY GOLD VALUE BKASH',
      },
      {
        field: 'BUY_GOLD_VALUE_CARD',
        title: 'BUY GOLD VALUE CARD',
      },
      {
        field: 'BUY_USER_COUNT',
        title: 'BUY USER COUNT',
      },
      {
        field: 'SELL_GOLD_VALUE',
        title: 'SELL GOLD VALUE',
      },
      {
        field: 'SELL_GOLD_QTY',
        title: 'SELL GOLD QTY',
      },
      {
        field: 'SELL_TRX_COUNT',
        title: 'SELL TRX COUNT',
      },
      {
        field: 'AVG_SELL_GOLD_VALUE',
        title: 'AVG SELL GOLD VALUE',
      },
      {
        field: 'SELL_COUNT_BKASH',
        title: 'SELL COUNT BKASH',
      },
      {
        field: 'SELL_COUNT_BANK',
        title: 'SELL COUNT BANK',
      },
      {
        field: 'SELL_GOLD_VALUE_BKASH',
        title: 'SELL GOLD VALUE BKASH',
      },
      {
        field: 'SELL_GOLD_VALUE_BANK',
        title: 'SELL GOLD VALUE CARD',
      },
      {
        field: 'SELL_USER_COUNT',
        title: 'SELL USER COUNT',
      },
      {
        field: 'COLLECT_GOLD_VALUE',
        title: 'COLLECT GOLD VALUE',
      },
      {
        field: 'COLLECT_GOLD_QTY',
        title: 'COLLECT GOLD QTY',
      },
      {
        field: 'COLLECT_TRX_COUNT',
        title: 'COLLECT TRX COUNT',
      },
      {
        field: 'AVG_COLLECT_GOLD_VALUE',
        title: 'AVG COLLECT GOLD VALUE',
      },
      {
        field: 'COLLECT_COUNT_BKASH',
        title: 'COLLECT COUNT BKASH',
      },
      {
        field: 'COLLECT_COUNT_CARD',
        title: 'COLLECT COUNT CARD',
      },
      {
        field: 'COLLECT_GOLD_VALUE_BKASH',
        title: 'COLLECT GOLD VALUE BKASH',
      },
      {
        field: 'COLLECT_GOLD_VALUE_CARD',
        title: 'COLLECT GOLD VALUE CARD',
      },
      {
        field: 'COLLECT_USER_COUNT',
        title: 'COLLECT USER COUNT',
      },
      {
        field: 'GIFT_RECEIVE_GOLD_VALUE',
        title: 'GIFT RECEIVE GOLD VALUE',
      },
      {
        field: 'GIFT_RECEIVE_GOLD_QTY',
        title: 'GIFT RECEIVE GOLD QTY',
      },
      {
        field: 'GIFT_RECEIVE_TRX_COUNT',
        title: 'GIFT RECEIVE TRX COUNT',
      },
      {
        field: 'AVG_GIFT_RECEIVE_GOLD_VALUE',
        title: 'AVG_GIFT RECEIVE GOLD VALUE',
      },
      {
        field: 'GIFT_RECEIVE_USER_COUNT',
        title: 'GIFT RECEIVE USER COUNT',
      },
      {
        field: 'GIFT_SEND_GOLD_VALUE',
        title: 'GIFT SEND GOLD VALUE',
      },
      {
        field: 'GIFT_SEND_GOLD_QTY',
        title: 'GIFT SEND GOLD QTY',
      },
      {
        field: 'GIFT_SEND_TRX_COUNT',
        title: 'GIFT SEND TRX COUNT',
      },
      {
        field: 'AVG_GIFT_SEND_GOLD_VALUE',
        title: 'AVG GIFT SEND GOLD VALUE',
      },
      {
        field: 'GIFT_SEND_USER_COUNT',
        title: 'GIFT SEND USER COUNT',
      },
      {
        field: 'TOTAL_COUNT_BKASH',
        title: 'TOTAL COUNT BKASH',
      },
      {
        field: 'TOTAL_COUNT_CARD',
        title: 'TOTAL COUNT CARD',
      },
      {
        field: 'TOTAL_COUNT_BANK',
        title: 'TOTAL COUNT BANK',
      },
      {
        field: 'TOTAL_GOLD_VALUE_BKASH',
        title: 'TOTAL GOLD VALUE BKASH',
      },
      {
        field: 'TOTAL_GOLD_VALUE_CARD',
        title: 'TOTAL GOLD VALUE CARD',
      },
      {
        field: 'TOTAL_GOLD_VALUE_BANK',
        title: 'TOTAL GOLD VALUE BANK',
      },
    ];


    const downloadExcel = () => {
      const newData = this.props.userReferReportData.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'reports');
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
       this.props.summary===false?
         XLSX.writeFile(workBook, 'User Refer Report.xlsx')
          : XLSX.writeFile(workBook, 'User Refer Summary Report.xlsx');
      
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Finance"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <MaterialTable
              title={this.props.summary===false?
                 'User Refer':'User Refer Summary'
              }
              columns={
                this.props.summary===false? columns:summaryColumns
                
                 
              }
              data={this.props.userReferReportData}
              actions={[
                {
                  icon: () => <Button>Export All</Button>, // you can pass icon too
                  tooltip: 'Export to Excel',
                  onClick: () => downloadExcel(),
                  isFreeAction: true,
                },
              ]}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                // exportButton: { csv: true, pdf: false },
                // exportFileName:
                //   this.props.location.state.summary === false
                //     ? 'Collect Gold Report'
                //     : 'Collect Gold Report Summarized',
                pageSize: 20,
                columnsButton: true,
              }}
              localization={{
                toolbar: {
                  exportCSVName: 'Export as CSV',
                },
              }}
            />
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserReferReport;

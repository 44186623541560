import moment from 'moment';
import React, { Component } from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // Input,
  Button,
} from 'reactstrap';
import {
  capitalizeFirstLetter,
  getToken,
  GKApi,
} from '../../../components/config';
import DatePicker from '../../../components/Common/DatePicker';
import Tooltip from '@material-ui/core/Tooltip';

class MiniWidgets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      menu: 'weekly',
      custom: false,
      selectedStartDate: null,
      selectedEndDate: null,
      userData: [],
    };
  }
  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();
    const startDate = moment(new Date())
      .subtract(6, 'days')
      .format('YYYY/MM/DD');
    const endDate = moment(new Date()).format('YYYY/MM/DD');
    const response = await GKApi.get(
      `/marketing/user-counts?startDate=${startDate}&endDate=${endDate}&type=${this.state.menu}`,
      {
        headers: {
          Authorization: token,
          cmpId: 2,
        },
      }
    );

    this.setState({ userData: response.data.data }, () => {});
  }
  handleSelectedStartDateChange = (event) => {
    this.setState({ selectedStartDate: event });
  };

  handleSelectedEndDateChange = async (event) => {
    this.setState({ selectedEndDate: event });
  };
  handleClickYearly = async (event) => {
    this.setState({ custom: false, menu: 'yearly' }, () => {});

    const token = getToken();
    const startDate = moment(new Date())
      .subtract(365, 'days')
      .format('YYYY/MM/DD');
    const endDate = moment(new Date()).format('YYYY/MM/DD');
    const response = await GKApi.get(
      `/marketing/user-counts?startDate=${startDate}&endDate=${endDate}&type=yearly`,
      {
        headers: {
          Authorization: token,
          cmpId: 2,
        },
      }
    );
    this.setState({ userData: response.data.data }, () => {});
  };
  handleClickMonthly = async (event) => {
    this.setState({ custom: false, menu: 'monthly' }, () => {});
    const token = getToken();
    const startDate = moment(new Date())
      .subtract(29, 'days')
      .format('YYYY/MM/DD');
    const endDate = moment(new Date()).format('YYYY/MM/DD');
    const response = await GKApi.get(
      `/marketing/user-counts?startDate=${startDate}&endDate=${endDate}&type=monthly`,
      {
        headers: {
          Authorization: token,
          cmpId: 2,
        },
      }
    );

    this.setState({ userData: response.data.data }, () => {});
  };
  handleClickWeekly = async (event) => {
    this.setState({ custom: false, menu: 'weekly' }, () => {});

    const token = getToken();
    const startDate = moment(new Date())
      .subtract(6, 'days')
      .format('YYYY/MM/DD');
    const endDate = moment(new Date()).format('YYYY/MM/DD');
    const response = await GKApi.get(
      `/marketing/user-counts?startDate=${startDate}&endDate=${endDate}&type=weekly`,
      {
        headers: {
          Authorization: token,
          cmpId: 2,
        },
      }
    );

    this.setState({ userData: response.data.data }, () => {});
  };
  handleClickCustom = async (event) => {
    this.setState({ custom: true, menu: 'custom' }, () => {});
  };

  render() {
    return (
      <div>
        <Row>
          {this.state.userData &&
            this.state.userData.map((ud, index) => (
              <Col xl={3} key={index}>
                <Card>
                  <CardBody className="shadow-lg">
                    <div className="d-flex" style={{ height: '100px' }}>
                      <div className="flex-1 overflow-hidden">
                        <p
                          className="fw-bold"
                          style={{
                            color: '#848C9C',
                            fontWeight: 500,
                            fontSize: '15px',
                          }}
                        >
                          Total Users
                        </p>
                        <h4 style={{ fontWeight: 600, fontSize: '36px' }}>
                          {ud.totalUsers}
                        </h4>
                      </div>
                      <div>
                        <i
                          className={'ri-user-line font-size-24'}
                          style={{ color: '#CEC382' }}
                        ></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          <Col xl={9}>
            <Card>
              <CardBody className="shadow-lg">
                <div style={{ height: '100px' }}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <h4
                        className="fw-bold mt-2"
                        style={{ fontWeight: '600', fontSize: '15px' }}
                      >
                        All User
                      </h4>
                      <div className="px-2">
                        <Dropdown
                          isOpen={this.state.isOpen}
                          toggle={() =>
                            this.setState({ isOpen: !this.state.isOpen })
                          }
                        >
                          <DropdownToggle color="light" caret>
                            {capitalizeFirstLetter(this.state.menu)}{' '}
                            <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={this.handleClickWeekly}>
                              Weekly
                            </DropdownItem>
                            <DropdownItem onClick={this.handleClickMonthly}>
                              Monthly
                            </DropdownItem>
                            <DropdownItem onClick={this.handleClickYearly}>
                              Yearly
                            </DropdownItem>
                            <DropdownItem onClick={this.handleClickCustom}>
                              Custom
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                    <span>
                      {this.state.custom === true && (
                        <>
                          <span className="d-flex gap-3">
                            <div
                              style={{
                                border: '1px solid',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                              }}
                              className="d-flex gap-3 align-content-center"
                            >
                              <p
                                className="mt-1"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                Start Date
                              </p>
                              <DatePicker
                                selectedDate={this.state.selectedStartDate}
                                handleSelectedDateChange={
                                  this.handleSelectedStartDateChange
                                }
                              />
                            </div>
                            <div
                              style={{
                                border: '1px solid',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '5px',
                                paddingLeft: '5px',
                              }}
                              className="d-flex d-grid gap-3"
                            >
                              <p
                                className="mt-1"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                End Date
                              </p>
                              <DatePicker
                                selectedDate={this.state.selectedEndDate}
                                handleSelectedDateChange={
                                  this.handleSelectedEndDateChange
                                }
                                className="mx-3"
                              />
                            </div>
                            <Button
                              onClick={async () => {
                                const token = getToken();
                                const startDate = moment(
                                  this.state.selectedStartDate
                                ).format('YYYY/MM/DD');
                                const endDate = moment(
                                  this.state.selectedEndDate
                                ).format('YYYY/MM/DD');

                                const response = await GKApi.get(
                                  `/marketing/user-counts?startDate=${startDate}&endDate=${endDate}&type=custom`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 2,
                                    },
                                  }
                                );

                                this.setState({userData:response.data.data})
                              }}
                            >
                              Get User
                            </Button>
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                  {this.state.userData &&
                    this.state.userData.map((ud, i) => (
                      <div className="py-2" key={i}>
                        <div className="d-md-flex flex-md-row gap-4">
                          <div className="col col-xl-2 border-right border-primary">
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex gap-3 align-items-center">
                                  <p className="text-truncate font-size-14 mb-1 fw-bold">
                                    Active User
                                  </p>
                                  <Tooltip
                                    title={
                                      <div className="d-flex gap-3 pt-2">
                                        <p className='font-size-12'>Transactors: {ud.transactors} </p>
                                        <p className='font-size-12'>Non-Transactors: {ud.nonTransactors} </p>
                                      </div>
                                    }
                                    arrow
                                  >
                                    <i className="mdi mdi-chevron-down-box font-size-20"></i>
                                  </Tooltip>
                                </div>
                                <h4 className="mb-1 font-size-14 fw-bold">
                                  {ud.activeUsers}
                                </h4>
                              </div>
                              <div
                                className="d-flex"
                                style={{ height: '40px', color: '#848C9C' }}
                              >
                                <div className="vr"></div>
                              </div>
                            </div>
                          </div>
                          <div className="col col-xl-2 border-right border-primary px-2">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-truncate font-size-14 mb-1 fw-bold">
                                  Inactive User
                                </p>
                                <h4 className="mb-1 font-size-14 fw-bold">
                                  {ud.inactiveUsers}
                                </h4>
                              </div>
                              <div
                                className="d-flex"
                                style={{ height: '40px', color: '#848C9C' }}
                              >
                                <div className="vr"></div>
                              </div>
                            </div>
                          </div>
                          <div className="col col-xl-2 border-right border-primary">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-truncate font-size-14 mb-1 fw-bold">
                                  New User
                                </p>
                                <h4 className="mb-1 font-size-14 fw-bold">
                                  {ud.newUsers}
                                </h4>
                              </div>
                              <div
                                className="d-flex"
                                style={{ height: '40px', color: '#848C9C' }}
                              >
                                <div className="vr"></div>
                              </div>
                            </div>
                          </div>
                          <div className="col col-xl-2 border-right border-primary">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-truncate font-size-14 mb-1 fw-bold">
                                  Campaign User
                                </p>
                                <h4 className="mb-1 font-size-14 fw-bold">
                                  {ud.campaignUsers}
                                </h4>
                              </div>
                              <div
                                className="d-flex"
                                style={{ height: '40px', color: '#848C9C' }}
                              >
                                <div className="vr"></div>
                              </div>
                            </div>
                          </div>
                          <div className="col col-xl-2 border-right border-primary">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-truncate font-size-14 mb-1 fw-bold">
                                  Referral User
                                </p>
                                <h4 className="mb-1 font-size-14 fw-bold">
                                  {ud.referralUsers}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default MiniWidgets;

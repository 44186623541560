import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import './dashboard.scss';
import { getToken, GKApi } from '../../../components/config';
import moment from 'moment';

class GoldPriceTrend extends Component {
  state = {
    weeklySeries: [
      {
        name: 'Weekly',
        data: [],
      },
    ],
    monthlySeries: [
      {
        name: 'Monthly',
        data: [],
      },
    ],

    weeklyOptions: {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
          },
          export: {
            csv: {
              filename: 'Gold Price Trend',
              columnDelimiter: ',',
              headerCategory: 'Month',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: 'Gold Price Trend',
            },
            png: {
              filename: 'Gold Price Trend',
            },
          },
        },
      },
      stroke: {
        width: [0, 3],
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
        },
      },
      dataLabels: {
        enabled: false,
      },

      legend: {
        show: false,
      },
      colors: ['#5664d2'],
      labels: [],
    },
    monthlyOptions: {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
          },
          export: {
            csv: {
              filename: 'Gold Price Trend',
              columnDelimiter: ',',
              headerCategory: 'Month',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: 'Gold Price Trend',
            },
            png: {
              filename: 'Gold Price Trend',
            },
          },
        },
      },
      stroke: {
        width: [0, 3],
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
        },
      },
      dataLabels: {
        enabled: false,
      },

      legend: {
        show: false,
      },
      colors: ['#5664d2'],
      labels: [],
    },
    display: 'weekly',
  };

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();
    const monthlyGoldPrice = [];
    const weeklyGoldPrice = [];
    const monthlyLabels = [];
    const weeklyLabels = [];
    const response = await GKApi.get('/finance/gold-price', {
      headers: {
        Authorization: token,
        cmpId: 4,
      },
    });


    for (const obj of response.data.monthly) {
      monthlyGoldPrice.push(obj.goldPrice);
      monthlyLabels.push(moment(obj.date).format('MMM D, YYYY'));
    }

    for (const obj of response.data.weekly) {
      weeklyGoldPrice.push(obj.goldPrice);
      weeklyLabels.push(moment(obj.date).format('MMM D'));
    }

    this.setState({
      monthlySeries: [
        {
          name: 'Gold Price',
          data: monthlyGoldPrice,
        },
      ],
      weeklySeries: [
        {
          name: 'Gold Price',
          data: weeklyGoldPrice,
        },
      ],
      weeklyOptions: {
        chart: {
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
            },
            export: {
              csv: {
                filename: 'Gold Price Trend',
                columnDelimiter: ',',
                headerCategory: 'Week',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: 'Gold Price Trend',
              },
              png: {
                filename: 'Gold Price Trend',
              },
            },
          },
        },
        stroke: {
          width: [0, 3],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' bdt';
            },
          },
        },
        colors: ['#5664d2'],
        labels: weeklyLabels,
      },
      monthlyOptions: {
        chart: {
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
            },
            export: {
              csv: {
                filename: 'Gold Price Trend',
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: 'Gold Price Trend',
              },
              png: {
                filename: 'Gold Price Trend',
              },
            },
          },
        },
        stroke: {
          width: [0, 3],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        colors: ['#5664d2'],
        labels: monthlyLabels,
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-end d-none d-md-inline-block">
              <ButtonGroup className="mb-2">
                <Button
                  size="sm"
                  color={this.state.display==='weekly'?'secondary':"light"}
                  
                  type="button"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      display: 'weekly',
                    })
                  }
                >
                  Weekly
                </Button>
                <Button
                  size="sm"
                  color={this.state.display==='monthly'?'secondary':"light"}
                  type="button"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      display: 'monthly',
                    })
                  }
                >
                  Monthly
                </Button>
              </ButtonGroup>
            </div>
            <h4 className="card-title mb-4">Gold Price Trend</h4>
            {this.state.weeklySeries && this.state.monthlySeries ? (
              <div>
                <div id="chart" className="apex-charts" dir="ltr">
                  <ReactApexChart
                    options={
                      this.state.display === 'weekly'
                        ? this.state.weeklyOptions
                        : this.state.monthlyOptions
                    }
                    series={
                      this.state.display === 'weekly'
                        ? this.state.weeklySeries
                        : this.state.monthlySeries
                    }
                    type="bar"
                    height="280"
                  />
                </div>
              </div>
            ) : (
              <h1 className="text-muted">Data Not Available</h1>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default GoldPriceTrend;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { roundToTwo } from '../../../components/config';

// Table data

class CollectTrx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'CRM', link: '#' },
        { title: 'User List', link: '#' },
        { title: 'Collect History', link: '#' },
      ],
      visible: false,
      modal_standard: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();}
    const columns = [
      {
        field: 'slNo',
        title: 'SL No',
      },
      {
        field: 'transactionReference',
        title: 'Transaction Reference No',
      },
      {
        field: 'collectRequestDate',
        title: 'Collection Request Date',
      },

      {
        field: 'collectAmountInGoldGM',
        title: 'Collect Amount in Gold (GM)',
        render: (row) => <div>{roundToTwo(row.collectAmountInGoldGM)}</div>,
      },
      {
        field: 'collectionType',
        title: 'Collection Type',
        render: (row) =>
          <div>
            {(row.collectionType === 'Pickup') ? 'Pick Up' : 'Home Delivery'}
          </div>,
      },
      {
        field: 'SKU(Coin 2gm)',
        title: 'SKU(Coin 2gm)',
        render: (row) => <div>{roundToTwo(row['SKU(Coin 2gm)'])}</div>,
      },
      {
        field: 'SKU(Coin 4gm)',
        title: 'SKU(Coin 4gm)',
        render: (row) => <div>{roundToTwo(row['SKU(Coin 4gm)'])}</div>,
      },
      {
        field: 'SKU(Bar 1gm)',
        title: 'SKU(Bar 1gm)',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 1gm)'])}</div>,
      },
      {
        field: 'SKU(Bar 5gm)',
        title: 'SKU(Bar 5gm)',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 5gm)'])}</div>,
      },
      {
        field: 'SKU(Bar 10gm)',
        title: 'SKU(Bar 10gm)',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 10gm)'])}</div>,
      },
      {
        field: 'perUnitPrice',
        title: 'Per Unit Price (Live market Price)',
        render: (row) => <div>{roundToTwo(row.perUnitPrice)}</div>,
      },
      {
        field: 'serviceCharge',
        title: 'Service Charge',
        render: (row) => <div>{roundToTwo(row.serviceCharge)}</div>,
      },
      {
        field: 'deliveryCharge',
        title: 'Delivery Charge',
        render: (row) => <div>{roundToTwo(row.deliveryCharge)}</div>,
      },
      {
        field: 'totalCollectAmountIncludingCharges',
        title: 'Total Collect Amount Including Charges',
        render: (row) => <div>{(row.totalCollectAmountIncludingCharges).toFixedDown(4)}</div>,
      },

      {
        field: 'promoAmount',
        title: 'Promo Amount',
        render: (row) => <div>{roundToTwo(row.promoAmount)}</div>,
      },
      {
        field: 'discountAmount',
        title: 'Discount Amount',
        render: (row) => <div>{roundToTwo(row.discountAmount)}</div>,
      },
      {
        field: 'goldBack',
        title: 'Gold Back',
        render: (row) => <div>{roundToTwo(row.goldBack)}</div>,
      },
      {
        field: 'paymentMedium',
        title: 'Payment Medium',
      },
      {
        field: 'netCollectAmount',
        title: 'Net Collect Amount',
        render: (row) => <div>{roundToTwo(row.netCollectAmount)}</div>,
      },
    ];


    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="User-Collect transaction history"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <MaterialTable
              title={'Collect Gold Report'}
              columns={columns}
              data={this.props.location.state.data}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                pageSize: 20,
                columnsButton: true,
              }}
            />
            <Modal
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_standard: false })}
              >
                User Edit
              </ModalHeader>
              <ModalBody>
                {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Name
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.name}
                      id="example-name-input"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Position
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.position}
                      id="example-position-input"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Salary
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.salary}
                      id="example-salary-input"
                    />
                  </Col>
                </Row>
                <Button className="float-end">Update</Button>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={this.tog_standard}
                  color="light"
                  className="waves-effect"
                >
                  Close
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                >
                  Save changes
                </Button>
              </ModalFooter>
            </Modal>
            ;
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CollectTrx;

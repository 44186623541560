
import React, { Component } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { getToken, GKApi } from '../../../components/config';

function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2');
}

class MiniWidgets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: {},
      buyMinLimit: {},
    };
  }
  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();
    const response = await GKApi.get('/finance/highlights', {
      headers: {
        Authorization: token,
        cmpId: 1,
      },
    });

    this.setState({ reportData: response.data });

    const minLimit = await GKApi.get('/finance/check-buy-min-limit', {
      headers: {
        Authorization: token,
        cmpId: 1,
      },
    });
    this.setState({ buyMinLimit: minLimit.data });
  }

  render() {
    const feeRevenue =
      this.state.reportData.Total_Refund_BDT +
      this.state.reportData.Total_Service_BDT;

    const avgTicket = roundToTwo(
      this.state.reportData.Total_Transaction_BDT /
        this.state.reportData.Number_of_Transactions
    ).toLocaleString('en-US');

    const avgMSISDNTicket = roundToTwo(
      this.state.reportData.Total_Transaction_BDT /
        this.state.reportData.Unique_MSISDN
    ).toLocaleString('en-US');

    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();
    };
    return (
      <React.Fragment>
        <Row>
          <div className="d-flex justify-content-center">
            
            <Col xl={8}>
              <Card className="p-2">
                <CardBody
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p
                        className="font-size-20 mb-3"
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        Market
                      </p>

                      {this.state.buyMinLimit.goldPrice ? (
                        <h4 className="mb-1 font-size-20 text-center">
                          Market price: BDT{' '}
                          {roundToTwo(this.state.buyMinLimit.goldPrice)}
                        </h4>
                      ) : (
                        <h4 className="mb-1 font-size-12 text-center">
                          Data not available
                        </h4>
                      )}
                      {this.state.buyMinLimit.minLimit ? (
                        <h4 className="mb-1 font-size-20 text-center">
                          Minimum Buy Limit: BDT{' '}
                          {this.state.buyMinLimit.minLimit}
                        </h4>
                      ) : (
                        <h4 className="mb-1 font-size-12 text-center">
                          Data not available
                        </h4>
                      )}
                      {this.state.buyMinLimit.calculatedValue ? (
                        <h4
                          className="mb-1 font-size-20 text-center"
                          style={{
                            color:
                              this.state.buyMinLimit.isValid === false
                                ? '#FF0000'
                                : '#00FF00',
                          }}
                        >
                          Minimum Buy Limit Feasibility:{' '}
                          {this.state.buyMinLimit.isValid === false ? (
                            <span style={{ color: '#FF0000' }}>
                              {this.state.buyMinLimit.calculatedValue}
                            </span>
                          ) : (
                            <span style={{ color: '#00FF00' }}>
                              {this.state.buyMinLimit.calculatedValue}
                            </span>
                          )}
                        </h4>
                      ) : (
                        <h4 className="mb-1 font-size-12 text-center">
                          Data not available
                        </h4>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </div>
        </Row>

        <Row>
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Buy
                    </p>

                    {this.state.reportData.Total_Buy_GM ? (
                      <h4 className="mb-1 font-size-12">
                        GM{' '}
                        {/* {roundToTwo(
                          this.state.reportData.Total_Buy_GM
                        ).toLocaleString('en-US')} */}
                        {this.state.reportData.Total_Buy_GM.toFixedDown(4)}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                    {this.state.reportData.Total_Buy_BDT ? (
                      <h4 className="mb-1 font-size-12">
                        BDT{' '}
                        {roundToTwo(
                          this.state.reportData.Total_Buy_BDT
                        ).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted">Total Gold Buy</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Sell
                    </p>

                    {this.state.reportData.Total_Sell_GM ? (
                      <h4 className="mb-1 font-size-12">
                        GM{' '}
                        {/* {roundToTwo(
                          this.state.reportData.Total_Sell_GM
                        ).toLocaleString('en-US')} */}
                        {this.state.reportData.Total_Sell_GM.toFixedDown(4)}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                    {this.state.reportData.Total_Sell_BDT ? (
                      <h4 className="mb-1 font-size-12">
                        BDT{' '}
                        {roundToTwo(
                          this.state.reportData.Total_Sell_BDT
                        ).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data Not available</h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">Total Gold Sell</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Collect
                    </p>

                    {this.state.reportData.Total_Collect_GM ? (
                      <h4 className="mb-1 font-size-12">
                        GM{' '}
                        {/* {roundToTwo(
                          this.state.reportData.Total_Collect_GM
                        ).toLocaleString('en-US')} */}
                        {this.state.reportData.Total_Collect_GM.toFixedDown(4)}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                    {this.state.reportData.Total_Collect_BDT ? (
                      <h4 className="mb-1 font-size-12">
                        BDT{' '}
                        {roundToTwo(
                          this.state.reportData.Total_Collect_BDT
                        ).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">Total Gold Collect</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Gift
                    </p>

                    {this.state.reportData.Total_Gift_GM ? (
                      <h4 className="mb-1 font-size-12">
                        GM{' '}
                        {/* {roundToTwo(
                          this.state.reportData.Total_Gift_GM
                        ).toLocaleString('en-US')} */}
                        {this.state.reportData.Total_Gift_GM.toFixedDown(4)}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                    {this.state.reportData.Total_Gift_BDT ? (
                      <h4 className="mb-1 font-size-12">
                        BDT{' '}
                        {roundToTwo(
                          this.state.reportData.Total_Gift_BDT
                        ).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">Total Gold Gift</span>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Service Fee
                    </p>

                    {this.state.reportData.Total_Buy_Service_BDT ? (
                      <h4 className="mb-1 font-size-12">
                        Buy : BDT{' '}
                        {roundToTwo(
                          this.state.reportData.Total_Buy_Service_BDT
                        ).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
{this.state.reportData.Total_Collect_Service_BDT ? (
                      <h4 className="mb-1 font-size-12">
                        Collect : BDT{' '}
                        {roundToTwo(
                          this.state.reportData.Total_Collect_Service_BDT
                        ).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}

                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">Total Service Fee</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Refund Charge
                    </p>

                    {this.state.reportData.Total_Refund_BDT ? (
                      <h4 className="mb-1 font-size-12">
                        BDT{' '}
                        {roundToTwo(
                          this.state.reportData.Total_Refund_BDT
                        ).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">Total Refund Charge</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Vat
                    </p>

                    {this.state.reportData.Total_VAT_BDT ? (
                      <h4 className="mb-1 font-size-12">
                        BDT{' '}
                        {roundToTwo(
                          this.state.reportData.Total_VAT_BDT
                        ).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">Total Vat</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Fee Revenue
                    </p>

                    {feeRevenue ? (
                      <h4 className="mb-1 font-size-12">
                        BDT {roundToTwo(feeRevenue).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">Total Fee Revenue</span>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      No. of trx
                    </p>

                    {this.state.reportData.Number_of_Transactions ? (
                      <h4 className="mb-1 font-size-12">
                        {roundToTwo(
                          this.state.reportData.Number_of_Transactions
                        ).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">Total No of Trx</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      {' '}
                      Ticket Size
                    </p>

                    {avgTicket === 'NaN' ? (
                      <h4 className="mb-1 font-size-12">Not Available</h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">BDT {avgTicket}</h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">Average Ticket Size</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="px-1 py-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Unique MSISDN Trx
                    </p>

                    {this.state.reportData.Unique_MSISDN ? (
                      <h4 className="mb-1 font-size-12">
                        {roundToTwo(
                          this.state.reportData.Unique_MSISDN
                        ).toLocaleString('en-US')}
                      </h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">Data not available</h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">
                    Total no of Unique MSISDN Trx{' '}
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="p-2">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p
                      className="font-size-14 mb-3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Average MSISDN
                    </p>
                    {avgMSISDNTicket === 'NaN' ? (
                      <h4 className="mb-1 font-size-12">Not Available</h4>
                    ) : (
                      <h4 className="mb-1 font-size-12">
                        BDT {avgMSISDNTicket}
                      </h4>
                    )}
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-1">
                <div className="text-truncate">
                  <span className="text-muted ms-2">Average MSISDN Ticket</span>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default MiniWidgets;

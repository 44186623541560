import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import './dashboard.scss';
import { roundToTwo } from '../../../components/config';
// import apexchart from 'apexcharts';

class SalesAnalyticsTrxAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [30, 30, 25, 25],
      options: {
        chart: {
          id: 'trxAmount',
        },
        labels: ['Buy', 'Sell', 'Collect', 'Gift'],
        plotOptions: {
          pie: {
            donut: {
              size: '75%',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ['#5664d2', '#1cbb8c', '#eeb902', '#A04000'],
      },
    };
  }

  async componentDidMount() {
    
    // this.setState({
    //   ...this.state,
    //   series: newSeries,
    // });
    // apexchart.exec(this.state.options.chart.id, 'updateSeries', { series: newSeries });
  }
  render() {
    let newSeries = [
      roundToTwo(this.props.buyAmountPercentage),
      roundToTwo(this.props.sellAmountPercentage),
      roundToTwo(this.props.collectAmountPercentage),
      roundToTwo(this.props.giftAmountPercentage),
    ];

    // eslint-disable-next-line
    this.state.series=newSeries
    return (
      <React.Fragment>
        <div id="donut-chart" className="apex-charts">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="donut"
            height="250"
          />
        </div>

        <Row>
          <Col xs={3}>
            <div className="text-center mt-4">
              <p className="mb-2 text-truncate">
                <i className="mdi mdi-circle text-primary font-size-10 me-1"></i>{' '}
                Buy
              </p>
              <h5 className="fw-bold">
                {roundToTwo(this.props.buyAmountPercentage)} %
              </h5>
            </div>
          </Col>
          <Col xs={3}>
            <div className="text-center mt-4">
              <p className="mb-2 text-truncate">
                <i className="mdi mdi-circle text-success font-size-10 me-1"></i>{' '}
                Sell
              </p>
              <h5 className="fw-bold">
                {roundToTwo(this.props.sellAmountPercentage)} %
              </h5>
            </div>
          </Col>
          <Col xs={3}>
            <div className="text-center mt-4">
              <p className="mb-2 text-truncate">
                <i className="mdi mdi-circle text-warning font-size-10 me-1"></i>{' '}
                Collect
              </p>
              <h5 className="fw-bold">
                {roundToTwo(this.props.collectAmountPercentage)} %
              </h5>
            </div>
          </Col>
          <Col xs={3}>
            <div className="text-center mt-4">
              <p className="mb-2 text-truncate">
                <i
                  className="mdi mdi-circle font-size-10 me-1"
                  style={{ color: '#A04000' }}
                ></i>{' '}
                Gift
              </p>
              <h5 className="fw-bold">
                {roundToTwo(this.props.giftAmountPercentage)} %
              </h5>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SalesAnalyticsTrxAmount;

import React, { Component } from 'react';

import {
  Row,
  Col,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
} from 'reactstrap';
import CampaignData from './CampaignData';
import classnames from 'classnames';
import CampaignManager from '../CampaignManager';
import CommunicationHistory from './CommunicationHistory';
import { getGroupName } from '../../../components/config';
import Presets from './Presets';
import CancelledComChannelList from './CancelledComChannelList';

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        // { title: 'Nazox', link: '/' },
        // { title: 'Marketing-Dashboard', link: '#' },
      ],
      activeTabJustify: '1',
      isSuper: false,
      isMkt: false,
    };
  }

  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };
  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'MKT') {
        this.setState({
          isMkt: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    let groupNames = [...gN];

    this.setState({ groupName: groupNames });
  }

  render() {
    return (
      <>
        <div className="page-content">
          {this.state.isMkt === true || this.state.isSuper === true ? (
            <Row>
              <Col xl="12">
                <Nav tabs className="nav-tabs-custom nav-justified">
                  {/*Profile Info Tab  */}
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTabJustify === '1',
                      })}
                      onClick={async () => {
                        this.toggleCustomJustified('1');
                      }}
                    >
                      <span className="d-none d-sm-block fw-bold">
                        Campaign Data
                      </span>
                    </NavLink>
                  </NavItem>

                  {/*address Info Tab  */}
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTabJustify === '3',
                      })}
                      onClick={async () => {
                        this.toggleCustomJustified('3');
                      }}
                    >
                      <span className="d-none d-sm-block fw-bold">
                        Schedule
                      </span>
                    </NavLink>
                  </NavItem>

                  {/*Bkash Info Tab  */}
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTabJustify === '4',
                      })}
                      onClick={() => {
                        this.toggleCustomJustified('4');
                      }}
                    >
                      <span className="d-none d-sm-block fw-bold">
                        Communication History
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTabJustify === '5',
                      })}
                      onClick={() => {
                        this.toggleCustomJustified('5');
                      }}
                    >
                      <span className="d-none d-sm-block fw-bold">Presets</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTabJustify === '2',
                      })}
                      onClick={() => {
                        this.toggleCustomJustified('2');
                      }}
                    >
                      <span className="d-none d-sm-block fw-bold">Cancelled Com Channel</span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTabJustify}>
                  <TabPane tabId="1" className="p-3">
                    <CampaignData />
                  </TabPane>
                  <TabPane tabId="2" className="p-3">
                    <CancelledComChannelList/>
                  </TabPane>
                  <TabPane tabId="3" className="p-3">
                    <CampaignManager />
                  </TabPane>
                  <TabPane tabId="4" className="p-3">
                    <CommunicationHistory />
                  </TabPane>
                  <TabPane tabId="5" className="p-3">
                    <Presets />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          ) : (
            <h5>Sorry you don't have permission to view this</h5>
          )}
        </div>
      </>
    );
  }
}

export default Campaign;

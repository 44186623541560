import React, { Component } from 'react';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  Container,
  Label,
  Input,
} from 'reactstrap';
import classnames from 'classnames';
import ChangePassword from './changePassword';
// import { getUser } from '../../components/config';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getGroupName } from '../../components/config';

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const name = useSelector((state) => state.Login.user.user?.name);
    const email = useSelector((state) => state.Login.user.user?.email);
    const designation = useSelector(
      (state) => state.Login.user.user?.designation
    );
    const dob = useSelector((state) => state.Login.user.user?.dob);
    const phone = useSelector((state) => state.Login.user.user?.phone);
    return (
      <Component
        {...props}
        name={name}
        email={email}
        designation={designation}
        dob={dob}
        phone={phone}
      />
    );
  };
}

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Admin', link: '/' },
        { title: 'User Profile', link: '/user-profile' },
      ],
      activeTabJustify: '1',
      name: '',
      email: '',
      phoneNumber: '',
      dob: null,
      designation: '',
      col1: true,
      col2: false,
      col3: false,
      col5: true,
    };
    this.toggleCustomJustified = this.toggleCustomJustified.bind(this);
  }

  toggleCustomJustified(tab) {
    if (this.state.activeTabJustify !== tab) {
      this.setState({
        activeTabJustify: tab,
      });
    }
  }

  handleNameChange = (event) => {
    this.setState({ ...this.state, name: event.target.value });
  };

  handleEmailChange = (event) => {
    this.setState({ ...this.state, email: event.target.value });
  };

  handlePhoneNumberChange = (event) => {
    this.setState({ ...this.state, phoneNumber: event.target.value });
  };

  handleDOBChange = (event) => {
    this.setState({ ...this.state, dob: event.target.value });
  };

  handleDesignationChange = (event) => {
    this.setState({ ...this.state, designation: event.target.value });
  };


  render() {
    let groupName = getGroupName();
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Profile"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row className="d-flex justify-content-center">
              <Col xs={6}>
                <div>
                  <Card>
                    <CardBody>
                      <div>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: this.state.activeTabJustify === '1',
                              })}
                              onClick={() => {
                                this.toggleCustomJustified('1');
                              }}
                            >
                              <span className="d-none d-sm-block">
                                User Profile for {this.props.name}
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: this.state.activeTabJustify === '2',
                              })}
                              onClick={() => {
                                this.toggleCustomJustified('2');
                              }}
                            >
                              <span className="d-none d-sm-block">
                                Change Password
                              </span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </CardBody>
                  </Card>

                  <TabContent activeTab={this.state.activeTabJustify}>
                    <TabPane tabId="1">
                      <Card>
                        <CardBody>
                          <h3
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px',
                              textAlign: 'center',
                              color: 'GrayText',
                            }}
                          >
                            {this.props.name}
                          </h3>

                          <Row className="mb-3">
                            <Label
                              htmlFor="email-input"
                              className="col-md-3 col-form-label"
                            >
                              Email
                            </Label>
                            <Col md={9}>
                              <Input
                                type="email"
                                defaultValue={this.props.email}
                                placeholder="Please input email address of the admin user"
                                id="email-input"
                                onChange={this.handleEmailChange}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="phone-number-input"
                              className="col-md-3 col-form-label"
                            >
                              Phone Number
                            </Label>
                            <Col md={9}>
                              <Input
                                type="text"
                                defaultValue={this.props.phone}
                                placeholder="Please input email address of the admin user"
                                id="email-input"
                                onChange={this.handlePhoneNumberChange}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="dob"
                              className="col-md-3 col-form-label"
                            >
                              Date of birth
                            </Label>
                            <Col md={9}>
                              <Input
                                type="text"
                                defaultValue={moment(this.props.dob).format(
                                  'DD/MM/YYYY'
                                )}
                                placeholder="Please input email address of the admin user"
                                id="email-input"
                                onChange={this.handleDOBChange}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="designation"
                              className="col-md-3 col-form-label"
                            >
                              Designation
                            </Label>
                            <Col md={9}>
                              <Input
                                type="text"
                                defaultValue={this.props.designation}
                                placeholder="Please input email address of the admin user"
                                id="email-input"
                                onChange={this.handleDesignationChange}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="group-name"
                              className="col-md-3 col-form-label"
                            >
                              Assigned Groups(s)
                            </Label>
                            <Col md={9}>
                              <div
                                className="p-2"
                                style={{
                                  border: '1px solid gray',
                                  borderRadius: '5px',
                                  backgroundColor: 'azure',
                                }}
                              >
                                {groupName}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </TabPane>
                    <TabPane tabId="2">
                      <Card>
                        <CardBody>
                          <Row>
                            <Col sm="12">
                              <ChangePassword />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(Profile);

import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Col,
  Button,
  Card,
  CardBody,
  Input,
} from 'reactstrap';
import Switch from '@mui/material/Switch';
import DatePicker from '../../../components/Common/DatePicker';
import {
  capitalizeFirstLetter,
  getToken,
  // getUser,
  GKApi,
} from '../../../components/config';
import moment from 'moment';
import CustomReferReport from './customReferReport';
import UserReferReport from './userReferReport';
import AllReferReport from './allReferRerport';

const ReferReport = (props) => {
  const [referTypeMenu, setReferTypeMenu] = useState(false);
  const [referType, setReferType] = useState('Select Refer Type');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [customReferReportData, setCustomReferReportData] = useState([]);
  const [userReferReportData, setUserReferReportData] = useState([]);
  const [allReferReportData, setAllReferReportData] = useState([]);
  const [referCode, setReferCode] = useState('');
  const [summaryChecked, setSummaryChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [referDetails, setReferDetails] = useState({});
  const [dateRangeTypeMenu, setDateRangeTypeMenu] = useState(false);
  const [dateRangeType, setDateRangeType] = useState('Transaction');

  const handleSummaryCheckedChange = (event) => {
    setSummaryChecked(event.target.checked);
  };
  const handleReferCodeChange = (event) => {
    setReferCode(event.target.value);
  };

  const clearAll = () => {
    setReferTypeMenu(false);
    setReferType('Select Refer Type');
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setCustomReferReportData([]);
    setUserReferReportData([]);
    setAllReferReportData([]);
    setReferCode('');
    setSummaryChecked(false);
  };

  const handleSelectedStartDateChange = (event) => {
    setSelectedStartDate(moment(event).format('YYYY-MM-DD'));
  };

  const handleSelectedEndDateChange = (event) => {
    setSelectedEndDate(moment(event).format('YYYY-MM-DD'));
  };
  const onClick = async (event) => {
    event.preventDefault();

    const token = getToken();
    // if(selectedStartDate.isBefore(selectedEndDate)){

    if (summaryChecked === false) {
      setIsLoading(true);
      let submitData = await GKApi.get(
        `/finance/refer/report/details?${
          referType === 'Select Refer Type' ? '' : `referType=${referType}&`
        }startDate=${selectedStartDate}&endDate=${selectedEndDate}&dateType=${dateRangeType}${
          referCode !== '' ? `&referCode=${referCode}` : ''
        }`,
        {
          headers: {
            Authorization: token,
            cmpId: 166,
          },
        }
      );

      if (referType === 'Custom') {
        setCustomReferReportData(submitData.data.data);
      } else if (referType === 'User') {
        setUserReferReportData(submitData.data.data);
      } else {
        setAllReferReportData(submitData.data.data);
      }

      setIsLoading(false);
      submitData.data.data.length === 0 && window.alert('No Data Available');
    } else {
      setIsLoading(true);
      let submitData = await GKApi.get(
        `/finance/refer/report/summary?startDate=${selectedStartDate}&endDate=${selectedEndDate}&dateType=${dateRangeType}&referCode=${referCode}`,
        {
          headers: {
            Authorization: token,
            cmpId: 166,
          },
        }
      );
      if (submitData.data.referDetails) {
        setCustomReferReportData(submitData.data.data);
        setReferDetails(submitData.data.referDetails);
      } else {
        setUserReferReportData(submitData.data.data);
      }
      setIsLoading(false);
      submitData.data.data.length === 0 && window.alert('No Data Available');
    }
  };
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="d-grid gap-3">
          <div className="d-flex align-items-center">
            <Col xs={4}>
              <h5>Summary</h5>
            </Col>
            <Col xs={8} className="d-flex  gap-3">
              <Switch
                checked={summaryChecked}
                onChange={handleSummaryCheckedChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Col>
          </div>
          {summaryChecked === false && (
            <div className="d-flex align-items-center">
              <Col xs={4}>
                <h5>Select Refer Type (Optional)</h5>
              </Col>
              <Col xs={8}>
                <Dropdown
                  isOpen={referTypeMenu}
                  toggle={(event) => setReferTypeMenu(!referTypeMenu)}
                >
                  <DropdownToggle className="px-5 py-2" color="primary">
                    {capitalizeFirstLetter(referType)}
                    <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={(event) => {
                        setReferType('User');
                      }}
                    >
                      User
                    </DropdownItem>

                    <DropdownItem onClick={(event) => setReferType('Custom')}>
                      Custom
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </div>
          )}

          <div className="d-flex align-items-center">
            <Col xs={4}>
              <h5>Refer Code ({summaryChecked ? 'Mandatory' : 'Optional'})</h5>
            </Col>
            <Col xs={8}>
              <Input
                type="text"
                value={referCode}
                placeholder="Please input Refer Code"
                onChange={handleReferCodeChange}
              />
            </Col>
          </div>

          <div className="d-flex align-items-center">
            <Col xs={4}>
              <h5>Select Date Range Type (Mandatory)</h5>
            </Col>
            <Col xs={8}>
              <Dropdown
                isOpen={dateRangeTypeMenu}
                toggle={(event) => setDateRangeTypeMenu(!dateRangeTypeMenu)}
              >
                <DropdownToggle className="px-5 py-2" color="primary">
                  {dateRangeType} Date
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(event) => {
                      setDateRangeType('Transaction');
                    }}
                  >
                    Transaction Date
                  </DropdownItem>

                  <DropdownItem
                    onClick={(event) => setDateRangeType('Joining')}
                  >
                    Joining Date
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </div>
          <div className="d-flex align-items-center">
            <Col xs={4}>
              <h5>Date range (Mandatory)</h5>
            </Col>
            <Col xs={8} className="d-flex gap-3">
              <div
                style={{
                  border: '1px solid',
                  borderRadius: '5px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  paddingRight: '4px',
                  paddingLeft: '4px',
                }}
                className="d-flex gap-3 align-content-center"
              >
                <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                  Start Date
                </p>
                <DatePicker
                  selectedDate={selectedStartDate}
                  handleSelectedDateChange={handleSelectedStartDateChange}
                />
              </div>
              <div
                style={{
                  border: '1px solid',
                  borderRadius: '5px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  paddingRight: '5px',
                  paddingLeft: '5px',
                }}
                className="d-flex d-grid gap-3"
              >
                <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                  End Date
                </p>
                <DatePicker
                  selectedDate={selectedEndDate}
                  handleSelectedDateChange={handleSelectedEndDateChange}
                  className="mx-3"
                />
              </div>
            </Col>
          </div>

          <div className="d-flex align-items-center gap-3 justify-content-center">
            {selectedStartDate && selectedEndDate ? (
              <div className="d-flex gap-3">
                <Button onClick={onClick}>
                  {isLoading && (
                    <span className="spinner-grow spinner-grow-sm"></span>
                  )}
                  {isLoading
                    ? 'Generating Report, Please Wait!!'
                    : 'View Report'}
                </Button>
                <Button onClick={clearAll}>Clear report</Button>
              </div>
            ) : (
              'Please choose the date parameter to view refer report'
            )}
          </div>

          <Card>
            <CardBody>
              {userReferReportData.length > 0 ? (
                <UserReferReport
                  userReferReportData={userReferReportData}
                  summary={summaryChecked}
                ></UserReferReport>
              ) : customReferReportData.length > 0 ? (
                <CustomReferReport
                  customReferReportData={customReferReportData}
                  summary={summaryChecked}
                  referDetails={referDetails}
                ></CustomReferReport>
              ) : allReferReportData.length > 0 ? (
                <AllReferReport
                  allReferReportData={allReferReportData}
                  summary={summaryChecked}
                />
              ) : (
                ''
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ReferReport;

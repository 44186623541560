import React, { Component } from 'react';
import MaterialTable from 'material-table';


//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { roundToTwo } from '../../../components/config';

// Table data

class SellTrx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'CRM', link: '#' },
        { title: 'User List', link: '#' },
        { title: 'Sell History', link: '#' },
      ],
      visible: false,
      modal_standard: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();}
    const columns = [
      {
        field: 'slNo',
        title: 'SL No',
      },
      {
        field: 'transactionReference',
        title: 'Transaction Reference No',
        render: (row) => (
          <div>
            {row.transactionReference === ' '
              ? 'Not available'
              : row.transactionReference}
          </div>
        ),
      },
      {
        field: 'sellRequestID',
        title: 'Sell Request ID',
      },
      {
        field: 'sellRequestDate',
        title: 'Transaction Date',
      },

      {
        field: 'sellAmountInGoldGM',
        title: 'Sell Amount (gm)',
        render: (row) => <div>{(row.sellAmountInGoldGM).toFixedDown(4)}</div>,
      },
      {
        field: 'perUnitPrice',
        title: 'Per Unit Price (Live market Price)',
        render: (row) => <div>{roundToTwo(row.perUnitPrice)}</div>,
      },
      {
        field: 'principalAmount',
        title: 'Principal Amount',
        render: (row) => <div>{roundToTwo(row.principalAmount)}</div>,
      },
      {
        field: 'refundCharge',
        title: 'Refund Charge',
      },

      {
        field: 'discountAmount',
        title: 'Discount Amount',
        render: (row) => <div>{roundToTwo(row.discountAmount)}</div>,
      },
      {
        field: 'promoAmount',
        title: 'Promo Amount',
        render: (row) => <div>{roundToTwo(row.promoAmount)}</div>,
      },

      {
        field: 'sellMedium',
        title: 'Sell Medium',
      },

      {
        field: 'netSellAmount',
        title: 'Net Sell Amount',
        render: (row) => <div>{roundToTwo(row.netSellAmount)}</div>,
      },
    ];


    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="User-Sell transaction history"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <MaterialTable
              title={'Sell transaction history'}
              columns={columns}
              data={this.props.location.state.data}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                pageSize: 20,
                columnsButton: true,
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SellTrx;

import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import {
  getGroupName,
  getToken,
  getUser,
  GKApi,
} from '../../../components/config';
import SweetAlert from 'react-bootstrap-sweetalert';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { deviceInfo } from '../../../helpers/device_info_helper';

class FinanceSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '#' },
        { title: 'Finance Internal Settings', link: '#' },
      ],

      bkashSettlementFeeChange: false,

      bkashSettlementFeeDefault: 0,

      bkashSettlementFee: 0,
      nagadSettlementFeeChange: false,

      nagadSettlementFeeDefault: 0,

      nagadSettlementFee: 0,
      buyServiceChargeVatChange: false,

      buyServiceChargeVatDefault: 0,

      buyServiceChargeVat: 0,
      sellRefundChargeVatChange: false,

      sellRefundChargeVatDefault: 0,

      sellRefundChargeVat: 0,
      collectFeeVatChange: false,

      collectFeeVatDefault: 0,

      collectFeeVat: 0,
      giftFeeVatChange: false,

      giftFeeVatDefault: 0,

      giftFeeVat: 0,
      bblSettlementFeeChange: false,

      bblSettlementFeeDefault: 0,

      bblSettlementFee: 0,
      citySettlementFeeChange: false,

      citySettlementFeeDefault: 0,

      citySettlementFee: 0,

      lastApprovedData: {},
      lastRequestedData: {},
      success: '',
      custom_div1: false,
      tooltipOpen: false,
      functionStatus: [],
      modal_standard: false,
      modal_standard4: false,
      modal_standard5: false,
      deviceInfo: {},
      reason: '',

      canViewInternalSettings: 1,
      internalSettingsAuthStatForUser: 1,
      compAuthStat: 0,
      canAuthInternalSettings: 0,
      canInsertInternalSettings: 0,
      isFin: false,
      isSuper: false,
    };
  }
  // modal toggles
  removeBodyCss = () => {
    document.body.classList.add('no_padding');
  };

  tog_standard = () => {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
      reason: '',
    }));
    this.removeBodyCss();
  };
  tog_standard4 = () => {
    this.setState((prevState) => ({
      modal_standard4: !prevState.modal_standard4,
      reason: '',
    }));
    this.removeBodyCss();
  };
  tog_standard5 = () => {
    this.setState((prevState) => ({
      modal_standard5: !prevState.modal_standard5,
      reason: '',
    }));
    this.removeBodyCss();
  };
  // api calls on page load

  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    // GET request using axios with async/await
    const token = getToken();
    // const gId = getGroupId();

    //get request for input data
    const response = await GKApi.get('/finance/internal-settings', {
      headers: {
        Authorization: token,
        cmpId: '18',
      },
    });

    this.setState({
      lastApprovedData: response.data.lastApproved,
      lastRequestedData: response.data.lastRequested,
    });

    //setting input data to wait

    if (this.state.lastRequestedData === null) {
      this.setState({
        buyServiceChargeVatDefault:
          this.state.lastApprovedData?.buyServiceChargeVat,
        buyServiceChargeVat: this.state.lastApprovedData?.buyServiceChargeVat,
        sellRefundChargeVatDefault:
          this.state.lastApprovedData?.sellRefundChargeVat,
        sellRefundChargeVat: this.state.lastApprovedData?.sellRefundChargeVat,
        collectFeeVatDefault: this.state.lastApprovedData?.collectFeeVat,
        collectFeeVat: this.state.lastApprovedData?.collectFeeVat,
        giftFeeVatDefault: this.state.lastApprovedData?.giftFeeVat,
        giftFeeVat: this.state.lastApprovedData?.giftFeeVat,
        bkashSettlementFeeDefault:
          this.state.lastApprovedData?.bkashSettlementFee,
        bkashSettlementFee: this.state.lastApprovedData?.bkashSettlementFee,
        nagadSettlementFeeDefault:
          this.state.lastApprovedData?.nagadSettlementFee,
        nagadSettlementFee: this.state.lastApprovedData?.nagadSettlementFee,
        bblSettlementFeeDefault: this.state.lastApprovedData?.bblSettlementFee,
        bblSettlementFee: this.state.lastApprovedData?.bblSettlementFee,
        citySettlementFeeDefault:
          this.state.lastApprovedData?.citySettlementFee,
        citySettlementFee: this.state.lastApprovedData?.citySettlementFee,
      });
    } else {
      this.setState({
        buyServiceChargeVatDefault:
          this.state.lastApprovedData?.buyServiceChargeVat,
        buyServiceChargeVat: this.state.lastRequestedData?.buyServiceChargeVat,
        sellRefundChargeVatDefault:
          this.state.lastApprovedData?.sellRefundChargeVat,
        sellRefundChargeVat: this.state.lastRequestedData?.sellRefundChargeVat,
        collectFeeVatDefault: this.state.lastApprovedData?.collectFeeVat,
        collectFeeVat: this.state.lastRequestedData?.collectFeeVat,
        giftFeeVatDefault: this.state.lastApprovedData?.giftFeeVat,
        giftFeeVat: this.state.lastRequestedData?.giftFeeVat,
        bkashSettlementFeeDefault:
          this.state.lastApprovedData?.bkashSettlementFee,
        bkashSettlementFee: this.state.lastRequestedData?.bkashSettlementFee,
        nagadSettlementFeeDefault:
          this.state.lastApprovedData?.nagadSettlementFee,
        nagadSettlementFee: this.state.lastRequestedData?.nagadSettlementFee,
        bblSettlementFeeDefault: this.state.lastApprovedData?.bblSettlementFee,
        bblSettlementFee: this.state.lastRequestedData?.bblSettlementFee,
        citySettlementFeeDefault:
          this.state.lastApprovedData?.citySettlementFee,
        citySettlementFee: this.state.lastRequestedData?.citySettlementFee,

        compAuthStat: this.state.lastRequestedData?.compAuthStat,
      });
    }

    const getPermission = await GKApi.get(`/role/permission?cmpId=18`, {
      headers: {
        Authorization: token,
      },
    });
    // getting permission data from api

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    // setting permissions to their states of their own

    this.setState({
      canViewInternalSettings: permissionData[18].canView,
      internalSettingsAuthStatForUser: permissionData[18].authState,
      canAuthInternalSettings: permissionData[18].canAuth,
      canInsertInternalSettings: permissionData[18].canInsert,
    });
    //function status api call

    // calling geo location api call for the device info
    const res = deviceInfo();
    this.setState({ deviceInfo: res });
  }

  //  change handlers

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  handleBuyServiceChargeVatChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        buyServiceChargeVat: event.target.value,
        buyServiceChargeVatChange: true,
      });
    } else
      this.setState({
        ...this.state,
        buyServiceChargeVat: '',
        buyServiceChargeVatChange: false,
      });
  };

  handleSellRefundChargeVatChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        sellRefundChargeVat: event.target.value,
        sellRefundChargeVatChange: true,
      });
    } else
      this.setState({
        ...this.state,
        sellRefundChargeVat: '',
        sellRefundChargeVatChange: false,
      });
  };

  handleCollectFeeVatChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        collectFeeVat: event.target.value,
        collectFeeVatChange: true,
      });
    } else
      this.setState({
        ...this.state,
        collectFeeVat: '',
        collectFeeVatChange: false,
      });
  };

  handleGiftFeeVatChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        giftFeeVat: event.target.value,
        giftFeeVatChange: true,
      });
    } else
      this.setState({ ...this.state, giftFeeVat: '', giftFeeVatChange: false });
  };

  handleBkashSettlementFeeChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        bkashSettlementFee: event.target.value,
        bkashSettlementFeeChange: true,
      });
    } else
      this.setState({
        ...this.state,
        bkashSettlementFee: '',
        bkashSettlementFeeChange: false,
      });
  };

  handleNagadSettlementFeeChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        nagadSettlementFee: event.target.value,
        nagadSettlementFeeChange: true,
      });
    } else
      this.setState({
        ...this.state,
        nagadSettlementFee: '',
        nagadSettlementFeeChange: false,
      });
  };

  handleBBLSettlementFeeChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        bblSettlementFee: event.target.value,
        bblSettlementFeeChange: true,
      });
    } else
      this.setState({
        ...this.state,
        bblSettlementFee: '',
        bblSettlementFeeChange: false,
      });
  };

  handleCitySettlementFeeChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        citySettlementFee: event.target.value,
        citySettlementFeeChange: true,
      });
    } else
      this.setState({
        ...this.state,
        citySettlementFee: '',
        citySettlementFeeChange: false,
      });
  };

  handleReasonChange = (event) => {
    this.setState({ ...this.state, reason: event.target.value });
  };

  //change handlers

  handleSubmit = async (event) => {
    event.preventDefault();
    let sendData = {
      cmpId: 18,
      buyServiceChargeVat: this.state.buyServiceChargeVat,
      sellRefundChargeVat: this.state.sellRefundChargeVat,
      collectFeeVat: this.state.collectFeeVat,
      giftFeeVat: this.state.giftFeeVat,
      bkashSettlementFee: this.state.bkashSettlementFee,
      nagadSettlementFee: this.state.nagadSettlementFee,
      bblSettlementFee: this.state.bblSettlementFee,
      citySettlementFee: this.state.citySettlementFee,
      deviceInfo: this.state.deviceInfo,
    };
    const token = getToken();
    let submitData = await GKApi.post('/finance/internal-settings', sendData, {
      headers: {
        Authorization: token,
        cmpId: '18',
      },
    });
    if (submitData) {
      this.setState({
        ...this.state,
        // success: 'Finance Inputs Updated Successfully',
        custom_div1: true,
      });
      setTimeout(() => {
        this.setState({ custom_div1: false });
      }, 5000);
    }
    const response = await GKApi.get('/finance/internal-settings', {
      headers: {
        Authorization: token,
        cmpId: '18',
      },
    });
    this.setState(
      {
        lastApprovedData: response.data.lastApproved,
        lastRequestedData: response.data.lastRequested,
      },
      () => {}
    );
  };

  render() {
    const authDiff =
      this.state.internalSettingsAuthStatForUser - this.state.compAuthStat;
    const loggedUser = getUser();
    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isFin === true || this.state.isSuper === true ? (
            <Container fluid>
              <Breadcrumbs
                title="Finance Internal Settings"
                breadcrumbItems={this.state.breadcrumbItems}
              />

              <Col xl={12}>
                {/* finance inputs with the previous data and proposed data. */}
                {this.state.canViewInternalSettings === 1 ? (
                  <Form onSubmit={this.handleSubmit} id="18">
                    <FormGroup>
                      <Row>
                        <Col>
                          <Card>
                            <CardBody>
                              <h4 className="card-title">
                                Finance Internal Settings
                              </h4>
                              <p className="card-title-desc">
                                Please input Finance Internal Settings values
                                here.
                              </p>
                              <div className="d-flex mb-3">
                                <Col xs={4}></Col>
                                <Col xs={4}>
                                  <h5>Present Value</h5>
                                </Col>
                                <Col xs={4}>
                                  <h5>Proposed Value</h5>
                                </Col>
                              </div>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="Buy Service Charge Vat-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Buy Service Charge Vat (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={
                                      this.state.buyServiceChargeVatDefault
                                    }
                                    placeholder="Please input the value for buy service charge vat"
                                    id="Buy Service Charge Vat-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.buyServiceChargeVat}
                                    placeholder="Please input the value for buy service charge vat"
                                    id="Buy Service Charge Vat-input"
                                    onChange={
                                      this.handleBuyServiceChargeVatChange
                                    }
                                    style={{
                                      backgroundColor:
                                        this.state.buyServiceChargeVatChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInternalSettings ===
                                        1 ||
                                        this.state.canInsertInternalSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="Sell Refund Charge Vat-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Sell Refund Charge Vat (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={
                                      this.state.sellRefundChargeVatDefault
                                    }
                                    placeholder="Please input the value for sell refund charge vat"
                                    id="Sell Refund Charge Vat-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.sellRefundChargeVat}
                                    placeholder="Please input the value for sell refund charge vat"
                                    id="Sell Refund Charge Vat-input"
                                    onChange={
                                      this.handleSellRefundChargeVatChange
                                    }
                                    style={{
                                      backgroundColor:
                                        this.state.sellRefundChargeVatChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInternalSettings ===
                                        1 ||
                                        this.state.canInsertInternalSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="Collect Fee Vat-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Collect Fee Vat (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.collectFeeVatDefault}
                                    placeholder="Please input the value for collect fee vat"
                                    id="Collect Fee Vat-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.collectFeeVat}
                                    placeholder="Please input the value for collect fee vat"
                                    id="Collect Fee Vat-input"
                                    onChange={this.handleCollectFeeVatChange}
                                    style={{
                                      backgroundColor:
                                        this.state.collectFeeVatChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInternalSettings ===
                                        1 ||
                                        this.state.canInsertInternalSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="Gift Fee Vat-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Gift Fee Vat (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.giftFeeVatDefault}
                                    placeholder="Please input the value for Gift Fee Vat"
                                    id="Gift Fee Vat-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.giftFeeVat}
                                    placeholder="Please input the value for Gift Fee Vat"
                                    id="Gift Fee Vat-input"
                                    onChange={this.handleGiftFeeVatChange}
                                    style={{
                                      backgroundColor:
                                        this.state.giftFeeVatChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInternalSettings ===
                                        1 ||
                                        this.state.canInsertInternalSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="Bkash Settlement Fee-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Bkash Settlement Fee (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.bkashSettlementFeeDefault}
                                    placeholder="Please input the value for Bkash Settlement Fee"
                                    id="Bkash Settlement Fee-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.bkashSettlementFee}
                                    placeholder="Please input the value for Bkash Settlement Fee"
                                    id="Bkash Settlement Fee-input"
                                    onChange={
                                      this.handleBkashSettlementFeeChange
                                    }
                                    style={{
                                      backgroundColor:
                                        this.state.bkashSettlementFeeChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInternalSettings ===
                                        1 ||
                                        this.state.canInsertInternalSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="Nagad Settlement Fee-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Nagad Settlement Fee (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.nagadSettlementFeeDefault}
                                    placeholder="Please input the value for Nagad Settlement Fee"
                                    id="Nagad Settlement Fee-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.nagadSettlementFee}
                                    placeholder="Please input the value for Nagad Settlement Fee"
                                    id="Nagad Settlement Fee-input"
                                    onChange={
                                      this.handleNagadSettlementFeeChange
                                    }
                                    style={{
                                      backgroundColor:
                                        this.state.nagadSettlementFeeChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInternalSettings ===
                                        1 ||
                                        this.state.canInsertInternalSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="BBL Settlement Fee-input"
                                  className="col-md-3 col-form-label"
                                >
                                  BBL Settlement Fee (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.bblSettlementFeeDefault}
                                    placeholder="Please input the value for BBL Settlement Fee"
                                    id="BBL Settlement Fee-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.bblSettlementFee}
                                    placeholder="Please input the value for BBL Settlement Fee"
                                    id="BBL Settlement Fee-input"
                                    onChange={this.handleBBLSettlementFeeChange}
                                    style={{
                                      backgroundColor:
                                        this.state.bblSettlementFeeChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInternalSettings ===
                                        1 ||
                                        this.state.canInsertInternalSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="City Settlement Fee-input"
                                  className="col-md-3 col-form-label"
                                >
                                  City Settlement Fee (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.citySettlementFeeDefault}
                                    placeholder="Please input the value for City Settlement Fee"
                                    id="City Settlement Fee-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.citySettlementFee}
                                    placeholder="Please input the value for City Settlement Fee"
                                    id="City Settlement Fee-input"
                                    onChange={
                                      this.handleCitySettlementFeeChange
                                    }
                                    style={{
                                      backgroundColor:
                                        this.state.citySettlementFeeChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInternalSettings ===
                                        1 ||
                                        this.state.canInsertInternalSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              {/* successful submit sweet alert */}
                              <Row
                                className="d-flex justify-content-start"
                                style={{
                                  fontSize: '20px',
                                  color: 'darkorange',
                                }}
                              >
                                {this.state.custom_div1 ? (
                                  <SweetAlert
                                    title="Request successfully submitted for review!!"
                                    timeout={2500}
                                    style={{
                                      top: '0px',
                                      right: '0px',
                                    }}
                                    showCloseButton={false}
                                    showConfirm={false}
                                    success
                                    onConfirm={() =>
                                      this.setState({ custom_div1: false })
                                    }
                                  ></SweetAlert>
                                ) : null}
                              </Row>
                              {/* successful submit sweet alert */}

                              {/* submit based on component auth state, user auth state, auth state difference */}
                              <Row
                                className="d-flex justify-content-end mx-1"
                                id="foo"
                              >
                                {/* auth permission check for maker  */}
                                {this.state.internalSettingsAuthStatForUser ===
                                  1 && this.state.lastRequestedData === null ? (
                                  <Button
                                    type="submit"
                                    style={{
                                      width: '35%',
                                    }}
                                  >
                                    Submit
                                  </Button>
                                ) : this.state
                                    .internalSettingsAuthStatForUser === 1 &&
                                  this.state.lastRequestedData != null ? (
                                  <Button
                                    style={{
                                      width: '35%',
                                    }}
                                    disabled
                                  >
                                    You have already a pending request
                                  </Button>
                                ) : //  auth permission check for maker

                                // auth permission check for checker
                                this.state.internalSettingsAuthStatForUser ===
                                    2 &&
                                  this.state.lastRequestedData != null &&
                                  authDiff === 1 ? (
                                  <div className="d-flex justify-content-between my-5">
                                    <Button
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({
                                          modal_standard4: true,
                                        });
                                      }}
                                    >
                                      Approve
                                    </Button>

                                    {/*
                                     * @desc modal for APPROVING request for the CHECKER STARTS here
                                     */}

                                    <Modal
                                      isOpen={this.state.modal_standard4}
                                      toggle={this.tog_standard4}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard4: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>

                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want Approve this
                                            request?
                                          </Row>
                                        </Col>
                                      </ModalBody>

                                      <ModalFooter>
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="waves-effect waves-light"
                                          onClick={async (event) => {
                                            event.preventDefault();
                                            const token = getToken();
                                            let submitData = {
                                              cmpId: 18,
                                              recID:
                                                this.state.lastRequestedData
                                                  ?.recID,
                                              deviceInfo: this.state.deviceInfo,
                                            };
                                            await GKApi.put(
                                              `/finance/internal-settings/approve`,
                                              submitData,
                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );
                                            const response = await GKApi.get(
                                              '/finance/internal-settings',
                                              {
                                                headers: {
                                                  Authorization: token,
                                                  cmpId: '18',
                                                },
                                              }
                                            );
                                            this.setState({
                                              lastApprovedData:
                                                response.data.lastApproved,
                                              lastRequestedData:
                                                response.data.lastRequested,
                                              compAuthStat:
                                                response.data.lastRequested
                                                  .compAuthStat,
                                            });
                                            this.tog_standard4();
                                          }}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          type="button"
                                          onClick={this.tog_standard4}
                                          color="light"
                                          className="waves-effect"
                                        >
                                          No
                                        </Button>
                                      </ModalFooter>
                                    </Modal>

                                    <Button
                                      type="submit"
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({
                                          modal_standard5: true,
                                        });
                                      }}
                                    >
                                      Reject
                                    </Button>

                                    {/*
                                     * @desc modal for REJECTING request for the CHECKER STARTS here
                                     */}
                                    <Modal
                                      isOpen={this.state.modal_standard5}
                                      toggle={this.tog_standard5}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard5: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>

                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want to Reject this
                                            request?
                                          </Row>
                                          <Row className="mb-3 mx-2">
                                            <Input
                                              type="text"
                                              // oninput={validate(this)}
                                              value={this.state.reason}
                                              placeholder="Please specify the reason"
                                              id="gold-price-input"
                                              onChange={this.handleReasonChange}
                                            />
                                          </Row>
                                        </Col>
                                      </ModalBody>

                                      {this.state.reason === '' ? (
                                        ''
                                      ) : (
                                        <ModalFooter>
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="waves-effect waves-light"
                                            onClick={async (event) => {
                                              event.preventDefault();

                                              const token = getToken();
                                              let submitData = {
                                                cmpId: 18,

                                                recID:
                                                  this.state.lastRequestedData
                                                    .recID,

                                                deviceInfo:
                                                  this.state.deviceInfo,
                                                reason: this.state.reason,
                                              };
                                              await GKApi.put(
                                                `finance/internal-settings/reject`,
                                                submitData,
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                  },
                                                }
                                              );
                                              const response = await GKApi.get(
                                                '/finance/internal-settings',
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                    cmpId: '18',
                                                  },
                                                }
                                              );

                                              this.setState({
                                                lastApprovedData:
                                                  response.data.lastApproved,
                                                lastRequestedData:
                                                  response.data.lastRequested,
                                                compAuthStat:
                                                  response.data.lastRequested
                                                    .compAuthStat,
                                              });
                                              this.tog_standard5();
                                            }}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            type="button"
                                            onClick={this.tog_standard5}
                                            color="light"
                                            className="waves-effect"
                                          >
                                            No
                                          </Button>
                                        </ModalFooter>
                                      )}
                                    </Modal>
                                  </div>
                                ) : this.state
                                    .internalSettingsAuthStatForUser === 2 &&
                                  authDiff === 0 ? (
                                  <h4 style={{ textAlign: 'center' }}>
                                    The request has been reviewed by Checker
                                  </h4>
                                ) : // auth permission check for checker

                                // auth permission check for authorizer
                                this.state.internalSettingsAuthStatForUser ===
                                    3 &&
                                  this.state.lastRequestedData !== null &&
                                  (this.state.lastRequestedData
                                    ?.entryStaffID !== loggedUser ||
                                    this.state.lastRequestedData
                                      ?.lastAuthorizerID !== loggedUser) ? (
                                  <div className="d-flex justify-content-between my-5">
                                    <Button
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({
                                          modal_standard4: true,
                                        });
                                      }}
                                    >
                                      Authorize
                                    </Button>
                                    {/*
                                     * @desc modal for AUTHORIZING request for the AUTHORIZER STARTS here
                                     */}
                                    <Modal
                                      isOpen={this.state.modal_standard4}
                                      toggle={this.tog_standard4}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard4: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>

                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want Authorize this
                                            request?
                                          </Row>
                                        </Col>
                                      </ModalBody>

                                      <ModalFooter>
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="waves-effect waves-light"
                                          onClick={async (event) => {
                                            event.preventDefault();
                                            const token = getToken();
                                            let submitData = {
                                              cmpId: 18,
                                              recID:
                                                this.state.lastRequestedData
                                                  .recID,

                                              deviceInfo: this.state.deviceInfo,
                                            };
                                            await GKApi.put(
                                              `finance/internal-settings/approve`,
                                              submitData,
                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );
                                            const response = await GKApi.get(
                                              '/finance/internal-settings',
                                              {
                                                headers: {
                                                  Authorization: token,
                                                  cmpId: '18',
                                                },
                                              }
                                            );

                                            this.setState({
                                              lastApprovedData:
                                                response.data.lastApproved,
                                              lastRequestedData:
                                                response.data.lastRequested,
                                              // compAuthStat:
                                              //   response.data.lastRequested
                                              //     .compAuthStat,
                                            });
                                            this.tog_standard4();
                                          }}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          type="button"
                                          onClick={this.tog_standard4}
                                          color="light"
                                          className="waves-effect"
                                        >
                                          No
                                        </Button>
                                      </ModalFooter>
                                    </Modal>

                                    <Button
                                      type="submit"
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({
                                          modal_standard5: true,
                                        });
                                      }}
                                    >
                                      Reject
                                    </Button>
                                    {/*
                                     * @desc modal for REJECTING request for the AUTHORIZER STARTS here
                                     */}
                                    <Modal
                                      isOpen={this.state.modal_standard5}
                                      toggle={this.tog_standard5}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard5: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>

                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want to Reject this
                                            request?
                                          </Row>
                                          <Row className="mb-3 mx-2">
                                            <Input
                                              type="text"
                                              // oninput={validate(this)}
                                              value={this.state.reason}
                                              placeholder="Please specify the reason"
                                              id="gold-price-input"
                                              onChange={this.handleReasonChange}
                                            />
                                          </Row>
                                        </Col>
                                      </ModalBody>

                                      {this.state.reason === '' ? (
                                        ''
                                      ) : (
                                        <ModalFooter>
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="waves-effect waves-light"
                                            onClick={async (event) => {
                                              event.preventDefault();

                                              const token = getToken();
                                              let submitData = {
                                                cmpId: 18,

                                                recID:
                                                  this.state.lastRequestedData
                                                    .recID,

                                                deviceInfo:
                                                  this.state.deviceInfo,
                                                reason: this.state.reason,
                                              };
                                              await GKApi.put(
                                                `finance/internal-settings/reject`,
                                                submitData,
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                  },
                                                }
                                              );
                                              const response = await GKApi.get(
                                                '/finance/internal-settings',
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                    cmpId: '18',
                                                  },
                                                }
                                              );

                                              this.setState({
                                                lastApprovedData:
                                                  response.data.lastApproved,
                                                lastRequestedData:
                                                  response.data.lastRequested,
                                              });
                                              this.tog_standard5();
                                            }}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            type="button"
                                            onClick={this.tog_standard5}
                                            color="light"
                                            className="waves-effect"
                                          >
                                            No
                                          </Button>
                                        </ModalFooter>
                                      )}
                                    </Modal>
                                  </div>
                                ) : this.state
                                    .internalSettingsAuthStatForUser === 3 &&
                                  this.state.lastRequestedData === null ? (
                                  <Button
                                    type="submit"
                                    style={{
                                      width: '35%',
                                    }}
                                  >
                                    Submit
                                  </Button>
                                ) : this.state.lastRequestedData
                                    ?.entryStaffID === loggedUser ||
                                  this.state.lastRequestedData
                                    ?.lastAuthorizerID === loggedUser ? (
                                  <h4>
                                    You have already authorized this request
                                  </h4>
                                ) : (
                                  ''
                                )}
                                <Tooltip
                                  target="foo"
                                  isOpen={this.state.tooltipOpen}
                                  toggle={this.toggle}
                                >
                                  Be careful!!! Its going to update the database
                                  directly
                                </Tooltip>
                              </Row>
                              {/* submit based on component auth state, user auth state, auth state difference */}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                ) : (
                  <></>
                )}
              </Col>
            </Container>
          ) : (
            <h5>Sorry you don't have permission to view this.</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default FinanceSettings;

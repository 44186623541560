import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes/';
import AppRoute from './routes/route';

// layouts
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';
import { checkLogin, logoutUser } from './store/actions';
import { getToken, GKApi } from './components/config';
import { Auth } from 'aws-amplify';
function withMyHook(Component) {
  return function WrappedComponent(props) {
    const dispatch = useDispatch();
    return <Component {...props} dispatch={dispatch} />;
  };
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  async componentDidMount() {
    const dispatch = this.props.dispatch;
    const token = getToken();
    const currentLocation = window.location.pathname;
    Auth.currentAuthenticatedUser({
      // Optional, By default is false. If set to true,
      // this call will send a request to Cognito to get the latest user data
      bypassCache: true,
    })
      .then(async (user) => {
        // GET request using axios with async/await
        const response = await GKApi.get(`/staff/logged-in-user`, {
          headers: {
            Authorization: token,
          },
        });
  
        dispatch(checkLogin(response.data));
      })
      .catch((err) => {
       if (currentLocation !== '/signin'){ 
        window.localStorage.clear();
        this.props.dispatch(logoutUser());
        window.location.href = '/logout';}
      });
    
    // if (!token && currentLocation !== '/signin') {
    //   window.localStorage.clear();
    //   this.props.dispatch(logoutUser());
    //   window.location.href = '/logout';
    // } else {
    //   // GET request using axios with async/await
    //   const response = await GKApi.get(`/staff/logged-in-user`, {
    //     headers: {
    //       Authorization: token,
    //     },
    //   });

    //   dispatch(checkLogin(response.data));
    // }
  }

  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      // case "horizontal":
      //   layoutCls = HorizontalLayout;
      //   break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();
    const token = getToken();

    // // Store unload time in local storage
    // window.addEventListener('beforeunload', function (e) {
    //   window.localStorage.setItem('unloadTime', JSON.stringify(new Date()));
    // });

    // // Check if refresh time is greater than 1 hour and remove access-token if so
    // window.addEventListener('load', function () {
    //   const loadTime = new Date();
    //   const unloadTime = new Date(
    //     JSON.parse(window.localStorage.getItem('unloadTime'))
    //   );
    //   const refreshTime = loadTime - unloadTime;

    //   if (refreshTime > 3600000) {
    //     // window.localStorage.removeItem("access-token");
    //     window.localStorage.clear();
    //     this.props.dispatch(logoutUser());
    //     window.location.href = '/logout';
    //   }
    // });
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={token ? '' : NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
          </Switch>
        </Router>
        {/* </Beforeunload> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default withMyHook(connect(mapStateToProps, null)(App));

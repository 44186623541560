import React, { Component } from 'react';
import MaterialTable from 'material-table';

import {
  capitalizeFirstLetter,
  getToken,
  GKApi,
} from '../../../components/config';
import moment from 'moment';

class CommunicationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyData: [],
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  async componentDidMount() {
    let token = getToken();
    const response = await GKApi.get('/marketing/campaign/list?type=history', {
      headers: {
        Authorization: token,
      },
    });

    this.setState({ historyData: response.data });
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  render() {
    const columns = [
      {
        field: 'title',
        title: 'Communication Title',
      },
      {
        title: 'Campaign Timeline',
        render: (row) => (
          <div>
            {moment(row.startDate).format('Do MMM')} -
            {moment(row.endDate).format('Do MMM')}
          </div>
        ),
      },
      {
        field: 'type',
        title: 'Campaign Type',
        render: (row) => <div>{capitalizeFirstLetter(row.type)}</div>,
      },
      {
        field: 'isBanner',
        title: 'Banner',
        render: (row) => (
          <div>
            {row.isBanner === 0 ? (
              'N/A'
            ) : row.isBanner === 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        field: 'isInbox',
        title: 'Inbox',
        render: (row) => (
          <div>
            {row.isInbox === 0 ? (
              'N/A'
            ) : row.isInbox === 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        field: 'isNotification',
        title: 'Notification',
        render: (row) => (
          <div>
            {row.isNotification === 0 ? (
              'N/A'
            ) : row.isNotification === 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        field: 'docUrl',
        title: 'Document',
        render: (row) => (
          <div>
            {row.docUrl !== null ? (
              <a rel="noopener noreferrer" href={row.docUrl} target="_blank">
                <i className="mdi mdi-file-download"></i>
              </a>
            ) : (
              'N/A'
            )}
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <MaterialTable
          title="Communication History"
          columns={columns}
          data={this.state.historyData}
          // actions={[
          //   {
          //     icon: () => <i className='mdi mdi-file-download'></i>, // you can pass icon too
          //     tooltip: 'Download Document',
          //   },
          // ]}
          options={{
            cellStyle: {
              borderRight: '1px solid #eee',
              textAlign: 'center',
            },
            headerStyle: {
              whiteSpace: 'nowrap',
            },

            exportButton: { csv: true, pdf: false },
            exportFileName: 'Communication History',
            pageSize: 20,
            actionsColumnIndex: -1,
            columnsButton: true,
          }}
          localization={{
            toolbar: {
              exportCSVName: 'Export as CSV',
              
            },
          }}
        />
      </React.Fragment>
    );
  }
}

export default CommunicationHistory;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { roundToTwo } from '../../../components/config';
import moment from 'moment';

// Table data

class RefundReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '#' },
        { title: 'Finance-Dashboard', link: '#' },
        { title: 'Refund Report', link: '#' },
      ],
      visible: false,
      refundData: {},
      modal_standard: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();}
    const columns = [
      {
        field: ['SL No'],
        title: 'SL No',
      },
      {
        field: ['Transaction Reference No'],
        title: 'Transaction Reference No',
      },
      {
        field: ['Refund Request ID'],
        title: 'Refund Request ID',
      },
      {
        field: ['Bkash Transaction ID'],
        title: 'Bkash Transaction ID',
      },
      {
        field: ['Refund Request Date'],
        title: 'Refund Request Date',
        render: (row) => (
          <div>{moment(row['Refund Request Date']).format('DD/MM/YYYY')}</div>
        ),
      },

      {
        field: ['Customer Name'],
        title: 'Customer Name',
      },
      {
        field: ['MSISDN'],
        title: 'MSISDN',
      },
      {
        field: ['Bank Account Name'],
        title: 'Bank Account Name',
        render: (row) => (
          <div>
            {row['Bank Account Name'] === null
              ? 'Not available'
              : row['Bank Account Name']}
          </div>
        ),
      },
      {
        field: ['Bank Account No'],
        title: 'Bank Account No',
        render: (row) => (
          <div>
            {row['Bank Account No'] === null
              ? 'Not available'
              : row['Bank Account No']}
          </div>
        ),
      },
      {
        field: ['Bank Name'],
        title: 'Bank Name',
        render: (row) => (
          <div>
            {row['Bank Name'] === null ? 'Not available' : row['Bank Name']}
          </div>
        ),
      },
      {
        field: ['Bank Branch'],
        title: 'Bank Branch',
        render: (row) => (
          <div>
            {row['Bank Branch'] === null ? 'Not available' : row['Bank Branch']}
          </div>
        ),
      },
      {
        field: ['Bank District'],
        title: 'Bank District',
        render: (row) => (
          <div>
            {row['Bank District'] === null
              ? 'Not available'
              : row['Bank District']}
          </div>
        ),
      },
      {
        field: ['Bank Routing No'],
        title: 'Bank Routing No',
        render: (row) => (
          <div>
            {row['Bank Routing No'] === null
              ? 'Not available'
              : row['Bank Routing No']}
          </div>
        ),
      },
      {
        field: ['bKash Account Name'],
        title: 'bKash Account name',
        render: (row) => (
          <div>
            {row['bKash Account Name'] === null
              ? 'Not available'
              : row['bKash Account Name']}
          </div>
        ),
      },
      {
        field: ['bKash Number'],
        title: 'bKash Number',
        render: (row) => (
          <div>
            {row['bKash Number'] === null
              ? 'Not available'
              : row['bKash Number']}
          </div>
        ),
      },
      {
        field: ['Per Unit Price (Live Market Price)'],
        title: ' Per Unit Price (Live market Price)',
      },
      {
        field: ['Refund Gold (Gm)'],
        title: 'Refund Gold (Gm)',
        render: (row) => <div>{(row['Refund Gold (Gm)']).toFixedDown(4)}</div>,
      },
      {
        field: ['Principal Amount'],
        title: 'Principal Amount',
        render: (row) => <div>{roundToTwo(row['Principal Amount'])}</div>,
      },
      {
        field: ['Discount Amount (On Gold)'],
        title: 'Discount Amount (On Gold)',
      },
      {
        field: ['Promo Amount (On Gold)'],
        title: 'Promo Amount (On Gold)',
      },
      {
        field: ['Other Additions (On Gold)'],
        title: 'Other Additions (On Gold)',
      },

      {
        field: ['Net Amount (On Gold)'],
        title: 'Net Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Net Amount (On Gold)'])}</div>,
      },
      {
        field: ['VAT Rate (On Gold)'],
        title: 'VAT Rate (On Gold)',
      },
      {
        field: ['VAT Amount (On Gold)'],
        title: 'VAT Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['VAT Amount (On Gold)'])}</div>,
      },
      {
        field: ['Total Price Including VAT (On Gold)'],
        title: 'Total Price Including VAT (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Total Price Including VAT (On Gold)'])}</div>
        ),
      },
      {
        field: ['Refund Fee Rate'],
        title: 'Refund Fee Rate',
      },
      {
        field: ['Refund Fee (Including VAT)'],
        title: 'Refund Fee (Including VAT)',
        render: (row) => <div>{roundToTwo(row['Refund Fee (Including VAT)'])}</div>,
      },

      {
        field: ['Discount Amount (On Refund fee)'],
        title: 'Discount Amount (On Refund fee)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Refund fee)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Refund fee)'],
        title: 'Promo Amount (On Refund fee)',
        render: (row) => (
          <div>{roundToTwo(row['Promo Amount (On Refund fee)'])}</div>
        ),
      },
      {
        field: ['Other Additions (On Refund fee)'],
        title: 'Other Additions (On Refund fee)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Refund fee)'])}</div>
        ),
      },
      {
        field: ['Net Refund Fee (Including VAT)'],
        title: 'Net Refund Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Net Refund Fee (Including VAT)'])}</div>
        ),
      },
      {
        field: ['VAT Rate (On Refund fee)'],
        title: 'VAT Rate (On Refund fee)',
        render: (row) => (
          <div>{roundToTwo(row['VAT Rate (On Refund fee)'])}</div>
        ),
      },
      {
        field: ['Amount of VAT (On Refund fee)'],
        title: 'Amount of VAT (On Refund fee)',
        render: (row) => (
          <div>{roundToTwo(row['Amount of VAT (On Refund fee)'])}</div>
        ),
      },
      {
        field: ['Total Refund Fee (Excluding VAT)'],
        title: 'Total Refund Fee (Excluding VAT)',
        render: (row) => (
          <div>
            {roundToTwo(row['Total Refund Fee (Excluding VAT)'])}
          </div>
        ),
      },
      {
        field: ['Net Refund'],
        title: 'Net Refund',
        render: (row) => <div>{roundToTwo(row['Net Refund'])}</div>,
      },
      {
        field: ['Refund Medium'],
        title: 'Refund Medium',
      },
      
      {
        field: ['Refund Status'],
        title: 'Refund Status',
      },
    ];

    const summaryColumns = [
      {
        field: ['Date'],
        title: 'Date',
      },
      {
        field: ['Number of Refund Requests'],
        title: 'Number of Refund Request',
      },
      {
        field: ['Pending'],
        title: 'Pending',
      },
      {
        field: ['Refunded'],
        title: 'Refunded',
      },
      {
        field: ['No of Bkash Transaction'],
        title: 'No of Bkash Transaction',
      },
      {
        field: ['No of Bank Transaction'],
        title: 'No of Bank Transaction',
      },
      {
        field: ['Refund Gold (Gm)'],
        title: 'Refund Gold (Gm)',
        render: (row) => <div>{(row['Refund Gold (Gm)']).toFixedDown(4)}</div>,
      },
      {
        field: ['Principal Amount'],
        title: 'Principal Amount',
        render: (row) => <div>{roundToTwo(row['Principal Amount'])}</div>,
      },
      {
        field: ['Discount Amount (On Gold)'],
        title: 'Discount Amount (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Gold)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Gold)'],
        title: 'Promo Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Promo Amount (On Gold)'])}</div>,
      },
      {
        field: ['Other Additions (On Gold)'],
        title: 'Other Additions (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Gold)'])}</div>
        ),
      },
      {
        field: ['Net Amount (On Gold)'],
        title: 'Net Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Net Amount (On Gold)'])}</div>,
      },
      {
        field: ['VAT Amount (On Gold)'],
        title: 'VAT Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['VAT Amount (On Gold)'])}</div>,
      },
      {
        field: ['Total Price Including VAT (On Gold)'],
        title: 'Total Price Including VAT (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Total Price Including VAT (On Gold)'])}</div>
        ),
      },
      {
        field: ['Refund Fee (Including VAT)'],
        title: 'Refund Fee (Including VAT)',
        render: (row) => <div>{roundToTwo(row['Refund Fee (Including VAT)'])}</div>,
      },
      
      {
        field: ['Discount Amount (On Refund fee)'],
        title: 'Discount Amount (On Refund fee)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Refund fee)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Refund fee)'],
        title: 'Promo Amount (On Refund fee)',
        render: (row) => (
          <div>{roundToTwo(row['Promo Amount (On Refund fee)'])}</div>
        ),
      },
      {
        field: ['Other Additions (On Refund fee)'],
        title: 'Other Additions (On Refund fee)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Refund fee)'])}</div>
        ),
      },
      {
        field: ['Net Refund Fee (Including VAT)'],
        title: 'Net Refund Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Net Refund Fee (Including VAT)'])}</div>
        ),
      },
      {
        field: ['Amount of VAT (On Refund fee)'],
        title: 'Amount of VAT (On Refund fee)',
        render: (row) => (
          <div>{roundToTwo(row['Amount of VAT (On Refund fee)'])}</div>
        ),
      },
      {
        field: ['Total Refund Fee (Excluding VAT)'],
        title: 'Total Refund Fee (Excluding VAT)',
        render: (row) => (
          <div>
            {roundToTwo(row['Total Refund Fee (Excluding VAT)'])}
          </div>
        ),
      },
      {
        field: ['Net Refund'],
        title: 'Net Refund',
        render: (row) => <div>{roundToTwo(row['Net Refund'])}</div>,
      },
    ];

    const downloadExcel = () => {
      const newData = this.props.location.state.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Refund Report');
      
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      
      this.props.location.state.summary === false?
        XLSX.writeFile(workBook, 'Refund Report.xlsx'):
        XLSX.writeFile(workBook, 'Refund Report Summarized.xlsx')
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Finance"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <MaterialTable
              title={
                this.props.location.state.summary === false
                  ? 'Refund Report'
                  : 'Refund Report Summarized'
              }
              columns={
                this.props.location.state.summary === false
                  ? columns
                  : summaryColumns
              }
              data={this.props.location.state.data}
              actions={[
                {
                  icon: () => <Button>Export All</Button>, // you can pass icon too
                  tooltip: 'Export to Excel',
                  onClick: () => downloadExcel(),
                  isFreeAction: true,
                },
                // {
                //   icon: () => <Button>Advance Search</Button>, // you can pass icon too
                //   tooltip: 'Export to Excel',
                //   onClick: () => {
                //     this.setState({ modal_standard: true });
                //   },
                //   isFreeAction: true,
                // },
              ]}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                exportButton: { csv: true, pdf: true },
                exportFileName:
                  this.props.location.state.summary === false
                    ? 'Refund Report'
                    : 'Refund Report Summarized',
                pageSize: 20,
                columnsButton: true,
              }}
              localization={{
                toolbar: {
                  exportCSVName: 'Export as CSV',
                  exportPDFName: 'Export as PDF',
                },
              }}
            />
            <Modal
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_standard: false })}
              >
                User Edit
              </ModalHeader>
              <ModalBody>
                {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Name
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.name}
                      id="example-name-input"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Position
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.position}
                      id="example-position-input"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Salary
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.salary}
                      id="example-salary-input"
                    />
                  </Col>
                </Row>
                <Button className="float-end">Update</Button>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={this.tog_standard}
                  color="light"
                  className="waves-effect"
                >
                  Close
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                >
                  Save changes
                </Button>
              </ModalFooter>
            </Modal>
            ;
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RefundReport;

import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Col,
  Button,
} from 'reactstrap';
import Switch from '@mui/material/Switch';
import DatePicker from '../../../components/Common/DatePicker';
import {
  capitalizeFirstLetter,
  getToken,
  GKApi,
} from '../../../components/config';
import { withRouter } from 'react-router-dom';

const FinanceReport = (props) => {
  const [reportMenu, setReportMenu] = useState(false);
  const [report, setReport] = useState('refund');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [dailySummaryChecked, setDailySummaryChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  /*handle function for the parameter changes*/

  const handleDailySummaryCheckedChange = (event) => {
    setDailySummaryChecked(event.target.checked);
  };

  const handleSelectedStartDateChange = (event) => {
    setSelectedStartDate(event);
  };

  const handleSelectedEndDateChange = (event) => {
    setSelectedEndDate(event);
  };

  //On Click function
  const onClick = async (event) => {
    event.preventDefault();
    const token = getToken();
    setLoading(true);
    //get api call with needed parameters
    let submitData = await GKApi.get(
      `/finance/report?reportType=${report}&summary=${dailySummaryChecked}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`,
      {
        headers: {
          Authorization: token,
          cmpId: 10,
        },
      }
    );
    if (submitData) {
      setLoading(false);
    }

    /*report based on the report state parameters,
  and pushing the route to the specific report */

    props.history.push({
      pathname: `/${report}-report`,
      state: submitData.data,
    });
  };
  return (
    <>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Finance Reports</h4>
          <div className="d-grid gap-3">
            <div>
              <div className="d-flex align-items-center">
                <Col xs={6}>
                  <h5>Select Report Type</h5>
                </Col>
                <Col xs={6}>
                  <Dropdown
                    isOpen={reportMenu}
                    toggle={(event) => setReportMenu(!reportMenu)}
                  >
                    <DropdownToggle className="px-5 py-2" color="primary">
                      {capitalizeFirstLetter(report)} report{' '}
                      <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={(event) => {
                          setReport('refund');
                        }}
                      >
                        Refund Report
                      </DropdownItem>

                      <DropdownItem onClick={(event) => setReport('sales')}>
                        Sales Report
                      </DropdownItem>

                      <DropdownItem onClick={(event) => setReport('gift')}>
                        Gift Report
                      </DropdownItem>

                      <DropdownItem onClick={(event) => setReport('collect')}>
                        Collect Report
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <Col xs={6}>
                {report ? (
                  <h5>
                    Select the Date range for {capitalizeFirstLetter(report)}{' '}
                    report
                  </h5>
                ) : (
                  ''
                )}
              </Col>
              <Col xs={6} className="d-flex gap-3">
                <div
                  style={{
                    border: '1px solid',
                    borderRadius: '5px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingRight: '4px',
                    paddingLeft: '4px',
                  }}
                  className="d-flex gap-3 align-content-center"
                >
                  <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                    Start Date
                  </p>
                  <DatePicker
                    selectedDate={selectedStartDate}
                    handleSelectedDateChange={handleSelectedStartDateChange}
                  />
                </div>
                <div
                  style={{
                    border: '1px solid',
                    borderRadius: '5px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingRight: '5px',
                    paddingLeft: '5px',
                  }}
                  className="d-flex d-grid gap-3"
                >
                  <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                    End Date
                  </p>
                  <DatePicker
                    selectedDate={selectedEndDate}
                    handleSelectedDateChange={handleSelectedEndDateChange}
                    className="mx-3"
                  />
                </div>
              </Col>
            </div>
            <div className="d-flex align-items-center">
              <Col xs={6}>
                <h5>Daily Summary</h5>
              </Col>
              <Col xs={6} className="d-flex  gap-3">
                <Switch
                  checked={dailySummaryChecked}
                  onChange={handleDailySummaryCheckedChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Col>
            </div>
            {/* <div className="d-flex align-items-center">
              <Col xs={6}>
                <h5>Detailed Report</h5>
              </Col>
              <Col xs={6} className="d-flex  gap-3">
                <Switch
                  checked={detailsChecked}
                  onChange={handleDetailsCheckedChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Col>
            </div> */}
            <div className="d-flex justify-content-center">
              {selectedStartDate && selectedEndDate ? (
                <Button
                  onClick={onClick}
                  style={{
                    width: '50%',
                  }}
                >
                  {loading && (
                    <span className="spinner-grow spinner-grow-sm"></span>
                  )}
                  {loading ? 'Generating Report' : 'View Report'}
                </Button>
              ) : (
                'Please choose the date parameter to generate report'
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default withRouter(FinanceReport);

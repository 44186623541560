import React from 'react';
// import AllSchedule from './AllSchedule';
import { Col, Row } from 'reactstrap';
import AllSchedule from './AllSchedule';

const CampaignManager = () => {
  return (
    <React.Fragment>
        <Row>
          <Col xl={12}>
            <AllSchedule/>
          </Col>
        </Row>
    </React.Fragment>
  );
};

export default CampaignManager;

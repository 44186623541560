import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { getToken, GKApi } from '../../../components/config';
import moment from 'moment';

// Table data

class CancelledNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canceledGenNotificationList: [],
      canceledCampNotificationList: [],
    };
  }

  async componentDidMount() {
    const token = getToken();
    const cancelledNotification = await GKApi.get(
      '/marketing/campaign/stop-list?type=notification',
      {
        headers: {
          Authorization: token,
        },
      }
    );

    let cancelledGenNotification = [];
    let cancelledCampNotification = [];
    cancelledNotification.data.forEach((al) => {
      if (al.type === 'GENERAL') {
        cancelledGenNotification.push(al);
      } else {
        cancelledCampNotification.push(al);
      }
    });
    this.setState({
      canceledGenNotificationList: cancelledGenNotification,
      canceledCampNotificationList: cancelledCampNotification,
    });
  }

  render() {
    const genNotificationColumns = [
      {
        field: 'communicationTitle',
        title: 'Communication Title',
      },
      {
        field: 'title',
        title: 'Push Notification Title',
      },
      {
        field: 'message',
        title: 'Message',
      },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },

      {
        field: 'status',
        title: 'Status',
      },
    ];

    const campNotificationColumns = [
      {
        field: 'communicationTitle',
        title: 'Communication Title',
      },
      {
        field: 'title',
        title: 'Push Notification Title',
      },
      {
        field: 'message',
        title: 'Message',
      },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },

      {
        field: 'status',
        title: 'Status',
      },
    ];

    return (
      <React.Fragment>
        <MaterialTable
          title="General Communication"
          columns={genNotificationColumns}
          data={this.state.canceledGenNotificationList}
          options={{
            cellStyle: {
              borderRight: '1px solid #eee',
              textAlign: 'center',
              whiteSpace: 'nowrap',
            },
            headerStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },

            actionsColumnIndex: -1,
            exportFileName: 'User',
            pageSize: 5,
            columnsButton: true,
          }}
          localization={{
            toolbar: {},
          }}
        />
        <MaterialTable
          title="Campaign Communication"
          columns={campNotificationColumns}
          data={this.state.canceledCampNotificationList}
          options={{
            cellStyle: {
              borderRight: '1px solid #eee',
              textAlign: 'center',
              whiteSpace: 'nowrap',
            },
            headerStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },

            actionsColumnIndex: -1,
            exportFileName: 'User',
            pageSize: 5,
            columnsButton: true,
          }}
          localization={{
            toolbar: {},
          }}
        />
      </React.Fragment>
    );
  }
}

export default CancelledNotification;

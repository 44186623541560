import React, { Component } from 'react';
import { Col, Row, Label, Input, Button, FormGroup, Form } from 'reactstrap';
import { Auth } from 'aws-amplify';
import {
  removeAccessToken,
  removeGroupId,
  removeGroupName,
} from '../../components/config';
import { logoutUser } from '../../store/actions';
import { useDispatch } from 'react-redux';

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const dispatch = useDispatch();
    return <Component {...props} dispatch={dispatch} />;
  };
}
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Admin', link: '/' },
        { title: 'User Profile', link: '/user-profile' },
      ],
      user: null,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      cognitoError: '',
      loading: false,
    };
  }

  handleOldPasswordChange = (event) => {
    this.setState({ ...this.state, oldPassword: event.target.value });
  };

  handleNewPasswordChange = (event) => {
    this.setState({ ...this.state, newPassword: event.target.value });
  };

  handleConfirmNewPasswordChange = (event) => {
    this.setState({ ...this.state, confirmNewPassword: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      this.setState({ loading: true });
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        this.state.oldPassword,
        this.state.newPassword
      );

      removeAccessToken();
      removeGroupId();
      removeGroupName();
      this.props.dispatch(logoutUser());
      this.setState({
        cognitoError:
          'Password change successful!! Please logout and login again to continue',
        loading: false,
      });
      // this.props.history.push('/logout')
      window.location.href = '/logout';
    } catch (err) {
      this.setState({ loading: false });
      this.setState({
        cognitoError: 'Error!! Please check your old and new password!!',
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Col>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Row className="mb-3">
                <Label
                  htmlFor="old-password-input"
                  className="col-md-3 col-form-label"
                >
                  Old Password
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    value={this.state.oldPassword}
                    placeholder="Please input old password"
                    id="old-password-input"
                    onChange={this.handleOldPasswordChange}
                  />
                </Col>
              </Row>
              <Row className="mb-1">
                <Label
                  htmlFor="new-password-input"
                  className="col-md-3 col-form-label"
                >
                  New Password
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    value={this.state.newPassword}
                    placeholder="Please input new password"
                    id="new-password-input"
                    onChange={this.handleNewPasswordChange}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-:?.><()/|~]).{8,30}$"
                    title="Must contain at least one number and one uppercase and one lowercase letter and one special character, and at least 8 or more characters"
                    required
                  />
                  <p className="font-size-12 pt-4">
                    * Password must contain at least one number and one
                    uppercase and one lowercase letter and one special
                    character, and at least 8 or more characters
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="email-input"
                  className="col-md-3 col-form-label"
                >
                  Confirm new Password
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    value={this.state.confirmNewPassword}
                    placeholder="Please confirm new password"
                    id="confirm-new-password-input"
                    onChange={this.handleConfirmNewPasswordChange}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-:?.><()/|~]).{8,30}$"
                    title="Must contain at least one number and one uppercase and one lowercase letter and one special character, and at least 8 or more characters"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6
                    className="py-1"
                    style={{
                      color:
                        this.state.cognitoError ===
                        'Error!! Please check your old and new password!!'
                          ? '#ff0000'
                          : '#00FF00',
                    }}
                  >
                    {this.state.cognitoError}
                  </h6>
                </Col>
              </Row>
              <Button
                type="submit"
                color="primary"
                className="waves-effect waves-light"
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                {this.state.loading === true
                  ? 'Password Updating!!'
                  : 'Update password'}
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </React.Fragment>
    );
  }
}

export default withMyHook(ChangePassword);

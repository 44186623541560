import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0B2445',
    },
  },
});

const TimePickerComponent = (props) => {
  let { selectedDate, handleSelectedDateChange } = props;

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          value={selectedDate}
          defaultValue={selectedDate ? selectedDate : ''}
          onChange={(newValue) => {
            handleSelectedDateChange(newValue);
          }}
        //   inputFormat="dd/MM/yyyy"
        //   placeHolder="dd/MM/yyyy"
        minDate={new Date()}
          InputProps={{
            disableUnderline: true,
          }}
          renderInput={(params) => (
            <TextField {...params} className="w-11/12" variant="standard" />
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default TimePickerComponent;

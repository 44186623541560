import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Input,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  
  Nav,
  CardText,
  Button,
  Label,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import {
  getToken,
  getUser,
  GKApi,
} from '../../../components/config';
import moment from 'moment';
import DatePickerComponent from '../../../components/Common/DatePicker';
import { deviceInfo } from '../../../helpers/device_info_helper';

class EditDraftCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        // { title: 'Forms', link: '#' },
        // { title: 'Create Campaign', link: '#' },
      ],
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 25,
      isOpen: false,
      radio: 'App Banner',
      radio1Campaign: 1,
      radio1General: 'All Users',
      selectedFiles: [],
      selectedFilesBanner: [],
      campaignDocId: '',
      campaignSelectedStartDate: null,
      campaignSelectedEndDate: null,
      campaignSelectedStartTime: '',
      campaignSelectedEndTime: '',
      isOpen1: false,
      menu1: 'General',
      isOpen2: false,
      presetId: 'Select preset Audience',
      activeTabJustify: '5',
      offerPercentage: 0,
      campaignBudget: 0,
      modal_standard: false,
      campaignTitle: '',
      modalityValues: [],
      maxTrxCountCampaignDuration: 0,
      maxTrxCountPerDay: 0,
      maxTrxAmountCampaignDuration: 0,
      maxTrxAmountPerDay: 0,
      singleTrxMaxAmountCap: 0,
      ticketSize: 0,
      totalTrxAmount: 0,
      totalTrxCount: 0,
      newCustomerAcquisition: 0,
      activeCustomerPercentage: 0,
      ticketSizeKPI: 0,
      campaignAppBannerUrl: '',
      campaignAppBannerTargetLink: '',
      campaignPushNotificationTitle: '',
      campaignPushNotificationDescription: '',
      campaignPushNotificationTargetLink: '',
      campaignInboxTitle: '',
      campaignInboxDescription: '',
      campaignInboxTargetLink: '',
      newKPIData: [{ kpiTitle: '', kpiValue: 0 }],
      deviceInfo: {},
      communicationType: [],
      audienceType: [],
      audType: '',
      audTypeCode: 1,
      commCode: 1,
      campaignBannerLinkTypeMenu: false,
      campaignBannerLinkType: 'Select Link Type',
      campaignBannerInternalLinkTypeMenu: false,
      bannerInternalLinkList: [],
      campaignPushNotificationLinkTypeMenu: false,
      campaignPushNotificationLinkType: 'Select Link Type',
      campaignInboxLinkTypeMenu: false,
      campaignInboxLinkType: 'Select Link Type',
      campaignOfferTypeMenu: false,
      campaignOfferType: 'Select Offer Type',
      downloadAudienceData: [],
      presetData: [],
      presetName: 'Select preset',
      modalityType: [],
      modalityCode: 1,
      campaignType: [],
      selectedCampaignTypeCode: 0,
      selectedCampaignType: 'Select Campaign Type',
      promoCode: '',
      campaignBannerS3Key:'',
      disableModality1: false,
      disableModality2: false,
      disableModality3: false,
      disableModality4: false,
      disableModality5: false,
      disableModality6: false,
    };
    // resetState = () => {
    //   this.setState(this.menu1());
    // };
    this.toggleTab.bind(this);
    this.toggleTabProgress.bind(this);
  }

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();

    this.setState({
      campaignTitle: this.props.campaignDraftData.title,
      campaignSelectedStartDate: moment(this.props.campaignDraftData.startDate).format('MM-DD-YYYY'),
      campaignSelectedEndDate: moment(this.props.campaignDraftData.endDate).format('MM-DD-YYYY'),
      campaignSelectedStartTime: moment(this.props.campaignDraftData.startDate).format('HH:mm:ss'),
      campaignSelectedEndTime: moment(this.props.campaignDraftData.endDate).format('HH:mm:ss'),
      selectedCampaignType: this.props.campaignDraftData.campaignType,
      selectedCampaignTypeCode:
        this.props.campaignDraftData.campaignTypeCode ,
      campaignPushNotificationTitle:
        this.props.campaignDraftData.notificationTitle,
      campaignPushNotificationDescription:
        this.props.campaignDraftData.notificationMsg,
      promoCode:this.props.campaignDraftData.promoCode ? this.props.campaignDraftData.promoCode:'',
      // selectedFilesBanner:this.props.campaignDraftData.bannerUrl
      campaignBannerLinkType: this.props.campaignDraftData.bannerLinkType,
      campaignAppBannerTargetLink: this.props.campaignDraftData.bannerLink,
      audTypeCode:this.props.campaignDraftData.audienceType,
      radio1Campaign:
        this.props.campaignDraftData.audienceType === 1
          ? 'All Users'
          : this.props.campaignDraftData.audienceType === 2
          ? 'All New Users'
          : 'Custom Users',
      presetId: this.props.campaignDraftData.customerPresetId,
      campaignInboxDescription: this.props.campaignDraftData.inboxMsg,
      campaignOfferType: this.props.campaignDraftData.offerType,
      offerPercentage: this.props.campaignDraftData.offerValue,
      campaignBudget: this.props.campaignDraftData.budget,
    });

    this.props.campaignDraftData.kpi.forEach((item) => {
      item.title === 'Transaction Count' &&
        this.setState({
          totalTrxCount: parseInt(item.value),
        });
      item.title === 'Transaction Amount' &&
        this.setState({
          totalTrxAmount: parseInt(item.value),
        });
      item.title === 'New Customer Acquisiton' &&
        this.setState({
          newCustomerAcquisition: parseInt(item.value),
        });
      item.title === 'Active Customer Percentage' &&
        this.setState({
          activeCustomerPercentage: parseInt(item.value),
        });
      item.title === 'Ticket Size' &&
        this.setState({
          ticketSizeKPI: parseInt(item.value),
        });
    });

    this.props.campaignDraftData.modality.forEach((item) => {
      
      item.id === 1 &&
        this.setState({
          ticketSize: parseInt(item.value),
        });
      item.id === 2 &&
        this.setState({
          singleTrxMaxAmountCap: parseInt(item.value),
        });
      item.id === 3 &&
        this.setState({
          maxTrxAmountCampaignDuration: parseInt(item.value),
        });
      item.id === 4 &&
        this.setState({
          maxTrxAmountPerDay: parseInt(item.value),
        });
      item.id === 5 &&
        this.setState({
          maxTrxCountCampaignDuration: parseInt(item.value),
        });
      item.id === 6 &&
        this.setState({
          maxTrxCountPerDay: parseInt(item.value),
        });
      
    });

    if (this.props.campaignDraftData.docId) {
      this.setState({
        campaignDocId: this.props.campaignDraftData.docId,
      });
    }

    if (this.props.campaignDraftData.bannerUrl) {
      this.setState({
        campaignAppBannerUrl: this.props.campaignDraftData.bannerUrl,
        campaignBannerS3Key: this.props.campaignDraftData.bannerS3key
      });
    }

    const getAudienceData = await GKApi.get(
      `/marketing/download-audience?id=${this.props.campaignDraftData.customerPresetId}`,

      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (getAudienceData.data) {
      this.setState({
        presetData: JSON.parse(getAudienceData.data.jsonData),
      });
    }

    const campaignType = await GKApi.get('/marketing/campaign/type', {
      headers: {
        Authorization: token,
      },
    });

    this.setState({
      campaignType: campaignType.data,
    });

    const audType = await GKApi.get('/marketing/general-com/audience-type', {
      headers: {
        Authorization: token,
      },
    });
    this.setState({
      audienceType: audType.data,
    });

    const getDownloadAudience = await GKApi.get(
      '/marketing/download-audience',
      {
        headers: {
          Authorization: token,
        },
      }
    );
    this.setState({
      downloadAudienceData: getDownloadAudience.data,
    });

    const getModalityType = await GKApi.get('/marketing/modality/type', {
      headers: {
        Authorization: token,
      },
    });
    let tempModalityType = getModalityType.data.map((item) => {
      return { ...item, show: true };
    });
    this.setState({
      modalityType: tempModalityType,
    });

    //internal link api call

    const internalLinkList = await GKApi.get('/marketing/banner/target-link', {
      headers: {
        Authorization: token,
      },
    });
    this.setState({ bannerInternalLinkList: internalLinkList.data.data });

    const res = deviceInfo();
    this.setState({ deviceInfo: res }, () => {});
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTabProgress: tab,
        });

        if (tab === 1) {
          this.setState({ progressValue: 25 });
        }
        if (tab === 2) {
          this.setState({ progressValue: 50 });
        }
        if (tab === 3) {
          this.setState({ progressValue: 75 });
        }
        if (tab === 4) {
          this.setState({ progressValue: 100 });
        }
      }
    }
  }

  tog_standard = () => {
    let temp = [...this.state.modalityType];
    this.state.modalityValues.forEach((item) => {
      for (let i = 0; i < temp.length; i++) {
        if (item === temp[i].title) {
          temp[i].show = false;
        }
      }
    });
    this.setState({
      modalityType: temp,
    });
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
      reason: '',
    }));
    this.removeBodyCss();
  };

  removeBodyCss = () => {
    document.body.classList.add('no_padding');
  };

  handleCampaignAppBannerTargetLinkChange = (e) => {
    this.setState({ campaignAppBannerTargetLink: e.target.value });
  };
  handleCampaignPushNotificationTitleChange = (e) => {
    this.setState({ campaignPushNotificationTitle: e.target.value });
  };
  handleCampaignPushNotificationDescriptionChange = (e) => {
    this.setState({ campaignPushNotificationDescription: e.target.value });
  };
  handleCampaignPushNotificationTargetLinkChange = (e) => {
    this.setState({ campaignPushNotificationTargetLink: e.target.value });
  };
  handleCampaignInboxTitleChange = (e) => {
    this.setState({ campaignInboxTitle: e.target.value });
  };
  handleCampaignInboxDescriptionChange = (e) => {
    this.setState({ campaignInboxDescription: e.target.value });
  };
  handleCampaignInboxTargetLinkChange = (e) => {
    this.setState({ campaignInboxTargetLink: e.target.value });
  };

  handleCampaignTitleChange = (e) => {
    this.setState({ campaignTitle: e.target.value });
  };

  handlePromoCodeChange = (e) => {
    this.setState({ promoCode: e.target.value.toUpperCase() });
  };

  handleOfferPercentageChange = (e) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (e.target.value === '' || re.test(e.target.value)) &&
      parseFloat(e.target.value) <= 100
    ) {
      this.setState({ offerPercentage: e.target.value });
    }
  };

  handleCampaignBudgetChange = (e) => {
    this.setState({ campaignBudget: e.target.value });
  };

  handleCampaignSelectedStartDateChange = (e) => {
    this.setState({ campaignSelectedStartDate: e });
  };

  handleCampaignSelectedStartTimeChange = (e) => {
    this.setState({ campaignSelectedStartTime: e.target.value });
  };

  handleCampaignSelectedEndDateChange = (e) => {
    this.setState({ campaignSelectedEndDate: e });
  };

  handleCampaignSelectedEndTimeChange = (e) => {
    this.setState({ campaignSelectedEndTime: e.target.value });
  };

  handleMaxTrxCountCampaignDurationChange = (e) => {
    this.setState({ maxTrxCountCampaignDuration: e.target.value });
  };

  handleMaxTrxCountPerDayChange = (e) => {
    this.setState({ maxTrxCountPerDay: e.target.value });
  };

  handleMaxTrxAmountCampaignDurationChange = (e) => {
    this.setState({ maxTrxAmountCampaignDuration: e.target.value });
  };

  handleMaxTrxAmountPerDayChange = (e) => {
    this.setState({ maxTrxAmountPerDay: e.target.value });
  };
  handleSingleTrxMaxAmountCap = (e) => {
    this.setState({ singleTrxMaxAmountCap: e.target.value });
  };
  

  handleTicketSizeChange = (e) => {
    this.setState({ ticketSize: e.target.value });
  };

  handleTotalTrxCountChange = (e) => {
    this.setState({ totalTrxCount: e.target.value });
  };
  handleTotalTrxAmountChange = (e) => {
    this.setState({ totalTrxAmount: e.target.value });
  };
  handleNewCustomerAcquisitionChange = (e) => {
    this.setState({ newCustomerAcquisition: e.target.value });
  };
  handleActiveCustomerPercentageChange = (e) => {
    this.setState({ activeCustomerPercentage: e.target.value });
  };
  handleTicketSizeKPIChange = (e) => {
    this.setState({ ticketSizeKPI: e.target.value });
  };

  /*
   * @desc checkbox handlers
   */
  handleInputChange = (event) => {
    // let temAudValue=new Set();
    const target = event.target;
    var value = target.value;
    if (target.checked) {
      this.state.modalityValues.push(value);
      // temAudValue.add(value);
    } else {
      this.state.modalityValues.splice(value, 1);
    }

    //  let audValue= [...temAudValue]
    // this.setState({modalityValues:audValue})
  };

  //   file upload
  handleAcceptedFiles = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };

  handleAcceptedFilesBanner = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFilesBanner: files });
  };
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  //   for radio button
  onRadioChange = (e) => {
    this.setState({
      radio: e.currentTarget.value,
    });
  };
  onRadioChange1Campaign = (e) => {
    this.setState({
      radio1Campaign: e.currentTarget.value,
    });
  };

  toggleCustomJustified(tab) {
    if (this.state.activeTabJustify !== tab) {
      this.setState({
        activeTabJustify: tab,
      });
    }
  }

  uploadDocCampaign = async () => {
    const token = getToken(); //get token from local storage
    const response = await GKApi.post(
      '/marketing/proposal-doc',
      {
        staffId: getUser(),
        fileName:
          'proposal.' + this.state.selectedFiles[0].name.split('.').pop(),
        campaignTitle: this.state.campaignTitle
          ? this.state.campaignTitle
          : 'Campaign',
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // this.setState({ campaignDocId: response.data.docId });
    // eslint-disable-next-line
    this.state.campaignDocId = response.data.docId;
    await axios.put(
      response.data.url,
      this.state.selectedFiles[0]
    );
  };

  uploadBannerCampaign = async () => {
    const token = getToken(); //get token from local storage
    const response = await GKApi.post(
      '/marketing/banner',
      {
        staffId: getUser(),
        fileName:
          'banner.' + this.state.selectedFilesBanner[0].name.split('.').pop(),
        campaignTitle: this.state.campaignTitle
          ? this.state.campaignTitle
          : 'Campaign',
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // this.setState({ campaignAppBannerUrl: response.data.key });
    // eslint-disable-next-line
    this.state.campaignAppBannerUrl = response.data.key;
    await axios.put(
      response.data.url,
      this.state.selectedFilesBanner[0]
    );
  };

  handleCampaignCommunicationSubmit = async () => {
    // let staffId = getUser();
    const token = getToken(); //get token from local storage
    // upload banner
    if (this.state.selectedFilesBanner.length > 0) {
      await this.uploadBannerCampaign();
    }
    // upload doc
    if (this.state.selectedFiles.length > 0) {
      await this.uploadDocCampaign();
    }

    const submitData = {
      isDraft: 0,
      campaignTitle: this.state.campaignTitle,
      draftId: this.props.campaignDraftData.id,
      deviceInfo: {
        ip: this.state.deviceInfo.IPv4,
      },
      startDate: this.state.campaignSelectedStartDate
        ? moment(this.state.campaignSelectedStartDate).format(
            'DD-MM-YYYY '
          )+this.state.campaignSelectedStartTime
        : null,
      endDate: this.state.campaignSelectedEndDate
        ? moment(this.state.campaignSelectedEndDate).format(
            'DD-MM-YYYY '
          )+this.state.campaignSelectedEndTime
        : null,
      comType: this.state.selectedCampaignTypeCode,
      promoCode:
        this.state.selectedCampaignTypeCode === 2 ||
        this.state.selectedCampaignTypeCode === 4 ||this.state.selectedCampaignTypeCode === 6
          ? this.state.promoCode
          : null,
      docId: this.state.campaignDocId === '' ? null : this.state.campaignDocId,
      notificationTitle:
        this.state.campaignPushNotificationTitle === ''
          ? null
          : this.state.campaignPushNotificationTitle,
      notificationMsg:
        this.state.campaignPushNotificationDescription === ''
          ? null
          : this.state.campaignPushNotificationDescription,
      notificationLink:
        this.state.campaignPushNotificationTargetLink === ''
          ? null
          : this.state.campaignPushNotificationTargetLink,
      notificationLinkType:
        this.state.campaignPushNotificationLinkType === 'Select Link Type'
          ? null
          : this.state.campaignPushNotificationLinkType,
      bannerUrl:
      this.state.selectedFilesBanner.length > 0
      ? this.state.campaignAppBannerUrl === ''
        ? null
        : this.state.campaignAppBannerUrl
      : this.state.campaignAppBannerUrl === ''
      ? null
      : this.state.campaignBannerS3Key,
      bannerLink:
        this.state.campaignAppBannerTargetLink === ''
          ? null
          : this.state.campaignAppBannerTargetLink,
      bannerLinkType:
        this.state.campaignBannerLinkType === 'Select Link Type'
          ? null
          : this.state.campaignBannerLinkType,
      audienceType: this.state.audTypeCode,
      customerPresetId:
        this.state.presetId === 'Select preset Audience'
          ? null
          : this.state.presetId,
      inboxTitle:
        this.state.campaignInboxTitle === ''
          ? null
          : this.state.campaignInboxTitle,
      inboxMsg:
        this.state.campaignInboxDescription === ''
          ? null
          : this.state.campaignInboxDescription,
      isBannner:
        this.state.selectedFilesBanner.length > 0 ||
        this.state.campaignAppBannerUrl
          ? 1
          : 0,
      isNotification: this.state.campaignPushNotificationDescription ? 1 : 0,
      isInbox: this.state.campaignInboxDescription ? 1 : 0,
      budget: this.state.campaignBudget,
      offerType:
        this.state.campaignOfferType === 'Select Offer Type'
          ? null
          : this.state.campaignOfferType,
      offerValue: this.state.offerPercentage,
      kpi: [
        this.state.totalTrxCount !== 0 && {
          id: 1,
          value: this.state.totalTrxCount,
        },
        this.state.totalTrxAmount !== 0 && {
          id: 2,
          value: this.state.totalTrxAmount,
        },
        this.state.newCustomerAcquisition !== 0 && {
          id: 3,
          value: this.state.newCustomerAcquisition,
        },
        this.state.activeCustomerPercentage !== 0 && {
          id: 4,
          value: this.state.activeCustomerPercentage,
        },
        this.state.ticketSizeKPI !== 0 && {
          id: 5,
          value: this.state.ticketSizeKPI,
        },
      ],
      modality: [
        this.state.ticketSize !== 0 && {
          id: 1,
          value: this.state.ticketSize,
        },
        this.state.singleTrxMaxAmountCap !== 0 && {
          id: 2,
          value: this.state.singleTrxMaxAmountCap,
        },
        this.state.maxTrxAmountCampaignDuration !== 0 && {
          id: 3,
          value: this.state.maxTrxAmountCampaignDuration,
        },
        this.state.maxTrxAmountPerDay !== 0 && {
          id: 4,
          value: this.state.maxTrxAmountPerDay,
        },
        this.state.maxTrxCountCampaignDuration !== 0 && {
          id: 5,
          value: this.state.maxTrxCountCampaignDuration,
        },
        this.state.maxTrxCountPerDay !== 0 && {
          id: 6,
          value: this.state.maxTrxCountPerDay,
        },
      ],
    };
    await GKApi.put('/marketing/campaign', submitData, {
      headers: {
        Authorization: token,
      },
    });
    await this.props.fetchCampaign()
  };

  handleCampaignCommunicationDraftSave = async () => {
    const token = getToken(); //get token from local storage

    // upload banner
    if (this.state.selectedFilesBanner.length > 0) {
      await this.uploadBannerCampaign();
    }
    // upload doc
    if (this.state.selectedFiles.length > 0) {
      await this.uploadDocCampaign();
    }

    const submitData = {
      isDraft: 1,
      campaignTitle: this.state.campaignTitle,
      draftId: this.props.campaignDraftData.id,
      deviceInfo: {
        ip: this.state.deviceInfo.IPv4,
      },
      startDate: this.state.campaignSelectedStartDate
        ? moment(this.state.campaignSelectedStartDate).format(
            'DD-MM-YYYY '
          )+this.state.campaignSelectedStartTime
        : null,
      endDate: this.state.campaignSelectedEndDate
        ? moment(this.state.campaignSelectedEndDate).format(
            'DD-MM-YYYY '
          )+this.state.campaignSelectedEndTime
        : null,
      comType: this.state.selectedCampaignTypeCode,
      promoCode:
      this.state.selectedCampaignTypeCode === 2 ||
      this.state.selectedCampaignTypeCode === 4 ||this.state.selectedCampaignTypeCode === 6
          ? this.state.promoCode
          : null,
      docId: this.state.campaignDocId === '' ? null : this.state.campaignDocId,
      notificationTitle:
        this.state.campaignPushNotificationTitle === ''
          ? null
          : this.state.campaignPushNotificationTitle,
      notificationMsg:
        this.state.campaignPushNotificationDescription === ''
          ? null
          : this.state.campaignPushNotificationDescription,
      notificationLink:
        this.state.campaignPushNotificationTargetLink === ''
          ? null
          : this.state.campaignPushNotificationTargetLink,
      notificationLinkType:
        this.state.campaignPushNotificationLinkType === 'Select Link Type'
          ? null
          : this.state.campaignPushNotificationLinkType,
      bannerUrl:
      this.state.selectedFilesBanner.length > 0
      ? this.state.campaignAppBannerUrl === ''
        ? null
        : this.state.campaignAppBannerUrl
      : this.state.campaignAppBannerUrl === ''
      ? null
      : this.state.campaignBannerS3Key,
      bannerLink:
        this.state.campaignAppBannerTargetLink === ''
          ? null
          : this.state.campaignAppBannerTargetLink,
      bannerLinkType:
        this.state.campaignBannerLinkType === 'Select Link Type'
          ? null
          : this.state.campaignBannerLinkType,
      audienceType: this.state.audTypeCode,
      customerPresetId:
        this.state.presetId === 'Select preset Audience'
          ? null
          : this.state.presetId,
      inboxTitle:
        this.state.campaignInboxTitle === ''
          ? null
          : this.state.campaignInboxTitle,
      inboxMsg:
        this.state.campaignInboxDescription === ''
          ? null
          : this.state.campaignInboxDescription,
      isBannner:
        this.state.selectedFilesBanner.length > 0 ||
        this.state.campaignAppBannerUrl
          ? 1
          : 0,
      isNotification: this.state.campaignPushNotificationDescription ? 1 : 0,
      isInbox: this.state.campaignInboxDescription ? 1 : 0,
      budget: this.state.campaignBudget,
      offerType:
        this.state.campaignOfferType === 'Select Offer Type'
          ? null
          : this.state.campaignOfferType,
      offerValue: this.state.offerPercentage,
      kpi: [
        this.state.totalTrxCount !== 0 && {
          id: 1,
          value: this.state.totalTrxCount,
        },
        this.state.totalTrxAmount !== 0 && {
          id: 2,
          value: this.state.totalTrxAmount,
        },
        this.state.newCustomerAcquisition !== 0 && {
          id: 3,
          value: this.state.newCustomerAcquisition,
        },
        this.state.activeCustomerPercentage !== 0 && {
          id: 4,
          value: this.state.activeCustomerPercentage,
        },
        this.state.ticketSizeKPI !== 0 && {
          id: 5,
          value: this.state.ticketSizeKPI,
        },
      ],
      modality: [
        this.state.ticketSize !== 0 && {
          id: 1,
          value: this.state.ticketSize,
        },
        this.state.singleTrxMaxAmountCap !== 0 && {
          id: 2,
          value: this.state.singleTrxMaxAmountCap,
        },
        this.state.maxTrxAmountCampaignDuration !== 0 && {
          id: 3,
          value: this.state.maxTrxAmountCampaignDuration,
        },
        this.state.maxTrxAmountPerDay !== 0 && {
          id: 4,
          value: this.state.maxTrxAmountPerDay,
        },
        this.state.maxTrxCountCampaignDuration !== 0 && {
          id: 5,
          value: this.state.maxTrxCountCampaignDuration,
        },
        this.state.maxTrxCountPerDay !== 0 && {
          id: 6,
          value: this.state.maxTrxCountPerDay,
        },
      ],
    };
    await GKApi.put('/marketing/campaign', submitData, {
      headers: {
        Authorization: token,
      },
    });
    await this.props.fetchCampaign()
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <Row>
            <Col xl={5}>
              <h6 className="fw-bold pt-2">Campaign Communication</h6>
            </Col>
          </Row>

          {/* @desc Campaign communication create */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 1,
                          })}
                          onClick={() => {
                            this.toggleTab(1);
                          }}
                        >
                          <span className="step-number">01</span>
                          <span className="step-title">Details</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 2,
                          })}
                          onClick={() => {
                            this.toggleTab(2);
                          }}
                        >
                          <span className="step-number">02</span>
                          <span className="step-title">Content</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 3,
                          })}
                          onClick={() => {
                            this.toggleTab(3);
                          }}
                        >
                          <span className="step-number">03</span>
                          <span className="step-title">KPI</span>
                        </NavLink>
                      </NavItem>
                    </ul>
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="twitter-bs-wizard-tab-content"
                    >
                      <TabPane tabId={1}>
                        <Form>
                          <Row>
                            <Col lg="12">
                              <div className="mb-3">
                                <h6 className="fw-bold">Campaign Title</h6>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-firstname-input1"
                                  value={this.state.campaignTitle}
                                  onChange={(e) => {
                                    this.handleCampaignTitleChange(e);
                                  }}
                                />
                                <p className="pb-2">
                                  * Please at least fill the title field to
                                  proceed to the next step
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <div>
                            {' '}
                            <h6 className="fw-bold">Campaign Tenure</h6>
                            <p>Select your campaign start date and end date</p>
                          </div>
                          <Row>
                            <Col lg="6" className='d-flex gap-2'>
                                <Col lg='2'><p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Start Date
                                  </p></Col>
                                <Col lg='4'>
                                  <div
                                    style={{
                                      border: '1px solid gray',
                                      borderRadius: '5px',
                                      paddingTop: '2.5px',
                                      paddingBottom: '2.5px',
                                      paddingRight: '4px',
                                      paddingLeft: '4px',
                                    }}
                                  >
                                    
                                    <DatePickerComponent
                                      selectedDate={
                                        this.state.campaignSelectedStartDate
                                      }
                                      handleSelectedDateChange={
                                        this.handleCampaignSelectedStartDateChange
                                      }
                                    />
  
  </div>
                                </Col >
<Col lg='4'>
  <Input
                            type="time"
                            value={this.state.campaignSelectedStartTime}
                            id="add-sku-deliveryNote-input"
                            onChange={this.handleCampaignSelectedStartTimeChange}
                          />
</Col >
                              </Col>
                              <Col lg="6" className='d-flex gap-2'>
                                <Col lg='2'><p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    End Date
                                  </p></Col>
                                <Col lg='4'>
                                  <div
                                    style={{
                                      border: '1px solid gray',
                                      borderRadius: '5px',
                                      paddingTop: '2.5px',
                                      paddingBottom: '2.5px',
                                      paddingRight: '4px',
                                      paddingLeft: '4px',
                                    }}
                                  >
                                    
                                    <DatePickerComponent
                                      selectedDate={
                                        this.state.campaignSelectedEndDate
                                      }
                                      handleSelectedDateChange={
                                        this.handleCampaignSelectedEndDateChange
                                      }
                                    />
  
  </div>
                                </Col >
<Col lg='4'>
  <Input
                            type="time"
                            value={this.state.campaignSelectedEndTime}
                            id="add-sku-deliveryNote-input"
                            onChange={this.handleCampaignSelectedEndTimeChange}
                          />
</Col >
                              </Col>
                            </Row>

                          <Row>
                            <Col lg="12" className="my-3">
                              <h6 className="fw-bold">Campaign Type</h6>

                              <Dropdown
                                isOpen={this.state.isOpen}
                                toggle={() =>
                                  this.setState({
                                    isOpen: !this.state.isOpen,
                                  })
                                }
                                className="mt-4 mt-sm-0"
                              >
                                <DropdownToggle
                                  className="d-flex justify-content-between"
                                  color="light"
                                  caret
                                  style={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #BDBDBD',
                                    borderRadius: '4px',
                                    width: '100%',
                                  }}
                                >
                                  {this.state.selectedCampaignType}
                                  <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {this.state.campaignType.map((ct) => (
                                    <DropdownItem
                                      onClick={(event) =>
                                        this.setState({
                                          selectedCampaignType: ct.title,
                                          selectedCampaignTypeCode: ct.code,
                                        })
                                      }
                                    >
                                      {ct.title}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>
                          {(this.state.selectedCampaignTypeCode === 2 ||
                            this.state.selectedCampaignTypeCode === 4 || this.state.selectedCampaignTypeCode === 6) && (
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <h6 className="fw-bold">Promo Code</h6>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    value={this.state.promoCode}
                                    onChange={(e) => {
                                      this.handlePromoCodeChange(e);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}

                          <Row>
                            <Col lg="12" className="my-3">
                              <h6 className="fw-bold">Offer Type</h6>

                              <Dropdown
                                isOpen={this.state.campaignOfferTypeMenu}
                                toggle={() =>
                                  this.setState({
                                    campaignOfferTypeMenu:
                                      !this.state.campaignOfferTypeMenu,
                                  })
                                }
                                className="mt-4 mt-sm-0"
                              >
                                <DropdownToggle
                                  className="d-flex justify-content-between"
                                  color="light"
                                  caret
                                  style={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #BDBDBD',
                                    borderRadius: '4px',
                                    width: '100%',
                                  }}
                                >
                                  {this.state.campaignOfferType}
                                  <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={(event) =>
                                      this.setState({
                                        campaignOfferType: 'PERCENTAGE',
                                      })
                                    }
                                  >
                                    Percentage
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={(event) =>
                                      this.setState({
                                        campaignOfferType: 'FLAT',
                                      })
                                    }
                                  >
                                    Flat
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl={12}>
                              <div className="mb-3">
                                <h6 className="fw-bold">Offer Value</h6>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="basicpill-email-input4"
                                  placeholder="Please input Offer percentage"
                                  value={this.state.offerPercentage}
                                  onChange={(e) => {
                                    this.handleOfferPercentageChange(e);
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={12}>
                              <div className="mb-3">
                                <h6 className="fw-bold">
                                  Campaign Budget (BDT)
                                </h6>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-email-input4"
                                  placeholder="Please input Offer percentage"
                                  value={this.state.campaignBudget}
                                  onChange={(e) => {
                                    this.handleCampaignBudgetChange(e);
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="my-3">
                              <Row className="mb-3">
                                <h6 className="fw-bold">Modalities</h6>
                              </Row>
                              <Row className="mb-3">
                                {this.state.modalityType.map((modality) => (
                                  <Row className="mb-3">
                                    <Label
                                      htmlFor="email-input"
                                      className="col-md-8 col-form-label"
                                    >
                                      {modality.title}
                                    </Label>
                                    <Col md={3}>
                                      {modality.code === 1 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={this.state.ticketSize}
                                            placeholder="Please input Ticket Size"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleTicketSizeChange(e);
                                            }}
                                            disabled={
                                              this.state.disableModality1
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality1 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality1: true,
                                                  ticketSize: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality1 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality1: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                      {modality.code === 2 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={
                                              this.state.singleTrxMaxAmountCap
                                            }
                                            placeholder="Please input Single Transaction Max Amount Cap"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleSingleTrxMaxAmountCap(
                                                e
                                              );
                                            }}
                                            disabled={
                                              this.state.disableModality2
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality2 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality2: true,
                                                  singleTrxMaxAmountCap: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality2 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality2: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}

                                      {modality.code === 3 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={
                                              this.state
                                                .maxTrxAmountCampaignDuration
                                            }
                                            placeholder="Please input Max Transaction Amount in Campaign Duration"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleMaxTrxAmountCampaignDurationChange(
                                                e
                                              );
                                            }}
                                            disabled={
                                              this.state.disableModality3
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality3 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality3: true,
                                                  maxTrxAmountCampaignDuration: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality3 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality3: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}

                                      {modality.code === 4 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={
                                              this.state.maxTrxAmountPerDay
                                            }
                                            placeholder="Please input Max Transaction Amount per Day"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleMaxTrxAmountPerDayChange(
                                                e
                                              );
                                            }}
                                            disabled={
                                              this.state.disableModality4
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality4 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality4: true,
                                                  maxTrxAmountPerDay: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality4 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality4: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                      {modality.code === 5 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={
                                              this.state
                                                .maxTrxCountCampaignDuration
                                            }
                                            placeholder="Please input Max Transaction Count in Campaign Duration"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleMaxTrxCountCampaignDurationChange(
                                                e
                                              );
                                            }}
                                            disabled={
                                              this.state.disableModality5
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality5 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality5: true,
                                                  maxTrxCountCampaignDuration: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality5 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality5: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                      {modality.code === 6 && (
                                        <div className="d-flex gap-3">
                                          <Input
                                            type="number"
                                            value={this.state.maxTrxCountPerDay}
                                            placeholder="Please input Max Transaction Count per Day"
                                            id="email-input"
                                            onChange={(e) => {
                                              this.handleMaxTrxCountPerDayChange(
                                                e
                                              );
                                            }}
                                            disabled={
                                              this.state.disableModality6
                                                ? true
                                                : false
                                            }
                                          />
                                          {this.state.disableModality6 ===
                                            false && (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality6: true,
                                                  maxTrxCountPerDay: 0,
                                                });
                                              }}
                                            >
                                              Disable
                                            </Button>
                                          )}
                                          {this.state.disableModality6 ===
                                            true && (
                                            <Button
                                              color="primary"
                                              onClick={() => {
                                                this.setState({
                                                  disableModality6: false,
                                                });
                                              }}
                                            >
                                              Enable
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                ))}
                              </Row>
                            </Row>
                          <Row>
                            {this.props?.campaignDraftData?.docUrl &&
                            this.state.campaignDocId ===
                              this.props.campaignDraftData.docId ? (
                              <div className="py-2 d-flex gap-5">
                                <a
                                  href={this.props?.campaignDraftData?.docUrl}
                                  className="mt-2"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Proposal doc
                                </a>
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      campaignDocId: '',
                                    })
                                  }
                                >
                                  Clear draft document
                                </Button>
                              </div>
                            ) : (
                              <Form>
                                <h6 className="fw-bold">
                                  Upload Campaign Proposal/Documents
                                </h6>
                                <Dropzone
                                  accept="image/*,.pdf"
                                  onDrop={(acceptedFiles) =>
                                    this.handleAcceptedFiles(acceptedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                      <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                        </div>
                                        <h4>
                                          Upload Campaign Proposal/Documents
                                        </h4>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {this.state.selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + '-file'}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted fw-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {f.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    );
                                  })}
                                </div>
                              </Form>
                            )}
                          </Row>
                        </Form>
                      </TabPane>
                      <TabPane tabId={2}>
                        <Form>
                          <Col xl={12}>
                            <Card>
                              <CardBody>
                                <Nav
                                  tabs
                                  className="nav-tabs-custom nav-justified"
                                >
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: 'pointer' }}
                                      className={classnames({
                                        active:
                                          this.state.activeTabJustify === '5',
                                      })}
                                      onClick={() => {
                                        this.toggleCustomJustified('5');
                                      }}
                                    >
                                      <span className="d-none d-sm-block">
                                        App Banner
                                      </span>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: 'pointer' }}
                                      className={classnames({
                                        active:
                                          this.state.activeTabJustify === '6',
                                      })}
                                      onClick={() => {
                                        this.toggleCustomJustified('6');
                                      }}
                                    >
                                      <span className="d-none d-sm-block">
                                        Push Notifications
                                      </span>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: 'pointer' }}
                                      className={classnames({
                                        active:
                                          this.state.activeTabJustify === '7',
                                      })}
                                      onClick={() => {
                                        this.toggleCustomJustified('7');
                                      }}
                                    >
                                      <span className="d-none d-sm-block">
                                        Inbox
                                      </span>
                                    </NavLink>
                                  </NavItem>
                                </Nav>

                                <TabContent
                                  activeTab={this.state.activeTabJustify}
                                >
                                  <TabPane tabId="5" className="p-3">
                                    <Row>
                                      <Col sm="12">
                                        <CardText>
                                          <div className="border border-1 rounded p-4">
                                            {this.state.campaignAppBannerUrl ? (
                                              <Row className="py-2 px-4">
                                                <img
                                                  className="py-2"
                                                  data-dz-thumbnail=""
                                                  // height="50%"
                                                  // width="50%"
                                                  // className="avatar-sm rounded bg-light"
                                                  alt={'app banner'}
                                                  src={
                                                    this.state
                                                      .campaignAppBannerUrl
                                                  }
                                                />
                                                <Button
                                                  className="py-2"
                                                  onClick={() => {
                                                    this.setState({
                                                      campaignAppBannerUrl: '',
                                                    });
                                                  }}
                                                >
                                                  Clear Draft banner
                                                </Button>
                                              </Row>
                                            ) : (
                                              <Row className="py-2 px-4">
                                                <Dropzone
                                                  accept="image/*"
                                                  onDrop={(acceptedFiles) =>
                                                    this.handleAcceptedFilesBanner(
                                                      acceptedFiles
                                                    )
                                                  }
                                                >
                                                  {({
                                                    getRootProps,
                                                    getInputProps,
                                                  }) => (
                                                    <div className="dropzone">
                                                      <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                      >
                                                        <input
                                                          {...getInputProps()}
                                                        />
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>Upload banner</h4>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Dropzone>
                                                <div
                                                  className="dropzone-previews mt-3"
                                                  id="file-previews"
                                                >
                                                  {this.state.selectedFilesBanner.map(
                                                    (f, i) => {
                                                      return (
                                                        <Card
                                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                          key={i + '-file'}
                                                        >
                                                          <div className="p-2">
                                                            <Row className="align-items-center">
                                                              <Col className="col-auto">
                                                                <img
                                                                  data-dz-thumbnail=""
                                                                  height="80"
                                                                  className="avatar-sm rounded bg-light"
                                                                  alt={f.name}
                                                                  src={
                                                                    f.preview
                                                                  }
                                                                />
                                                              </Col>
                                                              <Col>
                                                                <Link
                                                                  to="#"
                                                                  className="text-muted fw-bold"
                                                                >
                                                                  {f.name}
                                                                </Link>
                                                                <p className="mb-0">
                                                                  <strong>
                                                                    {
                                                                      f.formattedSize
                                                                    }
                                                                  </strong>
                                                                </p>
                                                              </Col>
                                                            </Row>
                                                          </div>
                                                        </Card>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </Row>
                                            )}
                                            <Row className="py-2">
                                              <span>
                                                <h6 className="fw-bold">
                                                  {' '}
                                                  Link Type
                                                </h6>
                                              </span>
                                              <span>
                                                <Col>
                                                  <Dropdown
                                                    isOpen={
                                                      this.state
                                                        .campaignBannerLinkTypeMenu
                                                    }
                                                    toggle={() =>
                                                      this.setState({
                                                        campaignBannerLinkTypeMenu:
                                                          !this.state
                                                            .campaignBannerLinkTypeMenu,
                                                      })
                                                    }
                                                    className="mt-4 mt-sm-0"
                                                  >
                                                    <DropdownToggle
                                                      className="d-flex justify-content-between"
                                                      color="light"
                                                      caret
                                                      style={{
                                                        backgroundColor: '#fff',
                                                        border:
                                                          '1px solid #BDBDBD',
                                                        borderRadius: '4px',
                                                        width: '100%',
                                                      }}
                                                    >
                                                      {this.state
                                                        .campaignBannerLinkType ===
                                                      1
                                                        ? 'Internal'
                                                        : this.state
                                                            .campaignBannerLinkType ===
                                                          2
                                                        ? 'External'
                                                        : 'Select Link Type'}
                                                      <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                      <DropdownItem
                                                        onClick={(event) =>
                                                          this.setState({
                                                            campaignBannerLinkType: 1,
                                                          })
                                                        }
                                                      >
                                                        Internal
                                                      </DropdownItem>
                                                      <DropdownItem
                                                        onClick={(event) =>
                                                          this.setState({
                                                            campaignBannerLinkType: 2,
                                                          })
                                                        }
                                                      >
                                                        External
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </Dropdown>
                                                </Col>
                                              </span>
                                            </Row>
                                            <Row className="py-2">
                                              <span>
                                                <h6 className="fw-bold">
                                                  {' '}
                                                  Target Link
                                                </h6>
                                              </span>
                                              <span>
                                                <Col>
                                                  {this.state
                                                    .campaignBannerLinkType ===
                                                  1 ? (
                                                    <Dropdown
                                                      isOpen={
                                                        this.state
                                                          .campaignBannerInternalLinkTypeMenu
                                                      }
                                                      toggle={() =>
                                                        this.setState({
                                                          campaignBannerInternalLinkTypeMenu:
                                                            !this.state
                                                              .campaignBannerInternalLinkTypeMenu,
                                                        })
                                                      }
                                                      className="mt-4 mt-sm-0"
                                                    >
                                                      <DropdownToggle
                                                        className="d-flex justify-content-between"
                                                        color="light"
                                                        caret
                                                        style={{
                                                          backgroundColor:
                                                            '#fff',
                                                          border:
                                                            '1px solid #BDBDBD',
                                                          borderRadius: '4px',
                                                          width: '100%',
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .campaignAppBannerTargetLink
                                                        }
                                                        <i className="mdi mdi-chevron-down"></i>
                                                      </DropdownToggle>
                                                      <DropdownMenu>
                                                        {this.state.bannerInternalLinkList.map(
                                                          (links) => (
                                                            <DropdownItem
                                                              onClick={(
                                                                event
                                                              ) =>
                                                                this.setState({
                                                                  campaignAppBannerTargetLink:
                                                                    links,
                                                                })
                                                              }
                                                            >
                                                              {links}
                                                            </DropdownItem>
                                                          )
                                                        )}
                                                      </DropdownMenu>
                                                    </Dropdown>
                                                  ) : (
                                                    <div>
                                                      <Input
                                                        type="text"
                                                        value={
                                                          this.state
                                                            .campaignAppBannerTargetLink
                                                        }
                                                        placeholder="PLease input App Banner Target Link"
                                                        id="gold-price-input"
                                                        onChange={
                                                          this
                                                            .handleCampaignAppBannerTargetLinkChange
                                                        }
                                                      />
                                                      <p className="py-2">
                                                        *External link example
                                                        format :
                                                        https://www.goldkinen.com
                                                      </p>
                                                    </div>
                                                  )}
                                                </Col>
                                              </span>
                                            </Row>
                                          </div>
                                        </CardText>
                                      </Col>
                                    </Row>
                                  </TabPane>
                                  <TabPane tabId="6" className="p-3">
                                    <Row>
                                      <Col sm="12">
                                        <CardText>
                                          <div className="border border-1 rounded p-4">
                                            <div className="py-4">
                                              <div className="pb-2">
                                                <h5 className="card-title">
                                                  Title
                                                </h5>
                                                <Input
                                                  type="text"
                                                  value={
                                                    this.state
                                                      .campaignPushNotificationTitle
                                                  }
                                                  placeholder="PLease input Push Notification Title"
                                                  onChange={
                                                    this
                                                      .handleCampaignPushNotificationTitleChange
                                                  }
                                                />
                                              </div>
                                              <div className="pb-2">
                                                <h5 className="card-title">
                                                  Description
                                                </h5>
                                                <div>
                                                  <TextField
                                                    className="w-full"
                                                    variant="outlined"
                                                    type="text"
                                                    multiline
                                                    minRows={2}
                                                    style={{
                                                      width: '100%',
                                                    }}
                                                    placeholder="Please input Push Notification Description"
                                                    value={
                                                      this.state
                                                        .campaignPushNotificationDescription
                                                    }
                                                    onChange={
                                                      this
                                                        .handleCampaignPushNotificationDescriptionChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </CardText>
                                      </Col>
                                    </Row>
                                  </TabPane>
                                  <TabPane tabId="7" className="p-3">
                                    <Row>
                                      <Col sm="12">
                                        <CardText>
                                          <div className="border border-1 rounded p-4">
                                            <div className="py-4">
                                              <div className="pb-2">
                                                <h5 className="card-title">
                                                  Description
                                                </h5>
                                                <div>
                                                  <TextField
                                                    className="w-full"
                                                    variant="outlined"
                                                    type="text"
                                                    multiline
                                                    minRows={2}
                                                    style={{
                                                      width: '100%',
                                                    }}
                                                    placeholder="Please input Inbox Description"
                                                    value={
                                                      this.state
                                                        .campaignInboxDescription
                                                    }
                                                    onChange={
                                                      this
                                                        .handleCampaignInboxDescriptionChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </CardText>
                                      </Col>
                                    </Row>
                                  </TabPane>
                                </TabContent>
                              </CardBody>
                            </Card>
                          </Col>
                          <Row className="py-2">
                            <h6 className="fw-bold">Campaign Audience</h6>
                            {this.state.audienceType.map((at, id) => (
                              <Col xl={4}>
                                <div
                                  className={`border border-1 px-4 py-1  rounded d-flex ${
                                    this.state.radio1Campaign === at.title
                                      ? 'twitter-bs-wizard-border-radio'
                                      : ''
                                  }`}
                                >
                                  <div className="mt-3">
                                    <label className="d-flex gap-2 px-4">
                                      <input
                                        type="radio"
                                        value={at.title}
                                        checked={
                                          this.state.radio1Campaign === at.title
                                        }
                                        onChange={(e) => {
                                          this.onRadioChange1Campaign(e);
                                          this.setState({
                                            audTypeCode: at.code,
                                          });
                                        }}
                                      />
                                      <p className="px-4">{at.title}</p>
                                    </label>
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                          {this.state.radio1Campaign === 'Custom Users' && (
                            <div className="border border-1 rounded p-4">
                              <Row className="py-2">
                                <h6 className="fw-bold">
                                  Preset Audience Name
                                </h6>
                                <Dropdown
                                  isOpen={this.state.isOpen2}
                                  toggle={() =>
                                    this.setState({
                                      isOpen2: !this.state.isOpen2,
                                    })
                                  }
                                  className="mt-4 mt-sm-0"
                                >
                                  <DropdownToggle
                                    className="d-flex justify-content-between"
                                    color="light"
                                    caret
                                    style={{
                                      backgroundColor: '#fff',
                                      border: '1px solid #f1f1f1',
                                      borderRadius: '4px',
                                      width: '100%',
                                    }}
                                  >
                                    {this.state.presetName}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem disabled>
                                      Select Audience preset
                                    </DropdownItem>

                                    {this.state.downloadAudienceData.map(
                                      (dad, id) => (
                                        <DropdownItem
                                          key={id}
                                          onClick={(event) =>
                                            this.setState(
                                              {
                                                presetId: dad.id,
                                                presetName: dad.name,
                                                presetData: JSON.parse(
                                                  dad.jsonData
                                                ),
                                              },
                                              () => {}
                                            )
                                          }
                                        >
                                          {dad.name}
                                        </DropdownItem>
                                      )
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                                <div className="my-3">
                                  <h6>Preset Details</h6>

                                  {this.state.presetData.map((pd) => (
                                    <Row>
                                      <Col xl={3}>{pd.displayName}</Col>
                                      <Col xl={7}>
                                        {pd.operator === 'range' ? (
                                          <div className="d-flex gap-2">
                                            <p>From</p>
                                            <p>{pd.data.from}</p>
                                            <p>To</p>
                                            <p>{pd.data.to}</p>
                                          </div>
                                        ) : pd.operator === 'equal' ? (
                                          <div className="d-flex gap-2">
                                            <p>=</p>
                                            <p>{pd.data.value}</p>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </Col>
                                    </Row>
                                  ))}
                                </div>
                              </Row>
                            </div>
                          )}
                        </Form>
                      </TabPane>

                      <TabPane tabId={3}>
                        <div>
                          <Card>
                            <CardBody className="border-bottom">
                              <div>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <h6 className="fw-bold">
                                        Total Transaction Amount
                                      </h6>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.totalTrxAmount}
                                        id="basicpill-firstname-input1"
                                        onChange={(e) => {
                                          this.handleTotalTrxAmountChange(e);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <h6 className="fw-bold">
                                        Total Transaction Count
                                      </h6>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.totalTrxCount}
                                        id="basicpill-firstname-input1"
                                        onChange={(e) => {
                                          this.handleTotalTrxCountChange(e);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <h6 className="fw-bold">
                                        New Customer Acquisition
                                      </h6>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        value={
                                          this.state.newCustomerAcquisition
                                        }
                                        id="basicpill-firstname-input1"
                                        onChange={(e) => {
                                          this.handleNewCustomerAcquisitionChange(
                                            e
                                          );
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <h6 className="fw-bold">
                                        Active Customer Percentage
                                      </h6>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        value={
                                          this.state.activeCustomerPercentage
                                        }
                                        id="basicpill-firstname-input1"
                                        onChange={(e) => {
                                          this.handleActiveCustomerPercentageChange(
                                            e
                                          );
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <h6 className="fw-bold">Ticket size</h6>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        value={this.state.ticketSizeKPI}
                                        id="basicpill-firstname-input1"
                                        onChange={(e) => {
                                          this.handleTicketSizeKPIChange(e);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </TabPane>
                    </TabContent>
                    <div className="twitter-bs-wizard-pager-link">
                      <div className="d-flex justify-content-between">
                        <button
                          className="border-0"
                          style={{
                            color: '#FF0000',
                            backgroundColor: '#fff',
                          }}
                          onClick={() => {
                            this.props.modalClose();
                          }}
                        >
                          <h6
                            className="fw-bold"
                            style={{
                              color: '#FF0000',
                              backgroundColor: '#fff',
                            }}
                          >
                            Discard
                          </h6>
                        </button>
                        <div className="d-flex px-4 border-0">
                          <button
                            className="border-0 fw-bold rounded px-2"
                            onClick={(e) => {
                              this.handleCampaignCommunicationDraftSave(e);
                              this.props.modalClose();
                            }}
                            disabled={
                              this.state.campaignTitle !== '' ? false : true
                            }
                          >
                            <h6 className="fw-bold mt-2">Update draft</h6>
                          </button>
                          <div className="px-2">
                            <li
                              className={`${
                                this.state.activeTab === 4
                                  ? 'next disabled'
                                  : 'next'
                              } `}
                            >
                              {this.state.activeTab !== 3 && (
                                <Button
                                  onClick={() => {
                                    this.toggleTab(this.state.activeTab + 1);
                                  }}
                                  className="fw-bold"
                                  style={{
                                    color: '#081939',
                                    backgroundColor: '#c2b56f',
                                  }}
                                  disabled={
                                    this.state.campaignTitle !== '' &&
                                    (this.state.selectedFiles.length > 0 ||
                                      this.state.campaignDocId)
                                      ? false
                                      : true
                                  }
                                >
                                  <h6 className="fw-bold mt-2">
                                    {this.state.campaignTitle !== '' &&
                                    (this.state.selectedFiles.length > 0 ||
                                      this.state.campaignDocId)
                                      ? 'Next'
                                      : 'Communication must have a title and proposal doc'}
                                  </h6>
                                </Button>
                              )}
                              {this.state.activeTab === 3 && (
                                <Button
                                  style={{
                                    color: '#081939',
                                    backgroundColor: '#c2b56f',
                                  }}
                                  onClick={(e) => {
                                    this.handleCampaignCommunicationSubmit(e);
                                    this.toggleTab(this.state.activeTab + 1);

                                    this.props.modalClose();
                                  }}
                                  disabled={
                                    this.state.campaignTitle !== '' &&
                                    (this.state.selectedFiles.length > 0 ||
                                      this.state.campaignDocId)
                                      ? false
                                      : true
                                  }
                                >
                                  <h6 className="fw-bold mt-2">
                                    {this.state.campaignTitle !== '' &&
                                    (this.state.selectedFiles.length > 0 ||
                                      this.state.campaignDocId)
                                      ? 'Submit'
                                      : 'Communication must have a title and proposal doc'}
                                  </h6>
                                </Button>
                              )}
                            </li>
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* <li
                              className={`${
                                this.state.activeTab === 1
                                  ? 'previous disabled'
                                  : 'previous'
                              } px-2`}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  this.toggleTab(this.state.activeTab - 1);
                                }}
                              >
                                Previous
                              </Link>
                            </li> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default EditDraftCampaign;

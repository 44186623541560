import axios from 'axios';


export const AppTitle = 'GK - Admin';

export const GKAPI_Base = process.env.REACT_APP_API_KEY;

export const GKApi = axios.create({
  baseURL: GKAPI_Base,
  headers: {
    'Content-type': 'application/json',
  },
});

export function getToken() {
  let accessToken = null;
  if (
    typeof window !== undefined &&
    window.localStorage.getItem('access-token')
  ) {
    accessToken = window.localStorage.getItem('access-token');
    return accessToken;
  } else {
    removeAccessToken();
  }
  return accessToken;
}

export const setAccessToken = (token) => {
  window.localStorage.setItem('access-token', token);
};

export function getUser() {
  let user = null;
  if (typeof window !== undefined && window.localStorage.getItem('user')) {
    user = window.localStorage.getItem('user');
    return user;
  } else {
    removeUser();
  }
  return user;
}

export const setLoggedUser = (user) => {
  window.localStorage.setItem('user', user);
};

export function getGroupId() {
  let gId = null;
  if (typeof window !== undefined && window.localStorage.getItem('GroupId')) {
    gId = window.localStorage.getItem('GroupId');
    return gId;
  } else {
    removeGroupId();
  }
  return gId;
}

export const setLoggedUserGroupId = (gId) => {
  window.localStorage.setItem('GroupId', gId);
};

export function getGroupName() {
  let groupName = [];
  if (typeof window !== undefined && window.localStorage.getItem('GroupName')) {
    groupName = window.localStorage.getItem('GroupName');
    return groupName;
  } else {
    removeGroupName();
  }
  return groupName;
}

export const setLoggedUserGroupName = (groupName) => {
  window.localStorage.setItem('GroupName', groupName);
};

export const removeAccessToken = () => {
  window.localStorage.removeItem('access-token');
};

export const removeUser = () => {
  window.localStorage.removeItem('user');
};

export const removeGroupId = () => {
  window.localStorage.removeItem('GroupId');
};

export const removeGroupName = () => {
  window.localStorage.removeItem('GroupName');
};


export function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2');
}
export function roundToFour(num) {
  return +(Math.round(num + 'e+4') + 'e-4');
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Logout from '../pages/Authentication/Logout';

// Dashboard
// import Dashboard from '../pages/Dashboard/index';
import FinanceDashboard from '../pages/Finance/Finance-Dashboard/index';
import MarketingDashboard from '../pages/Marketing/Marketing-Dashboard/index';

import FinanceCollect from '../pages/Finance/Finance-Collect';
import FinanceOpsInputs from '../pages/Finance/Finance-Inputs';
import Users from '../pages/AppUsers/AppUser';
import RefundReport from '../pages/Finance/Finance-Reports/RefundReport';
import SalesReport from '../pages/Finance/Finance-Reports/SalesReport';
import GiftGoldReport from '../pages/Finance/Finance-Reports/GiftGoldReport';
import CollectGoldReport from '../pages/Finance/Finance-Reports/CollectGoldReport';
import AdminUsers from '../pages/AdminUsers/AdminUser';

//sign in
import Signin from '../pages/Signin';

//Campaign
import Campaign from '../pages/Marketing/Campaign';
import Profile from '../pages/Profile/profile';
import MarketingReport from '../pages/Marketing/Report';

import CampaignManager from '../pages/Marketing/CampaignManager';
import CreateCampaign from '../pages/Marketing/CampaignManager/CreateCampaign';
import ReferDisbursement from '../pages/Finance/Refer-Disbursement/ReferDisbursement';
import UserRequests from '../pages/AppUsers/UserRequests';
import { getToken } from '../components/config';
import ManualDisbursement from '../pages/Finance/Refer-Disbursement/ManualDisbursement';
import Disputes from '../pages/Finance/Finance-Disputes/Disputes';
import BuyTrx from '../pages/AppUsers/User-Transactions/BuyTrx';
import SellTrx from '../pages/AppUsers/User-Transactions/SellTrx';
import GiftTrx from '../pages/AppUsers/User-Transactions/GiftTrx';
import CollectTrx from '../pages/AppUsers/User-Transactions/CollectTrx';
import UserGroups from '../pages/AdminUsers/UserGroup';
import CRMChangeRequests from '../pages/AppUsers/CrmChangeRequests';
import BuyReportMKT from '../pages/Marketing/Report/buyReport';
import SellReportMKT from '../pages/Marketing/Report/sellReport';
import CollectReportMKT from '../pages/Marketing/Report/collectReport';
import ActiveUserReportMKT from '../pages/Marketing/Report/activeUserReport';
import InactiveUserReportMKT from '../pages/Marketing/Report/inactiveUserReport';
import NewUserReportMKT from '../pages/Marketing/Report/newUserReport';
import AppVersion from '../pages/AdminUsers/AppVersion';
import FinanceSettings from '../pages/Finance/Finance-Settings';
import GoldHoldingReport from '../pages/Finance/Gold-Holding-Report';
import FinanceLedger from '../pages/Finance/Finance-Ledger';
import CustomRefer from '../pages/Finance/Custome-Refer-Settings';

import ReferSettings from '../pages/Finance/Refer-Settings';
import ReferReport from '../pages/Finance/Finance-Refer-Report';
import DeliveryManagement from '../pages/DeliveryManagement';
import BkashDisbursementSettings from '../pages/Finance/Bkash-Disbursement-Settings';
import ManualRefund from '../pages/Finance/Manual-Refund';
import PickupManagement from '../pages/DeliveryManagement/pickupManagement';
import CSRVIEW from '../pages/CSR';

const token = getToken();

const authProtectedRoutes = [
  //Finance
  {
    path: '/finance-dashboard',
    component: FinanceDashboard,
  },
  {
    path: '/finance-collect',
    component: FinanceCollect,
  },
  { path: '/finance-ops-inputs', component: FinanceOpsInputs },
  { path: '/refund-report', component: RefundReport },
  { path: '/sales-report', component: SalesReport },
  { path: '/gift-report', component: GiftGoldReport },
  { path: '/collect-report', component: CollectGoldReport },
  { path: `/buy-transaction-details`, component: BuyTrx },
  { path: `/sell-transaction-details`, component: SellTrx },
  { path: `/collect-transaction-details`, component: CollectTrx },
  { path: `/gift-transaction-details`, component: GiftTrx },
  { path: '/refer-disbursement', component: ReferDisbursement },
  { path: '/manual-disbursement', component: ManualDisbursement },
  { path: '/refer-report', component: ReferReport },
  { path: '/finance-disputes', component: Disputes },
  { path: '/finance-internal-settings', component: FinanceSettings },
  {
    path: '/bkash-disbursement-settings',
    component: BkashDisbursementSettings,
  },
  { path: '/manual-refund', component: ManualRefund },
  { path: '/finance-gold-holding-report', component: GoldHoldingReport },
  { path: '/finance-ledger', component: FinanceLedger },
  { path: '/custom-refer-settings', component: CustomRefer },
  //SKU management

  // { path: '/sku-management', component: SKU },

  //Delivery management
  { path: '/delivery-management', component: DeliveryManagement },
  { path: '/pickup-management', component: PickupManagement },

  { path: '/finance-refer-settings', component: ReferSettings },

  //Marketing
  { path: '/marketing-dashboard', component: MarketingDashboard },
  { path: '/marketing-campaign', component: Campaign },
  { path: '/marketing-report', component: MarketingReport },
  { path: '/create-campaign', component: CreateCampaign },
  { path: '/app-marketing', component: CampaignManager },

  //marketing report

  { path: '/marketing-buy-report', component: BuyReportMKT },
  { path: '/marketing-sell-report', component: SellReportMKT },
  { path: '/marketing-collect-report', component: CollectReportMKT },
  { path: '/marketing-active-user-report', component: ActiveUserReportMKT },
  { path: '/marketing-inactive-user-report', component: InactiveUserReportMKT },
  { path: '/marketing-new-user-report', component: NewUserReportMKT },

  //App Users
  { path: '/app-users', component: Users },
  { path: '/user-requests', component: UserRequests },
  { path: '/crm-change-requests', component: CRMChangeRequests },

  //Admin Users
  { path: '/admin-users', component: AdminUsers },
  { path: '/user-groups', component: UserGroups },
  { path: '/app-version', component: AppVersion },

  //Admin User's profile
  { path: '/user-profile', component: Profile },

  // CSR view
  { path: '/csr', component: CSRVIEW },
  // this route should be at the end of all other routes
  token
    ? {
        path: '/',
        exact: true,
        component: () => <Redirect to="/finance-dashboard" />,
      }
    : { path: '/', exact: true, component: () => <Redirect to="/signin" /> },
];
const publicRoutes = [
  { path: '/signin', component: Signin },
  { path: '/logout', component: Logout },
];

export { authProtectedRoutes, publicRoutes };

import React, { Component } from 'react';
import { Card, CardBody, Row, Col, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import './dashboard.scss';
import { getToken, GKApi, roundToTwo } from '../../../components/config';

class EarningReports extends Component {
  state = {
    buySeries: [32, 32,36],
    buyOptions: {
      chart: {
        type: 'donut',
      },
      labels: ['Bkash', 'Nagad', 'Banking'],
      dataLabels: {
        enabled: true,
        background: {
          enabled: true,
          foreColor: '#ffff',
          borderRadius: 5,
          borderWidth: 3,
          opacity: 0.9,
        },
      },
      plotOptions: {
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#FFFFFF'],
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    sellSeries: [42, 58],
    sellOptions: {
      chart: {
        type: 'donut',
      },
      labels: ['MFS', 'Banking'],
      dataLabels: {
        enabled: true,
        background: {
          enabled: true,
          foreColor: '#ffff',
          borderRadius: 5,
          borderWidth: 3,
          opacity: 0.9,
        },
      },
      plotOptions: {
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#FFFFFF'],
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    collectSeries: [42, 42, 16],
    collectOptions: {
      chart: {
        type: 'donut',
      },
      labels: ['Bkash', 'Nagad', 'Banking'],
      dataLabels: {
        enabled: true,
        background: {
          enabled: true,
          foreColor: '#ffff',
          borderRadius: 5,
          borderWidth: 3,
          opacity: 0.9,
        },
      },
      plotOptions: {
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#FFFFFF'],
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    display: 'buy',
    buyDetails: {},
    sellDetails: {},
    collectDetails: {},
  };

  async componentDidMount() {
    // GET request using axios with async/await
    const buyData = [];
    const sellData = [];
    const collectData = [];
    const token = getToken();
    const response = await GKApi.get('/finance/mfs-bank', {
      headers: {
        Authorization: token,
        cmpId: 9,
      },
    });

    buyData.push(
      response.data.buy.bkashPercentage,
      response.data.buy.nagadPercentage,
      response.data.buy.bankPercentage
    );
    sellData.push(
      response.data.sell.mfsPercentage,
      response.data.sell.bankPercentage
    );
    collectData.push(
      response.data.collect.bkashPercentage,
      response.data.collect.nagadPercentage,
      response.data.collect.bankPercentage
    );
    this.setState({
      ...this.state,
      buySeries: buyData,
      sellSeries: sellData,
      collectSeries: collectData,
      buyDetails: response.data.buy,
      sellDetails: response.data.sell,
      collectDetails: response.data.collect,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-end d-none d-md-inline-block">
              <ButtonGroup className="mb-2">
                <Button
                  size="sm"
                  color={this.state.display === 'buy' ? 'secondary' : 'light'}
                  type="button"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      display: 'buy',
                    })
                  }
                >
                  Buy
                </Button>
                <Button
                  size="sm"
                  color={this.state.display === 'sell' ? 'secondary' : 'light'}
                  type="button"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      display: 'sell',
                    })
                  }
                >
                  Sell
                </Button>
                <Button
                  size="sm"
                  color={
                    this.state.display === 'collect' ? 'secondary' : 'light'
                  }
                  type="button"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      display: 'collect',
                    })
                  }
                >
                  Collect
                </Button>
              </ButtonGroup>
            </div>

            <h4 className="card-title mb-4">Daily Banking VS MFS Channel</h4>
            <div className="text-center">
              <Row>
                <div className='my-4'>
                  <div id="donut-chart" className="apex-charts">
                    <ReactApexChart
                      options={
                        this.state.display === 'buy'
                          ? this.state.buyOptions
                          : this.state.display === 'sell'
                          ? this.state.sellOptions
                          : this.state.collectOptions
                      }
                      series={
                        this.state.display === 'buy'
                          ? this.state.buySeries
                          : this.state.display === 'sell'
                          ? this.state.sellSeries
                          : this.state.collectSeries
                      }
                      type="donut"
                      height="250"
                    />
                  </div>
                </div>
                <Col sm={this.state.display==='sell'?6:4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">Banking</p>
                    {this.state.display === 'buy' ? (
                      <h5>
                        BDT {roundToTwo(this.state.buyDetails.bankAmount)}
                      </h5>
                    ) : this.state.display === 'sell' ? (
                      <h5>
                        BDT {roundToTwo(this.state.sellDetails.bankAmount)}
                      </h5>
                    ):<h5>
                    BDT {roundToTwo(this.state.collectDetails.bankAmount)}
                  </h5>}
                  </div>
                </Col>

                <Col sm={this.state.display==='sell'?6:4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">Bkash</p>
                    {this.state.display === 'buy' ? (
                      <h5>BDT {roundToTwo(this.state.buyDetails.bkashAmount)}</h5>
                    ) : this.state.display === 'sell' ? (
                      <h5>
                        BDT {roundToTwo(this.state.sellDetails.mfsAmount)}
                      </h5>
                    ): <h5>
                    BDT {roundToTwo(this.state.collectDetails.bkashAmount)}
                  </h5>}
                  </div>
                </Col>

                {this.state.display==='sell'?'':<Col sm={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">Nagad</p>
                    {this.state.display === 'buy' ? (
                      <h5>BDT {roundToTwo(this.state.buyDetails.nagadAmount)}</h5>
                    ) :  <h5>
                    BDT {roundToTwo(this.state.collectDetails.nagadAmount)}
                  </h5>}
                  </div>
                </Col>}
              </Row>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default EarningReports;

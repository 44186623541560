import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, ButtonGroup, Button } from 'reactstrap';
import { getToken, GKApi, roundToTwo } from '../../../components/config';
import moment from 'moment';

class DailySFRC extends Component {
  state = {
    // initial state for chart daily series data

    dailySeries: [
      {
        name: 'Service Fee',
        data: [],
      },
      {
        name: 'Refund Charge',
        data: [],
      },
    ],

    //initial state for chart weekly series data

    weeklySeries: [
      {
        name: 'Service Fee',
        data: [],
      },
      {
        name: 'Refund Charge',
        data: [],
      },
    ],

    //initial state for chart monthly series data

    monthlySeries: [
      {
        name: 'Service Fee',
        data: [],
      },
      {
        name: 'Refund Charge',
        data: [],
      },
    ],

    //initial state for chart daily option data

    dailyOptions: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
          },
          export: {
            csv: {
              filename: 'Collection VS Delivery Status',
              columnDelimiter: ',',
              headerCategory: 'Month',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: 'Collection VS Delivery Status',
            },
            png: {
              filename: 'Collection VS Delivery Status',
            },
          },
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: ' gm',
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' gm';
          },
        },
      },
    },

    //initial state for chart weekly option data

    weeklyOptions: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
          },
          export: {
            csv: {
              filename: 'Collection VS Delivery Status',
              columnDelimiter: ',',
              headerCategory: 'Month',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: 'Collection VS Delivery Status',
            },
            png: {
              filename: 'Collection VS Delivery Status',
            },
          },
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: ' gm',
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' gm';
          },
        },
      },
    },

    //initial state for chart monthly option data

    monthlyOptions: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
          },
          export: {
            csv: {
              filename: 'Collection VS Delivery Status',
              columnDelimiter: ',',
              headerCategory: 'Month',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: 'Collection VS Delivery Status',
            },
            png: {
              filename: 'Collection VS Delivery Status',
            },
          },
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: ' gm',
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' gm';
          },
        },
      },
    },

    // display state data

    display: 'daily',
  };

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();
    const dailySfData = [];
    const dailyRcData = [];
    const hourLabels = [];
    const weeklySfData = [];
    const weeklyRcData = [];
    const weekLabels = [];
    const monthlySfData = [];
    const monthlyRcData = [];
    const monthLabels = [];
    const response = await GKApi.get('/finance/sf-rc', {
      headers: {
        Authorization: token,
        cmpId: 3,
      },
    });


    // pushing series data into arrays for daily Data

    for (const obj of response.data.daily) {
      dailySfData.push(roundToTwo(obj.serviceFee));
      dailyRcData.push(roundToTwo(obj.refundCharge));
      hourLabels.push(moment(obj.hour, ['HH']).format('hh A'));
    }

    //pushing series data into arrays for weekly data

    for (const obj of response.data.weekly) {
      weeklySfData.push(roundToTwo(obj.serviceFee));
      weeklyRcData.push(roundToTwo(obj.refundCharge));
      weekLabels.push(obj.date);
    }

    //pushing series data into arrays for monthly Data

    for (const obj of response.data.monthly) {
      monthlySfData.push(roundToTwo(obj.serviceFee));
      monthlyRcData.push(roundToTwo(obj.refundCharge));
      monthLabels.push(obj.date);
    }

    //setting the state with api data
    this.setState({
      ...this.state,
      dailySeries: [
        {
          name: 'Service Fee',
          data: dailySfData, //setting daily collection data
        },
        {
          name: 'Refund Charge',
          data: dailyRcData, //setting daily delivery data
        },
      ],
      weeklySeries: [
        {
          name: 'Service Fee',
          data: weeklySfData, //setting weekly collection data
        },
        {
          name: 'Refund Charge',
          data: weeklyRcData, //setting weekly delivery data
        },
      ],
      monthlySeries: [
        {
          name: 'Service Fee',
          data: monthlySfData, //setting monthly collection data
        },
        {
          name: 'Refund Charge',
          data: monthlyRcData, //setting monthly delivery data
        },
      ],

      dailyOptions: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
            },
            export: {
              csv: {
                filename: 'Collection VS Delivery Status',
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: 'Collection VS Delivery Status',
              },
              png: {
                filename: 'Collection VS Delivery Status',
              },
            },
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: hourLabels, // setting daily hour labels
        },
        yaxis: {
          title: {
            text: ' bdt',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' bdt';
            },
          },
        },
      },
      weeklyOptions: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
            },
            export: {
              csv: {
                filename: 'Collection VS Delivery Status',
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: 'Collection VS Delivery Status',
              },
              png: {
                filename: 'Collection VS Delivery Status',
              },
            },
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: weekLabels, // setting weekly labels
        },
        yaxis: {
          title: {
            text: ' bdt',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' bdt';
            },
          },
        },
      },
      monthlyOptions: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
            },
            export: {
              csv: {
                filename: 'Collection VS Delivery Status',
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: 'Collection VS Delivery Status',
              },
              png: {
                filename: 'Collection VS Delivery Status',
              },
            },
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: monthLabels, // setting monthly labels
        },
        yaxis: {
          title: {
            text: ' bdt',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' bdt';
            },
          },
        },
      },
    });
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-end d-none d-md-inline-block">
              <ButtonGroup className="mb-2">
                <Button
                  
                  size="sm"
                  color={this.state.display==='daily'?'secondary':"light"}
                  type="button"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      display: 'daily', //setting state through display state
                    })
                  }
                >
                  Daily
                </Button>
                <Button
                  size="sm"
                  color={this.state.display==='weekly'?'secondary':"light"}
                  type="button"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      display: 'weekly', //setting state through display state
                    })
                  }
                >
                  Weekly
                </Button>
                <Button
                  size="sm"
                  color={this.state.display==='monthly'?'secondary':"light"}
                  type="button"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      display: 'monthly', //setting state through display state
                    })
                  }
                >
                  Monthly
                </Button>
              </ButtonGroup>
            </div>
            <h4 className="card-title mb-4">SF and RC Revenue</h4>

            <ReactApexChart
              options={
                //show chart data options based on conditions
                this.state.display === 'weekly'
                  ? this.state.weeklyOptions
                  : this.state.display === 'monthly'
                  ? this.state.monthlyOptions
                  : this.state.dailyOptions
              }
              series={
                //show chart series data based on conditions
                this.state.display === 'weekly'
                  ? this.state.weeklySeries
                  : this.state.display === 'monthly'
                  ? this.state.monthlySeries
                  : this.state.dailySeries
              }
              type="bar"
              height="280"
            />
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default DailySFRC;

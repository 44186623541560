import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  
} from 'reactstrap';
import { getToken, getUser, GKApi } from '../../../components/config';
import SweetAlert from 'react-bootstrap-sweetalert';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import moment from 'moment';
import TimePickerComponent from '../../../components/Common/TimePicker';
import { deviceInfo } from '../../../helpers/device_info_helper';

class ReferSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '#' },
        { title: 'Finance Refer Settings', link: '#' },
      ],
      referralBudgetChange: false,

      referralBudgetDefault: 0,

      referralBudget: 0,

      receiveValueChange: false,

      receiveValueDefault: 0,

      receiveValue: 0,
      receiveLimitChange: false,

      receiveLimitDefault: 0,

      receiveLimit: 0,
      customerPercentChange: false,

      customerPercentDefault: 0,

      customerPercent: 0,
      refererPercentChange: false,

      refererPercentDefault: 0,

      refererPercent: 0,
      minTrxAmountChange: false,

      minTrxAmountDefault: 0,

      minTrxAmount: 0,
      startDateChange: false,

      startDateDefault: null,

      startDate: null,
      endDateChange: false,

      endDateDefault: null,

      endDate: null,
referStatus:'',
referRequestId:'',
referApprovedId:'',
      lastApprovedData: {},
      lastRequestedData: {},
      success: '',
      custom_div1: false,
      tooltipOpen: false,
      modal_standard: false,
      modal_standard4: false,
      modal_standard5: false,
      deviceInfo: {},
      reason: '',
      canViewReferSettings: 1,
      referSettingsAuthStatForUser: 1,
      compAuthStat: 0,
      canAuthReferSettings: 0,
      canInsertReferSettings: 0,
    };
  }
  // modal toggles
  removeBodyCss = () => {
    document.body.classList.add('no_padding');
  };

  tog_standard = () => {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
      reason: '',
    }));
    this.removeBodyCss();
  };
  tog_standard4 = () => {
    this.setState((prevState) => ({
      modal_standard4: !prevState.modal_standard4,
      reason: '',
    }));
    this.removeBodyCss();
  };
  tog_standard5 = () => {
    this.setState((prevState) => ({
      modal_standard5: !prevState.modal_standard5,
      reason: '',
    }));
    this.removeBodyCss();
  };
  // api calls on page load

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();
    // const gId = getGroupId();

    //get request for input data
    const response = await GKApi.get('/finance/refer-settings', {
      headers: {
        Authorization: token,
        cmpId: '18',
      },
    });

    this.setState({
      referStatus:response.data.lastApproved?.status,
      referApprovedId:response.data.lastApproved?.recID,
      referRequestId:response.data.lastRequested?.recID,
      lastApprovedData: response.data.lastApproved?response.data.lastApproved:null,
      lastRequestedData: response.data.lastRequested,
    });
    
    //setting input data to wait

    if (this.state.lastRequestedData === null) {
      this.setState({
        referralBudget:this.state.lastApprovedData?.budget,
        referralBudgetDefault:this.state.lastApprovedData?.budget,
        receiveValueDefault: this.state.lastApprovedData?.receiveValue,
        receiveValue: this.state.lastApprovedData?.receiveValue,
        receiveLimitDefault: this.state.lastApprovedData?.receiveLimit,
        receiveLimit: this.state.lastApprovedData?.receiveLimit,
        customerPercentDefault: this.state.lastApprovedData?.customerPercent,
        customerPercent: this.state.lastApprovedData?.customerPercent,
        refererPercentDefault: this.state.lastApprovedData?.refererPercent,
        refererPercent: this.state.lastApprovedData?.refererPercent,
        minTrxAmountDefault: this.state.lastApprovedData?.minTrxAmount,
        minTrxAmount: this.state.lastApprovedData?.minTrxAmount,
        startDateDefault: this.state.lastApprovedData?.startDate,
        startDate: this.state.lastApprovedData?.startDate,
        endDateDefault: this.state.lastApprovedData?.endDate,
        endDate: this.state.lastApprovedData?.endDate,
      });
    } else {
      this.setState({
        referralBudgetDefault:this.state.lastApprovedData?.budget,
        referralBudget:this.state.lastRequestedData?.budget,
        receiveValueDefault: this.state.lastApprovedData?.receiveValue,
        receiveValue: this.state.lastRequestedData?.receiveValue,
        receiveLimitDefault: this.state.lastApprovedData?.receiveLimit,
        receiveLimit: this.state.lastRequestedData?.receiveLimit,
        customerPercentDefault: this.state.lastApprovedData?.customerPercent,
        customerPercent: this.state.lastRequestedData?.customerPercent,
        refererPercentDefault: this.state.lastApprovedData?.refererPercent,
        refererPercent: this.state.lastRequestedData?.refererPercent,
        minTrxAmountDefault: this.state.lastApprovedData?.minTrxAmount,
        minTrxAmount: this.state.lastRequestedData?.minTrxAmount,
        startDateDefault: this.state.lastApprovedData?.startDate,
        startDate: this.state.lastRequestedData?.startDate,
        endDateDefault: this.state.lastApprovedData?.endDate,
        endDate: this.state.lastRequestedData?.endDate,

        compAuthStat: this.state.lastRequestedData?.compAuthStat,
      });
    }

    const getPermission = await GKApi.get(`/role/permission?cmpId=18`, {
      headers: {
        Authorization: token,
      },
    });
    // getting permission data from api

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    // setting permissions to their states of their own

    this.setState({
      canViewReferSettings: permissionData[18].canView,
      referSettingsAuthStatForUser: permissionData[18].authState,
      canAuthReferSettings: permissionData[18].canAuth,
      canInsertReferSettings: permissionData[18].canInsert,
    });
    //function status api call

    // calling geo location api call for the device info
    const res = deviceInfo();
    this.setState({ deviceInfo: res });
  }

  //  change handlers

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  handleSelectedStartDateDefaultChange = (event) => {
    this.setState({ startDateDefault: event });
  };
  handleSelectedStartDateChange = (event) => {
    this.setState({ startDate: event });
  };

  handleSelectedEndDateDefaultChange = (event) => {
    this.setState({ endDateDefault: event });
  };
  handleSelectedEndDateChange = (event) => {
    this.setState({ endDate: event });
  };

  handleReceiveValueChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        receiveValue: event.target.value,
        receiveValueChange: true,
      });
    } else
      this.setState({
        ...this.state,
        receiveValue: '',
        receiveValueChange: false,
      });
  };

  handleReceiveLimitChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        receiveLimit: event.target.value,
        receiveLimitChange: true,
      });
    } else
      this.setState({
        ...this.state,
        receiveLimit: '',
        receiveLimitChange: false,
      });
  };

  handleCustomerPercentChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        customerPercent: event.target.value,
        customerPercentChange: true,
      });
    } else
      this.setState({
        ...this.state,
        customerPercent: '',
        customerPercentChange: false,
      });
  };

  handleRefererPercentChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        refererPercent: event.target.value,
        refererPercentChange: true,
      });
    } else
      this.setState({
        ...this.state,
        refererPercent: '',
        refererPercentChange: false,
      });
  };

  handleMinTrxAmountChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        minTrxAmount: event.target.value,
        minTrxAmountChange: true,
      });
    } else
      this.setState({
        ...this.state,
        minTrxAmount: '',
        minTrxAmountChange: false,
      });
  };

  handleReferralBudgetChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        referralBudget: event.target.value,
        referralBudgetChange: true,
      });
    } else
      this.setState({
        ...this.state,
        referralBudget: '',
        referralBudgetChange: false,
      });
  };

  handleReasonChange = (event) => {
    this.setState({ ...this.state, reason: event.target.value });
  };

  //change handlers

  handleSubmit = async (event) => {
    event.preventDefault();
    let sendData = {
      cmpId: 18,
      budget:this.state.referralBudget,
      receiveValue: this.state.receiveValue,
      receiveLimit: this.state.receiveLimit,
      customerPercent: this.state.customerPercent,
      refererPercent: this.state.refererPercent,
      minTrxAmount: this.state.minTrxAmount,
      startDate: moment(this.state.startDate, 'DD-MM-YYYY HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      endDate: moment(this.state.endDate, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
      deviceInfo: this.state.deviceInfo,
    };
    const token = getToken();
    let submitData = await GKApi.post('/finance/refer-settings', sendData, {
      headers: {
        Authorization: token,
        cmpId: '18',
      },
    });
    if (submitData) {
      this.setState({
        ...this.state,
        // success: 'Finance Inputs Updated Successfully',
        custom_div1: true,
      });
      setTimeout(() => {
        this.setState({ custom_div1: false });
      }, 5000);
    }
    const response = await GKApi.get('/finance/refer-settings', {
      headers: {
        Authorization: token,
        cmpId: '18',
      },
    });

    this.setState(
      {
        referStatus:response.data.lastApproved.status,
      referApprovedId:response.data.lastApproved.recID,
      referRequestId:response.data.lastRequested?.recID,
      lastApprovedData: response.data.lastApproved,
      lastRequestedData: response.data.lastRequested,
      },
      () => {}
    );
  };

  render() {
    const authDiff =
      this.state.referSettingsAuthStatForUser - this.state.compAuthStat;
    const totalReferPercentage =
      this.state.refererPercent + this.state.customerPercent;
    const loggedUser = getUser();
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Finance Refer Settings"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Col xl={12}>
              {/* finance inputs with the previous data and proposed data. */}
              {this.state.canViewReferSettings === 1 ? (

                <div>
                  {this.state.referStatus!=='stop'&&<Col
              xl={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              id="17"
            >
              <Card className="p-2" style={{ width: '50%' }}>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <h3
                        className="font-size-14 mb-3"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        Refer Status Change
                      </h3>
                      <div className="d-flex justify-content-center gap-5">
                        {this.state.referStatus !== 'play' && (
                          <i
                            className="fas fa-play font-size-40"
                            style={{
                              color: '#00FF00',
                            }}
                            onClick={async (event) => {
                              event.preventDefault();
  
                              this.setState({
                                status: 'play',
                                playPauseCampaignModal: true,
                              });
                            }}
                          ></i>
                        )}
                        {this.state.referStatus !== 'pause' && (
                          <i
                            className="fas fa-pause font-size-40"
                            style={{
                              color: '#ff0000',
                            }}
                            onClick={async (event) => {
                              event.preventDefault();
                              this.setState({
                                status: 'pause',
                                playPauseCampaignModal: true,
                              });
                            }}
                          ></i>
                        )}
                        {this.state.referStatus !== 'stop' && (
                          <i
                            className="fas fa-stop font-size-40"
                            style={{
                              color: '#ff0000',
                            }}
                            onClick={async (event) => {
                              event.preventDefault();
                              this.setState({
                                status: 'stop',
                                playPauseCampaignModal: true,
                              });
                            }}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <Modal
                    isOpen={this.state.playPauseCampaignModal}
                    toggle={this.tog_PlayPauseCampaign}
                  >
                    <ModalHeader
                      toggle={() => {
                        this.setState({ playPauseCampaignModal: false });
                      }}
                    >
                      Confirmation
                    </ModalHeader>
  
                    <ModalBody>
                      <Col xl={12}>
                        <Row className="mb-3 mx-2">
                          Are you sure you want to change refer status to {this.state.status}?
                        </Row>
                      </Col>
                    </ModalBody>
  
                    <ModalFooter>
                      <Button
                        type="button"
                        color="primary"
                        className="waves-effect waves-light"
                        onClick={async (event) => {
                          event.preventDefault();
                          const token = getToken();
                          const submit = await GKApi.patch(
                            `/finance/user-refer/play-pause/${this.state.referApprovedId}`,
                            {
                              status: this.state.status,
                              deviceInfo: this.state.deviceInfo,
                            },
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 167,
                              },
                            }
                          );
                          if (submit.status === 200) {
                            const response = await GKApi.get('/finance/refer-settings', {
                              headers: {
                                Authorization: token,
                                cmpId: '18',
                              },
                            });
                        
                            this.setState({
                              referStatus:response.data.lastApproved.status,
                              referApprovedId:response.data.lastApproved.recID,
                              referRequestId:response.data.lastRequested?.recID,
                              lastApprovedData: response.data.lastApproved,
                              lastRequestedData: response.data.lastRequested,
                            });
                            this.setState((prevState) => ({
                              playPauseCampaignModal:
                                !prevState.playPauseCampaignModal,
                            }));
                            this.removeBodyCss();
                          }
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        type="button"
                        onClick={this.tog_PlayPauseCampaign}
                        color="light"
                        className="waves-effect"
                      >
                        No
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>}
                  <Form onSubmit={this.handleSubmit} id="18">
                    <FormGroup>
                      <Row>
                        <Col>
                          <Card>
                            <CardBody>
                              <h4 className="card-title">
                                Finance Refer Settings
                              </h4>
                              <p className="card-title-desc">
                                Please input Finance Refer Settings values here.
                              </p>
                              <div className="d-flex mb-3">
                                <Col xs={4}></Col>
                                <Col xs={4}>
                                  <h5>Present Value</h5>
                                </Col>
                                <Col xs={4}>
                                  <h5>Proposed Value</h5>
                                </Col>
                              </div>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="gold-price-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Referral Budget (in bdt){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    
                                    value={this.state.referralBudgetDefault}
                                    placeholder="Please input the value for receive limit"
                                    id="gold-price-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.referralBudget}
                                    placeholder="Please input the value for receive limit"
                                    id="gold-price-input"
                                    onChange={this.handleReferralBudgetChange}
                                    style={{
                                      backgroundColor:
                                        this.state.referralBudgetChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthReferSettings === 1 ||
                                        this.state.canInsertReferSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              
                              <Row className="mb-3">
                                <Label
                                  htmlFor="gold-price-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Offer Value (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.receiveValueDefault}
                                    placeholder="Please input the value for Daily Gold Price"
                                    id="gold-price-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.receiveValue}
                                    placeholder="Please input the value for Receive value"
                                    id="gold-price-input"
                                    onChange={this.handleReceiveValueChange}
                                    style={{
                                      backgroundColor:
                                        this.state.receiveValueChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthReferSettings === 1 ||
                                        this.state.canInsertReferSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="gold-price-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Single Transaction Cap (in bdt){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.receiveLimitDefault}
                                    placeholder="Please input the value for receive limit"
                                    id="gold-price-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.receiveLimit}
                                    placeholder="Please input the value for receive limit"
                                    id="gold-price-input"
                                    onChange={this.handleReceiveLimitChange}
                                    style={{
                                      backgroundColor:
                                        this.state.receiveLimitChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthReferSettings === 1 ||
                                        this.state.canInsertReferSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="gold-price-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Customer Percent (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.customerPercentDefault}
                                    placeholder="Please input the value for Daily Gold Price"
                                    id="gold-price-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.customerPercent}
                                    placeholder="Please input the value for Daily Gold Price"
                                    id="gold-price-input"
                                    onChange={this.handleCustomerPercentChange}
                                    style={{
                                      backgroundColor:
                                        this.state.customerPercentChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthReferSettings === 1 ||
                                        this.state.canInsertReferSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="gold-price-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Referer Percent (in %){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.refererPercentDefault}
                                    placeholder="Please input the value for Gift Fee Vat"
                                    id="gold-price-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.refererPercent}
                                    placeholder="Please input the value for Gift Fee Vat"
                                    id="gold-price-input"
                                    onChange={this.handleRefererPercentChange}
                                    style={{
                                      backgroundColor:
                                        this.state.refererPercentChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthReferSettings === 1 ||
                                        this.state.canInsertReferSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="gold-price-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Minimum Transaction Amount to activate referral (in bdt){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.minTrxAmountDefault}
                                    placeholder="Please input the value for Bkash Settlement Fee"
                                    id="gold-price-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.minTrxAmount}
                                    placeholder="Please input the value for Bkash Settlement Fee"
                                    id="gold-price-input"
                                    onChange={this.handleMinTrxAmountChange}
                                    style={{
                                      backgroundColor:
                                        this.state.minTrxAmountChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthReferSettings === 1 ||
                                        this.state.canInsertReferSettings ===
                                          1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="gold-price-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Referral Start Date{' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Col xl={6}>
                                    <div
                                      style={{
                                        border: '1px solid',
                                        borderRadius: '5px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        paddingRight: '4px',
                                        paddingLeft: '4px',
                                        pointerEvents: 'none',
                                      }}
                                      className="d-flex gap-3 align-content-center"
                                    >
                                      <TimePickerComponent
                                        selectedDate={this.state.startDateDefault}
                                        handleSelectedDateChange={
                                          this
                                            .handleSelectedStartDateDefaultChange
                                        }
                                        disabled
                                      />
                                    </div>
                                  </Col>
                                  <Col xl={5}>
                                    <div
                                      style={{
                                        border: '1px solid',
                                        borderRadius: '5px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        paddingRight: '5px',
                                        paddingLeft: '5px',
                                      }}
                                      className="d-flex d-grid gap-3"
                                    >
                                      <TimePickerComponent
                                        selectedDate={this.state.startDate}
                                        handleSelectedDateChange={
                                          this.handleSelectedStartDateChange
                                        }
                                        className="mx-3"
                                      />
                                    </div>
                                  </Col>
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="gold-price-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Referral End Date{' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Col xl={6}>
                                    <div
                                      style={{
                                        border: '1px solid',
                                        borderRadius: '5px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        paddingRight: '4px',
                                        paddingLeft: '4px',
                                        pointerEvents: 'none',
                                      }}
                                      className="d-flex gap-3 align-content-center"
                                    >
                                      <TimePickerComponent
                                        selectedDate={this.state.endDateDefault}
                                        handleSelectedDateChange={
                                          this.handleSelectedEndDateDefaultChange
                                        }
                                        disabled
                                      />
                                    </div>
                                  </Col>
                                  <Col xl={5}>
                                    <div
                                      style={{
                                        border: '1px solid',
                                        borderRadius: '5px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        paddingRight: '5px',
                                        paddingLeft: '5px',
                                      }}
                                      className="d-flex d-grid gap-3"
                                    >
                                      <TimePickerComponent
                                        selectedDate={this.state.endDate}
                                        handleSelectedDateChange={
                                          this.handleSelectedEndDateChange
                                        }
                                        className="mx-3"
                                      />
                                    </div>
                                  </Col>
                                </Col>
                              </Row>
                              {/* successful submit sweet alert */}
                              <Row
                                className="d-flex justify-content-start"
                                style={{ fontSize: '20px', color: 'darkorange' }}
                              >
                                {this.state.custom_div1 ? (
                                  <SweetAlert
                                    title="Request successfully submitted for review!!"
                                    timeout={2500}
                                    style={{
                                      top: '0px',
                                      right: '0px',
                                    }}
                                    showCloseButton={false}
                                    showConfirm={false}
                                    success
                                    onConfirm={() =>
                                      this.setState({ custom_div1: false })
                                    }
                                  ></SweetAlert>
                                ) : null}
                              </Row>
                              {/* successful submit sweet alert */}
                              
  
                              {/* submit based on component auth state, user auth state, auth state difference */}
                              <Row
                                className="d-flex justify-content-end mx-1"
                                id="foo"
                              >
                                {/* auth permission check for maker  */}
                                {this.state.referSettingsAuthStatForUser === 1 &&
                                this.state.lastRequestedData === null ? (
                                  <Button
                                    type="submit"
                                    style={{
                                      width: '35%',
                                    }}
                                    disabled={
                                      totalReferPercentage >
                                      100 
                                        ? true
                                        : false
                                    }
                                  >
                                    Submit
                                  </Button>
                                ) : this.state.referSettingsAuthStatForUser ===
                                    1 && this.state.lastRequestedData != null ? (
                                  <Button
                                    style={{
                                      width: '35%',
                                    }}
                                    disabled
                                  >
                                    You have already a pending request
                                  </Button>
                                ) : //  auth permission check for maker
  
                                // auth permission check for checker
                                this.state.referSettingsAuthStatForUser === 2 &&
                                  this.state.lastRequestedData != null &&
                                  authDiff === 1 ? (
                                    this.state.referStatus==='stop'&&
                                    <div className="d-flex justify-content-between my-5">
                                    <Button
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({ modal_standard4: true });
                                      }}
                                    >
                                      Approve
                                    </Button>
  
                                    {/*
                                     * @desc modal for APPROVING request for the CHECKER STARTS here
                                     */}
  
                                    <Modal
                                      isOpen={this.state.modal_standard4}
                                      toggle={this.tog_standard4}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard4: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>
  
                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want Approve this
                                            request?
                                          </Row>
                                        </Col>
                                      </ModalBody>
  
                                      <ModalFooter>
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="waves-effect waves-light"
                                          onClick={async (event) => {
                                            event.preventDefault();
                                            const token = getToken();
                                            let submitData = {
                                              cmpId: 18,
                                              recID:
                                                this.state.lastRequestedData
                                                  ?.recID,
                                              deviceInfo: this.state.deviceInfo,
                                            };
                                            await GKApi.put(
                                              `/finance/refer-settings/approve`,
                                              submitData,
                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );
                                            const response = await GKApi.get(
                                              '/finance/refer-settings',
                                              {
                                                headers: {
                                                  Authorization: token,
                                                  cmpId: '18',
                                                },
                                              }
                                            );
  
                                            this.setState({
                                              referStatus:response.data.lastApproved.status,
      referApprovedId:response.data.lastApproved.recID,
      referRequestId:response.data.lastRequested?.recID,
      lastApprovedData: response.data.lastApproved,
      lastRequestedData: response.data.lastRequested,
                                              compAuthStat:
                                                response.data.lastRequested
                                                  .compAuthStat,
                                            });
                                            this.tog_standard4();
                                          }}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          type="button"
                                          onClick={this.tog_standard4}
                                          color="light"
                                          className="waves-effect"
                                        >
                                          No
                                        </Button>
                                      </ModalFooter>
                                    </Modal>
  
                                    <Button
                                      type="submit"
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({ modal_standard5: true });
                                      }}
                                    >
                                      Reject
                                    </Button>
  
                                    {/*
                                     * @desc modal for REJECTING request for the CHECKER STARTS here
                                     */}
                                    <Modal
                                      isOpen={this.state.modal_standard5}
                                      toggle={this.tog_standard5}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard5: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>
  
                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want to Reject this
                                            request?
                                          </Row>
                                          <Row className="mb-3 mx-2">
                                            <Input
                                              type="text"
                                              // oninput={validate(this)}
                                              value={this.state.reason}
                                              placeholder="Please specify the reason"
                                              id="gold-price-input"
                                              onChange={this.handleReasonChange}
                                            />
                                          </Row>
                                        </Col>
                                      </ModalBody>
  
                                      {this.state.reason === '' ? (
                                        ''
                                      ) : (
                                        <ModalFooter>
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="waves-effect waves-light"
                                            onClick={async (event) => {
                                              event.preventDefault();
  
                                              const token = getToken();
                                              let submitData = {
                                                cmpId: 18,
  
                                                recID:
                                                  this.state.lastRequestedData
                                                    .recID,
  
                                                deviceInfo: this.state.deviceInfo,
                                                reason: this.state.reason,
                                              };
                                              await GKApi.put(
                                                `finance/refer-settings/reject`,
                                                submitData,
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                  },
                                                }
                                              );
                                              const response = await GKApi.get(
                                                '/finance/refer-settings',
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                    cmpId: '18',
                                                  },
                                                }
                                              );
  
                                              this.setState({
                                                referStatus:response.data.lastApproved.status,
      referApprovedId:response.data.lastApproved.recID,
      referRequestId:response.data.lastRequested?.recID,
      lastApprovedData: response.data.lastApproved,
      lastRequestedData: response.data.lastRequested,
                                                compAuthStat:
                                                  response.data.lastRequested
                                                    .compAuthStat,
                                              });
                                              this.tog_standard5();
                                            }}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            type="button"
                                            onClick={this.tog_standard5}
                                            color="light"
                                            className="waves-effect"
                                          >
                                            No
                                          </Button>
                                        </ModalFooter>
                                      )}
                                    </Modal>
                                  </div>
                                ) : this.state.referSettingsAuthStatForUser ===
                                    2 && authDiff === 0 ? (
                                  <h4 style={{ textAlign: 'center' }}>
                                    The request has been reviewed by Checker
                                  </h4>
                                ) : // auth permission check for checker
  
                                // auth permission check for authorizer
                                this.state.referSettingsAuthStatForUser === 3 &&
                                  this.state.lastRequestedData !== null &&
                                  (this.state.lastRequestedData?.entryStaffID !==
                                    loggedUser ||
                                    this.state.lastRequestedData
                                      ?.lastAuthorizerID !== loggedUser) ? (
                                        this.state.referStatus==='stop'&&<div className="d-flex justify-content-between my-5">
                                    <Button
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({ modal_standard4: true });
                                        
                                      }}
                                    >
                                      Authorize
                                    </Button>
                                    {/*
                                     * @desc modal for AUTHORIZING request for the AUTHORIZER STARTS here
                                     */}
                                    <Modal
                                      isOpen={this.state.modal_standard4}
                                      toggle={this.tog_standard4}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard4: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>
  
                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want Authorize this
                                            request?
                                          </Row>
                                        </Col>
                                      </ModalBody>
  
                                      <ModalFooter>
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="waves-effect waves-light"
                                          onClick={async (event) => {
                                            event.preventDefault();
                                            const token = getToken();
                                            let submitData = {
                                              cmpId: 18,
                                              recID:
                                                this.state.lastRequestedData
                                                  .recID,
  
                                              deviceInfo: this.state.deviceInfo,
                                            };
                                            await GKApi.put(
                                              `finance/refer-settings/approve`,
                                              submitData,
                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );
                                            const response = await GKApi.get(
                                              '/finance/refer-settings',
                                              {
                                                headers: {
                                                  Authorization: token,
                                                  cmpId: '18',
                                                },
                                              }
                                            );
                                            this.setState({
                                              referStatus:response.data.lastApproved.status,
      referApprovedId:response.data.lastApproved.recID,
      referRequestId:response.data.lastRequested?.recID,
      lastApprovedData: response.data.lastApproved,
      lastRequestedData: response.data.lastRequested,
                                            });
                                            this.tog_standard4();
                                          }}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          type="button"
                                          onClick={this.tog_standard4}
                                          color="light"
                                          className="waves-effect"
                                        >
                                          No
                                        </Button>
                                      </ModalFooter>
                                    </Modal>
  
                                    <Button
                                      type="submit"
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({ modal_standard5: true });
                                      }}
                                    >
                                      Reject
                                    </Button>
                                    {/*
                                     * @desc modal for REJECTING request for the AUTHORIZER STARTS here
                                     */}
                                    <Modal
                                      isOpen={this.state.modal_standard5}
                                      toggle={this.tog_standard5}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard5: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>
  
                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want to Reject this
                                            request?
                                          </Row>
                                          <Row className="mb-3 mx-2">
                                            <Input
                                              type="text"
                                              // oninput={validate(this)}
                                              value={this.state.reason}
                                              placeholder="Please specify the reason"
                                              id="gold-price-input"
                                              onChange={this.handleReasonChange}
                                            />
                                          </Row>
                                        </Col>
                                      </ModalBody>
  
                                      {this.state.reason === '' ? (
                                        ''
                                      ) : (
                                        <ModalFooter>
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="waves-effect waves-light"
                                            onClick={async (event) => {
                                              event.preventDefault();
  
                                              const token = getToken();
                                              let submitData = {
                                                cmpId: 18,
  
                                                recID:
                                                  this.state.lastRequestedData
                                                    .recID,
  
                                                deviceInfo: this.state.deviceInfo,
                                                reason: this.state.reason,
                                              };
                                              await GKApi.put(
                                                `finance/refer-settings/reject`,
                                                submitData,
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                  },
                                                }
                                              );
                                              const response = await GKApi.get(
                                                '/finance/refer-settings',
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                    cmpId: '18',
                                                  },
                                                }
                                              );
  
                                              this.setState({
                                                referStatus:response.data.lastApproved.status,
      referApprovedId:response.data.lastApproved.recID,
      referRequestId:response.data.lastRequested?.recID,
      lastApprovedData: response.data.lastApproved,
      lastRequestedData: response.data.lastRequested,
                                              });
                                              this.tog_standard5();
                                            }}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            type="button"
                                            onClick={this.tog_standard5}
                                            color="light"
                                            className="waves-effect"
                                          >
                                            No
                                          </Button>
                                        </ModalFooter>
                                      )}
                                    </Modal>
                                  </div>
                                ) : this.state.referSettingsAuthStatForUser ===
                                    3 && this.state.lastRequestedData === null ? (
                                  <Button
                                    type="submit"
                                    style={{
                                      width: '35%',
                                    }}
                                  >
                                    Submit
                                  </Button>
                                ) : this.state.lastRequestedData?.entryStaffID ===
                                    loggedUser ||
                                  this.state.lastRequestedData
                                    ?.lastAuthorizerID === loggedUser ? (
                                  <h4>
                                    You have already authorized this request
                                  </h4>
                                ) : (
                                  ''
                                )}
                                <Tooltip
                                  target="foo"
                                  isOpen={this.state.tooltipOpen}
                                  toggle={this.toggle}
                                >
                                  Be careful!!! Its going to update the database
                                  directly
                                </Tooltip>
                              </Row>
                              {/* submit based on component auth state, user auth state, auth state difference */}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </div>
              ) : (
                <></>
              )}
            </Col>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ReferSettings;

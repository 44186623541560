import React, { Component } from 'react';
import MaterialTable from 'material-table';

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { roundToTwo } from '../../../components/config';

// Table data

class BuyTrx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'CRM', link: '#' },
        { title: 'User List', link: '#' },
        { title: 'Buy History', link: '#' },
      ],
      visible: false,
      refundData: {},
      modal_standard: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();
    };
    const columns = [
      {
        field: 'slNo',
        title: 'SL No',
      },
      {
        field: 'transactionReference',
        title: 'Transaction Reference No',
      },
      {
        field: 'buyOrderID',
        title: 'Buy Order ID',
      },
      {
        field: 'transactionDate',
        title: 'Transaction Date',
      },
      {
        field: 'transactionTime',
        title: 'Transaction Time',
      },
      {
        field: 'perUnitPrice',
        title: ' Per Unit Price (Live market Price)',
      },
      {
        field: 'buyType',
        title: 'Buy Type',
      },
      {
        field: 'buyAmountInGoldGM',
        title: 'Buy Gold (Gm)',
        render: (row) => <div>{row.buyAmountInGoldGM.toFixedDown(4)}</div>,
      },
      {
        field: 'amountOfVatOnBuy',
        title: 'Amount of VAT on Buy',
        render: (row) => <div>{roundToTwo(row.amountOfVatOnBuy)}</div>,
      },
      {
        field: 'serviceCharge',
        title: 'Service Charge',
        render: (row) => <div>{roundToTwo(row.serviceCharge)}</div>,
      },

      {
        field: 'bonusAmount',
        title: 'Bonus Amount',
      },
      {
        field: 'goldBack',
        title: 'Gold Back',
        render: (row) => <div>{row.goldBack.toFixedDown(4)}</div>,
      },
      {
        field: 'promoAmount',
        title: 'Promo Amount',
      },
      {
        field: 'totalBuyAmount',
        title: 'Total Buy Amount',
        render: (row) => <div>{roundToTwo(row.totalBuyAmount)}</div>,
      },
      {
        field: 'paymentMedium',
        title: 'Payment Medium',
      },

      {
        field: 'totalBuyAmountIncludingVatAndSF',
        title: 'Total Buy Amount Including Vat And SF',
        render: (row) => (
          <div>{roundToTwo(row.totalBuyAmountIncludingVatAndSF)}</div>
        ),
      },
      {
        field: 'netBuyAmount',
        title: 'Net Buy Amount',
        render: (row) => <div>{roundToTwo(row.netBuyAmount)}</div>,
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="User-Buy transaction history"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <MaterialTable
              title={'Buy transaction history'}
              columns={columns}
              data={this.props.location.state.data}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                pageSize: 20,
                columnsButton: true,
              }}
            />
            <Modal
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_standard: false })}
              >
                User Edit
              </ModalHeader>
              <ModalBody>
                {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Name
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.name}
                      id="example-name-input"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Position
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.position}
                      id="example-position-input"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Salary
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.salary}
                      id="example-salary-input"
                    />
                  </Col>
                </Row>
                <Button className="float-end">Update</Button>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={this.tog_standard}
                  color="light"
                  className="waves-effect"
                >
                  Close
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                >
                  Save changes
                </Button>
              </ModalFooter>
            </Modal>
            ;
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BuyTrx;

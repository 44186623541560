import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Frame from './../../images/Frame.png';
import {
  getToken,
  GKApi,
  setAccessToken,
  setLoggedUser,
  setLoggedUserGroupId,
  setLoggedUserGroupName,
} from '../../components/config';
import { useDispatch } from 'react-redux';
import { checkLogin } from '../../store/actions';

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const dispatch = useDispatch();
    return <Component {...props} dispatch={dispatch} />;
  };
}

const Signin = (props) => {
  const [email, setEmail] = useState('');
  const [forgetPassEmail, setForgetPassEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [modal, setModal] = useState(false);
  const [forgetPasswordModal, setForgetPasswordModal] = useState(false);
  const [user, setUser] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');

  const removeBodyCss = () => {
    document.body.classList.add('no_padding');
  };
  const togStandard = () => {
    setModal(!modal);
    removeBodyCss();
  };

  const togForgetPassword = () => {
    setForgetPasswordModal(!forgetPasswordModal);
    removeBodyCss();
  };
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleNewPasswordVisibility = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    Auth.signIn(email, password)
      .then(async (user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUser(user);
          setModal(true);
        } else if (user) {
          setSubmitting(true);
          setLoggedUser(user.username);

          setAccessToken(user.signInUserSession.accessToken.jwtToken);
          const dispatch = props.dispatch;
          const token = getToken();
          // GET request using axios with async/await
          const response = await GKApi.get(`/staff/logged-in-user`, {
            headers: {
              Authorization: token,
            },
          });

          dispatch(checkLogin(response.data));
          setLoggedUserGroupId(response.data.groupId);

          setLoggedUserGroupName(response.data.groupName);

          props.history.push('/user-profile');
        }
      })
      .catch((e) => {
        setError(
          'Invalid username or password!!! Please check your password and email!!'
        );
      });
  };

  const passwordSubmit = (event) => {
    event.preventDefault();

    Auth.completeNewPassword(
      user, // the Cognito User Object
      newPassword // the new password
    )
      .then((user) => {
        // at this time the user is logged in if no MFA required
        setModal(false);
        setPassword('');
        props.history.push('/signin');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const forgetPasswordSubmit = (event) => {
    event.preventDefault();
    console.log(confirmationCode);
    Auth.forgotPasswordSubmit(forgetPassEmail, confirmationCode, newPassword)
      .then((user) => {
        setForgetPasswordModal(false);
        setPassword('');
        props.history.push('/signin');
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <section className="vh-80">
      <div
        className="container d-flex align-items-center justify-content-center"
        style={{
          height: '100vh',
        }}
      >
        <div className="col col-xl-8">
          <div className="card">
            <div className="row g-0">
              <div className="col-md-6 col-lg-5 d-flex align-items-center justify-content-center px-5">
                <img src={Frame} alt="login form" className="img-fluid" />
              </div>
              <div className="col-md-6 col-lg-7 d-flex align-items-center">
                <div className="card-body p-4 p-lg-5 text-black">
                  <form onSubmit={onSubmit}>
                    <div className="d-flex align-items-center mb-3 pb-1">
                      {/* <img
                        src={FramVertical}
                        alt="login form"
                        className="img-fluid"
                        style={{
                          height: '50px',
                          // width: '50px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      /> */}
                      {/* <h1
                        className="h1 fw-bold mb-0"
                        style={{ color: '#c2b56f' }}
                      >
                        GOLD{' '}
                        <span
                          className="text-transparent text-purple-900"
                          style={{ color: '#081939' }}
                        >
                          KINEN
                        </span>
                      </h1> */}
                    </div>

                    <h5 className="fw-normal mb-3 pb-3">
                      Login into your account
                    </h5>
                    <div className="form-outline mb-4">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="E-mail"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        pattern="/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm"
                        required
                      />
                    </div>
                    <div
                      className="form-outline mb-4 d-flex justify-content-between align-items-center"
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: '7px',
                      }}
                    >
                      <input
                        className="form-control form-control-lg"
                        placeholder="Password"
                        id="psw"
                        value={password}
                        type={passwordShown ? 'text' : 'password'}
                        onChange={(event) => setPassword(event.target.value)}
                        style={{ border: 0 }}
                        // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-:?.><()/|~]).{8,30}$"
                        // title="Must contain at least one number and one uppercase and one lowercase letter and one special character, and at least 8 or more characters"
                        required
                      />
                      <span className="py-1 px-3">
                        <div
                          className="cursor-pointer"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordShown ? (
                            <i
                              className="fas fa-eye cursor-pointer"
                              style={{ color: '#c2b56f' }}
                            />
                          ) : (
                            <i
                              className="fas fa-eye-slash cursor-pointer"
                              style={{ color: '#c2b56f' }}
                            />
                          )}
                        </div>
                      </span>
                    </div>

                    <div
                      className="d-flex justify-content-end text-decoration-underline"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setForgetPasswordModal(true);
                      }}
                    >
                      {' '}
                      Forgot password? Click Here
                    </div>

                    <div className="form-outline mb-4">
                      <p className="py-2">{error !== '' && error}</p>
                      <button
                        className="form-control form-control-lg fw-bold"
                        type="submit"
                        style={{ color: '#081939', backgroundColor: '#c2b56f' }}
                        disabled={submitting ? true : false}
                      >
                        {submitting && (
                          <span className="spinner-grow spinner-grow-sm"></span>
                        )}
                        {submitting ? 'Logging in' : 'Log in'}
                      </button>
                    </div>
                  </form>
                  <Modal isOpen={modal} toggle={togStandard}>
                    <ModalHeader toggle={() => setModal(true)} charCode="close">
                      Change password
                    </ModalHeader>
                    <form onSubmit={passwordSubmit}>
                      <ModalBody>
                        {/* <div className="form-outline mb-4">
                          <input
                            className="form-control form-control-lg"
                            value={newPassword}
                            onChange={(event) =>
                              setNewPassword(event.target.value)
                            }
                          />
                        </div> */}
                        <div
                          className="form-outline mb-4 d-flex justify-content-between align-items-center"
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRadius: '7px',
                          }}
                        >
                          <input
                            className="form-control form-control-lg"
                            placeholder="New Password"
                            id="psw"
                            value={newPassword}
                            type={newPasswordShown ? 'text' : 'password'}
                            onChange={(event) =>
                              setNewPassword(event.target.value)
                            }
                            style={{ border: 0 }}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-:?.><()/|~]).{8,30}$"
                            title="Must contain at least one number and one uppercase and one lowercase letter and one special character, and at least 8 or more characters"
                            required
                          />
                          <span className="py-1 px-3">
                            <div
                              className="cursor-pointer"
                              onClick={toggleNewPasswordVisibility}
                            >
                              {newPasswordShown ? (
                                <i
                                  className="fas fa-eye cursor-pointer"
                                  style={{ color: '#c2b56f' }}
                                />
                              ) : (
                                <i
                                  className="fas fa-eye-slash cursor-pointer"
                                  style={{ color: '#c2b56f' }}
                                />
                              )}
                            </div>
                          </span>
                        </div>
                        <p className="font-size-12">
                          * Password must contain at least one number and one
                          uppercase and one lowercase letter and one special
                          character, and at least 8 or more characters
                        </p>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          type="submit"
                          color="primary"
                          className="waves-effect waves-light"
                        >
                          Change Password
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          className="waves-effect waves-light"
                          onClick={() => togStandard()}
                        >
                          Close
                        </Button>
                      </ModalFooter>
                    </form>
                  </Modal>

                  <Modal
                    isOpen={forgetPasswordModal}
                    toggle={togForgetPassword}
                  >
                    <ModalHeader
                      toggle={() => setForgetPasswordModal(false)}
                      charCode="close"
                    >
                      Forget Password
                    </ModalHeader>
                    <form onSubmit={forgetPasswordSubmit}>
                      <ModalBody>
                        <h6 className="mb-4">
                          Please contact Tech team for confirmation code
                        </h6>

                        <div className="form-outline mb-4">
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            placeholder="E-mail"
                            value={forgetPassEmail}
                            onChange={(event) =>
                              setForgetPassEmail(event.target.value)
                            }
                            pattern="/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm"
                            required
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Confirmation code sent by admin"
                            value={confirmationCode}
                            onChange={(event) =>
                              setConfirmationCode(event.target.value)
                            }
                            required
                          />
                        </div>
                        <div
                          className="form-outline mb-4 d-flex justify-content-between align-items-center"
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRadius: '7px',
                          }}
                        >
                          <input
                            className="form-control form-control-lg"
                            placeholder="New Password"
                            id="psw"
                            value={newPassword}
                            type={newPasswordShown ? 'text' : 'password'}
                            onChange={(event) =>
                              setNewPassword(event.target.value)
                            }
                            style={{ border: 0 }}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-:?.><()/|~]).{8,30}$"
                            title="Must contain at least one number and one uppercase and one lowercase letter and one special character, and at least 8 or more characters"
                            required
                          />
                          <span className="py-1 px-3">
                            <div
                              className="cursor-pointer"
                              onClick={toggleNewPasswordVisibility}
                            >
                              {newPasswordShown ? (
                                <i
                                  className="fas fa-eye cursor-pointer"
                                  style={{ color: '#c2b56f' }}
                                />
                              ) : (
                                <i
                                  className="fas fa-eye-slash cursor-pointer"
                                  style={{ color: '#c2b56f' }}
                                />
                              )}
                            </div>
                          </span>
                        </div>
                        <p className="font-size-12">
                          * Password must contain at least one number and one
                          uppercase and one lowercase letter and one special
                          character, and at least 8 or more characters
                        </p>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          type="submit"
                          color="primary"
                          className="waves-effect waves-light"
                        >
                          Change Password
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          className="waves-effect waves-light"
                          onClick={() => togForgetPassword()}
                        >
                          Close
                        </Button>
                      </ModalFooter>
                    </form>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withMyHook(Signin);

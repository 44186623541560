import React, { Component } from 'react';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
import { Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
//Import Breadcrumb
// import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { getToken, GKApi, roundToTwo } from '../../../components/config';
import moment from 'moment';

// Table data

class BuyReportMKT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Marketing', link: '#' },
        { title: 'Reports and insights', link: '#' },
        { title: 'Buy Report', link: '#' },
      ],
      isLoading: false,
      visible: false,
      modal_standard: false,
      data: this.props.data,
      pageNumber: 1,
      pageSize: this.props.pageSize,
      totalPageCount: this.props.totalPage,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  // componentDidMount(){
  //   this.props.data.length>0 && this.setState({data:this.props.data})
  // }

  render() {
    const columns = [
      {
        field: ['Customer ID'],
        title: 'Customer ID',
        render: (row) => (
          <div>
            {row['Customer ID'] === ' ' ? 'Not available' : row['Customer ID']}
          </div>
        ),
      },
      {
        field: ['User Name'],
        title: 'User Name',
        render: (row) => (
          <div>
            {row['User Name'] === ' ' ? 'Not available' : row['User Name']}
          </div>
        ),
      },
      {
        field: ['Mobile No.'],
        title: 'Mobile No.',
      },
      {
        field: ['Email'],
        title: 'Email',
      },
      {
        field: ['Age'],
        title: 'Age',
      },
      {
        field: ['Gender'],
        title: 'Gender',
      },
      {
        field: ['Marital Status'],
        title: 'Marital Status',
      },

      {
        field: ['Thana'],
        title: 'Thana',
      },
      {
        field: ['District'],
        title: 'District',
      },
      {
        field: ['Occupation'],
        title: 'Occupation',
      },

      {
        field: ['Join Date'],
        title: 'Join Date',
      },
      {
        field: ['User Type'],
        title: 'User Type',
      },
      {
        field: ['User Activity Type'],
        title: 'User Activity Type',
      },
      {
        field: ['Buy Date'],
        title: 'Buy Date',
        render: (row) =>
          row['Buy Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['Buy Date']).format('DD-MM-YYYY')}</div>
          ),
      },
      {
        field: ['Per Unit Price'],
        title: 'Per Unit Price (Live market Price)',
      },

      {
        field: ['Buy Gold Amount in Qty'],
        title: 'Buy Gold Amount in Qty',
        render: (row) =>
          row['Buy Gold Amount in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Buy Gold Amount in Qty'])}</div>
          ),
      },

      {
        field: ['Buy Gold Amount in Taka'],
        title: 'Buy Gold Amount in Taka',
        render: (row) =>
          row['Buy Gold Amount in Taka'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Buy Gold Amount in Taka'])}</div>
          ),
      },

      {
        field: ['Payment Medium'],
        title: 'Payment Medium',
      },

      {
        field: ['Campaign Promo Title'],
        title: 'Campaign Promo Title',
      },
      {
        field: ['Campaign Promo Code'],
        title: 'Campaign Promo Code',
      },
      {
        field: ['Campaign Promo Amount'],
        title: 'Campaign Promo Amount',
      },

      {
        field: ['Campaign Bonus Title'],
        title: 'Campaign Bonus Title',
      },

      {
        field: ['Campaign Bonus Amount'],
        title: 'Campaign Bonus Amount',
      },
      {
        field: ['Last Login Date'],
        title: 'Last Login Date',
        render: (row) =>
          row['Last Login Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['Last Login Date']).format('DD-MM-YYYY')}</div>
          ),
      },
    ];

    const summaryColumns = [
      {
        field: ['Customer ID'],
        title: 'Customer ID',
        render: (row) => (
          <div>
            {row['Customer ID'] === ' ' ? 'Not available' : row['Customer ID']}
          </div>
        ),
      },
      {
        field: ['User Name'],
        title: 'User Name',
        render: (row) => (
          <div>
            {row['User Name'] === ' ' ? 'Not available' : row['User Name']}
          </div>
        ),
      },
      {
        field: ['Mobile No.'],
        title: 'Mobile No.',
      },
      {
        field: ['Email'],
        title: 'Email',
      },
      {
        field: ['Age'],
        title: 'Age',
      },
      {
        field: ['Gender'],
        title: 'Gender',
      },
      {
        field: ['Marital Status'],
        title: 'Marital Status',
      },
      {
        field: ['Thana'],
        title: 'Thana',
      },
      {
        field: ['District'],
        title: 'District',
      },
      {
        field: ['Occupation'],
        title: 'Occupation',
      },
      {
        field: ['Join Date'],
        title: 'Join Date',
      },
      {
        field: ['User Type'],
        title: 'User Type',
      },
      {
        field: ['User Activity Type'],
        title: 'User Activity Type',
      },
      {
        field: ['Trx Count'],
        title: 'Trx Count',
      },
      {
        field: ['Buy Gold Amount in Qty'],
        title: 'Buy Gold Amount in Qty',
        render: (row) =>
          row['Buy Gold Amount in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Buy Gold Amount in Qty'])}</div>
          ),
      },
      {
        field: ['Buy Gold Amount in Taka'],
        title: 'Buy Gold Amount in Taka',
        render: (row) =>
          row['Buy Gold Amount in Taka'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Buy Gold Amount in Taka'])}</div>
          ),
      },

      {
        field: ['First Buy Date'],
        title: 'First Buy Date',
        render: (row) =>
          row['First Buy Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['First Buy Date']).format('DD-MM-YYYY')}</div>
          ),
      },
      {
        field: ['First Buy Trx Qty'],
        title: 'First Buy Trx Qty',
        render: (row) =>
          row['First Buy Trx Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['First Buy Trx Qty'])}</div>
          ),
      },
      {
        field: ['First Buy Trx Amount'],
        title: 'First Buy Trx Amount',
        render: (row) =>
          row['First Buy Trx Amount'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['First Buy Trx Amount'])}</div>
          ),
      },
      {
        field: ['Last Buy Date'],
        title: 'Last Buy Date',
        render: (row) =>
          row['Last Buy Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['Last Buy Date']).format('DD-MM-YYYY')}</div>
          ),
      },
      {
        field: ['Last Buy Trx Qty'],
        title: 'Last Buy Trx Qty',
        render: (row) =>
          row['Last Buy Trx Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Last Buy Trx Qty'])}</div>
          ),
      },
      {
        field: ['Last Buy Trx Amount'],
        title: 'Last Buy Trx Amount',
        render: (row) =>
          row['Last Buy Trx Amount'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Last Buy Trx Amount'])}</div>
          ),
      },
      {
        field: ['Per Unit Price (LMP)'],
        title: 'Per Unit Price (Live market Price)',
      },
      {
        field: ['Average Ticket Size Qty'],
        title: 'Average Ticket Size Qty',
        render: (row) =>
          row['Average Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Average Ticket Size Qty'])}</div>
          ),
      },
      {
        field: ['Max Ticket Size Qty'],
        title: 'Max Ticket Size Qty',
        render: (row) =>
          row['Max Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Max Ticket Size Qty'])}</div>
          ),
      },
      {
        field: ['Min Ticket Size Qty'],
        title: 'Min Ticket Size Qty',
        render: (row) =>
          row['Min Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Min Ticket Size Qty'])}</div>
          ),
      },
      {
        field: ['Average Ticket Size Amount'],
        title: 'Average Ticket Size Amount',
        render: (row) =>
          row['Average Ticket Size Amount'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Average Ticket Size Amount'])}</div>
          ),
      },
      {
        field: ['Max Ticket Size Amount'],
        title: 'Max Ticket Size Amount',
        render: (row) =>
          row['Max Ticket Size Amount'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Max Ticket Size Amount'])}</div>
          ),
      },

      {
        field: ['Min Ticket Size Amount'],
        title: 'Min Ticket Size Amount',
        render: (row) =>
          row['Min Ticket Size Amount'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Min Ticket Size Amount'])}</div>
          ),
      },
      {
        field: ['Payment Channel MFS Count'],
        title: 'Payment Channel MFS Count',
      },
      {
        field: ['Payment Channel Card Count'],
        title: 'Payment Channel Card Count',
      },
      {
        field: ['Campaign Promo Used Count'],
        title: 'Campaign Promo Used Count',
      },
      {
        field: ['Campaign Bonus Used Count'],
        title: 'Campaign Bonus Used Count',
      },

      {
        field: ['Gold Bought With Promo in Qty'],
        title: 'Gold Bought With Promo in Qty',
        render: (row) =>
          row['Gold Bought With Promo in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Gold Bought With Promo in Qty'])}</div>
          ),
      },
      {
        field: ['Gold Bought Without Promo in Qty'],
        title: 'Gold Bought Without Promo in Qty',
        render: (row) =>
          row['Gold Bought Without Promo in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Gold Bought Without Promo in Qty'])}</div>
          ),
      },
      {
        field: ['Gold Bought With Bonus in Qty'],
        title: 'Gold Bought With Bonus in Qty',
        render: (row) =>
          row['Gold Bought With Bonus in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Gold Bought With Bonus in Qty'])}</div>
          ),
      },
      {
        field: ['Gold Bought Without Bonus in Qty'],
        title: 'Gold Bought Without Bonus in Qty',
        render: (row) =>
          row['Gold Bought Without Bonus in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Gold Bought Without Bonus in Qty'])}</div>
          ),
      },

      {
        field: ['Last Login Date'],
        title: 'Last Login Date',
        render: (row) =>
          row['Last Login Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['Last Login Date']).format('DD-MM-YYYY')}</div>
          ),
      },
    ];
    const downloadExcel = () => {
      const newData = this.state.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'buy report');

      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download

      this.props.summary === false
        ? XLSX.writeFile(workBook, 'Marketing Buy Report.xlsx')
        : XLSX.writeFile(workBook, 'Marketing Buy Report Summarized.xlsx');
    };
    // const data=this.props.data
    return (
      <React.Fragment>
        {/* <Breadcrumbs
              title="Marketing"
              breadcrumbItems={this.state.breadcrumbItems}
            /> */}
        {this.props.data.length > 0 ? (
          <MaterialTable
            title={
              this.props.summary === false
                ? 'Marketing Buy Report'
                : 'Marketing Buy Report Summarized'
            }
            columns={this.props.summary === false ? columns : summaryColumns}
            data={this.state.data}
            actions={[
              {
                icon: () => <Button>Export All</Button>, // you can pass icon too
                tooltip: 'Export to Excel',
                onClick: () => downloadExcel(),
                isFreeAction: true,
              },
            ]}
            options={{
              cellStyle: {
                borderRight: '1px solid #eee',
                textAlign: 'center',
                whiteSpace: 'nowrap',
              },
              headerStyle: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
              },

              exportPDF: {
                headerStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              exportFileName:
                this.props.summary === false
                  ? 'Marketing Buy Report'
                  : 'Marketing Buy Report Summarized',
              search: false,
              paging: false,
            }}
            localization={{
              toolbar: {
                exportCSVName: 'Export as CSV',
              },
            }}
          />
        ) : (
          <h5>Loading</h5>
        )}

        {this.state.isLoading ? (
          <h5
            className="d-flex justify-content-center my-3
gap-5"
          >
            <CircularProgress color="inherit" />
          </h5>
        ) : (
          <div className="my-3 d-flex justify-content-center gap-5">
            <Button
              onClick={async () => {
                let token = getToken();
                //get api call with needed parameters
                if (this.props.summary === true) {
                  let submitData = await GKApi.get(
                    `/marketing/report?reportType=${
                      this.props.report
                    }&isSummary=${this.props.summary}&pageSize=${
                      this.state.pageSize
                    }&pageNumber=${this.state.pageNumber - 1}`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 10,
                      },
                    }
                  );
                  this.setState(
                    {
                      data: submitData.data.data,
                      pageNumber: submitData.data.paginationInfo.pageNumber,
                    },
                    () => {}
                  );
                } else {
                  let submitData = await GKApi.get(
                    `/marketing/report?reportType=${
                      this.props.report
                    }&isSummary=${this.props.summary}&startDate=${
                      this.props.startDate
                    }&endDate=${this.props.endDate}&pageSize=10&pageNumber=${
                      this.state.pageNumber - 1
                    }`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 10,
                      },
                    }
                  );
                  this.setState(
                    {
                      data: submitData.data.data,
                      pageNumber: submitData.data.paginationInfo.pageNumber,
                    },
                    () => {}
                  );
                }
              }}
              disabled={this.state.pageNumber === 1 ? true : false}
            >
              Previous page
            </Button>{' '}
            <Button
              onClick={async () => {
                let token = getToken();
                this.setState({ isLoading: true });
                //get api call with needed parameters
                if (this.props.summary === true) {
                  let submitData = await GKApi.get(
                    `/marketing/report?reportType=${
                      this.props.report
                    }&isSummary=${this.props.summary}&pageSize=${
                      this.state.pageSize
                    }&pageNumber=${this.state.pageNumber + 1}`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 10,
                      },
                    }
                  );
                  if (submitData) {
                    this.setState({
                      isLoading: false,
                    });
                  }
                  // data= submitData.data.data
                  this.setState(
                    {
                      data: submitData.data.data,
                      pageNumber: submitData.data.paginationInfo.pageNumber,
                      totalPageCount: submitData.data.paginationInfo.totalPage,
                    },
                    () => {}
                  );
                } else {
                  let submitData = await GKApi.get(
                    `/marketing/report?reportType=${
                      this.props.report
                    }&isSummary=${this.props.summary}&startDate=${
                      this.props.startDate
                    }&endDate=${this.props.endDate}&pageSize=${
                      this.state.pageSize
                    }&pageNumber=${this.state.pageNumber + 1}`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 10,
                      },
                    }
                  );
                  if (submitData) {
                    this.setState({
                      isLoading: false,
                    });
                  }
                  this.setState(
                    {
                      data: submitData.data.data,
                      pageNumber: submitData.data.paginationInfo.pageNumber,
                      totalPageCount: submitData.data.paginationInfo.totalPage,
                    },
                    () => {}
                  );
                }
              }}
              disabled={this.state.pageNumber === this.state.totalPageCount}
            >
              {this.state.isLoading ? 'Loading Next Page' : 'Next Page'}
            </Button>
          </div>
        )}
        <div className="my-2 d-flex justify-content-center">
          <h5>
            Page {this.state.pageNumber} of {this.state.totalPageCount}
          </h5>
        </div>
      </React.Fragment>
    );
  }
}

export default BuyReportMKT;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { roundToTwo } from '../../../components/config';
// import { TableCell, TableHead, TableRow } from '@material-ui/core';

// Table data

class GiftGoldReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '#' },
        { title: 'Finance-Dashboard', link: '#' },
        { title: 'Gift Gold Report', link: '#' },
      ],
      visible: false,
      modal_standard: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  render() {
    const columns = [
      {
        field: ['SL No'],
        title: 'SL No',
      },
      {
        field: ['Transaction Reference No'],
        title: 'Transaction Reference No',
      },
      {
        field: ['Gift Date'],
        title: 'Gift Date',
      },
      {
        field: ['Customer Name (From)'],
        title: 'Customer Name (From)',
      },

      {
        field: ['MSISDN (From)'],
        title: 'MSISDN (From)',
      },
      {
        field: ['Customer Name (To)'],
        title: 'Customer Name (To)',
      },

      {
        field: ['MSISDN (To)'],
        title: 'MSISDN (To)',
      },
      {
        field: ['Per Unit Price (Live Market Price)'],
        title: 'Per Unit Price (Live market Price)',
        render: (row) => (
          <div>{roundToTwo(row['Per Unit Price (Live Market Price)'])}</div>
        ),
      },
      {
        field: ['Gift Gold (Gm)'],
        title: 'Gift Gold Quantity (GM)',
        render: (row) => <div>{roundToTwo(row['Gift Gold (Gm)'])}</div>,
      },
      {
        field: ['Principal Amount'],
        title: 'Principal Amount',
        render: (row) => <div>{roundToTwo(row['Principal Amount'])}</div>,
      },
      {
        field: ['Discount Amount (On Gold)'],
        title: 'Discount Amount (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Gold)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Gold)'],
        title: 'Promo Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Promo Amount (On Gold)'])}</div>,
      },
      {
        field: ['Other Additions (On Gold)'],
        title: 'Other Additions (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Gold)'])}</div>
        ),
      },
      {
        field: ['Net Amount (On Gold)'],
        title: 'Net Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Net Amount (On Gold)'])}</div>,
      },
      {
        field: ['VAT Rate (On Gold)'],
        title: 'VAT Rate (On Gold)',
        render: (row) => <div>{roundToTwo(row['VAT Rate (On Gold)'])}</div>,
      },
      {
        field: ['VAT Amount (On Gold)'],
        title: 'VAT Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['VAT Amount (On Gold)'])}</div>,
      },
      {
        field: ['Total Price Including VAT (On Gold)'],
        title: 'Total Price Including VAT (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Total Price Including VAT (On Gold)'])}</div>
        ),
      },
      {
        field: ['Gift Fee Rate'],
        title: 'Gift Fee Rate',
        render: (row) => <div>{roundToTwo(row['Gift Fee Rate'])}</div>,
      },
      {
        field: ['Gift Fee (Including VAT)'],
        title: 'Gift Fee (Including VAT)',
        render: (row) => <div>{roundToTwo(row['Gift Fee (Including VAT)'])}</div>,
      },
      {
        field: ['Discount Amount (On Gift Fee)'],
        title: 'Discount Amount (On Gift Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Gift Fee)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Gift Fee)'],
        title: 'Promo Amount (On Gift Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Promo Amount (On Gift Fee)'])}</div>
        ),
      },
      {
        field: ['Other Additions (On Gift Fee)'],
        title: 'Other Additions (On Gift Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Gift Fee)'])}</div>
        ),
      },
      {
        field: ['Net Gift Fee (Including VAT)'],
        title: 'Net Gift Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Net Gift Fee (Including VAT)'])}</div>
        ),
      },
      {
        field: ['VAT Rate (On Gift Fee)'],
        title: 'VAT Rate (On Gift Fee)',
        render: (row) => <div>{roundToTwo(row['VAT Rate (On Gift Fee)'])}</div>,
      },
      {
        field: ['Amount of VAT (On Gift Fee)'],
        title: 'Amount of VAT (On Gift Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Amount of VAT (On Gift Fee)'])}</div>
        ),
      },
      {
        field: ['Total Gift Fee (Excluding VAT)'],
        title: 'Total Gift Fee (Excluding VAT)',
        render: (row) => (
          <div>
            {roundToTwo(row['Total Gift Fee (Excluding VAT)'])}
          </div>
        ),
      },
    ];
    const summaryColumns = [
      {
        field: ['Date'],
        title: 'Date',
      },
      {
        field: ['Number of Gift Transactions'],
        title: 'Number of Gift Transactions',
      },
      {
        field: ['Gift Gold (Gm)'],
        title: 'Gift Gold (GM)',
        render: (row) => <div>{roundToTwo(row['Gift Gold (Gm)'])}</div>,
      },
      {
        field: ['Principal Amount'],
        title: 'Principal Amount',
        render: (row) => <div>{roundToTwo(row['Principal Amount'])}</div>,
      },
      {
        field: ['Discount Amount (On Gold)'],
        title: 'Discount Amount (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Gold)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Gold)'],
        title: 'Promo Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Promo Amount (On Gold)'])}</div>,
      },
      {
        field: ['Other Additions (On Gold)'],
        title: 'Other Additions (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Gold)'])}</div>
        ),
      },
      {
        field: ['Net Amount (On Gold)'],
        title: 'Net Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['Net Amount (On Gold)'])}</div>,
      },

      {
        field: ['VAT Amount (On Gold)'],
        title: 'VAT Amount (On Gold)',
        render: (row) => <div>{roundToTwo(row['VAT Amount (On Gold)'])}</div>,
      },
      {
        field: ['Total Price Including VAT (On Gold)'],
        title: 'Total Price Including VAT (On Gold)',
        render: (row) => (
          <div>{roundToTwo(row['Total Price Including VAT (On Gold)'])}</div>
        ),
      },

      {
        field: ['Gift Fee (Including VAT)'],
        title: 'Gift Fee (Including VAT)',
        render: (row) => <div>{roundToTwo(row['Gift Fee (Including VAT)'])}</div>,
      },
      {
        field: ['Discount Amount (On Gift Fee)'],
        title: 'Discount Amount (On Gift Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Discount Amount (On Gift Fee)'])}</div>
        ),
      },
      {
        field: ['Promo Amount (On Gift Fee)'],
        title: 'Promo Amount (On Gift Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Promo Amount (On Gift Fee)'])}</div>
        ),
      },
      {
        field: ['Other Additions (On Gift Fee)'],
        title: 'Other Additions (On Gift Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Other Additions (On Gift Fee)'])}</div>
        ),
      },
      {
        field: ['Net Gift Fee (Including VAT)'],
        title: 'Net Gift Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Net Gift Fee (Including VAT)'])}</div>
        ),
      },

      {
        field: ['Amount of VAT (On Gift Fee)'],
        title: 'Amount of VAT (On Gift Fee)',
        render: (row) => (
          <div>{roundToTwo(row['Amount of VAT (On Gift Fee)'])}</div>
        ),
      },
      {
        field: ['Total Gift Fee (Excluding VAT)'],
        title: 'Total Gift Fee (Excluding VAT)',
        render: (row) => (
          <div>
            {roundToTwo(row['Total Gift Fee (Excluding VAT)'])}
          </div>
        ),
      },
    ];
    const downloadExcel = () => {
      const newData = this.props.location.state.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Gift gold report');
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      
        this.props.location.state.summary === false?
          XLSX.writeFile(workBook, 'Gift Gold Report.xlsx'):
          XLSX.writeFile(workBook, 'Gift Gold Report Summarized.xlsx')
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Finance"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <MaterialTable
              title={
                this.props.location.state.summary === false
                  ? 'Gift Gold Report'
                  : 'Gift Gold Report Summarized'
              }
              columns={
                this.props.location.state.summary === false
                  ? columns
                  : summaryColumns
              }
              data={this.props.location.state.data}
              actions={[
                {
                  icon: () => <Button>Export All</Button>, // you can pass icon too
                  tooltip: 'Export to Excel',
                  onClick: () => downloadExcel(),
                  isFreeAction: true,
                },
              ]}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                exportButton: { csv: true, pdf: false },
                exportFileName: this.props.location.state.summary === false
                ? 'Gift Gold Report'
                : 'Gift Gold Report Summarized',
                pageSize: 20,
                columnsButton: true,
              }}
              localization={{
                toolbar: {
                  exportCSVName: 'Export as CSV',
                },
              }}
            />
            <Modal
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_standard: false })}
              >
                User Edit
              </ModalHeader>
              <ModalBody>
                {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Name
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.name}
                      id="example-name-input"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Position
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.position}
                      id="example-position-input"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Salary
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.salary}
                      id="example-salary-input"
                    />
                  </Col>
                </Row>
                <Button className="float-end">Update</Button>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={this.tog_standard}
                  color="light"
                  className="waves-effect"
                >
                  Close
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                >
                  Save changes
                </Button>
              </ModalFooter>
            </Modal>
            ;
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default GiftGoldReport;

import React, { Component } from 'react';

import {
  Col,
  Input,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
} from 'reactstrap';
import { getToken, GKApi } from '../../../components/config';
import DatePicker from '../../../components/Common/DatePicker';
import SingleUser from './singleUser';
import MaterialInput from '@material-ui/core/Input';
class SingleUserSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeMenu: false,
      type: 'all',
      searchKey: '',
      ledgerData: {},
      searchLoading: false,
      selectedStartDate: null,
      selectedEndDate: null,
      error: '',
    };
  }
  handleSelectedStartDateChange = (event) => {
    this.setState({ selectedStartDate: event });
  };

  handleSelectedEndDateChange = (event) => {
    this.setState({ selectedEndDate: event });
  };
  handleSearchKeyChange = (event) => {
    this.setState({ searchKey: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ searchLoading: true, error: '', ledgerData: {} });
    const token = getToken();
    try {
      if (this.state.type === 'range') {
        const response = await GKApi.get(
          `/finance/ledger/single-user?phone=${this.state.searchKey}&type=${this.state.type}&startDate=${this.state.selectedStartDate}&endDate=${this.state.selectedEndDate}`,
          {
            headers: {
              Authorization: token,
              cmpId: 10,
            },
          }
        );
        this.setState(
          {
            ledgerData: response.data,
          },
          () => {}
        );
        if (response) {
          this.setState({ searchLoading: false });
        }
      } else {
        const response = await GKApi.get(
          `/finance/ledger/single-user?phone=${this.state.searchKey}&type=${this.state.type}`,
          {
            headers: {
              Authorization: token,
              cmpId: 10,
            },
          }
        );
        this.setState(
          {
            ledgerData: response.data,
          },
          () => {}
        );
        if (response) {
          this.setState({ searchLoading: false });
        }
      }
    } catch (error) {
      this.setState({
        error: error.response.data.message,
        searchLoading: false,
      });
    }
  };

  render() {
    const clearAfterExport = () => {
      this.setState({
        searchKey: '',
        ledgerData: {},
        selectedEndDate: null,
        selectedStartDate: null,
        type: 'all',
        error: '',
      });
    };
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Col xl="12">
              <div className="d-flex align-items-center mx-3 my-3">
                <h5
                  className="overflow-auto col-md-2"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Phone Number:
                </h5>
                {/* <Input
                  type="text"
                  value={this.state.searchKey}
                  id="example-name-input"
                  onChange={this.handleSearchKeyChange}
                /> */}
                <Col md={9}>
                  <Input
                    pattern="^01[3-9]{1}[0-9]{8}$"
                    value={this.state.searchKey}
                    className="form-control input-color placeholder-gray-500"
                    onChange={this.handleSearchKeyChange}
                    placeholder="Please input phone number for search"
                    title="Format : 01xxxxxxxxx"
                  >
                    {(inputProps) => (
                      <MaterialInput
                        {...inputProps}
                        type="tel"
                        disableUnderline
                      />
                    )}
                  </Input>
                </Col>
              </div>
              <div className="d-flex gap-3 align-items-center m-3">
                <h5>Select user Ledger type:</h5>
                <Dropdown
                  isOpen={this.state.typeMenu}
                  toggle={(event) =>
                    this.setState(
                      {
                        typeMenu: !this.state.typeMenu,
                      },
                      () => {}
                    )
                  }
                >
                  <DropdownToggle
                    className="px-5 py-2"
                    style={{
                      color: 'white',
                      backgroundColor: '#4E6360',
                    }}
                  >
                    {this.state.type === 'all' ? 'All' : 'Date Range'}
                    <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={async (event) => {
                        this.setState({ type: 'all' }, () => {});
                      }}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      onClick={async (event) => {
                        this.setState({ type: 'range' }, () => {});
                      }}
                    >
                      Date Range
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              {this.state.type === 'range' && (
                <div className="d-flex gap-3 align-items-center mx-3 my-3">
                  <h5>Select date range for ledger:</h5>
                  <Col xs={8} className="d-flex gap-3">
                    <div
                      style={{
                        border: '1px solid',
                        borderRadius: '5px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        paddingRight: '4px',
                        paddingLeft: '4px',
                      }}
                      className="d-flex gap-3 align-content-center"
                    >
                      <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                        Start Date
                      </p>
                      <DatePicker
                        selectedDate={this.state.selectedStartDate}
                        handleSelectedDateChange={
                          this.handleSelectedStartDateChange
                        }
                      />
                    </div>
                    <div
                      style={{
                        border: '1px solid',
                        borderRadius: '5px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        paddingRight: '5px',
                        paddingLeft: '5px',
                      }}
                      className="d-flex d-grid gap-3"
                    >
                      <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                        End Date
                      </p>
                      <DatePicker
                        selectedDate={this.state.selectedEndDate}
                        handleSelectedDateChange={
                          this.handleSelectedEndDateChange
                        }
                        className="mx-3"
                      />
                    </div>
                  </Col>
                </div>
              )}
              {this.state.searchKey !== '' && (
                <div className="d-flex align-items-center gap-3 justify-content-center py-2">
                  <Button
                    type="submit"
                    style={{
                      color: 'white',
                      backgroundColor: '#4E6360',
                    }}
                    // onClick={async () => {

                    // }}
                  >
                    {this.state.searchLoading
                      ? 'Generating Ledger Report'
                      : 'Generate Ledger Report'}
                  </Button>
                  <Button
                    style={{
                      color: 'white',
                      backgroundColor: '#4E6360',
                    }}
                    onClick={async () => {
                      // this.state.searchKey = '';
                      this.setState({
                        searchKey: '',
                        ledgerData: {},
                        selectedEndDate: null,
                        selectedStartDate: null,
                        type: 'all',
                        error: '',
                      });
                    }}
                  >
                    {'Clear Ledger Report'}
                  </Button>
                </div>
              )}
            </Col>
          </FormGroup>
        </Form>
        {Object.keys(this.state.ledgerData).length > 0 && (
          <SingleUser
            ledgerData={this.state.ledgerData}
            type={this.state.type}
            startDate={this.state.selectedStartDate}
            endDate={this.state.selectedEndDate}
            clear={clearAfterExport}
          ></SingleUser>
        )}

        {this.state.error !== '' && (
          <p
            style={{ color: 'red' }}
            className="d-flex justify-content-center py-2 fw-bold"
          >
            {this.state.error}
          </p>
        )}
      </>
    );
  }
}

export default SingleUserSelection;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import classnames from 'classnames';
import * as XLSX from 'xlsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
  Form,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import {
  getGroupName,
  getToken,
  GKApi,
  GKAPI_Base,
} from '../../components/config';
import moment from 'moment';
import DatePickerComponent from '../../components/Common/DatePicker';
import { CircularProgress, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { deviceInfo } from '../../helpers/device_info_helper';
class DeliveryManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryMediumMenu: false,
      deliveryMedium: 'Gold Kinen',
      agentCallStatusMenu: false,
      agentCallStatus: 'Received',
      trackingNumber: '',
      deliveryNote: '',
      deviceInfo: {},
      deliveryData: [],
      approvedDeliveries: [],
      selectedFiles: [],
      key: null,
      productCode: '',
      modal_standard: false,
      modal_standard2: false,
      modal_standard3: false,
      isFin: false,
      isSuper: false,
      isCRM: false,
      deliveryDate: null,
      deliveryTime: '',
      callDate: null,
      callTime: '',
      reqStatus: '',
      canAuth: 0,
      deliveredBy: '',
      deliveryDoc: null,
      activeTabJustify: '1',
      requestData: {},
      authModal: false,
      rejectModal: false,
      canInitiate: 0,
      canView: 0,
      daysFromRequest: 0,
      approvedDeliveryDetails: false,
      chalanUrl: '',
      searchLoading: false,
      searchStartDate: null,
      searchEndDate: null,
      pageNumber: 1,
      totalPageCount: 0,
      searchKey: '',
      isLoading: false,
      statusChangeConfirmation: false,
      agentCallingModal: false,
      callNote: '',
      agentName: '',
      agentCallStatusChangeConfirmation: false,
      canSubmitAgentCall: 0,
      canCreateCode: 0,
      canVerifyCode: 0,
      createConfirmation: false,
      verifyCodeModal: false,
      securityCode: '',
      errorIncorrectCode: '',
    };

    // binding the function calls
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_standard2 = this.tog_standard2.bind(this);
    this.tog_standard3 = this.tog_standard3.bind(this);
  }

  //modal closing options

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_standard2() {
    this.setState((prevState) => ({
      modal_standard2: !prevState.modal_standard2,
    }));
    this.removeBodyCss();
  }

  tog_standard3() {
    this.setState((prevState) => ({
      modal_standard3: !prevState.modal_standard3,
    }));
    this.removeBodyCss();
  }

  tog_authModal = () => {
    this.setState((prevState) => ({
      authModal: !prevState.authModal,
    }));
    this.removeBodyCss();
  };

  tog_rejectModal = () => {
    this.setState((prevState) => ({
      rejectModal: !prevState.rejectModal,
    }));
    this.removeBodyCss();
  };
  tog_statusChangeConfirmation = () => {
    this.setState((prevState) => ({
      statusChangeConfirmation: !prevState.statusChangeConfirmation,
    }));
    this.removeBodyCss();
  };

  tog_approvedDeliveryDetails = () => {
    this.setState((prevState) => ({
      approvedDeliveryDetails: !prevState.approvedDeliveryDetails,
    }));
    this.removeBodyCss();
  };

  tog_agentCallingModal = () => {
    this.setState((prevState) => ({
      agentCallingModal: !prevState.agentCallingModal,
    }));
    this.removeBodyCss();
  };

  tog_agentCallingStatusChangeConfirmation = () => {
    this.setState((prevState) => ({
      agentCallStatusChangeConfirmation:
        !prevState.agentCallStatusChangeConfirmation,
    }));
    this.removeBodyCss();
  };

  tog_verifyCodeModal = () => {
    this.setState((prevState) => ({
      verifyCodeModal: !prevState.verifyCodeModal,
    }));
    this.removeBodyCss();
  };

  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  //fetch sku list call

  async componentDidMount() {
    // GET request using axios with async/await
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      } else if (name === 'CRM') {
        this.setState({
          isCRM: true,
        });
      }
    });
    const token = getToken(); // getting token for api call
    this.setState({ isLoading: true });
    try {
      // the api call
      const response = await GKApi.get(
        '/finance/collect/pending?pageNumber=1&pageSize=10',
        {
          headers: {
            Authorization: token,
            cmpId: 169,
          },
        }
      );

      this.setState({
        deliveryData: response.data.data,
        totalPageCount: response.data.paginationInfo.totalPage,
        pageNumber: response.data.paginationInfo.pageNumber,
      });
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }

    const getPermission = await GKApi.get(
      `/role/permission?cmpId=169,170,174`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    this.setState({
      canSubmitAgentCall: permissionData[169].canSubmit,
      canView: permissionData[169].canView,
      canInitiate: permissionData[170].canInsert,
      canAuth: permissionData[170].canAuth,
      canCreateCode: permissionData[174].canAuth,
      canVerifyCode: permissionData[174].canAuth,
    });
    const res = deviceInfo();
    this.setState({ deviceInfo: res }, () => {});
  }

  //change handlers

  handleDeliveryDateChange = (event) => {
    this.setState({
      ...this.state,
      deliveryDate: event,
    });
  };

  handleDeliveryTimeChange = (event) => {
    this.setState({
      ...this.state,
      deliveryTime: event.target.value,
    });
  };

  handleDeliveryNoteChange = (event) => {
    this.setState({
      ...this.state,
      deliveryNote: event.target.value,
    });
  };

  handleTrackingNumberChange = (event) => {
    this.setState({
      ...this.state,
      trackingNumber: event.target.value,
    });
  };

  handleTrackingNumberChange = (event) => {
    this.setState({
      ...this.state,
      trackingNumber: event.target.value,
    });
  };

  handleDeliveredByChange = (event) => {
    this.setState({
      ...this.state,
      deliveredBy: event.target.value,
    });
  };

  handleSecurityCodeChange = (event) => {
    this.setState({
      ...this.state,
      securityCode: event.target.value,
    });
  };

  handleCallDateChange = (event) => {
    this.setState({
      ...this.state,
      callDate: event,
    });
  };

  handleCallTimeChange = (event) => {
    this.setState({
      ...this.state,
      callTime: event.target.value,
    });
  };

  handleCallNoteChange = (event) => {
    this.setState({
      ...this.state,
      callNote: event.target.value,
    });
  };

  handleAgentNameChange = (event) => {
    this.setState({
      ...this.state,
      agentName: event.target.value,
    });
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  handleAcceptedFiles = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };

  uploadDoc = async (customerId) => {
    const token = getToken(); //get token from local storage
    const response = await GKApi.post(
      '/finance/collect/chalan-upload',
      {
        cmpId: 170,
        customerId: customerId,
        fileName:
          'collect_chalan.' + this.state.selectedFiles[0].name.split('.').pop(),
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    // this.state.deliveryDoc = response.data.key;
    this.setState({ deliveryDoc: response.data.key });
    await axios.put(response.data.url, this.state.selectedFiles[0]);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const token = getToken();
    await this.uploadDoc(this.state.key?.customerId);
    let sendData = {
      cmpId: 170,
      collectId: this.state.key?.trxID,
      deliveryTime: moment(this.state.deliveryTime, 'HH:mm').format('HH:mm:00'),
      deliveryDate: moment(this.state.deliveryDate).format('YYYY-MM-DD'),
      deliveryPerson: this.state.deliveredBy,
      deliveryNote: this.state.deliveryNote,
      chalanUpload: this.state.deliveryDoc,
      deliveryMedium: this.state.deliveryMedium,
      trackingNo:
        this.state.trackingNumber === '' ? null : this.state.trackingNumber,
      // deviceInfo: this.state.deviceInfo,
    };

    // return
    await GKApi.post('/finance/collect/delivery-submit', sendData, {
      headers: {
        Authorization: token,
        // cmpId: 10,
      },
    });

    const response = await GKApi.get(
      `/finance/collect/pending?pageNumber=${this.state.pageNumber}&pageSize=10`,
      {
        headers: {
          Authorization: token,
          cmpId: 169,
        },
      }
    );

    this.setState({
      deliveryData: response.data.data,
      totalPageCount: response.data.paginationInfo.totalPage,
      pageNumber: response.data.paginationInfo.pageNumber,
    });
    this.tog_standard();
  };

  handleSubmitAgentCallStatus = async (event) => {
    event.preventDefault();
    const token = getToken();
    let sendData = {
      deliveryId: this.state.key?.deliveryID,
      collectId: this.state.key?.trxID,
      callTime: moment(this.state.callTime, 'HH:mm').format('HH:mm:00'),
      callDate: moment(this.state.callDate).format('YYYY-MM-DD'),
      agentName: this.state.agentName,
      callNote: this.state.callNote,
      callStatus: this.state.agentCallStatus,
      // deviceInfo: this.state.deviceInfo,
    };

    // return
    await GKApi.post('/finance/collect/agent-call', sendData, {
      headers: {
        Authorization: token,
        cmpId: 169,
      },
    });

    const response = await GKApi.get(
      `/finance/collect/pending?pageNumber=${this.state.pageNumber}&pageSize=10`,
      {
        headers: {
          Authorization: token,
          cmpId: 169,
        },
      }
    );

    this.setState({
      deliveryData: response.data.data,
      totalPageCount: response.data.paginationInfo.totalPage,
      pageNumber: response.data.paginationInfo.pageNumber,
    });
    this.tog_agentCallingModal();
  };

  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };

  handleSearchKeyChange = (event) => {
    this.setState({ searchKey: event.target.value });
  };

  handleSearchStartDate = (event) => {
    this.setState(
      {
        ...this.state,
        searchStartDate: event,
      },
      () => {}
    );
  };
  handleSearchEndDate = (event) => {
    this.setState(
      {
        ...this.state,
        searchEndDate: event,
      },
      () => {}
    );
  };

  render() {
    const columns = [
      {
        field: 'customerPhone',
        title: 'Customer Phone',
      },
      {
        field: 'customerName',
        title: 'Customer Name',
      },

      {
        field: 'trxDate',
        title: 'Transaction Date',
        render: (row) => (
          <div>
            {moment(row.trxDate, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY hh:mm A'
            )}
          </div>
        ),
      },
      {
        field: 'goldAmount',
        title: 'Amount',
      },
      {
        field: 'productDetails',
        title: 'Product Details',
      },
      {
        field: 'recipientPhone',
        title: 'Recipient Phone',
      },
      {
        field: 'recipientName',
        title: 'Recipient Name',
      },
      {
        field: 'recipientAddress',
        title: 'Recipient Address',
      },
      {
        field: 'deliveryStatus',
        title: 'Status',
        render: (row) => (
          <div>
            {row.deliveryStatus === 'Delivered'
              ? 'Delivered'
              : row.deliveryStatus === 'Rejected'
              ? 'Rejected'
              : row.deliveryStatus === 'Agent Call Done'
              ? 'Agent Call Done'
              : 'Pending'}
          </div>
        ),
      },
    ];
    const approvedColumns = [
      {
        field: 'customerPhone',
        title: 'Customer Phone',
      },
      {
        field: 'customerName',
        title: 'Customer Name',
      },

      {
        field: 'trxDate',
        title: 'Transaction Date',
        render: (row) => (
          <div>
            {moment(row.trxDate, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY hh:mm A'
            )}
          </div>
        ),
      },
      {
        field: 'goldAmount',
        title: 'Amount',
      },
      {
        field: 'productDetails',
        title: 'Product Details',
      },
      {
        field: 'recipientPhone',
        title: 'Recipient Phone',
      },
      {
        field: 'recipientName',
        title: 'Recipient Name',
      },
      {
        field: 'recipientAddress',
        title: 'Recipient Address',
      },
      {
        field: 'deliveryStatus',
        title: 'Status',
      },
      {
        field: 'deliveryTime',
        title: 'Delivery Time',
        render: (row) => (
          <div>
            {moment(row.deliveryTime, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY hh:mm A'
            )}
          </div>
        ),
      },
      {
        field: 'deliveryDays',
        title: 'Delivery Days',
      },
      {
        field: 'deliveryPerson',
        title: 'Delivery Person',
      },
      {
        field: 'deliveryNote',
        title: 'Delivery Note',
      },
    ];

    const downloadExcelPending = async () => {
      const token = getToken();
      const response = await GKApi.get(
        '/finance/collect/pending?pageNumber=1&pageSize=10000',
        {
          headers: {
            Authorization: token,
            cmpId: 169,
          },
        }
      );
      const newData = response.data.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Pending deliveries');
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(workBook, `Pending Delivery List.xlsx`);
    };

    const downloadExcelApproved = async () => {
      const response = await GKApi.get(
        '/finance/collect/approved/delivery-list?pageNumber=1&pageSize=10000',
        {
          headers: {
            Authorization: getToken(),
            cmpId: 169,
          },
        }
      );
      const newData = response.data.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Approved deliveries');
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(workBook, `Approved Delivery List.xlsx`);
    };
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isSuper === true ||
          this.state.isFin === true ||
          this.state.isCRM === true ? (
            <div className="container-fluid">
              <Row>
                <Col xl={12}>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: this.state.activeTabJustify === '1',
                        })}
                        onClick={async () => {
                          this.toggleCustomJustified('1');
                          const response = await GKApi.get(
                            '/finance/collect/pending?pageNumber=1&pageSize=10',
                            {
                              headers: {
                                Authorization: getToken(),
                                cmpId: 169,
                              },
                            }
                          );

                          this.setState({
                            deliveryData: response.data.data,
                            totalPageCount:
                              response.data.paginationInfo.totalPage,
                            pageNumber: response.data.paginationInfo.pageNumber,
                          });
                        }}
                      >
                        <span className="d-none d-sm-block fw-bold">
                          Pending Deliveries
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: this.state.activeTabJustify === '2',
                        })}
                        onClick={async () => {
                          this.toggleCustomJustified('2');
                          const response = await GKApi.get(
                            '/finance/collect/approved/delivery-list?pageNumber=1&pageSize=10',
                            {
                              headers: {
                                Authorization: getToken(),
                                cmpId: 169,
                              },
                            }
                          );

                          this.setState({
                            approvedDeliveries: response.data.data,
                            totalPageCount:
                              response.data.paginationInfo.totalPage,
                            pageNumber: response.data.paginationInfo.pageNumber,
                          });
                        }}
                      >
                        <span className="d-none d-sm-block fw-bold">
                          Confirmed Delivered
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTabJustify}>
                    <TabPane tabId="1">
                      <div>
                        <div className="d-flex gap-3">
                          <div className="d-flex gap-3 align-items-center mx-3 my-3">
                            <h5>Phone:</h5>
                            <Input
                              type="text"
                              value={this.state.searchKey}
                              id="example-name-input"
                              onChange={this.handleSearchKeyChange}
                            />
                          </div>
                          {/* <p className='mx-3'>Search based on joining date</p> */}
                          <div className="d-flex gap-3 align-items-center mx-3 my-3">
                            <h5>Start Date:</h5>
                            <div
                              style={{
                                border: '1px solid gray',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                              }}
                            >
                              <DatePickerComponent
                                selectedDate={this.state.searchStartDate}
                                handleSelectedDateChange={
                                  this.handleSearchStartDate
                                }
                              />
                            </div>

                            <h5>End Date:</h5>
                            <div
                              style={{
                                border: '1px solid gray',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                              }}
                            >
                              <DatePickerComponent
                                selectedDate={this.state.searchEndDate}
                                handleSelectedDateChange={
                                  this.handleSearchEndDate
                                }
                              />
                            </div>

                            {((this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null) ||
                              this.state.searchKey !== '') && (
                              <div className="d-flex align-items-center gap-3">
                                <Button
                                  onClick={async () => {
                                    this.setState({ searchLoading: true });
                                    const token = getToken();
                                    const response = await GKApi.get(
                                      `/finance/collect/pending?pageNumber=1&pageSize=10${
                                        this.state.searchKey !== ''
                                          ? `&phone=${this.state.searchKey}`
                                          : ``
                                      }${
                                        this.state.searchStartDate !== null &&
                                        this.state.searchEndDate !== null
                                          ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                          : ''
                                      }`,
                                      {
                                        headers: {
                                          Authorization: token,
                                          cmpId: 169,
                                        },
                                      }
                                    );
                                    this.setState(
                                      {
                                        deliveryData: response.data.data,
                                        totalPageCount:
                                          response.data.paginationInfo
                                            .totalPage,
                                        pageNumber:
                                          response.data.paginationInfo
                                            .pageNumber,
                                      },
                                      () => {}
                                    );
                                    if (response) {
                                      this.setState({ searchLoading: false });
                                    }
                                  }}
                                >
                                  {this.state.searchLoading
                                    ? 'Searching'
                                    : 'Search'}
                                </Button>
                                <Button
                                  onClick={async () => {
                                    // eslint-disable-next-line
                                    this.state.searchKey = '';
                                    this.setState({ clearLoading: true });
                                    const token = getToken();
                                    const response = await GKApi.get(
                                      `/finance/collect/pending?pageNumber=1&pageSize=10`,
                                      {
                                        headers: {
                                          Authorization: token,
                                          cmpId: 169,
                                        },
                                      }
                                    );
                                    this.setState(
                                      {
                                        deliveryData: response.data.data,

                                        totalPageCount:
                                          response.data.paginationInfo
                                            .totalPage,
                                        pageNumber:
                                          response.data.paginationInfo
                                            .pageNumber,
                                        searchStartDate: null,
                                        searchEndDate: null,
                                      },
                                      () => {}
                                    );
                                    if (response) {
                                      this.setState({ clearLoading: false });
                                    }
                                  }}
                                >
                                  {this.state.clearLoading
                                    ? 'Clearing'
                                    : 'Clear'}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                        <MaterialTable
                          title={'List of Pending Home Deliveries'}
                          columns={columns}
                          data={this.state.deliveryData}
                          actions={[
                            this.state.canView === 1 && {
                              icon: () => <Button>Export All</Button>, // you can pass icon too
                              tooltip: 'Export to Excel',
                              onClick: () => downloadExcelPending(),
                              isFreeAction: true,
                            },
                            (rowData) => ({
                              icon: () => <i className="fas fa-eye" />,
                              tooltip: 'See Delivery Details',
                              onClick: async (event, row) => {
                                this.setState({
                                  modal_standard2: true,
                                  key: rowData,
                                });
                              },
                            }),
                            (rowData) =>
                              this.state.canCreateCode === 1 && {
                                icon: () => (
                                  <i className="fas fa-laptop-code" />
                                ),
                                tooltip: 'Generate Security Code',
                                onClick: async (event, row) => {
                                  const generateSecurityCode = await GKApi.post(
                                    `/staff/delivery:generate-security-code`,
                                    {
                                      trxID: rowData.trxID,
                                      cmpId: 174,
                                    },
                                    {
                                      headers: {
                                        Authorization: getToken(),
                                      },
                                    }
                                  );
                                  const response = await GKApi.get(
                                    `/finance/collect/pending?pageNumber=${this.state.pageNumber}&pageSize=10`,
                                    {
                                      headers: {
                                        Authorization: getToken(),
                                        cmpId: 169,
                                      },
                                    }
                                  );

                                  this.setState({
                                    deliveryData: response.data.data,
                                    totalPageCount:
                                      response.data.paginationInfo.totalPage,
                                    pageNumber:
                                      response.data.paginationInfo.pageNumber,
                                  });

                                  
                                  
                                  if (generateSecurityCode) {
                                    this.setState({
                                      ...this.state,
                                      // success: 'Finance Inputs Updated Successfully',
                                      createConfirmation: true,
                                    });
                                    setTimeout(() => {
                                      this.setState({
                                        createConfirmation: false,
                                      });
                                    }, 5000);
                                  }
                                },
                                hidden: rowData.deliveryCodeVerifiedAt,
                              },
                            (rowData) =>
                              this.state.canVerifyCode === 1 && {
                                icon: () => (
                                  <i className="fas fa-check-double" />
                                ),
                                tooltip: 'Verify Security Code',
                                onClick: async (event, row) => {
                                  this.setState({
                                    verifyCodeModal: true,
                                    key: rowData,
                                  });
                                },
                                hidden: rowData.deliveryCodeVerifiedAt || rowData.deliveryCodeLastSentAt===null,
                              },
                            (rowData) => ({
                              icon: () => (
                                <i className="fas fa-file-download" />
                              ),
                              tooltip: 'Chalan download',
                              onClick: async () => {
                                const token = getToken();
                                // const response = await GKApi.get(
                                //   `/finance/collect/chalan-invoice?trxID=${rowData.trxID}`,
                                //   {
                                //     headers: {
                                //       Authorization: token,
                                //       cmpId: 169,
                                //     },
                                //   }
                                // );

                                // const fileURL =
                                //   window.URL.createObjectURL(new Blob(response.data, {type: "application/pdf"}));
                                // // Setting various property values
                                // let alink = document.createElement('a');
                                // alink.href = fileURL;
                                // alink.download = 'SamplePDF.pdf';
                                // alink.click();

                                axios({
                                  url: `${GKAPI_Base}/finance/collect/chalan-invoice?trxID=${rowData.trxID}`, //your url
                                  method: 'GET',
                                  responseType: 'blob', // important

                                  headers: {
                                    Authorization: token,
                                    cmpId: 169,
                                  },
                                }).then((response) => {
                                  // create file link in browser's memory
                                  const href = URL.createObjectURL(
                                    response.data
                                  );

                                  // create "a" HTLM element with href to file & click
                                  const link = document.createElement('a');
                                  link.href = href;
                                  link.setAttribute(
                                    'download',
                                    `${rowData.customerName}_${rowData.trxRefID}_Delivery_Chalan.pdf`
                                  ); //or any other extension
                                  document.body.appendChild(link);
                                  link.click();

                                  // clean up "a" element & remove ObjectURL
                                  document.body.removeChild(link);
                                  URL.revokeObjectURL(href);
                                });
                              },
                              hidden:
                                rowData.deliveryStatus === 'Delivered' ||
                                rowData.deliveryStatus === 'Agent Call Done' ||
                                this.state.canInitiate !== 1,
                            }),
                            (rowData) =>
                              this.state.canInitiate === 1 && {
                                icon: () => (
                                  <i className="fas fa-truck-moving" />
                                ),
                                tooltip: 'Initiate Delivery',
                                onClick: async () => {
                                  this.setState({
                                    modal_standard: true,
                                    key: rowData,
                                  });
                                },
                                hidden:
                                  rowData.deliveryStatus === 'Delivered' ||
                                  rowData.deliveryStatus === 'Agent Call Done',
                              },
                            (rowData) =>
                              this.state.canAuth === 1 && {
                                icon: () => (
                                  <i className="fas fa-clipboard-check" />
                                ),
                                tooltip: 'Authorize Delivery',
                                onClick: async () => {
                                  const token = getToken();
                                  const response = await GKApi.get(
                                    `/finance/collect/delivery-status/${rowData.trxID}`,
                                    {
                                      headers: {
                                        Authorization: token,
                                        cmpId: 170,
                                      },
                                    }
                                  );

                                  this.setState({
                                    requestData: response.data.data,
                                    modal_standard3: true,
                                    key: rowData,
                                  });
                                },
                                hidden:
                                  rowData.deliveryStatus !== 'Agent Call Done',
                              },

                            (rowData) =>
                              this.state.canSubmitAgentCall === 1 && {
                                icon: () => (
                                  <i className="fas fa-phone-square-alt" />
                                ),
                                tooltip: 'Agent Call',
                                onClick: async (event, row) => {
                                  this.setState({
                                    agentCallingModal: true,
                                    key: rowData,
                                  });
                                },
                                hidden:
                                  rowData.deliveryStatus !== 'Delivered' ||
                                  rowData.deliveryStatus ===
                                    'Agent Call Done' ||
                                  this.state.canAuth === 1,
                              },
                          ]}
                          options={{
                            //cell style for the table
                            cellStyle: {
                              borderRight: '1px solid #eee',
                              textAlign: 'left',
                              // whiteSpace:'nowrap'
                            },

                            //style for the header
                            search: false,
                            paging: false,
                            headerStyle: {
                              position: 'sticky',
                              textAlign: 'left',
                              top: 0,
                              whiteSpace: 'nowrap',
                            },
                            actionsColumnIndex: -1,
                            exportButton: { csv: true, pdf: false },
                            exportFileName: `Pending Delivery List Page ${this.state.pageNumber}`,
                            pageSize: 10,
                            columnsButton: true,
                            rowStyle: (rowData) => {
                              const reqDate = moment(rowData.trxDate);
                              const currentDate = moment(new Date());

                              const diff =
                                currentDate.diff(reqDate, 'hours') / 24;

                              return {
                                backgroundColor:
                                  rowData.deliveryStatus === 'Delivered'
                                    ? 'lavender'
                                    : rowData.deliveryStatus ===
                                      'Agent Call Done'
                                    ? 'aliceblue'
                                    : 'beige',
                                color: diff > 5 && 'red',
                              };
                            },
                          }}
                          localization={{
                            toolbar: {
                              exportCSVName: 'Export as CSV',
                            },
                          }}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div>
                        <div className="d-flex gap-3">
                          <div className="d-flex gap-3 align-items-center mx-3 my-3">
                            <h5>Phone:</h5>
                            <Input
                              type="text"
                              value={this.state.searchKey}
                              id="example-name-input"
                              onChange={this.handleSearchKeyChange}
                            />
                          </div>
                          {/* <p className='mx-3'>Search based on joining date</p> */}
                          <div className="d-flex gap-3 align-items-center mx-3 my-3">
                            <h5>Start Date:</h5>
                            <div
                              style={{
                                border: '1px solid gray',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                              }}
                            >
                              <DatePickerComponent
                                selectedDate={this.state.searchStartDate}
                                handleSelectedDateChange={
                                  this.handleSearchStartDate
                                }
                              />
                            </div>

                            <h5>End Date:</h5>
                            <div
                              style={{
                                border: '1px solid gray',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                              }}
                            >
                              <DatePickerComponent
                                selectedDate={this.state.searchEndDate}
                                handleSelectedDateChange={
                                  this.handleSearchEndDate
                                }
                              />
                            </div>

                            {((this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null) ||
                              this.state.searchKey !== '') && (
                              <div className="d-flex align-items-center gap-3">
                                <Button
                                  onClick={async () => {
                                    this.setState({ searchLoading: true });
                                    const token = getToken();
                                    const response = await GKApi.get(
                                      `/finance/collect/approved/delivery-list?pageNumber=1&pageSize=10${
                                        this.state.searchKey !== ''
                                          ? `&phone=${this.state.searchKey}`
                                          : ``
                                      }${
                                        this.state.searchStartDate !== null &&
                                        this.state.searchEndDate !== null
                                          ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                          : ''
                                      }`,
                                      {
                                        headers: {
                                          Authorization: token,
                                          cmpId: 169,
                                        },
                                      }
                                    );
                                    this.setState(
                                      {
                                        approvedDeliveries: response.data.data,

                                        totalPageCount:
                                          response.data.paginationInfo
                                            .totalPage,
                                        pageNumber:
                                          response.data.paginationInfo
                                            .pageNumber,
                                      },
                                      () => {}
                                    );
                                    if (response) {
                                      this.setState({ searchLoading: false });
                                    }
                                  }}
                                >
                                  {this.state.searchLoading
                                    ? 'Searching'
                                    : 'Search'}
                                </Button>
                                <Button
                                  onClick={async () => {
                                    // eslint-disable-next-line
                                    this.state.searchKey = '';
                                    this.setState({ clearLoading: true });
                                    const token = getToken();
                                    const response = await GKApi.get(
                                      `/finance/collect/approved/delivery-list?pageNumber=1&pageSize=10`,
                                      {
                                        headers: {
                                          Authorization: token,
                                          cmpId: 169,
                                        },
                                      }
                                    );
                                    this.setState(
                                      {
                                        approvedDeliveries: response.data.data,

                                        totalPageCount:
                                          response.data.paginationInfo
                                            .totalPage,
                                        pageNumber:
                                          response.data.paginationInfo
                                            .pageNumber,
                                        searchStartDate: null,
                                        searchEndDate: null,
                                      },
                                      () => {}
                                    );
                                    if (response) {
                                      this.setState({ clearLoading: false });
                                    }
                                  }}
                                >
                                  {this.state.clearLoading
                                    ? 'Clearing'
                                    : 'Clear'}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                        <MaterialTable
                          title={'List of Approved Home Deliveries'}
                          columns={approvedColumns}
                          data={this.state.approvedDeliveries}
                          actions={[
                            this.state.canView === 1 && {
                              icon: () => <Button>Export All</Button>, // you can pass icon too
                              tooltip: 'Export to Excel',
                              onClick: () => downloadExcelApproved(),
                              isFreeAction: true,
                            },
                            (rowData) => ({
                              icon: () => <i className="fas fa-eye" />,

                              onClick: async (event, row) => {
                                const response = await GKApi.post(
                                  '/finance/collect/chalan-url',
                                  {
                                    cmpId: 169,
                                    key: row.deliveryChalanUpload,
                                  },
                                  {
                                    headers: {
                                      Authorization: getToken(),
                                    },
                                  }
                                );

                                this.setState({
                                  approvedDeliveryDetails: true,
                                  key: rowData,
                                  chalanUrl: response.data.url,
                                });
                              },
                            }),
                          ]}
                          options={{
                            //cell style for the table
                            cellStyle: {
                              borderRight: '1px solid #eee',
                              textAlign: 'left',
                              // whiteSpace:'nowrap'
                            },

                            //style for the header

                            headerStyle: {
                              position: 'sticky',
                              textAlign: 'left',
                              top: 0,
                              whiteSpace: 'nowrap',
                            },
                            search: false,
                            paging: false,
                            actionsColumnIndex: -1,
                            exportButton: { csv: true, pdf: false },
                            exportFileName: `Approved Delivery List Page ${this.state.pageNumber}`,
                            pageSize: 10,
                            columnsButton: true,
                            // rowStyle: (rowData) => {
                            //   return {
                            //     backgroundColor:
                            //       rowData.deliveryStatus === 'Delivered'
                            //         ? 'cyan'
                            //         : 'yellowgreen',
                            //   };
                            // },
                          }}
                          localization={{
                            toolbar: {
                              exportCSVName: 'Export as CSV',
                            },
                          }}
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>

              {/* modal for delivery details */}
              <div id="41">
                <Modal
                  isOpen={this.state.modal_standard2}
                  toggle={this.tog_standard2}
                  size="lg"
                  style={{ maxWidth: '700px', width: '100%' }}
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_standard2: false })}
                  >
                    Delivery Details
                  </ModalHeader>

                  <ModalBody>
                    <Row className="mb-3">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Customer Name</h6>
                        <p>{this.state.key?.customerName}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Customer Phone</h6>
                        <p>{this.state.key?.customerPhone}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Transaction Date</h6>
                        <p>
                          {moment(
                            this.state.key?.trxDate,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('DD-MM-YYYY hh:mm A')}
                        </p>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Transaction Reference Id</h6>
                        <p>{this.state.key?.trxRefID}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Amount (in GM)</h6>
                        <p>{this.state.key?.goldAmount}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Product Details</h6>
                        <p>{this.state.key?.productDetails}</p>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Recipient Phone</h6>
                        <p>{this.state.key?.recipientPhone}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Recipient name</h6>
                        <p>{this.state.key?.recipientName}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Recipient Address</h6>
                        <p>{this.state.key?.recipientAddress}</p>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
              {/* modal for Delivery status change */}
              <div id="41">
                <Modal
                  isOpen={this.state.modal_standard}
                  toggle={this.tog_standard}
                  // size='lg'
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_standard: false })}
                  >
                    Delivery Form
                  </ModalHeader>

                  <ModalBody>
                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-deliveryNote-input"
                        className="col-md-5 col-form-label"
                      >
                        Delivery Date
                      </Label>
                      <Col md={7}>
                        <div
                          style={{
                            border: '1px solid gray',
                            borderRadius: '5px',
                            paddingTop: '2.5px',
                            paddingBottom: '2.5px',
                            paddingRight: '4px',
                            paddingLeft: '4px',
                          }}
                        >
                          <DatePickerComponent
                            selectedDate={this.state.deliveryDate}
                            handleSelectedDateChange={
                              this.handleDeliveryDateChange
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-deliveryNote-input"
                        className="col-md-5 col-form-label"
                      >
                        Delivery Time
                      </Label>
                      <Col md={7}>
                        <Input
                          type="time"
                          value={this.state.deliveryTime}
                          placeholder="Please input deliveryNote of the new SKU"
                          id="add-sku-deliveryNote-input"
                          onChange={this.handleDeliveryTimeChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-product-type-dropdown"
                        className="col-md-5 col-form-label"
                      >
                        Delivery Medium
                      </Label>
                      <Col md={7}>
                        <Dropdown
                          isOpen={this.state.deliveryMediumMenu}
                          toggle={(event) =>
                            this.setState({
                              deliveryMediumMenu:
                                !this.state.deliveryMediumMenu,
                            })
                          }
                        >
                          <DropdownToggle className="px-5 py-3" color="light">
                            {this.state.deliveryMedium}{' '}
                            <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={(event) => {
                                this.setState({
                                  deliveryMedium: 'Gold Kinen',
                                });
                              }}
                            >
                              Gold Kinen
                            </DropdownItem>

                            {/* <DropdownItem
                              onClick={(event) => {
                                this.setState({
                                  deliveryMedium: 'eCourier',
                                });
                              }}
                            >
                              eCourier
                            </DropdownItem>
                            <DropdownItem
                              onClick={(event) => {
                                this.setState({ deliveryMedium: 'Others' });
                              }}
                            >
                              Others
                            </DropdownItem> */}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-designation-input"
                        className="col-md-5 col-form-label"
                      >
                        Tracking Number
                      </Label>
                      <Col md={7}>
                        <Input
                          type="text"
                          value={this.state.trackingNumber}
                          placeholder="Please input tracking number of the delivery"
                          id="add-sku-trackingNumber-input"
                          onChange={this.handleTrackingNumberChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-deliveryNote-input"
                        className="col-md-5 col-form-label"
                      >
                        Deliver note
                      </Label>
                      <Col md={7}>
                        <TextField
                          className="w-full"
                          variant="outlined"
                          type="text"
                          multiline
                          minRows={2}
                          style={{
                            width: '100%',
                          }}
                          value={this.state.deliveryNote}
                          placeholder="Please input deliveryNote of the new SKU"
                          id="add-sku-deliveryNote-input"
                          onChange={this.handleDeliveryNoteChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-designation-input"
                        className="col-md-5 col-form-label"
                      >
                        Delivered By
                      </Label>
                      <Col md={7}>
                        <Input
                          type="text"
                          value={this.state.deliveredBy}
                          placeholder="Please input tracking number of the delivery"
                          id="add-sku-trackingNumber-input"
                          onChange={this.handleDeliveredByChange}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label
                        htmlFor="example-name-input"
                        className="col-md-5 col-form-label"
                      >
                        Delivery Document
                      </Label>
                      <Col md={7}>
                        <Form className="my-2">
                          <h6 className="fw-bold">Upload Delivery Document</h6>
                          <Dropzone
                            accept="image/*,.pdf"
                            onDrop={(acceptedFiles) =>
                              this.handleAcceptedFiles(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                  </div>
                                  <h4>Drag & Drop Your File Here</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {this.state.selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + '-file'}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted fw-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </ModalBody>

                  {this.state.selectedFiles.length > 0 ? (
                    <ModalFooter>
                      <Button
                        type="button"
                        onClick={this.tog_standard}
                        color="light"
                        className="waves-effect"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        className="waves-effect waves-light"
                        onClick={() => {
                          this.tog_statusChangeConfirmation();
                        }}
                      >
                        Change Delivery Status
                      </Button>
                    </ModalFooter>
                  ) : (
                    <h5 className="d-flex justify-content-center">
                      {' '}
                      Please upload delivery document to initiate!!
                    </h5>
                  )}
                </Modal>
                <Modal
                  isOpen={this.state.statusChangeConfirmation}
                  toggle={this.tog_statusChangeConfirmation}
                >
                  <ModalHeader
                    toggle={() =>
                      this.setState({ statusChangeConfirmation: false })
                    }
                  >
                    Confirmation
                  </ModalHeader>

                  <ModalBody>
                    <Row className="mb-3">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Invoice Id</h6>
                        <p>{this.state.key?.trxRefID}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Delivery date</h6>
                        <p>
                          {moment(this.state.deliveryDate).format('YYYY-MM-DD')}
                        </p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Delivery Time</h6>
                        <p>
                          {moment(this.state.deliveryTime, 'HH:mm').format(
                            'HH:mm:00'
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Delivered By</h6>
                        <p>{this.state.deliveredBy}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Delivery Note</h6>
                        <p>{this.state.deliveryNote}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Tracking Number</h6>
                        <p>{this.state.trackingNumber}</p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Chalan</h6>
                        <p>
                          {this.state.selectedFiles.map((f) => {
                            return <p>{f.name}</p>;
                          })}
                        </p>
                      </Col>
                    </Row>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_statusChangeConfirmation}
                      color="light"
                      className="waves-effect"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="waves-effect"
                      color="primary"
                      onClick={async (event) => {
                        this.handleSubmit(event);
                        this.tog_statusChangeConfirmation();
                      }}
                    >
                      Change Status
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              {/* modal for agent calling details */}
              <div id="41">
                <Modal
                  isOpen={this.state.agentCallingModal}
                  toggle={this.tog_agentCallingModal}
                  size="lg"
                  style={{ maxWidth: '700px', width: '100%' }}
                >
                  <ModalHeader
                    toggle={() => this.setState({ agentCallingModal: false })}
                  >
                    Agent call Details
                  </ModalHeader>

                  <ModalBody>
                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-deliveryNote-input"
                        className="col-md-5 col-form-label"
                      >
                        Call Date
                      </Label>
                      <Col md={7}>
                        <div
                          style={{
                            border: '1px solid gray',
                            borderRadius: '5px',
                            paddingTop: '2.5px',
                            paddingBottom: '2.5px',
                            paddingRight: '4px',
                            paddingLeft: '4px',
                          }}
                        >
                          <DatePickerComponent
                            selectedDate={this.state.callDate}
                            handleSelectedDateChange={this.handleCallDateChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-deliveryNote-input"
                        className="col-md-5 col-form-label"
                      >
                        Call Time
                      </Label>
                      <Col md={7}>
                        <Input
                          type="time"
                          value={this.state.callTime}
                          placeholder="Please input deliveryNote of the new SKU"
                          id="add-sku-deliveryNote-input"
                          onChange={this.handleCallTimeChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-product-type-dropdown"
                        className="col-md-5 col-form-label"
                      >
                        Call Status
                      </Label>
                      <Col md={7}>
                        <Dropdown
                          isOpen={this.state.agentCallStatusMenu}
                          toggle={(event) =>
                            this.setState({
                              agentCallStatusMenu:
                                !this.state.agentCallStatusMenu,
                            })
                          }
                        >
                          <DropdownToggle className="px-5 py-3" color="light">
                            {this.state.agentCallStatus}{' '}
                            <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={(event) => {
                                this.setState({
                                  agentCallStatus: 'Received',
                                });
                              }}
                            >
                              Received
                            </DropdownItem>

                            <DropdownItem
                              onClick={(event) => {
                                this.setState({
                                  agentCallStatus: 'Rejected',
                                });
                              }}
                            >
                              Rejected
                            </DropdownItem>
                            <DropdownItem
                              onClick={(event) => {
                                this.setState({
                                  agentCallStatus: 'Unreachable',
                                });
                              }}
                            >
                              Unreachable
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-deliveryNote-input"
                        className="col-md-5 col-form-label"
                      >
                        Call Note
                      </Label>
                      <Col md={7}>
                        <TextField
                          className="w-full"
                          variant="outlined"
                          type="text"
                          multiline
                          minRows={2}
                          style={{
                            width: '100%',
                          }}
                          value={this.state.callNote}
                          placeholder="Please input call note of the Delivery"
                          id="add-callNote-input"
                          onChange={this.handleCallNoteChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="add-sku-designation-input"
                        className="col-md-5 col-form-label"
                      >
                        Agent Name
                      </Label>
                      <Col md={7}>
                        <Input
                          type="text"
                          value={this.state.agentName}
                          placeholder="Please input Name of the calling agent"
                          id="add-calling-agent-name"
                          onChange={this.handleAgentNameChange}
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_agentCallingModal}
                      color="light"
                      className="waves-effect"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={() => {
                        this.tog_agentCallingStatusChangeConfirmation();
                      }}
                    >
                      Change Agent Calling Status
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  isOpen={this.state.agentCallStatusChangeConfirmation}
                  toggle={this.tog_agentCallingStatusChangeConfirmation}
                >
                  <ModalHeader
                    toggle={() =>
                      this.setState({
                        agentCallStatusChangeConfirmation: false,
                      })
                    }
                  >
                    Confirmation
                  </ModalHeader>

                  <ModalBody>
                    <Row className="mb-3">
                      <Col md={6} className="mb-3">
                        <h6 className="fw-bold">Customer Phone</h6>
                        <p>{this.state.key?.customerPhone}</p>
                      </Col>
                      <Col md={6} className="mb-3">
                        <h6 className="fw-bold">Invoice Id</h6>
                        <p>{this.state.key?.trxRefID}</p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={6} className="mb-3">
                        <h6 className="fw-bold">Call Time</h6>
                        <p>
                          {moment(this.state.callDate).format('YYYY-MM-DD')}{' '}
                          {moment(this.state.callTime, 'HH:mm').format(
                            'hh:mm A'
                          )}
                        </p>
                      </Col>

                      <Col md={6} className="mb-3">
                        <h6 className="fw-bold">Agent Name</h6>
                        <p>{this.state.agentName}</p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={6} className="mb-3">
                        <h6 className="fw-bold">Call Note</h6>
                        <p>{this.state.callNote}</p>
                      </Col>
                      <Col md={6} className="mb-3">
                        <h6 className="fw-bold">Call Status</h6>
                        <p>{this.state.agentCallStatus}</p>
                      </Col>
                    </Row>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_agentCallingStatusChangeConfirmation}
                      color="light"
                      className="waves-effect"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="waves-effect"
                      color="primary"
                      onClick={async (event) => {
                        this.handleSubmitAgentCallStatus(event);
                        this.tog_agentCallingStatusChangeConfirmation();
                      }}
                    >
                      Change Status
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              {/* modal for authorize delivery status change */}
              <div id="41">
                <Modal
                  isOpen={this.state.modal_standard3}
                  toggle={this.tog_standard3}
                  // size='lg'
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_standard3: false })}
                  >
                    Delivery Authorization
                  </ModalHeader>

                  <ModalBody>
                    <Row className="">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Customer Name</h6>
                        <p>{this.state.key?.customerName}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Customer Phone</h6>
                        <p>{this.state.key?.customerPhone}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Amount (in GM)</h6>
                        <p>{this.state.key?.goldAmount}</p>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Transaction Date</h6>
                        <p>
                          {moment(
                            this.state.key?.trxDate,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('DD-MM-YYYY hh:mm A')}
                        </p>
                      </Col>
                      <Col md={8} className="mb-3">
                        <h6 className="fw-bold">Product Details</h6>
                        <p>{this.state.key?.productDetails}</p>
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Delivery Time</h6>
                        <p>
                          {moment(this.state.requestData.DELIVERY_DATE).format(
                            'YYYY-MM-DD'
                          )}{' '}
                          {moment(
                            this.state.requestData.DELIVERY_TIME,
                            'HH:mm'
                          ).format('hh:mm A')}
                        </p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Delivery Note</h6>
                        <p>{this.state.requestData.DELIVERY_NOTE}</p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Delivered By</h6>
                        <p>{this.state.requestData.DELIVERY_PERSON}</p>
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Delivery Document</h6>
                        <a
                          href={this.state.requestData.CHALAN_URL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Document
                        </a>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Call Time</h6>
                        <p>
                          {moment(this.state.requestData.agentCallDate).format(
                            'YYYY-MM-DD'
                          )}{' '}
                          {moment(
                            this.state.requestData.agentCallTime,
                            'HH:mm'
                          ).format('hh:mm A')}
                        </p>
                      </Col>
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Agent Name</h6>
                        <p>{this.state.requestData.agentName}</p>
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md={4} className="mb-3">
                        <h6 className="fw-bold">Call Status</h6>
                        <p>{this.state.requestData.agentCallStatus}</p>
                      </Col>
                      <Col md={6} className="mb-3">
                        <h6 className="fw-bold">Call Note</h6>
                        <p>{this.state.requestData.agentCallNote}</p>
                      </Col>
                    </Row>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={() => {
                        this.tog_standard3();
                        this.tog_rejectModal();
                      }}
                      color="light"
                      className="waves-effect"
                    >
                      Reject
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={() => {
                        this.tog_standard3();
                        this.setState({
                          authModal: true,
                        });
                      }}
                    >
                      Authorize Delivery Status
                    </Button>
                  </ModalFooter>
                </Modal>
                <Modal
                  isOpen={this.state.authModal}
                  toggle={this.tog_authModal}
                >
                  <ModalHeader
                    toggle={() => this.setState({ authModal: false })}
                  >
                    Confirmation
                  </ModalHeader>

                  <ModalBody>
                    <Row className="mb-3">
                      <h6>Are you sure you want to Authorize this Delivery?</h6>
                    </Row>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_authModal}
                      color="light"
                      className="waves-effect"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="waves-effect"
                      color="primary"
                      onClick={async (event) => {
                        event.preventDefault();

                        const token = getToken();

                        let sendData = {
                          cmpId: 170,
                          collectId: this.state.requestData.COLLECT_ID,
                          recordId: this.state.requestData.RECUID,
                          deliveryStatus: 'Approved',
                          deviceInfo: this.state.deviceInfo,
                        };

                        // return
                        await GKApi.patch(
                          '/finance/collect/delivery-status',
                          sendData,
                          {
                            headers: {
                              Authorization: token,
                              // cmpId: 10,
                            },
                          }
                        );

                        // the api call
                        const response = await GKApi.get(
                          `/finance/collect/pending?pageNumber=${this.state.pageNumber}&pageSize=10`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 169,
                            },
                          }
                        );

                        this.setState({
                          deliveryData: response.data.data,
                          totalPageCount:
                            response.data.paginationInfo.totalPage,
                          pageNumber: response.data.paginationInfo.pageNumber,
                        });

                        this.tog_authModal();
                      }}
                    >
                      Authorize
                    </Button>
                  </ModalFooter>
                </Modal>
                <Modal
                  isOpen={this.state.rejectModal}
                  toggle={this.tog_rejectModal}
                >
                  <ModalHeader
                    toggle={() => this.setState({ rejectModal: false })}
                  >
                    Confirmation
                  </ModalHeader>

                  <ModalBody>
                    <Row className="mb-3">
                      <h6>
                        Are you sure you want to Reject this Delivery Request?
                      </h6>
                    </Row>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_rejectModal}
                      color="light"
                      className="waves-effect"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="waves-effect"
                      color="primary"
                      onClick={async (event) => {
                        event.preventDefault();

                        const token = getToken();

                        let sendData = {
                          cmpId: 170,
                          collectId: this.state.requestData.COLLECT_ID,
                          recordId: this.state.requestData.RECUID,
                          deliveryStatus: 'Rejected',
                          deviceInfo: this.state.deviceInfo,
                        };

                        // return
                        await GKApi.patch(
                          '/finance/collect/delivery-status',
                          sendData,
                          {
                            headers: {
                              Authorization: token,
                              // cmpId: 10,
                            },
                          }
                        );

                        // the api call
                        const response = await GKApi.get(
                          `/finance/collect/pending?pageNumber=${this.state.pageNumber}&pageSize=10`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 169,
                            },
                          }
                        );

                        this.setState({
                          deliveryData: response.data.data,
                          totalPageCount:
                            response.data.paginationInfo.totalPage,
                          pageNumber: response.data.paginationInfo.pageNumber,
                        });

                        this.tog_rejectModal();
                      }}
                    >
                      Reject
                    </Button>
                  </ModalFooter>
                </Modal>

                {/* verify code modal */}

                <Modal
                  isOpen={this.state.verifyCodeModal}
                  toggle={this.tog_verifyCodeModal}
                >
                  <ModalHeader
                    toggle={() => this.setState({ verifyCodeModal: false, errorIncorrectCode:'' })}
                  >
                    Verify Security Code
                  </ModalHeader>

                  <ModalBody>
                    <Row className="mb-3">
                      <Label
                        htmlFor="verify-code-input"
                        className="col-md-3 col-form-label"
                      >
                        Security code
                      </Label>
                      <Col md={9}>
                        <Input
                          type="text"
                          value={this.state.securityCode}
                          placeholder="Please input security code for verification"
                          id="verify-security-Code"
                          onChange={this.handleSecurityCodeChange}
                        />
                      </Col>
                    </Row>

                    <p className='fw-bold d-flex justify-content-center text-danger'> {this.state.errorIncorrectCode}</p>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_verifyCodeModal}
                      color="light"
                      className="waves-effect"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="waves-effect"
                      color="primary"
                      onClick={async (event) => {
                        event.preventDefault();

                        const token = getToken();

                        let sendData = {
                          trxID: this.state.key.trxID,
                          code: this.state.securityCode,
                          cmpId: 174,
                        };

                        // return

                        try
                        {
                          await GKApi.post(
                          '/staff/delivery:verify-security-code',
                          sendData,
                          {
                            headers: {
                              Authorization: token,
                              // cmpId: 10,
                            },
                          }
                        );

                        const response = await GKApi.get(
                          `/finance/collect/pending?pageNumber=${this.state.pageNumber}&pageSize=10`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 169,
                            },
                          }
                        );

                        this.setState({
                          deliveryData: response.data.data,
                          totalPageCount:
                            response.data.paginationInfo.totalPage,
                          pageNumber: response.data.paginationInfo.pageNumber,
                        });

                        this.tog_verifyCodeModal();
                      }catch(err){
                        this.setState({errorIncorrectCode:err.response.data.message ? err.response.data.message:'Operation not allowed'})
                        // console.log('eeror',err.response.data.message)
                        }

                        
                        

                       
                      }}
                    >
                      Verify
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              {/* modal for approved delivery details */}
              <Modal
                isOpen={this.state.approvedDeliveryDetails}
                toggle={this.tog_approvedDeliveryDetails}
                size="lg"
                style={{ maxWidth: '700px', width: '100%' }}
              >
                <ModalHeader
                  toggle={() =>
                    this.setState({ approvedDeliveryDetails: false })
                  }
                >
                  Delivery Details
                </ModalHeader>

                <ModalBody>
                  <Row className="mb-3">
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Customer Name</h6>
                      <p>{this.state.key?.customerName}</p>
                    </Col>
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Customer Phone</h6>
                      <p>{this.state.key?.customerPhone}</p>
                    </Col>
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Transaction Date</h6>
                      <p>
                        {moment(
                          this.state.key?.trxDate,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD-MM-YYYY hh:mm A')}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Transaction Reference Id</h6>
                      <p>{this.state.key?.trxRefID}</p>
                    </Col>
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Amount (in GM)</h6>
                      <p>{this.state.key?.goldAmount}</p>
                    </Col>
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Product Details</h6>
                      <p>{this.state.key?.productDetails}</p>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Recipient Phone</h6>
                      <p>{this.state.key?.recipientPhone}</p>
                    </Col>
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Recipient name</h6>
                      <p>{this.state.key?.recipientName}</p>
                    </Col>
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Recipient Address</h6>
                      <p>{this.state.key?.recipientAddress}</p>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Delivery Date</h6>
                      <p>
                        {moment(
                          this.state.key?.deliveryTime,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD-MM-YYYY hh:mm A')}
                      </p>
                    </Col>
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Delivery Note</h6>
                      <p>{this.state.key?.deliveryNote}</p>
                    </Col>
                    <Col md={4} className="mb-3">
                      <h6 className="fw-bold">Delivery Chalan</h6>
                      <a
                        href={this.state.chalanUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Delivery Chalan
                      </a>
                    </Col>
                  </Row>

                  <Row className="">
                    <Col md={3} className="mb-3">
                      <h6 className="fw-bold">Call Time</h6>
                      <p>
                        {moment(this.state.key?.agentCallDate).format(
                          'YYYY-MM-DD'
                        )}{' '}
                        {moment(this.state.key?.agentCallTime, 'HH:mm').format(
                          'hh:mm A'
                        )}
                      </p>
                    </Col>
                    <Col md={3} className="mb-3">
                      <h6 className="fw-bold">Agent Name</h6>
                      <p>{this.state.key?.agentName}</p>
                    </Col>
                    <Col md={3} className="mb-3">
                      <h6 className="fw-bold">Call Note</h6>
                      <p>{this.state.key?.agentCallNote}</p>
                    </Col>
                    <Col md={3} className="mb-3">
                      <h6 className="fw-bold">Call Status</h6>
                      <p>{this.state.key?.agentCallStatus}</p>
                    </Col>
                  </Row>
                  <Row className=""></Row>
                </ModalBody>
              </Modal>
              {this.state.createConfirmation ? (
                <SweetAlert
                  title="Security code Send SuccessFully!!"
                  timeout={2500}
                  style={{
                    top: '0px',
                    right: '0px',
                  }}
                  showCloseButton={false}
                  showConfirm={false}
                  success
                  onConfirm={() => this.setState({ createConfirmation: false })}
                ></SweetAlert>
              ) : null}
              {this.state.activeTabJustify === '2' &&
                (this.state.isLoading ? (
                  <h5
                    className="d-flex justify-content-center my-3
gap-5"
                  >
                    <CircularProgress color="inherit" />
                  </h5>
                ) : (
                  <div className="my-3 d-flex justify-content-center gap-5">
                    <Button
                      onClick={async () => {
                        let token = getToken();
                        //get api call with needed parameters

                        if (
                          (this.state.searchStartDate !== null &&
                            this.state.searchEndDate !== null) ||
                          this.state.searchKey !== ''
                        ) {
                          let submitData = await GKApi.get(
                            `/finance/collect/approved/delivery-list?pageNumber=${
                              this.state.pageNumber - 1
                            }&pageSize=10${
                              this.state.searchKey !== ''
                                ? `&phone=${this.state.searchKey}`
                                : ``
                            }${
                              this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null
                                ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                : ''
                            }`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 169,
                              },
                            }
                          );
                          this.setState(
                            {
                              approvedDeliveries: submitData.data.data,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                            },
                            () => {}
                          );
                        } else {
                          let submitData = await GKApi.get(
                            `/finance/collect/approved/delivery-list?pageNumber=${
                              this.state.pageNumber - 1
                            }&pageSize=10`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 169,
                              },
                            }
                          );
                          this.setState(
                            {
                              approvedDeliveries: submitData.data.data,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                            },
                            () => {}
                          );
                        }
                      }}
                      disabled={this.state.pageNumber === 1 ? true : false}
                    >
                      Previous page
                    </Button>{' '}
                    <Button
                      onClick={async () => {
                        let token = getToken();
                        // this.setState({ isLoading: true });
                        //get api call with needed parameters

                        if (
                          (this.state.searchStartDate !== null &&
                            this.state.searchEndDate !== null) ||
                          this.state.searchKey !== ''
                        ) {
                          let submitData = await GKApi.get(
                            `/finance/collect/approved/delivery-list?&pageNumber=${
                              this.state.pageNumber + 1
                            }&pageSize=10${
                              this.state.searchKey !== ''
                                ? `&phone=${this.state.searchKey}`
                                : ``
                            }${
                              this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null
                                ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                : ''
                            }`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 169,
                              },
                            }
                          );
                          if (submitData) {
                            this.setState({
                              isLoading: false,
                            });
                          }
                          this.setState(
                            {
                              approvedDeliveries: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        } else {
                          let submitData = await GKApi.get(
                            `/finance/collect/approved/delivery-list?&pageNumber=${
                              this.state.pageNumber + 1
                            }&pageSize=10`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 169,
                              },
                            }
                          );
                          if (submitData) {
                            this.setState({
                              isLoading: false,
                            });
                          }
                          this.setState(
                            {
                              approvedDeliveries: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        }
                      }}
                      disabled={
                        this.state.pageNumber === this.state.totalPageCount
                      }
                    >
                      {this.state.isLoading ? 'Loading Next Page' : 'Next Page'}
                    </Button>
                  </div>
                ))}

              {this.state.activeTabJustify === '1' &&
                (this.state.isLoading ? (
                  <h5 className="d-flex justify-content-center my-3 gap-5">
                    <CircularProgress color="inherit" />
                  </h5>
                ) : (
                  <div className="my-3 d-flex justify-content-center gap-5">
                    <Button
                      onClick={async () => {
                        let token = getToken();
                        //get api call with needed parameters

                        if (
                          (this.state.searchStartDate !== null &&
                            this.state.searchEndDate !== null) ||
                          this.state.searchKey !== ''
                        ) {
                          let submitData = await GKApi.get(
                            `/finance/collect/pending?pageNumber=${
                              this.state.pageNumber - 1
                            }&pageSize=10${
                              this.state.searchKey !== ''
                                ? `&phone=${this.state.searchKey}`
                                : ``
                            }${
                              this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null
                                ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                : ''
                            }`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 169,
                              },
                            }
                          );
                          this.setState(
                            {
                              deliveryData: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        } else {
                          let submitData = await GKApi.get(
                            `/finance/collect/pending?pageNumber=${
                              this.state.pageNumber - 1
                            }&pageSize=10`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 169,
                              },
                            }
                          );
                          this.setState(
                            {
                              deliveryData: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        }
                      }}
                      disabled={this.state.pageNumber === 1 ? true : false}
                    >
                      Previous page
                    </Button>{' '}
                    <Button
                      onClick={async () => {
                        let token = getToken();
                        // this.setState({ isLoading: true });
                        //get api call with needed parameters

                        if (
                          (this.state.searchStartDate !== null &&
                            this.state.searchEndDate !== null) ||
                          this.state.searchKey !== ''
                        ) {
                          let submitData = await GKApi.get(
                            `/finance/collect/pending?&pageNumber=${
                              this.state.pageNumber + 1
                            }&pageSize=10${
                              this.state.searchKey !== ''
                                ? `&phone=${this.state.searchKey}`
                                : ``
                            }${
                              this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null
                                ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                : ''
                            }`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 169,
                              },
                            }
                          );
                          if (submitData) {
                            this.setState({
                              isLoading: false,
                            });
                          }
                          this.setState(
                            {
                              deliveryData: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        } else {
                          let submitData = await GKApi.get(
                            `/finance/collect/pending?&pageNumber=${
                              this.state.pageNumber + 1
                            }&pageSize=10`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 169,
                              },
                            }
                          );
                          if (submitData) {
                            this.setState({
                              isLoading: false,
                            });
                          }
                          this.setState(
                            {
                              deliveryData: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        }
                      }}
                      disabled={
                        this.state.pageNumber === this.state.totalPageCount
                      }
                    >
                      {this.state.isLoading ? 'Loading Next Page' : 'Next Page'}
                    </Button>
                  </div>
                ))}
              <div className="my-2 d-flex justify-content-center">
                <h5>
                  Page {this.state.pageNumber} of {this.state.totalPageCount}
                </h5>
              </div>
            </div>
          ) : (
            <h5>Sorry you don't have permission to view this</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default DeliveryManagement;

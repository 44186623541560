import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import { getToken, GKApi, roundToFour } from '../../../components/config';
// import './dashboard.scss';

class GoldReserveStatus extends Component {
  state = {
    series: [55, 35, 10],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Reserve', 'Sold', 'Hold'],
      dataLabels: {
        enabled: false,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          textColor: '#ffff',
          colors: ['#FFFFFF', '#FFFFFF'],
        },
        background: {
          enabled: true,
          foreColor: '#fff',
        },
      },
      colors: ['#00FF00', '#FF0000', '#808080'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    reserveDetails: {},
  };

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();
    const reserveData = [];
    const response = await GKApi.get('/finance/reserve', {
      headers: {
        Authorization: token,
        cmpId: 5,
      },
    });
    if (response.data.data) {
      response.data.data.currentReservePercentage === null &&
      response.data.data.soldPercentage === null
        ? reserveData.push(0)
        : reserveData.push(
            response.data.data.currentReservePercentage,
            response.data.data.soldPercentage,
            response.data.data.onHoldPercentage,
          );
      this.setState({
        series: reserveData,
        reserveDetails: response.data.data,
      });
    }

  }
  render() {
    return (
      <React.Fragment>
        <Card>
          {this.state.series && this.state.options ? (
            <CardBody>
              <h4 className="card-title mb-4">Gold Reserve</h4>

              <div id="donut-chart">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="donut"
                  height="250"
                />
              </div>

              <Row>
                <Col xs={6}>
                  <div className="text-center mt-4">
                    <p className="mb-2 text-truncate">
                      <i
                        className="mdi mdi-circle  font-size-10 me-1"
                        style={{ color: '#00FF00' }}
                      ></i>{' '}
                      Reserve
                    </p>
                    {this.state.reserveDetails ? (
                      <h5>
                        {this.state.reserveDetails.currentReservePercentage}% (
                        {this.state.reserveDetails.currentReserveInGM === null
                          ? 0
                          : 
                              roundToFour(this.state.reserveDetails.currentReserveInGM)
                            }{' '}
                        gm)
                      </h5>
                    ) : (
                      <p>Data not available</p>
                    )}
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="text-center mt-4">
                    <p className="mb-2 text-truncate">
                      <i
                        className="mdi mdi-circle font-size-10 me-1"
                        style={{ color: '#FF0000' }}
                      ></i>{' '}
                      Sold
                    </p>
                    {this.state.reserveDetails ? (
                      <h5>
                        {this.state.reserveDetails.soldPercentage}% (
                        {this.state.reserveDetails.soldInGM === null
                          ? 0
                          : this.state.reserveDetails.soldInGM}{' '}
                        gm)
                      </h5>
                    ) : (
                      <p>Data not available</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
              
                <Col xs={6}>
                  <div className="text-center mt-4">
                    <p className="mb-2 text-truncate">
                    <i
                        className="mdi mdi-circle  font-size-10 me-1"
                        style={{ color: '#808080' }}
                      ></i>
                      Hold
                    </p>
                    {this.state.reserveDetails ? (
                      <h5>
                      {this.state.reserveDetails.onHoldPercentage}% (
                      {this.state.reserveDetails.onHoldInGM === null
                        ? 0
                        : this.state.reserveDetails.onHoldInGM}{' '}
                      gm)
                    </h5>
                    ) : (
                      <p>Data not available</p>
                    )}
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="text-center mt-4">
                    <p className="mb-2 text-truncate">
                      
                      Total Today's Stock
                    </p>
                    {this.state.reserveDetails ? (
                      <h5>
                        
                        {this.state.reserveDetails.totalReserveInGM === null
                          ? 0
                          : this.state.reserveDetails.totalReserveInGM}{' '}
                        gm
                      </h5>
                    ) : (
                      <p>Data not available</p>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          ) : (
            <CardBody>
              {' '}
              <h1 className="text-muted">Not Avaialble</h1>
            </CardBody>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

export default GoldReserveStatus;

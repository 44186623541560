import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { getToken, GKApi } from '../../../components/config';
import moment from 'moment';

// Table data

class CancelledInboxMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canceledGenInboxList: [],
      canceledCampInboxList: [],
    };
  }

  async componentDidMount() {
    const token = getToken();
    const cancelledInbox = await GKApi.get(
      '/marketing/campaign/stop-list?type=inbox',
      {
        headers: {
          Authorization: token,
        },
      }
    );

    let cancelledGenInbox = [];
    let cancelledCampInbox = [];
    cancelledInbox.data.forEach((al) => {
      if (al.type === 'GENERAL') {
        cancelledGenInbox.push(al);
      } else {
        cancelledCampInbox.push(al);
      }
    });
    this.setState({
      canceledGenInboxList: cancelledGenInbox,
      canceledCampInboxList: cancelledCampInbox,
    });
  }

  render() {
    const genInboxColumns = [
      {
        field: 'communicationTitle',
        title: 'Communication Title',
      },
      {
        field: 'message',
        title: 'Message',
      },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },

      {
        field: 'status',
        title: 'Status',
      },
    ];

    const campInboxColumns = [
      {
        field: 'communicationTitle',
        title: 'Communication Title',
      },
      {
        field: 'message',
        title: 'Message',
      },
      {
        field: 'type',
        title: 'Communication Type',
      },
      {
        title: 'Tenure',
        render: (row) => (
          <div>
            {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')} -
            {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('Do MMM')}
          </div>
        ),
      },

      {
        field: 'status',
        title: 'Status',
      },
    ];

    return (
      <React.Fragment>
        <MaterialTable
          title="General Communication"
          columns={genInboxColumns}
          data={this.state.canceledGenInboxList}
          options={{
            cellStyle: {
              borderRight: '1px solid #eee',
              textAlign: 'center',
              whiteSpace: 'nowrap',
            },
            headerStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },

            actionsColumnIndex: -1,
            exportFileName: 'User',
            pageSize: 5,
            columnsButton: true,
          }}
          localization={{
            toolbar: {},
          }}
        />
        <MaterialTable
          title="Campaign Communication"
          columns={campInboxColumns}
          data={this.state.canceledCampInboxList}
          options={{
            cellStyle: {
              borderRight: '1px solid #eee',
              textAlign: 'center',
              whiteSpace: 'nowrap',
            },
            headerStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },

            actionsColumnIndex: -1,
            exportFileName: 'User',
            pageSize: 5,
            columnsButton: true,
          }}
          localization={{
            toolbar: {},
          }}
        />
      </React.Fragment>
    );
  }
}

export default CancelledInboxMessage;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
import {
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  NavLink,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Label,
  Col,
  Input,
  Form,
  FormGroup,
} from 'reactstrap';
import classnames from 'classnames';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getToken, getUser, GKApi } from '../../../components/config';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import TimePickerComponent from '../../../components/Common/TimePicker';
import { deviceInfo } from '../../../helpers/device_info_helper';
// Table data

class CustomRefer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referData: [],
      visible: false,
      modal_standard: false,
      presetData: [],
      user: 0,
      change_status_modal: false,
      enableRefer: false,
      createRefer: false,
      canDeletePreset: 0,
      activeTabJustify: '1',
      approveModal: false,
      rejectModal: false,
      referId: '',
      referCode: '',
      referCodeTitle: '',
      referCodeDescription: '',
      startDate: null,
      endDate: null,
      deviceInfo: {},
      rejectReason: '',
      pendingReferList: [],
      entryStaffId: '',
      lastAuthorizerId: '',
      authStatus: 1,
      canCreateRefer: 0,
      canCreateReferAuth: 0,
      requestedStatus: 'play',
      canPlayPause: 0,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_ChangeStatusModal = () => {
    this.setState((prevState) => ({
      change_status_modal: !prevState.change_status_modal,
    }));
    this.removeBodyCss();
  };

  tog_ApproveModal = () => {
    this.setState((prevState) => ({
      approveModal: !prevState.approveModal,
    }));
    this.removeBodyCss();
  };

  tog_RejectModal = () => {
    this.setState((prevState) => ({
      rejectModal: !prevState.rejectModal,
    }));
    this.removeBodyCss();
  };

  tog_EnableModal = () => {
    this.setState((prevState) => ({
      enableRefer: !prevState.enableRefer,
    }));
    this.removeBodyCss();
  };

  tog_createRefer = () => {
    this.setState((prevState) => ({
      createRefer: !prevState.createRefer,
    }));
    this.removeBodyCss();
  };

  removeBodyCss() {
    document.body.classList.add('no_padding');
  }
  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };
  async componentDidMount() {
    const token = getToken();
    const getPermission = await GKApi.get(
      `/role/permission?cmpId=163,164,168`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    this.setState({
      canDeletePreset: permissionData[163].canDelete,
      authStatusForUser: permissionData[164].authState,
      canCreateRefer: permissionData[164].canInsert,
      canCreateReferAuth: permissionData[164].canAuth,
      canPlayPause: permissionData[168].canAuth,
    });

    const customReferList = await GKApi.get(
      `/finance/custom-refer?type=approved&pageSize=100000&pageNumber=1`,
      {
        headers: {
          cmpId: 163,
          Authorization: token,
        },
      }
    );
    this.setState({ referData: customReferList.data.data });

    const pendingReferList = await GKApi.get(
      `/finance/custom-refer?type=pending&pageSize=100000&pageNumber=1`,
      {
        headers: {
          cmpId: 163,
          Authorization: token,
        },
      }
    );
    this.setState({ pendingReferList: pendingReferList.data.data });


    const res = deviceInfo();
    this.setState({ deviceInfo: res }, () => {});
  }

  handleReferCodeChange = (event) => {
    this.setState({
      ...this.state,
      referCode: event.target.value.toUpperCase(),
    });
  };

  handleReferCodeTitleChange = (event) => {
    this.setState({ ...this.state, referCodeTitle: event.target.value });
  };
  handleDescriptionChange = (event) => {
    this.setState({ ...this.state, referCodeDescription: event.target.value });
  };
  handleStartDateChange = (event) => {
    this.setState({ ...this.state, startDate: event });
  };

  handleEndDateChange = (event) => {
    this.setState({ ...this.state, endDate: event });
  };
  handleReasonChange = (event) => {
    this.setState({ ...this.state, rejectReason: event.target.value });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const token = getToken();
    let sendData = {
      cmpId: 164,
      referCode: this.state.referCode,
      title: this.state.referCodeTitle,
      description: this.state.referCodeDescription,
      startDate: moment(this.state.startDate, 'DD-MM-YYYY').format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      endDate: moment(this.state.endDate, 'DD-MM-YYYY').format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      deviceInfo: this.state.deviceInfo,
    };

    await GKApi.post('/finance/custom-refer', sendData, {
      headers: {
        Authorization: token,
      },
    });

    // the api call
    const pendingReferList = await GKApi.get(
      `/finance/custom-refer?type=pending&pageSize=100000&pageNumber=1`,
      {
        headers: {
          cmpId: 163,
          Authorization: token,
        },
      }
    );

    this.setState({ pendingReferList: pendingReferList.data.data });

    this.tog_createRefer();
  };
  render() {
    const authDiff = this.state.authStatusForUser - this.state.authStatus;
    const columns = [
      {
        field: 'referCode',
        title: 'Refer Code',
      },
      {
        field: 'title',
        title: 'Refer Title',
      },
      {
        field: 'startDate',
        title: 'Refer Start Date',
      },
      {
        field: 'endDate',
        title: 'Refer End Date',
      },
      {
        field: 'referStatus',
        title: 'Current Status',
        render: (row) => <div>{row.referStatus==='stop'?'Stopped':row.referStatus==='pause'?'Paused':row.referStatus==='play'?'Playing':'Expired'}</div>,
      },
    ];

    const pendingReferListColumns = [
      {
        field: 'referCode',
        title: 'Refer Code',
      },
      {
        field: 'title',
        title: 'Refer Title',
      },
      {
        field: 'startDate',
        title: 'Refer Start Date',
      },
      {
        field: 'endDate',
        title: 'Refer End Date',
      },

      {
        field: 'authStatus',
        title: 'Last authorizer',
        render: (row) => (
          <div>
            {row.authStatus === 1
              ? 'Maker'
              : row.authStatus === 2
              ? 'Checker'
              : row.authStatus === 3
              ? 'Authorizer 1'
              : 'Authorizer 2'}
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <Nav tabs className="nav-tabs-custom nav-justified">
            {/*Refer list Tab  */}
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classnames({
                  active: this.state.activeTabJustify === '1',
                })}
                onClick={async () => {
                  this.toggleCustomJustified('1');
                }}
              >
                <span className="d-none d-sm-block fw-bold">
                  Custom Refer List
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classnames({
                  active: this.state.activeTabJustify === '2',
                })}
                onClick={async () => {
                  this.toggleCustomJustified('2');
                }}
              >
                <span className="d-none d-sm-block fw-bold">
                  Pending Custom Refer
                </span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTabJustify}>
            <TabPane tabId="1" className="p-3">
              <MaterialTable
                title="Custom Refer List"
                columns={columns}
                data={this.state.referData}
                actions={
                  this.state.canPlayPause === 1 && [
                    (rowData) =>
                      moment(new Date()).isBetween(
                        rowData.startDate,
                        rowData.endDate
                      ) && {
                        icon: () => (
                          <i className="fas fa-play font-size-40"></i>
                        ),
                        tooltip: 'Click to Play Refer',
                        onClick: async (event, row) => {
                          this.setState({
                            referId: rowData.recID,
                            requestedStatus: 'play',
                            change_status_modal: true,
                          });
                        },
                        hidden:
                          rowData.referStatus === 'play' ||
                          rowData.referStatus === 'stop',
                      },
                    (rowData) =>
                      moment(new Date()).isBetween(
                        rowData.startDate,
                        rowData.endDate
                      ) && {
                        icon: () => (
                          <i className="fas fa-pause font-size-40"></i>
                        ),
                        tooltip: 'Click to Pause Refer',
                        onClick: async (event, row) => {
                          this.setState({
                            referId: rowData.recID,
                            requestedStatus: 'pause',
                            change_status_modal: true,
                          });
                        },
                        hidden:
                          rowData.referStatus === 'pause' ||
                          rowData.referStatus === 'stop',
                      },

                    (rowData) =>
                      moment(new Date()).isBetween(
                        rowData.startDate,
                        rowData.endDate
                      ) && {
                        icon: () => (
                          <i className="fas fa-stop font-size-40"></i>
                        ),
                        tooltip: 'Click to View Stop Refer',
                        onClick: async (event, row) => {
                          this.setState({
                            referId: rowData.recID,
                            requestedStatus: 'stop',
                            change_status_modal: true,
                          });
                        },
                        hidden: rowData.referStatus === 'stop',
                      },
                  ]
                }
                options={{
                  cellStyle: {
                    borderRight: '1px solid #eee',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  },
                  headerStyle: {
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  },

                  actionsColumnIndex: -1,
                  exportFileName: 'User',
                  pageSize: 20,
                  columnsButton: true,
                }}
                localization={{
                  toolbar: {},
                }}
              />
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <MaterialTable
                title="Pending Custom Refer List"
                columns={pendingReferListColumns}
                data={this.state.pendingReferList}
                actions={[
                  (rowData) => ({
                    icon: VisibilityIcon,
                    tooltip: 'Click to View Details',
                    onClick: () => {
                      this.setState({
                        referId: rowData.recID,
                        modal_standard: true,
                        referCode: rowData.referCode,
                        referCodeTitle: rowData.title,
                        referCodeDescription: rowData.description,
                        startDate: rowData.startDate,
                        endDate: rowData.endDate,
                        entryStaffId: rowData.entryStaffID,
                        lastAuthorizerId: rowData.lastAuthorizerID,
                        authStatus: rowData.authStatus,
                      });
                    },
                  }),
                  (this.state.canCreateRefer === 1 ||
                    this.state.canCreateReferAuth === 1) && {
                    icon: () => <Button>Create Custom Refer</Button>, // you can pass icon too
                    tooltip: 'Add a Custom Refer Code',
                    onClick: () => this.setState({ createRefer: true }),
                    isFreeAction: true,
                  },
                ]}
                options={{
                  cellStyle: {
                    borderRight: '1px solid #eee',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  },
                  headerStyle: {
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  },

                  actionsColumnIndex: -1,
                  exportFileName: 'User',
                  pageSize: 20,
                  columnsButton: true,
                }}
                localization={{
                  toolbar: {},
                }}
              />
            </TabPane>
          </TabContent>

          <Modal isOpen={this.state.createRefer} toggle={this.tog_createRefer}>
            <ModalHeader toggle={() => this.setState({ createRefer: false })}>
              Create Custom Refer
            </ModalHeader>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <ModalBody>
                  <Row className="mb-3">
                    <Label
                      htmlFor="create-refer-code-input"
                      className="col-md-3 col-form-label"
                    >
                      Refer Code
                    </Label>
                    <Col md={9}>
                      <Input
                        type="text"
                        value={this.state.referCode}
                        placeholder="Please input new Refer Code"
                        id="create-refer-code-input"
                        onChange={this.handleReferCodeChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="create-refer-title-input"
                      className="col-md-3 col-form-label"
                    >
                      Refer Title
                    </Label>
                    <Col md={9}>
                      <Input
                        type="text"
                        value={this.state.referCodeTitle}
                        placeholder="Please input title of the refer"
                        id="create-refer-title-input"
                        onChange={this.handleReferCodeTitleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="create-refer-description-input"
                      className="col-md-3 col-form-label"
                    >
                      Refer Description
                    </Label>
                    <Col md={9}>
                      <div>
                        <TextField
                          className="w-full"
                          variant="outlined"
                          type="text"
                          multiline
                          minRows={2}
                          style={{
                            width: '100%',
                          }}
                          placeholder="Please input Refer Description"
                          value={this.state.referCodeDescription}
                          onChange={this.handleDescriptionChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="create-admin-date-of-birth-input"
                      className="col-md-3 col-form-label"
                    >
                      Start Date
                    </Label>
                    <Col md={9}>
                      <div
                        style={{
                          border: '1px solid',
                          borderRadius: '5px',
                          paddingTop: '5px',
                          paddingBottom: '5px',
                          paddingRight: '4px',
                          paddingLeft: '4px',
                        }}
                        className="d-flex gap-3 align-content-center"
                      >
                        <TimePickerComponent
                          selectedDate={this.state.startDate}
                          handleSelectedDateChange={this.handleStartDateChange}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Label
                      htmlFor="create-refer-end-date"
                      className="col-md-3 col-form-label"
                    >
                      End Date
                    </Label>
                    <Col md={9}>
                      <div
                        style={{
                          border: '1px solid',
                          borderRadius: '5px',
                          paddingTop: '5px',
                          paddingBottom: '5px',
                          paddingRight: '4px',
                          paddingLeft: '4px',
                        }}
                        className="d-flex gap-3 align-content-center"
                      >
                        <TimePickerComponent
                          selectedDate={this.state.endDate}
                          handleSelectedDateChange={this.handleEndDateChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_createRefer}
                    color="light"
                    className="waves-effect"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="waves-effect waves-light"
                  >
                    Create Refer Code
                  </Button>
                </ModalFooter>
              </FormGroup>
            </Form>
          </Modal>

          <Modal isOpen={this.state.modal_standard} toggle={this.tog_standard}>
            <ModalHeader
              toggle={() => this.setState({ modal_standard: false })}
            >
              {this.state.referCode}'s Refer Details
            </ModalHeader>
            <ModalBody>
              <Row className="mb-3">
                <Label
                  htmlFor="create-refer-code-input"
                  className="col-md-3 col-form-label"
                >
                  Refer Code
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    value={this.state.referCode}
                    placeholder="Please input new Refer Code"
                    id="create-refer-code-input"
                    onChange={this.handleReferCodeChange}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="create-refer-title-input"
                  className="col-md-3 col-form-label"
                >
                  Refer Title
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    value={this.state.referCodeTitle}
                    placeholder="Please input title of the refer"
                    id="create-refer-title-input"
                    onChange={this.handleReferCodeTitleChange}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="create-refer-description-input"
                  className="col-md-3 col-form-label"
                >
                  Refer Description
                </Label>
                <Col md={9}>
                  <div>
                    <TextField
                      className="w-full"
                      variant="outlined"
                      type="text"
                      multiline
                      minRows={2}
                      style={{
                        width: '100%',
                      }}
                      placeholder="Please input Refer Description"
                      value={this.state.referCodeDescription}
                      onChange={this.handleDescriptionChange}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="create-admin-date-of-birth-input"
                  className="col-md-3 col-form-label"
                >
                  Start Date
                </Label>
                <Col md={9}>
                  <div
                    style={{
                      border: '1px solid',
                      borderRadius: '5px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      paddingRight: '4px',
                      paddingLeft: '4px',
                    }}
                    className="d-flex gap-3 align-content-center"
                  >
                    <TimePickerComponent
                      selectedDate={this.state.startDate}
                      handleSelectedDateChange={this.handleStartDateChange}
                      disabled
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mb-3">
                <Label
                  htmlFor="create-refer-end-date"
                  className="col-md-3 col-form-label"
                >
                  End Date
                </Label>
                <Col md={9}>
                  <div
                    style={{
                      border: '1px solid',
                      borderRadius: '5px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      paddingRight: '4px',
                      paddingLeft: '4px',
                    }}
                    className="d-flex gap-3 align-content-center"
                  >
                    <TimePickerComponent
                      selectedDate={this.state.endDate}
                      handleSelectedDateChange={this.handleEndDateChange}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            {getUser() === this.state.entryStaffId ? (
              <h5 className="d-flex justify-content-center mb-3">
                You have already created this refer!
              </h5>
            ) : getUser() === this.state.lastAuthorizerId ? (
              <h5 className="d-flex justify-content-center mb-3">
                Sorry, you have already authorized this refer!
              </h5>
            ) : (
              (authDiff === 1 || this.state.authStatus === 3) && (
                <ModalFooter>
                  <Button
                    type="button"
                    onClick={() => {
                      this.tog_standard();
                      this.tog_ApproveModal();
                    }}
                    color="primary"
                    className="waves-effect"
                  >
                    Approve
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      this.tog_standard();
                      this.tog_RejectModal();
                    }}
                    color="light"
                    className="waves-effect"
                  >
                    Reject
                  </Button>
                </ModalFooter>
              )
            )}
          </Modal>

          <Modal isOpen={this.state.rejectModal} toggle={this.tog_RejectModal}>
            <ModalHeader toggle={() => this.setState({ rejectModal: false })}>
              Refer Reject Confirmation
            </ModalHeader>
            <ModalBody>
              {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

              <Row className="mb-3">
                Are you sure you want to reject this Refer?
              </Row>

              <Row className="mb-3">
                <Label
                  htmlFor="create-refer-title-input"
                  className="col-md-3 col-form-label"
                >
                  Refer reject reason:
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    value={this.state.rejectReason}
                    placeholder="Please input title of the refer"
                    id="create-refer-title-input"
                    onChange={this.handleReasonChange}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.rejectReason && (
                <Button
                  type="button"
                  onClick={async () => {
                    const token = getToken();
                    let sendData = {
                      cmpId: 164,
                      recID: this.state.referId,
                      deviceInfo: this.state.deviceInfo,
                      reason: this.state.rejectReason,
                    };

                    await GKApi.put('/finance/custom-refer/reject', sendData, {
                      headers: {
                        Authorization: token,
                      },
                    });

                    const pendingReferList = await GKApi.get(
                      `/finance/custom-refer?type=pending&pageSize=100000&pageNumber=1`,
                      {
                        headers: {
                          cmpId: 163,
                          Authorization: token,
                        },
                      }
                    );
                    this.setState({
                      pendingReferList: pendingReferList.data.data,
                    });

                    this.tog_RejectModal();
                  }}
                  color="primary"
                  className="waves-effect"
                >
                  Confirm
                </Button>
              )}
              <Button
                type="button"
                onClick={this.tog_RejectModal}
                color="light"
                className="waves-effect"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.approveModal}
            toggle={this.tog_ApproveModal}
          >
            <ModalHeader toggle={() => this.setState({ approveModal: false })}>
              Refer Approve Confirmation
            </ModalHeader>
            <ModalBody>
              {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

              <Row className="mb-3">
                Are you sure you want to approve this Refer?
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={async () => {
                  const token = getToken();
                  let sendData = {
                    cmpId: 164,
                    recID: this.state.referId,
                    deviceInfo: this.state.deviceInfo,
                  };

                  await GKApi.put('/finance/custom-refer/approve', sendData, {
                    headers: {
                      Authorization: token,
                    },
                  });

                  const pendingReferList = await GKApi.get(
                    `/finance/custom-refer?type=pending&pageSize=100000&pageNumber=1`,
                    {
                      headers: {
                        cmpId: 163,
                        Authorization: token,
                      },
                    }
                  );
                  this.setState({
                    pendingReferList: pendingReferList.data.data,
                  });
                  const referList = await GKApi.get(
                    `/finance/custom-refer?type=approved&pageSize=100000&pageNumber=1`,
                    {
                      headers: {
                        cmpId: 163,
                        Authorization: token,
                      },
                    }
                  );
                  this.setState({
                    referData: referList.data.data,
                  });

                  this.tog_ApproveModal();
                }}
                color="primary"
                className="waves-effect"
              >
                Confirm
              </Button>
              <Button
                type="button"
                onClick={this.tog_ApproveModal}
                color="light"
                className="waves-effect"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.change_status_modal}
            toggle={this.tog_ChangeStatusModal}
          >
            <ModalHeader
              toggle={() => this.setState({ change_status_modal: false })}
            >
              Refer Status Change Confirmation
            </ModalHeader>
            <ModalBody>
              {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

              <Row className="mb-3">
                Are you sure you want to {this.state.requestedStatus} this
                refer?
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={async (event) => {
                  event.preventDefault();
                  const token = getToken();
                   await GKApi.patch(
                    `/finance/custom-refer/play-pause/${this.state.referId}`,
                    {
                      status: this.state.requestedStatus,
                    },
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 168,
                      },
                    }
                  );

                  const referList = await GKApi.get(
                    `/finance/custom-refer?type=approved&pageSize=100000&pageNumber=1`,
                    {
                      headers: {
                        cmpId: 163,
                        Authorization: token,
                      },
                    }
                  );
                  this.setState({
                    referData: referList.data.data,
                  });

                  this.tog_ChangeStatusModal();
                }}
                color="primary"
                className="waves-effect"
              >
                Confirm
              </Button>
              <Button
                type="button"
                onClick={this.tog_ChangeStatusModal}
                color="light"
                className="waves-effect"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.enableRefer} toggle={this.tog_EnableModal}>
            <ModalHeader toggle={() => this.setState({ enableRefer: false })}>
              Refer Enable Confirmation
            </ModalHeader>
            <ModalBody>
              {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

              <Row className="mb-3">
                Are you sure you want to Enable this preset?
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={async () => {
                  this.tog_EnableModal();
                }}
                color="light"
                className="waves-effect"
              >
                Confirm
              </Button>
              <Button
                type="button"
                onClick={this.tog_EnableModal}
                color="light"
                className="waves-effect"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomRefer;

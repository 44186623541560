import React, { Component } from 'react';

import {
  Row,
  Col,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
} from 'reactstrap';
import classnames from 'classnames';
import CancelledNotification from './CancelledNotifiaction';
import CancelledInboxMessage from './CancelledInboxMessage';

class CancelledComChannelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabJustify: '1',
    };
  }

  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };

  render() {
    return (
      <>
        <Row>
          <Col xl="12">
            <Nav tabs className="nav-tabs-custom nav-justified">
              {/*Cancelled notification  */}
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: this.state.activeTabJustify === '1',
                  })}
                  onClick={async () => {
                    this.toggleCustomJustified('1');
                  }}
                >
                  <span className="d-none d-sm-block fw-bold">
                    Push Notification
                  </span>
                </NavLink>
              </NavItem>

              {/*Cancelled Inbox Message  */}
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: this.state.activeTabJustify === '2',
                  })}
                  onClick={async () => {
                    this.toggleCustomJustified('2');
                  }}
                >
                  <span className="d-none d-sm-block fw-bold">
                    Inbox Message
                  </span>
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTabJustify}>
              <TabPane tabId="1" className="p-3">
                <CancelledNotification />
              </TabPane>
              <TabPane tabId="2" className="p-3">
                <CancelledInboxMessage />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </>
    );
  }
}

export default CancelledComChannelList;

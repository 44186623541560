import React, { Component, Suspense } from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import { getGroupName, getToken, GKApi } from '../../../components/config';
//Import Components
const DailyOrderedSKU = React.lazy(() => import('./DailyOrderedSKU'));
const PendingDeliveryAgingStatus = React.lazy(() =>
  import('./PendingDeliveryAgingStatus')
);
const CollectionDeliveryStatus = React.lazy(() =>
  import('./CollectionDeliveryStatus')
);
const MostFrequentDeliveryLocation = React.lazy(() =>
  import('./MostFrequentDeliveryLocation')
);
const MostOrderedSKU = React.lazy(() => import('./MostOrderedSKU'));

class FinanceCollect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '/' },
        { title: 'Finance-Collect', link: '#' },
      ],

      pendingDeliveryAgingStatusViewAccess: 1,
      dailyOrderedSkuViewAccess: 1,
      collectionVSDeliveryViewAccess: 1,
      mostFrequentDeliveryLocationViewAccess: 1,
      mostOrderedSKUViewAccess: 1,
      isFin: false,
      isSuper: false,
    };
  }

  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    const token = getToken();
    // const groupId = getGroupId();
    // getting permission data from api
    const getPermission = await GKApi.get(
      `/role/permission?cmpId=11,12,13,14,15`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    this.setState({
      pendingDeliveryAgingStatusViewAccess: permissionData[11].canView,
      dailyOrderedSkuViewAccess: permissionData[12].canView,
      collectionVSDeliveryViewAccess: permissionData[13].canView,
      mostFrequentDeliveryLocationViewAccess: permissionData[14].canView,
      mostOrderedSKUViewAccess: permissionData[15].canView,
    });
  }

  render() {
    return (
      <React.Fragment>
          <div className="page-content">
        {this.state.isSuper === true || this.state.isFin === true ? (
            <Container fluid>
              <Breadcrumbs
                title="Finance Collect"
                breadcrumbItems={this.state.breadcrumbItems}
              />
              <Suspense
                fallback={<Spinner />}
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-20%, -50%)',
                }}
              >
                <Row>
                  <Col xl={8}>
                    <div id="11">
                      {this.state.pendingDeliveryAgingStatusViewAccess === 1 ? (
                        <PendingDeliveryAgingStatus />
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>

                  <Col xl={4}>
                    <div id="12">
                      {this.state.dailyOrderedSkuViewAccess === 1 ? (
                        <DailyOrderedSKU />
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>
                  <Col xl={12}>
                    {' '}
                    <div id="13">
                      {this.state.collectionVSDeliveryViewAccess === 1 ? (
                        <CollectionDeliveryStatus />
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  {/* Frequent delivery location */}
                  <Col xl={6}>
                    <div id="14">
                      {' '}
                      {this.state.mostFrequentDeliveryLocationViewAccess ===
                      1 ? (
                        <MostFrequentDeliveryLocation />
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>

                  {/* Most Ordered SKU */}
                  <Col xl="6">
                    <div id="15">
                      {this.state.mostOrderedSKUViewAccess === 1 ? (
                        <MostOrderedSKU />
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>
                </Row>
              </Suspense>
            </Container>
            ) : (
              <h5>Sorry you don't have permission to view this.</h5>
            )}
          </div>
      </React.Fragment>
    );
  }
}

export default FinanceCollect;

import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalFooter,
} from 'reactstrap';
import {
  capitalizeFirstLetter,
  getToken,
  getUser,
  GKApi,
  GKAPI_Base,
} from '../../../components/config';
import { Link } from 'react-router-dom';
// import { identity } from '@fullcalendar/react';
import DatePicker from '../../../components/Common/DatePicker';
import moment from 'moment';
import axios from 'axios';
import { deviceInfo } from '../../../helpers/device_info_helper';

class DownloadAudience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_standard: false,
      modal_standard1: false,
      col1: false,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      headerName: [],
      headerFields: {},
      audienceValues: [],
      email: '',
      customerId: '',
      name: '',
      dobSelectedStartDate: null,
      dobSelectedEndDate: null,
      dojSelectedStartDate: null,
      dojSelectedEndDate: null,
      ageStart: 0,
      ageEnd: 0,
      gender: 'Select Gender',
      genderMenu: false,
      maritalStat: 'Select Marital Status',
      maritalStatMenu: false,
      phoneNumber: '',
      nomineeName: '',
      nomineeDobSelectedStartDate: null,
      nomineeDobSelectedEndDate: null,
      nomineePhoneNumber: '',
      profileStat: 'Select Profile Status',
      profileStatMenu: false,
      goldBalanceFrom: 0,
      goldBalanceTo: 0,
      profession: 'Select Profession',
      professionCode: 0,
      professionList: [],
      professionListMenu: false,
      mobileOperator: 'Select Mobile Operator',
      mobileOperatorMenu: false,
      sellGoldMarketPriceAverage: 0,
      sellGoldTrxCount: 0,
      sellGoldTrxQuantity: 0,
      sellGoldTrxModeMFS: 0,
      sellGoldTrxModeBank: 0,
      giftGoldMarketPriceAverage: 0,
      giftGoldTrxCount: 0,
      giftGoldTrxQuantity: 0,
      collectGoldFavGoldAccount: '',
      collectCoin2gCount: 0,
      collectCoin2gWeight: 0,
      collectCoin4gCount: 0,
      collectCoin4gWeight: 0,
      collectBar5gCount: 0,
      collectBar5gWeight: 0,
      collectBar10gCount: 0,
      collectBar10gWeight: 0,
      collectGoldTrxSKUCount: 0,
      collectGoldTrxSKUWeight: 0,
      collectGoldSKU: 0,
      buyGoldMarketPriceAverage: 0,
      buyGoldTrxCountStart: 0,
      buyGoldTrxCountEnd: 0,
      buyGoldTrxQuantityStart: 0,
      buyGoldTrxQuantityEnd: 0,
      buyGoldTrxModeMFS: 0,
      presetData: [],
      childValues: [],
      selectedMulti: null,
      audienceSize: null,
      presetName: '',
      deviceInfo: {},
      savePresetModal: false,
    };
  }
  // handleMulti = (selectedMulti) => {
  //   this.setState({ selectedMulti });
  // };
  tog_standard = () => {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
      reason: '',
    }));
    this.removeBodyCss();
  };

  tog_standard1 = () => {
    this.setState((prevState) => ({
      modal_standard1: !prevState.modal_standard1,
    }));
    this.removeBodyCss();
  };
  tog_savePresetModal = () => {
    this.setState((prevState) => ({
      savePresetModal: !prevState.savePresetModal,
    }));
    this.removeBodyCss();
  };

  removeBodyCss = () => {
    document.body.classList.add('no_padding');
  };

  /*
   * @desc accordion handlers
   */
  t_col1 = () => {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    });
  };
  t_col2 = () => {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
      col5: false,
    });
  };

  t_col3 = () => {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
      col5: false,
    });
  };

  t_col4 = () => {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
      col5: false,
    });
  };

  t_col5 = () => {
    this.setState({
      col5: !this.state.col5,
      col2: false,
      col3: false,
      col4: false,
      col1: false,
    });
  };

  /*
   * @desc accordion handlers
   */

  /*
   * @desc checkbox handlers
   */
  handleInputChange = (event) => {
    // let temAudValue=new Set();
    const target = event.target;
    var value = target.value;
    if (target.checked) {
      this.state.audienceValues.push(value);
      // temAudValue.add(value);
    } else {
      this.state.audienceValues.splice(value, 1);
    }

    //  let audValue= [...temAudValue]
    // this.setState({audienceValues:audValue})
  };

  /*
   * @desc checkbox handlers
   */

  /*
   * @desc change handlers
   */
  handlePresetNameChangeChange = (e) => {
    this.setState({ presetName: e.target.value });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleCustomerIdChange = (e) => {
    this.setState({ customerId: e.target.value });
  };
  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  handleDobSelectedStartDateChange = (e) => {
    this.setState({ dobSelectedStartDate: e });
  };

  handleDobSelectedEndDateChange = (e) => {
    this.setState({ dobSelectedEndDate: e });
  };
  handleDojSelectedStartDateChange = (e) => {
    this.setState({ dojSelectedStartDate: e });
  };

  handleDojSelectedEndDateChange = (e) => {
    this.setState({ dojSelectedEndDate: e });
  };
  handleAgeStartChange = (e) => {
    this.setState({ ageStart: e.target.value });
  };
  handleAgeEndChange = (e) => {
    this.setState({ ageEnd: e.target.value });
  };
  handleGenderChange = (e) => {
    this.setState({ gender: e.target.value });
  };
  handleMaritalStatChange = (e) => {
    this.setState({ maritalStat: e.target.value });
  };

  handlePhoneNumberChange = (e) => {
    this.setState({ phoneNumber: e.target.value });
  };
  handleNomineeNameChange = (e) => {
    this.setState({ nomineeName: e.target.value });
  };
  handleNomineeDobSelectedStartDateChange = (e) => {
    this.setState({ nomineeDobSelectedStartDate: e });
  };

  handleNomineeDobSelectedEndDateChange = (e) => {
    this.setState({ nomineeDobSelectedEndDate: e });
  };
  handleNomineePhoneNumberChange = (e) => {
    this.setState({ nomineePhoneNumber: e.target.value });
  };

  handleProfileStatChange = (e) => {
    this.setState({ profileStat: e.target.value });
  };
  handleGoldBalanceFromChange = (e) => {
    this.setState({ goldBalanceFrom: e.target.value });
  };
  handleGoldBalanceToChange = (e) => {
    this.setState({ goldBalanceTo: e.target.value });
  };
  handleProfessionChange = (e) => {
    this.setState({ profession: e.target.value });
  };
  handleMobileOperatorChange = (e) => {
    this.setState({ mobileOperator: e.target.value });
  };
  handleSellGoldMarketPriceAverageChange = (e) => {
    this.setState({ sellGoldMarketPriceAverage: e.target.value });
  };
  handleSellGoldTrxCountChange = (e) => {
    this.setState({ sellGoldTrxCount: e.target.value });
  };
  handleSellGoldTrxQuantityChange = (e) => {
    this.setState({ sellGoldTrxQuantity: e.target.value });
  };
  handleSellGoldTrxModeMFSChange = (e) => {
    this.setState({ sellGoldTrxModeMFS: e.target.value });
  };
  handleSellGoldTrxModeBankChange = (e) => {
    this.setState({ sellGoldTrxModeBank: e.target.value });
  };
  handleGiftGoldMarketPriceAverageChange = (e) => {
    this.setState({ giftGoldMarketPriceAverage: e.target.value });
  };
  handleGiftGoldTrxCountChange = (e) => {
    this.setState({ giftGoldTrxCount: e.target.value });
  };
  handleGiftGoldTrxQuantityChange = (e) => {
    this.setState({ giftGoldTrxQuantity: e.target.value });
  };
  handleCollectGoldFavGoldAccountChange = (e) => {
    this.setState({ collectGoldFavGoldAccount: e.target.value });
  };
  handleCollectCoin2gCountChange = (e) => {
    this.setState({ collectCoin2gCount: e.target.value });
  };
  handleCollectCoin2gWeightChange = (e) => {
    this.setState({ collectCoin2gWeight: e.target.value });
  };
  handleCollectCoin4gCountChange = (e) => {
    this.setState({ collectCoi4gCount: e.target.value });
  };
  handleCollectCoin4gWeightChange = (e) => {
    this.setState({ collectCoi4gWeight: e.target.value });
  };
  handleCollectBar5gCountChange = (e) => {
    this.setState({ collectBar5gCount: e.target.value });
  };
  handleCollectCoin2gWeightChange = (e) => {
    this.setState({ collectCoin2gWeight: e.target.value });
  };
  handleCollectBar10gCountChange = (e) => {
    this.setState({ collectBar10gCount: e.target.value });
  };
  handleCollectBar10gWeightChange = (e) => {
    this.setState({ collectBar10gWeight: e.target.value });
  };
  handleCollectGoldTrxSKUCountChange = (e) => {
    this.setState({ collectGoldTrxSKUCount: e.target.value });
  };
  handleCollectGoldTrxSKUWeightChange = (e) => {
    this.setState({ collectGoldTrxSKUWeight: e.target.value });
  };
  handleBuyGoldTrxModeMFSChange = (e) => {
    this.setState({ buyGoldTrxModeMFS: e.target.value });
  };
  handleBuyGoldTrxCountStartChange = (e) => {
    this.setState({ buyGoldTrxCountStart: e.target.value });
  };
  handleBuyGoldTrxCountEndChange = (e) => {
    this.setState({ buyGoldTrxCountEnd: e.target.value });
  };
  handleBuyGoldTrxQuantityStartChange = (e) => {
    this.setState({ buyGoldTrxQuantityStart: e.target.value });
  };
  handleBuyGoldTrxQuantityEndChange = (e) => {
    this.setState({ buyGoldTrxQuantityEnd: e.target.value });
  };
  /*
   * @desc change handlers
   */

  /*
   * @desc component onload
   */

  async componentDidMount() {
    const token = getToken();

    //download audience fields get api call

    const response = await GKApi.get(`/marketing/download-audience/fields`, {
      headers: {
        Authorization: token,
      },
    });

    //download audience fields get api call

    const headers = new Set();
    const options = {};
    const children = [];

    response.data.forEach((item) => {
      //  mapping header
      headers.add(item.HEADER);
      children.push(item.DISPLAY_TEXT);

      //  mapping children
      if (options[item.HEADER]) options[item.HEADER].push(item.DISPLAY_TEXT);
      else options[item.HEADER] = [item.DISPLAY_TEXT];
    });

    // converting header set to array

    const headerArray = [...headers];

    //setting the state to for header array and option array

    this.setState({ headerName: headerArray, headerFields: options });

    const optionGroup = [
      children.forEach((item) => ({
        label: item,
        options: [{ label: item, value: item }],
      })),
    ];
    this.setState({
      childValues: optionGroup,
    });

    // api call for list ogf professions

    const profession = await GKApi.get(
      `/users/system/profession`,

      {
        headers: {
          Authorization: token,
          cmpId: 46,
        },
      }
    );
    this.setState({ professionList: profession.data }, () => {});

    // api call for geolocation data
    const res = deviceInfo();
    this.setState({ deviceInfo: res.IPv4 });
  }

  // removes input

  isDisableProfileField = (hf) => {
    for (let index = 0; index < this.state.audienceValues.length; index++) {
      const element = this.state.audienceValues[index];

      if (element === hf) return true;
    }
  };

  isDisableBuyField = (hf) => {
    for (let index = 0; index < this.state.audienceValues.length; index++) {
      const element = this.state.audienceValues[index];
      let buy = `${hf}`;
      if (element === buy) return true;
    }
  };
  isDisableSellField = (hf) => {
    for (let index = 0; index < this.state.audienceValues.length; index++) {
      const element = this.state.audienceValues[index];
      let Sell = `${hf}`;
      if (element === Sell) return true;
    }
  };
  isDisableCollectField = (hf) => {
    for (let index = 0; index < this.state.audienceValues.length; index++) {
      const element = this.state.audienceValues[index];

      if (element === hf) return true;
    }
  };
  isDisableGiftField = (hf) => {
    for (let index = 0; index < this.state.audienceValues.length; index++) {
      const element = this.state.audienceValues[index];
      let Gift = `${hf}`;
      if (element === Gift) return true;
    }
  };

  render() {
    return (
      <Card>
        <CardBody className="shadow-lg border-bottom">
          <div style={{ height: 'auto' }}>
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-4 fw-bold">Download Audience</h4>
            </div>
            <div className="mb-4">
              <h4 className="card-title fw-bold">Filter audience</h4>

              <InputGroup>
                <div className="mb-3">
                  {/* <Select
															value={this.state.selectedMulti}
															isMulti={true}
															onChange={this.handleMulti}
															options={this.state.childValues}
															classNamePrefix="select2-selection"
														/> */}
                </div>

                <Button
                  className="form-control form-control-lg fw-bold d-flex justify-content-center mx-1"
                  style={{
                    color: '#081939',
                    backgroundColor: '#c2b56f',
                    width: '50%',
                  }}
                  onClick={() => {
                    this.setState({ modal_standard: true });
                  }}
                >
                  Select Download Audience Fields
                </Button>
              </InputGroup>

              {/*
               * @desc Modal for download audience fields
               */}

              <Modal
                isOpen={this.state.modal_standard}
                toggle={this.tog_standard}
              >
                <ModalHeader
                  toggle={() => {
                    this.setState({ modal_standard: false });
                  }}
                >
                  Download Audience Fields
                </ModalHeader>

                <ModalBody>
                  <Col xl={12}>
                    <div id="accordion">
                      {this.state.headerName.map((head, hd) => (
                        <div>
                          <Card className="mb-1 shadow-none" key={hd}>
                            <Link
                              to="#"
                              onClick={
                                head === 'Profile'
                                  ? this.t_col1
                                  : head === 'Buy'
                                  ? this.t_col2
                                  : head === 'Sell'
                                  ? this.t_col3
                                  : head === 'Collect'
                                  ? this.t_col4
                                  : head === 'Gift'
                                  ? this.t_col5
                                  : ''
                              }
                              style={{ cursor: 'pointer' }}
                              className="text-dark"
                            >
                              <CardHeader id="headingOne">
                                <h6 className="m-0 font-14">
                                  {head}
                                  <i
                                    className={
                                      (
                                        head === 'Profile'
                                          ? this.state.col1
                                          : head === 'Buy'
                                          ? this.state.col2
                                          : head === 'Sell'
                                          ? this.state.col3
                                          : head === 'Gift'
                                          ? this.state.col5
                                          : head === 'Collect'
                                          ? this.state.col4
                                          : ''
                                      )
                                        ? 'mdi mdi-minus float-end accor-plus-icon'
                                        : 'mdi mdi-plus float-end accor-plus-icon'
                                    }
                                  ></i>
                                </h6>
                              </CardHeader>
                            </Link>

                            {head === 'Profile' &&
                              this.state.headerFields.Profile.map((hf, id) => (
                                <Collapse isOpen={this.state.col1}>
                                  <CardBody>
                                    <Form>
                                      <FormGroup check key={id}>
                                        <Input
                                          type="checkbox"
                                          value={hf}
                                          onChange={this.handleInputChange}
                                          disabled={this.isDisableProfileField(
                                            hf
                                          )}
                                        />
                                        <Label check>{hf}</Label>
                                      </FormGroup>
                                    </Form>
                                  </CardBody>
                                </Collapse>
                              ))}
                            {head === 'Buy' &&
                              this.state.headerFields.Buy.map((hf, id) => (
                                <Collapse isOpen={this.state.col2}>
                                  <CardBody>
                                    <Form>
                                      <FormGroup check key={id}>
                                        <Input
                                          type="checkbox"
                                          value={`${hf}`}
                                          onChange={this.handleInputChange}
                                          disabled={this.isDisableBuyField(hf)}
                                        />

                                        <Label check>{hf}</Label>
                                      </FormGroup>
                                    </Form>
                                  </CardBody>
                                </Collapse>
                              ))}
                            {head === 'Sell' &&
                              this.state.headerFields.Sell.map((hf, id) => (
                                <Collapse isOpen={this.state.col3}>
                                  <CardBody>
                                    <Form>
                                      <FormGroup check key={id}>
                                        <Input
                                          type="checkbox"
                                          value={`${hf}`}
                                          onChange={this.handleInputChange}
                                          disabled={this.isDisableSellField(hf)}
                                        />

                                        <Label check>{hf}</Label>
                                      </FormGroup>
                                    </Form>
                                  </CardBody>
                                </Collapse>
                              ))}
                            {head === 'Gift' &&
                              this.state.headerFields.Gift.map((hf, id) => (
                                <Collapse isOpen={this.state.col5}>
                                  <CardBody>
                                    <Form>
                                      <FormGroup check key={id}>
                                        <Input
                                          type="checkbox"
                                          value={`${hf}`}
                                          onChange={this.handleInputChange}
                                          disabled={this.isDisableGiftField(hf)}
                                        />

                                        <Label check>{hf}</Label>
                                      </FormGroup>
                                    </Form>
                                  </CardBody>
                                </Collapse>
                              ))}
                            {head === 'Collect' &&
                              this.state.headerFields.Collect.map((hf, id) => (
                                <Collapse isOpen={this.state.col4}>
                                  <CardBody>
                                    <Form>
                                      <FormGroup check key={id}>
                                        <Input
                                          type="checkbox"
                                          value={`${hf}`}
                                          onChange={this.handleInputChange}
                                          disabled={this.isDisableCollectField(
                                            hf
                                          )}
                                        />

                                        <Label check>{hf}</Label>
                                      </FormGroup>
                                    </Form>
                                  </CardBody>
                                </Collapse>
                              ))}
                          </Card>
                        </div>
                      ))}
                    </div>
                  </Col>
                </ModalBody>
              </Modal>
            </div>

            {/*
             * @desc mapping the selected fields
             */}
            <div>
              {this.state.audienceValues.map((av, id) => (
                <Row className="mb-3" key={id}>
                  <Label htmlFor="input" className="col-md-5 col-form-label">
                    {av}
                  </Label>
                  <Col md={6}>
                    {av === 'Profile Email' && (
                      <div>
                        <Input
                          type="email"
                          defaultValue={this.state.email}
                          placeholder="Please input email address"
                          id="email-input"
                          onChange={(e) => {
                            this.handleEmailChange(e);
                          }}
                        />
                        <p>Max. number of emails are 75</p>
                      </div>
                    )}
                    {av === 'Profile Name' && (
                      <Input
                        type="text"
                        value={this.state.name}
                        placeholder="Please input Name"
                        id="name-input"
                        onChange={(e) => {
                          this.handleNameChange(e);
                        }}
                      />
                    )}
                    {av === 'Profile Customer ID' && (
                      <Input
                        type="text"
                        defaultValue={this.state.customerId}
                        placeholder="Please input Customer Id"
                        id="customer-id-input"
                        onChange={(e) => {
                          this.handleCustomerIdChange(e);
                        }}
                      />
                    )}
                    {av === 'Profile Date of Birth' && (
                      <div className="d-flex gap-2">
                        <div
                          style={{
                            border: '1px solid',
                            borderRadius: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '4px',
                            paddingLeft: '4px',
                          }}
                          className="d-flex gap-3 align-content-center"
                        >
                          <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                            From
                          </p>
                          <DatePicker
                            selectedDate={this.state.dobSelectedStartDate}
                            handleSelectedDateChange={
                              this.handleDobSelectedStartDateChange
                            }
                          />
                        </div>
                        <div
                          style={{
                            border: '1px solid',
                            borderRadius: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '5px',
                            paddingLeft: '5px',
                          }}
                          className="d-flex d-grid gap-3"
                        >
                          <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                            To
                          </p>
                          <DatePicker
                            selectedDate={this.state.dobSelectedEndDate}
                            handleSelectedDateChange={
                              this.handleDobSelectedEndDateChange
                            }
                            className="mx-3"
                          />
                        </div>
                      </div>
                    )}
                    {av === 'Profile Date of Joining' && (
                      <div className="d-flex gap-2">
                        <div
                          style={{
                            border: '1px solid',
                            borderRadius: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '4px',
                            paddingLeft: '4px',
                          }}
                          className="d-flex gap-3 align-content-center"
                        >
                          <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                            From
                          </p>
                          <DatePicker
                            selectedDate={this.state.dojSelectedStartDate}
                            handleSelectedDateChange={
                              this.handleDojSelectedStartDateChange
                            }
                          />
                        </div>
                        <div
                          style={{
                            border: '1px solid',
                            borderRadius: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '5px',
                            paddingLeft: '5px',
                          }}
                          className="d-flex d-grid gap-3"
                        >
                          <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                            To
                          </p>
                          <DatePicker
                            selectedDate={this.state.dojSelectedEndDate}
                            handleSelectedDateChange={
                              this.handleDojSelectedEndDateChange
                            }
                            className="mx-3"
                          />
                        </div>
                      </div>
                    )}
                    {av === 'Profile Age' && (
                      <div className="d-flex gap-2">
                        <Input
                          type="text"
                          defaultValue={this.state.ageStart}
                          placeholder="Please input Age"
                          id="start-age-input"
                          onChange={(e) => {
                            this.handleAgeStartChange(e);
                          }}
                        />
                        <Input
                          type="text"
                          defaultValue={this.state.ageEnd}
                          placeholder="Please input Age"
                          id="end-age-input"
                          onChange={(e) => {
                            this.handleAgeEndChange(e);
                          }}
                        />
                      </div>
                    )}
                    {av === 'Profile Gender' && (
                      <Dropdown
                        isOpen={this.state.genderMenu}
                        toggle={(event) =>
                          this.setState({
                            genderMenu: !this.state.genderMenu,
                          })
                        }
                      >
                        <DropdownToggle className="px-5 py-2" color="light">
                          {capitalizeFirstLetter(this.state.gender)}{' '}
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* <DropdownItem
                            onClick={(event) => {
                              this.setState({ gender: 'Select' });
                            }}
                          >
                            Select Gender
                          </DropdownItem> */}
                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ gender: 'Male' });
                            }}
                          >
                            Male
                          </DropdownItem>

                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ gender: 'Female' });
                            }}
                          >
                            Female
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}

                    {av === 'Profile Marital Status' && (
                      <Dropdown
                        isOpen={this.state.maritalStatMenu}
                        toggle={(event) =>
                          this.setState({
                            maritalStatMenu: !this.state.maritalStatMenu,
                          })
                        }
                      >
                        <DropdownToggle className="px-5 py-2" color="light">
                          {capitalizeFirstLetter(this.state.maritalStat)}{' '}
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* <DropdownItem
                            onClick={(event) => {
                              this.setState({ gender: 'Select' });
                            }}
                          >
                            Select Gender
                          </DropdownItem> */}
                          <DropdownItem
                            onClick={(event) => {
                              this.setState(
                                { maritalStat: 'Married' },
                                () => {}
                              );
                            }}
                          >
                            Married
                          </DropdownItem>

                          <DropdownItem
                            onClick={(event) => {
                              this.setState(
                                { maritalStat: 'Unmarried' },
                                () => {}
                              );
                            }}
                          >
                            Unmarried
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                    {av === 'Profile Phone Number' && (
                      <div>
                        <Input
                          type="text"
                          defaultValue={this.state.phoneNumber}
                          placeholder="Please input Phone number"
                          id="phone-input"
                          onChange={(e) => {
                            this.handlePhoneNumberChange(e);
                          }}
                        />
                        <p>Max. number of phone numbers are 250</p>
                      </div>
                    )}
                    {av === 'Profile Nominee Name' && (
                      <Input
                        type="text"
                        defaultValue={this.state.nomineeName}
                        placeholder="Please input Nominee Name"
                        id="nominee-name-input"
                        onChange={(e) => {
                          this.handleNomineeNameChange(e);
                        }}
                      />
                    )}

                    {av === 'Profile Nominee Date of Birth' && (
                      <div className="d-flex gap-2">
                        <div
                          style={{
                            border: '1px solid',
                            borderRadius: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '4px',
                            paddingLeft: '4px',
                          }}
                          className="d-flex gap-3 align-content-center"
                        >
                          <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                            From
                          </p>
                          <DatePicker
                            selectedDate={
                              this.state.nomineeDobSelectedStartDate
                            }
                            handleSelectedDateChange={
                              this.handleNomineeDobSelectedStartDateChange
                            }
                          />
                        </div>
                        <div
                          style={{
                            border: '1px solid',
                            borderRadius: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '5px',
                            paddingLeft: '5px',
                          }}
                          className="d-flex d-grid gap-3"
                        >
                          <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                            To
                          </p>
                          <DatePicker
                            selectedDate={this.state.nomineeDobSelectedEndDate}
                            handleSelectedDateChange={
                              this.handleNomineeDobSelectedEndDateChange
                            }
                            className="mx-3"
                          />
                        </div>
                      </div>
                    )}

                    {av === 'Profile Nominee Phone Number' && (
                      <Input
                        type="text"
                        defaultValue={this.state.nomineePhoneNumber}
                        placeholder="Please input Nominee Phone Number"
                        id="phone-number-input"
                        onChange={(e) => {
                          this.handleNomineePhoneNumberChange(e);
                        }}
                      />
                    )}

                    {av === 'Profile Profile Status' && (
                      <Dropdown
                        isOpen={this.state.profileStatMenu}
                        toggle={(event) =>
                          this.setState({
                            profileStatMenu: !this.state.profileStatMenu,
                          })
                        }
                      >
                        <DropdownToggle className="px-5 py-2" color="light">
                          {capitalizeFirstLetter(this.state.profileStat)}{' '}
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* <DropdownItem
                            onClick={(event) => {
                              this.setState({ gender: 'Select' });
                            }}
                          >
                            Select Gender
                          </DropdownItem> */}
                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ profileStat: 'Verified' });
                            }}
                          >
                            Verified
                          </DropdownItem>

                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ profileStat: 'Unverified' });
                            }}
                          >
                            Unverified
                          </DropdownItem>
                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ profileStat: 'Pending' });
                            }}
                          >
                            Pending
                          </DropdownItem>
                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ profileStat: 'Incomplete' });
                            }}
                          >
                            Incomplete
                          </DropdownItem>
                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ profileStat: 'Locked' });
                            }}
                          >
                            Locked
                          </DropdownItem>
                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ profileStat: 'Rejected' });
                            }}
                          >
                            Rejected
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                    {av === 'Profile Gold Balance' && (
                      <div className="d-flex gap-2">
                        <Input
                          type="text"
                          defaultValue={this.state.goldBalanceFrom}
                          placeholder="Please input Gold balance"
                          id="start-gold-balance-input"
                          onChange={(e) => {
                            this.handleGoldBalanceFromChange(e);
                          }}
                        />
                        <Input
                          type="text"
                          defaultValue={this.state.goldBalanceTo}
                          placeholder="Please input Gold balance"
                          id="end-gold-balance-input"
                          onChange={(e) => {
                            this.handleGoldBalanceToChange(e);
                          }}
                        />
                      </div>
                    )}

                    {av === 'Profile Profession Code' && (
                      <Dropdown
                        isOpen={this.state.professionListMenu}
                        toggle={(event) =>
                          this.setState(
                            {
                              professionListMenu:
                                !this.state.professionListMenu,
                            },
                            () => {}
                          )
                        }
                      >
                        <DropdownToggle className="px-5 py-2" color="light">
                          {this.state.profession}
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          style={{
                            maxHeight: 500,
                            overflowY: 'scroll',
                          }}
                        >
                          {this.state.professionList.map((pr, index) => (
                            <DropdownItem
                              key={index}
                              onClick={async (event) => {
                                this.setState(
                                  {
                                    profession: pr.profession,
                                    professionCode: pr.professionCode,
                                  },
                                  () => {}
                                );
                              }}
                            >
                              {pr.profession}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    )}

                    {av === 'Profile Mobile Operator' && (
                      <Dropdown
                        isOpen={this.state.mobileOperatorMenu}
                        toggle={(event) =>
                          this.setState({
                            mobileOperatorMenu: !this.state.mobileOperatorMenu,
                          })
                        }
                      >
                        <DropdownToggle className="px-5 py-2" color="light">
                          {capitalizeFirstLetter(this.state.mobileOperator)}{' '}
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* <DropdownItem
                            onClick={(event) => {
                              this.setState({ gender: 'Select' });
                            }}
                          >
                            Select Gender
                          </DropdownItem> */}
                          <DropdownItem
                            onClick={(event) => {
                              this.setState({
                                mobileOperator: 'GrameenPhone',
                              });
                            }}
                          >
                            Grameen Phone
                          </DropdownItem>

                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ mobileOperator: 'Banglalink' });
                            }}
                          >
                            Banglalink
                          </DropdownItem>
                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ mobileOperator: 'Teletalk' });
                            }}
                          >
                            Teletalk
                          </DropdownItem>
                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ mobileOperator: 'Airtel' });
                            }}
                          >
                            Airtel
                          </DropdownItem>
                          <DropdownItem
                            onClick={(event) => {
                              this.setState({ mobileOperator: 'Robi' });
                            }}
                          >
                            Robi
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}

                    {av === 'Sell Gold Market Price Avg' && (
                      <Input
                        type="number"
                        defaultValue={this.state.sellGoldMarketPriceAverage}
                        placeholder="Please input Sell Gold Market Price"
                        id="sell-gold-market-price-average-input"
                        onChange={(e) => {
                          this.handleSellGoldMarketPriceAverageChange(e);
                        }}
                      />
                    )}

                    {av === 'Sell Gold Trx Quantity' && (
                      <Input
                        type="number"
                        defaultValue={this.state.sellGoldTrxQuantity}
                        placeholder="Please input Sell Gold Trx Quantity"
                        id="sell-gold-trx-quantity-input"
                        onChange={(e) => {
                          this.handleSellGoldTrxQuantityChange(e);
                        }}
                      />
                    )}
                    {av === 'Sell Gold Trx Count' && (
                      <Input
                        type="number"
                        defaultValue={this.state.sellGoldTrxCount}
                        placeholder="Please input Sell Gold Trx Count"
                        id="sell-gold-trx-count-input"
                        onChange={(e) => {
                          this.handleSellGoldTrxCountChange(e);
                        }}
                      />
                    )}
                    {av === 'Sell Gold Trx Mode MFS' && (
                      <Input
                        type="number"
                        defaultValue={this.state.sellGoldTrxModeMFS}
                        placeholder="Please input Sell Gold Trx Mode MFS"
                        id="sell-Gold-trx-mode-mfs-input"
                        onChange={(e) => {
                          this.handleSellGoldTrxModeMFSChange(e);
                        }}
                      />
                    )}

                    {av === 'Sell Gold Trx Mode Bank' && (
                      <Input
                        type="number"
                        defaultValue={this.state.sellGoldTrxModeBank}
                        placeholder="Please input Sell Gold Trx Mode Bank"
                        id="sell-Gold-trx-mode-bank-input"
                        onChange={(e) => {
                          this.handleSellGoldTrxModeBankChange(e);
                        }}
                      />
                    )}
                    {av === 'Gift Gold Market Price Avg' && (
                      <Input
                        type="number"
                        defaultValue={this.state.giftGoldMarketPriceAverage}
                        placeholder="Please input Gift Gold Market Price Average"
                        id="gift-gold-market-price-avg-input"
                        onChange={(e) => {
                          this.handleGiftGoldMarketPriceAverageChange(e);
                        }}
                      />
                    )}
                    {av === 'Gift Gold Trx Quantity' && (
                      <Input
                        type="number"
                        defaultValue={this.state.giftGoldTrxQuantity}
                        placeholder="Please input Gift Gold Trx Quantity"
                        id="gift-gold-trx-quantity-input"
                        onChange={(e) => {
                          this.handleGiftGoldTrxQuantityChange(e);
                        }}
                      />
                    )}
                    {av === 'Gift Gold Trx Count' && (
                      <Input
                        type="number"
                        defaultValue={this.state.giftGoldTrxCount}
                        placeholder="Please input Gift Gold Trx Count"
                        id="gift-gold-trx-count-input"
                        onChange={(e) => {
                          this.handleGiftGoldTrxCountChange(e);
                        }}
                      />
                    )}

                    {av === 'Gift Favourite Gold Account' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectGoldFavGoldAccount}
                        placeholder="Please input Favoutite Gold Account"
                        id="gift-favourite-account-input"
                        onChange={(e) => {
                          this.handleCollectGoldFavGoldAccountChange(e);
                        }}
                      />
                    )}

                    {av === 'Collect Coin 2g Count' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectCoin2gCount}
                        placeholder="Please input Coin 2g Count"
                        id="Collect Coin 2g Count-input"
                        onChange={(e) => {
                          this.handleCollectCoin2gCountChange(e);
                        }}
                      />
                    )}
                    {av === 'Collect Coin 2g Weight' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectCoin2gWeight}
                        placeholder="Please input Coin 2g Weight"
                        id="Collect Coin 2g Weight-input"
                        onChange={(e) => {
                          this.handleCollectCoin2gWeightChange(e);
                        }}
                      />
                    )}

                    {av === 'Collect Coin 4g Count' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectCoin4gCount}
                        placeholder="Please input Coin 4g Count"
                        id="Collect Coin 4g Count-input"
                        onChange={(e) => {
                          this.handleCollectCoin4gCountChange(e);
                        }}
                      />
                    )}
                    {av === 'Collect Coin 4g Weight' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectCoin4gWeight}
                        placeholder="Please input Coin 4g Weight"
                        id="Collect Coin 4g Weight-input"
                        onChange={(e) => {
                          this.handleCollectCoin4gWeightChange(e);
                        }}
                      />
                    )}

                    {av === 'Collect Bar 5g Count' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectBar5gCount}
                        placeholder="Please input Bar 5g Count"
                        id="Collect Bar 5g Count-input"
                        onChange={(e) => {
                          this.handleCollectBar5gCountChange(e);
                        }}
                      />
                    )}
                    {av === 'Collect Bar 5g Weight' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectBar5gWeight}
                        placeholder="Please input Bar 5g Weight"
                        id="Collect Bar 5g Weight-input"
                        onChange={(e) => {
                          this.handleCollectBar5gWeightChange(e);
                        }}
                      />
                    )}

                    {av === 'Collect Bar 10g Count' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectBar10gCount}
                        placeholder="Please input Bar 10g Count"
                        id="Collect Bar 10g Count-input"
                        onChange={(e) => {
                          this.handleCollectBar10gCountChange(e);
                        }}
                      />
                    )}
                    {av === 'Collect Bar 10g Weight' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectBar10gWeight}
                        placeholder="Please input Bar 10g Weight"
                        id="Collect Bar 10g Weight-input"
                        onChange={(e) => {
                          this.handleCollectBar10gWeightChange(e);
                        }}
                      />
                    )}

                    {av === 'Collect Gold Trx SKU Count' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectGoldTrxSKUCount}
                        placeholder="Please input Gold Trx SKU Count"
                        id="Collect Gold Trx SKU Count-input"
                        onChange={(e) => {
                          this.handleCollectGoldTrxSKUCountChange(e);
                        }}
                      />
                    )}
                    {av === 'Collect Gold Trx SKU Weight' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectGoldTrxSKUWeight}
                        placeholder="Please input Gold Trx SKU Weight"
                        id="Collect Gold Trx SKU Weight-input"
                        onChange={(e) => {
                          this.handleCollectGoldTrxSKUWeightChange(e);
                        }}
                      />
                    )}

                    {av === 'Collect Gold SKU' && (
                      <Input
                        type="text"
                        defaultValue={this.state.collectGoldSKU}
                        placeholder="Please input Collect Gold SKU"
                        id="Collect Gold SKU-input"
                        onChange={(e) => {
                          this.handleCollectGoldSKUcollectGoldSKUChange(e);
                        }}
                      />
                    )}

                    {av === 'Buy Gold Market Price Avg' && (
                      <Input
                        type="text"
                        defaultValue={this.state.buyGoldMarketPriceAverage}
                        placeholder="Please input Buy Gold Market Price"
                        id="Buy Gold Market Price Avg-input"
                        onChange={(e) => {
                          this.handleBuyGoldMarketPriceAverageChange(e);
                        }}
                      />
                    )}

                    {av === 'Buy Gold Trx Quantity' && (
                      <div className="d-flex gap-2">
                        <Input
                          type="text"
                          defaultValue={this.state.buyGoldTrxQuantityStart}
                          placeholder="Please input Gold Quantity start"
                          id="Buy Gold Trx Quantity-input"
                          onChange={(e) => {
                            this.handleBuyGoldTrxQuantityStartChange(e);
                          }}
                        />
                        <Input
                          type="text"
                          defaultValue={this.state.buyGoldTrxQuantityEnd}
                          placeholder="Please input Gold Quantity End"
                          id="Buy Gold Trx Quantity end-input"
                          onChange={(e) => {
                            this.handleBuyGoldTrxQuantityEndChange(e);
                          }}
                        />
                      </div>
                    )}
                    {av === 'Buy Gold Trx Count' && (
                      <div className="d-flex gap-2">
                        <Input
                          type="text"
                          defaultValue={this.state.buyGoldTrxCountStart}
                          placeholder="Please input Gold count start"
                          id="Gold count start-input"
                          onChange={(e) => {
                            this.handleBuyGoldTrxCountStartChange(e);
                          }}
                        />
                        <Input
                          type="text"
                          defaultValue={this.state.buyGoldTrxCountEnd}
                          placeholder="Please input Gold count end"
                          id="Gold count end-input"
                          onChange={(e) => {
                            this.handleBuyGoldTrxCountEndChange(e);
                          }}
                        />
                      </div>
                    )}
                    {av === 'Buy Gold Trx Mode MFS' && (
                      <Input
                        type="text"
                        defaultValue={this.state.buyGoldTrxModeMFS}
                        placeholder="Please input Buy Gold Trx Mode MFS"
                        id="Buy Gold Trx Mode MFS-input"
                        onChange={(e) => {
                          this.handleBuyGoldTrxModeMFSChange(e);
                        }}
                      />
                    )}
                  </Col>
                  <i
                    className="col-md-1 mdi mdi-close-circle"
                    onClick={() => {
                      let temp = this.state.audienceValues;
                      temp.splice(id, 1);
                      this.setState({ audienceValues: temp }, () => {});
                    }}
                  />
                </Row>
              ))}
            </div>

            {/*
             * @desc button to invoke the modal for preset save and check audience
             */}

            {this.state.audienceValues.length > 0 && (
              <Button
                className="form-control form-control-lg fw-bold my-5"
                style={{ color: '#081939', backgroundColor: '#c2b56f' }}
                onClick={async () => {
                  this.setState({ modal_standard1: true });
                }}
              >
                Check Audience
              </Button>
            )}

            {/*
             * @desc check audience value from the api
             */}
            {this.state.audienceSize !== null && (
              <div className="boarder bg-light p-4 mb-4">
                <h4 className="card-title fw-bold">Audience Size</h4>
                <p>Potential Reach: {this.state.audienceSize} people</p>
              </div>
            )}
          </div>

          {/*
           * @desc Modal for calling the check audience api with selected data
           */}

          <Modal
            isOpen={this.state.modal_standard1}
            toggle={this.tog_standard1}
          >
            <ModalHeader
              toggle={() => {
                this.setState({ modal_standard1: false });
              }}
            >
              Download Audience Preset Save
            </ModalHeader>

            <ModalBody>
              <Col xl={12}>
                <Row className="mb-3 mx-2">
                  Do you want to save this preset or just see the audience size?
                </Row>
              </Col>
            </ModalBody>

            <ModalFooter>
              <Button
                type="button"
                color="primary"
                className="waves-effect waves-light"
                onClick={async () => {
                  this.tog_standard1();
                  this.tog_savePresetModal();
                }}
              >
                Save Preset
              </Button>
              <Button
                type="button"
                onClick={async () => {
                  this.state.audienceValues.forEach(
                    (ad) => (
                      ad === 'Profile Name' &&
                        this.state.presetData.push({
                          type: 'string',
                          operator: 'equal',

                          displayName: ad,
                          fieldName: 'Profile_Name',
                          data: {
                            value: this.state.name,
                          },
                          // eslint-disable-next-line
                        }),
                      ad === 'Profile Customer ID' &&
                        this.state.presetData.push({
                          type: 'string',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Profile_Customer_ID',
                          data: {
                            value: this.state.customerId,
                          },
                        }),
                      ad === 'Profile Email' &&
                        this.state.presetData.push({
                          type: 'multiple',
                          operator: 'in',
                          displayName: ad,
                          fieldName: 'Profile_Email',
                          data: {
                            value: this.state.email,
                          },
                        }),
                      ad === 'Profile Date of Birth' &&
                        this.state.presetData.push({
                          type: 'date',
                          operator: 'range',
                          displayName: ad,
                          fieldName: 'Profile_Date_of_Birth',
                          data: {
                            from: moment(
                              this.state.dobSelectedStartDate
                            ).format('DD/MM/YYYY'),
                            to: moment(this.state.dobSelectedEndDate).format(
                              'DD/MM/YYYY'
                            ),
                          },
                        }),
                      ad === 'Profile Date of Joining' &&
                        this.state.presetData.push({
                          type: 'date',
                          operator: 'range',
                          displayName: ad,
                          fieldName: 'Profile_Date_of_Joining',
                          data: {
                            from: moment(
                              this.state.dojSelectedStartDate
                            ).format('DD/MM/YYYY'),
                            to: moment(this.state.dojSelectedEndDate).format(
                              'DD/MM/YYYY'
                            ),
                          },
                        }),
                      ad === 'Profile Gender' &&
                        this.state.presetData.push({
                          type: 'string',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Profile_Gender',
                          data: {
                            value: this.state.gender,
                          },
                        }),
                      ad === 'Profile Age' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'range',
                          displayName: ad,
                          fieldName: 'Profile_Age',
                          data: {
                            from: this.state.ageStart,
                            to: this.state.ageEnd,
                          },
                        }),
                      ad === 'Profile Marital Status' &&
                        this.state.presetData.push({
                          type: 'string',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Profile_Marital_Status',
                          data: {
                            value: this.state.maritalStat,
                          },
                        }),
                      ad === 'Profile Phone Number' &&
                        this.state.presetData.push({
                          type: 'multiple',
                          operator: 'in',
                          displayName: ad,
                          fieldName: 'Profile_Phone_Number',
                          data: {
                            value: this.state.phoneNumber,
                          },
                        }),
                      ad === 'Profile Nominee Name' &&
                        this.state.presetData.push({
                          type: 'string',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Profile_Nominee_Name',
                          data: {
                            value: this.state.nomineeName,
                          },
                        }),
                      ad === 'Profile Nominee Phone Number' &&
                        this.state.presetData.push({
                          type: 'string',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Profile_Nominee_Phone_Number',
                          data: {
                            value: this.state.nomineePhoneNumber,
                          },
                        }),
                      ad === 'Profile Nominee Date of Birth' &&
                        this.state.presetData.push({
                          type: 'date',
                          operator: 'range',
                          displayName: ad,
                          fieldName: 'Profile_Nominee_Date_of_Birth',
                          data: {
                            from: moment(
                              this.state.nomineeDobSelectedStartDate
                            ).format('DD/MM/YYYY'),
                            to: moment(
                              this.state.nomineeDobSelectedEndDate
                            ).format('DD/MM/YYYY'),
                          },
                        }),
                      ad === 'Profile Gold Balance' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'range',
                          displayName: ad,
                          fieldName: 'Profile_Gold_Balance',
                          data: {
                            from: this.state.goldBalanceFrom,
                            to: this.state.goldBalanceTo,
                          },
                        }),
                      ad === 'Profile Profile Status' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Profile_Profile_Status',
                          data: {
                            value:
                              this.state.profileStat === 'Verified'
                                ? 3
                                : this.state.profileStat === 'Unverified'
                                ? 2
                                : this.state.profileStat === 'Pending'
                                ? 1
                                : this.state.profileStat === 'Locked'
                                ? 4
                                : this.state.profileStat === 'Incomplete'
                                ? 5
                                : this.state.profileStat === 'Rejected'
                                ? 6
                                : 0,
                          },
                        }),
                      ad === 'Profile Profession Code' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Profile_Profession_Code',
                          data: {
                            value: this.state.professionCode,
                          },
                        }),
                      ad === 'Profile Mobile Operator' &&
                        this.state.presetData.push({
                          type: 'string',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Profile_Mobile_Operator',
                          data: {
                            value: this.state.mobileOperator,
                          },
                        }),
                      ad === 'Sell Gold Market Price Avg' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Sell_Gold_Market_Price_Avg',
                          data: {
                            value: this.state.sellGoldMarketPriceAverage,
                          },
                        }),
                      ad === 'Sell Gold Trx Quantity' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Sell_Gold_Trx_Quantity',
                          data: {
                            value: this.state.sellGoldTrxQuantity,
                          },
                        }),
                      ad === 'Sell Gold Trx Count' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Sell_Gold_Trx_Count',
                          data: {
                            value: this.state.sellGoldTrxCount,
                          },
                        }),
                      ad === 'Sell Gold Trx Mode MFS' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Sell_Gold_Trx_Mode_MFS',
                          data: {
                            value: this.state.sellGoldTrxModeMFS,
                          },
                        }),
                      ad === 'Sell Gold Trx Mode Bank' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Sell_Gold_Trx_Mode_Bank',
                          data: {
                            value: this.state.sellGoldTrxModeBank,
                          },
                        }),
                      ad === 'Gift Gold Market Price Avg' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Gift_Gold_Market_Price_Avg',
                          data: {
                            value: this.state.giftGoldMarketPriceAverage,
                          },
                        }),
                      ad === 'Gift Gold Trx Quantity' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Gift_Gold_Trx_Quantity',
                          data: {
                            value: this.state.giftGoldTrxQuantity,
                          },
                        }),
                      ad === 'Gift Gold Trx Count' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Gift_Gold_Trx_Count',
                          data: {
                            value: this.state.giftGoldTrxCount,
                          },
                        }),
                      ad === 'Buy Gold Market Price Avg' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Buy_Gold_Market_Price_Avg',
                          data: {
                            value: this.state.buyGoldMarketPriceAverage,
                          },
                        }),
                      ad === 'Buy Gold Trx Quantity' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'range',
                          displayName: ad,
                          fieldName: 'Buy_Gold_Trx_Quantity',
                          data: {
                            from: this.state.buyGoldTrxQuantityStart,
                            to: this.state.buyGoldTrxQuantityEnd,
                          },
                        }),
                      ad === 'Buy Gold Trx Count' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'range',
                          displayName: ad,
                          fieldName: 'Buy_Gold_Trx_Count',
                          data: {
                            from: this.state.buyGoldTrxCountStart,
                            to: this.state.buyGoldTrxCountEnd,
                          },
                        }),
                      ad === 'Buy Gold Trx Mode MFS' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Buy_Gold_Trx_Mode_MFS',
                          data: {
                            value: this.state.buyGoldTrxModeMFS,
                          },
                        }),
                      ad === 'Gift Favourite Gold Account' &&
                        this.state.presetData.push({
                          type: 'string',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Gift_Favourite_Gold_Account',
                          data: {
                            value: this.state.collectGoldFavGoldAccount,
                          },
                        }),
                      ad === 'Collect Coin 2g Count' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Coin_2g_Count',
                          data: {
                            value: this.state.collectCoin2gCount,
                          },
                        }),
                      ad === 'Collect Coin 2g Weight' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Coin_2g_Weight',
                          data: {
                            value: this.state.collectCoin2gWeight,
                          },
                        }),
                      ad === 'Collect Coin 4g Count' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Coin_4g_Count',
                          data: {
                            value: this.state.collectCoin4gCount,
                          },
                        }),
                      ad === 'Collect Coin 4g Weight' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Coin_4g_Weight',
                          data: {
                            value: this.state.collectCoin4gWeight,
                          },
                        }),
                      ad === 'Collect Bar 5g Count' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Bar_5g_Count',
                          data: {
                            value: this.state.collectBar5gCount,
                          },
                        }),
                      ad === 'Collect Bar 5g Weight' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Bar_5g_Weight',
                          data: {
                            value: this.state.collectBar5gWeight,
                          },
                        }),
                      ad === 'Collect Bar 10g Count' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Bar_10g_Count',
                          data: {
                            value: this.state.collectBar10gCount,
                          },
                        }),
                      ad === 'Collect Bar 10g Weight' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Bar_10g_Weight',
                          data: {
                            value: this.state.collectBar10gWeight,
                          },
                        }),
                      ad === 'Collect Gold Trx SKU Count' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Gold_Trx_SKU_Count',
                          data: {
                            value: this.state.collectGoldTrxSKUCount,
                          },
                        }),
                      ad === 'Collect Gold Trx SKU Weight' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Gold_Trx_SKU_Weight',
                          data: {
                            value: this.state.collectGoldTrxSKUWeight,
                          },
                        }),
                      ad === 'Collect Gold SKU' &&
                        this.state.presetData.push({
                          type: 'number',
                          operator: 'equal',
                          displayName: ad,
                          fieldName: 'Collect_Gold_SKU',
                          data: {
                            value: this.state.collectGoldSKU,
                          },
                        })
                    )
                  );
                  const token = getToken();
                  const user = getUser();
                  const response = await GKApi.post(
                    `/marketing/download-audience`,
                    {
                      savePreset: false,
                      presetInfo: {
                        staffId: user,
                        // presetName: this.state.presetName,
                        deviceInfo: {
                          ip: this.state.deviceInfo,
                        },
                      },
                      presetData: this.state.presetData,
                    },
                    {
                      headers: {
                        Authorization: token,
                      },
                    }
                  );


                  this.setState({
                    audienceSize: response.data.total_audience,
                  });
                  this.setState({ presetData: [] }, () => {});
                  this.tog_standard1();
                }}
                color="light"
                className="waves-effect"
              >
                See only audience size
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.savePresetModal}
            toggle={this.tog_standard1}
          >
            <ModalHeader
              toggle={() => {
                this.setState({ savePresetModal: false });
              }}
            >
              Preset Save
            </ModalHeader>

            <ModalBody>
              <Col xl={12}>
                <Row className="mb-3 mx-2">Provide a name for the preset</Row>
                <Row className="mb-3 mx-2">
                  <Input
                    type="text"
                    // oninput={validate(this)}
                    value={this.state.presetName}
                    placeholder="Please give this preset a name"
                    id="gold-price-input"
                    onChange={this.handlePresetNameChangeChange}
                  />
                </Row>
              </Col>
            </ModalBody>

            <ModalFooter>
              {this.state.presetName === '' ? (
                ''
              ) : (
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={async () => {
                    this.state.audienceValues.forEach(
                      (ad) => (
                        ad === 'Profile Name' &&
                          this.state.presetData.push({
                            type: 'string',
                            operator: 'equal',

                            displayName: ad,
                            fieldName: 'Profile_Name',
                            data: {
                              value: this.state.name,
                            },
                            // eslint-disable-next-line
                          }),
                        ad === 'Profile Customer ID' &&
                          this.state.presetData.push({
                            type: 'string',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Profile_Customer_ID',
                            data: {
                              value: this.state.customerId,
                            },
                          }),
                        ad === 'Profile Email' &&
                          this.state.presetData.push({
                            type: 'multiple',
                            operator: 'in',
                            displayName: ad,
                            fieldName: 'Profile_Email',
                            data: {
                              value: this.state.email,
                            },
                          }),
                        ad === 'Profile Date of Birth' &&
                          this.state.presetData.push({
                            type: 'date',
                            operator: 'range',
                            displayName: ad,
                            fieldName: 'Profile_Date_of_Birth',
                            data: {
                              from: moment(
                                this.state.dobSelectedStartDate
                              ).format('DD/MM/YYYY'),
                              to: moment(this.state.dobSelectedEndDate).format(
                                'DD/MM/YYYY'
                              ),
                            },
                          }),
                        ad === 'Profile Date of Joining' &&
                          this.state.presetData.push({
                            type: 'date',
                            operator: 'range',
                            displayName: ad,
                            fieldName: 'Profile_Date_of_Joining',
                            data: {
                              from: moment(
                                this.state.dojSelectedStartDate
                              ).format('DD/MM/YYYY'),
                              to: moment(this.state.dojSelectedEndDate).format(
                                'DD/MM/YYYY'
                              ),
                            },
                          }),
                        ad === 'Profile Gender' &&
                          this.state.presetData.push({
                            type: 'string',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Profile_Gender',
                            data: {
                              value: this.state.gender,
                            },
                          }),
                        ad === 'Profile Age' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'range',
                            displayName: ad,
                            fieldName: 'Profile_Age',
                            data: {
                              from: this.state.ageStart,
                              to: this.state.ageEnd,
                            },
                          }),
                        ad === 'Profile Marital Status' &&
                          this.state.presetData.push({
                            type: 'string',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Profile_Marital_Status',
                            data: {
                              value: this.state.maritalStat,
                            },
                          }),
                        ad === 'Profile Phone Number' &&
                          this.state.presetData.push({
                            type: 'multiple',
                            operator: 'in',
                            displayName: ad,
                            fieldName: 'Profile_Phone_Number',
                            data: {
                              value: this.state.phoneNumber,
                            },
                          }),
                        ad === 'Profile Nominee Name' &&
                          this.state.presetData.push({
                            type: 'string',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Profile_Nominee_Name',
                            data: {
                              value: this.state.nomineeName,
                            },
                          }),
                        ad === 'Profile Nominee Phone Number' &&
                          this.state.presetData.push({
                            type: 'string',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Profile_Nominee_Phone_Number',
                            data: {
                              value: this.state.nomineePhoneNumber,
                            },
                          }),
                        ad === 'Profile Nominee Date of Birth' &&
                          this.state.presetData.push({
                            type: 'date',
                            operator: 'range',
                            displayName: ad,
                            fieldName: 'Profile_Nominee_Date_of_Birth',
                            data: {
                              from: moment(
                                this.state.nomineeDobSelectedStartDate
                              ).format('DD/MM/YYYY'),
                              to: moment(
                                this.state.nomineeDobSelectedEndDate
                              ).format('DD/MM/YYYY'),
                            },
                          }),
                        ad === 'Profile Gold Balance' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'range',
                            displayName: ad,
                            fieldName: 'Profile_Gold_Balance',
                            data: {
                              from: this.state.goldBalanceFrom,
                              to: this.state.goldBalanceTo,
                            },
                          }),
                        ad === 'Profile Profile Status' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Profile_Profile_Status',
                            data: {
                              value:
                                this.state.profileStat === 'Verified'
                                  ? 3
                                  : this.state.profileStat === 'Unverified'
                                  ? 2
                                  : this.state.profileStat === 'Pending'
                                  ? 1
                                  : this.state.profileStat === 'Locked'
                                  ? 4
                                  : this.state.profileStat === 'Incomplete'
                                  ? 5
                                  : this.state.profileStat === 'Rejected'
                                  ? 6
                                  : 0,
                            },
                          }),
                        ad === 'Profile Profession Code' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Profile_Profession_Code',
                            data: {
                              value: this.state.professionCode,
                            },
                          }),
                        ad === 'Profile Mobile Operator' &&
                          this.state.presetData.push({
                            type: 'string',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Profile_Mobile_Operator',
                            data: {
                              value: this.state.mobileOperator,
                            },
                          }),
                        ad === 'Sell Gold Market Price Avg' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Sell_Gold_Market_Price_Avg',
                            data: {
                              value: this.state.sellGoldMarketPriceAverage,
                            },
                          }),
                        ad === 'Sell Gold Trx Quantity' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Sell_Gold_Trx_Quantity',
                            data: {
                              value: this.state.sellGoldTrxQuantity,
                            },
                          }),
                        ad === 'Sell Gold Trx Count' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Sell_Gold_Trx_Count',
                            data: {
                              value: this.state.sellGoldTrxCount,
                            },
                          }),
                        ad === 'Sell Gold Trx Mode MFS' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Sell_Gold_Trx_Mode_MFS',
                            data: {
                              value: this.state.sellGoldTrxModeMFS,
                            },
                          }),
                        ad === 'Sell Gold Trx Mode Bank' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Sell_Gold_Trx_Mode_Bank',
                            data: {
                              value: this.state.sellGoldTrxModeBank,
                            },
                          }),
                        ad === 'Gift Gold Market Price Avg' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Gift_Gold_Market_Price_Avg',
                            data: {
                              value: this.state.giftGoldMarketPriceAverage,
                            },
                          }),
                        ad === 'Gift Gold Trx Quantity' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Gift_Gold_Trx_Quantity',
                            data: {
                              value: this.state.giftGoldTrxQuantity,
                            },
                          }),
                        ad === 'Gift Gold Trx Count' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Gift_Gold_Trx_Count',
                            data: {
                              value: this.state.giftGoldTrxCount,
                            },
                          }),
                        ad === 'Buy Gold Market Price Avg' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Buy_Gold_Market_Price_Avg',
                            data: {
                              value: this.state.buyGoldMarketPriceAverage,
                            },
                          }),
                        ad === 'Buy Gold Trx Quantity' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'range',
                            displayName: ad,
                            fieldName: 'Buy_Gold_Trx_Quantity',
                            data: {
                              from: this.state.buyGoldTrxQuantityStart,
                              to: this.state.buyGoldTrxQuantityEnd,
                            },
                          }),
                        ad === 'Buy Gold Trx Count' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'range',
                            displayName: ad,
                            fieldName: 'Buy_Gold_Trx_Count',
                            data: {
                              from: this.state.buyGoldTrxCountStart,
                              to: this.state.buyGoldTrxCountEnd,
                            },
                          }),
                        ad === 'Buy Gold Trx Mode MFS' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Buy_Gold_Trx_Mode_MFS',
                            data: {
                              value: this.state.buyGoldTrxModeMFS,
                            },
                          }),
                        ad === 'Gift Favourite Gold Account' &&
                          this.state.presetData.push({
                            type: 'string',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Gift_Favourite_Gold_Account',
                            data: {
                              value: this.state.collectGoldFavGoldAccount,
                            },
                          }),
                        ad === 'Collect Coin 2g Count' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Coin_2g_Count',
                            data: {
                              value: this.state.collectCoin2gCount,
                            },
                          }),
                        ad === 'Collect Coin 2g Weight' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Coin_2g_Weight',
                            data: {
                              value: this.state.collectCoin2gWeight,
                            },
                          }),
                        ad === 'Collect Coin 4g Count' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Coin_4g_Count',
                            data: {
                              value: this.state.collectCoin4gCount,
                            },
                          }),
                        ad === 'Collect Coin 4g Weight' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Coin_4g_Weight',
                            data: {
                              value: this.state.collectCoin4gWeight,
                            },
                          }),
                        ad === 'Collect Bar 5g Count' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Bar_5g_Count',
                            data: {
                              value: this.state.collectBar5gCount,
                            },
                          }),
                        ad === 'Collect Bar 5g Weight' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Bar_5g_Weight',
                            data: {
                              value: this.state.collectBar5gWeight,
                            },
                          }),
                        ad === 'Collect Bar 10g Count' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Bar_10g_Count',
                            data: {
                              value: this.state.collectBar10gCount,
                            },
                          }),
                        ad === 'Collect Bar 10g Weight' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Bar_10g_Weight',
                            data: {
                              value: this.state.collectBar10gWeight,
                            },
                          }),
                        ad === 'Collect Gold Trx SKU Count' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Gold_Trx_SKU_Count',
                            data: {
                              value: this.state.collectGoldTrxSKUCount,
                            },
                          }),
                        ad === 'Collect Gold Trx SKU Weight' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Gold_Trx_SKU_Weight',
                            data: {
                              value: this.state.collectGoldTrxSKUWeight,
                            },
                          }),
                        ad === 'Collect Gold SKU' &&
                          this.state.presetData.push({
                            type: 'number',
                            operator: 'equal',
                            displayName: ad,
                            fieldName: 'Collect_Gold_SKU',
                            data: {
                              value: this.state.collectGoldSKU,
                            },
                          })
                      )
                    );
                    const token = getToken();
                    const user = getUser();
                    const response = await GKApi.post(
                      `/marketing/download-audience`,
                      {
                        savePreset: true,
                        presetInfo: {
                          staffId: user,
                          presetName: this.state.presetName,
                          deviceInfo: {
                            ip: this.state.deviceInfo,
                          },
                        },
                        presetData: this.state.presetData,
                      },
                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );


                    this.setState({
                      audienceSize: response.data.total_audience,
                    });
                    this.setState(
                      { presetData: [], audienceValues: [] },
                      () => {}
                    );
                    this.tog_savePresetModal();
                  }}
                >
                  Save Preset
                </Button>
              )}
              <Button
                type="button"
                onClick={async () => {
                  this.tog_savePresetModal();
                }}
                color="light"
                className="waves-effect"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          {this.state.audienceValues.length > 0 && (
            <Button
              className="form-control form-control-lg fw-bold"
              style={{ color: '#081939', backgroundColor: '#c2b56f' }}
              onClick={async () => {
                this.state.audienceValues.forEach(
                  (ad) => (
                    ad === 'Profile Name' &&
                      this.state.presetData.push({
                        type: 'string',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Profile_Name',
                        data: {
                          value: this.state.name,
                        },
                        // eslint-disable-next-line
                      }),
                    ad === 'Profile Customer ID' &&
                      this.state.presetData.push({
                        type: 'string',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Profile_Customer_ID',
                        data: {
                          value: this.state.customerId,
                        },
                      }),
                    ad === 'Profile Email' &&
                      this.state.presetData.push({
                        type: 'multiple',
                        operator: 'in',
                        displayName: ad,
                        fieldName: 'Profile_Email',
                        data: {
                          value: this.state.email,
                        },
                      }),
                    ad === 'Profile Date of Birth' &&
                      this.state.presetData.push({
                        type: 'date',
                        operator: 'range',
                        displayName: ad,
                        fieldName: 'Profile_Date_of_Birth',
                        data: {
                          from: moment(this.state.dobSelectedStartDate).format(
                            'DD/MM/YYYY'
                          ),
                          to: moment(this.state.dobSelectedEndDate).format(
                            'DD/MM/YYYY'
                          ),
                        },
                      }),
                    ad === 'Profile Date of Joining' &&
                      this.state.presetData.push({
                        type: 'date',
                        operator: 'range',
                        displayName: ad,
                        fieldName: 'Profile_Date_of_Joining',
                        data: {
                          from: moment(this.state.dojSelectedStartDate).format(
                            'DD/MM/YYYY'
                          ),
                          to: moment(this.state.dojSelectedEndDate).format(
                            'DD/MM/YYYY'
                          ),
                        },
                      }),
                    ad === 'Profile Gender' &&
                      this.state.presetData.push({
                        type: 'string',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Profile_Gender',
                        data: {
                          value: this.state.gender,
                        },
                      }),
                    ad === 'Profile Age' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'range',
                        displayName: ad,
                        fieldName: 'Profile_Age',
                        data: {
                          from: this.state.ageStart,
                          to: this.state.ageEnd,
                        },
                      }),
                    ad === 'Profile Marital Status' &&
                      this.state.presetData.push({
                        type: 'string',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Profile_Marital_Status',
                        data: {
                          value: this.state.maritalStat,
                        },
                      }),
                    ad === 'Profile Phone Number' &&
                      this.state.presetData.push({
                        type: 'multiple',
                        operator: 'in',
                        displayName: ad,
                        fieldName: 'Profile_Phone_Number',
                        data: {
                          value: this.state.phoneNumber,
                        },
                      }),
                    ad === 'Profile Nominee Name' &&
                      this.state.presetData.push({
                        type: 'string',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Profile_Nominee_Name',
                        data: {
                          value: this.state.nomineeName,
                        },
                      }),
                    ad === 'Profile Nominee Phone Number' &&
                      this.state.presetData.push({
                        type: 'string',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Profile_Nominee_Phone_Number',
                        data: {
                          value: this.state.nomineePhoneNumber,
                        },
                      }),
                    ad === 'Profile Nominee Date of Birth' &&
                      this.state.presetData.push({
                        type: 'date',
                        operator: 'range',
                        displayName: ad,
                        fieldName: 'Profile_Nominee_Date_of_Birth',
                        data: {
                          from: moment(
                            this.state.nomineeDobSelectedStartDate
                          ).format('DD/MM/YYYY'),
                          to: moment(
                            this.state.nomineeDobSelectedEndDate
                          ).format('DD/MM/YYYY'),
                        },
                      }),
                    ad === 'Profile Gold Balance' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'range',
                        displayName: ad,
                        fieldName: 'Profile_Gold_Balance',
                        data: {
                          from: this.state.goldBalanceFrom,
                          to: this.state.goldBalanceTo,
                        },
                      }),
                    ad === 'Profile Profile Status' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Profile_Profile_Status',
                        data: {
                          value:
                            this.state.profileStat === 'Verified'
                              ? 3
                              : this.state.profileStat === 'Unverified'
                              ? 2
                              : this.state.profileStat === 'Pending'
                              ? 1
                              : this.state.profileStat === 'Locked'
                              ? 4
                              : this.state.profileStat === 'Incomplete'
                              ? 5
                              : this.state.profileStat === 'Rejected'
                              ? 6
                              : 0,
                        },
                      }),
                    ad === 'Profile Profession Code' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Profile_Profession_Code',
                        data: {
                          value: this.state.professionCode,
                        },
                      }),
                    ad === 'Profile Mobile Operator' &&
                      this.state.presetData.push({
                        type: 'string',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Profile_Mobile_Operator',
                        data: {
                          value: this.state.mobileOperator,
                        },
                      }),
                    ad === 'Sell Gold Market Price Avg' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Sell_Gold_Market_Price_Avg',
                        data: {
                          value: this.state.sellGoldMarketPriceAverage,
                        },
                      }),
                    ad === 'Sell Gold Trx Quantity' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Sell_Gold_Trx_Quantity',
                        data: {
                          value: this.state.sellGoldTrxQuantity,
                        },
                      }),
                    ad === 'Sell Gold Trx Count' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Sell_Gold_Trx_Count',
                        data: {
                          value: this.state.sellGoldTrxCount,
                        },
                      }),
                    ad === 'Sell Gold Trx Mode MFS' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Sell_Gold_Trx_Mode_MFS',
                        data: {
                          value: this.state.sellGoldTrxModeMFS,
                        },
                      }),
                    ad === 'Sell Gold Trx Mode Bank' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Sell_Gold_Trx_Mode_Bank',
                        data: {
                          value: this.state.sellGoldTrxModeBank,
                        },
                      }),
                    ad === 'Gift Gold Market Price Avg' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Gift_Gold_Market_Price_Avg',
                        data: {
                          value: this.state.giftGoldMarketPriceAverage,
                        },
                      }),
                    ad === 'Gift Gold Trx Quantity' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Gift_Gold_Trx_Quantity',
                        data: {
                          value: this.state.giftGoldTrxQuantity,
                        },
                      }),
                    ad === 'Gift Gold Trx Count' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Gift_Gold_Trx_Count',
                        data: {
                          value: this.state.giftGoldTrxCount,
                        },
                      }),
                    ad === 'Buy Gold Market Price Avg' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Buy_Gold_Market_Price_Avg',
                        data: {
                          value: this.state.buyGoldMarketPriceAverage,
                        },
                      }),
                    ad === 'Buy Gold Trx Quantity' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'range',
                        displayName: ad,
                        fieldName: 'Buy_Gold_Trx_Quantity',
                        data: {
                          from: this.state.buyGoldTrxQuantityStart,
                          to: this.state.buyGoldTrxQuantityEnd,
                        },
                      }),
                    ad === 'Buy Gold Trx Count' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'range',
                        displayName: ad,
                        fieldName: 'Buy_Gold_Trx_Count',
                        data: {
                          from: this.state.buyGoldTrxCountStart,
                          to: this.state.buyGoldTrxCountEnd,
                        },
                      }),
                    ad === 'Buy Gold Trx Mode MFS' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Buy_Gold_Trx_Mode_MFS',
                        data: {
                          value: this.state.buyGoldTrxModeMFS,
                        },
                      }),
                    ad === 'Gift Favourite Gold Account' &&
                      this.state.presetData.push({
                        type: 'string',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Gift_Favourite_Gold_Account',
                        data: {
                          value: this.state.collectGoldFavGoldAccount,
                        },
                      }),
                    ad === 'Collect Coin 2g Count' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Coin_2g_Count',
                        data: {
                          value: this.state.collectCoin2gCount,
                        },
                      }),
                    ad === 'Collect Coin 2g Weight' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Coin_2g_Weight',
                        data: {
                          value: this.state.collectCoin2gWeight,
                        },
                      }),
                    ad === 'Collect Coin 4g Count' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Coin_4g_Count',
                        data: {
                          value: this.state.collectCoin4gCount,
                        },
                      }),
                    ad === 'Collect Coin 4g Weight' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Coin_4g_Weight',
                        data: {
                          value: this.state.collectCoin4gWeight,
                        },
                      }),
                    ad === 'Collect Bar 5g Count' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Bar_5g_Count',
                        data: {
                          value: this.state.collectBar5gCount,
                        },
                      }),
                    ad === 'Collect Bar 5g Weight' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Bar_5g_Weight',
                        data: {
                          value: this.state.collectBar5gWeight,
                        },
                      }),
                    ad === 'Collect Bar 10g Count' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Bar_10g_Count',
                        data: {
                          value: this.state.collectBar10gCount,
                        },
                      }),
                    ad === 'Collect Bar 10g Weight' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Bar_10g_Weight',
                        data: {
                          value: this.state.collectBar10gWeight,
                        },
                      }),
                    ad === 'Collect Gold Trx SKU Count' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Gold_Trx_SKU_Count',
                        data: {
                          value: this.state.collectGoldTrxSKUCount,
                        },
                      }),
                    ad === 'Collect Gold Trx SKU Weight' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Gold_Trx_SKU_Weight',
                        data: {
                          value: this.state.collectGoldTrxSKUWeight,
                        },
                      }),
                    ad === 'Collect Gold SKU' &&
                      this.state.presetData.push({
                        type: 'number',
                        operator: 'equal',
                        displayName: ad,
                        fieldName: 'Collect_Gold_SKU',
                        data: {
                          value: this.state.collectGoldSKU,
                        },
                      })
                  )
                );
                const token = getToken();
                const user = getUser();
                // const response = await GKApi.post(
                //   `/marketing/download-audience/file`,
                //   {
                //     savePreset: false,
                //     presetInfo: {
                //       staffId: user,

                //       deviceInfo: {
                //         ip: this.state.deviceInfo,
                //       },
                //     },
                //     presetData: this.state.presetData,
                //   },
                //   {
                //     headers: {
                //       Authorization: token,
                //     },
                //   }
                // );
                axios({
                  url: `${GKAPI_Base}/marketing/download-audience/file`, //your url
                  method: 'POST',
                  responseType: 'blob', // important
                  data: {
                    savePreset: false,
                    presetInfo: {
                      staffId: user,

                      deviceInfo: {
                        ip: this.state.deviceInfo,
                      },
                    },
                    presetData: this.state.presetData,
                  },

                  headers: {
                    Authorization: token,
                  },
                }).then((response) => {
                  // create file link in browser's memory
                  const href = URL.createObjectURL(response.data);

                  // create "a" HTLM element with href to file & click
                  const link = document.createElement('a');
                  link.href = href;
                  link.setAttribute('download', 'Audience list.csv'); //or any other extension
                  document.body.appendChild(link);
                  link.click();

                  // clean up "a" element & remove ObjectURL
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
                });


                this.setState({ presetData: [], audienceValues: [] }, () => {});
              }}
            >
              Download Audience List
            </Button>
          )}

          <p>Note: Only Profile Email and Profile Phone Number fields can accept multiple values </p>
        </CardBody>
      </Card>
    );
  }
}

export default DownloadAudience;

import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';

import { getToken, GKApi } from '../../../components/config';
import moment from 'moment';

class CampaignRevenueAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: 'Buy',
          data: [],
        },
        {
          name: 'Sell',
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
            },
            export: {
              csv: {
                filename: 'Daily SF and RC Revenue',
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: 'Daily SF and RC Revenue',
              },
              png: {
                filename: 'Daily SF and RC Revenue',
              },
            },
          },
        },

        colors: ['#77B6EA', '#34c38f'],
        dataLabels: {
          enabled: true,
          style: { color: '#ffff' },
          background: {
            //enabled: true,
            foreColor: '#FFFFFF',
            padding: 4,
            borderRadius: 6,
            borderWidth: 2,
            borderColor: '#fff',
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45,
            },
          },
        },
        fill: {
          opacity: 1,
        },
        stroke: { width: [3, 3], curve: 'smooth' },
        title: {
          text: 'Daily Buy Sell',
          align: 'left',
          style: { fontWeight: 'normal', fill: '#373d3f', opacity: 1 },
        },
        grid: {
          row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.2 },
          borderColor: '#e7e7e7',
        },
        markers: { style: 'inverted', size: 2, color: '#fff' },
        xaxis: {
          categories: [],
          title: { text: 'Hour' },
        },
        yaxis: { title: { text: 'Buy Sell' } },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: !0,
          offsetY: -25,
          offsetX: -5,
        },
        responsive: [
          {
            breakpoint: 600,
            options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
          },
        ],
      },
      display:'daily'
    };
  }
  async componentDidMount() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();
    };
    let trxAmountData = [];
    let trxCountData = [];
    let labelData = [];
    if (this.props.revenueAnalyticsData.length > 0) {
      this.props.revenueAnalyticsData.forEach((e) => {
        trxAmountData.push(e.trx_amount.toFixedDown(2));
        trxCountData.push(e.trx_count.toFixedDown(2));
        labelData.push(moment(e.hour, ['HH']).format('hh A'));
      });
    }
    this.setState(
      {
        series: [
          {
            name: 'Trx Amount',
            data: trxAmountData,
          },
          {
            name: 'Trx Count',
            data: trxCountData,
          },
        ],
        options: {
          chart: {
            height: 550,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
              },
              export: {
                csv: {
                  filename: 'Daily SF and RC Revenue',
                  columnDelimiter: ',',
                  headerCategory: 'Month',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp).toDateString();
                  },
                },
                svg: {
                  filename: 'Daily SF and RC Revenue',
                },
                png: {
                  filename: 'Daily SF and RC Revenue',
                },
              },
            },
          },

          colors: ['#77B6EA', '#34c38f'],
          dataLabels: {
            enabled: true,
            style: { color: '#ffff' },
            background: {
              //enabled: true,
              foreColor: '#FFFFFF',
              padding: 4,
              borderRadius: 6,
              borderWidth: 2,
              borderColor: '#fff',
              opacity: 0.9,
              dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45,
              },
            },
          },
          fill: {
            opacity: 1,
          },
          stroke: { width: [3, 3], curve: 'smooth' },
          title: {
            text: 'Revenue Analytics',
            align: 'left',
            style: { fontWeight: 'normal', fill: '#373d3f', opacity: 1 },
          },
          grid: {
            row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.2 },
            borderColor: '#e7e7e7',
          },
          markers: { style: 'inverted', size: 2, color: '#fff' },
          xaxis: {
            categories: labelData,
            title: { text: 'Hour' },
          },
          yaxis: { title: { text: 'Buy Sell' } },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            floating: !0,
            offsetY: -25,
            offsetX: -5,
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: { toolbar: { show: !1 } },
                legend: { show: !1 },
              },
            },
          ],
        },
      },
      () => {}
    );
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div>
              <h4 className="card-title mb-4 fw-bold">Revenue Analytics</h4>
            </div>
            <div className="float-end d-none d-md-inline-block">
              <ButtonGroup className="mb-2">
                <Button
                  active
                  size="sm"
                  color={this.state.display==='daily'?'secondary':"light"}
                  type="button"
                  onClick={async() => {
                    this.setState({
                      ...this.state,
                      display: 'daily',
                    });
                    let trxAmountData = [];
                    let trxCountData = [];
                    let labelData = [];
                    let token = getToken();
                    const response = await GKApi.get(
                        `/marketing/campaign/revenue-analytics?id=${this.props.campId}&type=daily`,
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                      
                     response.data.data.forEach((e) => {
                        trxAmountData.push(e.trx_amount.toFixedDown(2));
                        trxCountData.push(e.trx_count.toFixedDown(2));
                        labelData.push(moment(e.hour, ['HH']).format('hh A'));
                      });
                      this.setState(
                        {
                          series: [
                            {
                              name: 'Trx Amount',
                              data: trxAmountData,
                            },
                            {
                              name: 'Trx Count',
                              data: trxCountData,
                            },
                          ],
                          options: {
                            chart: {
                              height: 350,
                              type: 'line',
                              dropShadow: {
                                enabled: true,
                                color: '#000',
                                top: 18,
                                left: 7,
                                blur: 10,
                                opacity: 0.2,
                              },
                              toolbar: {
                                show: false,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                  download: true,
                                  selection: true,
                                },
                                export: {
                                  csv: {
                                    filename: 'Daily SF and RC Revenue',
                                    columnDelimiter: ',',
                                    headerCategory: 'Month',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                      return new Date(timestamp).toDateString();
                                    },
                                  },
                                  svg: {
                                    filename: 'Daily SF and RC Revenue',
                                  },
                                  png: {
                                    filename: 'Daily SF and RC Revenue',
                                  },
                                },
                              },
                            },
                  
                            colors: ['#77B6EA', '#34c38f'],
                            dataLabels: {
                              enabled: true,
                              style: { color: '#ffff' },
                              background: {
                                //enabled: true,
                                foreColor: '#FFFFFF',
                                padding: 4,
                                borderRadius: 6,
                                borderWidth: 2,
                                borderColor: '#fff',
                                opacity: 0.9,
                                dropShadow: {
                                  enabled: false,
                                  top: 1,
                                  left: 1,
                                  blur: 1,
                                  color: '#000',
                                  opacity: 0.45,
                                },
                              },
                            },
                            fill: {
                              opacity: 1,
                            },
                            stroke: { width: [3, 3], curve: 'smooth' },
                            title: {
                              text: 'Revenue Analytics',
                              align: 'left',
                              style: { fontWeight: 'normal', fill: '#373d3f', opacity: 1 },
                            },
                            grid: {
                              row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.2 },
                              borderColor: '#e7e7e7',
                            },
                            markers: { style: 'inverted', size: 2, color: '#fff' },
                            xaxis: {
                              categories: labelData,
                              title: { text: 'Hour' },
                            },
                            yaxis: { title: { text: 'Buy Sell' } },
                            legend: {
                              position: 'bottom',
                              horizontalAlign: 'center',
                              floating: !0,
                              offsetY: -25,
                              offsetX: -5,
                            },
                            responsive: [
                              {
                                breakpoint: 600,
                                options: {
                                  chart: { toolbar: { show: !1 } },
                                  legend: { show: !1 },
                                },
                              },
                            ],
                          },
                        },
                        () => {}
                      );
                  }}
                >
                  Daily
                </Button>
                <Button
                  active
                  size="sm"
                  color={this.state.display==='weekly'?'secondary':"light"}
                  type="button"
                  onClick={async() => {
                    this.setState({
                      ...this.state,
                      display: 'weekly',
                    });
                    let trxAmountData = [];
                    let trxCountData = [];
                    let labelData = [];
                    let token = getToken();
                    const response = await GKApi.get(
                        `/marketing/campaign/revenue-analytics?id=${this.props.campId}&type=weekly`,
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                     response.data.data.forEach((e) => {
                        trxAmountData.push(e.trx_amount.toFixedDown(2));
                        trxCountData.push(e.trx_count.toFixedDown(2));
                        labelData.push(moment(e.date).format('MMM Do'));
                      });
                      this.setState(
                        {
                          series: [
                            {
                              name: 'Trx Amount',
                              data: trxAmountData,
                            },
                            {
                              name: 'Trx Count',
                              data: trxCountData,
                            },
                          ],
                          options: {
                            chart: {
                              height: 350,
                              type: 'line',
                              dropShadow: {
                                enabled: true,
                                color: '#000',
                                top: 18,
                                left: 7,
                                blur: 10,
                                opacity: 0.2,
                              },
                              toolbar: {
                                show: false,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                  download: true,
                                  selection: true,
                                },
                                export: {
                                  csv: {
                                    filename: 'Daily SF and RC Revenue',
                                    columnDelimiter: ',',
                                    headerCategory: 'Month',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                      return new Date(timestamp).toDateString();
                                    },
                                  },
                                  svg: {
                                    filename: 'Daily SF and RC Revenue',
                                  },
                                  png: {
                                    filename: 'Daily SF and RC Revenue',
                                  },
                                },
                              },
                            },
                  
                            colors: ['#77B6EA', '#34c38f'],
                            dataLabels: {
                              enabled: true,
                              style: { color: '#ffff' },
                              background: {
                                //enabled: true,
                                foreColor: '#FFFFFF',
                                padding: 4,
                                borderRadius: 6,
                                borderWidth: 2,
                                borderColor: '#fff',
                                opacity: 0.9,
                                dropShadow: {
                                  enabled: false,
                                  top: 1,
                                  left: 1,
                                  blur: 1,
                                  color: '#000',
                                  opacity: 0.45,
                                },
                              },
                            },
                            fill: {
                              opacity: 1,
                            },
                            stroke: { width: [3, 3], curve: 'smooth' },
                            title: {
                              text: 'Revenue Analytics',
                              align: 'left',
                              style: { fontWeight: 'normal', fill: '#373d3f', opacity: 1 },
                            },
                            grid: {
                              row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.2 },
                              borderColor: '#e7e7e7',
                            },
                            markers: { style: 'inverted', size: 2, color: '#fff' },
                            xaxis: {
                              categories: labelData,
                              title: { text: 'Hour' },
                            },
                            yaxis: { title: { text: 'Buy Sell' } },
                            legend: {
                              position: 'bottom',
                              horizontalAlign: 'center',
                              floating: !0,
                              offsetY: -25,
                              offsetX: -5,
                            },
                            responsive: [
                              {
                                breakpoint: 600,
                                options: {
                                  chart: { toolbar: { show: !1 } },
                                  legend: { show: !1 },
                                },
                              },
                            ],
                          },
                        },
                        () => {}
                      );
                  }}
                >
                  Weekly
                </Button>
                <Button
                  active
                  size="sm"
                  color={this.state.display==='monthly'?'secondary':"light"}
                  type="button"
                  onClick={async() => {
                    this.setState({
                      ...this.state,
                      display: 'monthly',
                    });
                    let trxAmountData = [];
                    let trxCountData = [];
                    let labelData = [];
                    let token = getToken();
                    const response = await GKApi.get(
                        `/marketing/campaign/revenue-analytics?id=${this.props.campId}&type=monthly`,
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                     response.data.data.forEach((e) => {
                        trxAmountData.push(e.trx_amount.toFixedDown(2));
                        trxCountData.push(e.trx_count.toFixedDown(2));
                        labelData.push(moment(e.date).format('MMM Do'));
                      });
                      this.setState(
                        {
                          series: [
                            {
                              name: 'Trx Amount',
                              data: trxAmountData,
                            },
                            {
                              name: 'Trx Count',
                              data: trxCountData,
                            },
                          ],
                          options: {
                            chart: {
                              height: 350,
                              type: 'line',
                              dropShadow: {
                                enabled: true,
                                color: '#000',
                                top: 18,
                                left: 7,
                                blur: 10,
                                opacity: 0.2,
                              },
                              toolbar: {
                                show: false,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                  download: true,
                                  selection: true,
                                },
                                export: {
                                  csv: {
                                    filename: 'Daily SF and RC Revenue',
                                    columnDelimiter: ',',
                                    headerCategory: 'Month',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                      return new Date(timestamp).toDateString();
                                    },
                                  },
                                  svg: {
                                    filename: 'Daily SF and RC Revenue',
                                  },
                                  png: {
                                    filename: 'Daily SF and RC Revenue',
                                  },
                                },
                              },
                            },
                  
                            colors: ['#77B6EA', '#34c38f'],
                            dataLabels: {
                              enabled: true,
                              style: { color: '#ffff' },
                              background: {
                                //enabled: true,
                                foreColor: '#FFFFFF',
                                padding: 4,
                                borderRadius: 6,
                                borderWidth: 2,
                                borderColor: '#fff',
                                opacity: 0.9,
                                dropShadow: {
                                  enabled: false,
                                  top: 1,
                                  left: 1,
                                  blur: 1,
                                  color: '#000',
                                  opacity: 0.45,
                                },
                              },
                            },
                            fill: {
                              opacity: 1,
                            },
                            stroke: { width: [3, 3], curve: 'smooth' },
                            title: {
                              text: 'Revenue Analytics',
                              align: 'left',
                              style: { fontWeight: 'normal', fill: '#373d3f', opacity: 1 },
                            },
                            grid: {
                              row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.2 },
                              borderColor: '#e7e7e7',
                            },
                            markers: { style: 'inverted', size: 2, color: '#fff' },
                            xaxis: {
                              categories: labelData,
                              title: { text: 'Hour' },
                            },
                            yaxis: { title: { text: 'Buy Sell' } },
                            legend: {
                              position: 'bottom',
                              horizontalAlign: 'center',
                              floating: !0,
                              offsetY: -25,
                              offsetX: -5,
                            },
                            responsive: [
                              {
                                breakpoint: 600,
                                options: {
                                  chart: { toolbar: { show: !1 } },
                                  legend: { show: !1 },
                                },
                              },
                            ],
                          },
                        },
                        () => {}
                      );
                  }}
                >
                  Monthly
                </Button>
              </ButtonGroup>
            </div>
            <div>
              <div id="line-column-chart" className="apex-charts" dir="ltr">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="line"
                  height="380"
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default CampaignRevenueAnalytics;

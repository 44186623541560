import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import {
  getGroupName,
  getToken,
  getUser,
  GKApi,
  roundToTwo,
} from '../../../components/config';
import SweetAlert from 'react-bootstrap-sweetalert';
import Switch from '@mui/material/Switch';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useSelector } from 'react-redux';
import moment from 'moment';

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const name = useSelector((state) => state.Login.user.user?.name);
    const email = useSelector((state) => state.Login.user.user?.email);
    const designation = useSelector(
      (state) => state.Login.user.user?.designation
    );
    const dob = useSelector((state) => state.Login.user.user?.dob);
    const phone = useSelector((state) => state.Login.user.user?.phone);
    const groupId = useSelector((state) => state.Login.user.user?.groupId);
    return (
      <Component
        {...props}
        name={name}
        email={email}
        designation={designation}
        dob={dob}
        phone={phone}
        groupId={groupId}
      />
    );
  };
}

class FinanceOpsInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '#' },
        { title: 'Finance and Operations Inputs', link: '#' },
      ],

      dailyGoldPriceChange: false,
      dailyBuyLimitMinChange: false,
      dailyBuyLimitMaxChange: false,
      serviceChargeChange: false,
      vatChange: false,
      refundChargeChange: false,
      giftChargeChange: false,
      collectionChargeChange: false,
      dailyCollectionLimitMinChange: false,
      dailyCollectionLimitMaxChange: false,
      dailySellLimitMinChange: false,
      dailySellLimitMaxChange: false,
      dailyGiftLimitMinChange: false,
      dailyGiftLimitMaxChange: false,
      morningCutOffChange: false,
      eveningCutOffChange: false,
      goldReserveChange: false,
      insideDhakaPickupChargeChange: false,
      outsideDhakaPickupChargeChange: false,
      toggleSwitch: true,
      dailyGoldPriceDefault: 0,
      dailyBuyLimitMinDefault: 0,
      dailyBuyLimitMaxDefault: 0,
      serviceChargeDefault: 0,
      deliveryChargeInsideDefault: 0,
      deliveryChargeOutsideDefault: 0,
      vatDefault: 0,
      refundChargeDefault: 0,
      giftChargeDefault: 0,
      collectionChargeDefault: 0,
      dailyCollectionLimitMinDefault: 0,
      dailyCollectionLimitMaxDefault: 0,
      dailySellLimitMinDefault: 0,
      dailySellLimitMaxDefault: 0,
      dailyGiftLimitMinDefault: 0,
      dailyGiftLimitMaxDefault: 0,
      morningCutOffDefault: '',
      eveningCutOffDefault: '',
      goldReserveDefault: 0,
      insideDhakaPickupChargeDefault: 0,
      outsideDhakaPickupChargeDefault: 0,
      dailyGoldPrice: 0,
      dailyBuyLimitMin: 0,
      dailyBuyLimitMax: 0,
      serviceCharge: 0,
      vat: 0,
      refundCharge: 0,
      giftCharge: 0,
      collectionCharge: 0,
      dailyCollectionLimitMin: 0,
      dailyCollectionLimitMax: 0,
      dailySellLimitMin: 0,
      dailySellLimitMax: 0,
      dailyGiftLimitMin: 0,
      dailyGiftLimitMax: 0,
      morningCutOff: '13:45:00',
      eveningCutOff: '13:45:00',
      goldReserve: 0,
      insideDhakaPickupCharge: 0,
      outsideDhakaPickupCharge: 0,
      lastApprovedData: {},
      lastRequestedData: {},
      success: '',
      custom_div1: false,
      tooltipOpen: false,
      functionStatus: [],
      modal_standard: false,
      modal_standard1: false,
      modal_standard2: false,
      modal_standard3: false,
      modal_standard4: false,
      modal_standard5: false,
      modal_standard6: false,
      modal_standard7: false,
      modal_standard8: false,
      paused: 0,
      type: '',
      deviceInfo: {},
      reason: '',
      groupId: null,
      canViewSinglePlayPause: 1,
      canViewMasterPlayPause: 1,
      canViewInputOps: 1,
      inputOpsAuthStatForUser: 1,
      compAuthStat: 0,
      canAuthInputOps: 0,
      canInsertInputOps: 0,
      isSuper: false,
      isFin: false,
      deliveryCharges: [],
      deliveryChargesDefault: [],
      skuDetails: [],
      skuCodes: {},
      newDeliveryCharges: [],
      bkashMinDisbursement: 0,
      bkashMinDisbursementDefault: 0,
      bkashMinDisbursementChange: false,
      bkashMaxDisbursement: 0,
      bkashMaxDisbursementDefault: 0,
      bkashMaxDisbursementChange: false,
      bkashDisbursementEnabled: false,
      bkashDisbursementEnabledDefault: false,
      autoPayMaxActiveCount: 0,
      autoPayMaxActiveCountDefault: 0,
      autoPayMaxActiveCountChange: false,
      autoPayMinCycleAmount: 0,
      autoPayMinCycleAmountDefault: 0,
      autoPayMinCycleAmountChange: false,
      autoPayMaxCycleAmount: 0,
      autoPayMaxCycleAmountDefault: 0,
      autoPayMaxCycleAmountChange: false,
    };
  }
  // modal toggles
  removeBodyCss = () => {
    document.body.classList.add('no_padding');
  };

  tog_standard = () => {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
      reason: '',
    }));
    this.removeBodyCss();
  };
  tog_standard1 = () => {
    this.setState((prevState) => ({
      modal_standard1: !prevState.modal_standard1,
    }));
    this.removeBodyCss();
  };
  tog_standard2 = () => {
    this.setState((prevState) => ({
      modal_standard2: !prevState.modal_standard2,
    }));
    this.removeBodyCss();
  };
  tog_standard3 = () => {
    this.setState((prevState) => ({
      modal_standard3: !prevState.modal_standard3,
    }));
    this.removeBodyCss();
  };
  tog_standard4 = () => {
    this.setState((prevState) => ({
      modal_standard4: !prevState.modal_standard4,
    }));
    this.removeBodyCss();
  };
  tog_standard5 = () => {
    this.setState((prevState) => ({
      modal_standard5: !prevState.modal_standard5,
    }));
    this.removeBodyCss();
  };
  tog_standard6 = () => {
    this.setState((prevState) => ({
      modal_standard6: !prevState.modal_standard6,
    }));
    this.removeBodyCss();
  };
  tog_standard7 = () => {
    this.setState((prevState) => ({
      modal_standard7: !prevState.modal_standard7,
    }));
    this.removeBodyCss();
  };
  tog_standard8 = () => {
    this.setState((prevState) => ({
      modal_standard8: !prevState.modal_standard8,
    }));
    this.removeBodyCss();
  };

  // api calls on page load

  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    // GET request using axios with async/await
    const token = getToken();

    // active sku details from api
    const skuDetails = await GKApi.get(`/staff/sku-details`, {
      headers: {
        Authorization: token,
      },
    });

    this.setState({ skuDetails: skuDetails.data.data });

    const mapped = this.state.skuDetails.map((item) => ({
      [item.code]: `${item.type} ${item.weight}`,
    }));
    const newObj = Object.assign({}, ...mapped);

    this.setState({
      skuCodes: newObj,
    });
    // getting permission data from api
    const getPermission = await GKApi.get(`/role/permission?cmpId=16,17,18`, {
      headers: {
        Authorization: token,
      },
    });

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    // setting permissions to their states of their own

    this.setState({
      canViewSinglePlayPause: permissionData[16].canView,
      canViewMasterPlayPause: permissionData[17].canView,
      canViewInputOps: permissionData[18].canView,
      inputOpsAuthStatForUser: permissionData[18].authState,
      canAuthInputOps: permissionData[18].canAuth,
      canInsertInputOps: permissionData[18].canInsert,
    });

    //get request for input data
    const response = await GKApi.get('/finance/operation-input', {
      headers: {
        Authorization: token,
        cmpId: 18,
      },
    });

    this.setState({
      lastApprovedData: response.data.lastApproved,
      lastRequestedData: response.data.lastRequested,
    });
    //setting input data to wait

    if (this.state.lastRequestedData === null) {
      this.setState({
        dailyGoldPriceDefault: this.state.lastApprovedData?.dailyGoldPrice,
        dailyBuyLimitMinDefault: this.state.lastApprovedData?.dailyBuyLimitMin,
        dailyBuyLimitMaxDefault: this.state.lastApprovedData?.dailyBuyLimitMax,
        serviceChargeDefault: this.state.lastApprovedData?.serviceCharge,
        vatDefault: this.state.lastApprovedData?.vat,
        refundChargeDefault: this.state.lastApprovedData?.refundCharge,
        giftChargeDefault: this.state.lastApprovedData?.giftCharge,
        collectionChargeDefault: this.state.lastApprovedData?.collectionCharge,
        dailyCollectionLimitMinDefault:
          this.state.lastApprovedData?.dailyCollectionLimitMin,
        dailyCollectionLimitMaxDefault:
          this.state.lastApprovedData?.dailyCollectionLimitMax,
        dailySellLimitMinDefault:
          this.state.lastApprovedData?.dailySellLimitMin,
        dailySellLimitMaxDefault:
          this.state.lastApprovedData?.dailySellLimitMax,
        dailyGiftLimitMinDefault:
          this.state.lastApprovedData?.dailyGiftLimitMin,
        dailyGiftLimitMaxDefault:
          this.state.lastApprovedData?.dailyGiftLimitMax,
        bkashMinDisbursementDefault:
          this.state.lastApprovedData?.instantDisbursementLimitMin,
        bkashMaxDisbursementDefault:
          this.state.lastApprovedData?.instantDisbursementLimitMax,
        bkashDisbursementEnabledDefault:
          this.state.lastApprovedData?.isBkashInstantDisbursement,
        morningCutOffDefault: this.state.lastApprovedData?.morningCutOff,
        eveningCutOffDefault: this.state.lastApprovedData?.eveningCutOff,
        deliveryChargesDefault: this.state.lastApprovedData?.deliveryCharges,
        goldReserveDefault: roundToTwo(
          this.state.lastApprovedData?.goldReserve
        ),
        insideDhakaPickupChargeDefault:
          this.state.lastApprovedData?.pickupCharge.indhaka_pickup,
        outsideDhakaPickupChargeDefault:
          this.state.lastApprovedData?.pickupCharge.outdhaka_pickup,
        autoPayMaxActiveCountDefault:
          this.state.lastApprovedData?.autoPayMaxActiveCount,
        autoPayMinCycleAmountDefault:
          this.state.lastApprovedData?.autoPayMinCycleAmount,
        autoPayMaxCycleAmountDefault:
          this.state.lastApprovedData?.autoPayMaxCycleAmount,
        dailyGoldPrice: this.state.lastApprovedData?.dailyGoldPrice,
        dailyBuyLimitMin: this.state.lastApprovedData?.dailyBuyLimitMin,
        dailyBuyLimitMax: this.state.lastApprovedData?.dailyBuyLimitMax,
        serviceCharge: this.state.lastApprovedData?.serviceCharge,

        vat: this.state.lastApprovedData?.vat,
        refundCharge: this.state.lastApprovedData?.refundCharge,
        giftCharge: this.state.lastApprovedData?.giftCharge,
        collectionCharge: this.state.lastApprovedData?.collectionCharge,
        dailyCollectionLimitMin:
          this.state.lastApprovedData?.dailyCollectionLimitMin,
        dailyCollectionLimitMax:
          this.state.lastApprovedData?.dailyCollectionLimitMax,
        dailySellLimitMin: this.state.lastApprovedData?.dailySellLimitMin,
        dailySellLimitMax: this.state.lastApprovedData?.dailySellLimitMax,
        dailyGiftLimitMin: this.state.lastApprovedData?.dailyGiftLimitMin,
        dailyGiftLimitMax: this.state.lastApprovedData?.dailyGiftLimitMax,
        bkashMinDisbursement:
          this.state.lastApprovedData?.instantDisbursementLimitMin,
        bkashMaxDisbursement:
          this.state.lastApprovedData?.instantDisbursementLimitMax,
        bkashDisbursementEnabled:
          this.state.lastApprovedData?.isBkashInstantDisbursement,
        morningCutOff: this.state.lastApprovedData?.morningCutOff,
        eveningCutOff: this.state.lastApprovedData?.eveningCutOff,
        deliveryCharges: this.state.lastApprovedData?.deliveryCharges,
        goldReserve: roundToTwo(this.state.lastApprovedData?.goldReserve),
        insideDhakaPickupCharge:
          this.state.lastApprovedData?.pickupCharge.indhaka_pickup,
        outsideDhakaPickupCharge:
          this.state.lastApprovedData?.pickupCharge.outdhaka_pickup,
        autoPayMaxActiveCount:
          this.state.lastApprovedData?.autoPayMaxActiveCount,
        autoPayMinCycleAmount:
          this.state.lastApprovedData?.autoPayMinCycleAmount,
        autoPayMaxCycleAmount:
          this.state.lastApprovedData?.autoPayMaxCycleAmount,
      });
    } else {
      this.setState({
        dailyGoldPriceDefault: this.state.lastApprovedData?.dailyGoldPrice,
        dailyBuyLimitMinDefault: this.state.lastApprovedData?.dailyBuyLimitMin,
        dailyBuyLimitMaxDefault: this.state.lastApprovedData?.dailyBuyLimitMax,
        serviceChargeDefault: this.state.lastApprovedData?.serviceCharge,

        vatDefault: this.state.lastApprovedData?.vat,
        refundChargeDefault: this.state.lastApprovedData?.refundCharge,
        giftChargeDefault: this.state.lastApprovedData?.giftCharge,
        collectionChargeDefault: this.state.lastApprovedData?.collectionCharge,
        dailyCollectionLimitMinDefault:
          this.state.lastApprovedData?.dailyCollectionLimitMin,
        dailyCollectionLimitMaxDefault:
          this.state.lastApprovedData?.dailyCollectionLimitMax,
        dailySellLimitMinDefault:
          this.state.lastApprovedData?.dailySellLimitMin,
        dailySellLimitMaxDefault:
          this.state.lastApprovedData?.dailySellLimitMax,
        dailyGiftLimitMinDefault:
          this.state.lastApprovedData?.dailyGiftLimitMin,
        dailyGiftLimitMaxDefault:
          this.state.lastApprovedData?.dailyGiftLimitMax,
        bkashMinDisbursementDefault:
          this.state.lastApprovedData?.instantDisbursementLimitMin,
        bkashMaxDisbursementDefault:
          this.state.lastApprovedData?.instantDisbursementLimitMax,
        bkashDisbursementEnabledDefault:
          this.state.lastApprovedData?.isBkashInstantDisbursement,
        morningCutOffDefault: this.state.lastApprovedData?.morningCutOff,
        eveningCutOffDefault: this.state.lastApprovedData?.eveningCutOff,
        deliveryChargesDefault: this.state.lastApprovedData?.deliveryCharges,
        goldReserveDefault: roundToTwo(
          this.state.lastApprovedData?.goldReserve
        ),
        insideDhakaPickupChargeDefault:
          this.state.lastApprovedData?.pickupCharge.indhaka_pickup,
        outsideDhakaPickupChargeDefault:
          this.state.lastApprovedData?.pickupCharge.outdhaka_pickup,
        autoPayMaxActiveCountDefault:
          this.state.lastApprovedData?.autoPayMaxActiveCount,
        autoPayMinCycleAmountDefault:
          this.state.lastApprovedData?.autoPayMinCycleAmount,
        autoPayMaxCycleAmountDefault:
          this.state.lastApprovedData?.autoPayMaxCycleAmount,
        dailyGoldPrice: this.state.lastRequestedData?.dailyGoldPrice,
        dailyBuyLimitMin: this.state.lastRequestedData?.dailyBuyLimitMin,
        dailyBuyLimitMax: this.state.lastRequestedData?.dailyBuyLimitMax,
        serviceCharge: this.state.lastRequestedData?.serviceCharge,

        vat: this.state.lastRequestedData?.vat,
        refundCharge: this.state.lastRequestedData?.refundCharge,
        giftCharge: this.state.lastRequestedData?.giftCharge,
        collectionCharge: this.state.lastRequestedData?.collectionCharge,
        dailyCollectionLimitMin:
          this.state.lastRequestedData?.dailyCollectionLimitMin,
        dailyCollectionLimitMax:
          this.state.lastRequestedData?.dailyCollectionLimitMax,
        dailySellLimitMin: this.state.lastRequestedData?.dailySellLimitMin,
        dailySellLimitMax: this.state.lastRequestedData?.dailySellLimitMax,
        dailyGiftLimitMin: this.state.lastRequestedData?.dailyGiftLimitMin,
        dailyGiftLimitMax: this.state.lastRequestedData?.dailyGiftLimitMax,
        bkashMinDisbursement:
          this.state.lastRequestedData?.instantDisbursementLimitMin,
        bkashMaxDisbursement:
          this.state.lastRequestedData?.instantDisbursementLimitMax,
        bkashDisbursementEnabled:
          this.state.lastRequestedData?.isBkashInstantDisbursement,
        morningCutOff: this.state.lastRequestedData?.morningCutOff,
        eveningCutOff: this.state.lastRequestedData?.eveningCutOff,
        deliveryCharges: this.state.lastRequestedData?.deliveryCharges,
        goldReserve: roundToTwo(this.state.lastRequestedData?.goldReserve),
        insideDhakaPickupCharge:
          this.state.lastRequestedData?.pickupCharge.indhaka_pickup,
        outsideDhakaPickupCharge:
          this.state.lastRequestedData?.pickupCharge.outdhaka_pickup,
        compAuthStat: this.state.lastRequestedData?.compAuthStat,
        autoPayMaxActiveCount:
          this.state.lastRequestedData?.autoPayMaxActiveCount,
        autoPayMinCycleAmount:
          this.state.lastRequestedData?.autoPayMinCycleAmount,
        autoPayMaxCycleAmount:
          this.state.lastRequestedData?.autoPayMaxCycleAmount,
      });
    }

    //function status api call
    const functionStatusResponse = await GKApi.get('/finance/play-pause', {
      headers: {
        Authorization: token,
        cmpId: 17,
      },
    });

    //setting function status data to state
    this.setState({ functionStatus: functionStatusResponse.data.data });

    // calling geo location api call for the device info
    this.setState({ deviceInfo: {
      country_code: 'BD',
      country_name: 'Bangladesh',
      city: 'Dhaka',
      postal: '1000',
      latitude: 23.729,
      longitude: 90.4112,
      IPv4: '198.188.191.116',
      state: 'Dhaka',
    } });
  }

  //  change handlers

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  handleGoldPriceChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        dailyGoldPrice: event.target.value,
        dailyGoldPriceChange: true,
      });
    }
  };
  handleBuyLimitMinChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        dailyBuyLimitMin: event.target.value,
        dailyBuyLimitMinChange: true,
      });
    }
  };
  handleBuyLimitMaxChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        dailyBuyLimitMax: event.target.value,
        dailyBuyLimitMaxChange: true,
      });
    }
  };
  handleServiceChargeChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        serviceCharge: event.target.value,
        serviceChargeChange: true,
      });
    } else
      this.setState({
        ...this.state,
        serviceCharge: '',
        serviceChargeChange: false,
      });
  };

  handleVatChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        vat: event.target.value,
        vatChange: true,
      });
    } else this.setState({ ...this.state, vat: '', vatChange: false });
  };
  handleRefundChargeChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        refundCharge: event.target.value,
        refundChargeChange: true,
      });
    } else
      this.setState({
        ...this.state,
        refundCharge: '',
        refundChargeChange: false,
      });
  };
  handleGiftChargeChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        giftCharge: event.target.value,
        giftChargeChange: true,
      });
    } else
      this.setState({ ...this.state, giftCharge: '', giftChargeChange: false });
  };
  handleCollectionChargeChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (
      (event.target.value === '' || re.test(event.target.value)) &&
      parseFloat(event.target.value) <= 100
    ) {
      this.setState({
        ...this.state,
        collectionCharge: event.target.value,
        collectionChargeChange: true,
      });
    } else
      this.setState({
        ...this.state,
        collectionCharge: '',
        collectionChargeChange: false,
      });
  };
  handleDailyCollectionLimitMinChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        dailyCollectionLimitMin: event.target.value,
        dailyCollectionLimitMinChange: true,
      });
    }
  };
  handleDailyCollectionLimitMaxChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        dailyCollectionLimitMax: event.target.value,
        dailyCollectionLimitMaxChange: true,
      });
    }
  };
  handleDailySellLimitMinChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        dailySellLimitMin: event.target.value,
        dailySellLimitMinChange: true,
      });
    }
  };
  handleDailySellLimitMaxChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        dailySellLimitMax: event.target.value,
        dailySellLimitMaxChange: true,
      });
    }
  };
  handleDailyGiftLimitMinChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        dailyGiftLimitMin: event.target.value,
        dailyGiftLimitMinChange: true,
      });
    }
  };
  handleDailyGiftLimitMaxChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        dailyGiftLimitMax: event.target.value,
        dailyGiftLimitMaxChange: true,
      });
    }
  };
  handleMorningCutOffChange = (event) => {
    this.setState({
      ...this.state,
      morningCutOff: event.target.value,
      morningCutOffChange: true,
    });
  };
  handleEveningCutOffChange = (event) => {
    this.setState({
      ...this.state,
      eveningCutOff: event.target.value,
      eveningCutOffChange: true,
    });
  };
  handleGoldReserveChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        goldReserve: event.target.value,
        goldReserveChange: true,
      });
    } else
      this.setState({
        ...this.state,
        goldReserve: null,
        goldReserveChange: false,
      });
  };

  handleBkashMinDisbursementChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        bkashMinDisbursement: event.target.value,
        bkashMinDisbursementChange: true,
      });
    }
  };
  handleBkashMaxDisbursementChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        bkashMaxDisbursement: event.target.value,
        bkashMaxDisbursementChange: true,
      });
    }
  };

  handleBkashDisbursementEnabledChange = (event) => {
    this.setState({ bkashDisbursementEnabled: event.target.checked });
  };

  handleReasonChange = (event) => {
    this.setState({ ...this.state, reason: event.target.value });
  };

  handleInsideDhakaPickupChargeChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        insideDhakaPickupCharge: event.target.value,
        insideDhakaPickupChargeChange: true,
      });
    }
  };

  handleOutsideDhakaPickupChargeChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        outsideDhakaPickupCharge: event.target.value,
        outsideDhakaPickupChargeChange: true,
      });
    }
  };

  handleAutoPayMaxActiveCountChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        autoPayMaxActiveCount: event.target.value,
        autoPayMaxActiveCountChange: true,
      });
    }
  };

  handleAutoPayMinCycleAmountChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        autoPayMinCycleAmount: event.target.value,
        autoPayMinCycleAmountChange: true,
      });
    }
  };
  handleAutoPayMaxCycleAmountChange = (event) => {
    const re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        autoPayMaxCycleAmount: event.target.value,
        autoPayMaxCycleAmountChange: true,
      });
    }
  };

  //change handlers

  handleSubmit = async (event) => {
    event.preventDefault();

    const updatedDeliveryCode = this.state.newDeliveryCharges.map((item) => ({
      [item.code]: item.code,
    }));
    const updatedDeliveryCodeObj = Object.assign({}, ...updatedDeliveryCode);

    const notUpdatedDeliveryCharges = this.state.deliveryChargesDefault.filter(
      (item) => item.code !== updatedDeliveryCodeObj[item.code]
    );

    let sendData = {
      dailyGoldPrice: this.state.dailyGoldPrice,
      dailyBuyLimitMin: this.state.dailyBuyLimitMin,
      dailyBuyLimitMax: this.state.dailyBuyLimitMax,
      serviceCharge: this.state.serviceCharge,
      vat: this.state.vat,
      refundCharge: this.state.refundCharge,
      giftCharge: this.state.giftCharge,
      collectionCharge: this.state.collectionCharge,
      dailyCollectionLimitMin: this.state.dailyCollectionLimitMin,
      dailyCollectionLimitMax: this.state.dailyCollectionLimitMax,
      dailySellLimitMin: this.state.dailySellLimitMin,
      dailySellLimitMax: this.state.dailySellLimitMax,
      dailyGiftLimitMin: this.state.dailyGiftLimitMin,
      dailyGiftLimitMax: this.state.dailyGiftLimitMax,
      morningCutOff: moment(this.state.morningCutOff, 'HH:mm:ss').format(
        'HH:mm:ss'
      ),
      eveningCutOff: moment(this.state.eveningCutOff, 'HH:mm:ss').format(
        'HH:mm:ss'
      ),
      goldReserve: this.state.goldReserve,
      isBkashInstantDisbursement: this.state.bkashDisbursementEnabled,
      instantDisbursementLimitMin: this.state.bkashMinDisbursement,
      instantDisbursementLimitMax: this.state.bkashMaxDisbursement,
      deviceInfo: this.state.deviceInfo,
      deliveryCharges:
        this.state.newDeliveryCharges.length === 0
          ? this.state.deliveryChargesDefault
          : [...notUpdatedDeliveryCharges, ...this.state.newDeliveryCharges],
      pickupCharge: {
        insideDhaka: this.state.insideDhakaPickupCharge,
        outsideDhaka: this.state.outsideDhakaPickupCharge,
      },
      autoPayMaxActiveCount: this.state.autoPayMaxActiveCount,
      autoPayMinCycleAmount: this.state.autoPayMinCycleAmount,
      autoPayMaxCycleAmount: this.state.autoPayMaxCycleAmount,
    };
    const token = getToken();
    let submitData = await GKApi.post('/finance/operation-input', sendData, {
      headers: {
        Authorization: token,
        cmpId: 18,
      },
    });
    if (submitData) {
      this.setState({
        ...this.state,
        // success: 'Finance Inputs Updated Successfully',
        custom_div1: true,
      });
      setTimeout(() => {
        this.setState({ custom_div1: false });
      }, 5000);
    }
    const response = await GKApi.get('/finance/operation-input', {
      headers: {
        Authorization: token,
        cmpId: 18,
      },
    });

    this.setState(
      {
        lastApprovedData: response.data.lastApproved,
        lastRequestedData: response.data.lastRequested,
      },
      () => {}
    );
  };

  render() {
    const authDiff =
      this.state.inputOpsAuthStatForUser - this.state.compAuthStat;

    const loggedUser = getUser();
    const styles = {
      root: {
        height: 1000,
        width: 400,
      },
    };

    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isSuper === true || this.state.isFin ? (
            <Container fluid>
              <Breadcrumbs
                title="Finance and Operations Inputs"
                breadcrumbItems={this.state.breadcrumbItems}
              />

              <Col xl={12}>
                <Row>
                  {/* mapping individual function data  */}
                  {this.state.functionStatus.map((func, index) =>
                    // checking permission for viewing the individual play pause
                    this.state.canViewSinglePlayPause === 1 ? (
                      <>
                        <Col xl={3} key={index} id="16">
                          <Card className="p-2">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-1 overflow-hidden justify-content-center">
                                  <h3
                                    className="font-size-14 mb-3"
                                    style={{
                                      fontWeight: 'bold',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {func.type}
                                  </h3>
                                  <div className="d-flex justify-content-center">
                                    <Button
                                      style={{
                                        width: '50%',
                                        alignContent: 'center',
                                        backgroundColor:
                                          func.isPaused === 1
                                            ? '#ff0000'
                                            : '#00FF00',
                                        color: '#ffffff',
                                      }}
                                      onClick={(event) => {
                                        event.preventDefault();

                                        // ?
                                        this.setState({
                                          ...this.state,
                                          paused: func.isPaused,
                                          type: func.type,
                                          modal_standard: true,
                                        });
                                      }}
                                    >
                                      {func.isPaused === 1
                                        ? 'Stopped'
                                        : 'Running'}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                          <Modal
                            isOpen={this.state.modal_standard}
                            toggle={this.tog_standard}
                          >
                            <ModalHeader
                              toggle={() => {
                                this.setState({ modal_standard: false });
                              }}
                            >
                              Confirmation
                            </ModalHeader>

                            <ModalBody>
                              <Col xl={12}>
                                <Row className="mb-3 mx-2">
                                  Are you sure you want to{' '}
                                  {this.state.paused === 1 ? 'Resume' : 'Stop'}{' '}
                                  {this.state.type}?
                                </Row>
                                <Row className="mb-3 mx-2">
                                  <Input
                                    type="text"
                                    // oninput={validate(this)}
                                    value={this.state.reason}
                                    placeholder="Please specify the reason"
                                    id="gold-price-input"
                                    onChange={this.handleReasonChange}
                                  />
                                </Row>
                              </Col>
                            </ModalBody>

                            {this.state.reason === '' ? (
                              ''
                            ) : (
                              <ModalFooter>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="waves-effect waves-light"
                                  onClick={async (event) => {
                                    event.preventDefault();
                                    const token = getToken();
                                    const user = getUser();
                                    const submit = await GKApi.post(
                                      `/finance/play-pause`,
                                      {
                                        deviceIP: this.state.deviceInfo.IPv4,
                                        deviceInfo: this.state.deviceInfo,
                                        isPaused:
                                          this.state.paused === 1
                                            ? false
                                            : true,
                                        trxType:
                                          this.state.type === 'Buy'
                                            ? 'BUY'
                                            : this.state.type === 'Sell'
                                            ? 'SELL'
                                            : this.state.type === 'Collect'
                                            ? 'COLLECT'
                                            : 'GIFT',
                                        staffId: user,
                                        reason: this.state.reason,
                                        cmpId: 17,
                                      },
                                      {
                                        headers: {
                                          Authorization: token,
                                        },
                                      }
                                    );
                                    if (submit.status === 200) {
                                      const response = await GKApi.get(
                                        `finance/play-pause`,
                                        {
                                          headers: {
                                            Authorization: token,
                                            cmpId: 17,
                                          },
                                        }
                                      );
                                      this.setState({
                                        functionStatus: response.data.data,
                                      });
                                    }
                                    this.setState((prevState) => ({
                                      modal_standard: !prevState.modal_standard,
                                      reason: '',
                                    }));
                                    this.removeBodyCss();
                                  }}
                                >
                                  Yes
                                </Button>
                                <Button
                                  type="button"
                                  onClick={this.tog_standard}
                                  color="light"
                                  className="waves-effect"
                                >
                                  No
                                </Button>
                              </ModalFooter>
                            )}
                          </Modal>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )
                  )}

                  {/* permission for master play pause */}
                  {this.state.canViewMasterPlayPause === 1 ? (
                    //master play-pause
                    <Col
                      xl={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      id="17"
                    >
                      <Card className="p-2" style={{ width: '50%' }}>
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                              <h3
                                className="font-size-14 mb-3"
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                }}
                              >
                                Master
                              </h3>
                              <div className="d-flex justify-content-center gap-5">
                                <i
                                  className="fas fa-play font-size-40"
                                  style={{
                                    color: '#00FF00',
                                  }}
                                  onClick={async (event) => {
                                    event.preventDefault();
                                    this.setState({ modal_standard1: true });
                                  }}
                                ></i>
                                <i
                                  className="fas fa-pause font-size-40"
                                  style={{
                                    color: '#ff0000',
                                  }}
                                  onClick={async (event) => {
                                    event.preventDefault();
                                    this.setState({ modal_standard2: true });
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                          <Modal
                            isOpen={this.state.modal_standard1}
                            toggle={this.tog_standard1}
                          >
                            <ModalHeader
                              toggle={() => {
                                this.setState({ modal_standard1: false });
                              }}
                            >
                              Confirmation
                            </ModalHeader>

                            <ModalBody>
                              <Col xl={12}>
                                <Row className="mb-3 mx-2">
                                  Are you sure you want to Start all features?
                                </Row>
                                <Row className="mb-3 mx-2">
                                  <Input
                                    type="text"
                                    // oninput={validate(this)}
                                    value={this.state.reason}
                                    placeholder="Please specify the reason"
                                    id="gold-price-input"
                                    onChange={this.handleReasonChange}
                                  />
                                </Row>
                              </Col>
                            </ModalBody>

                            {this.state.reason === '' ? (
                              ''
                            ) : (
                              <ModalFooter>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="waves-effect waves-light"
                                  onClick={async (event) => {
                                    event.preventDefault();
                                    const token = getToken();
                                    const user = getUser();
                                    const submit = await GKApi.post(
                                      `/finance/play-pause`,
                                      {
                                        deviceIP: this.state.deviceInfo.IPv4,
                                        deviceInfo: this.state.deviceInfo,
                                        isPaused: false,
                                        trxType: 'ALL',
                                        staffId: user,
                                        reason: this.state.reason,
                                        cmpId: 17,
                                      },
                                      {
                                        headers: {
                                          Authorization: token,
                                        },
                                      }
                                    );
                                    if (submit.status === 200) {
                                      const response = await GKApi.get(
                                        `finance/play-pause`,
                                        {
                                          headers: {
                                            Authorization: token,
                                            cmpId: 17,
                                          },
                                        }
                                      );
                                      this.setState({
                                        functionStatus: response.data.data,
                                      });
                                    }
                                    this.setState((prevState) => ({
                                      modal_standard1:
                                        !prevState.modal_standard1,
                                      reason: '',
                                    }));
                                    this.removeBodyCss();
                                  }}
                                >
                                  Yes
                                </Button>
                                <Button
                                  type="button"
                                  onClick={this.tog_standard1}
                                  color="light"
                                  className="waves-effect"
                                >
                                  No
                                </Button>
                              </ModalFooter>
                            )}
                          </Modal>
                          <Modal
                            isOpen={this.state.modal_standard2}
                            toggle={this.tog_standard2}
                          >
                            <ModalHeader
                              toggle={() => {
                                this.setState({ modal_standard2: false });
                              }}
                            >
                              Confirmation
                            </ModalHeader>

                            <ModalBody>
                              <Col xl={12}>
                                <Row className="mb-3 mx-2">
                                  Are you sure you want to Stop all features?
                                </Row>
                                <Row className="mb-3 mx-2">
                                  <Input
                                    type="text"
                                    // oninput={validate(this)}
                                    value={this.state.reason}
                                    placeholder="Please specify the reason"
                                    id="gold-price-input"
                                    onChange={this.handleReasonChange}
                                  />
                                </Row>
                              </Col>
                            </ModalBody>

                            {this.state.reason === '' ? (
                              ''
                            ) : (
                              <ModalFooter>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="waves-effect waves-light"
                                  onClick={async (event) => {
                                    event.preventDefault();
                                    const token = getToken();
                                    const user = getUser();
                                    const submit = await GKApi.post(
                                      `/finance/play-pause`,
                                      {
                                        deviceIP: this.state.deviceInfo.IPv4,
                                        deviceInfo: this.state.deviceInfo,
                                        isPaused: true,
                                        trxType: 'ALL',
                                        staffId: user,
                                        reason: this.state.reason,
                                        cmpId: 17,
                                      },
                                      {
                                        headers: {
                                          Authorization: token,
                                        },
                                      }
                                    );
                                    if (submit.status === 200) {
                                      const response = await GKApi.get(
                                        `finance/play-pause`,
                                        {
                                          headers: {
                                            Authorization: token,
                                            cmpId: 17,
                                          },
                                        }
                                      );
                                      this.setState({
                                        functionStatus: response.data.data,
                                      });
                                    }
                                    this.setState((prevState) => ({
                                      modal_standard2:
                                        !prevState.modal_standard2,
                                      reason: '',
                                    }));
                                    this.removeBodyCss();
                                  }}
                                >
                                  Yes
                                </Button>
                                <Button
                                  type="button"
                                  onClick={this.tog_standard2}
                                  color="light"
                                  className="waves-effect"
                                >
                                  No
                                </Button>
                              </ModalFooter>
                            )}
                          </Modal>
                        </CardBody>
                      </Card>
                    </Col>
                  ) : (
                    <Col xl={12}>
                      <></>
                    </Col>
                  )}
                </Row>

                {/* finance inputs with the previous data and proposed data. */}
                {this.state.canViewInputOps === 1 ? (
                  <Form onSubmit={this.handleSubmit} id="18">
                    <FormGroup>
                      <Row>
                        <Col>
                          <Card>
                            <CardBody>
                              <h4 className="card-title">
                                Finance and Operations Inputs
                              </h4>
                              <p className="card-title-desc">
                                Please input Financial and Operations related
                                values here.
                              </p>
                              <div className="d-flex mb-3">
                                <Col xs={4}></Col>
                                <Col xs={4}>
                                  <h5>Present Value</h5>
                                </Col>
                                <Col xs={4}>
                                  <h5>Proposed Value</h5>
                                </Col>
                              </div>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="gold-price-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Daily Gold Price Input (in BDT){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    min="0.01"
                                    step="0.01"
                                    value={this.state.dailyGoldPriceDefault}
                                    placeholder="Please input the value for Daily Gold Price"
                                    id="gold-price-input"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.dailyGoldPrice}
                                    placeholder="Please input the value for Daily Gold Price"
                                    id="gold-price-input"
                                    onChange={this.handleGoldPriceChange}
                                    style={{
                                      backgroundColor:
                                        this.state.dailyGoldPriceChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    // disabled={
                                    //   this.state.inputOpsAuthStatForUser === 2 ||
                                    //   (this.state.inputOpsAuthStatForUser === 3 &&
                                    //     this.state.lastRequestedData === null)
                                    //     ? true
                                    //     : this.state.inputOpsAuthStatForUser ===
                                    //         1 &&
                                    //       this.state.lastRequestedData === null
                                    //     ? false
                                    //     : this.state.inputOpsAuthStatForUser ===
                                    //         1 &&
                                    //       this.state.lastRequestedData != null
                                    //     ? true
                                    //     : true
                                    // }

                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="gold-price-input"
                                  className="col-md-3 col-form-label"
                                >
                                  Daily Gold Reserve (in gm){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    value={
                                      this.state.goldReserveDefault
                                        ? this.state.goldReserveDefault
                                        : 0
                                    }
                                    placeholder="Please input the value for Daily Gold Reserve"
                                    id="gold-reserve-input"
                                    disabled
                                  />
                                  <Input
                                    value={
                                      this.state.goldReserve
                                        ? this.state.goldReserve
                                        : null
                                    }
                                    placeholder="Please input the value for Daily Gold Reserve"
                                    id="gold-reserve-input"
                                    onChange={this.handleGoldReserveChange}
                                    style={{
                                      backgroundColor:
                                        this.state.goldReserveChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="Per-transaction-buy-limit"
                                  className={`col-md-3 col-form-label`}
                                >
                                  Per transaction Buy Limit (in BDT){' '}
                                </Label>
                                <Col md={9} className="d-flex gap-4">
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <span>Max:</span>
                                        <Input
                                          value={
                                            this.state.dailyBuyLimitMinDefault
                                          }
                                          placeholder="Please input the value for Daily Buy Limit"
                                          id="Per-transaction-buy-limit"
                                          disabled
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <span>Max:</span>

                                        <Input
                                          value={
                                            this.state.dailyBuyLimitMaxDefault
                                          }
                                          placeholder="Please input the value for Daily Buy Limit"
                                          id="Per-transaction-buy-limit"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={this.state.dailyBuyLimitMin}
                                          placeholder="Please input the value for Daily Buy Limit"
                                          id="daily-buy-limit"
                                          onChange={
                                            this.handleBuyLimitMinChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .dailyBuyLimitMinChange === true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={this.state.dailyBuyLimitMax}
                                          placeholder="Please input the value for Daily Buy Limit"
                                          id="daily-buy-limit"
                                          onChange={
                                            this.handleBuyLimitMaxChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .dailyBuyLimitMaxChange === true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="service-charge"
                                  className="col-md-3 col-form-label"
                                >
                                  Service Charge (in %)
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    value={this.state.serviceChargeDefault}
                                    placeholder="Please input the value for Service Charge"
                                    id="service-charge"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.serviceCharge}
                                    placeholder="Please input the value for Service Charge"
                                    id="service-charge"
                                    onChange={this.handleServiceChargeChange}
                                    style={{
                                      backgroundColor:
                                        this.state.serviceChargeChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    // disabled={
                                    //   (this.state.canAuthInputOps === 1 ||
                                    //     this.state.canInsertInputOps === 1) &&
                                    //   this.state.lastRequestedData === null
                                    //     ? false
                                    //     : true
                                    // }
                                    disabled
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="vat"
                                  className="col-md-3 col-form-label"
                                >
                                  VAT (in %)
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    value={this.state.vatDefault}
                                    placeholder="Please input the value for VAT"
                                    id="vat"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.vat}
                                    placeholder="Please input the value for VAT"
                                    id="vat"
                                    onChange={this.handleVatChange}
                                    style={{
                                      backgroundColor:
                                        this.state.vatChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="refund-charge"
                                  className="col-md-3 col-form-label"
                                >
                                  Refund Charge (in %)
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    value={this.state.refundChargeDefault}
                                    placeholder="Please input the value for refund Charge"
                                    id="refund-charge"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.refundCharge}
                                    placeholder="Please input the value for refund Charge"
                                    id="refund-charge"
                                    onChange={this.handleRefundChargeChange}
                                    style={{
                                      backgroundColor:
                                        this.state.refundChargeChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="gift-charge"
                                  className="col-md-3 col-form-label"
                                >
                                  Gift Charge (in %)
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    value={this.state.giftChargeDefault}
                                    placeholder="Please input the value for gift charge"
                                    id="gift-charge"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.giftCharge}
                                    placeholder="Please input the value for gift charge"
                                    id="gift-charge"
                                    onChange={this.handleGiftChargeChange}
                                    style={{
                                      backgroundColor:
                                        this.state.giftChargeChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="collection-service-charge"
                                  className="col-md-3 col-form-label"
                                >
                                  Collection Service Charge (in %)
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    value={this.state.collectionChargeDefault}
                                    placeholder="Please input the value for Collection Service Charge"
                                    id="collection-service-charge"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.collectionCharge}
                                    placeholder="Please input the value for Collection Service Charge"
                                    id="collection-service-charge"
                                    onChange={this.handleCollectionChargeChange}
                                    style={{
                                      backgroundColor:
                                        this.state.collectionChargeChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Label
                                  htmlFor="collection-service-charge"
                                  className="col-md-3 col-form-label"
                                >
                                  Auto pay max active count
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    value={
                                      this.state.autoPayMaxActiveCountDefault
                                    }
                                    placeholder="Please input the value for Auto pay max active count"
                                    id="auto-pay-max-active-count"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.autoPayMaxActiveCount}
                                    placeholder="Please input the value for Collection Service Charge"
                                    id="collection-service-charge"
                                    onChange={
                                      this.handleAutoPayMaxActiveCountChange
                                    }
                                    style={{
                                      backgroundColor:
                                        this.state
                                          .autoPayMaxActiveCountChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="per-transaction-sell-limit"
                                  className="col-md-3 col-form-label"
                                >
                                  Auto pay cycle amount (in BDT)
                                </Label>
                                <Col md={9} className="d-flex gap-4">
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={
                                            this.state.autoPayMinCycleAmount
                                          }
                                          placeholder="Please input the value for minimum auto pay cycle amount"
                                          id="auto-pay-min-cycle-amount"
                                          disabled
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={
                                            this.state.autoPayMaxCycleAmount
                                          }
                                          placeholder="Please input the value for maximum auto pay cycle amount"
                                          id="auto-pay-max-cycle-amount"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={
                                            this.state.autoPayMinCycleAmount
                                          }
                                          placeholder="Please input the value for minimum auto pay cycle amount"
                                          id="auto-pay-min-cycle-amount"
                                          onChange={
                                            this
                                              .handleAutoPayMinCycleAmountChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .autoPayMinCycleAmountChange ===
                                              true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={
                                            this.state.autoPayMaxCycleAmount
                                          }
                                          placeholder="Please input the value for maximum auto pay cycle amount"
                                          id="auto-pay-max-cycle-amount"
                                          onChange={
                                            this
                                              .handleAutoPayMaxCycleAmountChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .autoPayMaxCycleAmountChange ===
                                              true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="per-transaction-collection-limit"
                                  className="col-md-3 col-form-label"
                                >
                                  Per transaction Collection Limit (in gm)
                                </Label>
                                <Col md={9} className="d-flex gap-4">
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={
                                            this.state
                                              .dailyCollectionLimitMinDefault
                                          }
                                          placeholder="Please input the value for Per transaction Minimum Collection Limit"
                                          id="per-transaction-collection-limit-min"
                                          disabled
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={
                                            this.state
                                              .dailyCollectionLimitMaxDefault
                                          }
                                          placeholder="Please input the value for Per transaction Maximum Collection Limit"
                                          id="per-transaction-collection-limit-max"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={
                                            this.state.dailyCollectionLimitMin
                                          }
                                          placeholder="Please input the value for Per transaction Collection Limit"
                                          id="daily-collection-limit"
                                          onChange={
                                            this
                                              .handleDailyCollectionLimitMinChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .dailyCollectionLimitMinChange ===
                                              true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={
                                            this.state.dailyCollectionLimitMax
                                          }
                                          placeholder="Please input the value for Per transaction Collection Limit"
                                          id="daily-collection-limit"
                                          onChange={
                                            this
                                              .handleDailyCollectionLimitMaxChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .dailyCollectionLimitMaxChange ===
                                              true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="per-transaction-sell-limit"
                                  className="col-md-3 col-form-label"
                                >
                                  Per transaction Sell Limit (in BDT)
                                </Label>
                                <Col md={9} className="d-flex gap-4">
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={
                                            this.state.dailySellLimitMinDefault
                                          }
                                          placeholder="Please input the value for Per transaction Sell Limit"
                                          id="per-transaction-sell-limit-min"
                                          disabled
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={
                                            this.state.dailySellLimitMaxDefault
                                          }
                                          placeholder="Please input the value for Per transaction Sell Limit"
                                          id="per-transaction-sell-limit-max"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={this.state.dailySellLimitMin}
                                          placeholder="Please input the value for Per transaction Sell Limit"
                                          id="per-transaction-sell-limit"
                                          onChange={
                                            this.handleDailySellLimitMinChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .dailySellLimitMinChange ===
                                              true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={this.state.dailySellLimitMax}
                                          placeholder="Please input the value for Per transaction Sell Limit"
                                          id="per-transaction-sell-limit"
                                          onChange={
                                            this.handleDailySellLimitMaxChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .dailySellLimitMaxChange ===
                                              true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="per-transaction-gift-limit"
                                  className="col-md-3 col-form-label"
                                >
                                  Per transaction Gift Limit (in BDT)
                                </Label>
                                <Col md={9} className="d-flex gap-4">
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={
                                            this.state.dailyGiftLimitMinDefault
                                          }
                                          placeholder="Please input the value for Per transaction Gift Limit"
                                          id="per-transaction-gift-limit"
                                          disabled
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={
                                            this.state.dailyGiftLimitMaxDefault
                                          }
                                          placeholder="Please input the value for Per transaction Gift Limit"
                                          id="per-transaction-gift-limit"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={this.state.dailyGiftLimitMin}
                                          placeholder="Please input the value for Per transaction Gift Limit"
                                          id="per-transaction-gift-limit"
                                          onChange={
                                            this.handleDailyGiftLimitMinChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .dailyGiftLimitMinChange ===
                                              true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={this.state.dailyGiftLimitMax}
                                          placeholder="Please input the value for Per transaction Gift Limit"
                                          id="per-transaction-gift-limit"
                                          onChange={
                                            this.handleDailyGiftLimitMaxChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .dailyGiftLimitMaxChange ===
                                              true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="per-transaction-gift-limit"
                                  className="col-md-3 col-form-label"
                                >
                                  Bkash Instant Disbursement Limit (in BDT)
                                </Label>
                                <Col md={9} className="d-flex gap-4">
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={
                                            this.state
                                              .bkashMinDisbursementDefault
                                          }
                                          placeholder="Please input the value for Per transaction Gift Limit"
                                          id="per-transaction-gift-limit"
                                          disabled
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={
                                            this.state
                                              .bkashMaxDisbursementDefault
                                          }
                                          placeholder="Please input the value for Per transaction Gift Limit"
                                          id="per-transaction-gift-limit"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Min:</p>
                                        <Input
                                          value={
                                            this.state.bkashMinDisbursement
                                          }
                                          placeholder="Please input the value for Per transaction Gift Limit"
                                          id="per-transaction-gift-limit"
                                          onChange={
                                            this
                                              .handleBkashMinDisbursementChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .bkashMaxDisbursementChange ===
                                              true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <p>Max:</p>
                                        <Input
                                          value={
                                            this.state.bkashMaxDisbursement
                                          }
                                          placeholder="Please input the value for Per transaction Gift Limit"
                                          id="per-transaction-gift-limit"
                                          onChange={
                                            this
                                              .handleBkashMaxDisbursementChange
                                          }
                                          style={{
                                            backgroundColor:
                                              this.state
                                                .bkashMaxDisbursementChange ===
                                              true
                                                ? '#D3D3D3'
                                                : '',
                                          }}
                                          disabled={
                                            (this.state.canAuthInputOps === 1 ||
                                              this.state.canInsertInputOps ===
                                                1) &&
                                            this.state.lastRequestedData ===
                                              null
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="morning-cut-off"
                                  className="col-md-3 col-form-label"
                                >
                                  Bkash Instant Disbursement
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Col md={6}>
                                    <Switch
                                      checked={
                                        this.state
                                          .bkashDisbursementEnabledDefault
                                      }
                                      inputProps={{
                                        'aria-label': 'controlled',
                                      }}
                                      size=""
                                      disabled
                                    />
                                  </Col>
                                  <Col md={6}>
                                    <Switch
                                      checked={
                                        this.state.bkashDisbursementEnabled
                                      }
                                      onChange={
                                        this
                                          .handleBkashDisbursementEnabledChange
                                      }
                                      inputProps={{
                                        'aria-label': 'controlled',
                                      }}
                                      classes={styles.root}
                                      disabled={
                                        (this.state.canAuthInputOps === 1 ||
                                          this.state.canInsertInputOps === 1) &&
                                        this.state.lastRequestedData === null
                                          ? false
                                          : true
                                      }
                                    />
                                  </Col>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="morning-cut-off"
                                  className="col-md-3 col-form-label"
                                >
                                  Morning Cut Off
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    type="time"
                                    value={this.state.morningCutOffDefault}
                                    id="morning-cut-off"
                                    disabled
                                  />
                                  <Input
                                    type="time"
                                    value={this.state.morningCutOff}
                                    id="morning-cut-off"
                                    onChange={this.handleMorningCutOffChange}
                                    style={{
                                      backgroundColor:
                                        this.state.morningCutOffChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="evening-cut-off"
                                  className="col-md-3 col-form-label"
                                >
                                  Evening Cut Off
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    type="time"
                                    value={this.state.eveningCutOffDefault}
                                    id="evening-cut-off"
                                    disabled
                                  />
                                  <Input
                                    type="time"
                                    value={this.state.eveningCutOff}
                                    id="evening-cut-off"
                                    onChange={this.handleEveningCutOffChange}
                                    style={{
                                      backgroundColor:
                                        this.state.eveningCutOffChange === true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>

                              {/* successful submit sweet alert */}
                              <Row
                                className="d-flex justify-content-start"
                                style={{
                                  fontSize: '20px',
                                  color: 'darkorange',
                                }}
                              >
                                {this.state.custom_div1 ? (
                                  <SweetAlert
                                    title="Request successfully submitted for review!!"
                                    timeout={2500}
                                    style={{
                                      top: '0px',
                                      right: '0px',
                                    }}
                                    showCloseButton={false}
                                    showConfirm={false}
                                    success
                                    onConfirm={() =>
                                      this.setState({ custom_div1: false })
                                    }
                                  ></SweetAlert>
                                ) : null}
                              </Row>
                              {/* successful submit sweet alert */}

                              <Row className="py-2 px-2">
                                <h5 className="py-2">Delivery Charges</h5>
                                <table
                                  className="table border border-1 border-dark"
                                  style={{ borderRadius: '5px' }}
                                >
                                  <thead className="border border-1 border-dark">
                                    <tr style={{ backgroundColor: '#F8F9FA' }}>
                                      <th
                                        scope="col"
                                        className="border border-1 border-dark"
                                      >
                                        SKU
                                      </th>
                                      <th scope="col" colSpan={2}>
                                        Details
                                      </th>
                                      <th
                                        scope="col"
                                        colSpan={4}
                                        className=" text-center border border-1 border-dark"
                                      >
                                        <tr className="d-flex justify-content-center">
                                          <th
                                            scope="col"
                                            className="px-2 text-center"
                                          >
                                            In Dhaka
                                          </th>
                                        </tr>
                                        <tr className="d-flex justify-content-between">
                                          <th
                                            scope="col"
                                            className="px-2 text-center"
                                          >
                                            Present value{' '}
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-2 text-center"
                                          >
                                            Proposed value
                                          </th>
                                        </tr>
                                      </th>
                                      <th
                                        scope="col"
                                        colSpan={4}
                                        className="px-2 text-center"
                                      >
                                        <tr className="d-flex justify-content-center">
                                          <th
                                            scope="col"
                                            className="px-2 text-center"
                                          >
                                            Out Dhaka
                                          </th>
                                        </tr>
                                        <tr className="d-flex justify-content-between">
                                          <th
                                            scope="col"
                                            className="px-2 text-center"
                                          >
                                            Present value{' '}
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-2 text-center"
                                          >
                                            Proposed value
                                          </th>
                                        </tr>
                                      </th>
                                    </tr>
                                  </thead>
                                  {this.state.deliveryChargesDefault.map(
                                    (dcf, index) => (
                                      <tbody>
                                        <tr
                                          style={{
                                            backgroundColor: '#F8F9FA',
                                          }}
                                        >
                                          <td className="border border-1 border-dark">
                                            {dcf.code}
                                          </td>
                                          <td colSpan={2}>
                                            {/* {this.state..details} */}
                                            {this.state.skuCodes[dcf.code]} gm
                                          </td>
                                          <td
                                            colSpan={4}
                                            className="px-2 text-center border border-1 border-dark"
                                          >
                                            <tr className="d-flex justify-content-between">
                                              <td className="px-2 text-center">
                                                <Input
                                                  type="text"
                                                  value={
                                                    dcf.indhaka === null
                                                      ? 0
                                                      : dcf.indhaka
                                                  }
                                                  id="preset-value-in-dhaka"
                                                  disabled
                                                />
                                              </td>
                                              <td className="px-2 text-center">
                                                <Input
                                                  type="text"
                                                  defaultValue={
                                                    this.state.deliveryCharges[
                                                      index
                                                    ].indhaka === null
                                                      ? 0
                                                      : this.state
                                                          .deliveryCharges[
                                                          index
                                                        ].indhaka
                                                  }
                                                  id="proposed-value-in-dhaka"
                                                  onChange={(event) => {
                                                    if (
                                                      this.state
                                                        .newDeliveryCharges
                                                        .length === 0
                                                    ) {
                                                      this.setState({
                                                        newDeliveryCharges: [
                                                          {
                                                            code: dcf.code,
                                                            indhaka: parseInt(
                                                              event.target.value
                                                            ),
                                                            outdhaka: this.state
                                                              .newDeliveryCharges[
                                                              index
                                                            ]?.outdhaka
                                                              ? this.state
                                                                  .newDeliveryCharges[
                                                                  index
                                                                ].outdhaka
                                                              : dcf.outdhaka,
                                                          },
                                                        ],
                                                      });
                                                    } else {
                                                      const modifiedItem =
                                                        this.state.newDeliveryCharges.find(
                                                          (item) =>
                                                            item.code ===
                                                            dcf.code
                                                        );
                                                      if (modifiedItem) {
                                                        modifiedItem.indhaka =
                                                          parseInt(
                                                            event.target.value
                                                          );
                                                        modifiedItem.outdhaka
                                                          ? (modifiedItem.outdhaka =
                                                              // eslint-disable-next-line
                                                              modifiedItem.outdhaka)
                                                          : (modifiedItem.outdhaka =
                                                              dcf.outdhaka);
                                                        const otherItems =
                                                          this.state.newDeliveryCharges.filter(
                                                            (item) =>
                                                              item.code !==
                                                              dcf.code
                                                          );
                                                        this.setState({
                                                          newDeliveryCharges: [
                                                            ...otherItems,
                                                            modifiedItem,
                                                          ],
                                                        });
                                                      } else {
                                                        this.setState({
                                                          newDeliveryCharges: [
                                                            ...this.state
                                                              .newDeliveryCharges,
                                                            {
                                                              code: dcf.code,
                                                              indhaka: parseInt(
                                                                event.target
                                                                  .value
                                                              ),
                                                              outdhaka:
                                                                dcf.outdhaka,
                                                            },
                                                          ],
                                                        });
                                                      }
                                                    }
                                                  }}
                                                  disabled={
                                                    (this.state
                                                      .canAuthInputOps === 1 ||
                                                      this.state
                                                        .canInsertInputOps ===
                                                        1) &&
                                                    this.state
                                                      .lastRequestedData ===
                                                      null
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </td>
                                          <td
                                            colSpan={4}
                                            className="px-2 text-center"
                                          >
                                            <tr className="d-flex justify-content-between">
                                              <td className="px-2 text-center">
                                                <Input
                                                  type="text"
                                                  value={
                                                    dcf.outdhaka === null
                                                      ? 0
                                                      : dcf.outdhaka
                                                  }
                                                  id="preset-value-out-dhaka"
                                                  disabled
                                                />
                                              </td>
                                              <td className="px-2 text-center">
                                                <Input
                                                  type="text"
                                                  defaultValue={
                                                    this.state.deliveryCharges[
                                                      index
                                                    ].outdhaka === null
                                                      ? 0
                                                      : this.state
                                                          .deliveryCharges[
                                                          index
                                                        ].outdhaka
                                                  }
                                                  id="proposed-value-out-dhaka"
                                                  onChange={(event) => {
                                                    if (
                                                      this.state
                                                        .newDeliveryCharges
                                                        .length === 0
                                                    ) {
                                                      this.setState({
                                                        newDeliveryCharges: [
                                                          {
                                                            code: dcf.code,
                                                            outdhaka: parseInt(
                                                              event.target.value
                                                            ),
                                                            indhaka: this.state
                                                              .newDeliveryCharges[
                                                              index
                                                            ]?.indhaka
                                                              ? this.state
                                                                  .newDeliveryCharges[
                                                                  index
                                                                ].indhaka
                                                              : dcf.indhaka,
                                                          },
                                                        ],
                                                      });
                                                    } else {
                                                      const modifiedItem =
                                                        this.state.newDeliveryCharges.find(
                                                          (item) =>
                                                            item.code ===
                                                            dcf.code
                                                        );
                                                      if (modifiedItem) {
                                                        modifiedItem.outdhaka =
                                                          parseInt(
                                                            event.target.value
                                                          );
                                                        modifiedItem.indhaka
                                                          ? (modifiedItem.indhaka =
                                                              // eslint-disable-next-line
                                                              modifiedItem.indhaka)
                                                          : (modifiedItem.indhaka =
                                                              dcf.indhaka);
                                                        const otherItems =
                                                          this.state.newDeliveryCharges.filter(
                                                            (item) =>
                                                              item.code !==
                                                              dcf.code
                                                          );
                                                        this.setState({
                                                          newDeliveryCharges: [
                                                            ...otherItems,
                                                            modifiedItem,
                                                          ],
                                                        });
                                                      } else {
                                                        this.setState({
                                                          newDeliveryCharges: [
                                                            ...this.state
                                                              .newDeliveryCharges,
                                                            {
                                                              code: dcf.code,
                                                              outdhaka:
                                                                parseInt(
                                                                  event.target
                                                                    .value
                                                                ),
                                                              indhaka:
                                                                dcf.indhaka,
                                                            },
                                                          ],
                                                        });
                                                      }
                                                    }
                                                  }}
                                                  disabled={
                                                    (this.state
                                                      .canAuthInputOps === 1 ||
                                                      this.state
                                                        .canInsertInputOps ===
                                                        1) &&
                                                    this.state
                                                      .lastRequestedData ===
                                                      null
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )
                                  )}
                                </table>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="insideDhaka-pickup-charge"
                                  className="col-md-3 col-form-label"
                                >
                                  Inside Dhaka Pickup Charge (in BDT)
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    value={
                                      this.state.insideDhakaPickupChargeDefault
                                    }
                                    placeholder="Please input the value for Inside Dhaka Pickup Charge"
                                    id="insideDhaka-pickup-charge"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.insideDhakaPickupCharge}
                                    placeholder="Please input the value for Collection Service Charge"
                                    id="collection-service-charge"
                                    onChange={
                                      this.handleInsideDhakaPickupChargeChange
                                    }
                                    style={{
                                      backgroundColor:
                                        this.state
                                          .insideDhakaPickupChargeChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Label
                                  htmlFor="outsideDhaka-pickup-charge"
                                  className="col-md-3 col-form-label"
                                >
                                  Outside Dhaka Pickup Charge (in BDT)
                                </Label>
                                <Col md={9} className="d-flex gap-5">
                                  <Input
                                    value={
                                      this.state.outsideDhakaPickupChargeDefault
                                    }
                                    placeholder="Please input the value for Outside Dhaka Pickup Charge"
                                    id="outsideDhaka-pickup-charge"
                                    disabled
                                  />
                                  <Input
                                    value={this.state.outsideDhakaPickupCharge}
                                    placeholder="Please input the value for Collection Service Charge"
                                    id="collection-service-charge"
                                    onChange={
                                      this.handleOutsideDhakaPickupChargeChange
                                    }
                                    style={{
                                      backgroundColor:
                                        this.state
                                          .outsideDhakaPickupChargeChange ===
                                        true
                                          ? '#D3D3D3'
                                          : '',
                                    }}
                                    disabled={
                                      (this.state.canAuthInputOps === 1 ||
                                        this.state.canInsertInputOps === 1) &&
                                      this.state.lastRequestedData === null
                                        ? false
                                        : true
                                    }
                                  />
                                </Col>
                              </Row>

                              {/* submit based on component auth state, user auth state, auth state difference */}
                              <Row
                                className="d-flex justify-content-end mx-1"
                                id="foo"
                              >
                                {/* auth permission check for maker  */}
                                {this.state.inputOpsAuthStatForUser === 1 &&
                                this.state.lastRequestedData === null ? (
                                  <Button
                                    type="submit"
                                    style={{
                                      width: '35%',
                                    }}
                                  >
                                    Submit
                                  </Button>
                                ) : this.state.inputOpsAuthStatForUser === 1 &&
                                  this.state.lastRequestedData != null ? (
                                  <Button
                                    style={{
                                      width: '35%',
                                    }}
                                    disabled
                                  >
                                    You have already a pending request
                                  </Button>
                                ) : //  auth permission check for maker

                                // auth permission check for checker
                                this.state.inputOpsAuthStatForUser === 2 &&
                                  this.state.lastRequestedData != null &&
                                  authDiff === 1 ? (
                                  <div className="d-flex justify-content-between my-5">
                                    <Button
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({
                                          modal_standard4: true,
                                        });
                                      }}
                                    >
                                      Approve
                                    </Button>

                                    {/*
                                     * @desc modal for APPROVING request for the CHECKER STARTS here
                                     */}

                                    <Modal
                                      isOpen={this.state.modal_standard4}
                                      toggle={this.tog_standard4}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard4: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>

                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want Approve this
                                            request?
                                          </Row>
                                        </Col>
                                      </ModalBody>

                                      <ModalFooter>
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="waves-effect waves-light"
                                          onClick={async (event) => {
                                            event.preventDefault();
                                            const token = getToken();
                                            let submitData = {
                                              cmpId: 18,
                                              goldSettingsID:
                                                this.state.lastRequestedData
                                                  ?.goldSettingsID,
                                              settingsID:
                                                this.state.lastRequestedData
                                                  ?.settingsID,
                                              invQuotaID:
                                                this.state.lastRequestedData
                                                  ?.invQuotaID,
                                              deviceInfo: this.state.deviceInfo,
                                            };
                                            await GKApi.put(
                                              `finance/operation-input/approve`,
                                              submitData,
                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );
                                            const response = await GKApi.get(
                                              '/finance/operation-input',
                                              {
                                                headers: {
                                                  Authorization: token,
                                                  cmpId: 18,
                                                },
                                              }
                                            );

                                            this.setState({
                                              lastApprovedData:
                                                response.data.lastApproved,
                                              lastRequestedData:
                                                response.data.lastRequested,
                                              compAuthStat:
                                                response.data.lastRequested
                                                  .compAuthStat,
                                            });
                                            this.tog_standard4();
                                          }}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          type="button"
                                          onClick={this.tog_standard4}
                                          color="light"
                                          className="waves-effect"
                                        >
                                          No
                                        </Button>
                                      </ModalFooter>
                                    </Modal>

                                    <Button
                                      type="submit"
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({
                                          modal_standard5: true,
                                        });
                                      }}
                                    >
                                      Reject
                                    </Button>

                                    {/*
                                     * @desc modal for REJECTING request for the CHECKER STARTS here
                                     */}
                                    <Modal
                                      isOpen={this.state.modal_standard5}
                                      toggle={this.tog_standard5}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard5: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>

                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want to Reject this
                                            request?
                                          </Row>
                                          <Row className="mb-3 mx-2">
                                            <Input
                                              type="text"
                                              // oninput={validate(this)}
                                              value={this.state.reason}
                                              placeholder="Please specify the reason"
                                              id="gold-price-input"
                                              onChange={this.handleReasonChange}
                                            />
                                          </Row>
                                        </Col>
                                      </ModalBody>

                                      {this.state.reason === '' ? (
                                        ''
                                      ) : (
                                        <ModalFooter>
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="waves-effect waves-light"
                                            onClick={async (event) => {
                                              event.preventDefault();

                                              const token = getToken();
                                              let submitData = {
                                                cmpId: 18,
                                                goldSettingsID:
                                                  this.state.lastRequestedData
                                                    ?.goldSettingsID,
                                                settingsID:
                                                  this.state.lastRequestedData
                                                    ?.settingsID,
                                                invQuotaID:
                                                  this.state.lastRequestedData
                                                    ?.invQuotaID,
                                                deviceInfo:
                                                  this.state.deviceInfo,
                                                reason: this.state.reason,
                                              };
                                              await GKApi.put(
                                                `finance/operation-input/reject`,
                                                submitData,
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                  },
                                                }
                                              );
                                              const response = await GKApi.get(
                                                '/finance/operation-input',
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                    cmpId: 18,
                                                  },
                                                }
                                              );

                                              this.setState({
                                                lastApprovedData:
                                                  response.data.lastApproved,
                                                lastRequestedData:
                                                  response.data.lastRequested,
                                                // compAuthStat:
                                                //   response.data.lastRequested
                                                //     .compAuthStat,
                                              });
                                              this.tog_standard5();
                                            }}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            type="button"
                                            onClick={this.tog_standard5}
                                            color="light"
                                            className="waves-effect"
                                          >
                                            No
                                          </Button>
                                        </ModalFooter>
                                      )}
                                    </Modal>
                                  </div>
                                ) : this.state.inputOpsAuthStatForUser === 2 &&
                                  authDiff === 0 ? (
                                  <h4 style={{ textAlign: 'center' }}>
                                    The request has been reviewed by Checker
                                  </h4>
                                ) : // auth permission check for checker

                                // auth permission check for authorizer
                                this.state.inputOpsAuthStatForUser === 3 &&
                                  this.state.lastRequestedData !== null &&
                                  (this.state.lastRequestedData
                                    ?.entryStaffID !== loggedUser ||
                                    this.state.lastRequestedData
                                      ?.lastAuthorizerID !== loggedUser) ? (
                                  <div className="d-flex justify-content-between my-5">
                                    <Button
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({
                                          modal_standard4: true,
                                        });
                                      }}
                                    >
                                      Authorize
                                    </Button>
                                    {/*
                                     * @desc modal for AUTHORIZING request for the AUTHORIZER STARTS here
                                     */}
                                    <Modal
                                      isOpen={this.state.modal_standard4}
                                      toggle={this.tog_standard4}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard4: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>

                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want Authorize this
                                            request?
                                          </Row>
                                        </Col>
                                      </ModalBody>

                                      <ModalFooter>
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="waves-effect waves-light"
                                          onClick={async (event) => {
                                            event.preventDefault();
                                            const token = getToken();
                                            let submitData = {
                                              cmpId: 18,
                                              goldSettingsID:
                                                this.state.lastRequestedData
                                                  ?.goldSettingsID,
                                              settingsID:
                                                this.state.lastRequestedData
                                                  ?.settingsID,
                                              invQuotaID:
                                                this.state.lastRequestedData
                                                  ?.invQuotaID,
                                              deviceInfo: this.state.deviceInfo,
                                            };
                                            await GKApi.put(
                                              `finance/operation-input/approve`,
                                              submitData,
                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );
                                            const response = await GKApi.get(
                                              '/finance/operation-input',
                                              {
                                                headers: {
                                                  Authorization: token,
                                                  cmpId: 18,
                                                },
                                              }
                                            );

                                            this.setState({
                                              lastApprovedData:
                                                response.data.lastApproved,
                                              lastRequestedData:
                                                response.data.lastRequested,
                                            });
                                            this.tog_standard4();
                                          }}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          type="button"
                                          onClick={this.tog_standard4}
                                          color="light"
                                          className="waves-effect"
                                        >
                                          No
                                        </Button>
                                      </ModalFooter>
                                    </Modal>

                                    <Button
                                      type="submit"
                                      style={{
                                        width: '20%',
                                      }}
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState({
                                          modal_standard5: true,
                                        });
                                      }}
                                    >
                                      Reject
                                    </Button>
                                    {/*
                                     * @desc modal for REJECTING request for the AUTHORIZER STARTS here
                                     */}
                                    <Modal
                                      isOpen={this.state.modal_standard5}
                                      toggle={this.tog_standard5}
                                    >
                                      <ModalHeader
                                        toggle={() => {
                                          this.setState({
                                            modal_standard5: false,
                                          });
                                        }}
                                      >
                                        Confirmation
                                      </ModalHeader>

                                      <ModalBody>
                                        <Col xl={12}>
                                          <Row className="mb-3 mx-2">
                                            Are you sure you want to Reject this
                                            request?
                                          </Row>
                                          <Row className="mb-3 mx-2">
                                            <Input
                                              type="text"
                                              // oninput={validate(this)}
                                              value={this.state.reason}
                                              placeholder="Please specify the reason"
                                              id="gold-price-input"
                                              onChange={this.handleReasonChange}
                                            />
                                          </Row>
                                        </Col>
                                      </ModalBody>

                                      {this.state.reason === '' ? (
                                        ''
                                      ) : (
                                        <ModalFooter>
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="waves-effect waves-light"
                                            onClick={async (event) => {
                                              event.preventDefault();

                                              const token = getToken();
                                              let submitData = {
                                                cmpId: 18,
                                                goldSettingsID:
                                                  this.state.lastRequestedData
                                                    ?.goldSettingsID,
                                                settingsID:
                                                  this.state.lastRequestedData
                                                    ?.settingsID,
                                                invQuotaID:
                                                  this.state.lastRequestedData
                                                    ?.invQuotaID,
                                                deviceInfo:
                                                  this.state.deviceInfo,
                                                reason: this.state.reason,
                                              };
                                              await GKApi.put(
                                                `finance/operation-input/reject`,
                                                submitData,
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                  },
                                                }
                                              );
                                              const response = await GKApi.get(
                                                '/finance/operation-input',
                                                {
                                                  headers: {
                                                    Authorization: token,
                                                    cmpId: 18,
                                                  },
                                                }
                                              );

                                              this.setState({
                                                lastApprovedData:
                                                  response.data.lastApproved,
                                                lastRequestedData:
                                                  response.data.lastRequested,
                                              });
                                              this.tog_standard5();
                                            }}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            type="button"
                                            onClick={this.tog_standard5}
                                            color="light"
                                            className="waves-effect"
                                          >
                                            No
                                          </Button>
                                        </ModalFooter>
                                      )}
                                    </Modal>
                                  </div>
                                ) : this.state.inputOpsAuthStatForUser === 3 &&
                                  this.state.lastRequestedData === null ? (
                                  <Button
                                    type="submit"
                                    style={{
                                      width: '35%',
                                    }}
                                  >
                                    Submit
                                  </Button>
                                ) : this.state.lastRequestedData
                                    ?.entryStaffID === loggedUser ||
                                  this.state.lastRequestedData
                                    ?.lastAuthorizerID === loggedUser ? (
                                  <h4>
                                    You have already authorized this request
                                  </h4>
                                ) : (
                                  ''
                                )}
                                <Tooltip
                                  target="foo"
                                  isOpen={this.state.tooltipOpen}
                                  toggle={this.toggle}
                                >
                                  Be careful!!! Its going to update the database
                                  directly
                                </Tooltip>
                              </Row>
                              {/* submit based on component auth state, user auth state, auth state difference */}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                ) : (
                  <></>
                )}
              </Col>
            </Container>
          ) : (
            <h5>Sorry you don't have permission to view this.</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(FinanceOpsInputs);

import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import * as XLSX from 'xlsx';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Input,
  Button,
  FormGroup,
  Form,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import SweetAlert from 'react-bootstrap-sweetalert';
import {
  capitalizeFirstLetter,
  getGroupName,
  getToken,
  getUser,
  GKApi,
} from '../../../components/config';
import { useSelector } from 'react-redux';
import { deviceInfo } from '../../../helpers/device_info_helper';

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const name = useSelector((state) => state.Login.user.user?.name);
    const email = useSelector((state) => state.Login.user.user?.email);
    const designation = useSelector(
      (state) => state.Login.user.user?.designation
    );
    const dob = useSelector((state) => state.Login.user.user?.dob);
    const phone = useSelector((state) => state.Login.user.user?.phone);
    const groupId = useSelector((state) => state.Login.user.user?.groupId);
    return (
      <Component
        {...props}
        name={name}
        email={email}
        designation={designation}
        dob={dob}
        phone={phone}
        groupId={groupId}
      />
    );
  };
}
class Disputes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Tables', link: '#' },
        { title: 'Dispute list', link: '#' },
      ],
      reqType: 'pending',
      reqMenu: false,
      disputeData: [],
      fullName: '',
      email: '',
      phoneNumber: '',
      role: null,
      visible: false,
      modal_standard: false,
      modal_standard2: false,
      modal_standard3: false,
      modal_standard4: false,
      custom_div1: false,
      custom_div2: false,
      disputeIds: [],
      deviceInfo: {},
      ip: '',
      reason: '',
      isSuper: false,
      isFin: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_standard2 = this.tog_standard2.bind(this);
    this.tog_standard3 = this.tog_standard3.bind(this);
    this.tog_standard4 = this.tog_standard4.bind(this);
  }

  //change handlers

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_standard2() {
    this.setState((prevState) => ({
      modal_standard2: !prevState.modal_standard2,
    }));
    this.removeBodyCss();
  }

  tog_standard3() {
    this.setState((prevState) => ({
      modal_standard3: !prevState.modal_standard3,
    }));
    this.removeBodyCss();
  }

  tog_standard4() {
    this.setState((prevState) => ({
      modal_standard4: !prevState.modal_standard4,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  handleReasonChange = (event) => {
    this.setState({ ...this.state, reason: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  // get call for dispute disbursement list
  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    // GET request using axios with async/await
    const token = getToken();
    // const gId = getGroupId();

    const response = await GKApi.get(
      `finance/dispute?pageSize=1000&pageNumber=1&type=${this.state.reqType}`,
      {
        headers: {
          Authorization: token,
          cmpId: 20,
        },
      }
    );

    this.setState({ disputeData: response.data.data });
    const res = deviceInfo();
    this.setState({ deviceInfo: res });

    // getting permission data from api
    const getPermission = await GKApi.get(`/role/permission?cmpId=20`, {
      headers: {
        Authorization: token,
      },
    });

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    const disputeDataTemp = [];

    //adding auth difference attribute to the dispute list data
    response.data.data.forEach((item) => {
      item['authDif'] = permissionData[20].authState - item.authStat;
      disputeDataTemp.push(item);
    });

    this.setState(
      {
        disputeData: disputeDataTemp,
      },
      () => {}
    );

  }

  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();
    };
    const columns = [
      {
        field: 'custName',
        title: 'Customer Name',
      },
      {
        field: 'custPhone',
        title: 'Customer Phone Number',
      },
      {
        field: 'goldAmount',
        title: 'Gold Amount',
        render: (row) => <div>{row.goldAmount.toFixedDown(4)}</div>,
      },
      {
        field: 'dateTime',
        title: 'Date',
        render: (row) => (
          <div>{row.dateTime ? row.dateTime : 'Not Available'}</div>
        ),
      },
      {
        field: 'trxType',
        title: 'Transaction type',
        render: (row) => (
          <div>{row.trxType != null ? row.trxType : 'Not Available'}</div>
        ),
      },
      {
        field: 'trxRef',
        title: 'Transaction Reference',
      },
      {
        field: 'marketPricePerGM',
        title: 'Market Price per GM',
        render: (row) => (
          <div>
            {row.marketPricePerGM != null
              ? row.marketPricePerGM
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'reason',
        title: 'Reason',
        render: (row) => (
          <div>{row.reason != null ? row.reason : 'Not Available'}</div>
        ),
      },
      {
        field: 'inboxTitle',
        title: 'Inbox Title',
      },
      {
        field: 'inboxBody',
        title: 'Inbox Body',
      },

      {
        field: 'status',
        title: 'Status',
      },
    ];

    const downloadExcel = () => {
      const newData = this.state.disputeData.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Disputes');
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(workBook, 'Finance Disputes.xlsx');
    };

    const loggedUser = getUser();

    return (
      <React.Fragment>
        <div className="page-content" id="20">
          {this.state.isSuper === true || this.state.isFin === true ? (
            <div className="container-fluid">
              <Breadcrumbs
                title="Special Disbursement Table"
                breadcrumbItems={this.state.breadcrumbItems}
              />
              <MaterialTable
                title="List of Special Disbursement"
                columns={columns}
                components={{
                  Toolbar: (props) => (
                    <div>
                      <MTableToolbar {...props} />
                      <div className="d-flex gap-3 align-items-center mx-3">
                        <h5>Select Request type:</h5>
                        <Dropdown
                          isOpen={this.state.reqMenu}
                          toggle={(event) =>
                            this.setState({
                              reqMenu: !this.state.reqMenu,
                            })
                          }
                        >
                          <DropdownToggle className="px-5 py-2" color="light">
                            {capitalizeFirstLetter(this.state.reqType)}{' '}
                            <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={async (event) => {
                                this.setState({ reqType: 'pending' });

                                const token = getToken();
                                const response = await GKApi.get(
                                  `finance/dispute?pageSize=1000&pageNumber=1&type=pending`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 20,
                                    },
                                  }
                                );
                                const getPermission = await GKApi.get(
                                  `/role/permission?cmpId=20`,
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );

                                const permissionData = {};

                                // mapping permission to object instead of array
                                getPermission.data.map(
                                  (item) => (permissionData[item.cmpId] = item)
                                );
                                const disputeDataTemp = [];
                                response.data.data.forEach((item) => {
                                  item['authDif'] =
                                    permissionData[20].authState -
                                    item.authStat;
                                  disputeDataTemp.push(item);
                                });

                                this.setState(
                                  {
                                    disputeData: disputeDataTemp,
                                  },
                                  () => {}
                                );
                              }}
                            >
                              Pending
                            </DropdownItem>
                            <DropdownItem
                              onClick={async (event) => {
                                this.setState({ reqType: 'approved' });

                                const token = getToken();
                                const response = await GKApi.get(
                                  `finance/dispute?pageSize=1000&pageNumber=1&type=approved`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 20,
                                    },
                                  }
                                );
                                const getPermission = await GKApi.get(
                                  `/role/permission?cmpId=20`,
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );

                                const permissionData = {};

                                // mapping permission to object instead of array
                                getPermission.data.map(
                                  (item) => (permissionData[item.cmpId] = item)
                                );
                                const disputeDataTemp = [];
                                response.data.data.forEach((item) => {
                                  item['authDif'] =
                                    permissionData[20].authState -
                                    item.authStat;
                                  disputeDataTemp.push(item);
                                });

                                this.setState(
                                  {
                                    disputeData: disputeDataTemp,
                                  },
                                  () => {}
                                );
                              }}
                            >
                              Approved
                            </DropdownItem>

                            <DropdownItem
                              onClick={async (event) => {
                                this.setState({ reqType: 'rejected' });

                                const token = getToken();
                                const response = await GKApi.get(
                                  `finance/dispute?pageSize=1000&pageNumber=1&type=rejected`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 20,
                                    },
                                  }
                                );
                                const getPermission = await GKApi.get(
                                  `/role/permission?cmpId=20`,
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );

                                const permissionData = {};

                                // mapping permission to object instead of array
                                getPermission.data.map(
                                  (item) => (permissionData[item.cmpId] = item)
                                );
                                const disputeDataTemp = [];
                                response.data.data.forEach((item) => {
                                  item['authDif'] =
                                    permissionData[20].authState -
                                    item.authStat;
                                  disputeDataTemp.push(item);
                                });

                                this.setState(
                                  {
                                    disputeData: disputeDataTemp,
                                  },
                                  () => {}
                                );
                              }}
                            >
                              Rejected
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <div className="px-3 my-4 d-flex gap-3">
                        <div>
                          <i
                            className="mdi mdi-circle  font-size-10 me-1"
                            style={{ color: '#00FFFF' }}
                          ></i>{' '}
                          <span>Ready for checker to review.</span>
                        </div>
                        <div>
                          <i
                            className="mdi mdi-circle  font-size-10 me-1"
                            style={{ color: '#F5F5DC' }}
                          ></i>{' '}
                          <span>Ready for First Authorizer to review.</span>
                        </div>
                        <div>
                          <i
                            className="mdi mdi-circle  font-size-10 me-1"
                            style={{ color: '#9ACD32' }}
                          ></i>{' '}
                          <span>Ready for Second Authorizer to review.</span>
                        </div>
                      </div>
                    </div>
                  ),
                }}
                data={this.state.disputeData}
                actions={[
                  {
                    icon: () => <Button>Export All</Button>, // you can pass icon too
                    tooltip: 'Export to Excel',
                    onClick: () => downloadExcel(),
                    isFreeAction: true,
                  },
                  this.state.reqType === 'pending' && {
                    icon: () => <Button>Proceed with Disbursement</Button>, // you can pass icon too
                    tooltip: 'Disburse',
                    onClick: (event, data) => {
                      this.setState({ modal_standard3: true });
                      const selectedIds = [];
                      for (const obj of data) {
                        selectedIds.push(obj.id);
                      }
                      this.setState({ disputeIds: selectedIds });
                    },
                  },
                  this.state.reqType === 'pending' && {
                    icon: () => <Button>Reject Disbursement Request</Button>, // you can pass icon too
                    tooltip: 'Reject Disbursement request',
                    onClick: (event, data) => {
                      this.setState({ modal_standard4: true });
                      const selectedIds = [];
                      for (const obj of data) {
                        selectedIds.push(obj.id);
                      }
                      this.setState({ disputeIds: selectedIds });
                    },
                  },
                ]}
                options={{
                 

                  //style for the header

                  headerStyle: {
                    whiteSpace: 'nowrap',

                    textAlign: 'left',
                    top: 0,
                  },
                  cellStyle: {
                    borderRight: '1px solid #eee',
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                  },
                  selection: this.state.reqType === 'pending' && true,
                  selectionProps: (rowData) => ({
                    disabled:
                      rowData.authDif !== 1 &&
                      (rowData.entryStaffID === loggedUser ||
                        rowData.lastAuthorizerID === loggedUser),
                  }),
                  showSelectAllCheckbox: false,
                  actionsColumnIndex: -1,
                  exportButton: { csv: true, pdf: false },
                  exportFileName: 'Finance Disputes',
                  pageSize: 20,
                  columnsButton: true,
                  rowStyle: (rowData) =>
                    this.state.reqType === 'pending' && {
                      backgroundColor:
                        rowData.authStat === 1
                          ? 'cyan'
                          : rowData.authStat === 2
                          ? 'beige'
                          : 'yellowgreen',
                    },
                }}
                localization={{
                  toolbar: {
                    exportCSVName: 'Export as CSV',
                  },
                }}
              />

              <Modal
                isOpen={this.state.modal_standard3}
                toggle={this.tog_standard3}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard3: false })}
                >
                  Proceed with disbursement of Dispute request
                </ModalHeader>

                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <ModalBody>
                      {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                      Please confirm to{' '}
                      {this.props.groupId === 2 ? 'approve' : 'authorize'}{' '}
                      disbursement request
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        type="button"
                        onClick={this.tog_standard3}
                        color="light"
                        className="waves-effect"
                      >
                        Cancel
                      </Button>

                      <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light"
                        //submit dispute data for disbursement
                        onClick={async (event) => {
                          event.preventDefault();
                          const token = getToken();
                          // const user = getUser();
                          const submit = await GKApi.put(
                            `/finance/dispute/approve`,
                            {
                              cmpId: 20,
                              deviceInfo: this.state.deviceInfo,
                              disputeIDs: this.state.disputeIds,
                            },
                            {
                              headers: {
                                Authorization: token,
                              },
                            }
                          );
                          this.tog_standard3();

                          // refetching api data after submit
                          if (submit.status === 200) {
                            const response = await GKApi.get(
                              `finance/dispute?pageSize=1000&pageNumber=1&type=pending`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 20,
                                },
                              }
                            );
                            this.setState({
                              disputeData: response.data.data,
                              // custom_div1: true,
                            });
                          }
                        }}
                      >
                        {this.props.groupId === 2 ? 'Approve' : 'Authorize'}
                      </Button>
                      {this.state.custom_div1 === true ? (
                        <SweetAlert
                          title="Request successfully approved for authorization!!"
                          timeout={2500}
                          style={{
                            top: '0px',
                            right: '0px',
                          }}
                          showCloseButton={false}
                          showConfirm={false}
                          success
                          onConfirm={() =>
                            this.setState({ custom_div1: false })
                          }
                        ></SweetAlert>
                      ) : null}
                    </ModalFooter>
                  </FormGroup>
                </Form>
              </Modal>
              <Modal
                isOpen={this.state.modal_standard4}
                toggle={this.tog_standard4}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard4: false })}
                >
                  Proceed with rejection of disbursement request
                </ModalHeader>

                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <ModalBody>
                      {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                      Please state the reason for rejection of dispute request
                      <Row className="mb-3 mx-2">
                        <Input
                          type="text"
                          // oninput={validate(this)}
                          value={this.state.reason}
                          placeholder="Please specify the reason"
                          id="gold-price-input"
                          onChange={this.handleReasonChange}
                        />
                      </Row>
                    </ModalBody>

                    {this.state.reason !== '' && (
                      <ModalFooter>
                        <Button
                          type="button"
                          onClick={this.tog_standard4}
                          color="light"
                          className="waves-effect"
                        >
                          Cancel
                        </Button>

                        <Button
                          type="submit"
                          color="primary"
                          className="waves-effect waves-light"
                          //submit dispute data for disbursement
                          onClick={async (event) => {
                            event.preventDefault();
                            const token = getToken();
                            // const user = getUser();
                            const submit = await GKApi.put(
                              `/finance/dispute/reject`,
                              {
                                reason: this.state.reason,
                                cmpId: 20,
                                deviceInfo: this.state.deviceInfo,
                                disputeIDs: this.state.disputeIds,
                              },
                              {
                                headers: {
                                  Authorization: token,
                                },
                              }
                            );
                            this.tog_standard4();

                            // refetching api data after submit
                            if (submit.status === 200) {
                              const response = await GKApi.get(
                                `finance/dispute?pageSize=1000&pageNumber=1&type=pending`,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 20,
                                  },
                                }
                              );
                              this.setState({
                                disputeData: response.data.data,
                                // custom_div2: true,
                              });
                            }
                          }}
                        >
                          Reject
                        </Button>
                        {this.state.custom_div2 ? (
                          <SweetAlert
                            title="Request rejected!!"
                            timeout={2500}
                            style={{
                              top: '0px',
                              right: '0px',
                            }}
                            showCloseButton={false}
                            showConfirm={false}
                            success
                            onConfirm={() =>
                              this.setState({ custom_div2: false })
                            }
                          ></SweetAlert>
                        ) : null}
                      </ModalFooter>
                    )}
                  </FormGroup>
                </Form>
              </Modal>
            </div>
          ) : (
            <h5>Sorry you don't have permission to view this</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(Disputes);

import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
// import { Edit, Check } from '@material-ui/icons';
import {
  capitalizeFirstLetter,
  getGroupName,
  getToken,
  GKApi,
} from '../../components/config';
import { Check, Clear } from '@material-ui/icons';
import moment from 'moment';
import { deviceInfo } from '../../helpers/device_info_helper';
//Import Breadcrumb
// import Breadcrumbs from '../../components/Common/Breadcrumb';
// import DatePickerComponent from '../../components/Common/DatePicker';


class CRMChangeRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Tables', link: '#' },
        { title: 'Admin User Table', link: '#' },
      ],
      reqType: 'profileVerify',
      reqMenu: false,
      profilePicCurrent: null,
      profilePicUpdated: null,
      nidFrontPicCurrent: null,
      nidFrontPicUpdated: null,
      nidBackPicCurrent: null,
      nidBackPicUpdated: null,
      profileReq: [],
      resetPassReq: [],
      activeStatusReq: [],

      visible: false,
      custom_div1: false,
      modal_standard: false,
      modal_standard2: false,
      modal_standard3: false,
      modal_standard4: false,
      modal_standard5: false,
      modal_standard6: false,
      modal_standard7: false,
      modal_standard8: false,
      modal_standard9: false,
      modal_standard10: false,
      modal_standard11: false,
      modal_standard12: false,
      modal_standard13: false,
      modal_standard14: false,

      key: null,
      modal: false,
      dynamic_title: '',
      dynamic_description: '',
      success_confirm: false,
      deviceInfo: {},
      reason: '',
      compAuthState: 1,
      isCrm: false,
      isSuper: false,
      canAuthCRMReq: 0,
    };
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_standard2 = this.tog_standard2.bind(this);
    this.tog_standard3 = this.tog_standard3.bind(this);
  }

  //modal closing options

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_standard2() {
    this.setState((prevState) => ({
      modal_standard2: !prevState.modal_standard2,
    }));
    this.removeBodyCss();
  }

  tog_standard3() {
    this.setState((prevState) => ({
      modal_standard3: !prevState.modal_standard3,
    }));
    this.removeBodyCss();
  }
  tog_standard4 = () => {
    this.setState((prevState) => ({
      modal_standard4: !prevState.modal_standard4,
    }));
    this.removeBodyCss();
  };
  tog_standard5 = () => {
    this.setState((prevState) => ({
      modal_standard5: !prevState.modal_standard5,
    }));
    this.removeBodyCss();
  };
  tog_standard6 = () => {
    this.setState((prevState) => ({
      modal_standard6: !prevState.modal_standard6,
    }));
    this.removeBodyCss();
  };
  tog_standard7 = () => {
    this.setState((prevState) => ({
      modal_standard7: !prevState.modal_standard7,
    }));
    this.removeBodyCss();
  };
  tog_standard8 = () => {
    this.setState((prevState) => ({
      modal_standard8: !prevState.modal_standard8,
    }));
    this.removeBodyCss();
  };
  tog_standard9 = () => {
    this.setState((prevState) => ({
      modal_standard9: !prevState.modal_standard9,
    }));
    this.removeBodyCss();
  };
  tog_standard10 = () => {
    this.setState((prevState) => ({
      modal_standard10: !prevState.modal_standard10,
    }));
    this.removeBodyCss();
  };

  tog_standard11 = () => {
    this.setState((prevState) => ({
      modal_standard11: !prevState.modal_standard11,
    }));
    this.removeBodyCss();
  };

  tog_standard12 = () => {
    this.setState((prevState) => ({
      modal_standard12: !prevState.modal_standard12,
    }));
    this.removeBodyCss();
  };

  tog_standard13 = () => {
    this.setState((prevState) => ({
      modal_standard13: !prevState.modal_standard13,
    }));
    this.removeBodyCss();
  };

  tog_standard14 = () => {
    this.setState((prevState) => ({
      modal_standard14: !prevState.modal_standard14,
    }));
    this.removeBodyCss();
  };
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  // change handlers

  handleNameChange = (event) => {
    this.setState({ ...this.state, name: event.target.value });
  };

  handleEmailChange = (event) => {
    this.setState({ ...this.state, email: event.target.value });
  };

  handlePhoneChange = (event) => {
    this.setState({ ...this.state, phone: event.target.value });
  };

  handleDobChange = (event) => {
    this.setState({ ...this.state, dob: event });
  };

  handleGenderChange = (event) => {
    this.setState({ ...this.state, gender: event.target.value });
  };

  handleDesignationChange = (event) => {
    this.setState({ ...this.state, designation: event.target.value });
  };
  handleNidChange = (event) => {
    this.setState({ ...this.state, nid: event.target.value });
  };

  handleRoleChange = (role) => {
    this.setState({ role });
  };

  handleReasonChange = (event) => {
    this.setState({ ...this.state, reason: event.target.value });
  };

  //fetch user list call

  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'CRM') {
        this.setState({
          isCrm: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    // GET request using axios with async/await
    const token = getToken();
    // const gId = getGroupId();
    // getting permission data from api
    const getPermission = await GKApi.get(`/role/permission?cmpId=101`, {
      headers: {
        Authorization: token,
      },
    });

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    // const requestDataTemp = [];

    this.setState({
      compAuthState: permissionData[101].authState,
      canAuthCRMReq: permissionData[101].canAuth,
    });

    const response = await GKApi.get(
      `/users/crm-change-requests/list?type=${this.state.reqType}&pageNumber=1&pageSize=1000`,
      {
        headers: {
          Authorization: token,
          cmpId: 101,
        },
      }
    );

  
    this.setState({ profileReq: response.data.data });
    const res = deviceInfo();
    this.setState({ deviceInfo: res });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
  };

  render() {
    //user request columns for profile request
    const profileColumns = [
      {
        field: 'name',
        title: 'Name',
      },
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'current',
        title: 'Current Status',
      },
      {
        field: 'updated',
        title: 'Updated Status',
      },
      {
        field: 'createTime',
        title: 'Request Time',
        render: (row) => (
          <div>
            {row.createTime
              ? moment(row.createTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
    ];

    //user request columns for bank information request
    const activeStatusColumns = [
      {
        field: 'name',
        title: 'Name',
      },
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'current',
        title: 'Current Status',
      },
      {
        field: 'updated',
        title: 'Updated Status',
      },
      {
        field: 'createTime',
        title: 'Request Time',
        render: (row) => (
          <div>
            {row.createTime
              ? moment(row.createTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
    ];

    //user request columns for bkash details request
    const resetPassColumns = [
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'name',
        title: 'Request From',
      },
      {
        field: 'createTime',
        title: 'Request Time',
        render: (row) => (
          <div>
            {row.createTime
              ? moment(row.createTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isCrm === true || this.state.isSuper === true ? (
            <div className="container-fluid">
              {/* <Breadcrumbs
              title="Admin User Table"
              breadcrumbItems={this.state.breadcrumbItems}
            /> */}
              <div id="47">
                <MaterialTable
                  title={
                    <div className="d-flex gap-5">
                      <h5>List of User Requests</h5>
                    </div>
                  }
                  columns={
                    this.state.reqType === 'profileVerify'
                      ? profileColumns
                      : this.state.reqType === 'activeStatus'
                      ? activeStatusColumns
                      : resetPassColumns
                  }
                  components={{
                    Toolbar: (props) => (
                      <div>
                        <MTableToolbar {...props} />
                        <div className="d-flex gap-3 align-items-center mx-3">
                          <h5>Select Request type:</h5>
                          <Dropdown
                            isOpen={this.state.reqMenu}
                            toggle={(event) =>
                              this.setState({
                                reqMenu: !this.state.reqMenu,
                              })
                            }
                          >
                            <DropdownToggle className="px-5 py-2" color="light">
                              {capitalizeFirstLetter(this.state.reqType)}{' '}
                              <i className="mdi mdi-chevron-down"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={async (event) => {
                                  this.setState({ reqType: 'profileVerify' });
                                  const token = getToken();
                                  const response = await GKApi.get(
                                    `/users/crm-change-requests/list?type=profileVerify&pageNumber=1&pageSize=1000`,
                                    {
                                      headers: {
                                        Authorization: token,
                                        cmpId: 101,
                                      },
                                    }
                                  );
                                  this.setState({
                                    profileReq: response.data.data,
                                  });
                                }}
                              >
                                Profile Verify
                              </DropdownItem>
                              <DropdownItem
                                onClick={async (event) => {
                                  this.setState({ reqType: 'activeStatus' });
                                  const token = getToken();
                                  const response = await GKApi.get(
                                    `/users/crm-change-requests/list?type=activeStatus&pageNumber=1&pageSize=1000`,
                                    {
                                      headers: {
                                        Authorization: token,
                                        cmpId: 101,
                                      },
                                    }
                                  );

                                  this.setState({
                                    activeStatusReq: response.data.data,
                                  });
                                }}
                              >
                                Active Status
                              </DropdownItem>

                              <DropdownItem
                                onClick={async (event) => {
                                  this.setState({ reqType: 'resetPass' });
                                  const token = getToken();
                                  const response = await GKApi.get(
                                    `/users/crm-change-requests/list?type=resetPass&pageNumber=1&pageSize=1000`,
                                    {
                                      headers: {
                                        Authorization: token,
                                        cmpId: 101,
                                      },
                                    }
                                  );
                                  this.setState({
                                    resetPassReq: response.data.data,
                                  });
                                }}
                              >
                                Reset Password
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    ),
                  }}
                  data={
                    this.state.reqType === 'profileVerify'
                      ? this.state.profileReq
                      : this.state.reqType === 'activeStatus'
                      ? this.state.activeStatusReq
                      : this.state.resetPassReq
                  }
                  actions={[
                    this.state.canAuthCRMReq === 1 && {
                      icon: Check,
                      tooltip: 'Click to view request data',
                      onClick: async (event, row) => {
                        this.setState({ modal_standard: true });
                        this.setState({ key: row });
                      },
                    },
                    this.state.canAuthCRMReq === 1 && {
                      icon: Clear,
                      tooltip: 'Click to view request data',
                      onClick: async (event, row) => {
                        this.setState({ modal_standard2: true });
                        this.setState({ key: row });
                      },
                    },
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'left',
                    },

                    //style for the header

                    headerStyle: {
                      whiteSpace: 'nowrap',

                      textAlign: 'left',
                      top: 0,
                    },
                    actionsColumnIndex: -1,
                    exportButton: { csv: true, pdf: true },
                    exportFileName: 'CRM Change Requests',
                    pageSize: 20,
                    columnsButton: true,
                    rowStyle: (rowData) => ({
                      backgroundColor:
                        rowData.authStat === 1 ? 'cyan' : 'beige',
                    }),
                  }}
                  localization={{
                    toolbar: {
                      exportCSVName: 'Export as CSV',
                    },
                  }}
                />
              </div>
              {/* accept req modal */}
              <Modal
                isOpen={this.state.modal_standard}
                toggle={this.tog_standard}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard: false })}
                >
                  {this.state.key?.name}
                  's request
                </ModalHeader>

                <ModalBody>
                  <Col xl={12}>
                    <Row className="mb-3 mx-2">
                      Are you sure you want to Accept {this.state.key?.name}
                      's request?
                    </Row>
                  </Col>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_standard}
                    color="light"
                    className="waves-effect"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={async (event) => {
                      event.preventDefault();
                      const token = getToken();

                      await GKApi.put(
                        `/users/crm-change-requests/approve`,
                        {
                          recId: this.state.key?.recId,
                          type: this.state.reqType,
                          cmpId: 101,
                          deviceInfo: this.state.deviceInfo,
                        },
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                      this.setState((prevState) => ({
                        modal_standard: !prevState.modal_standard,
                      }));
                      this.removeBodyCss();
                      const response1 = await GKApi.get(
                        `/users/crm-change-requests/list?type=profileVerify&pageNumber=1&pageSize=1000`,
                        {
                          headers: {
                            Authorization: token,
                            cmpId: 101,
                          },
                        }
                      );

                      this.setState(
                        {
                          profileReq: response1.data.data,
                        },
                        () => {}
                      );
                      const response2 = await GKApi.get(
                        `/users/crm-change-requests/list?type=activeStatus&pageNumber=1&pageSize=1000`,
                        {
                          headers: {
                            Authorization: token,
                            cmpId: 101,
                          },
                        }
                      );

                      this.setState(
                        {
                          activeStatusReq: response2.data.data,
                        },
                        () => {}
                      );
                      const response3 = await GKApi.get(
                        `/users/crm-change-requests/list?type=resetPass&pageNumber=1&pageSize=1000`,
                        {
                          headers: {
                            Authorization: token,
                            cmpId: 101,
                          },
                        }
                      );
                      this.setState(
                        {
                          resetPassReq: response3.data.data,
                        },
                        () => {}
                      );
                    }}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal
                isOpen={this.state.modal_standard2}
                toggle={this.tog_standard2}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard2: false })}
                >
                  {this.state.key?.name}
                  's request
                </ModalHeader>

                <ModalBody>
                  <Col xl={12}>
                    <Row className="mb-3 mx-2">
                      Are you sure you want to Reject {this.state.key?.name}
                      's request?
                    </Row>
                    <Row className="mb-3 mx-2">
                      If so, Please state the reason.
                    </Row>
                    <Row className="mb-3 mx-2">
                      <Input
                        type="text"
                        // oninput={validate(this)}
                        value={this.state.reason}
                        placeholder="Please specify the reason"
                        id="gold-price-input"
                        onChange={this.handleReasonChange}
                      />
                    </Row>
                  </Col>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_standard2}
                    color="light"
                    className="waves-effect"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={async (event) => {
                      event.preventDefault();
                      const token = getToken();

                      await GKApi.put(
                        `/users/crm-change-requests/reject`,
                        {
                          recId: this.state.key?.recId,
                          type: this.state.reqType,
                          cmpId: 101,
                          deviceInfo: this.state.deviceInfo,
                          reason: this.state.reason,
                        },
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                      this.setState((prevState) => ({
                        modal_standard2: !prevState.modal_standard2,
                      }));
                      this.removeBodyCss();
                      const response1 = await GKApi.get(
                        '/users/crm-change-requests/list?type=profileVerify&pageNumber=1&pageSize=1000',
                        {
                          headers: {
                            Authorization: token,
                            cmpId: 101,
                          },
                        }
                      );

                      this.setState(
                        {
                          profileReq: response1.data.data,
                        },
                        () => {}
                      );
                      const response2 = await GKApi.get(
                        `/users/crm-change-requests/list?type=activeStatus&pageNumber=1&pageSize=1000`,
                        {
                          headers: {
                            Authorization: token,
                            cmpId: 101,
                          },
                        }
                      );

                      this.setState(
                        {
                          activeStatusReq: response2.data.data,
                        },
                        () => {}
                      );
                      const response3 = await GKApi.get(
                        `/users/crm-change-requests/list?type=resetPass&pageNumber=1&pageSize=1000`,
                        {
                          headers: {
                            Authorization: token,
                            cmpId: 101,
                          },
                        }
                      );
                      this.setState(
                        {
                          resetPassReq: response3.data.data,
                        },
                        () => {}
                      );
                    }}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          ) : (
            <h5>Sorry you don't have permission to view this</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default CRMChangeRequests;

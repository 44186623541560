import React, { Component } from 'react';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
import { Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
//Import Breadcrumb
// import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { getToken, GKApi, roundToTwo } from '../../../components/config';
import moment from 'moment';

// Table data

class ActiveUserReportMKT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Marketing', link: '#' },
        { title: 'Reports and insights', link: '#' },
        { title: 'Active User Report', link: '#' },
      ],
      visible: false,
      modal_standard: false,
      columnsList: [],
      data: this.props.data,
      pageNumber: 1,
      pageSize: this.props.pageSize,
      totalPageCount: this.props.totalPage,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  render() {
    const columns = [
      {
        field: ['User Name'],
        title: 'User Name',
        render: (row) => (
          <div>
            {row['User Name'] === ' ' ? 'Not available' : row['User Name']}
          </div>
        ),
      },
      {
        field: ['Mobile No.'],
        title: 'Mobile No.',
      },
      {
        field: ['Email'],
        title: 'Email',
      },

      {
        field: ['Age'],
        title: 'Age',
      },
      {
        field: ['Gender'],
        title: 'Gender',
      },
      {
        field: ['Marital Status'],
        title: 'Marital Status',
      },
      {
        field: ['Thana/District'],
        title: 'Thana/District',
      },

      {
        field: ['Occupation'],
        title: 'Occupation',
      },

      {
        field: ['Join Date'],
        title: 'Join Date',
      },
      {
        field: ['User Type'],
        title: 'User Type',
      },
      {
        field: ['Transaction Type'],
        title: 'Transaction Type',
      },

      {
        field: ['Sell Gold Amount in Taka'],
        title: 'Sell Gold Amount in Taka',
        render: (row) =>
          row['Sell Gold Amount in Taka'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Sell Gold Amount in Taka'])}</div>
          ),
      },

      {
        field: ['Sell Gold Amount in Qty'],
        title: 'Sell Gold Amount in Qty',
      },

      {
        field: ['Payment Channel'],
        title: 'Payment Channel',
      },

      {
        field: ['Last Login Date'],
        title: 'Last Login Date',
      },
    ];

    const summaryColumns = [
      {
        field: ['Customer ID'],
        title: 'Customer ID',
        render: (row) => (
          <div>
            {row['Customer ID'] === ' ' ? 'Not available' : row['Customer ID']}
          </div>
        ),
      },
      {
        field: ['User Name'],
        title: 'User Name',
        render: (row) => (
          <div>
            {row['User Name'] === ' ' ? 'Not available' : row['User Name']}
          </div>
        ),
      },
      {
        field: ['Mobile No.'],
        title: 'Mobile No.',
      },
      {
        field: ['Email'],
        title: 'Email',
      },
      {
        field: ['Age'],
        title: 'Age',
      },
      {
        field: ['Gender'],
        title: 'Gender',
      },
      {
        field: ['Marital Status'],
        title: 'Marital Status',
      },
      {
        field: ['Thana'],
        title: 'Thana',
      },
      {
        field: ['District'],
        title: 'District',
      },
      {
        field: ['Occupation'],
        title: 'Occupation',
      },
      {
        field: ['Join Date'],
        title: 'Join Date',
      },
      {
        field: ['User Type'],
        title: 'User Type',
      },
      {
        field: ['User Activity Type'],
        title: 'User Activity Type',
      },

      {
        field: ['Buy Trx Count'],
        title: 'Buy Trx Count',
        render: (row) =>
          row['Buy Trx Count'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Buy Trx Count'])}</div>
          ),
      },

      {
        field: ['Buy Gold Amount in Qty'],
        title: 'Buy Gold Amount in Qty',
        render: (row) =>
          row['Buy Gold Amount in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Buy Gold Amount in Qty'])}</div>
          ),
      },

      {
        field: ['First Buy Date'],
        title: 'First Buy Date',
        render: (row) =>
          row['First Buy Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['First Buy Date']).format('DD-MM-YYYY')}</div>
          ),
      },
      {
        field: ['First Buy Trx Qty'],
        title: 'First Buy Trx Qty',
        render: (row) =>
          row['First Buy Trx Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['First Buy Trx Qty'])}</div>
          ),
      },

      {
        field: ['Last Buy Date'],
        title: 'Last Buy Date',
        render: (row) =>
          row['Last Buy Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['Last Buy Date']).format('DD-MM-YYYY')}</div>
          ),
      },
      {
        field: ['Last Buy Trx Qty'],
        title: 'Last Buy Trx Qty',
        render: (row) =>
          row['Last Buy Trx Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Last Buy Trx Qty'])}</div>
          ),
      },

      {
        field: ['Buy Average Ticket Size Qty'],
        title: 'Buy Average Ticket Size Qty',
        render: (row) =>
          row['Buy Average Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Buy Average Ticket Size Qty'])}</div>
          ),
      },
      {
        field: ['Buy Max Ticket Size Qty'],
        title: 'Buy Max Ticket Size Qty',
        render: (row) =>
          row['Buy Max Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Buy Max Ticket Size Qty'])}</div>
          ),
      },
      {
        field: ['Buy Min Ticket Size Qty'],
        title: 'Buy Min Ticket Size Qty',
        render: (row) =>
          row['Buy Min Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Buy Min Ticket Size Qty'])}</div>
          ),
      },

      {
        field: ['Buy Payment Channel MFS Count'],
        title: 'Buy Payment Channel MFS Count',
      },
      {
        field: ['Buy Payment Channel Card Count'],
        title: 'Buy Payment Channel Card Count',
      },

      {
        field: ['Buy Campaign Promo Used Amount'],
        title: 'Buy Campaign Promo Used Amount',
      },
      {
        field: ['Buy Campaign Discount Used Amount'],
        title: 'Buy Campaign Discount Used Amount',
      },

      {
        field: ['Sell Trx Count'],
        title: 'Sell Trx Count',
        render: (row) =>
          row['Sell Trx Count'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Sell Trx Count'])}</div>
          ),
      },

      {
        field: ['Sell Gold Amount in Qty'],
        title: 'Sell Gold Amount in Qty',
        render: (row) =>
          row['Sell Gold Amount in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Sell Gold Amount in Qty'])}</div>
          ),
      },

      {
        field: ['First Sell Date'],
        title: 'First Sell Date',
        render: (row) =>
          row['First Sell Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['First Sell Date']).format('DD-MM-YYYY')}</div>
          ),
      },
      {
        field: ['First Sell Trx Qty'],
        title: 'First Sell Trx Qty',
        render: (row) =>
          row['First Sell Trx Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['First Sell Trx Qty'])}</div>
          ),
      },

      {
        field: ['Last Sell Date'],
        title: 'Last Sell Date',
        render: (row) =>
          row['Last Sell Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['Last Sell Date']).format('DD-MM-YYYY')}</div>
          ),
      },
      {
        field: ['Last Sell Trx Qty'],
        title: 'Last Sell Trx Qty',
        render: (row) =>
          row['Last Sell Trx Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Last Sell Trx Qty'])}</div>
          ),
      },

      {
        field: ['Sell Average Ticket Size Qty'],
        title: 'Sell Average Ticket Size Qty',
        render: (row) =>
          row['Sell Average Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Sell Average Ticket Size Qty'])}</div>
          ),
      },
      {
        field: ['Sell Max Ticket Size Qty'],
        title: 'Sell Max Ticket Size Qty',
        render: (row) =>
          row['Sell Max Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Sell Max Ticket Size Qty'])}</div>
          ),
      },
      {
        field: ['Sell Min Ticket Size Qty'],
        title: 'Sell Min Ticket Size Qty',
        render: (row) =>
          row['Sell Min Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Sell Min Ticket Size Qty'])}</div>
          ),
      },
      {
        field: ['Sell Payment Channel MFS Count'],
        title: 'Sell Payment Channel MFS Count',
      },
      {
        field: ['Sell Payment Channel Bank Count'],
        title: 'Sell Payment Channel Bank Count',
      },

      {
        field: ['Sell Campaign Promo Used Amount'],
        title: 'Sell Campaign Promo Used Amount',
      },
      {
        field: ['Sell Campaign Discount Used Amount'],
        title: 'Sell Campaign Discount Used Amount',
      },

      {
        field: ['Collect Trx Count'],
        title: 'Collect Trx Count',
        render: (row) =>
          row['Collect Trx Count'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Collect Trx Count'])}</div>
          ),
      },

      {
        field: ['Collect Gold Amount in Qty'],
        title: 'Collect Gold Amount in Qty',
        render: (row) =>
          row['Collect Gold Amount in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Collect Gold Amount in Qty'])}</div>
          ),
      },

      {
        field: ['First Collect Date'],
        title: 'First Collect Date',
        render: (row) =>
          row['First Collect Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['First Collect Date']).format('DD-MM-YYYY')}</div>
          ),
      },
      {
        field: ['First Collect Trx Qty'],
        title: 'First Collect Trx Qty',
        render: (row) =>
          row['First Collect Trx Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['First Collect Trx Qty'])}</div>
          ),
      },

      {
        field: ['Last Collect Date'],
        title: 'Last Collect Date',
        render: (row) =>
          row['Last Collect Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['Last Collect Date']).format('DD-MM-YYYY')}</div>
          ),
      },
      {
        field: ['Last Collect Trx Qty'],
        title: 'Last Collect Trx Qty',
        render: (row) =>
          row['Last Collect Trx Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Last Collect Trx Qty'])}</div>
          ),
      },

      {
        field: ['Collect Average Ticket Size Qty'],
        title: 'Collect Average Ticket Size Qty',
        render: (row) =>
          row['Collect Average Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Collect Average Ticket Size Qty'])}</div>
          ),
      },
      {
        field: ['Collect Max Ticket Size Qty'],
        title: 'Collect Max Ticket Size Qty',
        render: (row) =>
          row['Collect Max Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Collect Max Ticket Size Qty'])}</div>
          ),
      },
      {
        field: ['Collect Min Ticket Size Qty'],
        title: 'Collect Min Ticket Size Qty',
        render: (row) =>
          row['Collect Min Ticket Size Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Collect Min Ticket Size Qty'])}</div>
          ),
      },

      {
        field: ['Collect SKU Count'],
        title: 'Collect SKU Count',
        render: (row) =>
          row['Collect SKU Count'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Collect SKU Count'])}</div>
          ),
      },

      {
        field: ['Gift Sent Trx Count'],
        title: 'Gift Sent Trx Count',
        render: (row) =>
          row['Gift Sent Trx Count'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Gift Sent Trx Count'])}</div>
          ),
      },
      {
        field: ['Gift Sent Gold Amount in Qty'],
        title: 'Gift Sent Gold Amount in Qty',
        render: (row) =>
          row['Gift Sent Gold Amount in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Gift Sent Gold Amount in Qty'])}</div>
          ),
      },

      {
        field: ['Gift Received Trx Count'],
        title: 'Gift Received Trx Count',
        render: (row) =>
          row['Gift Received Trx Count'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Gift Received Trx Count'])}</div>
          ),
      },
      {
        field: ['Gift Received Gold Amount in Qty'],
        title: 'Gift Received Gold Amount in Qty',
        render: (row) =>
          row['Gift Received Gold Amount in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Gift Received Gold Amount in Qty'])}</div>
          ),
      },
      {
        field: ['Refer Count'],
        title: 'Refer Count',
        render: (row) =>
          row['Refer Count'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Refer Count'])}</div>
          ),
      },
      {
        field: ['Refer Gold Amount in Qty'],
        title: 'Refer Gold Amount in Qty',
        render: (row) =>
          row['Refer Gold Amount in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Refer Gold Amount in Qty'])}</div>
          ),
      },
      {
        field: ['Dispute Count'],
        title: 'Dispute Count',
        render: (row) =>
          row['Dispute Count'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Dispute Count'])}</div>
          ),
      },
      {
        field: ['Dispute Gold Amount in Qty'],
        title: 'Dispute Gold Amount in Qty',
        render: (row) =>
          row['Dispute Gold Amount in Qty'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{roundToTwo(row['Dispute Gold Amount in Qty'])}</div>
          ),
      },
      {
        field: ['Last Login Date'],
        title: 'Last Login Date',
        render: (row) =>
          row['Last Login Date'] === null ? (
            <div>Not Available</div>
          ) : (
            <div>{moment(row['Last Login Date']).format('DD-MM-YYYY')}</div>
          ),
      },
    ];
    const downloadExcel = () => {
      const newData = this.state.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workBook,
        workSheet,
        'Marketing Active User Report'
      );
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download

      this.props.summary === false
        ? XLSX.writeFile(workBook, 'Marketing Active User Report.xlsx')
        : XLSX.writeFile(
            workBook,
            'Marketing Active User Report Summarized.xlsx'
          );
    };

    return (
      <React.Fragment>
        {this.props.data.length > 0 ? (
          <MaterialTable
            title={
              this.props.summary === false
                ? 'Marketing Active User Report'
                : 'Marketing Active User Report Summarized'
            }
            columns={this.props.summary === false ? columns : summaryColumns}
            data={this.state.data}
            actions={[
              {
                icon: () => <Button>Export All</Button>, // you can pass icon too
                tooltip: 'Export to Excel',
                onClick: () => downloadExcel(),
                isFreeAction: true,
              },
            ]}
            options={{
              cellStyle: {
                borderRight: '1px solid #eee',
                textAlign: 'center',
                whiteSpace: 'nowrap',
              },
              headerStyle: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
              },

              exportPDF: {
                headerStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              exportFileName:
                this.props.summary === false
                  ? 'Marketing Active User Report'
                  : 'Marketing Active User Report Summarized',
              paging: false,
              search: false,
            }}
            localization={{
              toolbar: {
                exportCSVName: 'Export as CSV',
              },
            }}
          />
        ) : (
          <h5>Loading</h5>
        )}
        {this.state.isLoading ? (
          <h5
            className="d-flex justify-content-center my-3
gap-5"
          >
            <CircularProgress color="inherit" />
          </h5>
        ) : (
          <div className="my-3 d-flex justify-content-center gap-5">
            <Button
              onClick={async () => {
                let token = getToken();
                //get api call with needed parameters
                if (this.props.summary === true) {
                  let submitData = await GKApi.get(
                    `/marketing/report?reportType=${
                      this.props.report
                    }&isSummary=${this.props.summary}&pageSize=${
                      this.state.pageSize
                    }&pageNumber=${this.state.pageNumber - 1}`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 10,
                      },
                    }
                  );
                  this.setState(
                    {
                      data: submitData.data.data,
                      pageNumber: submitData.data.paginationInfo.pageNumber,
                    },
                    () => {}
                  );
                } else {
                  let submitData = await GKApi.get(
                    `/marketing/report?reportType=${
                      this.props.report
                    }&isSummary=${this.props.summary}&startDate=${
                      this.props.startDate
                    }&endDate=${this.props.endDate}&pageSize=${
                      this.state.pageSize
                    }&pageNumber=${this.state.pageNumber - 1}`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 10,
                      },
                    }
                  );
                  this.setState(
                    {
                      data: submitData.data.data,
                      pageNumber: submitData.data.paginationInfo.pageNumber,
                    },
                    () => {}
                  );
                }
              }}
              disabled={this.state.pageNumber === 1 ? true : false}
            >
              Previous page
            </Button>{' '}
            <Button
              onClick={async () => {
                let token = getToken();
                this.setState({ isLoading: true });
                //get api call with needed parameters
                if (this.props.summary === true) {
                  let submitData = await GKApi.get(
                    `/marketing/report?reportType=${
                      this.props.report
                    }&isSummary=${this.props.summary}&pageSize=${
                      this.state.pageSize
                    }&pageNumber=${this.state.pageNumber + 1}`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 10,
                      },
                    }
                  );
                  if (submitData) {
                    this.setState({
                      isLoading: false,
                    });
                  }
                  this.setState(
                    {
                      data: submitData.data.data,
                      pageNumber: submitData.data.paginationInfo.pageNumber,
                      totalPageCount: submitData.data.paginationInfo.totalPage,
                    },
                    () => {}
                  );
                } else {
                  let submitData = await GKApi.get(
                    `/marketing/report?reportType=${
                      this.props.report
                    }&isSummary=${this.props.summary}&startDate=${
                      this.props.startDate
                    }&endDate=${this.props.endDate}&pageSize=${
                      this.state.pageSize
                    }&pageNumber=${this.state.pageNumber + 1}`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 10,
                      },
                    }
                  );
                  if (submitData) {
                    this.setState({
                      isLoading: false,
                    });
                  }
                  this.setState(
                    {
                      data: submitData.data.data,
                      pageNumber: submitData.data.paginationInfo.pageNumber,
                      totalPageCount: submitData.data.paginationInfo.totalPage,
                    },
                    () => {}
                  );
                }
              }}
              disabled={this.state.pageNumber === this.state.totalPageCount}
            >
              {this.state.isLoading ? 'Loading Next Page' : 'Next Page'}
            </Button>
          </div>
        )}
        <div className="my-2 d-flex justify-content-center">
          <h5>
            Page {this.state.pageNumber} of {this.state.totalPageCount}
          </h5>
        </div>
      </React.Fragment>
    );
  }
}

export default ActiveUserReportMKT;

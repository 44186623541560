import moment from 'moment';
import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
} from 'reactstrap';
import { getToken, GKApi } from '../../../components/config';

class CampaignChannelData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canPalyPauseBanner: 0,
      playPauseBannerModal: false,
    };
  }

  tog_PlayPauseBanner = () => {
    this.setState((prevState) => ({
      playPauseBannerModal: !prevState.playPauseBannerModal,
    }));
    this.removeBodyCss();
  };
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  async componentDidMount() {
    const token = getToken();
    // const groupId = getGroupId();
    const getPermission = await GKApi.get(`/role/permission?cmpId=143`, {
      headers: {
        Authorization: token,
      },
    });
    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    this.setState({
      canPalyPauseBanner: permissionData[143].canAuth,
    });
  }
  render() {
    const campaignData = this.props.campaignData;
    return (
      <div>
        <Card>
          <CardBody className="shadow-lg">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr style={{ backgroundColor: '#F8F9FA' }}>
                    <th scope="col">Active Campaign</th>
                    <th scope="col">Channel</th>
                    <th scope="col">Campaign Type</th>
                    <th scope="col">Tenure</th>

                    <th className="px-2 text-center" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                {campaignData.isBannner === 1 && (
                    <tr>
                      <td>{campaignData.campaignTitle}</td>
                      <td>{campaignData.isBannner === 1 && 'Banner Ads'}</td>
                      <td>Not Available</td>
                      <td>
                        {moment(
                          campaignData.startDate,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD-MM-YYYY')}
                         {' '}to{' '} 
                        {moment(
                          campaignData.endDate,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD-MM-YYYY')}
                      </td>
                      {this.state.canPalyPauseBanner === 1 && (
                        <td>
                          <div>
                            {campaignData.isBannerActive === 1 ? (
                              <div
                                className="px-2 text-center"
                                style={{
                                  backgroundColor: '#FBF2D9',
                                  color: '#EBBA41',
                                }}
                              >
                                <i
                                  className="mdi mdi-stop"
                                  onClick={async () => {
                                    this.setState({
                                      playPauseBannerModal: true,
                                    });
                                  }}
                                ></i>
                              </div>
                            ) : campaignData.isBannerActive === 0 ? (
                              <div
                                className="px-2 text-center"
                                style={{
                                  backgroundColor: '#FBF2D9',
                                  color: '#EBBA41',
                                }}
                              >
                                <i
                                  className="mdi mdi-play"
                                  onClick={async () => {
                                    this.setState({
                                      playPauseBannerModal: true,
                                    });
                                  }}
                                ></i>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <Modal
                            isOpen={this.state.playPauseBannerModal}
                            toggle={this.tog_PlayPauseBanner}
                            size="lg"
                            // style={{ maxWidth: '900px', width: '100%' }}
                          >
                            <ModalHeader
                              toggle={() =>
                                this.setState({
                                  playPauseBannerModal: false,
                                })
                              }
                            >
                              {campaignData.isBannerActive === 1
                                ? 'Stop Banner'
                                : 'Start Banner'}
                            </ModalHeader>
                            {/* Edit */}
                            <ModalBody>
                              <Row>
                                <div>
                                  Are you sure you wan to{' '}
                                  {campaignData.isBannerActive === 1
                                    ? 'Stop Banner'
                                    : 'Start Banner'}
                                  ?
                                </div>
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                onClick={async () => {
                                  let token = getToken();
                                  await GKApi.put(
                                    `/marketing/play-pause/banner`,
                                    {
                                      cmpId: 143,
                                      isActive:
                                        campaignData.isBannerActive === 1
                                          ? false
                                          : true,
                                      bannerId: campaignData.bannerId,
                                    },
                                    {
                                      headers: {
                                        Authorization: token,
                                      },
                                    }
                                  );
                                  this.tog_PlayPauseBanner();
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                onClick={() => {
                                  this.tog_PlayPauseBanner();
                                }}
                              >
                                No
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </td>
                      )}
                    </tr>
                  )}
                  {campaignData.isBanner === 1 && (
                    <tr>
                      <td>{campaignData.title}</td>
                      <td>{campaignData.isBanner === 1 && 'Banner Ads'}</td>
                      <td>{campaignData.campaignType}</td>
                      <td>
                        {moment(
                          campaignData.startDate,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD-MM-YYYY')}
                         {' '}to{' '} 
                        {moment(
                          campaignData.endDate,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD-MM-YYYY')}
                      </td>
                      {this.state.canPalyPauseBanner === 1 && (
                        <td>
                          <div>
                            {campaignData.isBannerActive === 1 ? (
                              <div
                                className="px-2 text-center"
                                style={{
                                  backgroundColor: '#FBF2D9',
                                  color: '#EBBA41',
                                }}
                              >
                                <i
                                  className="mdi mdi-stop"
                                  onClick={async () => {
                                    this.setState({
                                      playPauseBannerModal: true,
                                    });
                                  }}
                                ></i>
                              </div>
                            ) : campaignData.isBannerActive === 0 ? (
                              <div
                                className="px-2 text-center"
                                style={{
                                  backgroundColor: '#FBF2D9',
                                  color: '#EBBA41',
                                }}
                              >
                                <i
                                  className="mdi mdi-play"
                                  onClick={async () => {
                                    this.setState({
                                      playPauseBannerModal: true,
                                    });
                                  }}
                                ></i>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <Modal
                            isOpen={this.state.playPauseBannerModal}
                            toggle={this.tog_PlayPauseBanner}
                            size="lg"
                            // style={{ maxWidth: '900px', width: '100%' }}
                          >
                            <ModalHeader
                              toggle={() =>
                                this.setState({
                                  playPauseBannerModal: false,
                                })
                              }
                            >
                              {campaignData.isBannerActive === 1
                                ? 'Stop Banner'
                                : 'Start Banner'}
                            </ModalHeader>
                            {/* Edit */}
                            <ModalBody>
                              <Row>
                                <div>
                                  Are you sure you wan to{' '}
                                  {campaignData.isBannerActive === 1
                                    ? 'Stop Banner'
                                    : 'Start Banner'}
                                  ?
                                </div>
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                onClick={async () => {
                                  let token = getToken();
                                  await GKApi.put(
                                    `/marketing/play-pause/banner`,
                                    {
                                      cmpId: 143,
                                      isActive:
                                        campaignData.isBannerActive === 1
                                          ? false
                                          : true,
                                      bannerId: campaignData.bannerId,
                                    },
                                    {
                                      headers: {
                                        Authorization: token,
                                      },
                                    }
                                  );
                                  this.tog_PlayPauseBanner();
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                onClick={() => {
                                  this.tog_PlayPauseBanner();
                                }}
                              >
                                No
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </td>
                      )}
                    </tr>
                  )}
                  {campaignData.isInbox === 1 && (
                    <tr>
                      <td>{campaignData.campaignTitle}</td>
                      <td>{campaignData.isInbox === 1 && 'Inbox'}</td>
                      <td>{campaignData.campaignType? campaignData.campaignType:'Not Available' }</td>
                      <td>
                        {moment(
                          campaignData.startDate,
                          'DD-MM-YYYYTHH:mm:ssZ'
                        ).format('DD-MM-YYYY')}
                        -
                        {moment(
                          campaignData.endDate,
                          'DD-MM-YYYYTHH:mm:ssZ'
                        ).format('DD-MM-YYYY')}
                      </td>
                    </tr>
                  )}
                  {campaignData.isNotification === 1 && (
                    <tr>
                      <td>{campaignData.campaignTitle}</td>
                      <td>
                        {campaignData.isNotification === 1 && 'Notification'}
                      </td>
                      <td>{campaignData.campaignType? campaignData.campaignType:'Not Available' }</td>
                      <td>
                        {moment(
                          campaignData.startDate,
                          'DD-MM-YYYYTHH:mm:ssZ'
                        ).format('DD-MM-YYYY')}
                        -
                        {moment(
                          campaignData.endDate,
                          'DD-MM-YYYYTHH:mm:ssZ'
                        ).format('DD-MM-YYYY')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default CampaignChannelData;

import React, { Component } from "react";


//i18b
import { withNamespaces } from "react-i18next";


class NotificationDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }
    render() {
        return (
            <React.Fragment>
                
            </React.Fragment>
        );
    }
}
export default withNamespaces()(NotificationDropdown);

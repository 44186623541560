import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import * as XLSX from 'xlsx';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import SweetAlert from 'react-bootstrap-sweetalert';
import {
  getGroupName,
  getToken,
  getUser,
  GKApi,
} from '../../../components/config';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { deviceInfo } from '../../../helpers/device_info_helper';

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const name = useSelector((state) => state.Login.user.user?.name);
    const email = useSelector((state) => state.Login.user.user?.email);
    const designation = useSelector(
      (state) => state.Login.user.user?.designation
    );
    const dob = useSelector((state) => state.Login.user.user?.dob);
    const phone = useSelector((state) => state.Login.user.user?.phone);
    const groupId = useSelector((state) => state.Login.user.user?.groupId);
    return (
      <Component
        {...props}
        name={name}
        email={email}
        designation={designation}
        dob={dob}
        phone={phone}
        groupId={groupId}
      />
    );
  };
}
class ReferDisbursement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Tables', link: '#' },
        { title: 'Admin User Table', link: '#' },
      ],
      referData: [],
      fullName: '',
      email: '',
      phoneNumber: '',
      role: null,
      visible: false,
      modal_standard: false,
      modal_standard2: false,
      modal_standard3: false,
      custom_div1: false,
      referIds: [],
      deviceInfo: {},
      ip: '',
      reason: '',
      referAuthLevel: 1,
      userAuthLevel: 1,
      canViewReferDisbursement: 1,
      referAuthStatForUser: 1,
      authDif: 1,
      isFin:false,
      isSuper:false
    };
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_standard2 = this.tog_standard2.bind(this);
    this.tog_standard3 = this.tog_standard3.bind(this);
  }

  //change handlers

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_standard2() {
    this.setState((prevState) => ({
      modal_standard2: !prevState.modal_standard2,
    }));
    this.removeBodyCss();
  }

  tog_standard3() {
    this.setState((prevState) => ({
      modal_standard3: !prevState.modal_standard3,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleReasonChange = (event) => {
    this.setState({ ...this.state, reason: event.target.value });
  };

  // get call for refer disbursement list
  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    // GET request using axios with async/await

    const token = getToken();

    // const gId = getGroupId();

    const response = await GKApi.get(
      `/finance/refer?pageSize=1000&pageNumber=1`,
      {
        headers: {
          Authorization: token,
          cmpId: 19,
        },
      }
    );

    // getting permission data from api
    const getPermission = await GKApi.get(
      `/role/permission?cmpId=19`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    const referDataTemp = [];

    //adding auth difference attribute to the refer list data
    response.data.data.forEach((item) => {
      item['authDif'] = permissionData[19].authState - item.authStat;
      referDataTemp.push(item);
    });

    this.setState({
      canViewReferDisbursement: permissionData[19].canView,
      userAuthLevel: permissionData[19].authState,
      referData: response.data.data,
      referAuthLevel: response.data.data.authStat,
    });

    const res = deviceInfo();
    this.setState({ deviceInfo: res });

    this.setState(
      {
        referData: referDataTemp,
      },
      () => {}
    );

  }

  render() {
    const columns = [
      {
        field: 'bonusEligibleDate',
        title: 'Bonus Eligible Date',
        render: (row) => (
          <div>
            {row.bonusEligibleDate
              ? moment(row.bonusEligibleDate).format('DD-MM-YYYY')
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'nameReferFrom',
        title: 'Name (Refer From)',
      },
      {
        field: 'msisdnReferFrom',
        title: 'MSISDN (Refer From)',
      },
      {
        field: 'nameReferTo',
        title: 'Name (Refer To)',
      },
      {
        field: 'msisdnReferTo',
        title: 'MSISDN (Refer To)',
      },
      {
        field: 'refererBonus',
        title: 'Referer Bonus (in BDT)',
      },
      {
        field: 'refereeBonus',
        title: 'Referee Bonus (in BDT)',
      },
      
      // {
      //   field: 'authStat',
      //   title: 'Refer Status',
      //   render: (row) => (
      //     <div>
      //       {row.authStat === 1
      //         ? 'Pending for checking'
      //         : 'Ready for Authorization'}
      //     </div>
      //   ),
      // },
    ];

    const downloadExcel = () => {
      const newData = this.state.referData.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Refer Disbursement');
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(workBook, 'Refer Disbursement.xlsx');
    };
    const loggedUser = getUser();

    return (
      <React.Fragment>
        <div className="page-content" id="19">
          {this.state.isFin===true||this.state.isSuper?<div>
            {this.state.canViewReferDisbursement === 1 ? (
              <div className="container-fluid">
                <Breadcrumbs
                  title="Refer Disbursement Table"
                  breadcrumbItems={this.state.breadcrumbItems}
                />
                <MaterialTable
                  title="List of Refer Disbursement"
                  columns={columns}
                  data={this.state.referData}
                  components={{
                    Toolbar: (props) => (
                      <div>
                        <MTableToolbar {...props} />
                        
                        <div className="px-3 my-4 d-flex gap-3">
                          <div>
                            <i
                              className="mdi mdi-circle  font-size-10 me-1"
                              style={{ color: '#00FFFF' }}
                            ></i>{' '}
                            <span>Ready for checker to review.</span>
                          </div>
                          <div>
                            <i
                              className="mdi mdi-circle  font-size-10 me-1"
                              style={{ color: '#F5F5DC' }}
                            ></i>{' '}
                            <span>Ready for First Authorizer to review.</span>
                          </div>
                          <div>
                            <i
                              className="mdi mdi-circle  font-size-10 me-1"
                              style={{ color: '#9ACD32' }}
                            ></i>{' '}
                            <span>Ready for Second Authorizer to review.</span>
                          </div>
                        </div>
                      </div>
                    ),
                  }}
                  actions={[
                    {
                      icon: () => <Button>Export All</Button>, // you can pass icon too
                      tooltip: 'Export to Excel',
                      onClick: () => downloadExcel(),
                      isFreeAction: true,
                    },
                    {
                      icon: () => <Button>Proceed with Disbursement</Button>, // you can pass icon too
                      tooltip: 'Disburse',
                      onClick: (event, data) => {
                        this.setState({ modal_standard3: true });
                        const selectedIds = [];
                        for (const obj of data) {
                          selectedIds.push(obj.id);
                        }
                        this.setState({ referIds: selectedIds });
                      },
                    },
                    {
                      icon: () => <Button>Reject Disbursement</Button>, // you can pass icon too
                      tooltip: 'Disburse',
                      onClick: (event, data) => {
                        this.setState({ modal_standard2: true });
                        const selectedIds = [];
                        for (const obj of data) {
                          selectedIds.push(obj.id);
                        }
                        this.setState({ referIds: selectedIds });
                      },
                    },
                  ]}
                  options={{
                    cellStyle: {
                      borderRight: '1px solid #eee',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    },
  
                    //style for the header
  
                    headerStyle: {
                      whiteSpace: 'nowrap',
  
                      textAlign: 'left',
                      top: 0,
                    },
                    selection: true,
                    selectionProps: (rowData) => ({
                      disabled:
                      rowData.authDif !== 1 &&
                      (rowData.entryStaffID === loggedUser ||
                        rowData.lastAuthorizerID === loggedUser),
                    }),
                    showSelectAllCheckbox: false,
                    actionsColumnIndex: -1,
                    exportButton: { csv: true, pdf: true },
                    exportFileName: 'Refer Disbursement',
                    pageSize: 20,
                    columnsButton: true,
                    rowStyle: (rowData) => ({
                      backgroundColor:
                        rowData.authStat === 1
                          ? 'cyan'
                          : rowData.authStat === 2
                          ? 'beige'
                          : 'yellowgreen',
                    }),
                  }}
                  localization={{
                    toolbar: {
                      exportCSVName: 'Export as CSV',
                      exportPDFName: 'Export as PDF',
                    },
                  }}
                />
                {/*
                 * @desc modal for ACCEPTING request for the CHECKER AND AUTHORIZER STARTS here
                 */}
                <Modal
                  isOpen={this.state.modal_standard3}
                  toggle={this.tog_standard3}
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_standard3: false })}
                  >
                    Proceed with disbursement of Refer
                  </ModalHeader>
  
                  <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <ModalBody>
                        {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                        Please{' '}
                        {this.props.groupId === 2 ? 'Approve' : 'Authorize'}{' '}
                        request for disbursement approval
                      </ModalBody>
  
                      <ModalFooter>
                        <Button
                          type="button"
                          onClick={this.tog_standard3}
                          color="light"
                          className="waves-effect"
                        >
                          Cancel
                        </Button>
  
                        <Button
                          type="submit"
                          color="primary"
                          className="waves-effect waves-light"
                          //submit refer data for disbursement
                          onClick={async (event) => {
                            event.preventDefault();
  
                            const token = getToken();
                            const user = getUser();
                            const submit = await GKApi.put(
                              `/finance/refer/approve`,
                              {
                                cmpId: 19,
                                userIP: this.state.deviceInfo.IPv4,
                                userID: user,
                                deviceInfo: this.state.deviceInfo,
                                referIDs: this.state.referIds,
                              },
                              {
                                headers: {
                                  Authorization: token,
                                },
                              }
                            );
                            this.tog_standard3();
  
                            // refetching api data after submit
                            if (submit.status === 200) {
                              const response = await GKApi.get(
                                `/finance/refer?pageSize=1000&pageNumber=1`,
                                {
                                  headers: {
                                    cmpId: 19,
                                    Authorization: token,
                                  },
                                }
                              );
                              this.setState({ referData: response.data.data });
                            }
                          }}
                        >
                          {this.props.groupId === 2 ? 'Approve' : 'Authorize'}
                        </Button>
                        {this.state.custom_div1 ? (
                          <SweetAlert
                            title="Request successfully submitted for review!!"
                            timeout={2500}
                            style={{
                              top: '0px',
                              right: '0px',
                            }}
                            showCloseButton={false}
                            showConfirm={false}
                            success
                            onConfirm={() =>
                              this.setState({ custom_div1: false })
                            }
                          ></SweetAlert>
                        ) : null}
                      </ModalFooter>
                    </FormGroup>
                  </Form>
                </Modal>
                {/*
                 * @desc modal for REJECTING request for the CHECKER AND AUTHORIZER STARTS here
                 */}
                <Modal
                  isOpen={this.state.modal_standard2}
                  toggle={this.tog_standard2}
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_standard2: false })}
                  >
                    Reject disbursement request of Refer
                  </ModalHeader>
  
                  <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <ModalBody>
                        {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                        <Col xl={12}>
                          <Row className="mb-3 mx-2">
                            Please confirm to reject request for disbursement
                            approval
                          </Row>
                          <Row className="mb-3 mx-2">
                            <Input
                              type="text"
                              // oninput={validate(this)}
                              value={this.state.reason}
                              placeholder="Please specify the reason"
                              id="gold-price-input"
                              onChange={this.handleReasonChange}
                            />
                          </Row>
                        </Col>
                      </ModalBody>
  
                      <ModalFooter>
                        <Button
                          type="button"
                          onClick={this.tog_standard2}
                          color="light"
                          className="waves-effect"
                        >
                          Cancel
                        </Button>
  
                        <Button
                          type="submit"
                          color="primary"
                          className="waves-effect waves-light"
                          //submit refer data for disbursement
                          onClick={async (event) => {
                            event.preventDefault();
  
                            const token = getToken();
                            // const user = getUser();
                            const submit = await GKApi.put(
                              `/finance/refer/reject`,
                              {
                                cmpId: 19,
                                // userIP: this.state.deviceInfo.IPv4,
                                // userID: user,
                                deviceInfo: this.state.deviceInfo,
                                referIDs: this.state.referIds,
                                reason: this.state.reason,
                              },
                              {
                                headers: {
                                  Authorization: token,
                                },
                              }
                            );
                            this.tog_standard2();
  
                            // refetching api data after submit
                            if (submit.status === 200) {
                              const response = await GKApi.get(
                                `/finance/refer?pageSize=1000&pageNumber=1`,
                                {
                                  headers: {
                                    cmpId: 19,
                                    Authorization: token,
                                  },
                                }
                              );
                              this.setState({ referData: response.data.data });
                            }
                          }}
                        >
                          Reject
                        </Button>
                        {this.state.custom_div1 ? (
                          <SweetAlert
                            title="Request successfully submitted for review!!"
                            timeout={2500}
                            style={{
                              top: '0px',
                              right: '0px',
                            }}
                            showCloseButton={false}
                            showConfirm={false}
                            success
                            onConfirm={() =>
                              this.setState({ custom_div1: false })
                            }
                          ></SweetAlert>
                        ) : null}
                      </ModalFooter>
                    </FormGroup>
                  </Form>
                </Modal>
              </div>
            ) : (
              ''
            )}
          </div>:<h5>Sorry you don't have permission to view this</h5>}
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(ReferDisbursement);

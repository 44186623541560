import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Input,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  
  Nav,
  CardText,
  Button,
  
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import {
  capitalizeFirstLetter,
  getToken,
  getUser,
  GKApi,
} from '../../../components/config';
import moment from 'moment';
import DatePickerComponent from '../../../components/Common/DatePicker';
import { deviceInfo } from '../../../helpers/device_info_helper';

class EditDraftGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        // { title: 'Forms', link: '#' },
        // { title: 'Create Campaign', link: '#' },
      ],
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 25,
      isOpen: false,
      commType: 'Select General Communication Type',
      radio: 'App Banner',
      radio1Campaign: 1,
      radio1General: 'All Users',
      selectedFiles: [],
      selectedFilesBanner: [],
      generalCampaignDocId: '',
      generalSelectedStartDate: null,
      generalSelectedEndDate: null,
      generalSelectedStartTime: '',
      generalSelectedEndTime: '',
      isOpen1: false,
      menu1: 'General',
      isOpen2: false,
      presetId: 'Select preset Audience',
      activeTabJustify: '5',
      modal_standard: false,
      generalCommunicationTitle: '',
      generalAppBannerUrl: '',
      generalAppBannerTargetLink: '',
      generalPushNotificationTitle: '',
      generalPushNotificationDescription: '',
      generalPushNotificationTargetLink: '',
      generalInboxTitle: '',
      generalInboxDescription: '',
      generalInboxTargetLink: '',
      deviceInfo: {},
      communicationType: [],
      audienceType: [],
      audType: '',
      audTypeCode: 1,
      commCode: 1,
      generalBannerLinkTypeMenu: false,
      generalBannerLinkType: 'Select Link Type',
      generalBannerInternalLinkTypeMenu: false,
      generalPushNotificationLinkTypeMenu: false,
      generalPushNotificationLinkType: 'Select Link Type',
      generalInboxLinkTypeMenu: false,
      generalInboxLinkType: 'Select Link Type',
      bannerInternalLinkList: [],
      downloadAudienceData: [],
      presetData: [],
      presetName: 'Select preset',
      generalBannerS3Key: '',
    };
    // resetState = () => {
    //   this.setState(this.menu1());
    // };
    this.toggleTab.bind(this);
    this.toggleTabProgress.bind(this);
  }

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();

    this.setState({
      generalCommunicationTitle: this.props.generalDraftData.campaignTitle,
      generalSelectedStartDate: moment(this.props.generalDraftData.startDate).format('MM-DD-YYYY'),
      generalSelectedStartTime: moment(this.props.generalDraftData.startDate).format('HH:mm:ss'),
      generalSelectedEndDate: moment(this.props.generalDraftData.endDate).format('MM-DD-YYYY'),
      generalSelectedEndTime: moment(this.props.generalDraftData.endDate).format('HH:mm:ss'),
      commType:
        this.props.generalDraftData.comType === 1
          ? 'Greeting'
          : this.props.generalDraftData.comType === 2
          ? 'Announcement'
          : this.props.generalDraftData.comType === 3
          ? 'Reminder'
          : 'Others',
      generalPushNotificationTitle:
        this.props.generalDraftData.notificationTitle,
      generalPushNotificationDescription:
        this.props.generalDraftData.notificationMsg,
      // selectedFilesBanner:this.props.generalDraftData.bannerUrl
      generalBannerLinkType: this.props.generalDraftData.bannerLinkType,
      generalAppBannerTargetLink: this.props.generalDraftData.bannerLink,
      audTypeCode:this.props.generalDraftData.audienceType,
      radio1General:
        this.props.generalDraftData.audienceType === 1
          ? 'All Users'
          : this.props.generalDraftData.audienceType === 2
          ? 'All New Users'
          : 'Custom Users',
      presetId: this.props.generalDraftData.customerPresetId,
      generalInboxDescription: this.props.generalDraftData.inboxMsg,
    });
    if (this.props.generalDraftData.docId) {
      this.setState({
        generalCampaignDocId: this.props.generalDraftData.docId,
      });
    }

    if (this.props.generalDraftData.bannerUrl) {
      this.setState({
        generalAppBannerUrl: this.props.generalDraftData.bannerUrl,
        generalBannerS3Key: this.props.generalDraftData.bannerS3Key,
      });
    }

    const getAudienceData = await GKApi.get(
      `/marketing/download-audience?id=${this.props.generalDraftData.customerPresetId}`,

      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (getAudienceData.data) {
      this.setState({
        presetData: JSON.parse(getAudienceData.data.jsonData),
      });
    }

    const commType = await GKApi.get('/marketing/general-com/type', {
      headers: {
        Authorization: token,
      },
    });

    this.setState({
      communicationType: commType.data,
    });

    const audType = await GKApi.get('/marketing/general-com/audience-type', {
      headers: {
        Authorization: token,
      },
    });
    this.setState({
      audienceType: audType.data,
    });

    const getDownloadAudience = await GKApi.get(
      '/marketing/download-audience',
      {
        headers: {
          Authorization: token,
        },
      }
    );
    this.setState({
      downloadAudienceData: getDownloadAudience.data,
    });

    //internal link api call

    const internalLinkList = await GKApi.get('/marketing/banner/target-link', {
      headers: {
        Authorization: token,
      },
    });
    this.setState({ bannerInternalLinkList: internalLinkList.data.data });

    const res = deviceInfo();
    this.setState({ deviceInfo: res }, () => {});
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTabProgress: tab,
        });

        if (tab === 1) {
          this.setState({ progressValue: 25 });
        }
        if (tab === 2) {
          this.setState({ progressValue: 50 });
        }
        if (tab === 3) {
          this.setState({ progressValue: 75 });
        }
        if (tab === 4) {
          this.setState({ progressValue: 100 });
        }
      }
    }
  }

  removeBodyCss = () => {
    document.body.classList.add('no_padding');
  };

  handleGeneralCommunicationTitleChange = (e) => {
    this.setState({ generalCommunicationTitle: e.target.value });
  };
  handleGeneralSelectedStartDateChange = (e) => {
    this.setState({ generalSelectedStartDate: e });
  };

  handleGeneralSelectedStartTimeChange = (e) => {
    this.setState({ generalSelectedStartTime: e.target.value });
  };

  handleGeneralSelectedEndDateChange = (e) => {
    this.setState({ generalSelectedEndDate: e });
  };

  handleGeneralSelectedEndTimeChange = (e) => {
    this.setState({ generalSelectedEndTime: e.target.value });
  };

  handleGeneralAppBannerTargetLinkChange = (e) => {
    this.setState({ generalAppBannerTargetLink: e.target.value });
  };
  handleGeneralPushNotificationTitleChange = (e) => {
    this.setState({ generalPushNotificationTitle: e.target.value });
  };
  handleGeneralPushNotificationDescriptionChange = (e) => {
    this.setState({ generalPushNotificationDescription: e.target.value });
  };
  handleGeneralPushNotificationTargetLinkChange = (e) => {
    this.setState({ generalPushNotificationTargetLink: e.target.value });
  };
  handleGeneralInboxTitleChange = (e) => {
    this.setState({ generalInboxTitle: e.target.value });
  };
  handleGeneralInboxDescriptionChange = (e) => {
    this.setState({ generalInboxDescription: e.target.value });
  };
  handleGeneralInboxTargetLinkChange = (e) => {
    this.setState({ generalInboxTargetLink: e.target.value });
  };

  //   file upload
  handleAcceptedFiles = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };

  handleAcceptedFilesBanner = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFilesBanner: files });
  };
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  //   for radio button
  onRadioChange = (e) => {
    this.setState({
      radio: e.currentTarget.value,
    });
  };

  onRadioChange1General = (e) => {
    this.setState({
      radio1General: e.currentTarget.value,
    });
  };

  toggleCustomJustified(tab) {
    if (this.state.activeTabJustify !== tab) {
      this.setState({
        activeTabJustify: tab,
      });
    }
  }

  uploadDoc = async () => {
    const token = getToken(); //get token from local storage
    const response = await GKApi.post(
      '/marketing/proposal-doc',
      {
        staffId: getUser(),
        fileName:
          'proposal.' + this.state.selectedFiles[0].name.split('.').pop(),
        campaignTitle: this.state.generalCommunicationTitle
          ? this.state.generalCommunicationTitle
          : 'General',
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // eslint-disable-next-line
    this.state.generalCampaignDocId = response.data.docId;
    await axios.put(
      response.data.url,
      this.state.selectedFiles[0]
    );
  };

  uploadBanner = async () => {
    const token = getToken(); //get token from local storage
    const response = await GKApi.post(
      '/marketing/banner',
      {
        staffId: getUser(),
        fileName:
          'banner.' + this.state.selectedFilesBanner[0].name.split('.').pop(),
        campaignTitle: this.state.generalCommunicationTitle
          ? this.state.generalCommunicationTitle
          : 'General',
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // eslint-disable-next-line
    this.state.generalAppBannerUrl = response.data.key;
    await axios.put(
      response.data.url,
      this.state.selectedFilesBanner[0]
    );
  };

  handleGeneralCommunicationSubmit = async () => {
    // let staffId = getUser();
    const token = getToken(); //get token from local storage
    // upload banner
    if (this.state.selectedFilesBanner.length > 0) {
      await this.uploadBanner();
    }
    // upload doc
    if (this.state.selectedFiles.length > 0) {
      await this.uploadDoc();
    }

    const submitData = {
      isDraft: 0,
      campaignTitle: this.state.generalCommunicationTitle,
      draftId: this.props.generalDraftData.id,
      deviceInfo: {
        ip: this.state.deviceInfo.IPv4,
      },
      startDate: this.state.generalSelectedStartDate
        ? moment(this.state.generalSelectedStartDate).format(
            'DD-MM-YYYY'
          )+this.state.generalSelectedStartTime : null,
      endDate: this.state.generalSelectedEndDate
        ? moment(this.state.generalSelectedEndDate).format(
          'DD-MM-YYYY'
        )+this.state.generalSelectedEndTime : null,
      comType: this.state.commCode,
      docId:
        this.state.generalCampaignDocId === ''
          ? null
          : this.state.generalCampaignDocId,
      notificationTitle:
        this.state.generalPushNotificationTitle === ''
          ? null
          : this.state.generalPushNotificationTitle,
      notificationMsg:
        this.state.generalPushNotificationDescription === ''
          ? null
          : this.state.generalPushNotificationDescription,
      notificationLink:
        this.state.generalPushNotificationTargetLink === ''
          ? null
          : this.state.generalPushNotificationTargetLink,
      notificationLinkType:
        this.state.generalPushNotificationLinkType === 'Select Link Type'
          ? null
          : this.state.generalPushNotificationLinkType,
      bannerUrl:
        this.state.selectedFilesBanner.length > 0
          ? this.state.generalAppBannerUrl === ''
            ? null
            : this.state.generalAppBannerUrl
          : this.state.generalAppBannerUrl === ''
          ? null
          : this.state.generalBannerS3Key,
      bannerLink:
        this.state.generalAppBannerTargetLink === ''
          ? null
          : this.state.generalAppBannerTargetLink,
      bannerLinkType:
        this.state.generalBannerLinkType === 'Select Link Type'
          ? null
          : this.state.generalBannerLinkType,
      audienceType: this.state.audTypeCode,
      customerPresetId:
        this.state.presetId === 'Select preset Audience'
          ? null
          : this.state.presetId,
      inboxTitle:
        this.state.generalInboxTitle === ''
          ? null
          : this.state.generalInboxTitle,
      inboxMsg:
        this.state.generalInboxDescription === ''
          ? null
          : this.state.generalInboxDescription,
      isBannner:
        this.state.selectedFilesBanner.length > 0 ||
        this.state.generalAppBannerUrl
          ? 1
          : 0,
      isNotification:
        this.state.generalPushNotificationDescription ? 1 : 0,
      isInbox: this.state.generalInboxDescription ? 1 : 0,
    };
    await GKApi.put('/marketing/general-com', submitData, {
      headers: {
        Authorization: token,
      },
    });
    await this.props.fetchCampaign()
  };

  handleGeneralCommunicationDraftSave = async () => {
    const token = getToken(); //get token from local storage
    // upload banner
    if (this.state.selectedFilesBanner.length > 0) {
      await this.uploadBanner();
    }
    // upload doc
    if (this.state.selectedFiles.length > 0) {
      await this.uploadDoc();
    }

    const submitData = {
      isDraft: 1,
      campaignTitle: this.state.generalCommunicationTitle,
      // cmpId: 37,
      draftId: this.props.generalDraftData.id,
      deviceInfo: {
        ip: this.state.deviceInfo.IPv4,
      },
      startDate: this.state.generalSelectedStartDate
        ? moment(this.state.generalSelectedStartDate).format(
            'DD-MM-YYYY'
          )+this.state.generalSelectedStartTime : null,
      endDate: this.state.generalSelectedEndDate
        ? moment(this.state.generalSelectedEndDate).format(
          'DD-MM-YYYY'
        )+this.state.generalSelectedEndTime : null,
      comType: this.state.commCode,
      docId:
        this.state.generalCampaignDocId === ''
          ? null
          : this.state.generalCampaignDocId,
      notificationTitle:
        this.state.generalPushNotificationTitle === ''
          ? null
          : this.state.generalPushNotificationTitle,
      notificationMsg:
        this.state.generalPushNotificationDescription === ''
          ? null
          : this.state.generalPushNotificationDescription,
      notificationLink:
        this.state.generalPushNotificationTargetLink === ''
          ? null
          : this.state.generalPushNotificationTargetLink,
      notificationLinkType:
        this.state.generalPushNotificationLinkType === 'Select Link Type'
          ? null
          : this.state.generalPushNotificationLinkType,
      bannerUrl:
        this.state.selectedFilesBanner.length > 0
          ? this.state.generalAppBannerUrl === ''
            ? null
            : this.state.generalAppBannerUrl
          : this.state.generalAppBannerUrl === ''
          ? null
          : this.state.generalBannerS3Key,
      bannerLink:
        this.state.generalAppBannerTargetLink === ''
          ? null
          : this.state.generalAppBannerTargetLink,
      bannerLinkType:
        this.state.generalBannerLinkType === 'Select Link Type'
          ? null
          : this.state.generalBannerLinkType,
      audienceType: this.state.audTypeCode,
      customerPresetId:
        this.state.presetId === 'Select preset Audience'
          ? null
          : this.state.presetId,
      inboxTitle:
        this.state.generalInboxTitle === ''
          ? null
          : this.state.generalInboxTitle,
      inboxMsg:
        this.state.generalInboxDescription === ''
          ? null
          : this.state.generalInboxDescription,
      isBannner:
        this.state.selectedFilesBanner.length > 0 ||
        this.state.generalAppBannerUrl
          ? 1
          : 0,
      isNotification:
        this.state.generalPushNotificationDescription ? 1 : 0,
      isInbox: this.state.generalInboxDescription ? 1 : 0,
    };
    await GKApi.put('/marketing/general-com', submitData, {
      headers: {
        Authorization: token,
      },
    });
    await this.props.fetchCampaign()
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <Row>
            <Col xl={5}>
              <h6 className="fw-bold pt-2">General Communication</h6>
            </Col>
          </Row>

          {/* @desc general communication create */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 1,
                          })}
                          onClick={() => {
                            this.toggleTab(1);
                          }}
                        >
                          <span className="step-number">01</span>
                          <span className="step-title">Details</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 2,
                          })}
                          onClick={() => {
                            this.toggleTab(2);
                          }}
                        >
                          <span className="step-number">02</span>
                          <span className="step-title">Content</span>
                        </NavLink>
                      </NavItem>
                    </ul>
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="twitter-bs-wizard-tab-content"
                    >
                      <TabPane tabId={1}>
                        <Form>
                          <Row>
                            <Col lg="12">
                              <div className="mb-3">
                                <h6 className="fw-bold">
                                  General communication Title
                                </h6>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-firstname-input1"
                                  value={this.state.generalCommunicationTitle}
                                  onChange={(e) => {
                                    this.handleGeneralCommunicationTitleChange(
                                      e
                                    );
                                  }}
                                />
                                <p className="pb-2">
                                  * Please at least fill the title field to
                                  proceed to the next step
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <div>
                            {' '}
                            <h6 className="fw-bold">
                              General communication Tenure
                            </h6>
                            <p>
                              Select your General Communication start date and
                              end date
                            </p>
                          </div>
                          <Row>
                              <Col lg="6" className='d-flex gap-2'>
                                <Col lg='2'><p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Start Date
                                  </p></Col>
                                <Col lg='4'>
                                  <div
                                    style={{
                                      border: '1px solid gray',
                                      borderRadius: '5px',
                                      paddingTop: '2.5px',
                                      paddingBottom: '2.5px',
                                      paddingRight: '4px',
                                      paddingLeft: '4px',
                                    }}
                                  >
                                    
                                    <DatePickerComponent
                                      selectedDate={
                                        this.state.generalSelectedStartDate
                                      }
                                      handleSelectedDateChange={
                                        this.handleGeneralSelectedStartDateChange
                                      }
                                    />
  
  </div>
                                </Col >
<Col lg='4'>
  <Input
                            type="time"
                            value={this.state.generalSelectedStartTime}
                            id="add-sku-deliveryNote-input"
                            onChange={this.handleGeneralSelectedStartTimeChange}
                          />
</Col >
                              </Col>
                              <Col lg="6" className='d-flex gap-2'>
                                <Col lg='2'><p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    End Date
                                  </p></Col>
                                <Col lg='4'>
                                  <div
                                    style={{
                                      border: '1px solid gray',
                                      borderRadius: '5px',
                                      paddingTop: '2.5px',
                                      paddingBottom: '2.5px',
                                      paddingRight: '4px',
                                      paddingLeft: '4px',
                                    }}
                                  >
                                    
                                    <DatePickerComponent
                                      selectedDate={
                                        this.state.generalSelectedEndDate
                                      }
                                      handleSelectedDateChange={
                                        this.handleGeneralSelectedEndDateChange
                                      }
                                    />
  
  </div>
                                </Col >
<Col lg='4'>
  <Input
                            type="time"
                            value={this.state.generalSelectedEndTime}
                            id="add-sku-deliveryNote-input"
                            onChange={this.handleGeneralSelectedEndTimeChange}
                          />
</Col >
                              </Col>
                            </Row>

                          <Row>
                            <Col lg="12" className="my-3">
                              <h6 className="fw-bold">
                                {' '}
                                General Communication Type
                              </h6>

                              <Dropdown
                                isOpen={this.state.isOpen}
                                toggle={() =>
                                  this.setState({
                                    isOpen: !this.state.isOpen,
                                  })
                                }
                                className="mt-4 mt-sm-0"
                              >
                                <DropdownToggle
                                  className="d-flex justify-content-between"
                                  color="light"
                                  caret
                                  style={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #BDBDBD',
                                    borderRadius: '4px',
                                    width: '100%',
                                  }}
                                >
                                  {capitalizeFirstLetter(this.state.commType)}
                                  <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {this.state.communicationType.map(
                                    (ct, id) => (
                                      <DropdownItem
                                        key={id}
                                        onClick={(event) => {
                                          this.setState(
                                            {
                                              commType: ct.title,
                                              commCode: ct.code,
                                            },
                                            () => {}
                                          );
                                        }}
                                      >
                                        {ct.title}
                                      </DropdownItem>
                                    )
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>

                          <Row>
                            {this.props?.generalDraftData?.docKey &&
                            this.state.generalCampaignDocId ===
                              this.props.generalDraftData.docId ? (
                              <div className="py-2 d-flex gap-5">
                                <a
                                  href={this.props?.generalDraftData?.docKey}
                                  className="mt-2"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Proposal doc
                                </a>
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      generalCampaignDocId: '',
                                    })
                                  }
                                >
                                  Clear draft document
                                </Button>
                              </div>
                            ) : (
                              <Form>
                                <h6 className="fw-bold">
                                  Upload General Communication
                                  Proposal/Documents
                                </h6>
                                <Dropzone
                                  accept="image/*,.pdf"
                                  onDrop={(acceptedFiles) =>
                                    this.handleAcceptedFiles(acceptedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                      <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                        </div>
                                        <h4>Drag & Drop Your File Here</h4>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>

                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {this.state.selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + '-file'}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted fw-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {f.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    );
                                  })}
                                </div>
                              </Form>
                            )}
                          </Row>
                        </Form>
                      </TabPane>
                      <TabPane tabId={2}>
                        <Form>
                          <Col xl={12}>
                            <Card>
                              <CardBody>
                                <Nav
                                  tabs
                                  className="nav-tabs-custom nav-justified"
                                >
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: 'pointer' }}
                                      className={classnames({
                                        active:
                                          this.state.activeTabJustify === '5',
                                      })}
                                      onClick={() => {
                                        this.toggleCustomJustified('5');
                                      }}
                                    >
                                      <span className="d-none d-sm-block">
                                        App Banner
                                      </span>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: 'pointer' }}
                                      className={classnames({
                                        active:
                                          this.state.activeTabJustify === '6',
                                      })}
                                      onClick={() => {
                                        this.toggleCustomJustified('6');
                                      }}
                                    >
                                      <span className="d-none d-sm-block">
                                        Push Notifications
                                      </span>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: 'pointer' }}
                                      className={classnames({
                                        active:
                                          this.state.activeTabJustify === '7',
                                      })}
                                      onClick={() => {
                                        this.toggleCustomJustified('7');
                                      }}
                                    >
                                      <span className="d-none d-sm-block">
                                        Inbox
                                      </span>
                                    </NavLink>
                                  </NavItem>
                                </Nav>

                                <TabContent
                                  activeTab={this.state.activeTabJustify}
                                >
                                  <TabPane tabId="5" className="p-3">
                                    <Row>
                                      <Col sm="12">
                                        <CardText>
                                          <div className="border border-1 rounded p-4">
                                            {this.state.generalAppBannerUrl ? (
                                              <Row className="py-2 px-4">
                                                <img
                                                  className="py-2"
                                                  data-dz-thumbnail=""
                                                  // height="50%"
                                                  // width="50%"
                                                  // className="avatar-sm rounded bg-light"
                                                  alt={'app banner'}
                                                  src={
                                                    this.state
                                                      .generalAppBannerUrl
                                                  }
                                                />
                                                <Button
                                                  className="py-2"
                                                  onClick={() => {
                                                    this.setState({
                                                      generalAppBannerUrl: '',
                                                    });
                                                  }}
                                                >
                                                  Clear Draft banner
                                                </Button>
                                              </Row>
                                            ) : (
                                              <Row className="py-2 px-4">
                                                <Dropzone
                                                  accept="image/*"
                                                  onDrop={(acceptedFiles) =>
                                                    this.handleAcceptedFilesBanner(
                                                      acceptedFiles
                                                    )
                                                  }
                                                >
                                                  {({
                                                    getRootProps,
                                                    getInputProps,
                                                  }) => (
                                                    <div className="dropzone">
                                                      <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                      >
                                                        <input
                                                          {...getInputProps()}
                                                        />
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>Upload banner</h4>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Dropzone>
                                                <div
                                                  className="dropzone-previews mt-3"
                                                  id="file-previews"
                                                >
                                                  {this.state.selectedFilesBanner.map(
                                                    (f, i) => {
                                                      return (
                                                        <Card
                                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                          key={i + '-file'}
                                                        >
                                                          <div className="p-2">
                                                            <Row className="align-items-center">
                                                              <Col className="col-auto">
                                                                <img
                                                                  data-dz-thumbnail=""
                                                                  height="80"
                                                                  className="avatar-sm rounded bg-light"
                                                                  alt={f.name}
                                                                  src={
                                                                    f.preview
                                                                  }
                                                                />
                                                              </Col>
                                                              <Col>
                                                                <Link
                                                                  to="#"
                                                                  className="text-muted fw-bold"
                                                                >
                                                                  {f.name}
                                                                </Link>
                                                                <p className="mb-0">
                                                                  <strong>
                                                                    {
                                                                      f.formattedSize
                                                                    }
                                                                  </strong>
                                                                </p>
                                                              </Col>
                                                            </Row>
                                                          </div>
                                                        </Card>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </Row>
                                            )}

                                            <Row className="py-2">
                                              <span>
                                                <h6 className="fw-bold">
                                                  {' '}
                                                  Link Type
                                                </h6>
                                              </span>
                                              <span>
                                                <Col>
                                                  <Dropdown
                                                    isOpen={
                                                      this.state
                                                        .generalBannerLinkTypeMenu
                                                    }
                                                    toggle={() =>
                                                      this.setState({
                                                        generalBannerLinkTypeMenu:
                                                          !this.state
                                                            .generalBannerLinkTypeMenu,
                                                      })
                                                    }
                                                    className="mt-4 mt-sm-0"
                                                  >
                                                    <DropdownToggle
                                                      className="d-flex justify-content-between"
                                                      color="light"
                                                      caret
                                                      style={{
                                                        backgroundColor: '#fff',
                                                        border:
                                                          '1px solid #BDBDBD',
                                                        borderRadius: '4px',
                                                        width: '100%',
                                                      }}
                                                    >
                                                      {this.state
                                                        .generalBannerLinkType ===
                                                      1
                                                        ? 'Internal'
                                                        : this.state
                                                            .generalBannerLinkType ===
                                                          2
                                                        ? 'External'
                                                        : 'Select Link Type'}
                                                      <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                      <DropdownItem
                                                        onClick={(event) =>
                                                          this.setState({
                                                            generalBannerLinkType: 1,
                                                          })
                                                        }
                                                      >
                                                        Internal
                                                      </DropdownItem>
                                                      <DropdownItem
                                                        onClick={(event) =>
                                                          this.setState({
                                                            generalBannerLinkType: 2,
                                                          })
                                                        }
                                                      >
                                                        External
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </Dropdown>
                                                </Col>
                                              </span>
                                            </Row>
                                            <Row className="py-2">
                                              <span>
                                                <h6 className="fw-bold">
                                                  {' '}
                                                  Target Link
                                                </h6>
                                              </span>
                                              <span>
                                                <Col>
                                                  <Col>
                                                    {this.state
                                                      .generalBannerLinkType ===
                                                    1 ? (
                                                      <Dropdown
                                                        isOpen={
                                                          this.state
                                                            .generalBannerInternalLinkTypeMenu
                                                        }
                                                        toggle={() =>
                                                          this.setState({
                                                            generalBannerInternalLinkTypeMenu:
                                                              !this.state
                                                                .generalBannerInternalLinkTypeMenu,
                                                          })
                                                        }
                                                        className="mt-4 mt-sm-0"
                                                      >
                                                        <DropdownToggle
                                                          className="d-flex justify-content-between"
                                                          color="light"
                                                          caret
                                                          style={{
                                                            backgroundColor:
                                                              '#fff',
                                                            border:
                                                              '1px solid #BDBDBD',
                                                            borderRadius: '4px',
                                                            width: '100%',
                                                          }}
                                                        >
                                                          {
                                                            this.state
                                                              .generalAppBannerTargetLink
                                                          }
                                                          <i className="mdi mdi-chevron-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                          {this.state.bannerInternalLinkList.map(
                                                            (links) => (
                                                              <DropdownItem
                                                                onClick={(
                                                                  event
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      generalAppBannerTargetLink:
                                                                        links,
                                                                    }
                                                                  )
                                                                }
                                                              >
                                                                {links}
                                                              </DropdownItem>
                                                            )
                                                          )}
                                                        </DropdownMenu>
                                                      </Dropdown>
                                                    ) : (
                                                      <div>
                                                        <Input
                                                          type="text"
                                                          value={
                                                            this.state
                                                              .generalAppBannerTargetLink
                                                          }
                                                          placeholder="Please input App Banner Target Link"
                                                          id="gold-price-input"
                                                          onChange={
                                                            this
                                                              .handleGeneralAppBannerTargetLinkChange
                                                          }
                                                        />
                                                        <p className="py-2">
                                                          *External link example
                                                          format :
                                                          https://www.goldkinen.com
                                                        </p>
                                                      </div>
                                                    )}
                                                  </Col>
                                                </Col>
                                              </span>
                                            </Row>
                                          </div>
                                        </CardText>
                                      </Col>
                                    </Row>
                                  </TabPane>
                                  <TabPane tabId="6" className="p-3">
                                    <Row>
                                      <Col sm="12">
                                        <CardText>
                                          <div className="border border-1 rounded p-4">
                                            <div className="py-4">
                                              <div className="pb-2">
                                                <h5 className="card-title">
                                                  Title
                                                </h5>
                                                <Input
                                                  type="text"
                                                  value={
                                                    this.state
                                                      .generalPushNotificationTitle
                                                  }
                                                  placeholder="PLease input Push Notification Title"
                                                  onChange={
                                                    this
                                                      .handleGeneralPushNotificationTitleChange
                                                  }
                                                />
                                              </div>
                                              <div className="pb-2">
                                                <h5 className="card-title">
                                                  Description
                                                </h5>
                                                <div>
                                                  <TextField
                                                    className="w-full"
                                                    variant="outlined"
                                                    type="text"
                                                    multiline
                                                    minRows={2}
                                                    style={{
                                                      width: '100%',
                                                    }}
                                                    placeholder="Please input Push Notification Description"
                                                    value={
                                                      this.state
                                                        .generalPushNotificationDescription
                                                    }
                                                    onChange={
                                                      this
                                                        .handleGeneralPushNotificationDescriptionChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </CardText>
                                      </Col>
                                    </Row>
                                  </TabPane>
                                  <TabPane tabId="7" className="p-3">
                                    <Row>
                                      <Col sm="12">
                                        <CardText>
                                          <div className="border border-1 rounded p-4">
                                            <div className="py-4">
                                              <div className="pb-2">
                                                <h5 className="card-title">
                                                  Description
                                                </h5>
                                                <div>
                                                  <TextField
                                                    className="w-full"
                                                    variant="outlined"
                                                    type="text"
                                                    multiline
                                                    minRows={2}
                                                    style={{
                                                      width: '100%',
                                                    }}
                                                    placeholder="Please input Inbox Description"
                                                    value={
                                                      this.state
                                                        .generalInboxDescription
                                                    }
                                                    onChange={
                                                      this
                                                        .handleGeneralInboxDescriptionChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </CardText>
                                      </Col>
                                    </Row>
                                  </TabPane>
                                </TabContent>
                              </CardBody>
                            </Card>
                          </Col>
                          <Row className="py-2">
                            <h6 className="fw-bold">Campaign Audience</h6>
                            {this.state.audienceType.map((at, id) => (
                              <Col xl={4}>
                                <div
                                  className={`border border-1 px-4 py-1  rounded d-flex ${
                                    this.state.radio1General === at.title
                                      ? 'twitter-bs-wizard-border-radio'
                                      : ''
                                  }`}
                                >
                                  <div className="mt-3">
                                    <label className='d-flex gap-2 px-4'>
                                      <input
                                        type="radio"
                                        value={at.title}
                                        checked={
                                          this.state.radio1General === at.title
                                        }
                                        onChange={(e) => {
                                          this.onRadioChange1General(e);
                                          this.setState({
                                            audTypeCode: at.code,
                                          });
                                        }}
                                      />
                                    <p className="px-4">{at.title}</p>
                                    </label >
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                          {this.state.radio1General === 'Custom Users' && (
                            <div className="border border-1 rounded p-4">
                              <Row className="py-2">
                                <h6 className="fw-bold">
                                  Preset Audience Name
                                </h6>
                                <Dropdown
                                  isOpen={this.state.isOpen2}
                                  toggle={() =>
                                    this.setState({
                                      isOpen2: !this.state.isOpen2,
                                    })
                                  }
                                  className="mt-4 mt-sm-0"
                                >
                                  <DropdownToggle
                                    className="d-flex justify-content-between"
                                    color="light"
                                    caret
                                    style={{
                                      backgroundColor: '#fff',
                                      border: '1px solid #f1f1f1',
                                      borderRadius: '4px',
                                      width: '100%',
                                    }}
                                  >
                                    {this.state.presetName}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {this.state.downloadAudienceData.map(
                                      (dad, id) => (
                                        <DropdownItem
                                          key={id}
                                          onClick={(event) =>
                                            this.setState(
                                              {
                                                presetId: dad.id,
                                                presetName: dad.name,
                                                presetData: JSON.parse(
                                                  dad.jsonData
                                                ),
                                              },
                                              () => {}
                                            )
                                          }
                                        >
                                          {dad.name}
                                        </DropdownItem>
                                      )
                                    )}
                                  </DropdownMenu>
                                </Dropdown>

                                <div className="my-3">
                                  <h6>Preset Details</h6>

                                  {this.state.presetData.map((pd) => (
                                    <Row>
                                      <Col xl={3}>{pd.displayName}</Col>
                                      <Col xl={7}>
                                        {pd.operator === 'range' ? (
                                          <div className="d-flex gap-2">
                                            <p>From</p>
                                            <p>{pd.data.from}</p>
                                            <p>To</p>
                                            <p>{pd.data.to}</p>
                                          </div>
                                        ) : pd.operator === 'equal' ? (
                                          <div className="d-flex gap-2">
                                            <p>=</p>
                                            <p>{pd.data.value}</p>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </Col>
                                    </Row>
                                  ))}
                                </div>
                              </Row>
                            </div>
                          )}
                        </Form>
                      </TabPane>
                    </TabContent>
                    <div className="twitter-bs-wizard-pager-link">
                      <div className="d-flex justify-content-between">
                        <button
                          className="border-0"
                          style={{
                            color: '#FF0000',
                            backgroundColor: '#fff',
                          }}
                          onClick={() => {
                            this.props.modalClose();
                          }}
                        >
                          <h6
                            className="fw-bold"
                            style={{
                              color: '#FF0000',
                              backgroundColor: '#fff',
                            }}
                          >
                            Discard
                          </h6>
                        </button>
                        <div className="d-flex px-4 border-0">
                          <button
                            className="border-0 fw-bold rounded px-2"
                            onClick={(e) => {
                              this.handleGeneralCommunicationDraftSave(e);
                              this.props.modalClose();
                            }}
                            disabled={
                              this.state.generalCommunicationTitle !== ''
                                ? false
                                : true
                            }
                          >
                            <h6 className="fw-bold mt-2">Update draft</h6>
                          </button>
                          <div className="px-2">
                            <li
                              className={`${
                                this.state.activeTab === 4
                                  ? 'next disabled'
                                  : 'next'
                              } `}
                            >
                              {this.state.activeTab !== 2 && (
                                <Button
                                  onClick={() => {
                                    this.toggleTab(this.state.activeTab + 1);
                                  }}
                                  className="fw-bold px-5"
                                  style={{
                                    color: '#081939',
                                    backgroundColor: '#c2b56f',
                                  }}
                                  disabled={
                                    this.state.generalCommunicationTitle !==
                                      '' &&
                                    (this.state.selectedFiles.length > 0 ||
                                      this.state.generalCampaignDocId)
                                      ? false
                                      : true
                                  }
                                >
                                  <h6 className="fw-bold mt-2">
                                    {this.state.generalCommunicationTitle !==
                                      '' &&
                                    (this.state.selectedFiles.length > 0 ||
                                      this.state.generalCampaignDocId)
                                      ? 'Next'
                                      : 'Communication must have a title and proposal doc'}
                                  </h6>
                                </Button>
                              )}
                              {this.state.activeTab === 2 && (
                                <Button
                                  style={{
                                    color: '#081939',
                                    backgroundColor: '#c2b56f',
                                  }}
                                  onClick={(e) => {
                                    this.handleGeneralCommunicationSubmit(e);
                                    this.props.modalClose();
                                  }}
                                  disabled={
                                    this.state.generalCommunicationTitle !==
                                      '' &&
                                    (this.state.selectedFiles.length > 0 ||
                                      this.state.generalCampaignDocId)
                                      ? false
                                      : true
                                  }
                                >
                                  <h6 className="fw-bold mt-2">
                                    {this.state.generalCommunicationTitle !==
                                      '' &&
                                    (this.state.selectedFiles.length > 0 ||
                                      this.state.generalCampaignDocId)
                                      ? 'Submit'
                                      : 'Communication must have a title and proposal doc'}
                                  </h6>
                                </Button>
                              )}
                            </li>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default EditDraftGeneral;

import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';

//Simple bar
import SimpleBar from 'simplebar-react';
import { getToken, GKApi } from '../../../components/config';

class Top5Collect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      collectData: [],
    };
  }

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();
    const response = await GKApi.get('/finance/top-collect', {
      headers: {
        Authorization: token,
        cmpId: 8,
      },
    });

    this.setState({
      collectData: response.data,
    });
  }

  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();
    };
    return (
      <React.Fragment>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Top 5 Collect activity</h4>

              <SimpleBar style={{ maxHeight: '330px' }}>
                <ul className="list-unstyled activity-wid">
                  <ul className="list-unstyled activity-wid">
                    {this.state.collectData.map((collect, index) => (
                      <li className="activity-list" key={index}>
                        <div className="activity-icon avatar-xs">
                          <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                            <i className="ri-user-2-fill"></i>
                          </span>
                        </div>
                        <div>
                          <div>
                            <h5 className="font-size-13">
                              {collect.name} ({collect.phone})
                            </h5>
                          </div>
                          <div>
                            <p className="text-muted mb-0">
                              Collected{' '}
                              {(
                                collect.totalAmountInGM
                              ).toFixedDown(4)}{' '}
                              gram through {collect.trxCount} transactions
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </ul>
              </SimpleBar>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default Top5Collect;

import React, { Component } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import Uploader from './uploader';
import { getGroupName, getToken, GKApi } from '../../../components/config';

export default class ManualDisbursement extends Component {
  constructor() {
    super();
    this.state = {
      resultFromUpload: [],
      sumFromFileRead: 0,
      totalCountFromFileRead: 0,
      sumFromFileUpload: 0,
      totalCountFromFileUpload: 0,
      deviceInfo: {
        country_code: 'BD',
        country_name: 'Bangladesh',
        city: 'Dhaka',
        postal: '1000',
        latitude: 23.729,
        longitude: 90.4112,
        IPv4: '198.188.191.116',
        state: 'Dhaka',
      },
      loading: false,
      canViewManualDisbursement: 0,
      isFin: false,
      isSuper: false,
    };
  }

  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    const token = getToken();
    // const gId = getGroupId();

    // getting permission data from api
    const getPermission = await GKApi.get(`/role/permission?cmpId=16`, {
      headers: {
        Authorization: token,
      },
    });

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    // setting permissions to their states of their own

    this.setState({
      canViewManualDisbursement: permissionData[16].canView,
    });
  }
  //hotfix: hardcoded device info as the api was not working atm
  handleSubmit = async (event) => {
    event.preventDefault();
    // const res = deviceInfo();
    this.setState({
      deviceInfo: {
        country_code: 'BD',
        country_name: 'Bangladesh',
        city: 'Dhaka',
        postal: '1000',
        latitude: 23.729,
        longitude: 90.4112,
        IPv4: '198.188.191.116',
        state: 'Dhaka',
      },
    });
    const token = getToken();
    this.setState({ loading: true });
    const submit = await GKApi.post(
      `/finance/dispute`,
      {
        cmpId: 20,
        deviceIP: this.state.deviceInfo.IPv4,
        deviceInfo: this.state.deviceInfo,
        disputes: this.state.resultFromUpload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (submit) {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <div className="page-content" id="20">
        <Container fluid>
          {this.state.isFin === true || this.state.isSuper === true ? (
            <Row>
              {this.state.canViewManualDisbursement === 1 ? (
                <Col xl={12}>
                  <Uploader
                    onDrop={(result) => {
                      this.setState({
                        resultFromUpload: result,
                      });
                      // result.splice(-1, 1);

                      const resultLength = result.splice(-1, 1);
                      const sum = result.reduce((accumulator, object) => {
                        return (
                          accumulator + parseFloat(Object.values(object).at(-7))
                        );
                      }, 0);

                      const dash = resultLength.reduce((accumulator) => {
                        return parseInt(Object.keys(accumulator)[0]);
                      });

                      const firstValue = parseFloat(Object.values(dash)[0]);
                      const lastValue = parseFloat(Object.values(dash).at(-7));
                      this.setState({
                        ...this.state,
                        sumFromFileRead: sum,
                        totalCountFromFileRead: result.length,
                        sumFromFileUpload: lastValue,
                        totalCountFromFileUpload: firstValue,
                      });
                    }}
                  />
                  <div className="mb-5">
                    {this.state.sumFromFileRead ===
                      this.state.sumFromFileUpload &&
                    this.state.totalCountFromFileRead ===
                      this.state.totalCountFromFileUpload &&
                    this.state.sumFromFileRead !== 0 &&
                    this.state.sumFromFileUpload !== 0 &&
                    this.state.totalCountFromFileRead !== 0 &&
                    this.state.totalCountFromFileUpload !== 0 ? (
                      <div style={{ textAlign: 'center' }}>
                        <p>
                          File successfully uploaded and read. Click on the
                          button to proceed with disbursement.
                        </p>
                        <Button
                          color="primary"
                          className="mb-10"
                          onClick={this.handleSubmit}
                        >
                          {this.state.loading === true
                            ? 'Proceeding with disbursement, Please wait for a while'
                            : 'Proceed with disbursement'}
                        </Button>
                      </div>
                    ) : this.state.sumFromFileRead === 0 &&
                      this.state.sumFromFileUpload === 0 &&
                      this.state.totalCountFromFileRead === 0 &&
                      this.state.totalCountFromFileUpload === 0 ? (
                      <p style={{ textAlign: 'center' }}>
                        Please upload a file to proceed with disbursement
                      </p>
                    ) : (
                      <p style={{ textAlign: 'center' }}>
                        File is incomplete!!!
                      </p>
                    )}
                  </div>
                </Col>
              ) : (
                <h4 className="text-center">Sorry you don't have permission</h4>
              )}
            </Row>
          ) : (
            <h5>Sorry you don't have permission to view this.</h5>
          )}
        </Container>
      </div>
    );
  }
}

import moment from 'moment';
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getToken, GKApi } from '../../../components/config';
import MarketingCalendar from './MarketingCalendar';

class TodayDateAndUpcomingEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_center: false,
      todayDate: new Date(),
      availableEvents: [],
    };

    this.tog_center = this.tog_center.bind(this);
  }

  tog_center() {
    this.setState((prevState) => ({
      modal_center: !prevState.modal_center,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  async componentDidMount() {
    const date = new Date();
    const token = getToken();
    const month = moment(date).format('MM');
    const year = moment(date).format('YYYY');
    const response = await GKApi.get(
      `/marketing/upcoming-events?year=${year}&month=${month}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    response.data?.forEach((e) => {
      e.start = moment(e.startDate).format('YYYY-MM-DD');
      delete e.startDate;
      e.end = moment(e.endDate).format('YYYY-MM-DD');
      delete e.endDate;
      e.title = e.code;
      delete e.code;
      delete e.maxDiscountLimit;
      delete e.discountType;
      delete e.discountValue;
      delete e.minTrxAmount;
      delete e.trxType;
      delete e.type;
    });
    //
    this.setState({ availableEvents: response.data }, () => {});
  }
  render() {
    return (
      <React.Fragment>
        <button
          style={{
            backgroundColor: '#081939',
            color: '#ffff',
            height: '140px',
          }}
          type="button"
          onClick={this.tog_center}
          className="border-0 rounded shadow-lg p-4"
        >
          <div className="d-flex ">
            <div className="flex-1">
              <div>
                <h4
                  className="text-center font-size-20 mb-1 fw-bold"
                  style={{ color: '#ffff' }}
                >
                  {moment(this.state.todayDate).format('DD')}
                </h4>
                <p className="text-center">
                  {' '}
                  {moment(this.state.todayDate).format('MMM').toUpperCase()}
                </p>
              </div>
              <h4
                className="d-flex justify-content-center mb-1 font-size-12"
                style={{ color: '#CEC382' }}
              >
                Click to see upcoming events
              </h4>
            </div>
          </div>
        </button>

        <Modal
          isOpen={this.state.modal_center}
          toggle={this.tog_center}
          centered={true}
        >
          <ModalHeader toggle={() => this.setState({ modal_center: false })}>
            Campaign Calendar
          </ModalHeader>
          <ModalBody>
            <MarketingCalendar event={this.state.availableEvents} />
          </ModalBody>
        </Modal>

        
      </React.Fragment>
    );
  }
}

export default TodayDateAndUpcomingEvent;

import React, { Component } from 'react';

import { Row, Col } from 'reactstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';


import '@fullcalendar/bootstrap/main.css';

import DeleteModal from './../../Calendar/DeleteModal';


class MarketingCalender extends Component {
  constructor(props) {
    super(props);

    this.calendarComponentRef = React.createRef();

    this.state = {
      breadcrumbItems: [
        { title: 'Nazox', link: '/' },
        { title: 'Calendar', link: '#' },
      ],
      calendarWeekends: true,
      modal: false,
      modalcategory: false,
      isDragBind: false,
      deleteModal: false,
      event: {},
      availableEvents: [],
    };

    this.toggle = this.toggle.bind(this);
  }

  // componentDidMount = async () => {
  //   const date = new Date();
  //   const token = getToken();
  //   const month = moment(date).format('MM');
  //   const year = moment(date).format('YYYY');
  //   const response = await GKApi.get(
  //     `/marketing/upcoming-events?year=${year}&month=${month}`,
  //     {
  //       headers: {
  //         Authorization: token,
  //       },
  //     }
  //   );

  //   response.data.forEach((e) => {
  //     e.start = e.startDate;
  //     delete e.startDate;
  //     e.end = e.endDate;
  //     delete e.endDate;
  //   });
  //   //
  //   this.setState({ availableEvents: response.data }, () => {});
  //   // new Draggable(document.getElementById('external-events'), {
  //   //   itemSelector: '.external-event',
  //   // });
  // };

  // eslint-disable-next-line no-unused-vars

  /**
   * Handling the modal state
   */
  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { deleteModal } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteEvent}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />

        <Row>
          <Col xl={12}>
            {/* fullcalendar control */}
            <FullCalendar
              defaultView="dayGridMonth"
              
              plugins={[dayGridPlugin]}
              events={this.props.event}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default MarketingCalender;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
import classnames from 'classnames';
import * as XLSX from 'xlsx';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from 'reactstrap';
import { getGroupName, getToken, GKApi } from '../../../components/config';
import moment from 'moment';
import DatePickerComponent from '../../../components/Common/DatePicker';
import { CircularProgress } from '@material-ui/core';
import { deviceInfo } from '../../../helpers/device_info_helper';

class ManualRefund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceInfo: {},
      pendingRefundData: [],
      failedRefundData: [],
      successfulRefundData: [],
      isFin: false,
      isSuper: false,
      isCRM: false,
      reqStatus: '',
      canAuth: 0,
      activeTabJustify: '1',
      requestData: {},
      authModal: false,
      initiateModal: false,
      authModalFailed: false,
      initiateModalFailed: false,
      rejectModal: false,
      canInitiate: 0,
      canView: 0,
      daysFromRequest: 0,
      approvedDeliveryDetails: false,
      searchLoading: false,
      searchStartDate: null,
      searchEndDate: null,
      pageNumber: 1,
      totalPageCount: 0,
      searchKey: '',
      isLoading: false,
      statusChangeConfirmation: false,
      rejectPending: false,
      rejectFailed: false,
      reason: '',
    };
  }
  //modal closing options
  tog_initiateModal = () => {
    this.setState((prevState) => ({
      initiateModal: !prevState.initiateModal,
    }));
    this.removeBodyCss();
  };

  tog_authModal = () => {
    this.setState((prevState) => ({
      authModal: !prevState.authModal,
    }));
    this.removeBodyCss();
  };

  tog_rejectPendingModal = () => {
    this.setState((prevState) => ({
      rejectPending: !prevState.rejectPending,
    }));
    this.removeBodyCss();
  };

  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  async componentDidMount() {
    // GET request using axios with async/await
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'FIN') {
        this.setState({
          isFin: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      } else if (name === 'CRM') {
        this.setState({
          isCRM: true,
        });
      }
    });
    const token = getToken(); // getting token for api call
    this.setState({ isLoading: true });
    try {
      // the api call
      const response = await GKApi.get(
        '/finance/refund?refStatus=Pending&pageNumber=1&pageSize=10',
        {
          headers: {
            Authorization: token,
            cmpId: 173,
          },
        }
      );

      this.setState({
        pendingRefundData: response.data.data,
        totalPageCount: response.data.paginationInfo.totalPage,
        pageNumber: response.data.paginationInfo.pageNumber,
      });
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }

    const getPermission = await GKApi.get(`/role/permission?cmpId=173`, {
      headers: {
        Authorization: token,
      },
    });

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    this.setState({
      canView: permissionData[173].canView,
      canInitiate: permissionData[173].canInsert,
      canAuth: permissionData[173].canAuth,
    });
    const res = deviceInfo();
    this.setState({ deviceInfo: res }, () => {});
  }

  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };

  handleSearchKeyChange = (event) => {
    this.setState({ searchKey: event.target.value });
  };

  handleSearchStartDate = (event) => {
    this.setState(
      {
        ...this.state,
        searchStartDate: event,
      },
      () => {}
    );
  };
  handleSearchEndDate = (event) => {
    this.setState(
      {
        ...this.state,
        searchEndDate: event,
      },
      () => {}
    );
  };
  handleReasonChange = (event) => {
    this.setState({ ...this.state, reason: event.target.value });
  };
  render() {
    // eslint-disable-next-line no-extend-native
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();
    };
    const pendingColumns = [
      {
        field: 'customerPhone',
        title: 'Customer Phone',
      },
      {
        field: 'customername',
        title: 'Customer Name',
      },

      {
        field: 'refReqDate',
        title: 'Request Date',
      },

      {
        field: 'refundGold(gm)',
        title: 'Refund Gold Amount (GM)',
        render: (row) => <div>{row['refundGold(gm)'].toFixedDown(4)}</div>,
      },
      {
        field: 'netRefAmount',
        title: 'Net Refund Amount (BDT)',
        render: (row) => <div>{row.netRefAmount.toFixedDown(2)}</div>,
      },
      {
        field: 'refMedium',
        title: 'Refund Medium',
      },
      {
        field: 'refundType',
        title: 'Refund Type',
      },
      {
        field: 'disStatus',
        title: 'Refund Status',
        render: (row) => (
          <div>{row.disStatus ? row.disStatus : 'Not Initiated'}</div>
        ),
      },
    ];

    const approvedColumns = [
      {
        field: 'customerPhone',
        title: 'Customer Phone',
      },
      {
        field: 'customername',
        title: 'Customer Name',
      },

      {
        field: 'refReqDate',
        title: 'Request Date',
      },
      {
        field: 'disbursementDate',
        title: 'Refund Date',
        render: (row) => (
          <div>
            {row.disbursementDate ? row.disbursementDate : 'Not available'}
          </div>
        ),
      },
      {
        field: 'refundGold(gm)',
        title: 'Refund Gold Amount (GM)',
        render: (row) => <div>{row['refundGold(gm)'].toFixedDown(4)}</div>,
      },
      {
        field: 'netRefAmount',
        title: 'Net Refund Amount (BDT)',
        render: (row) => <div>{row.netRefAmount.toFixedDown(2)}</div>,
      },
      {
        field: 'refMedium',
        title: 'Refund Medium',
      },
    ];

    const downloadExcelPending = async () => {
      const token = getToken();
      const response = await GKApi.get(
        '/finance/refund?refStatus=Pending&pageNumber=1&pageSize=10000',
        {
          headers: {
            Authorization: token,
            cmpId: 173,
          },
        }
      );
      const newData = response.data.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Pending Refunds');
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(workBook, `Pending Refund List.xlsx`);
    };

    const downloadExcelSuccess = async () => {
      const token = getToken();
      const response = await GKApi.get(
        '/finance/refund?refStatus=Refunded&pageNumber=1&pageSize=10000',
        {
          headers: {
            Authorization: token,
            cmpId: 173,
          },
        }
      );
      const newData = response.data.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Successful Refunds');
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(workBook, `Successful Refund List.xlsx`);
    };
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isSuper === true ||
          this.state.isFin === true ||
          this.state.isCRM === true ? (
            <div className="container-fluid">
              <Row>
                <Col xl={12}>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: this.state.activeTabJustify === '1',
                        })}
                        onClick={async () => {
                          this.toggleCustomJustified('1');
                          const response = await GKApi.get(
                            '/finance/refund?refStatus=Pending&pageNumber=1&pageSize=10',
                            {
                              headers: {
                                Authorization: getToken(),
                                cmpId: 173,
                              },
                            }
                          );

                          this.setState({
                            pendingRefundData: response.data.data,
                            totalPageCount:
                              response.data.paginationInfo.totalPage,
                            pageNumber: response.data.paginationInfo.pageNumber,
                          });
                        }}
                      >
                        <span className="d-none d-sm-block fw-bold">
                          Pending Refund
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: this.state.activeTabJustify === '2',
                        })}
                        onClick={async () => {
                          this.toggleCustomJustified('2');
                          const response = await GKApi.get(
                            '/finance/refund?refStatus=Refunded&pageNumber=1&pageSize=10',
                            {
                              headers: {
                                Authorization: getToken(),
                                cmpId: 173,
                              },
                            }
                          );

                          this.setState({
                            successfulRefundData: response.data.data,
                            totalPageCount:
                              response.data.paginationInfo.totalPage,
                            pageNumber: response.data.paginationInfo.pageNumber,
                          });
                        }}
                      >
                        <span className="d-none d-sm-block fw-bold">
                          Successful Refund
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTabJustify}>
                    <TabPane tabId="1">
                      <div>
                        <div className="d-flex gap-3">
                          <div className="d-flex gap-3 align-items-center mx-3 my-3">
                            <h5>Phone:</h5>
                            <Input
                              type="text"
                              value={this.state.searchKey}
                              id="example-name-input"
                              onChange={this.handleSearchKeyChange}
                            />
                          </div>
                          {/* <p className='mx-3'>Search based on joining date</p> */}
                          <div className="d-flex gap-3 align-items-center mx-3 my-3">
                            <h5>Start Date:</h5>
                            <div
                              style={{
                                border: '1px solid gray',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                              }}
                            >
                              <DatePickerComponent
                                selectedDate={this.state.searchStartDate}
                                handleSelectedDateChange={
                                  this.handleSearchStartDate
                                }
                              />
                            </div>

                            <h5>End Date:</h5>
                            <div
                              style={{
                                border: '1px solid gray',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                              }}
                            >
                              <DatePickerComponent
                                selectedDate={this.state.searchEndDate}
                                handleSelectedDateChange={
                                  this.handleSearchEndDate
                                }
                              />
                            </div>

                            {((this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null) ||
                              this.state.searchKey !== '') && (
                              <div className="d-flex align-items-center gap-3">
                                <Button
                                  onClick={async () => {
                                    this.setState({ searchLoading: true });
                                    const token = getToken();
                                    const response = await GKApi.get(
                                      `/finance/refund?refStatus=Pending&pageNumber=1&pageSize=10${
                                        this.state.searchKey !== ''
                                          ? `&phone=${this.state.searchKey}`
                                          : ``
                                      }${
                                        this.state.searchStartDate !== null &&
                                        this.state.searchEndDate !== null
                                          ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                          : ''
                                      }`,
                                      {
                                        headers: {
                                          Authorization: token,
                                          cmpId: 173,
                                        },
                                      }
                                    );
                                    this.setState(
                                      {
                                        pendingRefundData: response.data.data,
                                        totalPageCount:
                                          response.data.paginationInfo
                                            .totalPage,
                                        pageNumber:
                                          response.data.paginationInfo
                                            .pageNumber,
                                      },
                                      () => {}
                                    );
                                    if (response) {
                                      this.setState({ searchLoading: false });
                                    }
                                  }}
                                >
                                  {this.state.searchLoading
                                    ? 'Searching'
                                    : 'Search'}
                                </Button>
                                <Button
                                  onClick={async () => {
                                    // eslint-disable-next-line
                                    this.state.searchKey = '';
                                    this.setState({ clearLoading: true });
                                    const token = getToken();
                                    const response = await GKApi.get(
                                      `/finance/refund?refStatus=Pending&pageNumber=1&pageSize=10`,
                                      {
                                        headers: {
                                          Authorization: token,
                                          cmpId: 173,
                                        },
                                      }
                                    );
                                    this.setState(
                                      {
                                        pendingRefundData: response.data.data,

                                        totalPageCount:
                                          response.data.paginationInfo
                                            .totalPage,
                                        pageNumber:
                                          response.data.paginationInfo
                                            .pageNumber,
                                        searchStartDate: null,
                                        searchEndDate: null,
                                      },
                                      () => {}
                                    );
                                    if (response) {
                                      this.setState({ clearLoading: false });
                                    }
                                  }}
                                >
                                  {this.state.clearLoading
                                    ? 'Clearing'
                                    : 'Clear'}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                        <MaterialTable
                          title={'List of Pending Refunds'}
                          columns={pendingColumns}
                          data={this.state.pendingRefundData}
                          actions={[
                            this.state.canView === 1 && {
                              icon: () => <Button>Export All</Button>, // you can pass icon too
                              tooltip: 'Export to Excel',
                              onClick: () => downloadExcelPending(),
                              isFreeAction: true,
                            },

                            (rowData) =>
                              this.state.canInitiate === 1 && {
                                icon: () => (
                                  <i className="fas fa-file-invoice-dollar" />
                                ),
                                tooltip: 'Initiate Refund',
                                onClick: async () => {
                                  this.setState({
                                    initiateModal: true,
                                    key: rowData,
                                  });
                                },
                                hidden: rowData.disStatus === 'Pending',
                              },
                            (rowData) =>
                              this.state.canAuth === 1 && {
                                icon: () => (
                                  <i className="fas fa-clipboard-check" />
                                ),
                                tooltip: 'Authorize Refund',
                                onClick: async () => {
                                  this.setState({
                                    authModal: true,
                                    key: rowData,
                                  });
                                },
                                hidden:
                                  rowData.disStatus !== 'Pending' ||
                                  rowData.refundType === 'Auto' ||
                                  rowData.authorizerStaffID,
                              },
                          ]}
                          options={{
                            //cell style for the table
                            cellStyle: {
                              borderRight: '1px solid #eee',
                              textAlign: 'left',
                              // whiteSpace:'nowrap'
                            },

                            //style for the header
                            search: false,
                            paging: false,
                            headerStyle: {
                              position: 'sticky',
                              textAlign: 'left',
                              top: 0,
                              whiteSpace: 'nowrap',
                            },
                            actionsColumnIndex: -1,
                            exportButton: { csv: true, pdf: false },
                            exportFileName: `Pending Refund List Page ${this.state.pageNumber}`,
                            pageSize: 10,
                            columnsButton: true,
                            rowStyle: (rowData) => {
                              const reqDate = moment(rowData.trxDate);
                              const currentDate = moment(new Date());

                              const diff =
                                currentDate.diff(reqDate, 'hours') / 24;

                              return {
                                backgroundColor: rowData.authorizerStaffID
                                  ? 'aliceblue'
                                  : rowData.disStatus === 'Pending'
                                  ? 'lavender'
                                  : 'beige',
                                color: diff > 5 && 'red',
                              };
                            },
                          }}
                          localization={{
                            toolbar: {
                              exportCSVName: 'Export as CSV',
                            },
                          }}
                          detailPanel={(rowData) => {
                            return (
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Transaction Reference Number
                                      </p>
                                      <p>{rowData.trxRefNo}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">Sell ID</p>
                                      <p>{rowData.refReqID}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">Per Unit Price</p>
                                      <p>BDT {rowData.perUnitPrice}</p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <p className="fw-bold">Refund Fee Rate</p>
                                      <p>{rowData.refundFeeRate}%</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">Refund Fee </p>
                                      <p>BDT {rowData.refFee}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Principal Amount
                                      </p>
                                      <p>BDT {rowData.principalAmount}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Net Refund Amount
                                      </p>
                                      <p>BDT {rowData.netRefAmount}</p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bkash Account Name
                                      </p>
                                      <p>{rowData.bKashAccName}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bkash Account Number
                                      </p>
                                      <p>{rowData.bKashNumber}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bank Account Name
                                      </p>
                                      <p>{rowData.bankAccName}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bank Account Number
                                      </p>
                                      <p>{rowData.bankAccNo}</p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <p className="fw-bold">Bank Name</p>
                                      <p>{rowData.bankName}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bank Branch Name
                                      </p>
                                      <p>{rowData.bankBranch}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bank District Name
                                      </p>
                                      <p>{rowData.bankDistrict}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bank Routing Number
                                      </p>
                                      <p>{rowData.bankRoutingNo}</p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <p className="fw-bold">Initiator</p>
                                      <p>{rowData.entry_staff_email}</p>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            );
                          }}
                          onRowClick={(event, rowData, togglePanel) =>
                            togglePanel()
                          }
                        />
                      </div>
                    </TabPane>

                    <TabPane tabId="2">
                      <div>
                        <div className="d-flex gap-3">
                          <div className="d-flex gap-3 align-items-center mx-3 my-3">
                            <h5>Phone:</h5>
                            <Input
                              type="text"
                              value={this.state.searchKey}
                              id="example-name-input"
                              onChange={this.handleSearchKeyChange}
                            />
                          </div>
                          {/* <p className='mx-3'>Search based on joining date</p> */}
                          <div className="d-flex gap-3 align-items-center mx-3 my-3">
                            <h5>Start Date:</h5>
                            <div
                              style={{
                                border: '1px solid gray',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                              }}
                            >
                              <DatePickerComponent
                                selectedDate={this.state.searchStartDate}
                                handleSelectedDateChange={
                                  this.handleSearchStartDate
                                }
                              />
                            </div>

                            <h5>End Date:</h5>
                            <div
                              style={{
                                border: '1px solid gray',
                                borderRadius: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                              }}
                            >
                              <DatePickerComponent
                                selectedDate={this.state.searchEndDate}
                                handleSelectedDateChange={
                                  this.handleSearchEndDate
                                }
                              />
                            </div>

                            {((this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null) ||
                              this.state.searchKey !== '') && (
                              <div className="d-flex align-items-center gap-3">
                                <Button
                                  onClick={async () => {
                                    this.setState({ searchLoading: true });
                                    const token = getToken();
                                    const response = await GKApi.get(
                                      `/finance/refund?refStatus=Refunded&pageNumber=1&pageSize=10${
                                        this.state.searchKey !== ''
                                          ? `&phone=${this.state.searchKey}`
                                          : ``
                                      }${
                                        this.state.searchStartDate !== null &&
                                        this.state.searchEndDate !== null
                                          ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                          : ''
                                      }`,
                                      {
                                        headers: {
                                          Authorization: token,
                                          cmpId: 173,
                                        },
                                      }
                                    );
                                    this.setState(
                                      {
                                        successfulRefundData:
                                          response.data.data,

                                        totalPageCount:
                                          response.data.paginationInfo
                                            .totalPage,
                                        pageNumber:
                                          response.data.paginationInfo
                                            .pageNumber,
                                      },
                                      () => {}
                                    );
                                    if (response) {
                                      this.setState({ searchLoading: false });
                                    }
                                  }}
                                >
                                  {this.state.searchLoading
                                    ? 'Searching'
                                    : 'Search'}
                                </Button>
                                <Button
                                  onClick={async () => {
                                    // eslint-disable-next-line
                                    this.state.searchKey = '';
                                    this.setState({ clearLoading: true });
                                    const token = getToken();
                                    const response = await GKApi.get(
                                      `/finance/refund?refStatus=Refunded&pageNumber=1&pageSize=10`,
                                      {
                                        headers: {
                                          Authorization: token,
                                          cmpId: 173,
                                        },
                                      }
                                    );
                                    this.setState(
                                      {
                                        successfulRefundData:
                                          response.data.data,

                                        totalPageCount:
                                          response.data.paginationInfo
                                            .totalPage,
                                        pageNumber:
                                          response.data.paginationInfo
                                            .pageNumber,
                                        searchStartDate: null,
                                        searchEndDate: null,
                                      },
                                      () => {}
                                    );
                                    if (response) {
                                      this.setState({ clearLoading: false });
                                    }
                                  }}
                                >
                                  {this.state.clearLoading
                                    ? 'Clearing'
                                    : 'Clear'}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                        <MaterialTable
                          title={'List of Successful Refunds'}
                          columns={approvedColumns}
                          data={this.state.successfulRefundData}
                          actions={[
                            this.state.canView === 1 && {
                              icon: () => <Button>Export All</Button>, // you can pass icon too
                              tooltip: 'Export to Excel',
                              onClick: () => downloadExcelSuccess(),
                              isFreeAction: true,
                            },
                          ]}
                          options={{
                            //cell style for the table
                            cellStyle: {
                              borderRight: '1px solid #eee',
                              textAlign: 'left',
                              // whiteSpace:'nowrap'
                            },

                            //style for the header
                            search: false,
                            paging: false,
                            headerStyle: {
                              position: 'sticky',
                              textAlign: 'left',
                              top: 0,
                              whiteSpace: 'nowrap',
                            },
                            actionsColumnIndex: -1,
                            exportButton: { csv: true, pdf: false },
                            exportFileName: `Pending Refund List Page ${this.state.pageNumber}`,
                            pageSize: 10,
                            columnsButton: true,
                            rowStyle: (rowData) => {
                              const reqDate = moment(rowData.trxDate);
                              const currentDate = moment(new Date());

                              const diff =
                                currentDate.diff(reqDate, 'hours') / 24;

                              return {
                                backgroundColor:
                                  rowData.deliveryStatus === 'Delivered'
                                    ? 'lavender'
                                    : rowData.deliveryStatus ===
                                      'Agent Call Done'
                                    ? 'aliceblue'
                                    : 'beige',
                                color: diff > 5 && 'red',
                              };
                            },
                          }}
                          localization={{
                            toolbar: {
                              exportCSVName: 'Export as CSV',
                            },
                          }}
                          detailPanel={(rowData) => {
                            return (
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Transaction Reference Number
                                      </p>
                                      <p>{rowData.trxRefNo}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">Sell ID</p>
                                      <p>{rowData.refReqID}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">Per Unit Price</p>
                                      <p>BDT {rowData.perUnitPrice}</p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <p className="fw-bold">Refund Fee Rate</p>
                                      <p>{rowData.refundFeeRate}%</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">Refund Fee </p>
                                      <p>BDT {rowData.refFee}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Principal Amount
                                      </p>
                                      <p>BDT {rowData.principalAmount}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Net Refund Amount
                                      </p>
                                      <p>BDT {rowData.netRefAmount}</p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bkash Account Name
                                      </p>
                                      <p>{rowData.bKashAccName}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bkash Account Number
                                      </p>
                                      <p>{rowData.bKashNumber}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bank Account Name
                                      </p>
                                      <p>{rowData.bankAccName}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bank Account Number
                                      </p>
                                      <p>{rowData.bankAccNo}</p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <p className="fw-bold">Bank Name</p>
                                      <p>{rowData.bankName}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bank Branch Name
                                      </p>
                                      <p>{rowData.bankBranch}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bank District Name
                                      </p>
                                      <p>{rowData.bankDistrict}</p>
                                    </Col>
                                    <Col md={3}>
                                      <p className="fw-bold">
                                        Bank Routing Number
                                      </p>
                                      <p>{rowData.bankRoutingNo}</p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <p className="fw-bold">Initiator</p>
                                      <p>{rowData.entry_staff_email}</p>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            );
                          }}
                          onRowClick={(event, rowData, togglePanel) =>
                            togglePanel()
                          }
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>

              {/* modal for initiate delivery status change */}
              <div id="41">
                <Modal
                  isOpen={this.state.initiateModal}
                  toggle={this.tog_initiateModal}
                >
                  <ModalHeader
                    toggle={() => this.setState({ initiateModal: false })}
                  >
                    Confirmation
                  </ModalHeader>

                  <ModalBody>
                    <Row className="mb-3">
                      <h6>Are you sure you want to Initiate this Refund?</h6>
                    </Row>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_initiateModal}
                      color="light"
                      className="waves-effect"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="waves-effect"
                      color="primary"
                      onClick={async (event) => {
                        event.preventDefault();

                        const token = getToken();

                        let sendData = {
                          refReqID: this.state.key?.refReqID,
                          disStatus: 'Pending',
                          deviceInfo: this.state.deviceInfo,
                        };

                        // return
                        await GKApi.post('/finance/refund', sendData, {
                          headers: {
                            Authorization: token,
                            cmpId: 173,
                          },
                        });

                        // the api call
                        const response = await GKApi.get(
                          `/finance/refund?refStatus=Pending&pageNumber=${this.state.pageNumber}&pageSize=10`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 173,
                            },
                          }
                        );

                        this.setState({
                          pendingRefundData: response.data.data,
                          totalPageCount:
                            response.data.paginationInfo.totalPage,
                          pageNumber: response.data.paginationInfo.pageNumber,
                        });

                        this.tog_initiateModal();
                      }}
                    >
                      Initiate
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>

              {/* modal for authorize refund status change */}
              <div id="41">
                <Modal
                  isOpen={this.state.authModal}
                  toggle={this.tog_authModal}
                >
                  <ModalHeader
                    toggle={() => this.setState({ authModal: false })}
                  >
                    Confirmation
                  </ModalHeader>

                  <ModalBody>
                    <Row className="mb-3">
                      <h6>Please choose Authorize or Reject option</h6>
                    </Row>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={async (event) => {
                        event.preventDefault();

                        this.tog_rejectPendingModal();
                      }}
                      color="light"
                      className="waves-effect"
                    >
                      Reject
                    </Button>
                    <Button
                      className="waves-effect"
                      color="primary"
                      onClick={async (event) => {
                        event.preventDefault();

                        const token = getToken();

                        let sendData = {
                          refReqID: this.state.key?.refReqID,
                          disbursementID: this.state.key.disbursementID,
                          disStatus: 'Success',
                          deviceInfo: this.state.deviceInfo,
                        };

                        // return
                        await GKApi.post(
                          `/finance/refund/${
                            this.state.key?.refMedium === 'BKASH'
                              ? 'bkash'
                              : 'bank'
                          }`,
                          sendData,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 173,
                            },
                          }
                        );

                        // the api call
                        const response = await GKApi.get(
                          `/finance/refund?refStatus=Pending&pageNumber=${this.state.pageNumber}&pageSize=10`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 173,
                            },
                          }
                        );

                        this.setState({
                          pendingRefundData: response.data.data,
                          totalPageCount:
                            response.data.paginationInfo.totalPage,
                          pageNumber: response.data.paginationInfo.pageNumber,
                        });

                        this.tog_authModal();
                      }}
                    >
                      Authorize
                    </Button>
                  </ModalFooter>
                </Modal>
                <Modal
                  isOpen={this.state.rejectPending}
                  toggle={this.tog_rejectPendingModal}
                >
                  <ModalHeader
                    toggle={() => this.setState({ rejectPending: false })}
                  >
                    Confirmation
                  </ModalHeader>

                  <ModalBody>
                    <Col xl={12}>
                      <Row className="mb-3 mx-2">
                        Please confirm to reject user request
                      </Row>
                      <Row className="mb-3 mx-2">
                        <Input
                          type="text"
                          // oninput={validate(this)}
                          value={this.state.reason}
                          placeholder="Please specify the reason"
                          id="gold-price-input"
                          onChange={this.handleReasonChange}
                        />
                      </Row>
                    </Col>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_rejectPendingModal}
                      color="light"
                      className="waves-effect"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="waves-effect"
                      color="primary"
                      onClick={async (event) => {
                        event.preventDefault();

                        const token = getToken();

                        let sendData = {
                          refReqID: this.state.key?.refReqID,
                          disbursementID: this.state.key.disbursementID,
                          disStatus: 'Failed',
                          // deviceInfo: this.state.deviceInfo,
                          disbCancelReason: this.state.reason,
                        };

                        // return
                        await GKApi.post(
                          `/finance/refund/${
                            this.state.key?.refMedium === 'BKASH'
                              ? 'bkash'
                              : 'bank'
                          }`,
                          sendData,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 173,
                            },
                          }
                        );

                        // the api call
                        const response = await GKApi.get(
                          `/finance/refund?refStatus=Pending&pageNumber=${this.state.pageNumber}&pageSize=10`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 173,
                            },
                          }
                        );

                        this.setState({
                          pendingRefundData: response.data.data,
                          totalPageCount:
                            response.data.paginationInfo.totalPage,
                          pageNumber: response.data.paginationInfo.pageNumber,
                        });
                        this.tog_rejectPendingModal();
                        this.tog_authModal();
                      }}
                    >
                      Reject
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>

              {this.state.activeTabJustify === '2' &&
                (this.state.isLoading ? (
                  <h5
                    className="d-flex justify-content-center my-3
gap-5"
                  >
                    <CircularProgress color="inherit" />
                  </h5>
                ) : (
                  <div className="my-3 d-flex justify-content-center gap-5">
                    <Button
                      onClick={async () => {
                        let token = getToken();
                        //get api call with needed parameters

                        if (
                          (this.state.searchStartDate !== null &&
                            this.state.searchEndDate !== null) ||
                          this.state.searchKey !== ''
                        ) {
                          let submitData = await GKApi.get(
                            `/finance/refund?refStatus=Refunded&pageNumber=${
                              this.state.pageNumber - 1
                            }&pageSize=10${
                              this.state.searchKey !== ''
                                ? `&phone=${this.state.searchKey}`
                                : ``
                            }${
                              this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null
                                ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                : ''
                            }`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 173,
                              },
                            }
                          );
                          this.setState(
                            {
                              successfulRefundData: submitData.data.data,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                            },
                            () => {}
                          );
                        } else {
                          let submitData = await GKApi.get(
                            `/finance/refund?refStatus=Refunded&pageNumber=${
                              this.state.pageNumber - 1
                            }&pageSize=10`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 173,
                              },
                            }
                          );
                          this.setState(
                            {
                              successfulRefundData: submitData.data.data,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                            },
                            () => {}
                          );
                        }
                      }}
                      disabled={this.state.pageNumber === 1 ? true : false}
                    >
                      Previous page
                    </Button>{' '}
                    <Button
                      onClick={async () => {
                        let token = getToken();
                        // this.setState({ isLoading: true });
                        //get api call with needed parameters

                        if (
                          (this.state.searchStartDate !== null &&
                            this.state.searchEndDate !== null) ||
                          this.state.searchKey !== ''
                        ) {
                          let submitData = await GKApi.get(
                            `/finance/refund?refStatus=Refunded&pageNumber=${
                              this.state.pageNumber + 1
                            }&pageSize=10${
                              this.state.searchKey !== ''
                                ? `&phone=${this.state.searchKey}`
                                : ``
                            }${
                              this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null
                                ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                : ''
                            }`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 173,
                              },
                            }
                          );
                          if (submitData) {
                            this.setState({
                              isLoading: false,
                            });
                          }
                          this.setState(
                            {
                              successfulRefundData: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        } else {
                          let submitData = await GKApi.get(
                            `/finance/refund?refStatus=Refunded&pageNumber=${
                              this.state.pageNumber + 1
                            }&pageSize=10`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 173,
                              },
                            }
                          );
                          if (submitData) {
                            this.setState({
                              isLoading: false,
                            });
                          }
                          this.setState(
                            {
                              successfulRefundData: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        }
                      }}
                      disabled={
                        this.state.pageNumber === this.state.totalPageCount
                      }
                    >
                      {this.state.isLoading ? 'Loading Next Page' : 'Next Page'}
                    </Button>
                  </div>
                ))}

              {this.state.activeTabJustify === '1' &&
                (this.state.isLoading ? (
                  <h5 className="d-flex justify-content-center my-3 gap-5">
                    <CircularProgress color="inherit" />
                  </h5>
                ) : (
                  <div className="my-3 d-flex justify-content-center gap-5">
                    <Button
                      onClick={async () => {
                        let token = getToken();
                        //get api call with needed parameters

                        if (
                          (this.state.searchStartDate !== null &&
                            this.state.searchEndDate !== null) ||
                          this.state.searchKey !== ''
                        ) {
                          let submitData = await GKApi.get(
                            `/finance/refund?refStatus=Pending&pageNumber=${
                              this.state.pageNumber - 1
                            }&pageSize=10${
                              this.state.searchKey !== ''
                                ? `&phone=${this.state.searchKey}`
                                : ``
                            }${
                              this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null
                                ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                : ''
                            }`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 173,
                              },
                            }
                          );
                          this.setState(
                            {
                              pendingRefundData: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        } else {
                          let submitData = await GKApi.get(
                            `/finance/refund?refStatus=Pending&pageNumber=${
                              this.state.pageNumber - 1
                            }&pageSize=10`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 173,
                              },
                            }
                          );
                          this.setState(
                            {
                              pendingRefundData: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        }
                      }}
                      disabled={this.state.pageNumber === 1 ? true : false}
                    >
                      Previous page
                    </Button>{' '}
                    <Button
                      onClick={async () => {
                        let token = getToken();
                        // this.setState({ isLoading: true });
                        //get api call with needed parameters

                        if (
                          (this.state.searchStartDate !== null &&
                            this.state.searchEndDate !== null) ||
                          this.state.searchKey !== ''
                        ) {
                          let submitData = await GKApi.get(
                            `/finance/refund?refStatus=Pending&pageNumber=${
                              this.state.pageNumber + 1
                            }&pageSize=10${
                              this.state.searchKey !== ''
                                ? `&phone=${this.state.searchKey}`
                                : ``
                            }${
                              this.state.searchStartDate !== null &&
                              this.state.searchEndDate !== null
                                ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                : ''
                            }`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 173,
                              },
                            }
                          );
                          if (submitData) {
                            this.setState({
                              isLoading: false,
                            });
                          }
                          this.setState(
                            {
                              pendingRefundData: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        } else {
                          let submitData = await GKApi.get(
                            `/finance/refund?refStatus=Pending&pageNumber=${
                              this.state.pageNumber + 1
                            }&pageSize=10`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 173,
                              },
                            }
                          );
                          if (submitData) {
                            this.setState({
                              isLoading: false,
                            });
                          }
                          this.setState(
                            {
                              pendingRefundData: submitData.data.data,
                              pageNumber:
                                submitData.data.paginationInfo.pageNumber,
                              totalPageCount:
                                submitData.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        }
                      }}
                      disabled={
                        this.state.pageNumber === this.state.totalPageCount
                      }
                    >
                      {this.state.isLoading ? 'Loading Next Page' : 'Next Page'}
                    </Button>
                  </div>
                ))}
              <div className="my-2 d-flex justify-content-center">
                <h5>
                  Page {this.state.pageNumber} of {this.state.totalPageCount}
                </h5>
              </div>
            </div>
          ) : (
            <h5>Sorry you don't have permission to view this</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ManualRefund;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
  FormGroup,
  Form,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'reactstrap';
// import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Delete, Edit } from '@material-ui/icons';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  capitalizeFirstLetter,
  getGroupName,
  getToken,
  GKApi,
} from '../../components/config';

// Table data

class UserGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Admin', link: '#' },
        { title: 'User Group Table', link: '#' },
      ],
      userGroupData: [],
      visible: false,
      modal_standard: false,
      modal_standard2: false,
      delete_modal: false,
      presetId: null,
      presetName: '',
      presetData: null,
      presetUsers: [],
      componentList: null,
      roleList: null,
      isLoading: true,
      disableUpdateGroupBtn: true,
      groupDetailsModal: false,
      addUserToGroupModal: false,
      allAdminUserData: null,
      isSuper:false,
    };
    this.tog_standard = this.tog_standard.bind(this);
    this.groupDetailsModalToggle = this.groupDetailsModalToggle.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  groupDetailsModalToggle() {
    this.setState((prevState) => ({
      groupDetailsModal: !prevState.groupDetailsModal,
    }));
    this.removeBodyCss();
  }

  addUserToGroupModalToggle = () => {
    this.setState((prevState) => ({
      addUserToGroupModal: !prevState.addUserToGroupModal,
    }));
    this.removeBodyCss();
  };

  deleteGroupModalToggle = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
    this.removeBodyCss();
  };

  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  handleSubmit = (event) => {
    event.preventDefault();
  };

  fetchGroupList = async () => {
    const token = getToken(); // getting token for api call
    this.setState({ isLoading: true });
    try {
      // the api call
      const response = await GKApi.get('/role/groups', {
        headers: {
          Authorization: token,
        },
      });

      this.setState({
        userGroupData: response.data,
      });
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken(); // getting token for api call
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
       if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    try {
      const allAdminUserResponse = await GKApi.get(
        '/staff?pageNumber=1&pageSize=1000',
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // the api call
      const response = await GKApi.get('/role/groups', {
        headers: {
          Authorization: token,
        },
      });

      // the api call
      const preset_group_list_response = await GKApi.get('/role/components', {
        headers: {
          Authorization: token,
        },
      });
      const role_list_response = await GKApi.get('/role/roles', {
        headers: {
          Authorization: token,
        },
      });
      this.setState({
        userGroupData: response.data,
        componentList: preset_group_list_response.data,
        roleList: role_list_response.data,
        allAdminUserData: allAdminUserResponse.data.data,
      });
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  }

  fetchGroupDetails = async (id) => {
    const token = getToken(); // getting token for api call
    try {
      // the api call
      const response = await GKApi.get(`/role/group?groupId=${id}`, {
        headers: {
          Authorization: token,
        },
      });

      let tempPresetData = [];
      response.data.roles.forEach((element) => {
        tempPresetData.push({
          master: element.masterName,
          masterDropDown: false,
          branch: element.branchName,
          branchDropDown: false,
          component: element.componentName,
          componentDropDown: false,
          componentId: element.cmpId,
          role: element.role,
          roleDropDown: false,
          roleId: element.roleId,
        });
      });
      this.setState(
        {
          presetData: tempPresetData,
          presetUsers: response.data.users,
        },
        () => {}
      );
    } catch (error) {}
  };

  render() {
    const columns = [
      {
        field: 'groupId',
        title: 'Group id',
      },
      {
        field: 'title',
        title: 'User Group Name',
      },
      {
        field: 'userCount',
        title: 'User Count',
      },
    ];

    const presetColumns = [
      {
        field: 'master',
        title: 'Master',
      },
      {
        field: 'branch',
        title: 'Branch',
      },
      {
        field: 'component',
        title: 'Component',
      },
      {
        field: 'role',
        title: 'Role',
      },
    ];
    const presetUserColumns = [
      {
        field: 'staffId',
        title: 'Staff Id',
      },
      {
        field: 'name',
        title: 'Name',
      },
      {
        field: 'email',
        title: 'Email',
      },
      {
        field: 'phone',
        title: 'Phone',
      },
    ];

    const presetAdminUserColumns = [
      {
        field: 'userId',
        title: 'Staff Id',
      },
      {
        field: 'name',
        title: 'Name',
      },
      {
        field: 'email',
        title: 'Email',
      },
      {
        field: 'phone',
        title: 'Phone',
      },
    ];

    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isSuper===true?<div className="container-fluid">
            <Breadcrumbs
              title="User group Table"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <MaterialTable
              title="List of User groups"
              columns={columns}
              data={this.state.userGroupData}
              actions={[
                // {
                //   icon: VisibilityIcon,
                //   tooltip: 'View request details',
                //   onClick: (event, requests) => {
                //     this.setState({ modal_standard: true });
                //     this.setState({ requestData: requests });
                //   },
                // },
                (rowData) => ({
                  icon: VisibilityIcon,
                  tooltip: 'View Group Details',
                  onClick: (event) => {
                    this.fetchGroupDetails(rowData.groupId);
                    this.setState({
                      groupDetailsModal: true,
                      presetName: rowData.title,
                      presetId: rowData.groupId,
                    });
                  },
                  disabled: rowData.type === 'Approved',
                }),
                (rowData) => ({
                  icon: Edit,
                  tooltip: 'Edit Group',
                  onClick: (event) => {
                    this.fetchGroupDetails(rowData.groupId);
                    this.setState({
                      modal_standard: true,
                      presetName: rowData.title,
                      presetId: rowData.groupId,
                    });
                  },
                  disabled: rowData.type === 'Approved',
                }),
                (rowData) => ({
                  icon: () => <i className="fas fa-user-plus" />,
                  tooltip: 'Add User to Group',
                  onClick: (event) => {
                    this.fetchGroupDetails(rowData.groupId);
                    this.setState({
                      addUserToGroupModal: true,
                      presetName: rowData.title,
                      presetId: rowData.groupId,
                    });
                  },
                  disabled: rowData.type === 'Approved',
                }),
                (rowData) => ({
                  icon: Delete,
                  tooltip: 'Delete Group',
                  onClick: (event) => {
                    this.setState({
                      delete_modal: true,
                      presetName: rowData.title,
                      presetId: rowData.groupId,
                    });
                  },
                  disabled: rowData.title === 'SUPER_ADMIN',
                }),
              ]}
              options={{
                actionsColumnIndex: -1,
                exportButton: { csv: true, pdf: false },
                exportFileName: 'Admin User Group',
                pageSize: 20,
                columnsButton: true,
              }}
              localization={{
                toolbar: {
                  exportCSVName: 'Export as CSV',
                },
              }}
            />
            {/* User Group Details Modal */}
            <Modal
              size="xl"
              isOpen={this.state.groupDetailsModal}
              toggle={this.groupDetailsModalToggle}
            >
              <ModalHeader
                toggle={() => this.setState({ groupDetailsModal: false })}
              >
                {this.state.presetName}
              </ModalHeader>
              <ModalBody>
                <MaterialTable
                  title="List of permission"
                  columns={presetColumns}
                  data={
                    this.state.presetData === null ? [] : this.state.presetData
                  }
                  options={{
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                  }}
                />
                <div style={{ height: '5rem' }}></div>
                <MaterialTable
                  title="List of Users in this group"
                  columns={presetUserColumns}
                  data={
                    this.state.presetUsers === null
                      ? []
                      : this.state.presetUsers
                  }
                  
                  options={{
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                  }}
                />
              </ModalBody>
            </Modal>

            {/* Add User to Group Modal */}
            <Modal
              size="xl"
              isOpen={this.state.addUserToGroupModal}
              toggle={this.addUserToGroupModalToggle}
            >
              <ModalHeader
                toggle={() => this.setState({ addUserToGroupModal: false })}
              >
                {this.state.presetName}
              </ModalHeader>
              <ModalBody>
                <MaterialTable
                  title="List of Users in Group"
                  columns={presetUserColumns}
                  data={
                    this.state.presetUsers === null
                      ? []
                      : this.state.presetUsers
                  }
                  actions={[
                    {
                      tooltip: 'Remove All Selected Users From Group',
                      icon: 'delete',
                      onClick: async (evt, data) => {
                        alert('You want to delete ' + data.length + ' users');
                        const token = getToken();

                        data.forEach(async (user) => {
                          try {
                            const response = await GKApi.delete(
                              `/role/assign?staffId=${user.staffId}&groupId=${this.state.presetId}`,
                              {
                                headers: {
                                  Authorization: token,
                                },
                              }
                            );
                            if (response === null) console.log(response);
                          } catch (error) {
                            console.log(error);
                          }
                        });
                        await this.fetchGroupList();
                        await this.fetchGroupDetails(this.state.presetId);
                      },
                    },
                  ]}
                  options={{
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    selection: true,
                  }}
                />
                <div style={{ height: '6rem' }}></div>
                <MaterialTable
                  title="List of All Users"
                  columns={presetAdminUserColumns}
                  data={
                    this.state.allAdminUserData === null
                      ? []
                      : this.state.allAdminUserData.filter((userInAll) => {
                          let flag = true;

                          this.state.presetUsers.forEach((userInGroup) => {
                            if (userInAll.userId === userInGroup.staffId)
                              flag = false;
                          });
                          return flag;
                        })
                  }
                  actions={[
                    {
                      tooltip: 'Add All Selected Users to the Group',
                      icon: 'add',
                      onClick: async (evt, data) => {
                        alert('You want to Add ' + data.length + ' users');
                        const token = getToken();

                        data.forEach(async (user) => {
                          try {
                            const body = {
                              groupId: this.state.presetId,
                              staffId: user.userId,
                            };
                            const response = await GKApi.post(
                              `/role/assign`,
                              body,
                              {
                                headers: {
                                  Authorization: token,
                                },
                              }
                            );
                            if (response !== null) console.log(response);
                          } catch (error) {
                            console.log(error);
                          }
                        });
                        await this.fetchGroupList();
                        await this.fetchGroupDetails(this.state.presetId);
                      },
                    },
                  ]}
                  options={{
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    selection: true,
                  }}
                />
              </ModalBody>
            </Modal>

            {/* UPDATE USER GROUP */}
            <Modal
              size="xl"
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_standard: false })}
              >
                User groups
              </ModalHeader>
              <Form>
                <FormGroup>
                  <ModalBody>
                    <Row className="mb-3">
                      <Label className="col-md-2 col-form-label">
                        Group Name
                      </Label>
                      <Col md={4}>
                        <Input
                          type="text"
                          value={this.state.presetName}
                          placeholder="Please input name of the group"
                          onChange={(e) =>
                            this.setState({
                              presetName: e.target.value,
                              disableUpdateGroupBtn: false,
                            })
                          }
                        />
                      </Col>
                    </Row>

                    {this.state.presetData &&
                      this.state.presetData.map((item, index) => {
                        return (
                          <Row className="mb-3">
                            <Label className="col-md-1 col-form-label">
                              Master
                            </Label>
                            <Col md={2}>
                              <Dropdown
                                isOpen={item.masterDropDown}
                                toggle={(event) => {
                                  let temp = this.state.presetData;
                                  temp[index].masterDropDown =
                                    !temp[index].masterDropDown;
                                  this.setState({
                                    presetData: temp,
                                  });
                                }}
                              >
                                <DropdownToggle
                                  className="px-2 py-1"
                                  color="light"
                                >
                                  {capitalizeFirstLetter(item.master)}{' '}
                                  <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {Object.keys(this.state.componentList).map(
                                    (item) => (
                                      <DropdownItem
                                        onClick={(event) => {
                                          let temp = this.state.presetData;
                                          temp[index].master = item;
                                          temp[index].branch = 'Select Branch';
                                          temp[index].component =
                                            'Select Component';
                                          this.setState({ presetData: temp });
                                        }}
                                      >
                                        {item}
                                      </DropdownItem>
                                    )
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                            <Label className="col-md-1 col-form-label">
                              Branch
                            </Label>
                            <Col md={2}>
                              <Dropdown
                                isOpen={item.branchDropDown}
                                toggle={(event) => {
                                  let temp = this.state.presetData;
                                  temp[index].branchDropDown =
                                    !temp[index].branchDropDown;
                                  this.setState({
                                    presetData: temp,
                                  });
                                }}
                              >
                                <DropdownToggle
                                  className="px-2 py-1"
                                  color="light"
                                >
                                  {capitalizeFirstLetter(item.branch)}{' '}
                                  <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {this.state?.componentList[item.master] &&
                                    Object.keys(
                                      this.state?.componentList[item.master]
                                    ).map((item) => (
                                      <DropdownItem
                                        onClick={(event) => {
                                          let temp = this.state.presetData;
                                          temp[index].branch = item;
                                          temp[index].component =
                                            'Select Component';
                                          this.setState({ presetData: temp });
                                        }}
                                      >
                                        {item}
                                      </DropdownItem>
                                    ))}
                                </DropdownMenu>
                              </Dropdown>
                            </Col>

                            <Label className="col-md-1 col-form-label">
                              Component
                            </Label>
                            <Col md={2}>
                              <Dropdown
                                isOpen={item.componentDropDown}
                                toggle={(event) => {
                                  let temp = this.state.presetData;
                                  temp[index].componentDropDown =
                                    !temp[index].componentDropDown;
                                  this.setState({
                                    presetData: temp,
                                  });
                                }}
                              >
                                <DropdownToggle
                                  className="px-2 py-1"
                                  color="light"
                                >
                                  {capitalizeFirstLetter(item.component)}{' '}
                                  <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {item.master !== 'Select Master' &&
                                    item.branch !== 'Select Branch' &&
                                    this.state?.componentList[item.master][
                                      item.branch
                                    ].map((item) => (
                                      <DropdownItem
                                        onClick={(event) => {
                                          let temp = this.state.presetData;
                                          temp[index].component =
                                            item.componentName;
                                          temp[index].componentId = item.cmpId;
                                          this.setState({ presetData: temp });
                                        }}
                                      >
                                        {item.componentName}
                                      </DropdownItem>
                                    ))}
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                            <Label className="col-md-1 col-form-label">
                              Role
                            </Label>
                            <Col md={1}>
                              <Dropdown
                                isOpen={item.roleDropDown}
                                toggle={(event) => {
                                  let temp = this.state.presetData;
                                  temp[index].roleDropDown =
                                    !temp[index].roleDropDown;
                                  this.setState({
                                    presetData: temp,
                                  });
                                }}
                              >
                                <DropdownToggle
                                  className="px-2 py-1"
                                  color="light"
                                >
                                  {capitalizeFirstLetter(item.role)}{' '}
                                  <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {this.state.roleList.map((item) => (
                                    <DropdownItem
                                      onClick={(event) => {
                                        let temp = this.state.presetData;
                                        temp[index].role = item.title;
                                        temp[index].roleId = item.roleId;
                                        this.setState({ presetData: temp });
                                      }}
                                    >
                                      {item.title}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                            <Col md={1}>
                              <Button
                                disabled={this.state.presetData.length === 1}
                                onClick={() => {
                                  let temp = this.state.presetData;
                                  temp.splice(index, 1);
                                  this.setState({ presetData: temp });
                                }}
                              >
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: '40px',
                      }}
                    >
                      <Button
                        disabled={
                          this.state.presetData === null ||
                          this.state.presetData[
                            this.state.presetData.length - 1
                          ].componentId === null ||
                          this.state.presetData[
                            this.state.presetData.length - 1
                          ].roleId === null
                        }
                        onClick={() => {
                          let temp = this.state.presetData;
                          temp.push({
                            master: 'Select Master',
                            masterDropDown: false,
                            branch: 'Select Branch',
                            branchDropDown: false,
                            component: 'Select Component',
                            componentDropDown: false,
                            componentId: null,
                            role: 'Select Role',
                            roleDropDown: false,
                            roleId: null,
                          });
                          this.setState({
                            presetData: temp,
                            disableUpdateGroupBtn: false,
                          });
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={() => this.setState({ modal_standard: false })}
                      color="light"
                      className="waves-effect"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={this.state.disableUpdateGroupBtn}
                      type="submit"
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={async (e) => {
                        e.preventDefault();
                        if (this.state.presetName === '') {
                          return;
                        }
                        let roles = [];
                        this.state.presetData.forEach((item) => {
                          if (item.roleId !== null && item.componentId !== null)
                            roles.push({
                              cmpId: item.componentId,
                              roleId: item.roleId,
                            });
                        });
                        if (roles.length === 0) {
                          return;
                        }
                        let data = {
                          groupId: this.state.presetId,
                          groupName: this.state.presetName,
                          roles: roles,
                        };
                        try {
                          this.setState({ disableUpdateGroupBtn: true });
                          const token = getToken(); // getting token for api call
                          const role_list_response = await GKApi.put(
                            '/role/group',
                            data,
                            {
                              headers: {
                                Authorization: token,
                              },
                            }
                          );
                          if (role_list_response) {
                            this.setState({ modal_standard: false });
                          }
                        } catch (error) {
                        } finally {
                          this.fetchGroupList();
                          this.setState({ disableUpdateGroupBtn: false });
                        }
                      }}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </FormGroup>
              </Form>
            </Modal>

            <Modal
              isOpen={this.state.delete_modal}
              toggle={this.deleteGroupModalToggle}
            >
              <ModalHeader
                toggle={() => {
                  this.setState({
                    delete_modal: false,
                  });
                }}
              >
                Confirmation
              </ModalHeader>

              <ModalBody>
                <Col xl={12}>
                  <Row className="mb-3 mx-2">
                    Are you sure you want to Delete {this.state.presetName}{' '}
                    Group ?
                  </Row>
                </Col>
              </ModalBody>

              <ModalFooter>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={async (event) => {
                    event.preventDefault();

                    const token = getToken();

                    await GKApi.delete(
                      `/role/group?groupId=${this.state.presetId}`,

                      {
                        headers: {
                          Authorization: token,
                        },
                      }
                    );
                    await this.fetchGroupList();
                    this.deleteGroupModalToggle();
                  }}
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  onClick={this.deleteGroupModalToggle}
                  color="light"
                  className="waves-effect"
                >
                  No
                </Button>
              </ModalFooter>
            </Modal>
          </div>:<h5>Sorry you don't have permission to view this.</h5>}
        </div>
      </React.Fragment>
    );
  }
}

export default UserGroups;

import React, { useEffect, useState } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Knob from '../../AllCharts/knob/knob';
import KPIChart from './KPIChart';
import CampaignChannelData from './CampaignChannelData';
import CampaignChannelDataModification from './CampaignChannelDataModification';
import { getToken, GKApi } from '../../../components/config';
import CampaignRevenueAnalytics from './CampaignRevenueAnalytics';
import moment from 'moment';
// import CampaignStatus from './CampaignStatus';

const CampaignData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [menu, setMenu] = useState('Select Campaign');
  const [activeCampaignList, setActiveCampaignList] = useState([]);
  const [selectedCampaignData, setSelectedCampaignData] = useState({});
  // const [value, setValue] = useState(35);
  // const [value_prev, setValue_prev] = useState(44);
  // const [value_cur, setValue_cur] = useState(29);
  // const [angleArc, setAngleArc] = useState(29);
  const [campaignId, setCampaignId] = useState('');
  const [analyticsData, setAnalyticsData] = useState([]);
  const [timeTrxData, setTimeTrxData] = useState({});
  const [KPIData, setKPIData] = useState({});
  const [updatedInboxData, setUpdatedInboxData] = useState({});
  const [updatedNotificationData, setUpdatedNotificationData] = useState({});
  const [campaignType, setCampaignType] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const response = await GKApi.get('/marketing/campaign/list?type=active', {
        headers: {
          Authorization: getToken(),
        },
      });
      setIsLoading(false);
      setActiveCampaignList(response.data);
    };
    fetchData();
  }, []);

  // if (isLoading) return <div>Loading...</div>;
  return isLoading === true ? (
    <div>Loading...</div>
  ) : (
    <Card>
      <CardBody>
        <Row className="py-4">
          <div className="d-flex px-4 align-items-center gap-3 pb-4">
            <h6 className="fw-bold px-2">Active Campaigns</h6>
            <div className="">
              <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <DropdownToggle
                  color="light"
                  style={{
                    backgroundColor: '#f1f1f1',
                    border: '1px solid #BDBDBD',
                    borderRadius: '4px',
                  }}
                  caret
                >
                  {menu}
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu
                  style={{ overflowY: 'scroll', maxHeight: '160px' }}
                >
                  {activeCampaignList !== null &&
                    activeCampaignList.map((item, index) => (
                      <DropdownItem
                        onClick={async (event) => {
                          setMenu(item.title);
                          setAnalyticsData([]);
                          setTimeTrxData({});
                          setUpdatedInboxData({});
                          setUpdatedNotificationData({});
                          setKPIData({});
                          setCampaignType(item.type);
                          setSelectedCampaignData({});
                          const response = await GKApi.get(
                            `/marketing/${
                              item.type === 'CAMPAIGN'
                                ? 'campaign'
                                : 'general-com'
                            }?id=${item.id}`,
                            {
                              headers: {
                                Authorization: getToken(),
                              },
                            }
                          );
                          setSelectedCampaignData(response.data);
                          setCampaignId(item.id);

                          if (item.type === 'CAMPAIGN') {
                            const revenueAnalytics = await GKApi.get(
                              `/marketing/campaign/revenue-analytics?id=${item.id}&type=daily`,
                              {
                                headers: {
                                  Authorization: getToken(),
                                },
                              }
                            );
                            setAnalyticsData(revenueAnalytics.data.data);
                            const campaignTrxTimeData = await GKApi.get(
                              `/marketing/campaign/time-transaction?id=${item.id}`,
                              {
                                headers: {
                                  Authorization: getToken(),
                                },
                              }
                            );
                            setTimeTrxData(campaignTrxTimeData.data.data);

                            const campaignKPIData = await GKApi.get(
                              `/marketing/campaign/kpi?startdate=${moment(
                                item.startDate
                              ).format('DD-MM-YYYY')}&enddate=${moment(
                                item.endDate
                              ).format('DD-MM-YYYY')}&campaignId=${item.id}`,
                              {
                                headers: {
                                  Authorization: getToken(),
                                },
                              }
                            );
                            setKPIData(campaignKPIData.data);
                          }

                          const inboxUpdateData = await GKApi.get(
                            `/marketing/campaign/channel/inbox?id=${item.id}`,
                            {
                              headers: {
                                Authorization: getToken(),
                              },
                            }
                          );
                          setUpdatedInboxData(inboxUpdateData.data);

                          const notificationUpdateData = await GKApi.get(
                            `/marketing/campaign/channel/notification?id=${item.id}`,
                            {
                              headers: {
                                Authorization: getToken(),
                              },
                            }
                          );
                          setUpdatedNotificationData(
                            notificationUpdateData.data
                          );
                          setIsLoading(false);
                        }}
                      >
                        {item.title}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </Row>

        {/* {campaignType === 'CAMPAIGN' && (
          <Row className="py-2">
            <div>
              {Object.keys(selectedCampaignData).length > 0 && (
                <Row>
                  <Col xl={12}>
                    <CampaignStatus campaignData={selectedCampaignData} />
                  </Col>
                </Row>
              )}
            </div>
          </Row>
        )} */}
        <Row>
          {Object.keys(analyticsData).length > 0 && (
            <CampaignRevenueAnalytics
              campId={campaignId}
              revenueAnalyticsData={analyticsData}
            />
          )}
        </Row>
        <Row>
          <Col xl={12}>
            <KPIChart KPIData={KPIData} />
          </Col>
        </Row>
        {Object.keys(selectedCampaignData).length > 0 && (
          <Row>
            <Col xl={12}>
              <CampaignChannelData campaignData={selectedCampaignData} />
            </Col>
          </Row>
        )}
        <Row>
          <Col xl={6}>
            {Object.keys(timeTrxData).length > 0 && (
              <Card>
                <CardBody className="shadow-lg">
                  <span className="d-flex justify-content-between">
                    <h6 className="mb-4 fw-bold">Campaign Time Transaction</h6>
                  </span>

                  <Row className="justify-content-center">
                    <Col xl={6}>
                      <h5 className="text-center">Buy</h5>
                      <span className="d-flex items-center justify-content-center">
                        <Knob
                          value={timeTrxData.buy_count}
                          height={200}
                          width={150}
                          fgColor="#7a6fbe"
                          onChange={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </span>
                    </Col>
                    <Col xl={6}>
                      <h5 className="text-center"> Sell</h5>
                      <span className="d-flex items-center justify-content-center">
                        <Knob
                          value={timeTrxData.sell_count}
                          height={200}
                          width={150}
                          fgColor="#ffbb44"
                          onChange={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center py-4">
                    <Col xl={6}>
                      <h5 className="text-center">Collect</h5>
                      <span className="d-flex items-center justify-content-center">
                        <Knob
                          value={timeTrxData.collect_count}
                          height={200}
                          width={150}
                          fgColor="#4ac18e"
                          displayCustom={() => {
                            return false;
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </span>
                    </Col>
                    <Col xl={6}>
                      <h5 className="text-center">Gift</h5>
                      <span className="d-flex items-center justify-content-center">
                        <Knob
                          value={timeTrxData.gift_trx_count}
                          height={200}
                          width={150}
                          fgColor="#f06292"
                          onChange={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Col>
          {Object.keys(selectedCampaignData).length > 0 && (
            <Col xl={6}>
              <CampaignChannelDataModification
                campaignData={selectedCampaignData}
                campaignType={campaignType}
                updatedInboxData={updatedInboxData}
                updatedNotificationData={updatedNotificationData}
              />
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default CampaignData;

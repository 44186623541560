import React, { Component } from 'react';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  getGroupName,
  getToken,
  getUser,
  GKApi,
} from '../../components/config';
import moment from 'moment';
import DatePickerComponent from '../../components/Common/DatePicker';
import { useSelector } from 'react-redux';
import UserTransactions from './User-Transactions';
import { Avatar, createStyles, makeStyles, TextField } from '@material-ui/core';
import { deviceInfo } from '../../helpers/device_info_helper';

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const useStyles = makeStyles((theme) =>
      createStyles({
        size: {
          width: 250,
          height: 250,
        },
        padding: {
          padding: theme.spacing(0),
        },
      })
    );
    const name = useSelector((state) => state.Login.user.user?.name);
    const email = useSelector((state) => state.Login.user.user?.email);
    const designation = useSelector(
      (state) => state.Login.user.user?.designation
    );
    const dob = useSelector((state) => state.Login.user.user?.dob);
    const phone = useSelector((state) => state.Login.user.user?.phone);
    const groupId = useSelector((state) => state.Login.user.user?.groupId);
    const groupName = getGroupName();
    const classes = useStyles();
    return (
      <Component
        {...props}
        name={name}
        email={email}
        designation={designation}
        dob={dob}
        phone={phone}
        groupId={groupId}
        groupName={groupName}
        classes={classes}
      />
    );
  };
}

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Tables', link: '#' },
        { title: 'Application User Table', link: '#' },
      ],
      selectedFiles: [],
      userData: [],
      verifiedUserData: [],
      unverifiedUserData: [],
      key: null,
      visible: false,
      modal_standard: false,
      modal_standard2: false,
      modal_standard3: false,
      modal_standard4: false,
      modal_standard5: false,
      modal_standard6: false,
      modal_standard7: false,
      modal_standard8: false,
      modal_standard9: false,
      modal_standard10: false,
      notesModal: false,
      activeTabJustify: '1',
      profilePic: null,
      nidFrontPic: null,
      nidBackPic: null,
      userType: 'All',
      userMenu: false,
      nomineeName: '',
      nomineeNID: '',
      nomineeBirthDate: null,
      nomineeDocURL: '',
      nomineePhone: '',
      deviceInfo: {},
      groupId: null,
      profileStatusTypes: [],
      selectedProfileStatusType: '',
      bankListMenu: false,
      bankList: [],
      bankType: '',
      bankId: 1,
      districtListMenu: false,
      districtList: [],
      district: '',
      districtId: 1,
      branchListMenu: false,
      branchList: [],
      branch: '',
      routingNo: '',
      accountName: '',
      accountNo: '',
      bkashAccName: '',
      bkashAccNo: '',
      divisionListMenu: false,
      divisionList: [],
      division: '',
      divisionId: 1,
      addressDistrictListMenu: false,
      addressDistrictList: [],
      addressDistrict: '',
      addressDistrictId: 1,
      thanaListMenu: false,
      thanaList: [],
      thana: '',
      thanaId: 1,
      address: '',
      postalCode: '',
      additional: '',
      professionListMenu: false,
      relationListMenu: false,
      professionList: [],
      errors: {
        nid: false,
        name: false,
        nomineeName: false,
        nomineeNID: false,
        nomineePhone: false,
      },
      nid: '',
      phone: '',
      activeStatus: 1,
      age: null,
      dob: '',
      email: '',
      gender: '',
      name: '',
      profession: '',
      referralCode: '',
      sourceReferralCode: '',
      sourceReferralType: '',
      professionCode: 1,
      profileStatus: '',
      maritalStatus: '',
      maritalStatusMenu: false,
      genderMenu: false,
      activeStatusMenu: false,
      canVerify: 1,
      verificationRole: '',
      tableRole: '',
      resetPassRole: '',
      userDetailsRole: '',
      enableDisableRole: '',
      verificationCanAuth: 0,
      tableCanAuth: 0,
      resetPassCanAuth: 0,
      userDetailsCanAuth: 0,
      enableDisableCanAuth: 0,
      verificationCanInsert: 0,
      tableCanInsert: 0,
      resetPassCanInsert: 0,
      userDetailsCanInsert: 0,
      enableDisableCanInsert: 0,
      verificationCanEdit: 0,
      tableCanEdit: 0,
      resetPassCanEdit: 0,
      userDetailsCanEdit: 0,
      enableDisableCanEdit: 0,
      verificationCanReject: 0,
      tableCanReject: 0,
      resetPassCanReject: 0,
      userDetailsCanReject: 0,
      enableDisableCanReject: 0,
      verificationCanDelete: 0,
      tableCanDelete: 0,
      resetPassCanDelete: 0,
      userDetailsCanDelete: 0,
      enableDisableCanDelete: 0,
      verificationCanSubmit: 0,
      tableCanSubmit: 0,
      resetPassCanSubmit: 0,
      userDetailsCanSubmit: 0,
      enableDisableCanSubmit: 0,
      isSuper: false,
      isCrm: false,
      searchKey: '',
      searchLoading: false,
      clearLoading: false,
      pageNumber: 1,
      pageSize: 10,
      totalPageCount: 0,
      proPicLoading: false,
      nidBackLoading: false,
      nidFrontLoading: false,
      relationList: [],
      nomineeRelationWithUser: 'Select the relation between nominee and user',
      relationCode: 1,
      nomineeDoc: '',
      searchStartDate: null,
      searchEndDate: null,
      callDate: null,
      callTime: '',
      callNote: '',
      agentName: '',
      notesConfirmation: false,
      noteList: false,
      noteData: [],
      confirmingNote: false,
      pinResetData: [],
  pinResetModal: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };

  //profile details modal

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  //status change modal

  tog_standard2() {
    this.setState((prevState) => ({
      modal_standard2: !prevState.modal_standard2,
    }));
    this.removeBodyCss();
  }

  tog_standard3 = () => {
    this.setState((prevState) => ({
      modal_standard3: !prevState.modal_standard3,
    }));
    this.removeBodyCss();
  };

  tog_standard4 = () => {
    this.setState((prevState) => ({
      modal_standard4: !prevState.modal_standard4,
    }));
    this.removeBodyCss();
  };

  tog_standard5 = () => {
    this.setState((prevState) => ({
      modal_standard5: !prevState.modal_standard5,
    }));
    this.removeBodyCss();
  };
  tog_standard6 = () => {
    this.setState((prevState) => ({
      modal_standard6: !prevState.modal_standard6,
    }));
    this.removeBodyCss();
  };
  tog_standard7 = () => {
    this.setState((prevState) => ({
      modal_standard7: !prevState.modal_standard7,
    }));
    this.removeBodyCss();
  };
  tog_standard8 = () => {
    this.setState((prevState) => ({
      modal_standard8: !prevState.modal_standard8,
    }));
    this.removeBodyCss();
  };

  tog_standard9 = () => {
    this.setState((prevState) => ({
      modal_standard9: !prevState.modal_standard9,
    }));
    this.removeBodyCss();
  };

  tog_standard10 = () => {
    this.setState((prevState) => ({
      modal_standard10: !prevState.modal_standard10,
    }));
    this.removeBodyCss();
  };

  tog_notesModal = () => {
    this.setState((prevState) => ({
      notesModal: !prevState.notesModal,
    }));
    this.removeBodyCss();
  };
  tog_pinResetModal = () => {
    this.setState(prevState => ({
      pinResetModal: !prevState.pinResetModal,
    }));
  };
  

  tog_notesConfirmation = () => {
    this.setState((prevState) => ({
      notesConfirmation: !prevState.notesConfirmation,
    }));
    this.removeBodyCss();
  };

  tog_noteList = () => {
    this.setState((prevState) => ({
      noteList: !prevState.noteList,
    }));
    this.removeBodyCss();
  };
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  handleSearchKeyChange = (event) => {
    this.setState({ searchKey: event.target.value });
  };

  //nominee details change handlers
  handleNomineeNameChange = (event) => {
    const pattern = new RegExp('^[-_ a-zA-Z]+$');
    this.setState({ nomineeName: event.target.value });
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        nomineeName: !pattern.test(event.target.value),
      },
    }));
  };

  handleNomineeBirthDateChange = (event) => {
    this.setState(
      {
        ...this.state,
        nomineeBirthDate: event,
      },
      () => {}
    );
  };
  handleNomineeNIDChange = (event) => {
    const pattern = new RegExp('^([0-9]{10})$|^([0-9]{13})$|^([0-9]{17})$');
    this.setState({ nomineeNID: event.target.value });
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        nomineeNID: !pattern.test(event.target.value),
      },
    }));
  };
  handleNomineePhoneChange = (event) => {
    const pattern = new RegExp('^01[3-9]{1}[0-9]{8}$');
    this.setState({ nomineePhone: event.target.value });
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        nomineePhone: !pattern.test(event.target.value),
      },
    }));
  };

  //bank details change handlers
  handleAccountNameChange = (event) => {
    this.setState(
      {
        ...this.state,
        accountName: event.target.value,
      },
      () => {}
    );
  };
  handleAccountNoChange = (event) => {
    this.setState(
      {
        ...this.state,
        accountNo: event.target.value,
      },
      () => {}
    );
  };

  //bkash details change handlers
  handleBkashAccountNameChange = (event) => {
    this.setState(
      {
        ...this.state,
        bkashAccName: event.target.value,
      },
      () => {}
    );
  };
  handleBkashAccountNoChange = (event) => {
    this.setState(
      {
        ...this.state,
        bkashAccNo: event.target.value,
      },
      () => {}
    );
  };

  //address details change handlers
  handleAddressChange = (event) => {
    this.setState(
      {
        ...this.state,
        address: event.target.value,
      },
      () => {}
    );
  };

  handlePostalCodeNameChange = (event) => {
    this.setState(
      {
        ...this.state,
        postalCode: event.target.value,
      },
      () => {}
    );
  };
  handleAdditionalChange = (event) => {
    this.setState(
      {
        ...this.state,
        additional: event.target.value,
      },
      () => {}
    );
  };

  //profile info change handlers

  handleNameChange = (event) => {
    const pattern = new RegExp('^[-_ a-zA-Z]+$');
    this.setState({ name: event.target.value });
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        name: !pattern.test(event.target.value),
      },
    }));
  };
  handleGenderChange = (event) => {
    this.setState(
      {
        ...this.state,
        gender: event.target.value,
      },
      () => {}
    );
  };
  handlePhoneChange = (event) => {
    this.setState(
      {
        ...this.state,
        phone: event.target.value,
      },
      () => {}
    );
  };
  handleNIDChange = (event) => {
    const pattern = new RegExp('^([0-9]{10})$|^([0-9]{13})$|^([0-9]{17})$');
    this.setState({ nid: event.target.value });
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        nid: !pattern.test(event.target.value),
      },
    }));
  };

  handleEmailChange = (event) => {
    this.setState(
      {
        ...this.state,
        email: event.target.value,
      },
      () => {}
    );
  };
  handleReferralCodeChange = (event) => {
    this.setState(
      {
        ...this.state,
        referralCode: event.target.value,
      },
      () => {}
    );
  };
  handleSourceReferralCodeChange = (event) => {
    this.setState(
      {
        ...this.state,
        sourceReferralCode: event.target.value,
      },
      () => {}
    );
  };

  handleMaritalStatusChange = (event) => {
    this.setState(
      {
        ...this.state,
        maritalStatus: event.target.value,
      },
      () => {}
    );
  };
  handleDOBChange = (event) => {
    this.setState(
      {
        ...this.state,
        dob: event,
      },
      () => {}
    );
  };
  handleSearchStartDate = (event) => {
    this.setState(
      {
        ...this.state,
        searchStartDate: event,
      },
      () => {}
    );
  };
  handleSearchEndDate = (event) => {
    this.setState(
      {
        ...this.state,
        searchEndDate: event,
      },
      () => {}
    );
  };

  handleCallDateChange = (event) => {
    this.setState(
      {
        ...this.state,
        callDate: event,
      },
      () => {}
    );
  };

  handleCallTimeChange = (event) => {
    this.setState(
      {
        ...this.state,
        callTime: event.target.value,
      },
      () => {}
    );
  };

  handleCallNoteChange = (event) => {
    this.setState(
      {
        ...this.state,
        callNote: event.target.value,
      },
      () => {}
    );
  };

  handleAgentNameChange = (event) => {
    this.setState(
      {
        ...this.state,
        agentName: event.target.value,
      },
      () => {}
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  //   file upload
  handleAcceptedFiles = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };

  uploadDoc = async (userId) => {
    const token = getToken(); //get token from local storage
    const response = await GKApi.post(
      '/users/nominee/doc',
      {
        cmpId: 46,
        userId: userId,
        fileName:
          'nominee.' + this.state.selectedFiles[0].name.split('.').pop(),
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // eslint-disable-next-line
    this.state.nomineeDoc = response.data.key;
    await axios.put(response.data.url, this.state.selectedFiles[0]);
  };

  // page onload api call for the user list

  async componentDidMount() {
    // GET request using axios with async/await

    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'CRM') {
        this.setState({
          isCrm: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    const token = getToken();

    const profileStatusTypes = await GKApi.get('/users/profile-status', {
      headers: {
        Authorization: token,
        cmpId: 43,
      },
    });

    this.setState(
      {
        profileStatusTypes: profileStatusTypes.data.data,
      },
      () => {}
    );
    const response = await GKApi.get(
      `/users?pageNumber=${this.state.pageNumber}&pageSize=${this.state.pageSize}&type=${this.state.userType}`,
      {
        headers: {
          Authorization: token,
          cmpId: 43,
        },
      }
    );

    this.setState(
      {
        userData: response.data.data,
        groupId: this.props.groupId,
        pageNumber: response.data.paginationInfo.pageNumber,
        totalPageCount: response.data.paginationInfo.totalPage,
      },
      () => {}
    );

    // const groupId = getGroupId();
    const getPermission = await GKApi.get(
      `/role/permission?cmpId=43,44,45,46,61`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));

    this.setState(
      {
        verificationRole: permissionData[45].groupName,
        tableRole: permissionData[43].groupName,
        userDetailsRole: permissionData[46].groupName,
        enableDisableRole: permissionData[44].groupName,
        resetPassRole: permissionData[61].groupName,
        verificationCanAuth: permissionData[45].canAuth,
        tableCanAuth: permissionData[43].canAuth,
        resetPassCanAuth: permissionData[46].canAuth,
        userDetailsCanAuth: permissionData[44].canAuth,
        enableDisableCanAuth: permissionData[61].canAuth,
        verificationCanEdit: permissionData[45].canEdit,
        tableCanEdit: permissionData[43].canEdit,
        resetPassCanEdit: permissionData[46].canEdit,
        userDetailsCanEdit: permissionData[44].canEdit,
        enableDisableCanEdit: permissionData[61].canEdit,
        verificationCanInsert: permissionData[45].canInsert,
        tableCanInsert: permissionData[43].canInsert,
        resetPassCanInsert: permissionData[46].canInsert,
        userDetailsCanInsert: permissionData[44].canInsert,
        enableDisableCanInsert: permissionData[61].canInsert,
        verificationCanReject: permissionData[45].canReject,
        tableCanReject: permissionData[43].canReject,
        resetPassCanReject: permissionData[46].canReject,
        userDetailsCanReject: permissionData[44].canReject,
        enableDisableCanReject: permissionData[61].canReject,
        verificationCanDelete: permissionData[45].canDelete,
        tableCanDelete: permissionData[43].canDelete,
        resetPassCanDelete: permissionData[46].canDelete,
        userDetailsCanDelete: permissionData[44].canDelete,
        enableDisableCanDelete: permissionData[61].canDelete,

        verificationCanSubmit: permissionData[45].canSubmit,
        tableCanSubmit: permissionData[43].canSubmit,
        resetPassCanSubmit: permissionData[46].canSubmit,
        userDetailsCanSubmit: permissionData[44].canSubmit,
        enableDisableCanSubmit: permissionData[61].canSubmit,
      },
      () => {}
    );
    const res = deviceInfo();
    this.setState({ deviceInfo: res }, () => {});
  }

  render() {
    const columns = [
      {
        field: 'phone',
        title: 'Phone',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'name',
        title: 'Name',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'NID',
        title: 'NID',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'email',
        title: 'Email',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'gender',
        title: 'Gender',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'dateOfBirth',
        title: 'Date of Birth',
        headerStyle: {
          textAlign: 'center',
        },

        //conditional data rendering

        render: (row) => (
          <div>
            {row.dateOfBirth
              ? moment(row.dateOfBirth).format('DD-MM-YYYY')
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'joiningDate',
        title: 'Joining Date',
        headerStyle: {
          textAlign: 'center',
        },

        //conditional data rendering

        render: (row) => (
          <div>
            {row.joiningDate
              ? moment(row.joiningDate, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'lastLoginTime',
        title: 'Last Log in',
        headerStyle: {
          textAlign: 'center',
        },

        //conditional data rendering

        render: (row) => (
          <div>
            {row.lastLoginTime
              ? moment(row.lastLoginTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'deviceType',
        title: 'Device Type',
        headerStyle: {
          textAlign: 'center',
        },
        render: (row) => (
          <div>{row.deviceType ? row.deviceType : 'Not Available'}</div>
        ),
      },
      {
        field: 'referralCode',
        title: 'Referral Code',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'sourceReferralCode',
        title: 'Source Referral Code',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'sourceReferralType',
        title: 'Source Referral Type',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'maritalStatus',
        title: 'Marital Status',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'profession',
        title: 'Profession',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'activeStatus',
        title: 'Active Status',
        headerStyle: {
          textAlign: 'center',
        },

        //conditional data rendering, this render method is used for rendering custom data

        render: (row) => (
          <div>
            {row.activeStatus.charAt(0).toUpperCase() +
              row.activeStatus.slice(1).toLowerCase()}
          </div>
        ),
      },
      {
        field: 'profileStatus',
        title: 'Profile Status',
        headerStyle: {
          textAlign: 'center',
        },

        // conditional data rendering

        render: (row) => (
          <div>
            {row.profileStatus?.charAt(0).toUpperCase() +
              row.profileStatus?.slice(1).toLowerCase()}
          </div>
        ),
      },
      {
        field: 'nomineeName',
        title: 'Nominee Name',
        headerStyle: {
          textAlign: 'center',
        },
      },
    ];

    const noteColumns = [
      {
        field: 'NOTE_TIME',
        title: 'Call time',
        render: (row) => (
          <div>{moment(row.NOTE_TIME).format('DD-MM-YYYY hh:mm A')}</div>
        ),
      },
      {
        field: 'AGENT_NAME',
        title: 'Agent Name',
      },
      {
        field: 'NOTES',
        title: 'NOTES',
      },
      {
        field: 'ENTRY_DATE',
        title: 'Entry Date',
        render: (row) => (
          <div>{moment(row.ENTRY_DATE).format('DD-MM-YYYY hh:mm A')}</div>
        ),
      },
    ];

    const pinResetColumns = [
      {
        field: 'initiate',
        title: 'Pin Reset Email Initiated',
        render: (row) => (
          <div>{row.initiate === null ? "No Access Date is found" : moment(row.initiate).format('DD-MM-YYYY hh:mm A')}</div>
        ),
      },
      {
        field: 'landing',
        title: 'Pin Reset Link Accessed',
        render: (row) => (
          <div>{row.landing === null ? "No Access Date is found" : moment(row.landing).format('DD-MM-YYYY hh:mm A')}</div>
        ),
      },
    ];
    

    // download excel function

    const downloadExcel = async () => {
      const token = getToken();
      const response = await GKApi.get(
        `/users?pageNumber=1&pageSize=10000000&type=${this.state.userType}${
          this.state.searchKey !== '' ? `&search=${this.state.searchKey}` : ''
        }${
          this.state.searchStartDate !== null ||
          this.state.searchEndDate !== null
            ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
            : ''
        }`,
        {
          headers: {
            Authorization: token,
            cmpId: 43,
          },
        }
      );
      const newData = response.data.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'users');
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(
        workBook,
        `${
          this.state.userType.charAt(0).toUpperCase() +
          this.state.userType.slice(1).toLowerCase()
        } App User List.xlsx`
      );
    };

    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isCrm === true || this.state.isSuper === true ? (
            <div className="container-fluid">
              <Breadcrumbs
                title="App User Table"
                breadcrumbItems={this.state.breadcrumbItems}
              />

              <div id="43">
                <div className="d-flex align-items-center gap-5">
                  <div className="">
                    <div className="d-flex gap-3 align-items-center mx-3 my-3">
                      <h5>Search:</h5>
                      <Input
                        type="text"
                        value={this.state.searchKey}
                        id="example-name-input"
                        onChange={this.handleSearchKeyChange}
                      />
                      {this.state.searchKey !== '' && (
                        <div className="d-flex align-items-center gap-3">
                          <Button
                            onClick={async () => {
                              this.setState({ searchLoading: true });
                              const token = getToken();
                              const response = await GKApi.get(
                                `/users?pageNumber=1&pageSize=${this.state.pageSize}&type=${this.state.userType}&search=${this.state.searchKey}`,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 43,
                                  },
                                }
                              );
                              this.setState(
                                {
                                  userData: response.data.data,
                                  groupId: this.props.groupId,
                                  totalPageCount:
                                    response.data.paginationInfo.totalPage,
                                  pageNumber:
                                    response.data.paginationInfo.pageNumber,
                                },
                                () => {}
                              );
                              if (response) {
                                this.setState({ searchLoading: false });
                              }
                            }}
                          >
                            {this.state.searchLoading ? 'Searching' : 'Search'}
                          </Button>
                          <Button
                            onClick={async () => {
                              // eslint-disable-next-line
                              this.state.searchKey = '';
                              this.setState({ clearLoading: true });
                              const token = getToken();
                              const response = await GKApi.get(
                                `/users?pageNumber=1&pageSize=${this.state.pageSize}&type=${this.state.userType}`,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 43,
                                  },
                                }
                              );
                              this.setState(
                                {
                                  userData: response.data.data,
                                  groupId: this.props.groupId,
                                  totalPageCount:
                                    response.data.paginationInfo.totalPage,
                                  pageNumber:
                                    response.data.paginationInfo.pageNumber,
                                },
                                () => {}
                              );
                              if (response) {
                                this.setState({ clearLoading: false });
                              }
                            }}
                          >
                            {this.state.clearLoading ? 'Clearing' : 'Clear'}
                          </Button>
                        </div>
                      )}
                    </div>
                    {/* <p className='mx-3'>Search based on joining date</p> */}
                    <div className="d-flex gap-3 align-items-center mx-3 my-3">
                      <h5>Start Date:</h5>
                      <div
                        style={{
                          border: '1px solid gray',
                          borderRadius: '5px',
                          paddingTop: '5px',
                          paddingBottom: '5px',
                          paddingRight: '4px',
                          paddingLeft: '4px',
                        }}
                      >
                        <DatePickerComponent
                          selectedDate={this.state.searchStartDate}
                          handleSelectedDateChange={this.handleSearchStartDate}
                        />
                      </div>

                      <h5>End Date:</h5>
                      <div
                        style={{
                          border: '1px solid gray',
                          borderRadius: '5px',
                          paddingTop: '5px',
                          paddingBottom: '5px',
                          paddingRight: '4px',
                          paddingLeft: '4px',
                        }}
                      >
                        <DatePickerComponent
                          selectedDate={this.state.searchEndDate}
                          handleSelectedDateChange={this.handleSearchEndDate}
                        />
                      </div>

                      {this.state.searchStartDate !== null &&
                        this.state.searchEndDate !== null && (
                          <div className="d-flex align-items-center gap-3">
                            <Button
                              onClick={async () => {
                                this.setState({ searchLoading: true });
                                const token = getToken();
                                const response = await GKApi.get(
                                  `/users?pageNumber=1&pageSize=${this.state.pageSize}&type=${this.state.userType}&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 43,
                                    },
                                  }
                                );
                                this.setState(
                                  {
                                    userData: response.data.data,
                                    groupId: this.props.groupId,
                                    totalPageCount:
                                      response.data.paginationInfo.totalPage,
                                    pageNumber:
                                      response.data.paginationInfo.pageNumber,
                                  },
                                  () => {}
                                );
                                if (response) {
                                  this.setState({ searchLoading: false });
                                }
                              }}
                            >
                              {this.state.searchLoading
                                ? 'Searching'
                                : 'Search'}
                            </Button>
                            <Button
                              onClick={async () => {
                                // eslint-disable-next-line
                                this.state.searchKey = '';
                                this.setState({ clearLoading: true });
                                const token = getToken();
                                const response = await GKApi.get(
                                  `/users?pageNumber=1&pageSize=${this.state.pageSize}&type=${this.state.userType}`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 43,
                                    },
                                  }
                                );
                                this.setState(
                                  {
                                    userData: response.data.data,
                                    groupId: this.props.groupId,
                                    totalPageCount:
                                      response.data.paginationInfo.totalPage,
                                    pageNumber:
                                      response.data.paginationInfo.pageNumber,
                                    searchStartDate: null,
                                    searchEndDate: null,
                                  },
                                  () => {}
                                );
                                if (response) {
                                  this.setState({ clearLoading: false });
                                }
                              }}
                            >
                              {this.state.clearLoading ? 'Clearing' : 'Clear'}
                            </Button>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="d-flex gap-3 align-items-center mx-3">
                    <h5>Select User Status type:</h5>
                    <Dropdown
                      isOpen={this.state.userMenu}
                      toggle={(event) =>
                        this.setState(
                          {
                            userMenu: !this.state.userMenu,
                          },
                          () => {}
                        )
                      }
                    >
                      <DropdownToggle
                        className="px-5 py-2"
                        style={{
                          color: 'white',
                          backgroundColor: '#4E6360',
                        }}
                      >
                        {this.state.userType.charAt(0).toUpperCase() +
                          this.state.userType.slice(1).toLowerCase()}
                        <i className="mdi mdi-chevron-down"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={async (event) => {
                            this.setState(
                              { userType: 'ALL', searchKey: '' },
                              () => {}
                            );
                            const token = getToken();
                            const response = await GKApi.get(
                              `/users?pageNumber=1&pageSize=${this.state.pageSize}&type=ALL`,
                              {
                                headers: {
                                  Authorization: token,
                                  cmpId: 43,
                                },
                              }
                            );

                            this.setState(
                              {
                                userData: response.data.data,
                                pageNumber:
                                  response.data.paginationInfo.pageNumber,
                                totalPageCount:
                                  response.data.paginationInfo.totalPage,
                              },
                              () => {}
                            );
                          }}
                        >
                          All
                        </DropdownItem>
                        {this.state.profileStatusTypes.map((ps, index) => (
                          <DropdownItem
                            key={index}
                            onClick={async (event) => {
                              this.setState(
                                { userType: ps.profileStatus, searchKey: '' },
                                () => {}
                              );
                              const token = getToken();
                              const response = await GKApi.get(
                                `/users?pageNumber=1&pageSize=${this.state.pageSize}&type=${ps.profileStatus} `,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 43,
                                  },
                                }
                              );

                              this.setState(
                                {
                                  userData: response.data.data,
                                  pageNumber:
                                    response.data.paginationInfo.pageNumber,
                                  totalPageCount:
                                    response.data.paginationInfo.totalPage,
                                },
                                () => {}
                              );
                            }}
                          >
                            {ps.profileStatus.charAt(0).toUpperCase() +
                              ps.profileStatus.slice(1).toLowerCase()}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <MaterialTable
                  title={`List of ${
                    this.state.userType.charAt(0).toUpperCase() +
                    this.state.userType.slice(1).toLowerCase()
                  } App Users`}
                  columns={columns}
                  data={this.state.userData}
                  //action buttons for the table

                  actions={[
                    //modal for notes
                    (rowData) =>
                      this.state.tableCanAuth === 1 && {
                        icon: () => <i className="fas fa-comment-dots fa-xs" />,
                        tooltip: 'Click to log user call notes',

                        onClick: async (event, row) => {
                          const token = getToken();
                          const profileData = await GKApi.get(
                            `/users/user?userId=${rowData.userID}`,

                            {
                              headers: {
                                Authorization: token,
                                cmpId: 46,
                              },
                            }
                          );
                          this.setState({ key: profileData.data }, () => {});
                          this.tog_notesModal();
                        },
                      },

                    //view notes
                    (rowData) =>
                      this.state.tableCanAuth === 1 && {
                        icon: () => (
                          <i className="fas fa-clipboard-list fa-xs" />
                        ),
                        tooltip: 'Click to view notes',

                        onClick: async (event, row) => {
                          const token = getToken();
                          const notesData = await GKApi.get(
                            `/users/user-note?userId=${rowData.userID}`,

                            {
                              headers: {
                                Authorization: token,
                                cmpId: 46,
                              },
                            }
                          );
                          this.setState(
                            { noteData: notesData.data.data },
                            () => {}
                          );
                          this.tog_noteList();
                        },
                      },
                    
                      (rowData) => ({
                            icon: () => <i className="fas fa-history fa-xs" />,
                            tooltip: 'View Pin Reset History',
                            onClick: async (event, row) => {
                              
                              const token = getToken();
                          const pinResetHistory = await GKApi.get(
                            `/users/pin-reset-history?userId=${rowData.userID}`,

                            {
                              headers: {
                                Authorization: token,
                                cmpId: 46,
                              },
                            }
                              );
                              
                              console.log(pinResetHistory.data.landingInitiatePairs)

                              
                              this.setState(
                                  { pinResetData: pinResetHistory.data.landingInitiatePairs},
                                  () => {}
                                );
                              this.tog_pinResetModal()
                            },
                          }),


                    //for editing user details

                    (rowData) => ({
                      icon: () => <i className="fas fa-user-edit fa-xs" />,
                      tooltip: 'Click to edit user data',
                      onClick: async (event, row) => {
                        const token = getToken();
                        const profileData = await GKApi.get(
                          `/users/user?userId=${rowData.userID}`,

                          {
                            headers: {
                              Authorization: token,
                              cmpId: 46,
                            },
                          }
                        );
                        this.setState(
                          {
                            modal_standard: true,
                            key: profileData.data,
                          },
                          () => {}
                        );

                        const professionList = await GKApi.get(
                          `/users/system/profession`,

                          {
                            headers: {
                              Authorization: token,
                              cmpId: 46,
                            },
                          }
                        );
                        this.setState(
                          { professionList: professionList.data },
                          () => {}
                        );

                        this.setState(
                          {
                            name: profileData.data.profileInfo.name,
                            nid: profileData.data.profileInfo.NID,
                            phone: profileData.data.profileInfo.phone,
                            activeStatus:
                              profileData.data.profileInfo.activeStatus,
                            sourceReferralCode:
                              profileData.data.profileInfo.sourceReferralCode,
                            sourceReferralType:
                              profileData.data.profileInfo.sourceReferralType,
                            referralCode:
                              profileData.data.profileInfo.referralCode,
                            profession: profileData.data.profileInfo.profession,
                            dob: profileData.data.profileInfo.dateOfBirth,
                            email: profileData.data.profileInfo.email,
                            profileStatus:
                              profileData.data.profileInfo.profileStatus,
                            gender: profileData.data.profileInfo.gender,
                            maritalStatus:
                              profileData.data.profileInfo.maritalStatus,
                          },
                          () => {}
                        );
                        // image fetching

                        const response = await GKApi.get(
                          `/users/presigned-url?type=get&key=${profileData.data.profileInfo.photoURL}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        this.setState(
                          {
                            ...this.state,
                            profilePic: response.data.url,
                          },
                          () => {}
                        );

                        //nid front image fetching

                        const nid = await GKApi.get(
                          `/users/presigned-url?type=get&key=${profileData.data.profileInfo.nidFrontURL}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        this.setState(
                          {
                            ...this.state,
                            nidFrontPic: nid.data.url,
                          },
                          () => {}
                        );

                        //nid back image fetching

                        const nid2 = await GKApi.get(
                          `/users/presigned-url?type=get&key=${profileData.data.profileInfo.nidBackURL}`,

                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );

                        // const nidBack = axios.get(nid2.data.url);

                        // const picture = URL.createObjectURL(
                        //   (await profilePic).data
                        // );

                        this.setState(
                          {
                            ...this.state,
                            nidBackPic: nid2.data.url,
                          },
                          () => {}
                        );

                        const relationList = await GKApi.get(
                          `/users/system/nominee-relations`,

                          {
                            headers: {
                              Authorization: token,
                              cmpId: 46,
                            },
                          }
                        );
                        this.setState(
                          { relationList: relationList.data },
                          () => {}
                        );
                      },
                    }),

                    //have to pass row data, a default parameter from the table to show button based in data

                    (rowData) =>
                      this.state.tableCanSubmit === 1 && {
                        icon: () => <i className="fas fa-user-alt fa-xs" />,
                        tooltip: 'Click to Enable user',
                        hidden: rowData.activeStatus === 'ENABLED',
                        onClick: async (event, row) => {
                          const token = getToken();
                          const profileData = await GKApi.get(
                            `/users/user?userId=${rowData.userID}`,

                            {
                              headers: {
                                Authorization: token,
                                cmpId: 46,
                              },
                            }
                          );
                          this.setState({ modal_standard2: true }, () => {});
                          this.setState({ key: profileData.data }, () => {});
                        },
                      },
                    (rowData) =>
                      this.state.tableCanSubmit === 1 && {
                        icon: () => <i className="fas fa-user-slash fa-xs" />,
                        tooltip: 'Click to Disable user',
                        hidden:
                          rowData.activeStatus === 'DISABLED' ||
                          rowData.activeStatus === null,
                        onClick: async (event, row) => {
                          const token = getToken();
                          const profileData = await GKApi.get(
                            `/users/user?userId=${rowData.userID}`,

                            {
                              headers: {
                                Authorization: token,
                                cmpId: 46,
                              },
                            }
                          );
                          this.setState({ modal_standard2: true }, () => {});
                          this.setState({ key: profileData.data }, () => {});
                        },
                      },

                    // button to export user table as an excel file

                    this.state.tableCanAuth === 1 && {
                      icon: () => <Button>Export All</Button>, // you can pass icon too
                      tooltip: 'Export to Excel',
                      onClick: () => downloadExcel(),
                      isFreeAction: true,
                    },
                    this.state.tableCanSubmit === 1 && {
                      icon: () => <Button>Reset password</Button>,
                      tooltip: 'Click to reset user password',
                      onClick: async (event, row) => {
                        const token = getToken();
                        const profileData = await GKApi.get(
                          `/users/user?userId=${row.userID}`,

                          {
                            headers: {
                              Authorization: token,
                              cmpId: 46,
                            },
                          }
                        );
                        this.setState({ modal_standard3: true }, () => {});
                        this.setState({ key: profileData.data }, () => {});
                      },
                    },
                  ]}
                  // isLoading={this.state.userData?false:true}
                  options={{
                    search: false,
                    paging: false,
                    actionsCellStyle: { textAlign: 'right' },
                    //indicated the style for the data

                    cellStyle: {
                      borderRight: '1px solid #eee',
                      textAlign: 'left',
                    },

                    //style for the header

                    headerStyle: {
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    },

                    actionsColumnIndex: -1, // to move the action button to right most column
                    // exportButton: { csv: false, pdf: false },
                    // exportFileName: 'App User',
                    pageSize: 20,
                    columnsButton: true, // enables column filtering
                  }}
                  // for the file name indicator

                  // localization={{
                  //   toolbar: {
                  //     exportCSVName: 'Export as CSV',
                  //   },
                  // }}
                />
              </div>

              {/* modal for user details */}

              <div id="46">
                {this.state.userData.length ? (
                  <Modal
                    size="lg"
                    isOpen={this.state.modal_standard}
                    toggle={() => {
                      this.tog_standard();
                      this.setState({
                        profilePic: null,
                        nidFrontPic: null,
                        nidBackPic: null,
                        key: null,
                      });
                    }}
                  >
                    <ModalHeader
                      toggle={() =>
                        this.setState(
                          {
                            modal_standard: false,
                            profilePic: null,
                            nidFrontPic: null,
                            nidBackPic: null,
                            key: null,
                            selectedFiles: [],
                          },
                          () => {}
                        )
                      }
                    >
                      {this.state.key?.profileInfo.name}'s data Edit
                    </ModalHeader>

                    <Col xl={12}>
                      <Card>
                        <CardBody>
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            {/*Profile Info Tab  */}
                            <NavItem>
                              <NavLink
                                style={{ cursor: 'pointer' }}
                                className={classnames({
                                  active: this.state.activeTabJustify === '1',
                                })}
                                onClick={async () => {
                                  this.toggleCustomJustified('1');
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  Profile
                                </span>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                style={{ cursor: 'pointer' }}
                                className={classnames({
                                  active: this.state.activeTabJustify === '2',
                                })}
                                onClick={() => {
                                  this.toggleCustomJustified('2');
                                  this.setState(
                                    {
                                      nomineeName:
                                        this.state.key?.profileInfo.nomineeName,
                                      nomineeNID:
                                        this.state.key?.profileInfo.nomineeNID,
                                      nomineeBirthDate:
                                        this.state.key?.profileInfo
                                          .nomineeDateOfBirth,
                                      nomineePhone:
                                        this.state.key?.profileInfo
                                          .nomineePhone,
                                      nomineeDocURL:
                                        this.state.key?.profileInfo
                                          .nomineeDocURL,
                                      nomineeRelationWithUser: this.state.key
                                        ?.profileInfo.nomineeRelationship
                                        ? this.state.key?.profileInfo
                                            .nomineeRelationship
                                        : 'Select the relation between nominee and user',
                                    },
                                    () => {}
                                  );
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  Nominee Details
                                </span>
                              </NavLink>
                            </NavItem>

                            {/*address Info Tab  */}
                            <NavItem>
                              <NavLink
                                style={{ cursor: 'pointer' }}
                                className={classnames({
                                  active: this.state.activeTabJustify === '3',
                                })}
                                onClick={async () => {
                                  this.toggleCustomJustified('3');
                                  const token = getToken();
                                  const divisionList = await GKApi.get(
                                    '/users/system/division',
                                    {
                                      headers: {
                                        Authorization: token,
                                        cmpId: 46,
                                      },
                                    }
                                  );
                                  this.setState(
                                    {
                                      divisionList: divisionList.data,
                                      division:
                                        this.state.key?.addressInfo?.division,
                                      addressDistrict:
                                        this.state.key?.addressInfo?.district,
                                      thana: this.state.key?.addressInfo?.thana,
                                      thanaId:
                                        this.state.key?.addressInfo?.thanaCode,
                                      address:
                                        this.state.key?.addressInfo?.address,
                                      postalCode:
                                        this.state.key?.addressInfo?.postalCode,
                                      additional:
                                        this.state.key?.addressInfo?.additional,
                                    },
                                    () => {}
                                  );
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  Address
                                </span>
                              </NavLink>
                            </NavItem>

                            {/*Bkash Info Tab  */}
                            <NavItem>
                              <NavLink
                                style={{ cursor: 'pointer' }}
                                className={classnames({
                                  active: this.state.activeTabJustify === '4',
                                })}
                                onClick={() => {
                                  this.toggleCustomJustified('4');
                                  this.setState(
                                    {
                                      bkashAccName:
                                        this.state.key?.bkashInfo?.accountName,
                                      bkashAccNo:
                                        this.state.key?.bkashInfo?.bkashNo,
                                    },
                                    () => {}
                                  );
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  bKash Details
                                </span>
                              </NavLink>
                            </NavItem>

                            {/*Bank info Tab  */}
                            <NavItem>
                              <NavLink
                                style={{ cursor: 'pointer' }}
                                className={classnames({
                                  active: this.state.activeTabJustify === '5',
                                })}
                                onClick={async () => {
                                  this.toggleCustomJustified('5');

                                  const token = getToken();
                                  const bankList = await GKApi.get(
                                    '/users/system/bank',
                                    {
                                      headers: {
                                        Authorization: token,
                                        cmpId: 46,
                                      },
                                    }
                                  );

                                  this.setState(
                                    {
                                      bankList: bankList?.data,
                                      bankType:
                                        this.state.key?.bankInfo?.bankName,
                                      district:
                                        this.state.key?.bankInfo?.bankDistrict,
                                      branch:
                                        this.state.key?.bankInfo?.branchName,
                                      routingNo:
                                        this.state.key?.bankInfo?.routingNo,
                                      accountName:
                                        this.state.key?.bankInfo?.accountName,
                                      accountNo:
                                        this.state.key?.bankInfo?.accountNo,
                                    },
                                    () => {}
                                  );
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  Bank Details
                                </span>
                              </NavLink>
                            </NavItem>

                            {/*User transaction Info tab  */}
                            <NavItem>
                              <NavLink
                                style={{ cursor: 'pointer' }}
                                className={classnames({
                                  active: this.state.activeTabJustify === '6',
                                })}
                                onClick={() => {
                                  this.toggleCustomJustified('6');
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  Transaction Details
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>

                          <TabContent activeTab={this.state.activeTabJustify}>
                            {/*Profile Info Details view  */}
                            <TabPane tabId="1" className="p-3">
                              <ModalBody>
                                {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                                <Row className="mb-4 d-flex justify-content-center align-items-center w-full">
                                  <Avatar
                                    src={
                                      this.state.profilePic
                                      // ? this.state.profilePic
                                      // : 'Not available'
                                    }
                                    alt={this.props.name}
                                    // style={{
                                    //   height: 'auto',
                                    //   maxHeight: 250,
                                    //   width: 'auto',
                                    //   maxWidth: 'auto',
                                    //   // borderRadius: '100%',
                                    //   overflow: 'hidden',
                                    //   borderWidth: 3,
                                    //   display: 'flex',
                                    //   justifyContent: 'center',
                                    // }}
                                    className={this.props.classes.size}
                                  />
                                </Row>

                                {this.state.userDetailsCanAuth === 0 ||
                                this.state.userDetailsCanEdit === 0 ||
                                this.state.userDetailsCanInsert === 0 ||
                                this.state.userDetailsCanEdit === 0 ||
                                this.state.userDetailsCanInsert === 0 ? (
                                  ''
                                ) : (
                                  <div className="my-3 d-flex justify-content-center border-1 border-primary">
                                    <label
                                      htmlFor="formId"
                                      onChange={async (event) => {
                                        const token = getToken();
                                        let Image = event.target.files[0];
                                        const propic = await GKApi.get(
                                          `/users/presigned-url?type=put&key=${this.state.key?.profileInfo.photoURL}`,

                                          {
                                            headers: {
                                              Authorization: token,
                                            },
                                          }
                                        );

                                        this.setState({ proPicLoading: true });
                                        axios.put(propic.data.url, Image);
                                        // const response = await GKApi.get(
                                        //   `/users/presigned-url?type=get&key=${this.state.key?.profileInfo.photoURL}`,

                                        //   {
                                        //     headers: {
                                        //       Authorization: token,
                                        //     },
                                        //   }
                                        // );

                                        const profileData = await GKApi.get(
                                          `/users/user?userId=${this.state.key?.profileInfo.userID}`,

                                          {
                                            headers: {
                                              Authorization: token,
                                              cmpId: 46,
                                            },
                                          }
                                        );

                                        // image fetching

                                        const response = await GKApi.get(
                                          `/users/presigned-url?type=get&key=${profileData.data.profileInfo.photoURL}`,

                                          {
                                            headers: {
                                              Authorization: token,
                                            },
                                          }
                                        );

                                        // this.setState(
                                        //   {
                                        //     ...this.state,
                                        //     profilePic: response.data.url,
                                        //   },
                                        //   () => {}
                                        // );

                                        if (response) {
                                          this.setState(
                                            {
                                              profilePic: response.data.url,
                                            },
                                            () => {}
                                          );
                                          this.setState({
                                            proPicLoading: false,
                                          });
                                        }
                                      }}
                                      className="cursor-pointer"
                                    >
                                      {this.state.proPicLoading === true
                                        ? 'Changing photo, please wait!!'
                                        : 'Change Profile Photo'}
                                      <input type="file" hidden id="formId" />
                                    </label>
                                  </div>
                                )}

                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Name
                                  </Label>
                                  <Col md={9}>
                                    <Input
                                      type="text"
                                      defaultValue={
                                        this.state.key?.profileInfo.name
                                      }
                                      id="example-name-input"
                                      onChange={this.handleNameChange}
                                      disabled={
                                        this.state.userDetailsCanEdit === 1
                                          ? false
                                          : true
                                      }
                                    />
                                    {this.state.errors?.name && (
                                      <p style={{ color: '#FF0000' }}>
                                        Name Invalid
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    NID
                                  </Label>
                                  <Col md={9}>
                                    <Input
                                      type="text"
                                      defaultValue={
                                        this.state.key?.profileInfo.NID
                                      }
                                      id="example-position-input"
                                      onChange={this.handleNIDChange}
                                      disabled={
                                        this.state.userDetailsCanEdit === 1
                                          ? false
                                          : true
                                      }
                                    />
                                    {this.state.errors?.nid && (
                                      <p style={{ color: '#FF0000' }}>
                                        NID Invalid
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Gender
                                  </Label>
                                  <Col md={9}>
                                    <Dropdown
                                      isOpen={this.state.genderMenu}
                                      toggle={(event) =>
                                        this.setState(
                                          {
                                            genderMenu: !this.state.genderMenu,
                                          },
                                          () => {}
                                        )
                                      }
                                    >
                                      <DropdownToggle
                                        className="px-5 py-2"
                                        color="light"
                                      >
                                        {this.state.gender}
                                        <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        style={{
                                          maxHeight: 500,
                                          overflowY: 'scroll',
                                        }}
                                      >
                                        <DropdownItem
                                          onClick={async (event) => {
                                            this.setState(
                                              {
                                                gender: 'Male',
                                              },
                                              () => {}
                                            );
                                          }}
                                          disabled={
                                            this.state.userDetailsCanEdit === 1
                                              ? false
                                              : true
                                          }
                                        >
                                          Male
                                        </DropdownItem>

                                        <DropdownItem
                                          onClick={async (event) => {
                                            this.setState(
                                              {
                                                gender: 'Female',
                                              },
                                              () => {}
                                            );
                                          }}
                                          disabled={
                                            this.state.userDetailsCanEdit === 1
                                              ? false
                                              : true
                                          }
                                        >
                                          Female
                                        </DropdownItem>

                                        <DropdownItem
                                          onClick={async (event) => {
                                            this.setState(
                                              {
                                                gender: 'Others',
                                              },
                                              () => {}
                                            );
                                          }}
                                          disabled={
                                            this.state.userDetailsCanEdit === 1
                                              ? false
                                              : true
                                          }
                                        >
                                          Others
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Marital Status
                                  </Label>
                                  <Col md={9}>
                                    <Dropdown
                                      isOpen={this.state.maritalStatusMenu}
                                      toggle={(event) =>
                                        this.setState(
                                          {
                                            maritalStatusMenu:
                                              !this.state.maritalStatusMenu,
                                          },
                                          () => {}
                                        )
                                      }
                                    >
                                      <DropdownToggle
                                        className="px-5 py-2"
                                        color="light"
                                      >
                                        {this.state.maritalStatus}
                                        <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        style={{
                                          maxHeight: 500,
                                          overflowY: 'scroll',
                                        }}
                                      >
                                        <DropdownItem
                                          onClick={async (event) => {
                                            this.setState(
                                              {
                                                maritalStatus: 'Married',
                                              },
                                              () => {}
                                            );
                                          }}
                                          disabled={
                                            this.state.userDetailsCanEdit === 1
                                              ? false
                                              : true
                                          }
                                        >
                                          Married
                                        </DropdownItem>

                                        <DropdownItem
                                          onClick={async (event) => {
                                            this.setState(
                                              {
                                                maritalStatus: 'Unmarried',
                                              },
                                              () => {}
                                            );
                                          }}
                                          disabled={
                                            this.state.userDetailsCanEdit === 1
                                              ? false
                                              : true
                                          }
                                        >
                                          Unmarried
                                        </DropdownItem>

                                        <DropdownItem
                                          onClick={async (event) => {
                                            this.setState(
                                              {
                                                maritalStatus: 'Divorced',
                                              },
                                              () => {}
                                            );
                                          }}
                                          disabled={
                                            this.state.userDetailsCanEdit === 1
                                              ? false
                                              : true
                                          }
                                        >
                                          Divorced
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Phone number
                                  </Label>
                                  <Col md={9}>
                                    <Input
                                      type="text"
                                      defaultValue={
                                        this.state.key?.profileInfo.phone
                                      }
                                      id="example-salary-input"
                                      onChange={this.handlePhoneChange}
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Email
                                  </Label>
                                  <Col md={9}>
                                    <Input
                                      type="text"
                                      defaultValue={
                                        this.state.key?.profileInfo.email
                                        // ? this.state.email
                                        // : 'Not Available'
                                      }
                                      id="example-salary-input"
                                      onChange={this.handleEmailChange}
                                      // disabled={
                                      //   this.state.userDetailsRole ===
                                      //     'CRM_AUTHORIZER' ||
                                      //   this.state.userDetailsRole ===
                                      //     'CRM_SUPER_USER'
                                      //     ? false
                                      //     : true
                                      // }
                                      disabled={
                                        this.state.userDetailsCanEdit === 1
                                          ? false
                                          : true
                                      }
                                    />
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    NID Front
                                  </Label>
                                  <Col md={9}>
                                    <img
                                      src={
                                        this.state.nidFrontPic
                                          ? this.state.nidFrontPic
                                          : 'Not available'
                                      }
                                      alt={'Loading'}
                                      style={{
                                        height: 'auto',
                                        maxHeight: 250,
                                        width: 'auto',
                                        maxWidth: 250,
                                        // borderRadius: '25%',
                                        overflow: 'hidden',
                                        borderWidth: 3,
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    />

                                    {this.state.userDetailsCanAuth === 0 ||
                                    this.state.userDetailsCanEdit === 0 ||
                                    this.state.userDetailsCanInsert === 0 ||
                                    this.state.userDetailsCanEdit === 0 ||
                                    this.state.userDetailsCanInsert === 0 ? (
                                      ''
                                    ) : (
                                      <div className="my-3 border-1">
                                        <label
                                          htmlFor="nidFront"
                                          onChange={async (event) => {
                                            const token = getToken();
                                            let Image = event.target.files[0];
                                            const nidFrontPic = await GKApi.get(
                                              `/users/presigned-url?type=put&key=${this.state.key?.profileInfo.nidFrontURL}`,

                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );
                                            this.setState({
                                              nidFrontLoading: true,
                                            });
                                            axios.put(
                                              nidFrontPic.data.url,
                                              Image
                                            );
                                            const response = await GKApi.get(
                                              `/users/presigned-url?type=get&key=${this.state.key?.profileInfo.nidFrontURL}`,

                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );

                                            this.setState(
                                              {
                                                ...this.state,
                                                nidFrontPic: response.data.url,
                                              },
                                              () => {}
                                            );
                                            this.setState({
                                              nidFrontLoading: false,
                                            });
                                          }}
                                          className="cursor-pointer"
                                        >
                                          {this.state.nidFrontLoading === true
                                            ? 'Changing NID Front photo, Please wait!!'
                                            : 'Change NID Front Photo'}
                                          <input
                                            type="file"
                                            hidden
                                            id="nidFront"
                                          />
                                        </label>
                                      </div>
                                    )}
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    NID Back
                                  </Label>
                                  <Col>
                                    <img
                                      src={
                                        this.state.nidBackPic
                                          ? this.state.nidBackPic
                                          : 'Not available'
                                      }
                                      alt={'Loading'}
                                      style={{
                                        height: 'auto',
                                        maxHeight: 250,
                                        width: 'auto',
                                        maxWidth: 250,
                                        // borderRadius: '25%',
                                        overflow: 'hidden',
                                        borderWidth: 3,
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    />
                                    {this.state.userDetailsCanAuth === 0 ||
                                    this.state.userDetailsCanEdit === 0 ||
                                    this.state.userDetailsCanInsert === 0 ? (
                                      ''
                                    ) : (
                                      <div className="my-3 border-1">
                                        <label
                                          htmlFor="nidBack"
                                          onChange={async (event) => {
                                            const token = getToken();
                                            let Image = event.target.files[0];
                                            const nidBackPic = await GKApi.get(
                                              `/users/presigned-url?type=put&key=${this.state.key?.profileInfo.nidBackURL}`,

                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );
                                            this.setState({
                                              nidBackLoading: true,
                                            });
                                            axios.put(
                                              nidBackPic.data.url,
                                              Image
                                            );
                                            const response = await GKApi.get(
                                              `/users/presigned-url?type=get&key=${this.state.key?.profileInfo.nidBackURL}`,

                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );

                                            this.setState(
                                              {
                                                ...this.state,
                                                nidBackPic: response.data.url,
                                              },
                                              () => {}
                                            );
                                            this.setState({
                                              nidBackLoading: false,
                                            });
                                          }}
                                          className="cursor-pointer"
                                        >
                                          {this.state.nidBackLoading === true
                                            ? 'Changing NID Back photo, Please wait!!'
                                            : 'Change NID Back Photo'}
                                          <input
                                            type="file"
                                            hidden
                                            id="nidBack"
                                          />
                                        </label>
                                      </div>
                                    )}
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Date of Birth
                                  </Label>
                                  <Col md={9}>
                                    <div
                                      style={{
                                        border: '1px solid gray',
                                        borderRadius: '5px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        paddingRight: '4px',
                                        paddingLeft: '4px',
                                      }}
                                    >
                                      <DatePickerComponent
                                        selectedDate={this.state.dob}
                                        handleSelectedDateChange={
                                          this.handleDOBChange
                                        }
                                        disabled={
                                          this.state.userDetailsCanEdit === 1
                                            ? false
                                            : true
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Profession
                                  </Label>
                                  <Col md={9}>
                                    <Dropdown
                                      isOpen={this.state.professionListMenu}
                                      toggle={(event) =>
                                        this.setState(
                                          {
                                            professionListMenu:
                                              !this.state.professionListMenu,
                                          },
                                          () => {}
                                        )
                                      }
                                    >
                                      <DropdownToggle
                                        className="px-5 py-2"
                                        color="light"
                                      >
                                        {this.state.profession}
                                        <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        style={{
                                          maxHeight: 500,
                                          overflowY: 'scroll',
                                        }}
                                      >
                                        {this.state.professionList.map(
                                          (pr, index) => (
                                            <DropdownItem
                                              key={index}
                                              onClick={async (event) => {
                                                this.setState(
                                                  {
                                                    profession: pr.profession,
                                                    professionCode:
                                                      pr.professionCode,
                                                  },
                                                  () => {}
                                                );
                                              }}
                                              disabled={
                                                this.state
                                                  .userDetailsCanEdit === 1
                                                  ? false
                                                  : true
                                              }
                                            >
                                              {pr.profession}
                                            </DropdownItem>
                                          )
                                        )}
                                      </DropdownMenu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Referral Code
                                  </Label>
                                  <Col md={9}>
                                    <Input
                                      type="text"
                                      defaultValue={
                                        this.state.key?.profileInfo.referralCode
                                      }
                                      id="example-salary-input"
                                      onChange={this.handleReferralCodeChange}
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Source Referral Code
                                  </Label>
                                  <Col md={9}>
                                    <Input
                                      type="text"
                                      defaultValue={
                                        this.state.key?.profileInfo
                                          .sourceReferralCode
                                      }
                                      id="example-salary-input"
                                      onChange={
                                        this.handleSourceReferralCodeChange
                                      }
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Source Referral Code Type
                                  </Label>
                                  <Col md={9}>
                                    <Input
                                      type="text"
                                      defaultValue={
                                        this.state.key?.profileInfo
                                          .sourceReferralType
                                      }
                                      id="example-salary-input"
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Label
                                    htmlFor="profile-status"
                                    className="col-md-3 col-form-label"
                                  >
                                    Active Status
                                  </Label>
                                  <Col md={9}>
                                    <Input
                                      type="text"
                                      defaultValue={
                                        this.state.key?.profileInfo
                                          .activeStatus === 1
                                          ? 'Active'
                                          : 'Inactive'
                                      }
                                      id="profile-status"
                                      // onChange={
                                      //   this.handleSourceReferralCodeChange
                                      // }
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Label
                                    htmlFor="example-name-input"
                                    className="col-md-3 col-form-label"
                                  >
                                    Profile Status
                                  </Label>
                                  <Col md={9}>
                                    <Input
                                      type="text"
                                      defaultValue={
                                        this.state.key?.profileInfo.profileStatus
                                          .charAt(0)
                                          .toUpperCase() +
                                        this.state.key?.profileInfo.profileStatus
                                          .slice(1)
                                          .toLowerCase()
                                      }
                                      id="example-salary-input"
                                      disabled
                                    />
                                  </Col>
                                </Row>
                                {/* <Button className="float-end">Update</Button> */}
                                {this.state.verificationCanSubmit === 1 && (
                                  <Row>
                                    <Label
                                      htmlFor="example-name-input"
                                      className="col-md-3 col-form-label"
                                    >
                                      Change profile status:{' '}
                                    </Label>
                                    {this.state.userDetailsCanAuth === 1 ? (
                                      <Col md={9} className="d-flex gap-2">
                                        {this.state.profileStatusTypes.map(
                                          (ps, index) => (
                                            <div>
                                              {this.state.key?.profileInfo
                                                .profileStatus ===
                                              ps.profileStatus ? (
                                                ''
                                              ) : (
                                                <Button
                                                  type="button"
                                                  key={index}
                                                  onClick={async (event) => {
                                                    event.preventDefault();
                                                    this.setState(
                                                      {
                                                        selectedProfileStatusType:
                                                          ps.profileStatus,
                                                      },
                                                      () => {}
                                                    );
                                                    this.setState(
                                                      (prevState) => ({
                                                        modal_standard4:
                                                          !prevState.modal_standard4,
                                                      })
                                                    );
                                                    this.removeBodyCss();
                                                  }}
                                                >
                                                  {ps.profileStatus
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    ps.profileStatus
                                                      .slice(1)
                                                      .toLowerCase()}
                                                </Button>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </Col>
                                    ) : this.state.userDetailsCanSubmit ===
                                      1 ? (
                                      <Col md={9}>
                                        <div id="45" className="d-flex gap-2">
                                          {this.state.key?.profileInfo
                                            .profileStatus !== 'VERIFIED' && (
                                            <Button
                                              type="button"
                                              onClick={async (event) => {
                                                event.preventDefault();
                                                this.setState(
                                                  {
                                                    selectedProfileStatusType:
                                                      'Verified',
                                                  },
                                                  () => {}
                                                );
                                                this.setState((prevState) => ({
                                                  modal_standard10:
                                                    !prevState.modal_standard10,
                                                }));
                                                this.removeBodyCss();
                                              }}
                                            >
                                              Verify
                                            </Button>
                                          )}
                                          {this.state.key?.profileInfo
                                            .profileStatus !== 'LOCKED' && (
                                            <Button
                                              type="button"
                                              onClick={async (event) => {
                                                event.preventDefault();
                                                this.setState(
                                                  {
                                                    selectedProfileStatusType:
                                                      'Locked',
                                                  },
                                                  () => {}
                                                );
                                                this.setState((prevState) => ({
                                                  modal_standard10:
                                                    !prevState.modal_standard10,
                                                }));
                                                this.removeBodyCss();
                                              }}
                                            >
                                              Lock
                                            </Button>
                                          )}
                                        </div>
                                      </Col>
                                    ) : (
                                      ''
                                    )}
                                  </Row>
                                )}
                              </ModalBody>
                              {this.state.userDetailsCanAuth === 1 &&
                                this.state.userDetailsCanInsert === 1 &&
                                this.state.userDetailsCanEdit === 1 && (
                                  <ModalFooter>
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        this.tog_standard();
                                        this.setState({
                                          profilePic: null,
                                          nidFrontPic: null,
                                          nidBackPic: null,
                                          key: null,
                                        });
                                      }}
                                      color="light"
                                      className="waves-effect"
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="waves-effect waves-light"
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState((prevState) => ({
                                          modal_standard9:
                                            !prevState.modal_standard9,
                                        }));
                                        this.removeBodyCss();
                                      }}
                                      disabled={
                                        this.state.errors.name ||
                                        this.state.errors.nid
                                          ? true
                                          : false
                                      }
                                    >
                                      Update profile Details
                                    </Button>
                                  </ModalFooter>
                                )}
                            </TabPane>

                            {/*Nominee Info Details view  */}
                            <TabPane tabId="2" className="p-3">
                              <Row>
                                <Col sm="12">
                                  <CardText>
                                    <>
                                      <Row className="mb-3">
                                        <Label
                                          htmlFor="example-name-input"
                                          className="col-md-3 col-form-label"
                                        >
                                          Nominee Name
                                        </Label>
                                        <Col md={9}>
                                          <Input
                                            type="text"
                                            defaultValue={
                                              this.state.key?.profileInfo
                                                .nomineeName
                                            }
                                            id="example-salary-input"
                                            onChange={
                                              this.handleNomineeNameChange
                                            }
                                            disabled={
                                              this.state.userDetailsCanEdit ===
                                              1
                                                ? false
                                                : true
                                            }
                                          />
                                          {this.state.errors?.nomineeName && (
                                            <p style={{ color: '#FF0000' }}>
                                              Nominee Name Invalid
                                            </p>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row className="mb-3">
                                        <Label
                                          htmlFor="example-name-input"
                                          className="col-md-3 col-form-label"
                                        >
                                          Nominee NID
                                        </Label>
                                        <Col md={9}>
                                          <Input
                                            type="text"
                                            defaultValue={
                                              this.state.key?.profileInfo
                                                .nomineeNID
                                            }
                                            id="example-salary-input"
                                            onChange={
                                              this.handleNomineeNIDChange
                                            }
                                            disabled={
                                              this.state.userDetailsCanEdit ===
                                              1
                                                ? false
                                                : true
                                            }
                                          />
                                          {this.state.errors?.nomineeNID && (
                                            <p style={{ color: '#FF0000' }}>
                                              Nominee Nid Invalid
                                            </p>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row className="mb-3">
                                        <Label
                                          htmlFor="example-name-input"
                                          className="col-md-3 col-form-label"
                                        >
                                          Nominee Date of Birth
                                        </Label>
                                        <Col md={9}>
                                          <div
                                            style={{
                                              border: '1px solid gray',
                                              borderRadius: '5px',
                                              paddingTop: '5px',
                                              paddingBottom: '5px',
                                              paddingRight: '4px',
                                              paddingLeft: '4px',
                                            }}
                                          >
                                            <DatePickerComponent
                                              selectedDate={
                                                this.state.nomineeBirthDate
                                              }
                                              handleSelectedDateChange={
                                                this
                                                  .handleNomineeBirthDateChange
                                              }
                                              disabled={
                                                this.state
                                                  .userDetailsCanEdit === 1
                                                  ? false
                                                  : true
                                              }
                                              // defaultValue={
                                              //   this.state.key?.profileInfo.key?.profileInfo.nomineeDateOfBirth
                                              // }
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="mb-3">
                                        <Label
                                          htmlFor="example-name-input"
                                          className="col-md-3 col-form-label"
                                        >
                                          Nominee Phone Number
                                        </Label>
                                        <Col md={9}>
                                          <Input
                                            type="text"
                                            defaultValue={
                                              this.state.key?.profileInfo
                                                .nomineePhone
                                            }
                                            id="example-salary-input"
                                            onChange={
                                              this.handleNomineePhoneChange
                                            }
                                            disabled={
                                              this.state.userDetailsCanEdit ===
                                              1
                                                ? false
                                                : true
                                            }
                                          />
                                          {this.state.errors?.nomineePhone && (
                                            <p style={{ color: '#FF0000' }}>
                                              Nominee Phone Invalid
                                            </p>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row className="mb-3">
                                        <Label
                                          htmlFor="example-name-input"
                                          className="col-md-3 col-form-label"
                                        >
                                          Nominee Relation with User
                                        </Label>
                                        <Col md={9}>
                                          <Dropdown
                                            isOpen={this.state.relationListMenu}
                                            toggle={(event) =>
                                              this.setState(
                                                {
                                                  relationListMenu:
                                                    !this.state
                                                      .relationListMenu,
                                                },
                                                () => {}
                                              )
                                            }
                                          >
                                            <DropdownToggle
                                              className="px-5 py-2"
                                              color="light"
                                            >
                                              {
                                                this.state
                                                  .nomineeRelationWithUser
                                              }
                                              <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu
                                              style={{
                                                maxHeight: 500,
                                                overflowY: 'scroll',
                                              }}
                                            >
                                              {this.state.relationList.map(
                                                (re, index) => (
                                                  <DropdownItem
                                                    key={index}
                                                    onClick={async (event) => {
                                                      this.setState(
                                                        {
                                                          nomineeRelationWithUser:
                                                            re.relation,
                                                          relationCode: re.code,
                                                        },
                                                        () => {}
                                                      );
                                                    }}
                                                    disabled={
                                                      this.state
                                                        .userDetailsCanEdit ===
                                                      1
                                                        ? false
                                                        : true
                                                    }
                                                  >
                                                    {re.relation}
                                                  </DropdownItem>
                                                )
                                              )}
                                            </DropdownMenu>
                                          </Dropdown>
                                        </Col>
                                      </Row>

                                      <Row className="mb-3">
                                        <Label
                                          htmlFor="example-name-input"
                                          className="col-md-3 col-form-label"
                                        >
                                          Nominee Update Document
                                        </Label>
                                        <Col md={9}>
                                          {this.state.nomineeDocURL ? (
                                            <a
                                              rel="noopener noreferrer"
                                              href={this.state.nomineeDocURL}
                                              target="_blank"
                                            >
                                              View Document
                                            </a>
                                          ) : (
                                            <p>Not available</p>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row className="mb-3">
                                        <Label
                                          htmlFor="example-name-input"
                                          className="col-md-3 col-form-label"
                                        >
                                          Nominee Update Document
                                        </Label>
                                        <Col md={9}>
                                          <Form>
                                            <h6 className="fw-bold">
                                              Upload Nominee Update Document
                                            </h6>
                                            <Dropzone
                                              accept="image/*,.pdf"
                                              onDrop={(acceptedFiles) =>
                                                this.handleAcceptedFiles(
                                                  acceptedFiles
                                                )
                                              }
                                            >
                                              {({
                                                getRootProps,
                                                getInputProps,
                                              }) => (
                                                <div className="dropzone">
                                                  <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                  >
                                                    <input
                                                      {...getInputProps()}
                                                    />
                                                    <div className="mb-3">
                                                      <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                    </div>
                                                    <h4>
                                                      Drag & Drop Your File Here
                                                    </h4>
                                                  </div>
                                                </div>
                                              )}
                                            </Dropzone>
                                            <div
                                              className="dropzone-previews mt-3"
                                              id="file-previews"
                                            >
                                              {this.state.selectedFiles.map(
                                                (f, i) => {
                                                  return (
                                                    <Card
                                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                      key={i + '-file'}
                                                    >
                                                      <div className="p-2">
                                                        <Row className="align-items-center">
                                                          <Col className="col-auto">
                                                            <img
                                                              data-dz-thumbnail=""
                                                              height="80"
                                                              className="avatar-sm rounded bg-light"
                                                              alt={f.name}
                                                              src={f.preview}
                                                            />
                                                          </Col>
                                                          <Col>
                                                            <Link
                                                              to="#"
                                                              className="text-muted fw-bold"
                                                            >
                                                              {f.name}
                                                            </Link>
                                                            <p className="mb-0">
                                                              <strong>
                                                                {
                                                                  f.formattedSize
                                                                }
                                                              </strong>
                                                            </p>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    </Card>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </Form>
                                        </Col>
                                      </Row>
                                      <Row className="mb-3">
                                        <h5>
                                          {' '}
                                          Please upload a nominee update details
                                          document to update nominee details!!!
                                        </h5>
                                      </Row>
                                    </>
                                  </CardText>
                                </Col>
                              </Row>
                              {this.state.userDetailsCanAuth === 1 &&
                                this.state.userDetailsCanInsert === 1 &&
                                this.state.userDetailsCanEdit === 1 && (
                                  <ModalFooter>
                                    <Button
                                      type="button"
                                      onClick={this.tog_standard}
                                      color="light"
                                      className="waves-effect"
                                    >
                                      Close
                                    </Button>
                                    {this.state.selectedFiles.length > 0 && (
                                      <Button
                                        type="submit"
                                        color="primary"
                                        className="waves-effect waves-light"
                                        onClick={async (event) => {
                                          event.preventDefault();
                                          this.setState((prevState) => ({
                                            modal_standard5:
                                              !prevState.modal_standard5,
                                          }));
                                          this.removeBodyCss();
                                        }}
                                        disabled={
                                          this.state.errors?.nomineeName ||
                                          this.state.errors?.nomineeNID ||
                                          this.state.errors?.nomineePhone
                                            ? true
                                            : false
                                        }
                                      >
                                        Update Nominee Details
                                      </Button>
                                    )}
                                  </ModalFooter>
                                )}
                            </TabPane>

                            {/*Address Info Details view  */}
                            <TabPane tabId="3" className="p-3">
                              <Row>
                                <Col sm="12">
                                  <CardText>
                                    {this.state.key?.addressInfo ? (
                                      <>
                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Division
                                          </Label>
                                          <Col md={9}>
                                            <Dropdown
                                              isOpen={
                                                this.state.divisionListMenu
                                              }
                                              toggle={(event) =>
                                                this.setState(
                                                  {
                                                    divisionListMenu:
                                                      !this.state
                                                        .divisionListMenu,
                                                  },
                                                  () => {}
                                                )
                                              }
                                            >
                                              <DropdownToggle
                                                className="px-5 py-2"
                                                color="light"
                                              >
                                                {this.state.division}
                                                <i className="mdi mdi-chevron-down"></i>
                                              </DropdownToggle>
                                              <DropdownMenu
                                                style={{
                                                  maxHeight: 500,
                                                  overflowY: 'scroll',
                                                }}
                                              >
                                                {this.state.divisionList.map(
                                                  (dv, index) => (
                                                    <DropdownItem
                                                      key={index}
                                                      onClick={async (
                                                        event
                                                      ) => {
                                                        this.setState(
                                                          {
                                                            division:
                                                              dv.divisionName,
                                                            divisionId:
                                                              dv.divisionId,
                                                          },
                                                          () => {}
                                                        );

                                                        const token =
                                                          getToken();

                                                        const addressDistrictList =
                                                          await GKApi.get(
                                                            `/users/system/district?divisionId=${dv.divisionId}`,
                                                            {
                                                              headers: {
                                                                Authorization:
                                                                  token,
                                                                cmpId: 46,
                                                              },
                                                            }
                                                          );
                                                        this.setState(
                                                          {
                                                            addressDistrictList:
                                                              addressDistrictList.data,
                                                          },
                                                          () => {}
                                                        );
                                                      }}
                                                      disabled={
                                                        this.state
                                                          .userDetailsCanEdit ===
                                                        1
                                                          ? false
                                                          : true
                                                      }
                                                    >
                                                      {dv.divisionName}
                                                    </DropdownItem>
                                                  )
                                                )}
                                              </DropdownMenu>
                                            </Dropdown>
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            District
                                          </Label>
                                          <Col md={9}>
                                            {/* <Input
                                            type="text"
                                            defaultValue={
                                              this.state.key?.addressInfo
                                                .district
                                            }
                                            id="example-name-input"
                                          /> */}

                                            <Dropdown
                                              isOpen={
                                                this.state
                                                  .addressDistrictListMenu
                                              }
                                              toggle={(event) =>
                                                this.setState(
                                                  {
                                                    addressDistrictListMenu:
                                                      !this.state
                                                        .addressDistrictListMenu,
                                                  },
                                                  () => {}
                                                )
                                              }
                                            >
                                              <DropdownToggle
                                                className="px-5 py-2"
                                                color="light"
                                              >
                                                {/* {this.state.key?.bankInfo.bankName
                                                .charAt(0)
                                                .toUpperCase() +
                                                this.state.key?.bankInfo.bankName
                                                  .slice(1)
                                                  .toLowerCase()} */}
                                                {this.state.addressDistrict}
                                                <i className="mdi mdi-chevron-down"></i>
                                              </DropdownToggle>
                                              <DropdownMenu
                                                style={{
                                                  maxHeight: 500,
                                                  overflowY: 'scroll',
                                                }}
                                              >
                                                {this.state.addressDistrictList.map(
                                                  (ads, index) => (
                                                    <DropdownItem
                                                      key={index}
                                                      onClick={async (
                                                        event
                                                      ) => {
                                                        this.setState(
                                                          {
                                                            addressDistrict:
                                                              ads.districtName,
                                                            addressDistrictId:
                                                              ads.districtId,
                                                          },
                                                          () => {}
                                                        );
                                                        const token =
                                                          getToken();

                                                        const thanaList =
                                                          await GKApi.get(
                                                            `/users/system/thana?districtId=${ads.districtId}`,
                                                            {
                                                              headers: {
                                                                Authorization:
                                                                  token,
                                                                cmpId: 46,
                                                              },
                                                            }
                                                          );
                                                        this.setState(
                                                          {
                                                            thanaList:
                                                              thanaList.data,
                                                          },
                                                          () => {}
                                                        );
                                                      }}
                                                      disabled={
                                                        this.state
                                                          .userDetailsCanEdit ===
                                                        1
                                                          ? false
                                                          : true
                                                      }
                                                    >
                                                      {ads.districtName}
                                                    </DropdownItem>
                                                  )
                                                )}
                                              </DropdownMenu>
                                            </Dropdown>
                                          </Col>
                                        </Row>

                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Thana
                                          </Label>
                                          <Col md={9}>
                                            <Dropdown
                                              isOpen={this.state.thanaListMenu}
                                              toggle={(event) =>
                                                this.setState(
                                                  {
                                                    thanaListMenu:
                                                      !this.state.thanaListMenu,
                                                  },
                                                  () => {}
                                                )
                                              }
                                            >
                                              <DropdownToggle
                                                className="px-5 py-2"
                                                color="light"
                                              >
                                                {this.state.thana}
                                                <i className="mdi mdi-chevron-down"></i>
                                              </DropdownToggle>
                                              <DropdownMenu
                                                style={{
                                                  maxHeight: 500,
                                                  overflowY: 'scroll',
                                                }}
                                              >
                                                {this.state.thanaList.map(
                                                  (th, index) => (
                                                    <DropdownItem
                                                      key={index}
                                                      onClick={async (
                                                        event
                                                      ) => {
                                                        this.setState(
                                                          {
                                                            thana: th.thanaName,
                                                            thanaId: th.thanaId,
                                                          },
                                                          () => {}
                                                        );

                                                        this.setState(
                                                          {
                                                            thana: th.thanaName,
                                                            thanaId: th.thanaId,
                                                          },
                                                          () => {}
                                                        );
                                                      }}
                                                      disabled={
                                                        this.state
                                                          .userDetailsCanEdit ===
                                                        1
                                                          ? false
                                                          : true
                                                      }
                                                    >
                                                      {th.thanaName}
                                                    </DropdownItem>
                                                  )
                                                )}
                                              </DropdownMenu>
                                            </Dropdown>
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Address
                                          </Label>
                                          <Col md={9}>
                                            <Input
                                              type="text"
                                              defaultValue={this.state.address}
                                              id="example-salary-input"
                                              onChange={
                                                this.handleAddressChange
                                              }
                                              disabled={
                                                this.state
                                                  .userDetailsCanEdit === 1
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </Col>
                                        </Row>

                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-postal-code-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Postal Code
                                          </Label>
                                          <Col md={9}>
                                            <Input
                                              type="text"
                                              defaultValue={
                                                this.state.postalCode
                                              }
                                              id="example-postal-code-input"
                                              onChange={
                                                this.handlePostalCodeNameChange
                                              }
                                              disabled={
                                                this.state
                                                  .userDetailsCanEdit === 1
                                                  ? false
                                                  : true
                                              }
                                            />
                                            {(Math.ceil(
                                              Math.log10(this.state.postalCode)
                                            ) > 5 ||
                                              Math.ceil(
                                                Math.log10(
                                                  this.state.postalCode
                                                )
                                              ) < 4) && (
                                              <p>
                                                Postal code should be between 4
                                                and 5 digits
                                              </p>
                                            )}
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Additional Details
                                          </Label>
                                          <Col md={9}>
                                            <Input
                                              type="text"
                                              defaultValue={
                                                this.state.additional
                                              }
                                              id="example-salary-input"
                                              onChange={
                                                this.handleAdditionalChange
                                              }
                                              disabled={
                                                this.state
                                                  .userDetailsCanEdit === 1
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      <h4 className="text-center">
                                        No data available
                                      </h4>
                                    )}
                                  </CardText>
                                </Col>
                              </Row>
                              {this.state.userDetailsCanAuth === 1 &&
                                this.state.userDetailsCanInsert === 1 &&
                                this.state.userDetailsCanEdit === 1 && (
                                  <ModalFooter>
                                    <Button
                                      type="button"
                                      onClick={this.tog_standard}
                                      color="light"
                                      className="waves-effect"
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="waves-effect waves-light"
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState((prevState) => ({
                                          modal_standard8:
                                            !prevState.modal_standard8,
                                        }));
                                        this.removeBodyCss();
                                      }}
                                      disabled={
                                        Math.ceil(
                                          Math.log10(this.state.postalCode)
                                        ) > 5
                                          ? true
                                          : Math.ceil(
                                              Math.log10(this.state.postalCode)
                                            ) < 4
                                          ? true
                                          : false
                                      }
                                    >
                                      Update Address Details
                                    </Button>
                                  </ModalFooter>
                                )}
                            </TabPane>

                            {/*Bkash Info Details view  */}
                            <TabPane tabId="4" className="p-3">
                              <Row>
                                <Col sm="12">
                                  <CardText>
                                    {this.state.key?.bkashInfo ? (
                                      <>
                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Bkash account name
                                          </Label>
                                          <Col md={9}>
                                            <Input
                                              type="text"
                                              defaultValue={
                                                this.state.bkashAccName
                                              }
                                              id="example-name-input"
                                              onChange={
                                                this
                                                  .handleBkashAccountNameChange
                                              }
                                              disabled={
                                                this.state
                                                  .userDetailsCanEdit === 1
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Bkash account no
                                          </Label>
                                          <Col md={9}>
                                            <Input
                                              type="text"
                                              defaultValue={
                                                this.state.bkashAccNo
                                              }
                                              id="example-position-input"
                                              onChange={
                                                this.handleBkashAccountNoChange
                                              }
                                              disabled={
                                                this.state
                                                  .userDetailsCanEdit === 1
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      <h4 className="text-center">
                                        No data available
                                      </h4>
                                    )}
                                  </CardText>
                                </Col>
                              </Row>
                              {this.state.userDetailsCanAuth === 1 &&
                                this.state.userDetailsCanInsert === 1 &&
                                this.state.userDetailsCanEdit === 1 && (
                                  <ModalFooter>
                                    <Button
                                      type="button"
                                      onClick={this.tog_standard}
                                      color="light"
                                      className="waves-effect"
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="waves-effect waves-light"
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState((prevState) => ({
                                          modal_standard7:
                                            !prevState.modal_standard7,
                                        }));
                                        this.removeBodyCss();
                                      }}
                                    >
                                      Update Bkash Details
                                    </Button>
                                  </ModalFooter>
                                )}
                            </TabPane>

                            {/*Bank Info Details view  */}
                            <TabPane tabId="5" className="p-3">
                              <Row>
                                <Col sm="12">
                                  <CardText>
                                    {this.state.key?.bankInfo ? (
                                      <>
                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Bank Name
                                          </Label>
                                          <Col md={9}>
                                            {/* <Input
                                            type="text"
                                            defaultValue={
                                              this.state.key?.bankInfo.bankName
                                            }
                                            id="example-name-input"
                                          /> */}

                                            <Dropdown
                                              isOpen={this.state.bankListMenu}
                                              toggle={(event) =>
                                                this.setState({
                                                  bankListMenu:
                                                    !this.state.bankListMenu,
                                                })
                                              }
                                            >
                                              <DropdownToggle
                                                className="px-5 py-2"
                                                color="light"
                                              >
                                                {/* {this.state.key?.bankInfo.bankName
                                                .charAt(0)
                                                .toUpperCase() +
                                                this.state.key?.bankInfo.bankName
                                                  .slice(1)
                                                  .toLowerCase()} */}
                                                {this.state.bankType}
                                                <i className="mdi mdi-chevron-down"></i>
                                              </DropdownToggle>
                                              <DropdownMenu
                                                style={{
                                                  maxHeight: 500,
                                                  overflowY: 'scroll',
                                                }}
                                              >
                                                {this.state.bankList.map(
                                                  (bl, index) => (
                                                    <DropdownItem
                                                      key={index}
                                                      onClick={async (
                                                        event
                                                      ) => {
                                                        this.setState(
                                                          {
                                                            bankType:
                                                              bl.bankName,
                                                            bankId: bl.bankId,
                                                          },
                                                          () => {}
                                                        );

                                                        const token =
                                                          getToken();

                                                        const districtList =
                                                          await GKApi.get(
                                                            `/users/system/bank-district?bankId=${bl.bankId}`,
                                                            {
                                                              headers: {
                                                                Authorization:
                                                                  token,
                                                                cmpId: 46,
                                                              },
                                                            }
                                                          );
                                                        this.setState(
                                                          {
                                                            districtList:
                                                              districtList.data,
                                                            district:
                                                              this.state.key
                                                                ?.bankInfo
                                                                .bankDistrict,
                                                          },
                                                          () => {}
                                                        );
                                                      }}
                                                      disabled={
                                                        this.state
                                                          .userDetailsCanEdit ===
                                                        1
                                                          ? false
                                                          : true
                                                      }
                                                    >
                                                      {bl.bankName}
                                                    </DropdownItem>
                                                  )
                                                )}
                                              </DropdownMenu>
                                            </Dropdown>
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Bank District
                                          </Label>
                                          <Col md={9}>
                                            <Dropdown
                                              isOpen={
                                                this.state.districtListMenu
                                              }
                                              toggle={(event) =>
                                                this.setState({
                                                  districtListMenu:
                                                    !this.state
                                                      .districtListMenu,
                                                })
                                              }
                                            >
                                              <DropdownToggle
                                                className="px-5 py-2"
                                                color="light"
                                              >
                                                {/* {this.state.key?.bankInfo.bankName
                                                .charAt(0)
                                                .toUpperCase() +
                                                this.state.key?.bankInfo.bankName
                                                  .slice(1)
                                                  .toLowerCase()} */}
                                                {
                                                  // this.state.districtList !==[]
                                                  //   ? this.state.districtList[0]
                                                  //       .bankDistrictName
                                                  //   :
                                                  this.state.district
                                                }
                                                <i className="mdi mdi-chevron-down"></i>
                                              </DropdownToggle>
                                              <DropdownMenu
                                                style={{
                                                  maxHeight: 500,
                                                  overflowY: 'scroll',
                                                }}
                                              >
                                                {this.state.districtList.map(
                                                  (ds, index) => (
                                                    <DropdownItem
                                                      key={index}
                                                      onClick={async (
                                                        event
                                                      ) => {
                                                        this.setState(
                                                          {
                                                            district:
                                                              ds.bankDistrictName,
                                                            districtId:
                                                              ds.bankDistrictId,
                                                          },
                                                          () => {}
                                                        );
                                                        const token =
                                                          getToken();

                                                        const branchList =
                                                          await GKApi.get(
                                                            `/users/system/bank-branch?bankId=${this.state.bankId}&bankDistrictId=${ds.bankDistrictId}`,
                                                            {
                                                              headers: {
                                                                Authorization:
                                                                  token,
                                                                cmpId: 46,
                                                              },
                                                            }
                                                          );
                                                        this.setState(
                                                          {
                                                            branchList:
                                                              branchList.data,
                                                            branch:
                                                              this.state.key
                                                                ?.bankInfo
                                                                .bankDistrict,
                                                          },
                                                          () => {}
                                                        );
                                                      }}
                                                      disabled={
                                                        this.state
                                                          .userDetailsCanEdit ===
                                                        1
                                                          ? false
                                                          : true
                                                      }
                                                    >
                                                      {ds.bankDistrictName}
                                                    </DropdownItem>
                                                  )
                                                )}
                                              </DropdownMenu>
                                            </Dropdown>
                                          </Col>
                                        </Row>

                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Branch Name
                                          </Label>
                                          <Col md={9}>
                                            <Dropdown
                                              isOpen={this.state.branchListMenu}
                                              toggle={(event) =>
                                                this.setState({
                                                  branchListMenu:
                                                    !this.state.branchListMenu,
                                                })
                                              }
                                            >
                                              <DropdownToggle
                                                className="px-5 py-2"
                                                color="light"
                                              >
                                                {this.state.branch}
                                                <i className="mdi mdi-chevron-down"></i>
                                              </DropdownToggle>
                                              <DropdownMenu
                                                style={{
                                                  maxHeight: 500,
                                                  overflowY: 'scroll',
                                                }}
                                              >
                                                {this.state.branchList.map(
                                                  (br, index) => (
                                                    <DropdownItem
                                                      key={index}
                                                      onClick={async (
                                                        event
                                                      ) => {
                                                        this.setState(
                                                          {
                                                            branch:
                                                              br.bankBranchName,
                                                            routingNo:
                                                              br.bankRouting,
                                                          },
                                                          () => {}
                                                        );
                                                      }}
                                                      disabled={
                                                        this.state
                                                          .userDetailsCanEdit ===
                                                        1
                                                          ? false
                                                          : true
                                                      }
                                                    >
                                                      {br.bankBranchName}
                                                    </DropdownItem>
                                                  )
                                                )}
                                              </DropdownMenu>
                                            </Dropdown>
                                          </Col>
                                        </Row>

                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Routing No
                                          </Label>
                                          <Col md={9}>
                                            <Input
                                              type="text"
                                              defaultValue={
                                                this.state.routingNo
                                              }
                                              id="example-salary-input"
                                              disabled={
                                                this.state
                                                  .userDetailsCanEdit === 1
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Account Name
                                          </Label>
                                          <Col md={9}>
                                            <Input
                                              type="text"
                                              defaultValue={
                                                this.state.key?.bankInfo
                                                  .accountName
                                              }
                                              onChange={
                                                this.handleAccountNameChange
                                              }
                                              id="example-salary-input"
                                              disabled={
                                                this.state
                                                  .userDetailsCanEdit === 1
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Label
                                            htmlFor="example-name-input"
                                            className="col-md-3 col-form-label"
                                          >
                                            Account No
                                          </Label>
                                          <Col md={9}>
                                            <Input
                                              type="text"
                                              defaultValue={
                                                this.state.key?.bankInfo
                                                  .accountNo
                                              }
                                              onChange={
                                                this.handleAccountNoChange
                                              }
                                              id="example-salary-input"
                                              disabled={
                                                this.state
                                                  .userDetailsCanEdit === 1
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      <h4 className="text-center">
                                        No data available
                                      </h4>
                                    )}
                                  </CardText>
                                </Col>
                              </Row>
                              {this.state.userDetailsCanAuth === 1 &&
                                this.state.userDetailsCanInsert === 1 &&
                                this.state.userDetailsCanEdit === 1 && (
                                  <ModalFooter>
                                    <Button
                                      type="button"
                                      onClick={this.tog_standard}
                                      color="light"
                                      className="waves-effect"
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="waves-effect waves-light"
                                      onClick={async (event) => {
                                        event.preventDefault();
                                        this.setState((prevState) => ({
                                          modal_standard6:
                                            !prevState.modal_standard6,
                                        }));
                                        this.removeBodyCss();
                                      }}
                                    >
                                      Update Bank Details
                                    </Button>
                                  </ModalFooter>
                                )}
                            </TabPane>

                            {/*User transaction Info Details view  */}
                            <TabPane tabId="6" className="p-3">
                              <UserTransactions
                                uid={this.state.key?.profileInfo.userID}
                              />
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </Modal>
                ) : (
                  ''
                )}
              </div>

              {/* modal for user enable/disable */}

              <div id="44">
                <Modal
                  isOpen={this.state.modal_standard2}
                  toggle={this.tog_standard2}
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_standard2: false })}
                  >
                    {this.state.key?.profileInfo.name}'s status change
                  </ModalHeader>

                  <ModalBody>
                    <Col xl={12}>
                      <Row className="mb-3 mx-2">
                        Are you sure you want to{' '}
                        {this.state.key?.profileInfo.activeStatus === 1
                          ? 'Disable'
                          : 'Enable'}{' '}
                        {this.state.key?.profileInfo.name}'s account?
                      </Row>
                    </Col>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={() => {
                        this.setState({ modal_standard2: false });
                      }}
                      color="light"
                      className="waves-effect"
                    >
                      No
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={async (event) => {
                        event.preventDefault();
                        const token = getToken();
                        var statusData =
                          this.state.key?.profileInfo.activeStatus === 1
                            ? 'disable'
                            : 'enable';
                        await GKApi.put(
                          `/users/status`,
                          {
                            userId: this.state.key?.profileInfo.userID,
                            status: statusData,
                            cmpId: 44,
                            deviceInfo: this.state.deviceInfo,
                          },
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        this.setState((prevState) => ({
                          modal_standard2: !prevState.modal_standard2,
                        }));
                        this.removeBodyCss();
                        if (this.state.searchKey !== '') {
                          let response1 = await GKApi.get(
                            `/users?pageNumber=${this.state.pageNumber}&pageSize=10&type=${this.state.userType}&search=${this.state.searchKey}`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 43,
                              },
                            }
                          );

                          this.setState(
                            {
                              userData: response1.data.data,
                              pageNumber:
                                response1.data.paginationInfo.pageNumber,
                              totalPageCount:
                                response1.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        } else {
                          let response = await GKApi.get(
                            `/users?pageNumber=${this.state.pageNumber}&pageSize=${this.state.pageSize}&type=${this.state.userType}`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 43,
                              },
                            }
                          );

                          this.setState(
                            {
                              userData: response.data.data,
                              groupId: this.props.groupId,
                              pageNumber:
                                response.data.paginationInfo.pageNumber,
                              totalPageCount:
                                response.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        }
                        // const response2 = await GKApi.get(
                        //   `/users?pageNumber=1&pageSize=10000000&type=verified`,
                        //   {
                        //     headers: {
                        //       Authorization: token,
                        //       cmpId: 43,
                        //     },
                        //   }
                        // );

                        // this.setState(
                        //   {
                        //     userData: response2.data.data,
                        //   },
                        //   () => {}
                        // );
                        // const response3 = await GKApi.get(
                        //   `/users?pageNumber=1&pageSize=10000000&type=unverified`,
                        //   {
                        //     headers: {
                        //       Authorization: token,
                        //       cmpId: 43,
                        //     },
                        //   }
                        // );
                        // this.setState(
                        //   {
                        //     userData: response3.data.data,
                        //   },
                        //   () => {}
                        // );
                      }}
                    >
                      Yes
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>

              {/* user reset password modal */}
              <div id="61">
                <Modal
                  isOpen={this.state.modal_standard3}
                  toggle={this.tog_standard3}
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_standard3: false })}
                  >
                    {this.state.key?.profileInfo.name}'s password change
                  </ModalHeader>

                  <ModalBody>
                    <Col xl={12}>
                      <Row className="mb-3 mx-2">
                        Are you sure you want to change
                        {this.state.key?.profileInfo.name}'s password?
                      </Row>
                    </Col>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_standard3}
                      color="light"
                      className="waves-effect"
                    >
                      No
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={async (event) => {
                        event.preventDefault();
                        const token = getToken();
                        await GKApi.post(
                          `/users/reset-password`,
                          {
                            userId: this.state.key?.profileInfo.userID,
                            deviceInfo: this.state.deviceInfo,
                            cmpId: 61,
                          },
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        this.setState((prevState) => ({
                          modal_standard3: !prevState.modal_standard3,
                        }));
                        this.removeBodyCss();
                      }}
                    >
                      Yes
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>

              {/* user verification modal */}
              <div id="45">
                <Modal
                  isOpen={this.state.modal_standard4}
                  toggle={this.tog_standard4}
                >
                  <ModalHeader
                    toggle={() =>
                      this.setState({
                        modal_standard4: false,
                      })
                    }
                  >
                    {this.state.key?.profileInfo.name}'s status change
                  </ModalHeader>

                  <ModalBody>
                    <Col xl={12}>
                      <Row className="mb-3 mx-2">
                        Are you sure you want to change
                        {this.state.key?.profileInfo.name}
                        's profile status to{' '}
                        {this.state.selectedProfileStatusType}?
                      </Row>
                    </Col>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.tog_standard4}
                      color="light"
                      className="waves-effect"
                    >
                      No
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={async (event) => {
                        event.preventDefault();
                        const token = getToken();
                        const uId = getUser();
                        var statusData = this.state.selectedProfileStatusType;
                        await GKApi.put(
                          `/users/change-profile-status`,
                          {
                            userId: this.state.key?.profileInfo.userID,
                            profileStatus: statusData,
                            staffId: uId,
                            deviceInfo: this.state.deviceInfo,
                          },
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        this.setState((prevState) => ({
                          modal_standard4: !prevState.modal_standard4,
                        }));
                        this.removeBodyCss();
                        if (this.state.searchKey !== '') {
                          let response1 = await GKApi.get(
                            `/users?pageNumber=${this.state.pageNumber}&pageSize=10&type=${this.state.userType}&search=${this.state.searchKey}`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 43,
                              },
                            }
                          );

                          this.setState(
                            {
                              userData: response1.data.data,
                              pageNumber:
                                response1.data.paginationInfo.pageNumber,
                              totalPageCount:
                                response1.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        } else {
                          let response = await GKApi.get(
                            `/users?pageNumber=${this.state.pageNumber}&pageSize=${this.state.pageSize}&type=${this.state.userType}`,
                            {
                              headers: {
                                Authorization: token,
                                cmpId: 43,
                              },
                            }
                          );

                          this.setState(
                            {
                              userData: response.data.data,
                              groupId: this.props.groupId,
                              pageNumber:
                                response.data.paginationInfo.pageNumber,
                              totalPageCount:
                                response.data.paginationInfo.totalPage,
                            },
                            () => {}
                          );
                        }
                        const profileData = await GKApi.get(
                          `/users/user?userId=${this.state.key?.profileInfo.userID}`,

                          {
                            headers: {
                              Authorization: token,
                              cmpId: 46,
                            },
                          }
                        );
                        this.setState({ key: profileData.data }, () => {});
                      }}
                    >
                      Yes
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              <Modal
                isOpen={this.state.modal_standard4}
                toggle={this.tog_standard4}
              >
                <ModalHeader
                  toggle={() =>
                    this.setState({
                      modal_standard4: false,
                    })
                  }
                >
                  {this.state.key?.profileInfo.name}'s status change
                </ModalHeader>

                <ModalBody>
                  <Col xl={12}>
                    <Row className="mb-3 mx-2">
                      Are you sure you want to change
                      {this.state.key?.profileInfo.name}
                      's profile status to{' '}
                      {this.state.selectedProfileStatusType}?
                    </Row>
                  </Col>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_standard4}
                    color="light"
                    className="waves-effect"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={async (event) => {
                      event.preventDefault();
                      const token = getToken();
                      const uId = getUser();
                      var statusData = this.state.selectedProfileStatusType;
                      await GKApi.put(
                        `/users/change-profile-status`,
                        {
                          userId: this.state.key?.profileInfo.userID,
                          profileStatus: statusData,
                          staffId: uId,
                          deviceInfo: this.state.deviceInfo,
                          cmpId: 46,
                        },
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                      this.setState((prevState) => ({
                        modal_standard4: !prevState.modal_standard4,
                      }));
                      this.removeBodyCss();
                      if (this.state.searchKey !== '') {
                        let response1 = await GKApi.get(
                          `/users?pageNumber=${this.state.pageNumber}&pageSize=10&type=${this.state.userType}&search=${this.state.searchKey}`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 43,
                            },
                          }
                        );

                        this.setState(
                          {
                            userData: response1.data.data,
                            pageNumber:
                              response1.data.paginationInfo.pageNumber,
                            totalPageCount:
                              response1.data.paginationInfo.totalPage,
                          },
                          () => {}
                        );
                      } else {
                        let response = await GKApi.get(
                          `/users?pageNumber=${this.state.pageNumber}&pageSize=${this.state.pageSize}&type=${this.state.userType}`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 43,
                            },
                          }
                        );

                        this.setState(
                          {
                            userData: response.data.data,
                            groupId: this.props.groupId,
                            pageNumber: response.data.paginationInfo.pageNumber,
                            totalPageCount:
                              response.data.paginationInfo.totalPage,
                          },
                          () => {}
                        );
                      }
                      const profileData = await GKApi.get(
                        `/users/user?userId=${this.state.key?.profileInfo.userID}`,

                        {
                          headers: {
                            Authorization: token,
                            cmpId: 46,
                          },
                        }
                      );
                      this.setState({ key: profileData.data }, () => {});
                    }}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>
              {/* user nominee details change confirmation modal */}
              <Modal
                isOpen={this.state.modal_standard5}
                toggle={this.tog_standard5}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard5: false })}
                >
                  {this.state.key?.profileInfo.name}'s nominee details change
                </ModalHeader>

                <ModalBody>
                  <Col xl={12}>
                    <Row className="mb-3 mx-2">
                      Are you sure you want to change nominee details of {''}
                      {this.state.key?.profileInfo.name}?
                    </Row>
                  </Col>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_standard5}
                    color="light"
                    className="waves-effect"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={async (event) => {
                      event.preventDefault();
                      const token = getToken();
                      const userId = getUser();
                      await this.uploadDoc(this.state.key?.profileInfo.userID);
                      await GKApi.put(
                        `/users/nominee`,
                        {
                          userId: this.state.key?.profileInfo.userID,
                          nomineeName: this.state.nomineeName,
                          nomineeNid: this.state.nomineeNID,
                          nomineeRelationship:
                            this.state.nomineeRelationWithUser,
                          nomineeDocURL: this.state.nomineeDoc,
                          nomineeDob: moment(
                            this.state.nomineeBirthDate
                          ).format('DD/MM/YYYY'),
                          nomineePhone: this.state.nomineePhone,
                          staffId: userId,
                          deviceIP: this.state.deviceInfo.IPv4,
                          deviceInfo: this.state.deviceInfo,
                          cmpId: 46,
                        },
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                      this.setState((prevState) => ({
                        modal_standard5: !prevState.modal_standard5,
                      }));
                      this.removeBodyCss();
                    }}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>
              {/* user bank details change confirmation modal */}
              <Modal
                isOpen={this.state.modal_standard6}
                toggle={this.tog_standard6}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard6: false })}
                >
                  {this.state.key?.profileInfo.name}'s bank details change
                </ModalHeader>

                <ModalBody>
                  <Col xl={12}>
                    <Row className="mb-3 mx-2">
                      Are you sure you want to change bank details of {''}
                      {this.state.key?.profileInfo.name}?
                    </Row>
                  </Col>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_standard6}
                    color="light"
                    className="waves-effect"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={async (event) => {
                      event.preventDefault();
                      const token = getToken();
                      const userId = getUser();

                      await GKApi.put(
                        `/users/change-bank`,
                        {
                          userId: this.state.key?.profileInfo.userID,
                          accountName: this.state.accountName,
                          accountNo: this.state.accountNo,

                          routingNo: this.state.routingNo,
                          staffId: userId,
                          cmpId: 46,
                          deviceInfo: this.state.deviceInfo,
                        },
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                      this.setState((prevState) => ({
                        modal_standard6: !prevState.modal_standard6,
                      }));
                      this.removeBodyCss();
                    }}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>
              {/* user bkash details change confirmation modal */}
              <Modal
                isOpen={this.state.modal_standard7}
                toggle={this.tog_standard7}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard7: false })}
                >
                  {this.state.key?.profileInfo.name}'s Bkash details change
                </ModalHeader>

                <ModalBody>
                  <Col xl={12}>
                    <Row className="mb-3 mx-2">
                      Are you sure you want to change Bkash details of {''}
                      {this.state.key?.profileInfo.name}?
                    </Row>
                  </Col>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_standard7}
                    color="light"
                    className="waves-effect"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={async (event) => {
                      event.preventDefault();
                      const token = getToken();
                      const userId = getUser();

                      await GKApi.put(
                        `/users/change-bkash`,
                        {
                          userId: this.state.key?.profileInfo.userID,
                          bkashAccName: this.state.bkashAccName,
                          bkashNo: this.state.bkashAccNo,

                          staffId: userId,
                          cmpId: 46,
                          deviceInfo: this.state.deviceInfo,
                        },
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                      this.setState((prevState) => ({
                        modal_standard7: !prevState.modal_standard7,
                      }));
                      this.removeBodyCss();
                    }}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>

              {/* user address details change confirmation modal */}
              <Modal
                isOpen={this.state.modal_standard8}
                toggle={this.tog_standard8}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard8: false })}
                >
                  {this.state.key?.profileInfo.name}'s Address details change
                </ModalHeader>

                <ModalBody>
                  <Col xl={12}>
                    <Row className="mb-3 mx-2">
                      Are you sure you want to change Address details of {''}
                      {this.state.key?.profileInfo.name}?
                    </Row>
                  </Col>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_standard8}
                    color="light"
                    className="waves-effect"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={async (event) => {
                      event.preventDefault();
                      const token = getToken();
                      const userId = getUser();

                      await GKApi.put(
                        `/users/change-address`,
                        {
                          userId: this.state.key?.profileInfo.userID,
                          address: this.state.address,
                          additional: this.state.additional,
                          thanaId: this.state.thanaId,
                          postalCode: this.state.postalCode,
                          staffId: userId,
                          cmpId: 46,
                          deviceInfo: this.state.deviceInfo,
                        },
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                      this.setState((prevState) => ({
                        modal_standard8: !prevState.modal_standard8,
                      }));
                      this.removeBodyCss();
                    }}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>

              {/* user Profile details change confirmation modal */}
              <Modal
                isOpen={this.state.modal_standard9}
                toggle={this.tog_standard9}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard9: false })}
                >
                  {this.state.key?.profileInfo.name}'s Profile details change
                </ModalHeader>

                <ModalBody>
                  <Col xl={12}>
                    <Row className="mb-3 mx-2">
                      Are you sure you want to change Profile details of {''}
                      {this.state.key?.profileInfo.name}?
                    </Row>
                  </Col>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_standard9}
                    color="light"
                    className="waves-effect"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={async (event) => {
                      event.preventDefault();
                      const token = getToken();
                      const userId = getUser();

                      await GKApi.put(
                        `/users/change-profile`,
                        {
                          userId: this.state.key?.profileInfo.userID,
                          name: this.state.name,
                          email: this.state.email,
                          dob: moment(this.state.dob).format('YYYY-MM-DD'),
                          gender: this.state.gender,
                          professionCode: this.state.professionCode,
                          nid: this.state.nid,
                          photo: this.state.key?.profileInfo.photoURL,
                          nidFront: this.state.key?.profileInfo.nidFrontURL,
                          nidBack: this.state.key?.profileInfo.nidBackURL,
                          maritalStat: this.state.maritalStatus
                            .charAt(0)
                            .toUpperCase(),
                          staffId: userId,
                          cmpId: 46,
                          deviceInfo: this.state.deviceInfo,
                        },
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                      this.setState((prevState) => ({
                        modal_standard9: !prevState.modal_standard9,
                      }));
                      this.removeBodyCss();
                    }}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>

              {/* user notes input modal */}
              <Modal
                isOpen={this.state.notesModal}
                toggle={this.tog_notesModal}
              >
                <ModalHeader
                  toggle={() =>
                    this.setState({
                      notesModal: false,
                      callDate: '',
                      callTime: '',
                      callNote: '',
                      agentName: '',
                    })
                  }
                >
                  Call Note Input
                </ModalHeader>

                <ModalBody>
                  <Row className="mb-3">
                    <Label
                      htmlFor="add-sku-deliveryNote-input"
                      className="col-md-5 col-form-label"
                    >
                      Call Date
                    </Label>
                    <Col md={7}>
                      <div
                        style={{
                          border: '1px solid gray',
                          borderRadius: '5px',
                          paddingTop: '2.5px',
                          paddingBottom: '2.5px',
                          paddingRight: '4px',
                          paddingLeft: '4px',
                        }}
                      >
                        <DatePickerComponent
                          selectedDate={this.state.callDate}
                          handleSelectedDateChange={this.handleCallDateChange}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Label
                      htmlFor="add-sku-deliveryNote-input"
                      className="col-md-5 col-form-label"
                    >
                      Call Time
                    </Label>
                    <Col md={7}>
                      <Input
                        type="time"
                        value={this.state.callTime}
                        placeholder="Please input deliveryNote of the new SKU"
                        id="add-sku-deliveryNote-input"
                        onChange={this.handleCallTimeChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Label
                      htmlFor="add-sku-deliveryNote-input"
                      className="col-md-5 col-form-label"
                    >
                      Note
                    </Label>
                    <Col md={7}>
                      <TextField
                        className="w-full"
                        variant="outlined"
                        type="text"
                        multiline
                        minRows={2}
                        style={{
                          width: '100%',
                        }}
                        value={this.state.callNote}
                        placeholder="Please input call note of the Delivery"
                        id="add-callNote-input"
                        onChange={this.handleCallNoteChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="add-sku-designation-input"
                      className="col-md-5 col-form-label"
                    >
                      Agent Name
                    </Label>
                    <Col md={7}>
                      <Input
                        type="text"
                        value={this.state.agentName}
                        placeholder="Please input Name of the calling agent"
                        id="add-calling-agent-name"
                        onChange={this.handleAgentNameChange}
                      />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_notesModal}
                    color="light"
                    className="waves-effect"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={async (event) => {
                      event.preventDefault();

                      this.tog_notesConfirmation();
                    }}
                  >
                    Log note
                  </Button>
                </ModalFooter>
              </Modal>

              {/* notes confirmation modal */}

              <Modal
                isOpen={this.state.notesConfirmation}
                toggle={this.tog_notesConfirmation}
              >
                <ModalHeader
                  toggle={() =>
                    this.setState({
                      notesConfirmation: false,
                      confirmingNote: false,
                    })
                  }
                >
                  Confirmation Preview
                </ModalHeader>

                <ModalBody>
                  <Row className="mb-3">
                    <Col md={4}>
                      <p className="fw-bold">User Name</p>
                      <p>{this.state.key?.profileInfo.name}</p>
                    </Col>
                    <Col md={4}>
                      <p className="fw-bold">Note</p>
                      <p>{this.state.callNote}</p>
                    </Col>
                    <Col md={4}>
                      <p className="fw-bold">Agent Name</p>
                      <p>{this.state.agentName}</p>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <p className="fw-bold">Call date</p>
                      <p>{moment(this.state.callDate).format('DD-MM-YYYY')}</p>
                    </Col>
                    <Col md={6}>
                      <p className="fw-bold">Call time</p>
                      <p>
                        {moment(this.state.callTime, 'HH:mm').format('hh:mm A')}
                      </p>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    onClick={() => {
                      this.tog_notesConfirmation();
                      this.setState({ confirmingNote: false });
                    }}
                    color="light"
                    className="waves-effect"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    disabled={this.state.confirmingNote === true ? true : false}
                    onClick={async (event) => {
                      event.preventDefault();
                      const token = getToken();
                      this.setState({ confirmingNote: true });
                      await GKApi.post(
                        `/users/user-note`,
                        {
                          userId: this.state.key?.profileInfo.userID,
                          agentName: this.state.agentName,
                          noteTime:
                            moment(this.state.callDate).format('YYYY-MM-DD ') +
                            this.state.callTime,
                          note: this.state.callNote,
                        },
                        {
                          headers: {
                            Authorization: token,
                            cmpId: 46,
                          },
                        }
                      );

                      this.tog_notesConfirmation();
                      this.tog_notesModal();
                      this.setState({
                        confirmingNote: false,
                        callDate: null,
                        callNote: '',
                        agentName: '',
                      });
                    }}
                  >
                    Confirm
                  </Button>
                </ModalFooter>
              </Modal>

              {/* note table */}

              <Modal
                size="lg"
                isOpen={this.state.noteList}
                toggle={this.tog_noteList}
              >
                <ModalHeader toggle={() => this.setState({ noteList: false })}>
                  Call Note List
                </ModalHeader>

                <ModalBody>
                  <Row className="mb-3">
                    <MaterialTable
                      title={`List of Notes`}
                      columns={noteColumns}
                      data={this.state.noteData}
                      options={{
                        search: true,
                        paging: true,
                        actionsCellStyle: { textAlign: 'right' },
                        //indicated the style for the data

                        cellStyle: {
                          borderRight: '1px solid #eee',
                          textAlign: 'left',
                        },

                        //style for the header

                        headerStyle: {
                          whiteSpace: 'nowrap',
                          textAlign: 'left',
                        },

                        actionsColumnIndex: -1, // to move the action button to right most column
                        pageSize: 10,
                        columnsButton: true, // enables column filtering
                      }}
                    />
                  </Row>
                </ModalBody>
              </Modal>
                            {/* modal for pin reset history */}
              <Modal
                size="lg"
                isOpen={this.state.pinResetModal}
                toggle={this.tog_pinResetModal}
              >
                <ModalHeader toggle={() => this.setState({ pinResetModal: false })}>
                  Pin Reset History
                </ModalHeader>

                <ModalBody>
                  <Row className="mb-3">
                  <MaterialTable
  title="Pin Reset History"
  columns={pinResetColumns}
  data={this.state.pinResetData || []} // Ensure data is an array
  options={{
    search: true,
    paging: true,
    actionsCellStyle: { textAlign: 'right' },
    cellStyle: {
      borderRight: '1px solid #eee',
      textAlign: 'left',
    },
    headerStyle: {
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    actionsColumnIndex: -1,
    pageSize: 10,
    columnsButton: true,
  }}
/>

                  </Row>
                </ModalBody>
              </Modal>


              {/* user Profile verification status change confirmation modal */}
              <Modal
                isOpen={this.state.modal_standard10}
                toggle={this.tog_standard10}
              >
                <ModalHeader
                  toggle={() =>
                    this.setState({
                      modal_standard10: false,
                    })
                  }
                >
                  {this.state.key?.profileInfo.name}'s status change
                </ModalHeader>

                <ModalBody>
                  <Col xl={12}>
                    <Row className="mb-3 mx-2">
                      Are you sure you want to change
                      {this.state.key?.profileInfo.name}
                      's profile status to{' '}
                      {this.state.selectedProfileStatusType}?
                    </Row>
                  </Col>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.tog_standard10}
                    color="light"
                    className="waves-effect"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={async (event) => {
                      event.preventDefault();
                      const token = getToken();
                      var statusData =
                        this.state.selectedProfileStatusType === 'Verified'
                          ? true
                          : this.state.selectedProfileStatusType === 'Locked'
                          ? false
                          : '';
                      await GKApi.put(
                        `/users/verify`,
                        {
                          userId: this.state.key?.profileInfo.userID,
                          approve: statusData,
                          cmpId: 45,
                          deviceInfo: this.state.deviceInfo,
                        },
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                      this.setState((prevState) => ({
                        modal_standard10: !prevState.modal_standard10,
                      }));
                      this.removeBodyCss();
                      if (this.state.searchKey !== '') {
                        let response1 = await GKApi.get(
                          `/users?pageNumber=${this.state.pageNumber}&pageSize=10&type=${this.state.userType}&search=${this.state.searchKey}`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 43,
                            },
                          }
                        );

                        this.setState(
                          {
                            userData: response1.data.data,
                            pageNumber:
                              response1.data.paginationInfo.pageNumber,
                            totalPageCount:
                              response1.data.paginationInfo.totalPage,
                          },
                          () => {}
                        );
                      } else {
                        let response = await GKApi.get(
                          `/users?pageNumber=${this.state.pageNumber}&pageSize=${this.state.pageSize}&type=${this.state.userType}`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 43,
                            },
                          }
                        );

                        this.setState(
                          {
                            userData: response.data.data,
                            groupId: this.props.groupId,
                            pageNumber: response.data.paginationInfo.pageNumber,
                            totalPageCount:
                              response.data.paginationInfo.totalPage,
                          },
                          () => {}
                        );
                      }
                      const profileData = await GKApi.get(
                        `/users/user?userId=${this.state.key?.profileInfo.userID}`,

                        {
                          headers: {
                            Authorization: token,
                            cmpId: 46,
                          },
                        }
                      );
                      this.setState({ key: profileData.data }, () => {});
                    }}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>
              {this.state.isLoading ? (
                <h5
                  className="d-flex justify-content-center my-3
gap-5"
                >
                  <CircularProgress color="inherit" />
                </h5>
              ) : (
                <div className="my-3 d-flex justify-content-center gap-5">
                  <Button
                    onClick={async () => {
                      let token = getToken();
                      //get api call with needed parameters

                      if (
                        this.state.searchKey !== '' ||
                        (this.state.searchStartDate !== null &&
                          this.state.searchEndDate !== null)
                      ) {
                        let submitData = await GKApi.get(
                          `/users?pageNumber=${
                            this.state.pageNumber - 1
                          }&pageSize=${this.state.pageSize}&type=${
                            this.state.userType
                          }${
                            this.state.searchKey !== ''
                              ? `&search=${this.state.searchKey}`
                              : ``
                          }${
                            this.state.searchStartDate !== null &&
                            this.state.searchEndDate !== null
                              ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                              : ''
                          }`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 43,
                            },
                          }
                        );
                        this.setState(
                          {
                            userData: submitData.data.data,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        );
                      } else {
                        let submitData = await GKApi.get(
                          `/users?pageNumber=${
                            this.state.pageNumber - 1
                          }&pageSize=${this.state.pageSize}&type=${
                            this.state.userType
                          }`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 43,
                            },
                          }
                        );
                        this.setState(
                          {
                            userData: submitData.data.data,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        );
                      }
                    }}
                    disabled={this.state.pageNumber === 1 ? true : false}
                  >
                    Previous page
                  </Button>{' '}
                  <Button
                    onClick={async () => {
                      let token = getToken();
                      this.setState({ isLoading: true });
                      //get api call with needed parameters

                      if (
                        this.state.searchKey !== '' ||
                        (this.state.searchStartDate !== null &&
                          this.state.searchEndDate !== null)
                      ) {
                        let submitData = await GKApi.get(
                          `/users?pageNumber=${
                            this.state.pageNumber + 1
                          }&pageSize=${this.state.pageSize}&type=${
                            this.state.userType
                          }${
                            this.state.searchKey !== ''
                              ? `&search=${this.state.searchKey}`
                              : ``
                          }${
                            this.state.searchStartDate !== null &&
                            this.state.searchEndDate !== null
                              ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                              : ''
                          }`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 43,
                            },
                          }
                        );
                        if (submitData) {
                          this.setState({
                            isLoading: false,
                          });
                        }
                        this.setState(
                          {
                            userData: submitData.data.data,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                          },
                          () => {}
                        );
                      } else {
                        let submitData = await GKApi.get(
                          `/users?&pageNumber=${
                            this.state.pageNumber + 1
                          }&pageSize=${this.state.pageSize}&type=${
                            this.state.userType
                          }`,
                          {
                            headers: {
                              Authorization: token,
                              cmpId: 43,
                            },
                          }
                        );
                        if (submitData) {
                          this.setState({
                            isLoading: false,
                          });
                        }
                        this.setState(
                          {
                            userData: submitData.data.data,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                          },
                          () => {}
                        );
                      }
                    }}
                    disabled={
                      this.state.pageNumber === this.state.totalPageCount
                    }
                  >
                    {this.state.isLoading ? 'Loading Next Page' : 'Next Page'}
                  </Button>
                </div>
              )}
              <div className="my-2 d-flex justify-content-center">
                <h5>
                  Page {this.state.pageNumber} of {this.state.totalPageCount}
                </h5>
              </div>
            </div>
          ) : (
            <h5>Sorry you don't have permission to view this.</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(Users);

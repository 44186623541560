import React, { Component } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

//i18n
import { withNamespaces } from 'react-i18next';

// users
import avatar2 from '../../../assets/images/users/avatar.png';
import {
  removeAccessToken,
  removeGroupId,
  removeGroupName,
} from '../../config';
import { useSelector } from 'react-redux';
import { logoutUser } from '../../../store/actions';
import moment from 'moment';

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const name = useSelector((state) => state.Login.user.user?.name);

    return <Component {...props} name={name} />;
  };
}

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    const dispatch = this.props.dispatch;
    let username = this.props.name;

    return (
      <React.Fragment>
        <div className='d-flex gap-2'>
        <div className="d-flex gap-2 align-items-center">
                    <h4 className="text-center font-size-20 mb-1 fw-bold">
                      {moment(new Date()).format('Do MMM')}
                    </h4>
                  </div>
          <Dropdown
            isOpen={this.state.menu}
            toggle={this.toggle}
            className="d-inline-block user-dropdown"
          >
            <DropdownToggle
              tag="button"
              className="btn header-item waves-effect"
              id="page-header-user-dropdown"
            >
              <img
                className="rounded-circle header-profile-user me-1"
                src={avatar2}
                alt="Header Avatar"
              />
              <span className="d-none d-xl-inline-block ms-1 text-transform">
                {username}
              </span>
              <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="/user-profile">
                <i className="ri-user-line align-middle me-1"></i>{' '}
                {this.props.t('Profile')}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                className="text-danger"
                onClick={() => {
                  removeAccessToken();
                  removeGroupId();
                  removeGroupName();
                  dispatch(logoutUser());
                }}
                href="/logout"
              >
                <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{' '}
                {this.props.t('Logout')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(withNamespaces()(ProfileMenu));

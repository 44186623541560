import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import * as XLSX from 'xlsx';
import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import classnames from 'classnames';
import {
  capitalizeFirstLetter,
  getGroupName,
  getToken,
  GKApi,
} from '../../components/config';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import UserRequestHistory from './UserRequestHistory';
import { deviceInfo } from '../../helpers/device_info_helper';

class UserRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Tables', link: '#' },
        { title: 'Admin User Table', link: '#' },
      ],
      reqType: 'profile',
      reqMenu: false,
      activeTabJustify: '1',
      nidFrontPicCurrent: null,
      nidFrontPicUpdated: null,
      nidBackPicCurrent: null,
      nidBackPicUpdated: null,
      profileReq: [],
      bankReq: [],
      addressReq: [],
      bkashReq: [],
      unbindReq: [],
      userData: [],
      genderMenu: false,
      email: '',
      name: '',
      dob: null,
      gender: 'Select Gender',
      designation: '',
      nid: '',
      phone: '',
      visible: false,
      custom_div1: false,
      modal_standard: false,
      modal_standard2: false,
      modal_standard3: false,
      modal_standard4: false,
      modal_standard5: false,
      modal_standard6: false,
      modal_standard7: false,
      modal_standard8: false,
      modal_standard9: false,
      modal_standard10: false,
      modal_standard11: false,
      modal_standard12: false,
      modal_standard13: false,
      modal_standard14: false,
      adminUserData: [],
      key: null,
      modal: false,
      dynamic_title: '',
      dynamic_description: '',
      success_confirm: false,
      deviceInfo: {},
      reasonMenu: false,
      reason: '',
      compAuthState: 1,
      isCrm: false,
      isSuper: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_standard2 = this.tog_standard2.bind(this);
    this.tog_standard3 = this.tog_standard3.bind(this);
  }

  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };
  //modal closing options

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_standard2() {
    this.setState((prevState) => ({
      modal_standard2: !prevState.modal_standard2,
    }));
    this.removeBodyCss();
  }

  tog_standard3() {
    this.setState((prevState) => ({
      modal_standard3: !prevState.modal_standard3,
    }));
    this.removeBodyCss();
  }
  tog_standard4 = () => {
    this.setState((prevState) => ({
      modal_standard4: !prevState.modal_standard4,
    }));
    this.removeBodyCss();
  };
  tog_standard5 = () => {
    this.setState((prevState) => ({
      modal_standard5: !prevState.modal_standard5,
    }));
    this.removeBodyCss();
  };
  tog_standard6 = () => {
    this.setState((prevState) => ({
      modal_standard6: !prevState.modal_standard6,
    }));
    this.removeBodyCss();
  };
  tog_standard7 = () => {
    this.setState((prevState) => ({
      modal_standard7: !prevState.modal_standard7,
    }));
    this.removeBodyCss();
  };
  tog_standard8 = () => {
    this.setState((prevState) => ({
      modal_standard8: !prevState.modal_standard8,
    }));
    this.removeBodyCss();
  };
  tog_standard9 = () => {
    this.setState((prevState) => ({
      modal_standard9: !prevState.modal_standard9,
    }));
    this.removeBodyCss();
  };
  tog_standard10 = () => {
    this.setState((prevState) => ({
      modal_standard10: !prevState.modal_standard10,
    }));
    this.removeBodyCss();
  };

  tog_standard11 = () => {
    this.setState((prevState) => ({
      modal_standard11: !prevState.modal_standard11,
    }));
    this.removeBodyCss();
  };

  tog_standard12 = () => {
    this.setState((prevState) => ({
      modal_standard12: !prevState.modal_standard12,
    }));
    this.removeBodyCss();
  };

  tog_standard13 = () => {
    this.setState((prevState) => ({
      modal_standard13: !prevState.modal_standard13,
    }));
    this.removeBodyCss();
  };

  tog_standard14 = () => {
    this.setState((prevState) => ({
      modal_standard14: !prevState.modal_standard14,
    }));
    this.removeBodyCss();
  };
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  // change handlers

  handleNameChange = (event) => {
    this.setState({ ...this.state, name: event.target.value });
  };

  handleEmailChange = (event) => {
    this.setState({ ...this.state, email: event.target.value });
  };

  handlePhoneChange = (event) => {
    this.setState({ ...this.state, phone: event.target.value });
  };

  handleDobChange = (event) => {
    this.setState({ ...this.state, dob: event });
  };

  handleGenderChange = (event) => {
    this.setState({ ...this.state, gender: event.target.value });
  };

  handleDesignationChange = (event) => {
    this.setState({ ...this.state, designation: event.target.value });
  };
  handleNidChange = (event) => {
    this.setState({ ...this.state, nid: event.target.value });
  };

  handleRoleChange = (role) => {
    this.setState({ role });
  };

  //fetch user list call

  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'CRM') {
        this.setState({
          isCrm: true,
        });
      } else if (name === 'SUPER') {
        this.setState({
          isSuper: true,
        });
      }
    });
    // GET request using axios with async/await
    const token = getToken();
    // const gId = getGroupId();
    // getting permission data from api
    const getPermission = await GKApi.get(`/role/permission?cmpId=47,48`, {
      headers: {
        Authorization: token,
      },
    });

    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    // const requestDataTemp = [];

    this.setState({ compAuthState: permissionData[47].authState });

    const response = await GKApi.get(
      `/users/change-request/list?type=${this.state.reqType}&pageNumber=1&pageSize=1000`,
      {
        headers: {
          Authorization: token,
          cmpId: 47,
        },
      }
    );

    //adding auth difference attribute to the refer list data
    response.data.data.forEach((item) => {
      item['authDiff'] = permissionData[47].authState - item.authStat;
      this.state.profileReq.push(item);
    });

    this.setState({ profileReq: response.data.data });
    const res = deviceInfo();
    this.setState({ deviceInfo: res });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
  };

  render() {
    //user request columns for profile request
    const profileColumns = [
      {
        field: 'phone',
        title: 'Phone Number',
      },

      {
        field: 'currentName',
        title: 'Request From',
      },
      {
        field: 'createTime',
        title: 'Request Time',
        render: (row) => (
          <div>
            {row.createTime
              ? moment(row.createTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
    ];

    //user request columns for bank information request
    const bankColumns = [
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'name',
        title: 'Request From',
      },
      {
        field: 'createTime',
        title: 'Request Time',
        render: (row) => (
          <div>
            {row.createTime
              ? moment(row.createTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
    ];

    //user request columns for address details request
    const addressColumns = [
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'name',
        title: 'Request From',
      },
      {
        field: 'createTime',
        title: 'Request Time',
        render: (row) => (
          <div>
            {row.createTime
              ? moment(row.createTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
    ];

    //user request columns for bkash details request
    const bkashColumns = [
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'name',
        title: 'Request From',
      },
      {
        field: 'createTime',
        title: 'Request Time',
        render: (row) => (
          <div>
            {row.createTime
              ? moment(row.createTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
    ];

    //user request columns for unbind request
    const unbindColumns = [
      {
        field: 'curUserphone',
        title: 'Phone Number',
      },
      {
        field: 'curUserName',
        title: 'Request From',
      },
      {
        field: 'createTime',
        title: 'Request Time',
        render: (row) => (
          <div>
            {row.createTime
              ? moment(row.createTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
    ];

    //download excel function for the request details
    // takes request api call data as input
    // then mapping the fetched api data for mapping based of the request type for the downloading the excel
    const downloadExcel = () => {
      //mapping excel data based on request types
      const newData =
        this.state.reqType === 'profile'
          ? this.state.profileReq.map((row) => {
              delete row.tableData;
              return row; // return mapped rows profile request data for the excel download data
            })
          : this.state.reqType === 'bank'
          ? this.state.bankReq.map((row) => {
              delete row.tableData;
              return row; //return mapped rows bank request data for the excel download data
            })
          : this.state.reqType === 'address'
          ? this.state.addressReq.map((row) => {
              delete row.tableData;
              return row; //return mapped rows address request data for the excel download data
            })
          : this.state.reqType === 'unbind'
          ? this.state.unbindReq.map((row) => {
              delete row.tableData;
              return row; //return mapped rows unbind request data for the excel download data
            })
          : this.state.bkashReq.map((row) => {
              delete row.tableData;
              return row; //return mapped rows bkash request data for the excel download data
            });

      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'User requests');
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(workBook, 'User Requests.xlsx');
    };

    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isCrm === true || this.state.isSuper === true ? (
            <div className="container-fluid">
              <Row>
                <Col xl={12}>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: this.state.activeTabJustify === '1',
                        })}
                        onClick={() => {
                          this.toggleCustomJustified('1');
                        }}
                      >
                        <span>User Requests</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: this.state.activeTabJustify === '2',
                        })}
                        onClick={() => {
                          this.toggleCustomJustified('2');
                        }}
                      >
                        <span>History</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTabJustify}>
                    <TabPane tabId="1">
                      <div id="47">
                        <MaterialTable
                          title={
                            <div className="d-flex gap-5">
                              <h5>List of User Requests</h5>
                            </div>
                          }
                          columns={
                            this.state.reqType === 'profile'
                              ? profileColumns
                              : this.state.reqType === 'bank'
                              ? bankColumns
                              : this.state.reqType === 'address'
                              ? addressColumns
                              : this.state.reqType === 'unbind'
                              ? unbindColumns
                              : bkashColumns
                          }
                          components={{
                            Toolbar: (props) => (
                              <div>
                                <MTableToolbar {...props} />
                                <div className="d-flex gap-3 align-items-center mx-3">
                                  <h5>Select Request type:</h5>
                                  <Dropdown
                                    isOpen={this.state.reqMenu}
                                    toggle={(event) =>
                                      this.setState({
                                        reqMenu: !this.state.reqMenu,
                                      })
                                    }
                                  >
                                    <DropdownToggle
                                      className="px-5 py-2"
                                      color="light"
                                    >
                                      {capitalizeFirstLetter(
                                        this.state.reqType
                                      )}{' '}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={async (event) => {
                                          this.setState({ reqType: 'profile' });
                                          const token = getToken();
                                          const response = await GKApi.get(
                                            `/users/change-request/list?type=profile&pageNumber=1&pageSize=1000`,
                                            {
                                              headers: {
                                                Authorization: token,
                                                cmpId: 47,
                                              },
                                            }
                                          );
                                          response.data.data.forEach((item) => {
                                            item['authDiff'] =
                                              this.state.compAuthState -
                                              item.authStat;
                                            this.state.profileReq.push(item);
                                          });
                                          this.setState({
                                            profileReq: response.data.data,
                                          });
                                        }}
                                      >
                                        Profile
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={async (event) => {
                                          this.setState({ reqType: 'bank' });
                                          const token = getToken();
                                          const response = await GKApi.get(
                                            `/users/change-request/list?type=bank&pageNumber=1&pageSize=1000`,
                                            {
                                              headers: {
                                                Authorization: token,
                                                cmpId: 47,
                                              },
                                            }
                                          );
                                          response.data.data.forEach((item) => {
                                            item['authDiff'] =
                                              this.state.compAuthState -
                                              item.authStat;
                                            this.state.bankReq.push(item);
                                          });
                                          this.setState({
                                            bankReq: response.data.data,
                                          });
                                        }}
                                      >
                                        Bank
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={async (event) => {
                                          this.setState({ reqType: 'address' });
                                          const token = getToken();
                                          const response = await GKApi.get(
                                            `/users/change-request/list?type=address&pageNumber=1&pageSize=1000`,
                                            {
                                              headers: {
                                                Authorization: token,
                                                cmpId: 47,
                                              },
                                            }
                                          );

                                          response.data.data.forEach((item) => {
                                            item['authDiff'] =
                                              this.state.compAuthState -
                                              item.authStat;
                                            this.state.addressReq.push(item);
                                          });
                                          this.setState({
                                            addressReq: response.data.data,
                                          });
                                        }}
                                      >
                                        Address
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={async (event) => {
                                          this.setState({ reqType: 'bkash' });
                                          const token = getToken();
                                          const response = await GKApi.get(
                                            `/users/change-request/list?type=bkash&pageNumber=1&pageSize=1000`,
                                            {
                                              headers: {
                                                Authorization: token,
                                                cmpId: 47,
                                              },
                                            }
                                          );
                                          response.data.data.forEach((item) => {
                                            item['authDiff'] =
                                              this.state.compAuthState -
                                              item.authStat;
                                            this.state.bkashReq.push(item);
                                          });
                                          this.setState({
                                            bkashReq: response.data.data,
                                          });
                                        }}
                                      >
                                        Bkash
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={async (event) => {
                                          this.setState({ reqType: 'unbind' });
                                          const token = getToken();
                                          const response = await GKApi.get(
                                            `/users/change-request/list?type=unbind&pageNumber=1&pageSize=1000`,
                                            {
                                              headers: {
                                                Authorization: token,
                                                cmpId: 47,
                                              },
                                            }
                                          );
                                          response.data.data.forEach((item) => {
                                            item['authDiff'] =
                                              this.state.compAuthState -
                                              item.authStat;
                                            this.state.unbindReq.push(item);
                                          });
                                          this.setState({
                                            unbindReq: response.data.data,
                                          });
                                        }}
                                      >
                                        Unbind
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>
                              </div>
                            ),
                          }}
                          data={
                            this.state.reqType === 'profile'
                              ? this.state.profileReq
                              : this.state.reqType === 'bank'
                              ? this.state.bankReq
                              : this.state.reqType === 'address'
                              ? this.state.addressReq
                              : this.state.reqType === 'bkash'
                              ? this.state.bkashReq
                              : this.state.unbindReq
                          }
                          actions={[
                            {
                              icon: () => <i className="fas fa-eye" />,
                              tooltip: 'Click to view request data',
                              onClick: async (event, row) => {
                                this.setState({ modal_standard: true });
                                this.setState({ key: row });

                                if (this.state.reqType === 'profile') {
                                  const token = getToken();

                                  //nid front current image fetching

                                  if (row.currentNIDFront) {
                                    const nidCurrent = await GKApi.get(
                                      `/users/presigned-url?type=get&key=${row.currentNIDFront}`,

                                      {
                                        headers: {
                                          Authorization: token,
                                        },
                                      }
                                    );
                                    axios.get(nidCurrent.data.url);

                                    this.setState({
                                      ...this.state,
                                      nidFrontPicCurrent: nidCurrent.data.url,
                                    });
                                  }
                                  //nid front updated image fetching
                                  if (row.updatedNIDFront !== null) {
                                    const nidUpdated = await GKApi.get(
                                      `/users/presigned-url?type=get&key=${row.updatedNIDFront}`,

                                      {
                                        headers: {
                                          Authorization: token,
                                        },
                                      }
                                    );
                                    axios.get(nidUpdated.data.url);

                                    this.setState({
                                      ...this.state,
                                      nidFrontPicUpdated: nidUpdated.data.url,
                                    });
                                  }

                                  //nid back image fetching

                                  if (row.currentNIDBack) {
                                    const nid2 = await GKApi.get(
                                      `/users/presigned-url?type=get&key=${row.currentNIDBack}`,

                                      {
                                        headers: {
                                          Authorization: token,
                                        },
                                      }
                                    );
                                    axios.get(nid2.data.url);

                                    this.setState({
                                      ...this.state,
                                      nidBackPicCurrent: nid2.data.url,
                                    });
                                  }
                                  //nid back updated image fetching

                                  if (row.updatedNIDBack !== null) {
                                    const nid2Updated = await GKApi.get(
                                      `/users/presigned-url?type=get&key=${row.updatedNIDBack}`,

                                      {
                                        headers: {
                                          Authorization: token,
                                        },
                                      }
                                    );

                                    this.setState({
                                      ...this.state,
                                      nidBackPicUpdated: nid2Updated.data.url,
                                    });
                                  }
                                }
                              },
                            },
                            {
                              icon: () => <Button>Export All</Button>, // you can pass icon too
                              tooltip: 'Export to Excel',
                              onClick: () => downloadExcel(),
                              isFreeAction: true,
                            },
                          ]}
                          options={{
                            cellStyle: {
                              borderRight: '1px solid #eee',
                              textAlign: 'left',
                            },

                            //style for the header

                            headerStyle: {
                              whiteSpace: 'nowrap',

                              textAlign: 'left',
                              top: 0,
                            },
                            actionsColumnIndex: -1,
                            exportButton: { csv: true, pdf: true },
                            exportFileName: 'User Requests',
                            pageSize: 20,
                            columnsButton: true,
                            rowStyle: (rowData) => ({
                              backgroundColor:
                                rowData.authStat === 1 ? 'cyan' : 'beige',
                            }),
                          }}
                          localization={{
                            toolbar: {
                              exportCSVName: 'Export as CSV',
                              exportPDFName: 'Export as PDF',
                            },
                          }}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <UserRequestHistory />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>

              <div id="48">
                {this.state.reqType === 'profile' ? (
                  <Modal
                    size="lg"
                    isOpen={this.state.modal_standard}
                    toggle={() => {
                      this.tog_standard();
                      this.setState({
                        key: null,

                        nidFrontPicCurrent: null,
                        nidFrontPicUpdated: null,
                        nidBackPicCurrent: null,
                        nidBackPicUpdated: null,
                      });
                    }}
                  >
                    <ModalHeader
                      toggle={async () => {
                        this.setState({
                          modal_standard: false,
                          key: null,

                          nidFrontPicCurrent: null,
                          nidFrontPicUpdated: null,
                          nidBackPicCurrent: null,
                          nidBackPicUpdated: null,
                        });
                      }}
                    >
                      Request Details
                    </ModalHeader>
                    <ModalBody>
                      <div className="d-flex my-3">
                        <Col xs={2}></Col>
                        <Col xs={5} style={{ textAlign: 'center' }}>
                          <h4>Current Information</h4>
                        </Col>
                        <Col xs={5} style={{ textAlign: 'center' }}>
                          <h4>Updated Information</h4>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Name</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentName === null
                                    ? 'Not Available'
                                    : this.state.key?.currentName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedName === null
                                    ? this.state.key?.currentName
                                    : this.state.key?.updatedName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Phone</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.phone === null
                                    ? 'Not Available'
                                    : this.state.key?.phone
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.phone === null
                                    ? this.state.key?.phone
                                    : this.state.key?.phone
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Date of birth</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={moment(
                                  this.state.key?.currentDOB === null
                                    ? 'Not Available'
                                    : this.state.key?.currentDOB
                                ).format('DD/MM/YYYY')}
                                // placeholder="Please input the value for Date of birth"
                                id="date-of-birth"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={moment(
                                  this.state.key?.updatedDOB === null
                                    ? this.state.key?.currentDOB
                                    : this.state.key?.updatedDOB
                                ).format('DD/MM/YYYY')}
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6 style={{ alignItems: 'center' }}>Email </h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentEmail === null
                                    ? 'Not Available'
                                    : this.state.key?.currentEmail
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedEmail === null
                                    ? 'Not Available'
                                    : this.state.key?.updatedEmail
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Gender</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentGender === null
                                    ? 'Not Available'
                                    : this.state.key?.currentGender
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedGender === null
                                    ? this.state.key?.currentGender
                                    : this.state.key?.updatedGender
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Marital Status</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentMaritalStatus === null
                                    ? 'Not Available'
                                    : this.state.key?.currentMaritalStatus
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedMaritalStatus === null
                                    ? this.state.key?.currentMaritalStatus
                                    : this.state.key?.updatedMaritalStatus
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>NID</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentNID === null
                                    ? 'Not Available'
                                    : this.state.key?.currentNID
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedNID === null
                                    ? this.state.key?.currentNID
                                    : this.state.key?.updatedNID
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>NID Front</h6>
                            </Col>
                            <Col xs={5}>
                              <img
                                src={
                                  this.state.nidFrontPicCurrent
                                    ? this.state.nidFrontPicCurrent
                                    : 'Not available'
                                }
                                alt={
                                  this.state.nidFrontPicCurrent
                                    ? 'Loading'
                                    : 'Not available'
                                }
                                style={{
                                  height: 'auto',
                                  maxHeight: 250,
                                  width: 'auto',
                                  maxWidth: 250,
                                  // borderRadius: '25%',
                                  overflow: 'hidden',
                                  borderWidth: 3,
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              />
                            </Col>
                            <Col xs={5}>
                              <img
                                src={
                                  this.state.nidFrontPicUpdated !== null
                                    ? this.state.nidFrontPicUpdated
                                    : this.state.nidFrontPicCurrent
                                }
                                alt={'Loading'}
                                style={{
                                  height: 'auto',
                                  maxHeight: 250,
                                  width: 'auto',
                                  maxWidth: 250,
                                  // borderRadius: '25%',
                                  overflow: 'hidden',
                                  borderWidth: 3,
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>NID Back</h6>
                            </Col>
                            <Col xs={5}>
                              <img
                                src={
                                  this.state.nidBackPicCurrent
                                    ? this.state.nidBackPicCurrent
                                    : 'Not available'
                                }
                                alt={
                                  this.state.nidBackPicCurrent
                                    ? 'Loading'
                                    : 'Not available'
                                }
                                style={{
                                  height: 'auto',
                                  maxHeight: 250,
                                  width: 'auto',
                                  maxWidth: 250,
                                  // borderRadius: '25%',
                                  overflow: 'hidden',
                                  borderWidth: 3,
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              />
                            </Col>
                            <Col xs={5}>
                              <img
                                src={
                                  this.state.nidBackPicUpdated !== null
                                    ? this.state.nidBackPicUpdated
                                    : this.state.nidBackPicCurrent
                                }
                                alt={'Loading'}
                                style={{
                                  height: 'auto',
                                  maxHeight: 250,
                                  width: 'auto',
                                  maxWidth: 250,
                                  // borderRadius: '25%',
                                  overflow: 'hidden',
                                  borderWidth: 3,
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>

                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Profession</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentProfession === null
                                    ? 'Not Available'
                                    : this.state.key?.currentProfession
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedProfession === null
                                    ? this.state.key?.currentProfession
                                    : this.state.key?.updatedProfession
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      {/* <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Verified</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.isVerified === null
                                    ? 'Not Available'
                                    : this.state.key?.isVerified
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.isVerified === null
                                    ? 'Not Available'
                                    : this.state.key?.isVerified
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div> */}
                    </ModalBody>
                    {this.state.key?.authDiff === 1 ||
                    this.state.key?.authDiff === 5 ||
                    this.state.key?.authDiff === 6 ? (
                      <ModalFooter>
                        <div className="d-flex gap-3">
                          <Button
                            type="button"
                            color="light"
                            className="waves-effect"
                            onClick={async (event) => {
                              event.preventDefault();
                              this.setState({ modal_standard4: true });
                            }}
                          >
                            Reject
                          </Button>

                          {/*
                           * @desc modal for REJECTING request for the CHECKER AND AUTHORIZER STARTS here
                           */}
                          <Modal
                            isOpen={this.state.modal_standard4}
                            toggle={this.tog_standard4}
                          >
                            <ModalHeader
                              toggle={() =>
                                this.setState({
                                  modal_standard4: false,
                                })
                              }
                            >
                              Reject user request
                            </ModalHeader>

                            <ModalBody>
                              {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                              <Col xl={12}>
                                <Row className="mb-3 mx-2">
                                  Please confirm to reject user request
                                </Row>
                                <Row className="mb-3 mx-2">
                                  <Dropdown
                                    isOpen={this.state.reasonMenu}
                                    toggle={(event) =>
                                      this.setState({
                                        reasonMenu: !this.state.reasonMenu,
                                      })
                                    }
                                  >
                                    <DropdownToggle
                                      className="px-5 py-3"
                                      color="light"
                                    >
                                      {this.state.reason
                                        ? capitalizeFirstLetter(
                                            this.state.reason
                                          )
                                        : 'Select a reason for rejecting the request'}{' '}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={(event) => {
                                          this.setState({
                                            reason:
                                              'as customer could not be reached over call',
                                          });
                                        }}
                                      >
                                        Request was rejected as customer could
                                        not be reached over call.
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={(event) => {
                                          this.setState({
                                            reason:
                                              'due to inconsistent information',
                                          });
                                        }}
                                      >
                                        Request was rejected due to inconsistent
                                        information.
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={(event) => {
                                          this.setState({
                                            reason: 'as per customer’s request',
                                          });
                                        }}
                                      >
                                        Request was rejected as per customer’s
                                        request.
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </Row>
                              </Col>
                            </ModalBody>

                            {this.state.reason !== '' && (
                              <ModalFooter>
                                <Button
                                  type="button"
                                  onClick={() => {
                                    this.tog_standard4();
                                  }}
                                  color="light"
                                  className="waves-effect"
                                >
                                  Cancel
                                </Button>

                                <Button
                                  type="submit"
                                  color="primary"
                                  className="waves-effect waves-light"
                                  //submit refer data for disbursement
                                  onClick={async (event) => {
                                    event.preventDefault();
                                    const token = getToken();

                                    await GKApi.put(
                                      `users/change-request/reject`,
                                      {
                                        type:
                                          this.state.reqType === 'profile'
                                            ? 'profile'
                                            : this.state.reqType === 'bank'
                                            ? 'bank'
                                            : this.state.reqType === 'bkash'
                                            ? 'bkash'
                                            : 'address',
                                        id: this.state.key?.id,
                                        reason: this.state.reason,
                                        cmpId: 48,
                                        deviceInfo: this.state.deviceInfo,
                                      },
                                      {
                                        headers: {
                                          Authorization: token,
                                        },
                                      }
                                    );
                                    this.setState((prevState) => ({
                                      modal_standard: !prevState.modal_standard,
                                    }));
                                    this.removeBodyCss();
                                    const response = await GKApi.get(
                                      `/users/change-request/list?type=profile&pageNumber=1&pageSize=1000`,
                                      {
                                        headers: {
                                          Authorization: token,
                                          cmpId: 47,
                                        },
                                      }
                                    );

                                    response.data.data.forEach((item) => {
                                      item['authDiff'] =
                                        this.state.compAuthState -
                                        item.authStat;
                                      this.state.profileReq.push(item);
                                    });
                                    this.setState({
                                      profileReq: response.data.data,
                                      key: null,
                                      profilePicCurrent: null,
                                      profilePicUpdated: null,
                                      nidFrontPicCurrent: null,
                                      nidFrontPicUpdated: null,
                                      nidBackPicCurrent: null,
                                      nidBackPicUpdated: null,
                                    });
                                    this.tog_standard4();
                                  }}
                                >
                                  Reject
                                </Button>
                                {this.state.custom_div1 ? (
                                  <SweetAlert
                                    title="Request successfully submitted for review!!"
                                    timeout={2500}
                                    style={{
                                      top: '0px',
                                      right: '0px',
                                    }}
                                    showCloseButton={false}
                                    showConfirm={false}
                                    success
                                    onConfirm={() =>
                                      this.setState({ custom_div1: false })
                                    }
                                  ></SweetAlert>
                                ) : null}
                              </ModalFooter>
                            )}
                          </Modal>

                          <Button
                            type="button"
                            color="primary"
                            className="waves-effect waves-light"
                            onClick={async (event) => {
                              event.preventDefault();
                              this.setState({ modal_standard5: true });
                            }}
                          >
                            Accept
                          </Button>
                        </div>
                        {/*
                         * @desc modal for Approving request for the CHECKER AND AUTHORIZER STARTS here
                         */}
                        <Modal
                          isOpen={this.state.modal_standard5}
                          toggle={this.tog_standard5}
                        >
                          <ModalHeader
                            toggle={() =>
                              this.setState({ modal_standard5: false })
                            }
                          >
                            Accept user request
                          </ModalHeader>

                          <ModalBody>
                            {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                            <Col xl={12}>
                              <Row className="mb-3 mx-2">
                                Please confirm to Accept user request
                              </Row>
                            </Col>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              type="button"
                              onClick={this.tog_standard5}
                              color="light"
                              className="waves-effect"
                            >
                              Cancel
                            </Button>

                            <Button
                              type="submit"
                              color="primary"
                              className="waves-effect waves-light"
                              //submit refer data for disbursement
                              onClick={async (event) => {
                                event.preventDefault();
                                const token = getToken();

                                await GKApi.put(
                                  `users/change-request/approve`,
                                  {
                                    type:
                                      this.state.reqType === 'profile'
                                        ? 'profile'
                                        : this.state.reqType === 'bank'
                                        ? 'bank'
                                        : this.state.reqType === 'bkash'
                                        ? 'bkash'
                                        : 'address',
                                    id: this.state.key?.id,
                                    // isApproved: true,
                                    cmpId: 48,
                                    deviceInfo: this.state.deviceInfo,
                                  },
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );

                                if (this.state.key?.isVerified === 'false') {
                                  this.setState({ ...this.state, modal: true });
                                } else {
                                  this.setState((prevState) => ({
                                    modal_standard: !prevState.modal_standard,
                                  }));
                                  this.removeBodyCss();
                                  const response = await GKApi.get(
                                    `/users/change-request/list?type=profile&pageNumber=1&pageSize=1000`,
                                    {
                                      headers: {
                                        Authorization: token,
                                        cmpId: 47,
                                      },
                                    }
                                  );

                                  response.data.data.forEach((item) => {
                                    item['authDiff'] =
                                      this.state.compAuthState - item.authStat;
                                    this.state.profileReq.push(item);
                                  });
                                  this.setState({
                                    profileReq: response.data.data,
                                    key: null,
                                    profilePicCurrent: null,
                                    profilePicUpdated: null,
                                    nidFrontPicCurrent: null,
                                    nidFrontPicUpdated: null,
                                    nidBackPicCurrent: null,
                                    nidBackPicUpdated: null,
                                  });
                                  this.tog_standard5();
                                }
                              }}
                            >
                              Approve
                            </Button>
                            {this.state.custom_div1 ? (
                              <SweetAlert
                                title="Request successfully submitted for review!!"
                                timeout={2500}
                                style={{
                                  top: '0px',
                                  right: '0px',
                                }}
                                showCloseButton={false}
                                showConfirm={false}
                                success
                                onConfirm={() =>
                                  this.setState({ custom_div1: false })
                                }
                              ></SweetAlert>
                            ) : null}
                          </ModalFooter>
                        </Modal>
                        {this.state.modal === true ? (
                          <SweetAlert
                            title="Do you want to verify this user as well?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={async () => {
                              const token = getToken();
                              var statusData =
                                this.state.key?.isVerified === 'true'
                                  ? false
                                  : true;
                              await GKApi.put(
                                `/users/verify`,
                                {
                                  userId: this.state.key?.userId,
                                  approve: statusData,
                                },
                                {
                                  headers: {
                                    Authorization: token,
                                  },
                                }
                              );
                              this.setState({
                                success_confirm: true,
                                modal: false,
                                dynamic_title: 'Verified!',
                                dynamic_description:
                                  'This user has been verified.',
                              });
                            }}
                            onCancel={() => {
                              this.setState({
                                modal: false,
                              });
                              this.setState((prevState) => ({
                                modal_standard: !prevState.modal_standard,
                              }));
                              this.removeBodyCss();
                            }}
                          ></SweetAlert>
                        ) : null}
                      </ModalFooter>
                    ) : (
                      ''
                    )}
                  </Modal>
                ) : this.state.reqType === 'bank' ? (
                  <Modal
                    size="lg"
                    isOpen={this.state.modal_standard}
                    toggle={this.tog_standard}
                  >
                    <ModalHeader
                      toggle={() => this.setState({ modal_standard: false })}
                    >
                      Request Details
                    </ModalHeader>
                    <ModalBody>
                      <div className="d-flex my-3">
                        <Col xs={2}></Col>
                        <Col xs={5} style={{ textAlign: 'center' }}>
                          <h4>Current Information</h4>
                        </Col>
                        <Col xs={5} style={{ textAlign: 'center' }}>
                          <h4>Updated Information</h4>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Account name</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentAccountName === null
                                    ? 'Not Available'
                                    : this.state.key?.currentAccountName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedAccountName === null
                                    ? this.state.key?.currentAccountName
                                    : this.state.key?.updatedAccountName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6 style={{ alignItems: 'center' }}>
                                Account no{' '}
                              </h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentAccountNo === null
                                    ? 'Not Available'
                                    : this.state.key?.currentAccountNo
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedAccountNo === null
                                    ? 'Not Available'
                                    : this.state.key?.updatedAccountNo
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Bank Code</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentBankCode === null
                                    ? 'Not Available'
                                    : this.state.key?.currentBankCode
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedBankCode === null
                                    ? this.state.key?.currentBankCode
                                    : this.state.key?.updatedBankCode
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Bank District</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentBankDistrict === null
                                    ? 'Not Available'
                                    : this.state.key?.currentBankDistrict
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedBankDistrict === null
                                    ? this.state.key?.currentBankDistrict
                                    : this.state.key?.updatedBankDistrict
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Bank District Code</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentBankDistrictCode ===
                                  null
                                    ? 'Not Available'
                                    : this.state.key?.currentBankDistrictCode
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedBankDistrictCode ===
                                  null
                                    ? this.state.key?.currentBankDistrictCode
                                    : this.state.key?.updatedBankDistrictCode
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Bank Name</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentBankName === null
                                    ? 'Not Available'
                                    : this.state.key?.currentBankName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedBankName === null
                                    ? this.state.key?.currentBankName
                                    : this.state.key?.updatedBankName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Branch Code</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentBranchCode === null
                                    ? 'Not Available'
                                    : this.state.key?.currentBranchCode
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedBranchCode === null
                                    ? this.state.key?.currentBranchCode
                                    : this.state.key?.updatedBranchCode
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Branch Name</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentBranchName === null
                                    ? 'Not Available'
                                    : this.state.key?.currentBranchName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedBranchName === null
                                    ? this.state.key?.currentBranchName
                                    : this.state.key?.updatedBranchName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Routing No</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentRoutingNo === null
                                    ? 'Not Available'
                                    : this.state.key?.currentRoutingNo
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedRoutingNo === null
                                    ? this.state.key?.currentRoutingNo
                                    : this.state.key?.updatedRoutingNo
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Name</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.name === null
                                    ? 'Not Available'
                                    : this.state.key?.name
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Phone</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.phone === null
                                    ? 'Not Available'
                                    : this.state.key?.phone
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </ModalBody>
                    {this.state.key?.authDiff === 1 ||
                    this.state.key?.authDiff === 5 ||
                    this.state.key?.authDiff === 6 ? (
                      <ModalFooter>
                        <Button
                          type="button"
                          color="light"
                          className="waves-effect"
                          onClick={async (event) => {
                            event.preventDefault();
                            this.setState({ modal_standard6: true });
                          }}
                        >
                          Reject
                        </Button>

                        {/*
                         * @desc modal for REJECTING request for the CHECKER AND AUTHORIZER STARTS here
                         */}
                        <Modal
                          isOpen={this.state.modal_standard6}
                          toggle={this.tog_standard6}
                        >
                          <ModalHeader
                            toggle={() =>
                              this.setState({ modal_standard6: false })
                            }
                          >
                            Reject user request
                          </ModalHeader>

                          <ModalBody>
                            {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                            <Col xl={12}>
                              <Row className="mb-3 mx-2">
                                Please confirm to reject user request
                              </Row>
                              <Row className="mb-3 mx-2">
                                <Dropdown
                                  isOpen={this.state.reasonMenu}
                                  toggle={(event) =>
                                    this.setState({
                                      reasonMenu: !this.state.reasonMenu,
                                    })
                                  }
                                >
                                  <DropdownToggle
                                    className="px-5 py-3"
                                    color="light"
                                  >
                                    {this.state.reason
                                      ? capitalizeFirstLetter(this.state.reason)
                                      : 'Select a reason for rejecting the request'}{' '}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={(event) => {
                                        this.setState({
                                          reason:
                                            'as customer could not be reached over call',
                                        });
                                      }}
                                    >
                                      Request was rejected as customer could not
                                      be reached over call.
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) => {
                                        this.setState({
                                          reason:
                                            'due to inconsistent information',
                                        });
                                      }}
                                    >
                                      Request was rejected due to inconsistent
                                      information.
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) => {
                                        this.setState({
                                          reason: 'as per customer’s request',
                                        });
                                      }}
                                    >
                                      Request was rejected as per customer’s
                                      request.
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </Row>
                            </Col>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              type="button"
                              onClick={this.tog_standard6}
                              color="light"
                              className="waves-effect"
                            >
                              Cancel
                            </Button>

                            <Button
                              type="submit"
                              color="primary"
                              className="waves-effect waves-light"
                              //submit refer data for disbursement
                              onClick={async (event) => {
                                event.preventDefault();
                                const token = getToken();

                                await GKApi.put(
                                  `users/change-request/reject`,
                                  {
                                    type:
                                      this.state.reqType === 'profile'
                                        ? 'profile'
                                        : this.state.reqType === 'bank'
                                        ? 'bank'
                                        : this.state.reqType === 'bkash'
                                        ? 'bkash'
                                        : 'address',
                                    id: this.state.key?.id,
                                    reason: this.state.reason,
                                    cmpId: 48,
                                    deviceInfo: this.state.deviceInfo,
                                  },
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );
                                this.setState((prevState) => ({
                                  modal_standard: !prevState.modal_standard,
                                }));
                                this.removeBodyCss();
                                const response = await GKApi.get(
                                  `/users/change-request/list?type=bank&pageNumber=1&pageSize=1000`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 47,
                                    },
                                  }
                                );
                                response.data.data.forEach((item) => {
                                  item['authDiff'] =
                                    this.state.compAuthState - item.authStat;
                                  this.state.bankReq.push(item);
                                });
                                this.setState(
                                  { bankReq: response.data.data },
                                  () => {}
                                );
                              }}
                            >
                              Reject
                            </Button>
                            {this.state.custom_div1 ? (
                              <SweetAlert
                                title="Request successfully submitted for review!!"
                                timeout={2500}
                                style={{
                                  top: '0px',
                                  right: '0px',
                                }}
                                showCloseButton={false}
                                showConfirm={false}
                                success
                                onConfirm={() =>
                                  this.setState({ custom_div1: false })
                                }
                              ></SweetAlert>
                            ) : null}
                          </ModalFooter>
                        </Modal>
                        <Button
                          type="button"
                          color="primary"
                          className="waves-effect waves-light"
                          onClick={async (event) => {
                            event.preventDefault();
                            this.setState({ modal_standard7: true });
                          }}
                        >
                          Accept
                        </Button>

                        {/*
                         * @desc modal for Approving request for the CHECKER AND AUTHORIZER STARTS here
                         */}
                        <Modal
                          isOpen={this.state.modal_standard7}
                          toggle={this.tog_standard7}
                        >
                          <ModalHeader
                            toggle={() =>
                              this.setState({ modal_standard7: false })
                            }
                          >
                            Accept user request
                          </ModalHeader>

                          <ModalBody>
                            {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                            <Col xl={12}>
                              <Row className="mb-3 mx-2">
                                Please confirm to Accept user request
                              </Row>
                            </Col>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              type="button"
                              onClick={this.tog_standard7}
                              color="light"
                              className="waves-effect"
                            >
                              Cancel
                            </Button>

                            <Button
                              type="submit"
                              color="primary"
                              className="waves-effect waves-light"
                              //submit refer data for disbursement
                              onClick={async (event) => {
                                event.preventDefault();

                                const token = getToken();

                                await GKApi.put(
                                  `users/change-request/approve`,
                                  {
                                    type:
                                      this.state.reqType === 'profile'
                                        ? 'profile'
                                        : this.state.reqType === 'bank'
                                        ? 'bank'
                                        : this.state.reqType === 'bkash'
                                        ? 'bkash'
                                        : 'address',
                                    id: this.state.key?.id,
                                    // isApproved: true,
                                    deviceInfo: this.state.deviceInfo,
                                    cmpId: 48,
                                  },
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );
                                this.setState((prevState) => ({
                                  modal_standard: !prevState.modal_standard,
                                }));
                                this.removeBodyCss();
                                const response = await GKApi.get(
                                  `/users/change-request/list?type=bank&pageNumber=1&pageSize=1000`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 47,
                                    },
                                  }
                                );
                                response.data.data.forEach((item) => {
                                  item['authDiff'] =
                                    this.state.compAuthState - item.authStat;
                                  this.state.bankReq.push(item);
                                });
                                this.setState(
                                  { bankReq: response.data.data },
                                  () => {}
                                );
                              }}
                            >
                              Approve
                            </Button>
                            {this.state.custom_div1 ? (
                              <SweetAlert
                                title="Request successfully submitted for review!!"
                                timeout={2500}
                                style={{
                                  top: '0px',
                                  right: '0px',
                                }}
                                showCloseButton={false}
                                showConfirm={false}
                                success
                                onConfirm={() =>
                                  this.setState({ custom_div1: false })
                                }
                              ></SweetAlert>
                            ) : null}
                          </ModalFooter>
                        </Modal>
                      </ModalFooter>
                    ) : (
                      ''
                    )}
                  </Modal>
                ) : this.state.reqType === 'bkash' ? (
                  <Modal
                    size="lg"
                    isOpen={this.state.modal_standard}
                    toggle={this.tog_standard}
                  >
                    <ModalHeader
                      toggle={() => this.setState({ modal_standard: false })}
                    >
                      Request Details
                    </ModalHeader>
                    <ModalBody>
                      <div className="d-flex my-3">
                        <Col xs={2}></Col>
                        <Col xs={5} style={{ textAlign: 'center' }}>
                          <h4>Current Information</h4>
                        </Col>
                        <Col xs={5} style={{ textAlign: 'center' }}>
                          <h4>Updated Information</h4>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Bkash Account name</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentBkashAccName === null
                                    ? 'Not Available'
                                    : this.state.key?.currentBkashAccName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedBkashAccName === null
                                    ? this.state.key?.currentBkashAccName
                                    : this.state.key?.updatedBkashAccName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>

                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Bkash Number</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentBkashNumber === null
                                    ? 'Not Available'
                                    : this.state.key?.currentBkashNumber
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedBkashNumber === null
                                    ? this.state.key?.currentBkashNumber
                                    : this.state.key?.updatedBkashNumber
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </ModalBody>
                    {this.state.key?.authDiff === 1 ||
                    this.state.key?.authDiff === 5 ||
                    this.state.key?.authDiff === 6 ? (
                      <ModalFooter>
                        <Button
                          type="button"
                          color="light"
                          className="waves-effect"
                          onClick={async (event) => {
                            event.preventDefault();
                            this.setState({ modal_standard8: true });
                          }}
                        >
                          Reject
                        </Button>

                        {/*
                         * @desc modal for REJECTING request for the CHECKER AND AUTHORIZER STARTS here
                         */}
                        <Modal
                          isOpen={this.state.modal_standard8}
                          toggle={this.tog_standard8}
                        >
                          <ModalHeader
                            toggle={() =>
                              this.setState({ modal_standard8: false })
                            }
                          >
                            Reject user request
                          </ModalHeader>

                          <ModalBody>
                            {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                            <Col xl={12}>
                              <Row className="mb-3 mx-2">
                                Please confirm to reject user request
                              </Row>
                              <Row className="mb-3 mx-2">
                                <Dropdown
                                  isOpen={this.state.reasonMenu}
                                  toggle={(event) =>
                                    this.setState({
                                      reasonMenu: !this.state.reasonMenu,
                                    })
                                  }
                                >
                                  <DropdownToggle
                                    className="px-5 py-3"
                                    color="light"
                                  >
                                    {this.state.reason
                                      ? capitalizeFirstLetter(this.state.reason)
                                      : 'Select a reason for rejecting the request'}{' '}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={(event) => {
                                        this.setState({
                                          reason:
                                            'as customer could not be reached over call',
                                        });
                                      }}
                                    >
                                      Request was rejected as customer could not
                                      be reached over call.
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) => {
                                        this.setState({
                                          reason:
                                            'due to inconsistent information',
                                        });
                                      }}
                                    >
                                      Request was rejected due to inconsistent
                                      information.
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) => {
                                        this.setState({
                                          reason: 'as per customer’s request',
                                        });
                                      }}
                                    >
                                      Request was rejected as per customer’s
                                      request.
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </Row>
                            </Col>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              type="button"
                              onClick={this.tog_standard8}
                              color="light"
                              className="waves-effect"
                            >
                              Cancel
                            </Button>

                            <Button
                              type="submit"
                              color="primary"
                              className="waves-effect waves-light"
                              //submit refer data for disbursement
                              onClick={async (event) => {
                                event.preventDefault();
                                const token = getToken();

                                await GKApi.put(
                                  `users/change-request/reject`,
                                  {
                                    type:
                                      this.state.reqType === 'profile'
                                        ? 'profile'
                                        : this.state.reqType === 'bank'
                                        ? 'bank'
                                        : this.state.reqType === 'bkash'
                                        ? 'bkash'
                                        : 'address',
                                    id: this.state.key?.id,
                                    reason: this.state.reason,
                                    cmpId: 48,
                                    deviceInfo: this.state.deviceInfo,
                                  },
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );
                                this.setState((prevState) => ({
                                  modal_standard: !prevState.modal_standard,
                                }));
                                this.removeBodyCss();
                                const response = await GKApi.get(
                                  `/users/change-request/list?type=bkash&pageNumber=1&pageSize=1000`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 47,
                                    },
                                  }
                                );
                                response.data.data.forEach((item) => {
                                  item['authDiff'] =
                                    this.state.compAuthState - item.authStat;
                                  this.state.bkashReq.push(item);
                                });

                                this.setState({ bkashReq: response.data.data });
                              }}
                            >
                              Reject
                            </Button>
                            {this.state.custom_div1 ? (
                              <SweetAlert
                                title="Request successfully submitted for review!!"
                                timeout={2500}
                                style={{
                                  top: '0px',
                                  right: '0px',
                                }}
                                showCloseButton={false}
                                showConfirm={false}
                                success
                                onConfirm={() =>
                                  this.setState({ custom_div1: false })
                                }
                              ></SweetAlert>
                            ) : null}
                          </ModalFooter>
                        </Modal>
                        <Button
                          type="button"
                          color="primary"
                          className="waves-effect waves-light"
                          onClick={async (event) => {
                            event.preventDefault();
                            this.setState({ modal_standard11: true });
                          }}
                        >
                          Accept
                        </Button>

                        {/*
                         * @desc modal for Approving request for the CHECKER AND AUTHORIZER STARTS here
                         */}
                        <Modal
                          isOpen={this.state.modal_standard11}
                          toggle={this.tog_standard11}
                        >
                          <ModalHeader
                            toggle={() =>
                              this.setState({ modal_standard11: false })
                            }
                          >
                            Accept user request
                          </ModalHeader>

                          <ModalBody>
                            {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                            <Col xl={12}>
                              <Row className="mb-3 mx-2">
                                Please confirm to Accept user request
                              </Row>
                            </Col>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              type="button"
                              onClick={this.tog_standard11}
                              color="light"
                              className="waves-effect"
                            >
                              Cancel
                            </Button>

                            <Button
                              type="submit"
                              color="primary"
                              className="waves-effect waves-light"
                              //submit refer data for disbursement
                              onClick={async (event) => {
                                event.preventDefault();
                                const token = getToken();

                                await GKApi.put(
                                  `users/change-request/approve`,
                                  {
                                    type:
                                      this.state.reqType === 'profile'
                                        ? 'profile'
                                        : this.state.reqType === 'bank'
                                        ? 'bank'
                                        : this.state.reqType === 'bkash'
                                        ? 'bkash'
                                        : 'address',
                                    id: this.state.key?.id,
                                    // isApproved: true,
                                    cmpId: 48,
                                    deviceInfo: this.state.deviceInfo,
                                  },
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );

                                if (this.state.key?.isVerified === 'false') {
                                  this.setState({ ...this.state, modal: true });
                                } else {
                                  this.setState((prevState) => ({
                                    modal_standard: !prevState.modal_standard,
                                  }));
                                  this.removeBodyCss();
                                  const response = await GKApi.get(
                                    `/users/change-request/list?type=bkash&pageNumber=1&pageSize=1000`,
                                    {
                                      headers: {
                                        Authorization: token,
                                        cmpId: 47,
                                      },
                                    }
                                  );
                                  response.data.data.forEach((item) => {
                                    item['authDiff'] =
                                      this.state.compAuthState - item.authStat;
                                    this.state.bkashReq.push(item);
                                  });

                                  this.setState({
                                    bkashReq: response.data.data,
                                  });
                                }
                              }}
                            >
                              Approve
                            </Button>
                            {this.state.custom_div1 ? (
                              <SweetAlert
                                title="Request successfully submitted for review!!"
                                timeout={2500}
                                style={{
                                  top: '0px',
                                  right: '0px',
                                }}
                                showCloseButton={false}
                                showConfirm={false}
                                success
                                onConfirm={() =>
                                  this.setState({ custom_div1: false })
                                }
                              ></SweetAlert>
                            ) : null}
                          </ModalFooter>
                        </Modal>
                      </ModalFooter>
                    ) : (
                      ''
                    )}
                  </Modal>
                ) : this.state.reqType === 'unbind' ? (
                  <Modal
                    size="lg"
                    isOpen={this.state.modal_standard}
                    toggle={this.tog_standard}
                  >
                    <ModalHeader
                      toggle={() => this.setState({ modal_standard: false })}
                    >
                      Request Details
                    </ModalHeader>
                    <ModalBody>
                      <div className="d-flex my-3">
                        <Col xs={2}></Col>
                        <Col xs={5} style={{ textAlign: 'center' }}>
                          <h4>Current Information</h4>
                        </Col>
                        <Col xs={5} style={{ textAlign: 'center' }}>
                          <h4>Updated Information</h4>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Date of birth</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={moment(
                                  this.state.key?.curUserDOB === null
                                    ? 'Not Available'
                                    : this.state.key?.curUserDOB
                                ).format('DD/MM/YYYY')}
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={moment(
                                  this.state.key?.reqUserDOB === null
                                    ? this.state.key?.curUserDOB
                                    : this.state.key?.reqUserDOB
                                ).format('DD/MM/YYYY')}
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6 style={{ alignItems: 'center' }}>Email </h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.curUserEmail === null
                                    ? 'Not Available'
                                    : this.state.key?.curUserEmail
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.reqUserEmail === null
                                    ? this.state.key?.curUserEmail
                                    : this.state.key?.reqUserEmail
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>

                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>NID</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.curUserNID === null
                                    ? 'Not Available'
                                    : this.state.key?.curUserNID
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.reqUserNID === null
                                    ? this.state.key?.curUserNID
                                    : this.state.key?.reqUserNID
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>

                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Name</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.curUserName === null
                                    ? 'Not Available'
                                    : this.state.key?.curUserName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.reqUserName === null
                                    ? this.state.key?.curUserName
                                    : this.state.key?.reqUserName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>

                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Verified</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.isCurUserVerified === null
                                    ? 'Not Available'
                                    : this.state.key?.isCurUserVerified
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.isReqUserVerified === null
                                    ? this.state.key?.isCurUserVerified
                                    : this.state.key?.isReqUserVerified
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Phone</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.curUserphone === null
                                    ? 'Not Available'
                                    : this.state.key?.curUserphone
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.reqUserphone === null
                                    ? this.state.key?.curUserphone
                                    : this.state.key?.reqUserphone
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </ModalBody>
                    {this.state.key?.authDiff === 1 ||
                    this.state.key?.authDiff === 5 ||
                    this.state.key?.authDiff === 6 ? (
                      <ModalFooter>
                        <div className="d-flex gap-3">
                          <Button
                            type="button"
                            color="light"
                            className="waves-effect"
                            onClick={async (event) => {
                              event.preventDefault();
                              this.setState({ modal_standard10: true });
                            }}
                          >
                            Reject
                          </Button>

                          {/*
                           * @desc modal for REJECTING request for the CHECKER AND AUTHORIZER STARTS here
                           */}
                          <Modal
                            isOpen={this.state.modal_standard10}
                            toggle={this.tog_standard10}
                          >
                            <ModalHeader
                              toggle={() =>
                                this.setState({ modal_standard10: false })
                              }
                            >
                              Reject user request
                            </ModalHeader>

                            <ModalBody>
                              {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                              <Col xl={12}>
                                <Row className="mb-3 mx-2">
                                  Please confirm to reject user request
                                </Row>
                                <Row className="mb-3 mx-2">
                                  <Dropdown
                                    isOpen={this.state.reasonMenu}
                                    toggle={(event) =>
                                      this.setState({
                                        reasonMenu: !this.state.reasonMenu,
                                      })
                                    }
                                  >
                                    <DropdownToggle
                                      className="px-5 py-3"
                                      color="light"
                                    >
                                      {this.state.reason
                                        ? capitalizeFirstLetter(
                                            this.state.reason
                                          )
                                        : 'Select a reason for rejecting the request'}{' '}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={(event) => {
                                          this.setState({
                                            reason:
                                              'as customer could not be reached over call',
                                          });
                                        }}
                                      >
                                        Request was rejected as customer could
                                        not be reached over call.
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={(event) => {
                                          this.setState({
                                            reason:
                                              'due to inconsistent information',
                                          });
                                        }}
                                      >
                                        Request was rejected due to an
                                        information mismatch.
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={(event) => {
                                          this.setState({
                                            reason: 'as per customer’s request',
                                          });
                                        }}
                                      >
                                        Request was rejected as per your
                                        feedback.
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </Row>
                              </Col>
                            </ModalBody>

                            <ModalFooter>
                              <Button
                                type="button"
                                onClick={this.tog_standard10}
                                color="light"
                                className="waves-effect"
                              >
                                Cancel
                              </Button>

                              <Button
                                type="submit"
                                color="primary"
                                className="waves-effect waves-light"
                                //submit refer data for disbursement
                                onClick={async (event) => {
                                  event.preventDefault();
                                  const token = getToken();

                                  await GKApi.put(
                                    `users/change-request/reject`,
                                    {
                                      type:
                                        this.state.reqType === 'profile'
                                          ? 'profile'
                                          : this.state.reqType === 'bank'
                                          ? 'bank'
                                          : this.state.reqType === 'bkash'
                                          ? 'bkash'
                                          : this.state.reqType === 'address'
                                          ? 'address'
                                          : 'unbind',
                                      id: this.state.key?.id,
                                      reason: this.state.reason,
                                      cmpId: 48,
                                      deviceInfo: this.state.deviceInfo,
                                    },
                                    {
                                      headers: {
                                        Authorization: token,
                                      },
                                    }
                                  );
                                  this.setState((prevState) => ({
                                    modal_standard: !prevState.modal_standard,
                                  }));
                                  this.removeBodyCss();
                                  const response = await GKApi.get(
                                    `/users/change-request/list?type=unbind&pageNumber=1&pageSize=1000`,
                                    {
                                      headers: {
                                        Authorization: token,
                                        cmpId: 47,
                                      },
                                    }
                                  );
                                  response.data.data.forEach((item) => {
                                    item['authDiff'] =
                                      this.state.compAuthState - item.authStat;
                                    this.state.unbindReq.push(item);
                                  });

                                  this.setState({
                                    unbindReq: response.data.data,
                                  });
                                }}
                              >
                                Reject
                              </Button>
                              {this.state.custom_div1 ? (
                                <SweetAlert
                                  title="Request successfully submitted for review!!"
                                  timeout={2500}
                                  style={{
                                    top: '0px',
                                    right: '0px',
                                  }}
                                  showCloseButton={false}
                                  showConfirm={false}
                                  success
                                  onConfirm={() =>
                                    this.setState({ custom_div1: false })
                                  }
                                ></SweetAlert>
                              ) : null}
                            </ModalFooter>
                          </Modal>
                          <Button
                            type="button"
                            color="primary"
                            className="waves-effect waves-light"
                            onClick={async (event) => {
                              event.preventDefault();
                              const token = getToken();

                              await GKApi.put(
                                `users/change-request/approve`,
                                {
                                  type:
                                    this.state.reqType === 'profile'
                                      ? 'profile'
                                      : this.state.reqType === 'bank'
                                      ? 'bank'
                                      : this.state.reqType === 'bkash'
                                      ? 'bkash'
                                      : this.state.reqType === 'address'
                                      ? 'address'
                                      : 'unbind',
                                  id: this.state.key?.id,
                                  // isApproved: true,
                                  deviceInfo: this.state.deviceInfo,
                                  cmpId: 48,
                                },
                                {
                                  headers: {
                                    Authorization: token,
                                  },
                                }
                              );

                              if (this.state.key?.isVerified === 'false') {
                                this.setState({ ...this.state, modal: true });
                              } else {
                                this.setState((prevState) => ({
                                  modal_standard: !prevState.modal_standard,
                                }));
                                this.removeBodyCss();
                                const response = await GKApi.get(
                                  `/users/change-request/list?type=unbind&pageNumber=1&pageSize=1000`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 47,
                                    },
                                  }
                                );
                                response.data.data.forEach((item) => {
                                  item['authDiff'] =
                                    this.state.compAuthState - item.authStat;
                                  this.state.unbindReq.push(item);
                                });

                                this.setState({
                                  unbindReq: response.data.data,
                                });
                              }
                            }}
                          >
                            Accept
                          </Button>
                        </div>

                        {this.state.modal === true ? (
                          <SweetAlert
                            title="Do you want to verify this user as well?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={async () => {
                              const token = getToken();
                              var statusData =
                                this.state.key?.isVerified === 'true'
                                  ? false
                                  : true;
                              await GKApi.put(
                                `/users/verify`,
                                {
                                  userId: this.state.key?.userId,
                                  approve: statusData,
                                },
                                {
                                  headers: {
                                    Authorization: token,
                                  },
                                }
                              );
                              this.setState({
                                success_confirm: true,
                                modal: false,
                                dynamic_title: 'Verified!',
                                dynamic_description:
                                  'This user has been verified.',
                              });
                            }}
                            onCancel={() => {
                              this.setState({
                                modal: false,
                              });
                              this.setState((prevState) => ({
                                modal_standard: !prevState.modal_standard,
                              }));
                              this.removeBodyCss();
                            }}
                          ></SweetAlert>
                        ) : null}
                      </ModalFooter>
                    ) : (
                      ''
                    )}
                  </Modal>
                ) : (
                  <Modal
                    size="lg"
                    isOpen={this.state.modal_standard}
                    toggle={this.tog_standard}
                  >
                    <ModalHeader
                      toggle={() => this.setState({ modal_standard: false })}
                    >
                      Request Details
                    </ModalHeader>
                    <ModalBody>
                      <div className="d-flex my-3">
                        <Col xs={2}></Col>
                        <Col xs={5} style={{ textAlign: 'center' }}>
                          <h4>Current Information</h4>
                        </Col>
                        <Col xs={5} style={{ textAlign: 'center' }}>
                          <h4>Updated Information</h4>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>District name</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentDistrictName === null
                                    ? 'Not Available'
                                    : this.state.key?.currentDistrictName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedDistrictName === null
                                    ? this.state.key?.currentDistrictName
                                    : this.state.key?.updatedDistrictName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6 style={{ alignItems: 'center' }}>
                                Thana Name{' '}
                              </h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentThanaName === null
                                    ? 'Not Available'
                                    : this.state.key?.currentThanaName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedThanaName === null
                                    ? this.state.key?.currentThanaName
                                    : this.state.key?.updatedThanaName
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Thana Code</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentThanaCode === null
                                    ? 'Not Available'
                                    : this.state.key?.currentThanaCode
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedThanaCode === null
                                    ? this.state.key?.currentThanaCode
                                    : this.state.key?.updatedThanaCode
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Postal Code</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentPostalCode === null
                                    ? 'Not Available'
                                    : this.state.key?.currentPostalCode
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedPostalCode === null
                                    ? this.state.key?.currentPostalCode
                                    : this.state.key?.updatedPostalCode
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Address</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentAddress === null
                                    ? 'Not Available'
                                    : this.state.key?.currentAddress
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedAddress === null
                                    ? this.state.key?.currentAddress
                                    : this.state.key?.updatedAddress
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Additional Details</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.currentAdditionalDetails ===
                                  null
                                    ? 'Not Available'
                                    : this.state.key?.currentAdditionalDetails
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.updatedAdditionalDetails ===
                                  null
                                    ? 'Not Available'
                                    : this.state.key?.updatedAdditionalDetails
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Name</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.name === null
                                    ? 'Not Available'
                                    : this.state.key?.name
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="d-flex gap-1 mb-3">
                        <Col xs={12}>
                          <Row className="d-flex">
                            <Col xs={2}>
                              <h6>Phone</h6>
                            </Col>
                            <Col xs={5}>
                              <Input
                                defaultValue={
                                  this.state.key?.phone === null
                                    ? 'Not Available'
                                    : this.state.key?.phone
                                }
                                // placeholder="Please input the value for Daily Gold Price"
                                id="gold-price-input"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </ModalBody>
                    {this.state.key?.authDiff === 1 ||
                    this.state.key?.authDiff === 5 ||
                    this.state.key?.authDiff === 6 ? (
                      <ModalFooter>
                        <Button
                          type="button"
                          color="light"
                          className="waves-effect"
                          onClick={async (event) => {
                            event.preventDefault();
                            this.setState({ modal_standard12: true });
                          }}
                        >
                          Reject
                        </Button>

                        {/*
                         * @desc modal for REJECTING request for the CHECKER AND AUTHORIZER STARTS here
                         */}
                        <Modal
                          isOpen={this.state.modal_standard12}
                          toggle={this.tog_standard12}
                        >
                          <ModalHeader
                            toggle={() =>
                              this.setState({ modal_standard12: false })
                            }
                          >
                            Reject user request
                          </ModalHeader>

                          <ModalBody>
                            {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                            <Col xl={12}>
                              <Row className="mb-3 mx-2">
                                Please confirm to reject user request
                              </Row>
                              <Row className="mb-3 mx-2">
                                <Dropdown
                                  isOpen={this.state.reasonMenu}
                                  toggle={(event) =>
                                    this.setState({
                                      reasonMenu: !this.state.reasonMenu,
                                    })
                                  }
                                >
                                  <DropdownToggle
                                    className="px-5 py-3"
                                    color="light"
                                  >
                                    {this.state.reason
                                      ? capitalizeFirstLetter(this.state.reason)
                                      : 'Select a reason for rejecting the request'}{' '}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={(event) => {
                                        this.setState({
                                          reason:
                                            'as customer could not be reached over call',
                                        });
                                      }}
                                    >
                                      Request was rejected as customer could not
                                      be reached over call.
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) => {
                                        this.setState({
                                          reason:
                                            'due to inconsistent information',
                                        });
                                      }}
                                    >
                                      Request was rejected due to inconsistent
                                      information.
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) => {
                                        this.setState({
                                          reason: 'as per customer’s request',
                                        });
                                      }}
                                    >
                                      Request was rejected as per customer’s
                                      request.
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </Row>
                            </Col>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              type="button"
                              onClick={this.tog_standard12}
                              color="light"
                              className="waves-effect"
                            >
                              Cancel
                            </Button>

                            <Button
                              type="submit"
                              color="primary"
                              className="waves-effect waves-light"
                              //submit refer data for disbursement
                              onClick={async (event) => {
                                event.preventDefault();
                                const token = getToken();

                                await GKApi.put(
                                  `users/change-request/reject`,
                                  {
                                    type:
                                      this.state.reqType === 'profile'
                                        ? 'profile'
                                        : this.state.reqType === 'bank'
                                        ? 'bank'
                                        : this.state.reqType === 'bkash'
                                        ? 'bkash'
                                        : 'address',
                                    id: this.state.key?.id,
                                    reason: this.state.reason,
                                    cmpId: 48,
                                    deviceInfo: this.state.deviceInfo,
                                  },
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );
                                this.setState((prevState) => ({
                                  modal_standard: !prevState.modal_standard,
                                }));
                                this.removeBodyCss();
                                const response = await GKApi.get(
                                  `/users/change-request/list?type=address&pageNumber=1&pageSize=1000`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 47,
                                    },
                                  }
                                );
                                response.data.data.forEach((item) => {
                                  item['authDiff'] =
                                    this.state.compAuthState - item.authStat;
                                  this.state.addressReq.push(item);
                                });

                                this.setState({
                                  addressReq: response.data.data,
                                });
                              }}
                            >
                              Reject
                            </Button>
                            {this.state.custom_div1 ? (
                              <SweetAlert
                                title="Request successfully submitted for review!!"
                                timeout={2500}
                                style={{
                                  top: '0px',
                                  right: '0px',
                                }}
                                showCloseButton={false}
                                showConfirm={false}
                                success
                                onConfirm={() =>
                                  this.setState({ custom_div1: false })
                                }
                              ></SweetAlert>
                            ) : null}
                          </ModalFooter>
                        </Modal>
                        <Button
                          type="button"
                          color="primary"
                          className="waves-effect waves-light"
                          onClick={async (event) => {
                            event.preventDefault();
                            this.setState({ modal_standard13: true });
                          }}
                        >
                          Accept
                        </Button>

                        {/*
                         * @desc modal for Approving request for the CHECKER AND AUTHORIZER STARTS here
                         */}
                        <Modal
                          isOpen={this.state.modal_standard13}
                          toggle={this.tog_standard13}
                        >
                          <ModalHeader
                            toggle={() =>
                              this.setState({ modal_standard13: false })
                            }
                          >
                            Accept user request
                          </ModalHeader>

                          <ModalBody>
                            {/* <h5>{`User Details for ${propsData.name}`}</h5> */}
                            <Col xl={12}>
                              <Row className="mb-3 mx-2">
                                Please confirm to Accept user request
                              </Row>
                            </Col>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              type="button"
                              onClick={this.tog_standard13}
                              color="light"
                              className="waves-effect"
                            >
                              Cancel
                            </Button>

                            <Button
                              type="submit"
                              color="primary"
                              className="waves-effect waves-light"
                              //submit refer data for disbursement
                              onClick={async (event) => {
                                event.preventDefault();
                                const token = getToken();

                                await GKApi.put(
                                  `users/change-request/approve`,
                                  {
                                    type:
                                      this.state.reqType === 'profile'
                                        ? 'profile'
                                        : this.state.reqType === 'bank'
                                        ? 'bank'
                                        : this.state.reqType === 'bkash'
                                        ? 'bkash'
                                        : 'address',
                                    id: this.state.key?.id,
                                    // isApproved: true,
                                    cmpId: 48,
                                    deviceInfo: this.state.deviceInfo,
                                  },
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );

                                if (this.state.key?.isVerified === 'false') {
                                  this.setState({ ...this.state, modal: true });
                                } else {
                                  this.setState((prevState) => ({
                                    modal_standard: !prevState.modal_standard,
                                  }));
                                  this.removeBodyCss();
                                  const response = await GKApi.get(
                                    `/users/change-request/list?type=address&pageNumber=1&pageSize=1000`,
                                    {
                                      headers: {
                                        Authorization: token,
                                        cmpId: 47,
                                      },
                                    }
                                  );
                                  response.data.data.forEach((item) => {
                                    item['authDiff'] =
                                      this.state.compAuthState - item.authStat;
                                    this.state.addressReq.push(item);
                                  });

                                  this.setState({
                                    addressReq: response.data.data,
                                  });
                                }
                              }}
                            >
                              Approve
                            </Button>
                            {this.state.custom_div1 ? (
                              <SweetAlert
                                title="Request successfully submitted for review!!"
                                timeout={2500}
                                style={{
                                  top: '0px',
                                  right: '0px',
                                }}
                                showCloseButton={false}
                                showConfirm={false}
                                success
                                onConfirm={() =>
                                  this.setState({ custom_div1: false })
                                }
                              ></SweetAlert>
                            ) : null}
                          </ModalFooter>
                        </Modal>
                      </ModalFooter>
                    ) : (
                      ''
                    )}
                  </Modal>
                )}
              </div>
            </div>
          ) : (
            <h5>Sorry you don't have permission to view this</h5>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default UserRequests;

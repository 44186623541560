import moment from 'moment';
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getToken, GKApi } from '../../../components/config';

class DailyBuySell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Buy',
          data: [],
        },
        {
          name: 'Sell',
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
            },
            export: {
              csv: {
                filename: 'Daily SF and RC Revenue',
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: 'Daily SF and RC Revenue',
              },
              png: {
                filename: 'Daily SF and RC Revenue',
              },
            },
          },
        },

        colors: ['#77B6EA', '#34c38f'],
        dataLabels: {
          enabled: true,
          style: { color: '#ffff' },
          background: {
            //enabled: true,
            foreColor: '#FFFFFF',
            padding: 4,
            borderRadius: 6,
            borderWidth: 2,
            borderColor: '#fff',
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45,
            },
          },
        },
        fill: {
          opacity: 1,
        },
        stroke: { width: [3, 3], curve: 'smooth' },
        title: {
          text: 'Daily Buy Sell',
          align: 'left',
          style: { fontWeight: 'normal', fill: '#373d3f', opacity: 1 },
        },
        grid: {
          row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.2 },
          borderColor: '#e7e7e7',
        },
        markers: { style: 'inverted', size: 2, color: '#fff' },
        xaxis: {
          categories: [],

          title: { text: 'Hour' },
        },
        yaxis: { title: { text: 'Buy Sell' }, min: 5, max: 40 },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: true,
          offsetY: -25,
          offsetX: -5,
          itemMargin: {
            horizontal: 0,
            vertical: 0,
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
          },
        ],
      },
    };
  }

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();
    const buyData = [];
    const sellData = [];
    const hourLabels = [];
    // eslint-disable-next-line
    Number.prototype.toFixedDown = function (digits) {
      var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
        m = this.toString().match(re);
      return m ? parseFloat(m[1]) : this.valueOf();}

    const response = await GKApi.get('/finance/buy-sell', {
      headers: {
        Authorization: token,
        cmpId: 2,
      },
    });

    for (const obj of response.data.data) {
      buyData.push((obj.buy_quantity).toFixedDown(4));
      sellData.push((obj.sell_quantity).toFixedDown(4));
      hourLabels.push(moment(obj.hour, ['HH']).format('hh A'));
    }

    this.setState({
      series: [
        {
          name: 'Buy',
          data: buyData,
        },
        {
          name: 'Sell',
          data: sellData,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
            },
            export: {
              csv: {
                filename: 'Daily SF and RC Revenue',
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: 'Daily SF and RC Revenue',
              },
              png: {
                filename: 'Daily SF and RC Revenue',
              },
            },
          },
        },

        colors: ['#77B6EA', '#34c38f'],
        dataLabels: {
          enabled: true,
          style: { color: '#ffff' },
          background: {
            //enabled: true,
            foreColor: '#FFFFFF',
            padding: 4,
            borderRadius: 6,
            borderWidth: 2,
            borderColor: '#fff',
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45,
            },
          },
        },
        fill: {
          opacity: 1,
        },
        stroke: { width: [3, 3], curve: 'smooth' },
        title: {
          text: 'Daily Buy Sell',
          align: 'left',
          style: { fontWeight: 'normal', fill: '#373d3f', opacity: 1 },
        },
        grid: {
          row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.2 },
          borderColor: '#e7e7e7',
        },
        markers: { style: 'inverted', size: 2, color: '#fff' },
        xaxis: {
          categories: hourLabels,
          title: { text: 'Hour' },
        },
        yaxis: { title: { text: 'gm' } },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: !0,
          offsetY: -25,
          offsetX: -5,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return (val).toFixedDown(4) + ' gm';
            },
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
          },
        ],
      },
    });
  }
  render() {
    return (
      <React.Fragment>
        <div id="line-chart">
          {this.state.series[0].data === [] &&
          this.state.series[1].data === [] &&
          this.state.options.xaxis.categories === [] ? (
            'Data not available'
          ) : (
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="line"
              height="380"
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default DailyBuySell;

import React, { Component } from 'react';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { roundToTwo } from '../../../components/config';

// Table data

class CollectGoldReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Finance', link: '#' },
        { title: 'Finance-Dashboard', link: '#' },
        { title: 'Collect Gold Report', link: '#' },
      ],
      visible: false,
      modal_standard: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  render() {
    const columns = [
      {
        field: ['SL No'],
        title: 'SL No',
      },
      {
        field: ['Transaction Reference No'],
        title: 'Transaction Reference No',
      },
      {
        field: ['Collection ID'],
        title: 'Collection ID',
      },
      {
        field: ['Collection Date'],
        title: 'Collection Date',
      },
      {
        field: ['Collection Time'],
        title: 'Collection Time',
      },
      {
        field: ['Customer Name'],
        title: 'Customer Name',
      },
      {
        field: ['Customer NID'],
        title: 'Customer NID',
      },
      {
        field: ['Customer MSISDN'],
        title: 'Customer MSISDN',
      },

      {
        field: ['Customer Address'],
        title: 'Customer Address',
        render: (row) => (
          <div>
            {row['Customer Address'] === ' '
              ? 'Not available'
              : row['Customer Address']}
          </div>
        ),
      },
      {
        field: ['Customer Additional Details'],
        title: 'Customer Additional Details',
        render: (row) => (
          <div>
            {row['Customer Additional Details'] === ' '
              ? 'Not available'
              : row['Customer Additional Details']}
          </div>
        ),
      },
      {
        field: ['Customer Thana'],
        title: 'Customer Thana',
        render: (row) => (
          <div>
            {row['Customer Thana'] === ' '
              ? 'Not available'
              : row['Customer Thana']}
          </div>
        ),
      },
      {
        field: ['Customer District'],
        title: 'Customer District',
        render: (row) => (
          <div>
            {row['Customer District'] === ' '
              ? 'Not available'
              : row['Customer District']}
          </div>
        ),
      },
      {
        field: ['Customer Division'],
        title: 'Customer Division',
        render: (row) => (
          <div>
            {row['Customer Division'] === ' '
              ? 'Not available'
              : row['Customer Division']}
          </div>
        ),
      },
      {
        field: ['Customer Postal Code'],
        title: 'Customer Postal Code',
        render: (row) => (
          <div>
            {row['Customer Postal Code'] === ' '
              ? 'Not available'
              : row['Customer Postal Code']}
          </div>
        ),
      },

      {
        field: ['Recipient Name'],
        title: 'Recipient Name',
      },

      {
        field: ['Recipient MSISDN'],
        title: 'Recipient MSISDN',
      },

      {
        field: ['Recipient Address'],
        title: 'Recipient Address',
        render: (row) => (
          <div>
            {row['Recipient Address'] === ' '
              ? 'Not available'
              : row['Recipient Address']}
          </div>
        ),
      },
      {
        field: ['Recipient Additional Details'],
        title: 'Recipient Additional Details',
        render: (row) => (
          <div>
            {row['Recipient Additional Details'] === ' '
              ? 'Not available'
              : row['Recipient Additional Details']}
          </div>
        ),
      },
      {
        field: ['Recipient Thana'],
        title: 'Recipient Thana',
        render: (row) => (
          <div>
            {row['Recipient Thana'] === ' '
              ? 'Not available'
              : row['Recipient Thana']}
          </div>
        ),
      },
      {
        field: ['Recipient District'],
        title: 'Recipient District',
        render: (row) => (
          <div>
            {row['Recipient District'] === ' '
              ? 'Not available'
              : row['Recipient District']}
          </div>
        ),
      },
      {
        field: ['Recipient Division'],
        title: 'Recipient Division',
        render: (row) => (
          <div>
            {row['Recipient Division'] === ' '
              ? 'Not available'
              : row['Recipient Division']}
          </div>
        ),
      },
      {
        field: ['Recipient Postal Code'],
        title: 'Recipient Postal Code',
        render: (row) => (
          <div>
            {row['Recipient Postal Code'] === ' '
              ? 'Not available'
              : row['Recipient Postal Code']}
          </div>
        ),
      },

      {
        field: ['SKU(Coin 2gm) Quantity'],
        title: 'SKU(Coin 2gm) Quantity',
        render: (row) => <div>{roundToTwo(row['SKU(Coin 2gm) Quantity'])}</div>,
      },
      {
        field: ['SKU(Coin 2gm) Weight'],
        title: 'SKU(Coin 2gm) Weight',
        render: (row) => <div>{roundToTwo(row['SKU(Coin 2gm) Weight'])}</div>,
      },
      {
        field: ['Delivery Fee Rate (Coin 2gm)'],
        title: 'Delivery Fee Rate (Coin 2gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Rate (Coin 2gm)'])}</div>
        ),
      },
      {
        field: ['Delivery Fee Amount (Coin 2gm)'],
        title: 'Delivery Fee Amount (Coin 2gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Amount (Coin 2gm)'])}</div>
        ),
      },
      {
        field: ['SKU(Coin 4gm) Quantity'],
        title: 'SKU(Coin 4gm) Quantity',
        render: (row) => <div>{roundToTwo(row['SKU(Coin 4gm) Quantity'])}</div>,
      },
      {
        field: ['SKU(Coin 4gm) Weight'],
        title: 'SKU(Coin 4gm) Weight',
        render: (row) => <div>{roundToTwo(row['SKU(Coin 4gm) Weight'])}</div>,
      },
      {
        field: ['Delivery Fee Rate (Coin 4gm)'],
        title: 'Delivery Fee Rate (Coin 4gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Rate (Coin 4gm)'])}</div>
        ),
      },
      {
        field: ['Delivery Fee Amount (Coin 4gm)'],
        title: 'Delivery Fee Amount (Coin 4gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Amount (Coin 4gm)'])}</div>
        ),
      },

      {
        field: ['SKU(Bar 1gm) Quantity'],
        title: 'SKU(Bar 1gm) Quantity',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 1gm) Quantity'])}</div>,
      },
      {
        field: ['SKU(Bar 1gm) Weight'],
        title: 'SKU(Bar 1gm) Weight',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 1gm) Weight'])}</div>,
      },
      {
        field: ['Delivery Fee Rate (Bar 1gm)'],
        title: 'Delivery Fee Rate (Bar 1gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Rate (Bar 1gm)'])}</div>
        ),
      },
      {
        field: ['Delivery Fee Amount (Bar 1gm)'],
        title: 'Delivery Fee Amount (Bar 1gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Amount (Bar 1gm)'])}</div>
        ),
      },

      {
        field: ['SKU(Bar 5gm) Quantity'],
        title: 'SKU(Bar 5gm) Quantity',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 5gm) Quantity'])}</div>,
      },

      {
        field: ['SKU(Bar 5gm) Weight'],
        title: 'SKU(Bar 5gm) Weight',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 5gm) Weight'])}</div>,
      },
      {
        field: ['Delivery Fee Rate (Bar 5gm)'],
        title: 'Delivery Fee Rate (Bar 5gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Rate (Bar 5gm)'])}</div>
        ),
      },
      {
        field: ['Delivery Fee Amount (Bar 5gm)'],
        title: 'Delivery Fee Amount (Bar 5gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Amount (Bar 5gm)'])}</div>
        ),
      },
      {
        field: ['SKU(Bar 10gm) Quantity'],
        title: 'SKU(Bar 10gm) Quantity',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 10gm) Quantity'])}</div>,
      },
      {
        field: ['SKU(Bar 10gm) Weight'],
        title: 'SKU(Bar 10gm) Weight',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 10gm) Weight'])}</div>,
      },
      {
        field: ['Delivery Fee Rate (Bar 10gm)'],
        title: 'Delivery Fee Rate (Bar 10gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Rate (Bar 10gm)'])}</div>
        ),
      },
      {
        field: ['Delivery Fee Amount (Bar 10gm)'],
        title: 'Delivery Fee Amount (Bar 10gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Amount (Bar 10gm)'])}</div>
        ),
      },
      {
        field: ['Total SKU Quantity'],
        title: 'Total SKU Quantity',
        render: (row) => <div>{roundToTwo(row['Total SKU Quantity'])}</div>,
      },
      {
        field: ['Total Gold Weight (Gm)'],
        title: 'Total Gold Weight (Gm)',
        render: (row) => <div>{roundToTwo(row['Total Gold Weight (Gm)'])}</div>,
      },
      {
        field: ['Total Delivery Fee'],
        title: 'Total Delivery Fee',
        render: (row) => <div>{roundToTwo(row['Total Delivery Fee'])}</div>,
      },
      {
        field: ['Per Unit Price (Live Market Price)'],
        title: 'Per Unit Price (Live Market Price)',
        render: (row) => (
          <div>{roundToTwo(row['Per Unit Price (Live Market Price)'])}</div>
        ),
      },
      {
        field: ['Total Collect Value'],
        title: 'Total Collect Value',
        render: (row) => <div>{roundToTwo(row['Total Collect Value'])}</div>,
      },
      {
        field: ['Service Fee Rate (Including VAT)'],
        title: 'Service Fee Rate (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Service Fee Rate (Including VAT)'])}</div>
        ),
      },
      {
        field: ['Service Fee (Including VAT)'],
        title: 'Service Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Service Fee (Including VAT)'])}</div>
        ),
      },
      {
        field: ['Promo Amount'],
        title: 'Promo Amount',
        render: (row) => <div>{roundToTwo(row['Promo Amount'])}</div>,
      },
      {
        field: ['Discount Amount'],
        title: 'Discount Amount',
        render: (row) => <div>{roundToTwo(row['Discount Amount'])}</div>,
      },
      {
        field: ['Other Additions'],
        title: 'Other Additions',
        render: (row) => <div>{roundToTwo(row['Other Additions'])}</div>,
      },
      {
        field: ['Net Service Fee (Including VAT)'],
        title: 'Net Service Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Net Service Fee (Including VAT)'])}</div>
        ),
      },
      {
        field: ['VAT Rate (On Service Fee)'],
        title: 'VAT Rate (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['VAT Rate (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['VAT Amount (On Service Fee)'],
        title: 'VAT Amount (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['VAT Amount (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Total Service Fee (Excluding VAT)'],
        title: 'Total Service Fee (Excluding VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Total Service Fee (Excluding VAT)'])}</div>
        ),
      },
      {
        field: ['Total Collection Amount'],
        title: 'Total Collection Amount',
        render: (row) => (
          <div>{roundToTwo(row['Total Collection Amount'])}</div>
        ),
      },
      {
        field: ['PGW Settlement Fee Rate'],
        title: 'PGW Settlement Fee Rate',
        render: (row) => (
          <div>{roundToTwo(row['PGW Settlement Fee Rate'])}</div>
        ),
      },
      {
        field: ['PGW Settlement Fee Amount'],
        title: 'PGW Settlement Fee Amount',
        render: (row) => (
          <div>{roundToTwo(row['PGW Settlement Fee Amount'])}</div>
        ),
      },
      {
        field: ['Net Collection Amount'],
        title: 'Net Collection Amount',
        render: (row) => <div>{roundToTwo(row['Net Collection Amount'])}</div>,
      },
      {
        field: ['Payment Medium'],
        title: 'Payment Medium',
        render: (row) => (
          <div>
            {row['Payment Medium'] ? row['Payment Medium'] : 'Not Available'}
          </div>
        ),
      },
      {
        field: ['MFS/Bank Transaction ID'],
        title: 'MFS/Bank Transaction ID',
        render: (row) => (
          <div>
            {row['MFS/Bank Transaction ID']
              ? row['MFS/Bank Transaction ID']
              : 'Not Available'}
          </div>
        ),
      },
      {
        title: 'MFS/Bank Account',
        render: (row) => {
          return <div>{(row['MFS/Bank Account No'] || 'Not Available')}</div>
        }
      },
    ];

    const summaryColumns = [
      {
        field: ['Date'],
        title: 'Date',
      },
      {
        field: ['Number of Collect Transactions'],
        title: 'Number of Collect Transactions',
        render: (row) => (
          <div>{roundToTwo(row['Number of Collect Transactions'])}</div>
        ),
      },

      {
        field: ['SKU(Coin 2gm) Quantity'],
        title: 'SKU(Coin 2gm) Quantity',
        render: (row) => <div>{roundToTwo(row['SKU(Coin 2gm) Quantity'])}</div>,
      },

      {
        field: ['SKU(Coin 2gm) Weight'],
        title: 'SKU(Coin 2gm) Weight',
        render: (row) => <div>{roundToTwo(row['SKU(Coin 2gm) Weight'])}</div>,
      },

      {
        field: ['Delivery Fee Amount (Coin 2gm)'],
        title: 'Delivery Fee Amount (Coin 2gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Amount (Coin 2gm)'])}</div>
        ),
      },
      {
        field: ['SKU(Coin 4gm) Quantity'],
        title: 'SKU(Coin 4gm) Quantity',
        render: (row) => <div>{roundToTwo(row['SKU(Coin 4gm) Quantity'])}</div>,
      },
      {
        field: ['SKU(Coin 4gm) Weight'],
        title: 'SKU(Coin 4gm) Weight',
        render: (row) => <div>{roundToTwo(row['SKU(Coin 4gm) Weight'])}</div>,
      },
      {
        field: ['Delivery Fee Amount (Coin 4gm)'],
        title: 'Delivery Fee Amount (Coin 4gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Amount (Coin 4gm)'])}</div>
        ),
      },
      {
        field: ['SKU(Bar 1gm) Quantity'],
        title: 'SKU(Bar 1gm) Quantity',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 1gm) Quantity'])}</div>,
      },
      {
        field: ['SKU(Bar 1gm) Weight'],
        title: 'SKU(Bar 1gm) Weight',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 1gm) Weight'])}</div>,
      },
      {
        field: ['Delivery Fee Amount (Bar 1gm)'],
        title: 'Delivery Fee Amount (Bar 1gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Amount (Bar 1gm)'])}</div>
        ),
      },
      {
        field: ['SKU(Bar 5gm) Quantity'],
        title: 'SKU(Bar 5gm) Quantity',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 5gm) Quantity'])}</div>,
      },
      {
        field: ['SKU(Bar 5gm) Weight'],
        title: 'SKU(Bar 5gm) Weight',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 5gm) Weight'])}</div>,
      },
      {
        field: ['Delivery Fee Amount (Bar 5gm)'],
        title: 'Delivery Fee Amount (Bar 5gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Amount (Bar 5gm)'])}</div>
        ),
      },
      {
        field: ['SKU(Bar 10gm) Quantity'],
        title: 'SKU(Bar 10gm) Quantity',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 10gm) Quantity'])}</div>,
      },
      {
        field: ['SKU(Bar 10gm) Weight'],
        title: 'SKU(Bar 10gm) Weight',
        render: (row) => <div>{roundToTwo(row['SKU(Bar 10gm) Weight'])}</div>,
      },
      {
        field: ['Delivery Fee Amount (Bar 10gm)'],
        title: 'Delivery Fee Amount (Bar 10gm)',
        render: (row) => (
          <div>{roundToTwo(row['Delivery Fee Amount (Bar 10gm)'])}</div>
        ),
      },
      {
        field: ['Total SKU Quantity'],
        title: 'Total SKU Quantity',
        render: (row) => <div>{roundToTwo(row['Total SKU Quantity'])}</div>,
      },
      {
        field: ['Total Gold Weight (Gm)'],
        title: 'Total Gold Weight (Gm)',
        render: (row) => <div>{roundToTwo(row['Total Gold Weight (Gm)'])}</div>,
      },
      {
        field: ['Total Delivery Fee'],
        title: 'Total Delivery Fee',
        render: (row) => <div>{roundToTwo(row['Total Delivery Fee'])}</div>,
      },
      {
        field: ['Total Collect Value'],
        title: 'Total Collect Value',
        render: (row) => <div>{roundToTwo(row['Total Collect Value'])}</div>,
      },
      {
        field: ['Service Fee (Including VAT)'],
        title: 'Service Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Service Fee (Including VAT)'])}</div>
        ),
      },
      {
        field: ['Promo Amount'],
        title: 'Promo Amount',
        render: (row) => <div>{roundToTwo(row['Promo Amount'])}</div>,
      },
      {
        field: ['Discount Amount'],
        title: 'Discount Amount',
        render: (row) => <div>{roundToTwo(row['Discount Amount'])}</div>,
      },
      {
        field: ['Other Additions'],
        title: 'Other Additions',
        render: (row) => <div>{roundToTwo(row['Other Additions'])}</div>,
      },
      {
        field: ['Net Service Fee (Including VAT)'],
        title: 'Net Service Fee (Including VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Net Service Fee (Including VAT)'])}</div>
        ),
      },
      {
        field: ['VAT Amount (On Service Fee)'],
        title: 'VAT Amount (On Service Fee)',
        render: (row) => (
          <div>{roundToTwo(row['VAT Amount (On Service Fee)'])}</div>
        ),
      },
      {
        field: ['Total Service Fee (Excluding VAT)'],
        title: 'Total Service Fee (Excluding VAT)',
        render: (row) => (
          <div>{roundToTwo(row['Total Service Fee (Excluding VAT)'])}</div>
        ),
      },
      {
        field: ['Total Collection Amount'],
        title: 'Total Collection Amount',
        render: (row) => (
          <div>{roundToTwo(row['Total Collection Amount'])}</div>
        ),
      },
      {
        field: ['PGW Settlement Fee Amount'],
        title: 'PGW Settlement Fee Amount',
        render: (row) => (
          <div>{roundToTwo(row['PGW Settlement Fee Amount'])}</div>
        ),
      },
      {
        field: ['Net Collection Amount'],
        title: 'Net Collection Amount',
        render: (row) => <div>{roundToTwo(row['Net Collection Amount'])}</div>,
      },
    ];


    const downloadExcel = () => {
      const newData = this.props.location.state.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'reports');
      //Buffer
      // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      
        this.props.location.state.summary === false
          ? XLSX.writeFile(workBook, 'Collect Gold Report.xlsx')
          : XLSX.writeFile(workBook, 'Collect Gold Report Summarized.xlsx');
      
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Finance"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <MaterialTable
              title={
                this.props.location.state.summary === false
                  ? 'Collect Gold Report'
                  : 'Collect Gold Report Summarized'
              }
              columns={
                this.props.location.state.summary === false
                  ? columns
                  : summaryColumns
              }
              data={this.props.location.state.data}
              actions={[
                {
                  icon: () => <Button>Export All</Button>, // you can pass icon too
                  tooltip: 'Export to Excel',
                  onClick: () => downloadExcel(),
                  isFreeAction: true,
                },
              ]}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                },

                exportButton: { csv: true, pdf: false },
                exportFileName:
                  this.props.location.state.summary === false
                    ? 'Collect Gold Report'
                    : 'Collect Gold Report Summarized',
                pageSize: 20,
                columnsButton: true,
              }}
              localization={{
                toolbar: {
                  exportCSVName: 'Export as CSV',
                },
              }}
            />
            <Modal
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_standard: false })}
              >
                User Edit
              </ModalHeader>
              <ModalBody>
                {/* <h5>{`User Details for ${propsData.name}`}</h5> */}

                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Name
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.name}
                      id="example-name-input"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Position
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.position}
                      id="example-position-input"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="example-name-input"
                    className="col-md-2 col-form-label"
                  >
                    Salary
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      // defaultValue={rowData.salary}
                      id="example-salary-input"
                    />
                  </Col>
                </Row>
                <Button className="float-end">Update</Button>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={this.tog_standard}
                  color="light"
                  className="waves-effect"
                >
                  Close
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                >
                  Save changes
                </Button>
              </ModalFooter>
            </Modal>
            ;
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CollectGoldReport;

import React, { Component } from 'react';
import { Row, Col, Input, Button, Card, CardBody } from 'reactstrap';
import { getGroupName, getToken, GKApi } from '../../components/config';
import moment from 'moment';
import MaterialTable from 'material-table';

class CSRVIEW extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCSR: false,
      userData: [],
      searchKey: '',
      searchLoading: false,
      clearLoading: false,
    };
  }
  handleSearchKeyChange = (event) => {
    this.setState({ searchKey: event.target.value });
  };

  async componentDidMount() {
    let groupName = getGroupName();
    if (groupName.length > 0) {
      groupName = groupName.split(',');
    }
    let gN = new Set();
    groupName.forEach((i) => {
      let name = i.split('_')[0];
      gN.add(name);
      if (name === 'CSR') {
        this.setState({
          isCSR: true,
        });
      }
    });
    let groupNames = [...gN];

    this.setState({ groupName: groupNames });
  }

  render() {
    const columns = [
      {
        field: 'phone',
        title: 'Phone',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'name',
        title: 'Name',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'NID',
        title: 'NID',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'email',
        title: 'Email',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'gender',
        title: 'Gender',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'dateOfBirth',
        title: 'Date of Birth',
        headerStyle: {
          textAlign: 'center',
        },

        //conditional data rendering

        render: (row) => (
          <div>
            {row.dateOfBirth
              ? moment(row.dateOfBirth).format('DD-MM-YYYY')
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'joiningDate',
        title: 'Joining Date',
        headerStyle: {
          textAlign: 'center',
        },

        //conditional data rendering

        render: (row) => (
          <div>
            {row.joiningDate
              ? moment(row.joiningDate, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'lastLoginTime',
        title: 'Last Log in',
        headerStyle: {
          textAlign: 'center',
        },

        //conditional data rendering

        render: (row) => (
          <div>
            {row.lastLoginTime
              ? moment(row.lastLoginTime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD-MM-YYYY hh:mm A'
                )
              : 'Not Available'}
          </div>
        ),
      },
      {
        field: 'deviceType',
        title: 'Device Type',
        headerStyle: {
          textAlign: 'center',
        },
        render: (row) => (
          <div>{row.deviceType ? row.deviceType : 'Not Available'}</div>
        ),
      },
      {
        field: 'referralCode',
        title: 'Referral Code',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'sourceReferralCode',
        title: 'Source Referral Code',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'sourceReferralType',
        title: 'Source Referral Type',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'maritalStatus',
        title: 'Marital Status',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'profession',
        title: 'Profession',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'activeStatus',
        title: 'Active Status',
        headerStyle: {
          textAlign: 'center',
        },

        //conditional data rendering, this render method is used for rendering custom data

        render: (row) => (
          <div>
            {row.activeStatus.charAt(0).toUpperCase() +
              row.activeStatus.slice(1).toLowerCase()}
          </div>
        ),
      },
      {
        field: 'profileStatus',
        title: 'Profile Status',
        headerStyle: {
          textAlign: 'center',
        },

        // conditional data rendering

        render: (row) => (
          <div>
            {row.profileStatus?.charAt(0).toUpperCase() +
              row.profileStatus?.slice(1).toLowerCase()}
          </div>
        ),
      },
      {
        field: 'nomineeName',
        title: 'Nominee Name',
        headerStyle: {
          textAlign: 'center',
        },
      },
    ];
    return (
      <div className="page-content">
        {this.state.isCSR === true ? (
          <div className="container-fluid">
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <div className="d-flex gap-3 align-items-center mx-3 my-3">
                      <h5>Search:</h5>
                      <Input
                        type="text"
                        value={this.state.searchKey}
                        id="example-name-input"
                        onChange={this.handleSearchKeyChange}
                      />
                      {this.state.searchKey !== '' && (
                        <div className="d-flex align-items-center gap-3">
                          <Button
                            onClick={async () => {
                              this.setState({ searchLoading: true });
                              const token = getToken();
                              const response = await GKApi.get(
                                `/users?pageNumber=1&pageSize=10&type=All&search=${this.state.searchKey.slice(
                                  2
                                )}`,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 43,
                                  },
                                }
                              );
                              this.setState(
                                {
                                  userData: response.data.data,
                                },
                                () => {}
                              );
                              if (response) {
                                this.setState({ searchLoading: false });
                              }
                            }}
                          >
                            {this.state.searchLoading ? 'Searching' : 'Search'}
                          </Button>
                          <Button
                            onClick={async () => {
                              // eslint-disable-next-line
                              this.state.searchKey = '';

                              this.setState(
                                {
                                  userData: [],
                                },
                                () => {}
                              );
                            }}
                          >
                            {this.state.clearLoading ? 'Clearing' : 'Clear'}
                          </Button>
                        </div>
                      )}
                    </div>
                    <p className="mx-3 my-2">* Phone Format: 8801xxxxxxxxx</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.state.userData.length > 0 && (
              <Row>
                <Col xl={12}>
                  <div>
                    <MaterialTable
                      title={`User Information`}
                      columns={columns}
                      data={this.state.userData}
                      options={{
                        search: false,
                        paging: false,
                        actionsCellStyle: { textAlign: 'right' },
                        //indicated the style for the data

                        cellStyle: {
                          borderRight: '1px solid #eee',
                          textAlign: 'left',
                        },

                        //style for the header

                        headerStyle: {
                          whiteSpace: 'nowrap',
                          textAlign: 'left',
                        },

                        pageSize: 10,
                        columnsButton: true, // enables column filtering
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </div>
        ) : (
          <h5>Sorry you don't have permission to view this</h5>
        )}
      </div>
    );
  }
}

export default CSRVIEW;

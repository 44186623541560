import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  CardText,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import TimePickerComponent from '../../../components/Common/TimePicker';
import { getToken, getUser, GKApi } from '../../../components/config';
import moment from 'moment';
import { deviceInfo } from '../../../helpers/device_info_helper';
class CampaignChannelDataModification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        // { title: 'Nazox', link: '/' },
        // { title: 'Marketing-Dashboard', link: '#' },
      ],
      activeTabJustify: this.props.campaignData.isNotification === 1 ? '2' : '3',
      selectedFiles: [],
      inboxTitle: '',
      inboxMessage: '',
      notificationTitle: '',
      notificationMessage: '',
      notificationLink: '',
      notificationLinkTypeMenu: false,
      activationDate: null,
      activationDateNotification: null,
      notificationLinkType: 1,
      showChangeBanner: false,
      bannerLinkTypeMenu: false,
      bannerLink: '',
      bannerLinkType: 1,
      deviceInfo: {},
      reason: '',
      rejectionModal: false,
      rejectionModalInbox: false,
      acceptModalNotification: false,
      acceptModalInbox: false,
      updateNotificationModal: false,
      updateInboxModal: false,
      canViewInboxData: 0,
      canEditInboxData: 0,
      canViewNotificationData: 0,
      canEditNotificationData: 0,
      canViewAppBannerData: 0,
      canEditAppBannerData: 0,
      canApproveChannelData: 0,
      canAuthChannelData: 0,
      canRejectChannelData: 0,
      bannerUpdateModal: false,
      userAuthState: 0,
      bannerInternalLinkList: [],
      campaignBannerInternalLinkTypeMenu: false,
      bannerS3Key: '',
    };
  }

  async componentDidMount() {
    this.setState({
      inboxTitle: this.props.campaignData.inboxTitle,
      inboxMessage: this.props.campaignData.inboxMsg,
      notificationLink: this.props.campaignData.notificationLink,
      notificationTitle: this.props.campaignData.notificationTitle,
      notificationLinkType: this.props.campaignData.notificationLinkType,
      notificationMessage: this.props.campaignData.notificationMsg,
      bannerUrl: this.props.campaignData.bannerUrl,
      bannerLink: this.props.campaignData.bannerLink,
      bannerLinkType: this.props.campaignData.bannerLinkType,
      bannerS3Key: this.props.campaignData.bannerS3Key,
    });
    const token = getToken();
    const internalLinkList = await GKApi.get('/marketing/banner/target-link', {
      headers: {
        Authorization: token,
      },
    });
    console.log(internalLinkList.data.data);
    this.setState({ bannerInternalLinkList: internalLinkList.data.data });

    // const groupId=getGroupId();
    const getPermission = await GKApi.get(`/role/permission?cmpId=143,161`, {
      headers: {
        Authorization: token,
      },
    });
    console.log('permissionData:', getPermission.data);
    const permissionData = {};

    // mapping permission to object instead of array
    getPermission.data.map((item) => (permissionData[item.cmpId] = item));
    this.setState({
      canEditInboxData: permissionData[161].canInsert,
      canEditNotificationData: permissionData[161].canInsert,
      canEditAppBannerData: permissionData[143].canAuth,
      canApproveChannelData: permissionData[161].canSubmit,
      canAuthChannelData: permissionData[161].canAuth,
      canRejectChannelData: permissionData[161].canReject,
      userAuthState: permissionData[161].authState,
    });
    const res = deviceInfo();
    this.setState({ deviceInfo: res }, () => {});
  }

  tog_AcceptNotification = () => {
    this.setState((prevState) => ({
      acceptModalNotification: !prevState.acceptModalNotification,
    }));
    this.removeBodyCss();
  };

  tog_AcceptInbox = () => {
    this.setState((prevState) => ({
      acceptModalInbox: !prevState.acceptModalInbox,
    }));
    this.removeBodyCss();
  };

  tog_UpdateNotification = () => {
    this.setState((prevState) => ({
      updateNotificationModal: !prevState.updateNotificationModal,
    }));
    this.removeBodyCss();
  };
  tog_UpdateInbox = () => {
    this.setState((prevState) => ({
      updateInboxModal: !prevState.updateInboxModal,
    }));
    this.removeBodyCss();
  };

  tog_Rejection = () => {
    this.setState((prevState) => ({
      rejectionModal: !prevState.rejectionModal,
    }));
    this.removeBodyCss();
  };

  tog_RejectionInbox = () => {
    this.setState((prevState) => ({
      rejectionModalInbox: !prevState.rejectionModalInbox,
    }));
    this.removeBodyCss();
  };

  tog_BannerUpdate = () => {
    this.setState((prevState) => ({
      bannerUpdateModal: !prevState.bannerUpdateModal,
    }));
    this.removeBodyCss();
  };
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  handleActivationDateChange = (e) => {
    this.setState({ activationDate: e });
  };

  handleActivationDateNotificationChange = (e) => {
    this.setState({ activationDateNotification: e });
  };

  handleInboxTitleChange = (e) => {
    this.setState({
      inboxTitle: e.target.value,
    });
  };
  handleReasonChange = (e) => {
    this.setState({
      reason: e.target.value,
    });
  };
  handleInboxMessageChange = (e) => {
    this.setState({
      inboxMessage: e.target.value,
    });
  };
  handleNotificationMessageChange = (e) => {
    this.setState({
      notificationMessage: e.target.value,
    });
  };

  handleNotificationTitleChange = (e) => {
    this.setState({
      notificationTitle: e.target.value,
    });
  };
  handleNotificationLinkChange = (e) => {
    this.setState({
      notificationLink: e.target.value,
    });
  };
  handleBannerLinkChange = (e) => {
    this.setState({
      bannerLink: e.target.value,
    });
  };

  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  //   for radio button
  onRadioChange = (e) => {
    this.setState({
      radio: e.currentTarget.value,
    });
  };
  onRadioChange1 = (e) => {
    this.setState({
      radio1: e.currentTarget.value,
    });
  };

  toggleCustomJustified = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState(
        {
          activeTabJustify: tab,
        },
        () => {}
      );
    }
  };

  render() {
    const campaignData = this.props.campaignData;
    const updatedInboxData = this.props.updatedInboxData;
    const updatedNotificationData = this.props.updatedNotificationData;
    const authDiffInbox = this.state.userAuthState - updatedInboxData.authLevel;
    const authDiffNotification =
      this.state.userAuthState - updatedNotificationData.authLevel;
    // console.log('testing gen com-------------------------',campaignData);
    return (
      <React.Fragment>
        <Card>
          <CardBody className="shadow-lg" style={{ height: 'auto' }}>
            <div>
              <Nav tabs className="nav-tabs-custom nav-justified">
                {campaignData.isNotification === 1 && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTabJustify === '2',
                      })}
                      onClick={() => {
                        this.toggleCustomJustified('2');
                      }}
                    >
                      <span className="d-none d-sm-block">
                        Push Notifications
                      </span>
                    </NavLink>
                  </NavItem>
                )}
                {campaignData.isInbox === 1 && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTabJustify === '3',
                      })}
                      onClick={() => {
                        this.toggleCustomJustified('3');
                      }}
                    >
                      <span className="d-none d-sm-block">Inbox</span>
                    </NavLink>
                  </NavItem>
                )}
              </Nav>

              <TabContent activeTab={this.state.activeTabJustify}>
                <TabPane tabId="2" className="p-3">
                  <Row>
                    <Col sm="12">
                      <CardText>
                        <div className="border border-1 rounded p-4">
                          {Object.keys(updatedNotificationData).length > 0 ? (
                            <div className="py-4">
                              <div className="pb-2">
                                <h5 className="card-title">Title</h5>
                                <Input
                                  type="text"
                                  value={
                                    updatedNotificationData.title
                                      ? updatedNotificationData.title
                                      : 'not available'
                                  }
                                  id="gold-price-input"
                                  onChange={(e) => {
                                    this.handleNotificationTitleChange(e);
                                  }}
                                  disabled
                                />
                              </div>
                              <div className="pb-2">
                                <h5 className="card-title">Description</h5>
                                <div>
                                  <TextField
                                    className="w-full"
                                    variant="outlined"
                                    type="text"
                                    multiline
                                    minRows={2}
                                    style={{
                                      width: '100%',
                                    }}
                                    value={updatedNotificationData.msg}
                                    onChange={(e) => {
                                      this.handleNotificationMessageChange(e);
                                    }}
                                    disabled
                                    // placeholder="Enter your comment here ..."
                                    // {...register('comment')}
                                  />
                                </div>
                              </div>
                              {/* <div className="pb-2">
                                <h5 className="card-title">Target Link</h5>
                                <Input
                                  type="text"
                                  value={updatedNotificationData.link}
                                  placeholder="Https:/goldkinen.com/referral"
                                  id="gold-price-input"
                                  onChange={(e) => {
                                    this.handleNotificationLinkChange(e);
                                  }}
                                  disabled
                                />
                              </div>
                              <div className="pb-2">
                                <Dropdown
                                  isOpen={this.state.notificationLinkTypeMenu}
                                  toggle={() =>
                                    this.setState({
                                      notificationLinkTypeMenu:
                                        !this.state.notificationLinkTypeMenu,
                                    })
                                  }
                                  className="mt-4 mt-sm-0"
                                >
                                  <DropdownToggle
                                    className="d-flex justify-content-between"
                                    color="light"
                                    caret
                                    style={{
                                      backgroundColor: '#fff',
                                      border: '1px solid #BDBDBD',
                                      borderRadius: '4px',
                                      width: '100%',
                                    }}
                                  >
                                    {updatedNotificationData.linkType === 1
                                      ? 'Internal'
                                      : 'External'}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={(event) =>
                                        this.setState({
                                          notificationLinkType: 1,
                                        })
                                      }
                                      disabled
                                    >
                                      Internal
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) =>
                                        this.setState({
                                          notificationLinkType: 2,
                                        })
                                      }
                                      disabled
                                    >
                                      External
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div> */}
                              <div className="pb-2">
                                <div
                                  style={{
                                    border: '1px solid',
                                    borderRadius: '5px',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                    paddingRight: '4px',
                                    paddingLeft: '4px',
                                  }}
                                  className="d-flex gap-3 align-content-center"
                                >
                                  <p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Activation Time
                                  </p>
                                  <TimePickerComponent
                                    selectedDate={moment(
                                      updatedNotificationData.activationDate
                                    )}
                                    handleSelectedDateChange={
                                      this
                                        .handleActivationDateNotificationChange
                                    }
                                  />
                                </div>
                              </div>
                              {authDiffNotification === 1 && (
                                <div className="d-flex gap-5">
                                  {(this.state.canApproveChannelData === 1 ||
                                    this.state.canAuthChannelData === 1) && (
                                    <Button
                                      onClick={async () => {
                                        // let uId = getUser();
                                        this.tog_AcceptNotification();
                                      }}
                                    >
                                      Accept
                                    </Button>
                                  )}
                                  <Modal
                                    isOpen={this.state.acceptModalNotification}
                                    toggle={this.tog_AcceptNotification}
                                    size="lg"
                                    // style={{ maxWidth: '900px', width: '100%' }}
                                  >
                                    <ModalHeader
                                      toggle={() =>
                                        this.setState({
                                          acceptModalNotification: false,
                                        })
                                      }
                                    >
                                      Confirmation
                                    </ModalHeader>
                                    {/* Edit */}
                                    <ModalBody>
                                      <Row>
                                        <div>
                                          Are you sure you wan to accept updated
                                          notification ?
                                        </div>
                                      </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        onClick={async () => {
                                          let token = getToken();
                                          const NotificationAccept =
                                            await GKApi.put(
                                              `/marketing/campaign/channel/approve`,
                                              {
                                                cmpId: 161,
                                                id: updatedNotificationData.id,
                                                // staffId: uId,
                                                type: 'notification',
                                                deviceInfo:
                                                  this.state.deviceInfo,
                                              },
                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );
                                          console.log(NotificationAccept);
                                          this.tog_AcceptNotification();
                                        }}
                                      >
                                        Yes
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          this.tog_AcceptNotification();
                                        }}
                                      >
                                        No
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                  {this.state.canRejectChannelData === 1 && (
                                    <Button
                                      onClick={async () => {
                                        // let uId = getUser();
                                        this.tog_Rejection();
                                      }}
                                    >
                                      Reject
                                    </Button>
                                  )}
                                  <Modal
                                    isOpen={this.state.rejectionModal}
                                    toggle={this.tog_Rejection}
                                    size="lg"
                                    // style={{ maxWidth: '900px', width: '100%' }}
                                  >
                                    <ModalHeader
                                      toggle={() =>
                                        this.setState({ rejectionModal: false })
                                      }
                                    >
                                      Reject Notification
                                    </ModalHeader>
                                    {/* Edit */}
                                    <ModalBody>
                                      <Row className="mb-2">
                                        <div>
                                          Please state the reason for rejecting.
                                        </div>
                                      </Row>
                                      <Row>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="basicpill-firstname-input1"
                                          value={this.state.reason}
                                          onChange={(e) => {
                                            this.handleReasonChange(e);
                                          }}
                                        />
                                      </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        onClick={async () => {
                                          let token = getToken();
                                          const NotificationReject =
                                            await GKApi.put(
                                              `/marketing/campaign/channel/reject`,
                                              {
                                                cmpId: 161,
                                                id: updatedNotificationData.id,
                                                // staffId: uId,
                                                type: 'notification',
                                                reason: this.state.reason,
                                                deviceInfo:
                                                  this.state.deviceInfo,
                                              },
                                              {
                                                headers: {
                                                  Authorization: token,
                                                },
                                              }
                                            );
                                          console.log(NotificationReject);

                                          this.tog_Rejection();
                                        }}
                                      >
                                        Yes
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          this.tog_Rejection();
                                        }}
                                      >
                                        No
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="py-4">
                              <div className="pb-2">
                                <h5 className="card-title">Title</h5>
                                <Input
                                  type="text"
                                  value={this.state.notificationTitle}
                                  id="gold-price-input"
                                  onChange={(e) => {
                                    this.handleNotificationTitleChange(e);
                                  }}
                                />
                              </div>
                              <div className="pb-2">
                                <h5 className="card-title">Description</h5>
                                <div>
                                  <TextField
                                    className="w-full"
                                    variant="outlined"
                                    type="text"
                                    multiline
                                    minRows={2}
                                    style={{
                                      width: '100%',
                                    }}
                                    value={this.state.notificationMessage}
                                    onChange={(e) => {
                                      this.handleNotificationMessageChange(e);
                                    }}
                                    // placeholder="Enter your comment here ..."
                                    // {...register('comment')}
                                  />
                                </div>
                              </div>
                              {/* <div className="pb-2">
                                <h5 className="card-title">Target Link</h5>
                                <Input
                                  type="text"
                                  value={this.state.notificationLink}
                                  placeholder="Https:/goldkinen.com/referral"
                                  id="gold-price-input"
                                  onChange={(e) => {
                                    this.handleNotificationLinkChange(e);
                                  }}
                                />
                              </div> */}
                              {/* <div className="pb-2">
                                <Dropdown
                                  isOpen={this.state.notificationLinkTypeMenu}
                                  toggle={() =>
                                    this.setState({
                                      notificationLinkTypeMenu:
                                        !this.state.notificationLinkTypeMenu,
                                    })
                                  }
                                  className="mt-4 mt-sm-0"
                                >
                                  <DropdownToggle
                                    className="d-flex justify-content-between"
                                    color="light"
                                    caret
                                    style={{
                                      backgroundColor: '#fff',
                                      border: '1px solid #BDBDBD',
                                      borderRadius: '4px',
                                      width: '100%',
                                    }}
                                  >
                                    {this.state.notificationLinkType === 1
                                      ? 'Internal'
                                      : 'External'}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={(event) =>
                                        this.setState({
                                          notificationLinkType: 1,
                                        })
                                      }
                                    >
                                      Internal
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) =>
                                        this.setState({
                                          notificationLinkType: 2,
                                        })
                                      }
                                    >
                                      External
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div> */}
                              <div className="pb-2">
                                <div
                                  style={{
                                    border: '1px solid',
                                    borderRadius: '5px',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                    paddingRight: '4px',
                                    paddingLeft: '4px',
                                  }}
                                  className="d-flex gap-3 align-content-center"
                                >
                                  <p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Activation Time
                                  </p>
                                  <TimePickerComponent
                                    selectedDate={
                                      this.state.activationDateNotification
                                    }
                                    handleSelectedDateChange={
                                      this
                                        .handleActivationDateNotificationChange
                                    }
                                  />
                                </div>
                              </div>
                              {this.state.canEditNotificationData === 1 && (
                                <Button
                                  onClick={async () => {
                                    // let uId = getUser();
                                    this.tog_UpdateNotification();
                                  }}
                                >
                                  Update Notification Details
                                </Button>
                              )}
                              <Modal
                                isOpen={this.state.updateNotificationModal}
                                toggle={this.tog_UpdateNotification}
                                size="lg"
                                // style={{ maxWidth: '900px', width: '100%' }}
                              >
                                <ModalHeader
                                  toggle={() =>
                                    this.setState({
                                      updateNotificationModal: false,
                                    })
                                  }
                                >
                                  Update Notification
                                </ModalHeader>
                                {/* Edit */}
                                <ModalBody>
                                  <Row>
                                    <div>
                                      Are you sure you wan to update
                                      notification ?
                                    </div>
                                  </Row>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    onClick={async () => {
                                      let token = getToken();
                                      const NotificationChange =
                                        await GKApi.post(
                                          `/marketing/campaign/channel/notification`,
                                          {
                                            cmpId: 161,
                                            campaignId: campaignData.id,
                                            // staffId: uId,
                                            linkType:
                                              this.state.notificationLinkType,
                                            link: this.state.notificationLink,
                                            title: this.state.notificationTitle,
                                            msg: this.state.notificationMessage,
                                            activationDate: moment(
                                              this.state
                                                .activationDateNotification
                                            ).format('YYYY-MM-DD HH:mm:ss'),
                                          },
                                          {
                                            headers: {
                                              Authorization: token,
                                            },
                                          }
                                        );
                                      console.log(NotificationChange);
                                      this.tog_UpdateNotification();
                                    }}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      this.tog_UpdateNotification();
                                    }}
                                  >
                                    No
                                  </Button>
                                </ModalFooter>
                              </Modal>
                            </div>
                          )}
                        </div>
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3" className="p-3">
                  <Row>
                    <Col sm="12">
                      <CardText>
                        <div className="border border-1 rounded p-4">
                          {Object.keys(updatedInboxData).length > 0 ? (
                            <div className="py-4">
                              {/* <div className="pb-2">
                                <h5 className="card-title">Title</h5>
                                <Input
                                  type="text"
                                  value={updatedInboxData.title}
                                  id="gold-price-input"
                                  onChange={(e) => {
                                    this.handleNotificationTitleChange(e);
                                  }}
                                  disabled
                                />
                              </div> */}
                              <div className="pb-2">
                                <h5 className="card-title">Description</h5>
                                <div>
                                  <TextField
                                    className="w-full"
                                    variant="outlined"
                                    type="text"
                                    multiline
                                    minRows={2}
                                    style={{
                                      width: '100%',
                                    }}
                                    value={updatedInboxData.msg}
                                    onChange={(e) => {
                                      this.handleNotificationMessageChange(e);
                                    }}
                                    disabled
                                    // placeholder="Enter your comment here ..."
                                    // {...register('comment')}
                                  />
                                </div>
                              </div>
                              {/* <div className="pb-2">
                                <h5 className="card-title">Target Link</h5>
                                <Input
                                  type="text"
                                  value={updatedInboxData.link}
                                  placeholder="Https:/goldkinen.com/referral"
                                  id="gold-price-input"
                                  onChange={(e) => {
                                    this.handleNotificationLinkChange(e);
                                  }}
                                  disabled
                                />
                              </div>
                              <div className="pb-2">
                                <Dropdown
                                  isOpen={this.state.notificationLinkTypeMenu}
                                  toggle={() =>
                                    this.setState({
                                      notificationLinkTypeMenu:
                                        !this.state.notificationLinkTypeMenu,
                                    })
                                  }
                                  className="mt-4 mt-sm-0"
                                >
                                  <DropdownToggle
                                    className="d-flex justify-content-between"
                                    color="light"
                                    caret
                                    style={{
                                      backgroundColor: '#fff',
                                      border: '1px solid #BDBDBD',
                                      borderRadius: '4px',
                                      width: '100%',
                                    }}
                                  >
                                    {updatedInboxData.linkType === 1
                                      ? 'Internal'
                                      : 'External'}
                                    <i className="mdi mdi-chevron-down"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={(event) =>
                                        this.setState({
                                          notificationLinkType: 1,
                                        })
                                      }
                                      disabled
                                    >
                                      Internal
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) =>
                                        this.setState({
                                          notificationLinkType: 2,
                                        })
                                      }
                                      disabled
                                    >
                                      External
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div> */}
                              <div className="pb-2">
                                <div
                                  style={{
                                    border: '1px solid',
                                    borderRadius: '5px',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                    paddingRight: '4px',
                                    paddingLeft: '4px',
                                  }}
                                  className="d-flex gap-3 align-content-center"
                                >
                                  <p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Activation Time
                                  </p>
                                  <TimePickerComponent
                                    selectedDate={
                                      updatedInboxData.activationDate
                                    }
                                    handleSelectedDateChange={
                                      this
                                        .handleActivationDateNotificationChange
                                    }
                                  />
                                </div>
                              </div>
                              {authDiffInbox === 1 && (
                                <div className="d-flex gap-5">
                                  {(this.state.canApproveChannelData === 1 ||
                                    this.state.canAuthChannelData === 1) && (
                                    <Button
                                      onClick={async () => {
                                        // let uId = getUser();
                                        this.tog_AcceptInbox();
                                      }}
                                    >
                                      Accept
                                    </Button>
                                  )}
                                  <Modal
                                    isOpen={this.state.acceptModalInbox}
                                    toggle={this.tog_AcceptInbox}
                                    size="lg"
                                    // style={{ maxWidth: '900px', width: '100%' }}
                                  >
                                    <ModalHeader
                                      toggle={() =>
                                        this.setState({
                                          acceptModalInbox: false,
                                        })
                                      }
                                    >
                                      Confirmation
                                    </ModalHeader>
                                    {/* Edit */}
                                    <ModalBody>
                                      <Row>
                                        <div>
                                          Are you sure you wan to accept updated
                                          inbox data ?
                                        </div>
                                      </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        onClick={async () => {
                                          let token = getToken();
                                          const InboxAccept = await GKApi.put(
                                            `/marketing/campaign/channel/approve`,
                                            {
                                              cmpId: 161,
                                              id: updatedInboxData.id,
                                              // staffId: uId,
                                              type: 'inbox',
                                              deviceInfo: this.state.deviceInfo,
                                            },
                                            {
                                              headers: {
                                                Authorization: token,
                                              },
                                            }
                                          );
                                          console.log(InboxAccept);
                                          this.tog_AcceptInbox();
                                        }}
                                      >
                                        Yes
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          this.tog_AcceptInbox();
                                        }}
                                      >
                                        No
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                  {this.state.canRejectChannelData === 1 && (
                                    <Button
                                      onClick={async () => {
                                        // let uId = getUser();
                                        this.tog_RejectionInbox();
                                      }}
                                    >
                                      Reject
                                    </Button>
                                  )}
                                  <Modal
                                    isOpen={this.state.rejectionModalInbox}
                                    toggle={this.tog_RejectionInbox}
                                    size="lg"
                                    // style={{ maxWidth: '900px', width: '100%' }}
                                  >
                                    <ModalHeader
                                      toggle={() =>
                                        this.setState({
                                          rejectionModalInbox: false,
                                        })
                                      }
                                    >
                                      Reject Inbox
                                    </ModalHeader>
                                    {/* Edit */}
                                    <ModalBody>
                                      <Row className="mb-2">
                                        <div>
                                          Please state the reason for rejecting.
                                        </div>
                                      </Row>
                                      <Row>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="basicpill-firstname-input1"
                                          value={this.state.reason}
                                          onChange={(e) => {
                                            this.handleReasonChange(e);
                                          }}
                                        />
                                      </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        onClick={async () => {
                                          let token = getToken();
                                          const InboxReject = await GKApi.put(
                                            `/marketing/campaign/channel/reject`,
                                            {
                                              cmpId: 161,
                                              id: updatedInboxData.id,
                                              // staffId: uId,
                                              type: 'inbox',
                                              reason: this.state.reason,
                                              deviceInfo: this.state.deviceInfo,
                                            },
                                            {
                                              headers: {
                                                Authorization: token,
                                              },
                                            }
                                          );
                                          console.log(InboxReject);

                                          this.tog_RejectionInbox();
                                        }}
                                      >
                                        Yes
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          this.tog_RejectionInbox();
                                        }}
                                      >
                                        No
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="py-4">
                              {/* <div className="pb-2">
                                <h5 className="card-title">Title</h5>
                                <Input
                                  type="text"
                                  value={this.state.inboxTitle}
                                  id="gold-price-input"
                                  onChange={(e) => {
                                    this.handleInboxTitleChange(e);
                                  }}
                                />
                              </div> */}
                              <div className="pb-2">
                                <h5 className="card-title">Description</h5>
                                <div>
                                  <TextField
                                    className="w-full"
                                    variant="outlined"
                                    type="text"
                                    multiline
                                    minRows={2}
                                    style={{
                                      width: '100%',
                                    }}
                                    value={this.state.inboxMessage}
                                    onChange={(e) => {
                                      this.handleInboxMessageChange(e);
                                    }}
                                    // placeholder="Enter your comment here ..."
                                    // {...register('comment')}
                                  />
                                </div>
                              </div>
                              <div className="pb-2">
                                <div
                                  style={{
                                    border: '1px solid',
                                    borderRadius: '5px',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                    paddingRight: '4px',
                                    paddingLeft: '4px',
                                  }}
                                  className="d-flex gap-3 align-content-center"
                                >
                                  <p
                                    className="mt-1 fw-bold"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Activation Time
                                  </p>
                                  <TimePickerComponent
                                    selectedDate={this.state.activationDate}
                                    handleSelectedDateChange={
                                      this.handleActivationDateChange
                                    }
                                  />
                                </div>
                              </div>

                              {this.state.canEditInboxData === 1 && (
                                <Button
                                  onClick={async () => {
                                    this.tog_UpdateInbox();
                                  }}
                                >
                                  Update Inbox Details
                                </Button>
                              )}
                              <Modal
                                isOpen={this.state.updateInboxModal}
                                toggle={this.tog_UpdateInbox}
                                size="lg"
                                // style={{ maxWidth: '900px', width: '100%' }}
                              >
                                <ModalHeader
                                  toggle={() => {
                                    this.setState({
                                      updateInboxModal: false,
                                    });
                                    
                                  }}
                                >
                                  Update Inbox
                                </ModalHeader>
                                {/* Edit */}
                                <ModalBody>
                                  <Row>
                                    <div>
                                      Are you sure you wan to update inbox data
                                      ?
                                    </div>
                                  </Row>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    onClick={async () => {
                                      // let uId = getUser();
                                      let token = getToken();
                                      const inboxChange = await GKApi.post(
                                        `/marketing/campaign/channel/inbox`,
                                        {
                                          cmpId: 161,
                                          campaignId: campaignData.id,
                                          // staffId: uId,
                                          title: this.state.inboxTitle,
                                          msg: this.state.inboxMessage,
                                          activationDate: moment(
                                            this.state.activationDate
                                          ).format('YYYY-MM-DD HH:mm:ss'),
                                        },
                                        {
                                          headers: {
                                            Authorization: token,
                                          },
                                        }
                                      );
                                      console.log(inboxChange);
                                      this.tog_UpdateInbox();
                                    }}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      this.tog_UpdateInbox();
                                    }}
                                  >
                                    No
                                  </Button>
                                </ModalFooter>
                              </Modal>
                            </div>
                          )}
                        </div>
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>

            {(campaignData.isBanner === 1 || campaignData.isBannner === 1) && (
              <Row className="my-4">
                <Col sm="12">
                  <CardText>
                    <h5 className="fw-bold my-2">App banner</h5>
                    <div className="border border-1 rounded p-4">
                      <Row className="py-2 px-4">
                        {campaignData?.bannerUrl && (
                          <img
                            data-dz-thumbnail=""
                            // height="50%"
                            // width="50%"
                            // className="avatar-sm rounded bg-light"
                            alt={'app banner'}
                            src={campaignData?.bannerUrl}
                          />
                        )}
                        {this.state.canEditAppBannerData === 1 && (
                          <Row className="py-2 d-flex justify-content-around">
                            <Col
                              xl={4}
                              className="border border-1 text-center twitter-bs-wizard-border-radio rounded"
                            >
                              <div
                                className="d-flex justify-content-center"
                                onClick={() => {
                                  this.setState({
                                    showChangeBanner: true,
                                  });
                                }}
                              >
                                <i
                                  className="fas fa-pen-square px-2 py-3"
                                  style={{
                                    color: '#CEC382',
                                  }}
                                ></i>
                                <h6
                                  className="fw-bold mt-3"
                                  style={{
                                    color: '#CEC382',
                                  }}
                                >
                                  Change Banner
                                </h6>
                              </div>
                            </Col>
                          </Row>
                        )}
                        {this.state.showChangeBanner === true && (
                          <div>
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                this.handleAcceptedFiles(acceptedFiles)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                    </div>
                                    <h4>Upload banner</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {this.state.selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + '-file'}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted fw-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </Row>

                      <div className="pb-2">
                        <Dropdown
                          isOpen={this.state.bannerLinkTypeMenu}
                          toggle={() =>
                            this.setState({
                              bannerLinkTypeMenu:
                                !this.state.bannerLinkTypeMenu,
                            })
                          }
                          className="mt-4 mt-sm-0"
                        >
                          <DropdownToggle
                            className="d-flex justify-content-between"
                            color="light"
                            caret
                            style={{
                              backgroundColor: '#fff',
                              border: '1px solid #BDBDBD',
                              borderRadius: '4px',
                              width: '100%',
                            }}
                          >
                            {this.state.bannerLinkType === 1
                              ? 'Internal'
                              : 'External'}
                            <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={(event) =>
                                this.setState({
                                  bannerLinkType: 1,
                                })
                              }
                            >
                              Internal
                            </DropdownItem>
                            <DropdownItem
                              onClick={(event) =>
                                this.setState({
                                  bannerLinkType: 2,
                                })
                              }
                            >
                              External
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <Row className="py-2">
                        <span>
                          <h6 className="fw-bold"> Target Link</h6>
                        </span>
                        <span>
                          <Col>
                            {this.state.bannerLinkType === 2 ? (
                              <Input
                                type="text"
                                value={this.state.bannerLink}
                                placeholder="Https:/goldkinen.com/referral"
                                id="gold-price-input"
                                onChange={(e) => {
                                  this.handleBannerLinkChange(e);
                                }}
                              />
                            ) : (
                              <Dropdown
                                isOpen={
                                  this.state.campaignBannerInternalLinkTypeMenu
                                }
                                toggle={() =>
                                  this.setState({
                                    campaignBannerInternalLinkTypeMenu:
                                      !this.state
                                        .campaignBannerInternalLinkTypeMenu,
                                  })
                                }
                                className="mt-4 mt-sm-0"
                              >
                                <DropdownToggle
                                  className="d-flex justify-content-between"
                                  color="light"
                                  caret
                                  style={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #BDBDBD',
                                    borderRadius: '4px',
                                    width: '100%',
                                  }}
                                >
                                  {this.state.bannerLink}
                                  <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {this.state.bannerInternalLinkList.map(
                                    (links) => (
                                      <DropdownItem
                                        onClick={(event) =>
                                          this.setState({
                                            bannerLink: links,
                                          })
                                        }
                                      >
                                        {links}
                                      </DropdownItem>
                                    )
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            )}
                          </Col>
                        </span>
                      </Row>
                      {this.state.canEditAppBannerData === 1 && (
                        <Button
                          onClick={async () => {
                            this.tog_BannerUpdate();
                          }}
                        >
                          Update Banner
                        </Button>
                      )}
                    </div>
                    <Modal
                      isOpen={this.state.bannerUpdateModal}
                      toggle={this.tog_BannerUpdate}
                      size="lg"
                      // style={{ maxWidth: '900px', width: '100%' }}
                    >
                      <ModalHeader
                        toggle={() =>
                          this.setState({
                            bannerUpdateModal: false,
                          })
                        }
                      >
                        Banner Update
                      </ModalHeader>
                      {/* Edit */}
                      <ModalBody>
                        <Row>
                          <div>Are you sure you wan to update banner?</div>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          onClick={async () => {
                            const token = getToken(); //get token from local storage
                            // {&&
                            if (this.state.selectedFiles.length > 0) {
                              const response = await GKApi.post(
                                '/marketing/banner',
                                {
                                  staffId: getUser(),
                                  fileName:
                                    'banner.' +
                                    this.state.selectedFiles[0].name
                                      .split('.')
                                      .pop(),
                                  campaignTitle:
                                    this.props.campaignType === 'CAMPAIGN'
                                      ? campaignData.title
                                      : campaignData.campaignTitle,
                                },
                                {
                                  headers: {
                                    Authorization: token,
                                  },
                                }
                              );
                              this.setState({
                                bannerS3Key:response.data.key
                              })
                              const uploadS3Response = await axios.put(
                                response.data.url,
                                this.state.selectedFiles[0]
                              );
                              console.log(uploadS3Response);
                            }
                            // }
                            let uid = getUser();
                            const BannerChange = await GKApi.post(
                              `/marketing/campaign/channel/banner`,
                              {
                                // cmpId: 143,
                                campaignId: campaignData.id,
                                staffId: uid,
                                bannerLinkType: this.state.bannerLinkType,
                                bannerLink: this.state.bannerLink,
                                bannerUrl:
                                   this.state.bannerS3Key,
                                campaignType:
                                  this.props.campaignType === 'CAMPAIGN'
                                    ? 'campaign'
                                    : 'general',
                              },
                              {
                                headers: {
                                  Authorization: token,
                                },
                              }
                            );
                            console.log(BannerChange);
                            this.tog_BannerUpdate();
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={() => {
                            this.tog_BannerUpdate();
                          }}
                        >
                          No
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </CardText>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default CampaignChannelDataModification;

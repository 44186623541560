import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Col, Container, Row } from 'reactstrap';
const csv = require('csvtojson');

export default class Uploader extends Component {
  constructor() {
    super();

    this.state = {
      files: [],
    };
  }

  onDrop = (acceptedFiles) => {
    this.setState({
      files: acceptedFiles,
    });

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const fileAsBinaryString = reader.result;

        csv({
          noheader: true,
          output: 'json',
        })
          .fromString(fileAsBinaryString)
          .then((csvRows) => {
            const toJson = [];
            csvRows.forEach((aCsvRow, i) => {
              if (i !== 0) {
                const builtObject = {};

                Object.keys(aCsvRow).forEach((aKey) => {
                  const valueToAddInBuiltObject = aCsvRow[aKey];
                  const keyToAddInBuiltObject = csvRows[0][aKey];
                  builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                });

                toJson.push(builtObject);
              }
            });
            this.props.onDrop(toJson);
          });
      };

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      reader.readAsBinaryString(file);
    });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xl={12}>
                <Dropzone
                  onDrop={this.onDrop} //file ondrop call
                  multiple={false} // multiple selection off
                  style={{
                    position: 'absolute',
                    left: '50%',
                    // top: '50%',
                    transform: 'translate(-20%, -50%)',
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <h1 style={{ textAlign: 'center' }}>
                        Upload a CSV for batch disbursement
                      </h1>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p style={{ textAlign: 'center' }} className="m-5">
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                      <aside>
                        {this.state.files.length !== 0 ? <h4>File</h4> : ''}
                        <ul>
                          {this.state.files.map((f) => (
                            <li key={f.name}>
                              {f.name} - {f.size} bytes
                            </li>
                          ))}
                        </ul>
                      </aside>
                    </section>
                  )}
                </Dropzone>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

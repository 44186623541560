import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Auth } from 'aws-amplify';
import store from './store';

// cognito

Auth.configure({
  userPoolId: process.env.REACT_APP_USER_POOL,
  userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
});

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();

import React, { Component } from 'react';
// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
//i18n
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
  logoutUser,
} from '../../store/actions';
import { useSelector } from 'react-redux';
import { getGroupName, getToken, GKApi } from '../config';
import { Auth } from 'aws-amplify';

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const name = useSelector((state) => state.Login.user.user?.name);
    const email = useSelector((state) => state.Login.user.user?.email);
    const designation = useSelector(
      (state) => state.Login.user.user?.designation
    );
    const dob = useSelector((state) => state.Login.user.user?.dob);
    const phone = useSelector((state) => state.Login.user.user?.phone);
    const groupId = useSelector((state) => state.Login.user.user?.groupId);

    return (
      <Component
        {...props}
        name={name}
        email={email}
        designation={designation}
        dob={dob}
        phone={phone}
        groupId={groupId}
      />
    );
  };
}

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canViewCRMUserList: 1,
      canViewCRMChangeRequests: 1,
      canViewAdmin: 1,
      canViewManualDisbursement: 1,
      canViewReferDisbursement: 1,
      canViewBkashSettings: 1,
      groupName: [],
      isSuper: false,
      isFin: false,
      isMkt: false,
      isCrm: false,
      isCSR: false,
    };
  }

  async componentDidMount() {
    const token = getToken();
    // const groupId = getGroupId();

    Auth.currentAuthenticatedUser({
      // Optional, By default is false. If set to true,
      // this call will send a request to Cognito to get the latest user data
      bypassCache: false,
    })
      .then(async (user) => {
        const getPermission = await GKApi.get(
          `/role/permission?cmpId=19,16,43,101,40,172`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const permissionData = {};

        // mapping permission to object instead of array
        getPermission.data.map((item) => (permissionData[item.cmpId] = item));
        this.setState({
          canViewCRMUserList: permissionData[43].canView,
          canViewCRMChangeRequests: permissionData[101].canView,
          canViewAdmin: permissionData[40].canView,
          canViewManualDisbursement: permissionData[16].canView,
          canViewReferDisbursement: permissionData[19].canView,
          canViewBkashSettings: permissionData[172].canView,
        });

        let groupName = getGroupName();
        if (groupName.length > 0) {
          groupName = groupName.split(',');
        }
        let gN = new Set();
        groupName.forEach((i) => {
          let name = i.split('_')[0];
          gN.add(name);
          if (name === 'SUPER') {
            this.setState({
              isSuper: true,
            });
          } else if (name === 'FIN') {
            this.setState({
              isFin: true,
            });
          } else if (name === 'MKT') {
            this.setState({
              isMkt: true,
            });
          } else if (name === 'CRM') {
            this.setState({
              isCrm: true,
            });
          } else if (name === 'CSR') {
            this.setState({
              isCSR: true,
            });
          }
        });
        let groupNames = [...gN];

        this.setState({ groupName: groupNames });
        this.initMenu();
      })
      .catch((err) => {
        const currentLocation = window.location.pathname;
        if (currentLocation !== '/signin') {
          console.log(err);
          window.localStorage.clear();
          this.props.dispatch(logoutUser());
          window.location.href = '/logout';
        }
        this.initMenu();
      });

    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu('#side-menu');
    var matchingMenuItem = null;
    var ul = document.getElementById('side-menu');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t('Menu')}</li>

            {this.state.isSuper === true || this.state.isFin === true ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi-finance"></i>
                  <span className="ms-1">{this.props.t('Finance')}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/finance-dashboard">
                      {this.props.t('Finance Dashboard')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/finance-collect">
                      {this.props.t('Finance Collect')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/finance-ops-inputs">
                      {this.props.t('Finance & Ops Inputs')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/finance-internal-settings">
                      {this.props.t('Finance Internal Settings')}
                    </Link>
                  </li>
                  {this.state.canViewBkashSettings === 1 && (
                    <li>
                      <Link to="/bkash-disbursement-settings">
                        {this.props.t('Bkash Refund Settings')}
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link to="/manual-refund">
                      {this.props.t('Manual Refund')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/finance-refer-settings">
                      {this.props.t('User refer Settings')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/custom-refer-settings">
                      {this.props.t('Custom refer Settings')}
                    </Link>
                  </li>

                  {this.state.canViewReferDisbursement === 1 && (
                    <li>
                      <Link to="/refer-disbursement">
                        {this.props.t('Refer Disbursement')}
                      </Link>
                    </li>
                  )}
                  {this.state.canViewManualDisbursement === 1 && (
                    <li>
                      <Link to="/manual-disbursement">
                        {this.props.t('Manual Disbursement')}
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="/finance-disputes">
                      {this.props.t('Special Disbursement')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/finance-gold-holding-report">
                      {this.props.t('Holding Report')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/finance-ledger">
                      {this.props.t('Finance Ledger')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/refer-report">
                      {this.props.t('Finance Refer Report')}
                    </Link>
                  </li>

                  {/* {this.props.groupId === 2 || 21 ? (
                  
                ) : (
                  ''
                )} */}
                  {/* {this.props.groupId === 22 ? (
                    ''
                  ) : (
                    <li>
                      <Link to="/custom-report">
                        {this.props.t('Custom Report')}
                      </Link>
                    </li>
                  )} */}
                </ul>
              </li>
            ) : (
              ''
            )}

            {(this.state.isSuper === true || this.state.isMkt === true) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-briefcase"></i>
                  <span className="ms-1">{this.props.t('Marketing')}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/marketing-dashboard">
                      {this.props.t('Overview')}
                    </Link>
                  </li>

                  <li>
                    <Link to="/marketing-campaign">
                      {this.props.t('Communication Tools')}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/app-marketing">
                      {this.props.t('App marketing')}
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/marketing-report">
                      {this.props.t('Reports and insights')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {this.state.isSuper === true && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="fas fa-users-cog"></i>
                  <span className="ms-1">{this.props.t('Admin')}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/admin-users">
                      {this.props.t('Admin user list')}
                    </Link>
                  </li>

                  <li>
                    <Link to="/user-groups">{this.props.t('User Groups')}</Link>
                  </li>
                  <li>
                    <Link to="/app-version">{this.props.t('App Version')}</Link>
                  </li>
                </ul>
              </li>
            )}
            {(this.state.isSuper === true || this.state.isCrm === true) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="fas fa-users"></i>
                  <span className="ms-1">{this.props.t('CRM')}</span>
                </Link>
                <ul className="sub-menu">
                  {this.state.canViewCRMUserList === 1 ? (
                    <li>
                      <Link to="/app-users">{this.props.t('User list')}</Link>
                    </li>
                  ) : (
                    ''
                  )}

                  <li>
                    <Link to="/user-requests">
                      {this.props.t('App User Requests')}
                    </Link>
                  </li>
                  {this.state.canViewCRMChangeRequests === 1 && (
                    <li>
                      <Link to="/crm-change-requests">
                        {this.props.t('CRM Change Requests')}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {/* {this.state.isSuper === true && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-select-inverse"></i>
                  <span className="ms-1">{this.props.t('SKU')}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/sku-management">
                      {this.props.t('SKU Management')}
                    </Link>
                  </li>
                </ul>
              </li>
            )} */}

            {(this.state.isSuper === true ||
              this.state.isFin === true ||
              this.state.isCrm === true) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="fas fa-shipping-fast"></i>
                  <span className="ms-1">{this.props.t('Delivery')}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/delivery-management">
                      {this.props.t('Delivery Management')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/pickup-management">
                      {this.props.t('Pickup Management')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {this.state.isCSR && (
              <li>
                <Link to="/csr">
                  <i className="fas fa-users"></i>
                  <span>{this.props.t('CSR')}</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withMyHook(
  withRouter(
    connect(mapStatetoProps, {
      changeLayout,
      changeSidebarTheme,
      changeSidebarType,
      changeLayoutWidth,
      changePreloader,
    })(withNamespaces()(SidebarContent))
  )
);

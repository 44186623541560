import React, { Component } from 'react';


//i18n
// eslint-disable-next-line
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';



class LanguageDropdown extends Component {
  

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default withNamespaces()(LanguageDropdown);

import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';

import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Card,
  CardBody,
  Button,
  Input,
} from 'reactstrap';
import {
  capitalizeFirstLetter,
  getToken,
  GKApi,
} from '../../components/config';
import moment from 'moment';
import * as XLSX from 'xlsx';
import DatePickerComponent from '../../components/Common/DatePicker';
import { deviceInfo } from '../../helpers/device_info_helper';

class UserRequestHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Tables', link: '#' },
        { title: 'User Request history', link: '#' },
      ],
      reqType: 'profile',
      reqMenu: false,
      nidFrontPicUpdated: null,
      nidBackPicUpdated: null,
      nidFrontPicCurrent: null,
      nidBackPicCurrent: null,
      profileHistoryReq: [],
      addressHistoryReq: [],
      bankHistoryReq: [],
      bkashHistoryReq: [],
      unbindHistoryReq: [],
      modal_standard: false,
      key: null,
      modal: false,
      deviceInfo: {},
      searchKey: '',
      searchStartDate: null,
      searchEndDate: null,
      isLoading: false,
      pageNumber: 1,
      totalPageCount: 0,
      searchLoading: false,
      clearLoading: false,
    };
    this.tog_standard = this.tog_standard.bind(this);
  }

  //modal closing options

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  //fetch user list call

  async componentDidMount() {
    // GET request using axios with async/await
    const token = getToken();
    const response = await GKApi.get(
      `/users/change-request-history?type=${this.state.reqType}&pageNumber=1&pageSize=10`,
      {
        headers: {
          Authorization: token,
          cmpId: 47,
        },
      }
    );

    this.setState({
      profileHistoryReq: response.data.data,
      totalPageCount: response.data.paginationInfo.totalPage,
      pageNumber: response.data.paginationInfo.pageNumber,
    });
    const res = deviceInfo();
    this.setState({ deviceInfo: res });
  }

  handleSearchKeyChange = (event) => {
    this.setState({ searchKey: event.target.value });
  };

  handleSearchStartDate = (event) => {
    this.setState(
      {
        ...this.state,
        searchStartDate: event,
      },
      () => {}
    );
  };
  handleSearchEndDate = (event) => {
    this.setState(
      {
        ...this.state,
        searchEndDate: event,
      },
      () => {}
    );
  };

  render() {
    //user request columns for profile request
    const profileHistoryColumns = [
      {
        field: 'name',
        title: 'Name',
      },
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'createTime',
        title: 'Request Date',
      },
      {
        field: 'approvedTime',
        title: 'Action Date',
      },
      {
        field: 'status',
        title: 'Status',
      },
    ];
    const bankHistoryColumns = [
      {
        field: 'name',
        title: 'Name',
      },
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'createTime',
        title: 'Request Date',
      },
      {
        field: 'approvedTime',
        title: 'Action Date',
      },
      {
        field: 'status',
        title: 'Status',
      },
    ];
    const addressHistoryColumns = [
      {
        field: 'name',
        title: 'Name',
      },
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'createTime',
        title: 'Request Date',
      },
      {
        field: 'approvedTime',
        title: 'Action Date',
      },
      {
        field: 'status',
        title: 'Status',
      },
    ];
    const bkashHistoryColumns = [
      {
        field: 'name',
        title: 'Name',
      },
      {
        field: 'phone',
        title: 'Phone Number',
      },
      {
        field: 'createTime',
        title: 'Request Date',
      },
      {
        field: 'approvedTime',
        title: 'Action Date',
      },
      {
        field: 'status',
        title: 'Status',
      },
    ];
    const unbindHistoryColumns = [
      {
        field: 'requestUserName',
        title: 'Name',
      },
      {
        field: 'requestUserPhone',
        title: 'Phone Number',
      },
      {
        field: 'createTime',
        title: 'Request Date',
      },
      {
        field: 'approvedTime',
        title: 'Action Date',
      },
      {
        field: 'status',
        title: 'Status',
      },
    ];

    const downloadExcel = async () => {
      const token = getToken();
      const response = await GKApi.get(
        `/users/change-request-history?type=${this.state.reqType}&pageNumber=1&pageSize=10000000`,
        {
          headers: {
            Authorization: token,
            cmpId: 47,
          },
        }
      );
      const newData = response.data.data.map((row) => {
        delete row.tableData;
        return row;
      });
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workBook,
        workSheet,
        `${
          this.state.reqType === 'profile'
            ? 'Profile Requests'
            : this.state.reqType === 'bank'
            ? 'Bank Requests'
            : this.state.reqType === 'address'
            ? 'Address Requests'
            : this.state.reqType === 'bkash'
            ? 'Bkash Requests'
            : 'Unbind Requests'
        }`
      );
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(
        workBook,
        `${
          this.state.reqType === 'profile'
            ? 'Profile Request History.xlsx'
            : this.state.reqType === 'bank'
            ? 'Bank Request History.xlsx'
            : this.state.reqType === 'address'
            ? 'Address Request History.xlsx'
            : this.state.reqType === 'bkash'
            ? 'Bkash Request History.xlsx'
            : 'Unbind Request History.xlsx'
        }`
      );
    };
    return (
      <React.Fragment>
        <div>
          <div id="47">
            <MaterialTable
              title={
                <div className="d-flex gap-5">
                  <h5>History of User Requests</h5>
                </div>
              }
              columns={
                this.state.reqType === 'profile'
                  ? profileHistoryColumns
                  : this.state.reqType === 'address'
                  ? addressHistoryColumns
                  : this.state.reqType === 'bank'
                  ? bankHistoryColumns
                  : this.state.reqType === 'bkash'
                  ? bkashHistoryColumns
                  : unbindHistoryColumns
              }
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                    <div className="d-flex gap-3 align-items-center mx-3">
                      <h5>Select Request History type:</h5>
                      <Dropdown
                        isOpen={this.state.reqMenu}
                        toggle={(event) =>
                          this.setState({
                            reqMenu: !this.state.reqMenu,
                          })
                        }
                      >
                        <DropdownToggle className="px-5 py-2" color="light">
                          {capitalizeFirstLetter(this.state.reqType)}{' '}
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={async (event) => {
                              this.setState({ reqType: 'profile' });
                              const token = getToken();
                              const response = await GKApi.get(
                                `/users/change-request-history?type=profile&pageNumber=1&pageSize=10`,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 47,
                                  },
                                }
                              );
                              this.setState({
                                profileHistoryReq: response.data.data,
                                totalPageCount:
                                  response.data.paginationInfo.totalPage,
                                pageNumber:
                                  response.data.paginationInfo.pageNumber,
                              });
                            }}
                          >
                            Profile
                          </DropdownItem>
                          <DropdownItem
                            onClick={async (event) => {
                              this.setState({ reqType: 'bank' });
                              const token = getToken();
                              const response = await GKApi.get(
                                `/users/change-request-history?type=bank&pageNumber=1&pageSize=10`,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 47,
                                  },
                                }
                              );

                              this.setState({
                                bankHistoryReq: response.data.data,
                                totalPageCount:
                                  response.data.paginationInfo.totalPage,
                                pageNumber:
                                  response.data.paginationInfo.pageNumber,
                              });
                            }}
                          >
                            Bank
                          </DropdownItem>

                          <DropdownItem
                            onClick={async (event) => {
                              this.setState({ reqType: 'address' });
                              const token = getToken();
                              const response = await GKApi.get(
                                `/users/change-request-history?type=address&pageNumber=1&pageSize=10`,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 47,
                                  },
                                }
                              );
                              this.setState({
                                addressHistoryReq: response.data.data,
                                totalPageCount:
                                  response.data.paginationInfo.totalPage,
                                pageNumber:
                                  response.data.paginationInfo.pageNumber,
                              });
                            }}
                          >
                            Address
                          </DropdownItem>
                          <DropdownItem
                            onClick={async (event) => {
                              this.setState({ reqType: 'bkash' });
                              const token = getToken();
                              const response = await GKApi.get(
                                `/users/change-request-history?type=bkash&pageNumber=1&pageSize=10`,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 47,
                                  },
                                }
                              );
                              this.setState({
                                bkashHistoryReq: response.data.data,
                                totalPageCount:
                                  response.data.paginationInfo.totalPage,
                                pageNumber:
                                  response.data.paginationInfo.pageNumber,
                              });
                            }}
                          >
                            Bkash
                          </DropdownItem>
                          <DropdownItem
                            onClick={async (event) => {
                              this.setState({ reqType: 'unbind' });
                              const token = getToken();
                              const response = await GKApi.get(
                                `/users/change-request-history?type=unbind&pageNumber=1&pageSize=10`,
                                {
                                  headers: {
                                    Authorization: token,
                                    cmpId: 47,
                                  },
                                }
                              );
                              this.setState({
                                unbindHistoryReq: response.data.data,
                                totalPageCount:
                                  response.data.paginationInfo.totalPage,
                                pageNumber:
                                  response.data.paginationInfo.pageNumber,
                              });
                            }}
                          >
                            Unbind
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>

                    <div className="d-flex gap-3">
                      <div className="d-flex gap-3 align-items-center mx-3 my-3">
                        <h5>Phone:</h5>
                        <Input
                          key={'search-text'}
                          type="text"
                          value={this.state.searchKey}
                          id="example-name-input"
                          onChange={this.handleSearchKeyChange}
                          autoFocus="autoFocus"
                        />
                      </div>
                      {/* <p className='mx-3'>Search based on joining date</p> */}
                      <div className="d-flex gap-3 align-items-center mx-3 my-3">
                        <h5>Start Date:</h5>
                        <div
                          style={{
                            border: '1px solid gray',
                            borderRadius: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '4px',
                            paddingLeft: '4px',
                          }}
                        >
                          <DatePickerComponent
                            selectedDate={this.state.searchStartDate}
                            handleSelectedDateChange={
                              this.handleSearchStartDate
                            }
                          />
                        </div>

                        <h5>End Date:</h5>
                        <div
                          style={{
                            border: '1px solid gray',
                            borderRadius: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '4px',
                            paddingLeft: '4px',
                          }}
                        >
                          <DatePickerComponent
                            selectedDate={this.state.searchEndDate}
                            handleSelectedDateChange={this.handleSearchEndDate}
                          />
                        </div>

                        {((this.state.searchStartDate !== null &&
                          this.state.searchEndDate !== null) ||
                          this.state.searchKey !== '') && (
                          <div className="d-flex align-items-center gap-3">
                            <Button
                              onClick={async () => {
                                this.setState({ searchLoading: true });
                                const token = getToken();
                                const response = await GKApi.get(
                                  `/users/change-request-history?type=${
                                    this.state.reqType
                                  }&pageNumber=1&pageSize=10${
                                    this.state.searchKey !== ''
                                      ? `&phone=${this.state.searchKey}`
                                      : ``
                                  }${
                                    this.state.searchStartDate !== null &&
                                    this.state.searchEndDate !== null
                                      ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                                      : ''
                                  }`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 47,
                                    },
                                  }
                                );
                                
                                  this.state.reqType === 'profile'
                                    ? this.setState(
                                        {
                                          profileHistoryReq: response.data.data,
                                          totalPageCount:
                                            response.data.paginationInfo
                                              .totalPage,
                                          pageNumber:
                                            response.data.paginationInfo
                                              .pageNumber,
                                        },
                                        () => {}
                                      )
                                    : this.state.reqType === 'bank'
                                    ? this.setState(
                                        {
                                          bankHistoryReq: response.data.data,
                                          totalPageCount:
                                            response.data.paginationInfo
                                              .totalPage,
                                          pageNumber:
                                            response.data.paginationInfo
                                              .pageNumber,
                                        },
                                        () => {}
                                      )
                                    : this.state.reqType === 'address'
                                    ? this.setState(
                                        {
                                          addressHistoryReq: response.data.data,
                                          totalPageCount:
                                            response.data.paginationInfo
                                              .totalPage,
                                          pageNumber:
                                            response.data.paginationInfo
                                              .pageNumber,
                                        },
                                        () => {}
                                      )
                                    : this.state.reqType === 'bkash'
                                    ? this.setState(
                                        {
                                          bkashHistoryReq: response.data.data,
                                          totalPageCount:
                                            response.data.paginationInfo
                                              .totalPage,
                                          pageNumber:
                                            response.data.paginationInfo
                                              .pageNumber,
                                        },
                                        () => {}
                                      )
                                    : this.setState(
                                        {
                                          unbindHistoryReq: response.data.data,
                                          totalPageCount:
                                            response.data.paginationInfo
                                              .totalPage,
                                          pageNumber:
                                            response.data.paginationInfo
                                              .pageNumber,
                                        },
                                        () => {}
                                      );
                                
                                if (response) {
                                  this.setState({ searchLoading: false });
                                }
                              }}
                            >
                              {this.state.searchLoading
                                ? 'Searching'
                                : 'Search'}
                            </Button>
                            <Button
                              onClick={async () => {
                                // eslint-disable-next-line
                                this.state.searchKey = '';
                                this.setState({ clearLoading: true });
                                const token = getToken();
                                const response = await GKApi.get(
                                  `/users/change-request-history?type=${this.state.reqType}&pageNumber=1&pageSize=10`,
                                  {
                                    headers: {
                                      Authorization: token,
                                      cmpId: 47,
                                    },
                                  }
                                );
                                
                                  this.state.reqType === 'profile'
                                    ? this.setState(
                                        {
                                          profileHistoryReq: response.data.data,
                                          totalPageCount:
                                            response.data.paginationInfo
                                              .totalPage,
                                          pageNumber:
                                            response.data.paginationInfo
                                              .pageNumber,
                                        },
                                        () => {}
                                      )
                                    : this.state.reqType === 'bank'
                                    ? this.setState(
                                        {
                                          bankHistoryReq: response.data.data,
                                          totalPageCount:
                                            response.data.paginationInfo
                                              .totalPage,
                                          pageNumber:
                                            response.data.paginationInfo
                                              .pageNumber,
                                        },
                                        () => {}
                                      )
                                    : this.state.reqType === 'address'
                                    ? this.setState(
                                        {
                                          addressHistoryReq: response.data.data,
                                          totalPageCount:
                                            response.data.paginationInfo
                                              .totalPage,
                                          pageNumber:
                                            response.data.paginationInfo
                                              .pageNumber,
                                        },
                                        () => {}
                                      )
                                    : this.state.reqType === 'bkash'
                                    ? this.setState(
                                        {
                                          bkashHistoryReq: response.data.data,
                                          totalPageCount:
                                            response.data.paginationInfo
                                              .totalPage,
                                          pageNumber:
                                            response.data.paginationInfo
                                              .pageNumber,
                                        },
                                        () => {}
                                      )
                                    : this.setState(
                                        {
                                          unbindHistoryReq: response.data.data,
                                          totalPageCount:
                                            response.data.paginationInfo
                                              .totalPage,
                                          pageNumber:
                                            response.data.paginationInfo
                                              .pageNumber,
                                        },
                                        () => {}
                                      );
                                
                                if (response) {
                                  this.setState({
                                    clearLoading: false,
                                    searchKey: '',
                                    searchStartDate: null,
                                    searchEndDate: null,
                                  });
                                }
                              }}
                            >
                              {this.state.clearLoading ? 'Clearing' : 'Clear'}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ),
              }}
              data={
                this.state.reqType === 'profile'
                  ? this.state.profileHistoryReq
                  : this.state.reqType === 'address'
                  ? this.state.addressHistoryReq
                  : this.state.reqType === 'bank'
                  ? this.state.bankHistoryReq
                  : this.state.reqType === 'bkash'
                  ? this.state.bkashHistoryReq
                  : this.state.unbindHistoryReq
              }
              actions={[
                {
                  icon: () => <Button>Export All</Button>, // you can pass icon too
                  tooltip: 'Export to Excel',
                  onClick: () => downloadExcel(),
                  isFreeAction: true,
                },
              ]}
              options={{
                cellStyle: {
                  borderRight: '1px solid #eee',
                  textAlign: 'left',
                },

                //style for the header
                search: false,
                paging: false,
                headerStyle: {
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                  top: 0,
                },
                actionsColumnIndex: -1,
                exportFileName: 'CRM Change Requests',
                pageSize: 10,
                columnsButton: false,
              }}
              detailPanel={(rowData) => {
                return (
                  <Card>
                    {this.state.reqType === 'profile' ? (
                      <CardBody>
                        <Row>
                          <Col md={4}>
                            <p className="fw-bold">Attributes</p>
                          </Col>
                          <Col md={4}>
                            <p className="fw-bold">Existing</p>
                          </Col>
                          <Col md={4}>
                            <p className="fw-bold">Requested</p>
                          </Col>
                          {rowData.updatedName && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Name</p>
                              </Col>

                              <Col md={4}>
                                <p>{rowData.preData.currentName}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedName}</p>
                              </Col>
                            </Row>
                          )}
                          {rowData.updatedEmail && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Email</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentEmail}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedEmail}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedDOB && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">DOB</p>
                              </Col>
                              <Col md={4}>
                                <p>
                                  {moment(rowData.preData.currentDOB).format(
                                    'YYYY-MM-DD'
                                  )}
                                </p>
                              </Col>
                              <Col md={4}>
                                <p>
                                  {moment(rowData.updatedDOB).format(
                                    'YYYY-MM-DD'
                                  )}
                                </p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedGender && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Gender</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentGender}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedGender}</p>
                              </Col>
                            </Row>
                          )}
                        </Row>
                        <Row>
                          {rowData.updatedMaritalStatus && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Marital Status</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentMaritalStatus}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedMaritalStatus}</p>
                              </Col>
                            </Row>
                          )}
                          {rowData.updatedProfession && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Profession</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentProfession}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedProfession}</p>
                              </Col>
                            </Row>
                          )}
                          {rowData.updatedNID && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">NID</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentNID}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedNID}</p>
                              </Col>
                            </Row>
                          )}
                          {(rowData.updatedNIDFront ||
                            rowData.updatedNIDBack) && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">NID photos</p>
                              </Col>
                              <Col md={4}>
                                <Button
                                  onClick={async () => {
                                    //---NID Front Presigned url call---//

                                    if (rowData.updatedNIDFront !== null) {
                                      //NID Updated front
                                      //------
                                      const nidUpdatedFront = await GKApi.get(
                                        `/users/presigned-url?type=get&key=${rowData.updatedNIDFront}`,

                                        {
                                          headers: {
                                            Authorization: getToken(),
                                          },
                                        }
                                      );
                                      axios.get(nidUpdatedFront.data.url);

                                      // NID Current Front
                                      //------
                                      const nidCurrentFront = await GKApi.get(
                                        `/users/presigned-url?type=get&key=${rowData.preData.currentNIDFront}`,

                                        {
                                          headers: {
                                            Authorization: getToken(),
                                          },
                                        }
                                      );

                                      axios.get(nidCurrentFront.data.url);

                                      this.setState({
                                        ...this.state,
                                        nidFrontPicCurrent:
                                          nidCurrentFront.data.url,
                                        nidFrontPicUpdated:
                                          nidUpdatedFront.data.url,
                                      });
                                    }

                                    //---NID Back Presigned url call---//

                                    if (rowData.updatedNIDBack !== null) {
                                      //NID Updated Back
                                      //--------
                                      const nidUpdatedBack = await GKApi.get(
                                        `/users/presigned-url?type=get&key=${rowData.updatedNIDBack}`,

                                        {
                                          headers: {
                                            Authorization: getToken(),
                                          },
                                        }
                                      );
                                      axios.get(nidUpdatedBack.data.url);

                                      //NID Current Back
                                      //--------

                                      const nidCurrentBack = await GKApi.get(
                                        `/users/presigned-url?type=get&key=${rowData.preData.currentNIDBack}`,

                                        {
                                          headers: {
                                            Authorization: getToken(),
                                          },
                                        }
                                      );
                                      axios.get(nidCurrentBack.data.url);

                                      this.setState({
                                        ...this.state,
                                        nidBackPicUpdated:
                                          nidUpdatedBack.data.url,
                                        nidBackPicCurrent:
                                          nidCurrentBack.data.url,
                                      });
                                    }

                                    this.tog_standard();
                                  }}
                                >
                                  View
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      </CardBody>
                    ) : this.state.reqType === 'bank' ? (
                      <CardBody>
                        <Row>
                          <Row>
                            <Col md={4}>
                              <p className="fw-bold">Attributes</p>
                            </Col>
                            <Col md={4}>
                              <p className="fw-bold">Existing</p>
                            </Col>
                            <Col md={4}>
                              <p className="fw-bold">Requested</p>
                            </Col>
                          </Row>
                          {rowData.updatedAccountName && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Account Name</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentAccountName}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedAccountName}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedAccountNo && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Account No</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentAccountNo}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedAccountNo}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedRoutingNo && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Routing No</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentRoutingNo}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedRoutingNo}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedBankName && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Bank Name</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentBankName}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedBankName}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedBranchName && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Branch Name</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentBranchName}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedBranchName}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedBankDistrict && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Bank District</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentBankDistrict}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedBankDistrict}</p>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      </CardBody>
                    ) : this.state.reqType === 'address' ? (
                      <CardBody>
                        <Row>
                          <Row>
                            <Col md={4}>
                              <p className="fw-bold">Attributes</p>
                            </Col>
                            <Col md={4}>
                              <p className="fw-bold">Existing</p>
                            </Col>
                            <Col md={4}>
                              <p className="fw-bold">Requested</p>
                            </Col>
                          </Row>
                          {rowData.updatedAddress && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Address</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentAddress}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedAddress}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedAdditionalDetails && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Additional Details</p>
                              </Col>
                              <Col md={4}>
                                <p>
                                  {rowData.preData.currentAdditionalDetails}
                                </p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedAdditionalDetails}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedDistrictName && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">District Name</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentDistrictName}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedDistrictName}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedThanaName && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Thana Name</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentThanaName}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedThanaName}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedPostalCode && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Postal Code</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentPostalCode}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedPostalCode}</p>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      </CardBody>
                    ) : this.state.reqType === 'bkash' ? (
                      <CardBody>
                        <Row>
                          <Row>
                            <Col md={4}>
                              <p className="fw-bold">Attributes</p>
                            </Col>
                            <Col md={4}>
                              <p className="fw-bold">Existing</p>
                            </Col>
                            <Col md={4}>
                              <p className="fw-bold">Requested</p>
                            </Col>
                          </Row>
                          {rowData.updatedBkashAccName && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Bkash Account Name</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentBkashAccName}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedBkashAccName}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.updatedBkashNumber && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Bkash Number</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.preData.currentBkashNumber}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.updatedBkashNumber}</p>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      </CardBody>
                    ) : this.state.reqType === 'unbind' ? (
                      <CardBody>
                        <Row>
                          <Row>
                            <Col md={4}>
                              <p className="fw-bold">Attributes</p>
                            </Col>
                            <Col md={4}>
                              <p className="fw-bold">Existing</p>
                            </Col>
                            <Col md={4}>
                              <p className="fw-bold">Requested</p>
                            </Col>
                          </Row>
                          {rowData.requestUserName && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">User Name</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.currentUserName}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.requestUserName}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.requestUserPhone && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Phone Number</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.currentUserPhone}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.requestUserPhone}</p>
                              </Col>
                            </Row>
                          )}

                          {rowData.requestUserDateOfBirth && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">Date of Birth</p>
                              </Col>
                              <Col md={4}>
                                <p>
                                  {moment(
                                    rowData.currentUserDateOfBirth
                                  ).format('YYYY-MM-DD')}
                                </p>
                              </Col>
                              <Col md={4}>
                                <p>
                                  {moment(
                                    rowData.requestUserDateOfBirth
                                  ).format('YYYY-MM-DD')}
                                </p>
                              </Col>
                            </Row>
                          )}

                          {rowData.requestUserNid && (
                            <Row>
                              <Col md={4}>
                                <p className="fw-bold">User NID</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.currentUserNid}</p>
                              </Col>
                              <Col md={4}>
                                <p>{rowData.requestUserNid}</p>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      </CardBody>
                    ) : (
                      ''
                    )}
                  </Card>
                );
              }}
              onRowClick={async (event, rowData, togglePanel) => {
                togglePanel();
              }}
              localization={{
                toolbar: {
                  exportCSVName: 'Export as CSV',
                },
              }}
            />
          </div>

          <Modal
            size="lg"
            isOpen={this.state.modal_standard}
            toggle={this.tog_standard}
          >
            <ModalHeader
              toggle={() => this.setState({ modal_standard: false })}
            >
              Updated NID Photos
            </ModalHeader>

            <ModalBody>
              <Col xl={12}>
                <Row className="mb-3 mx-2">
                  <Col md={6}>
                    <p className="fw-bold">Existing NID Front</p>
                    <img
                      src={
                        this.state.nidFrontPicCurrent
                          ? this.state.nidFrontPicCurrent
                          : 'Not available'
                      }
                      alt={
                        this.state.nidFrontPicCurrent
                          ? 'Loading'
                          : 'Not available'
                      }
                      style={{
                        height: 'auto',
                        maxHeight: 250,
                        width: 'auto',
                        maxWidth: 250,
                        // borderRadius: '25%',
                        overflow: 'hidden',
                        borderWidth: 3,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <p className="fw-bold">Requested NID Front</p>
                    <img
                      src={
                        this.state.nidFrontPicUpdated
                          ? this.state.nidFrontPicUpdated
                          : 'Not available'
                      }
                      alt={
                        this.state.nidFrontPicUpdated
                          ? 'Loading'
                          : 'Not available'
                      }
                      style={{
                        height: 'auto',
                        maxHeight: 250,
                        width: 'auto',
                        maxWidth: 250,
                        // borderRadius: '25%',
                        overflow: 'hidden',
                        borderWidth: 3,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-3 mx-2">
                  <Col md={6}>
                    <p className="fw-bold">Existing NID Back</p>
                    <img
                      src={
                        this.state.nidBackPicCurrent
                          ? this.state.nidBackPicCurrent
                          : 'Not available'
                      }
                      alt={
                        this.state.nidBackPicCurrent
                          ? 'Loading'
                          : 'Not available'
                      }
                      style={{
                        height: 'auto',
                        maxHeight: 250,
                        width: 'auto',
                        maxWidth: 250,
                        // borderRadius: '25%',
                        overflow: 'hidden',
                        borderWidth: 3,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <p className="fw-bold">Requested NID Back</p>
                    <img
                      src={
                        this.state.nidBackPicUpdated
                          ? this.state.nidBackPicUpdated
                          : 'Not available'
                      }
                      alt={
                        this.state.nidBackPicUpdated
                          ? 'Loading'
                          : 'Not available'
                      }
                      style={{
                        height: 'auto',
                        maxHeight: 250,
                        width: 'auto',
                        maxWidth: 250,
                        // borderRadius: '25%',
                        overflow: 'hidden',
                        borderWidth: 3,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </ModalBody>
          </Modal>

          <div className="my-3 d-flex justify-content-center gap-5">
            <Button
              onClick={async () => {
                let token = getToken();
                //get api call with needed parameters

                if (
                  (this.state.searchStartDate !== null &&
                    this.state.searchEndDate !== null) ||
                  this.state.searchKey !== ''
                ) {
                  let submitData = await GKApi.get(
                    `/users/change-request-history?type=${
                      this.state.reqType
                    }&pageNumber=${this.state.pageNumber - 1}&pageSize=10${
                      this.state.searchKey !== ''
                        ? `&phone=${this.state.searchKey}`
                        : ``
                    }${
                      this.state.searchStartDate !== null &&
                      this.state.searchEndDate !== null
                        ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                        : ''
                    }`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 47,
                      },
                    }
                  );
                  
                    this.state.reqType === 'profile'
                      ? this.setState(
                          {
                            profileHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'bank'
                      ? this.setState(
                          {
                            bankHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'address'
                      ? this.setState(
                          {
                            addressHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'bkash'
                      ? this.setState(
                          {
                            bkashHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.setState(
                          {
                            unbindHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        );
                  
                } else {
                  let submitData = await GKApi.get(
                    `/users/change-request-history?type=${
                      this.state.reqType
                    }&pageNumber=${this.state.pageNumber - 1}&pageSize=10`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 47,
                      },
                    }
                  );
                  
                    this.state.reqType === 'profile'
                      ? this.setState(
                          {
                            profileHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'bank'
                      ? this.setState(
                          {
                            bankHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'address'
                      ? this.setState(
                          {
                            addressHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'bkash'
                      ? this.setState(
                          {
                            bkashHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.setState(
                          {
                            unbindHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        );
                  
                }
              }}
              disabled={this.state.pageNumber === 1 ? true : false}
            >
              Previous page
            </Button>{' '}
            <Button
              onClick={async () => {
                let token = getToken();
                // this.setState({ isLoading: true });
                //get api call with needed parameters

                if (
                  (this.state.searchStartDate !== null &&
                    this.state.searchEndDate !== null) ||
                  this.state.searchKey !== ''
                ) {
                  let submitData = await GKApi.get(
                    `/users/change-request-history?type=${
                      this.state.reqType
                    }&pageNumber=${this.state.pageNumber + 1}&pageSize=10${
                      this.state.searchKey !== ''
                        ? `&phone=${this.state.searchKey}`
                        : ``
                    }${
                      this.state.searchStartDate !== null &&
                      this.state.searchEndDate !== null
                        ? `&startDate=${this.state.searchStartDate}&endDate=${this.state.searchEndDate}`
                        : ''
                    }`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 47,
                      },
                    }
                  );
                  if (submitData) {
                    this.setState({
                      isLoading: false,
                    });
                  }
                  
                    this.state.reqType === 'profile'
                      ? this.setState(
                          {
                            profileHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'bank'
                      ? this.setState(
                          {
                            bankHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'address'
                      ? this.setState(
                          {
                            addressHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'bkash'
                      ? this.setState(
                          {
                            bkashHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.setState(
                          {
                            unbindHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        );
                  
                } else {
                  let submitData = await GKApi.get(
                    `/users/change-request-history?type=${
                      this.state.reqType
                    }&pageNumber=${this.state.pageNumber + 1}&pageSize=10`,
                    {
                      headers: {
                        Authorization: token,
                        cmpId: 47,
                      },
                    }
                  );
                  if (submitData) {
                    this.setState({
                      isLoading: false,
                    });
                  }
                  
                    this.state.reqType === 'profile'
                      ? this.setState(
                          {
                            profileHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'bank'
                      ? this.setState(
                          {
                            bankHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'address'
                      ? this.setState(
                          {
                            addressHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.state.reqType === 'bkash'
                      ? this.setState(
                          {
                            bkashHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        )
                      : this.setState(
                          {
                            unbindHistoryReq: submitData.data.data,
                            totalPageCount:
                              submitData.data.paginationInfo.totalPage,
                            pageNumber:
                              submitData.data.paginationInfo.pageNumber,
                          },
                          () => {}
                        );
                  
                }
              }}
              disabled={this.state.pageNumber === this.state.totalPageCount}
            >
              {this.state.isLoading ? 'Loading Next Page' : 'Next Page'}
            </Button>
          </div>

          <div className="my-2 d-flex justify-content-center">
            <h5>
              Page {this.state.pageNumber} of {this.state.totalPageCount}
            </h5>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserRequestHistory;

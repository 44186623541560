import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Col,
  Button,
} from 'reactstrap';
// import Switch from '@mui/material/Switch';
import DatePicker from '../../../components/Common/DatePicker';
import {
  capitalizeFirstLetter,
  getToken,
  // getUser,
  GKApi,
} from '../../../components/config';
import { withRouter } from 'react-router-dom';


const UserTransaction = (props) => {

  const [transactionMenu, setTransactionMenu] = useState(false);
  const [transaction, setTransaction] = useState('buy');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);


  const handleSelectedStartDateChange = (event) => {
    setSelectedStartDate(event);
  };

  const handleSelectedEndDateChange = (event) => {
    setSelectedEndDate(event);
  };
  const onClick = async (event) => {
   
    event.preventDefault();
    const token = getToken();
    // if(selectedStartDate.isBefore(selectedEndDate)){

    let submitData = await GKApi.get(
      `/users/trx-details?userId=${props.uid}&type=${transaction}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`,
      {
        headers: {
          Authorization: token,
          cmpId:46,
        },
      }
    );
    props.history.push({
      pathname: `/${transaction}-transaction-details`,
      state: submitData.data,
    });
    // } else{

    // }/
  };
  return (
    <>
      <div className="d-grid gap-3">
        <div>
          <div className="d-flex align-items-center">
            <Col xs={4}>
              <h5>Select Transaction Type</h5>
            </Col>
            <Col xs={8}>
              <Dropdown
                isOpen={transactionMenu}
                toggle={(event) => setTransactionMenu(!transactionMenu)}
              >
                <DropdownToggle className="px-5 py-2" color="primary">
                  {capitalizeFirstLetter(transaction)}
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(event) => {
                      setTransaction('buy');
                    }}
                  >
                    Buy Transaction details
                  </DropdownItem>

                  <DropdownItem onClick={(event) => setTransaction('sell')}>
                    Sell Transaction details
                  </DropdownItem>

                  <DropdownItem onClick={(event) => setTransaction('gift')}>
                    Gift Transaction details
                  </DropdownItem>

                  <DropdownItem onClick={(event) => setTransaction('collect')}>
                    Collect Transaction details
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Col xs={4}>
            {transaction ? (
              <h5>
                Date range for {capitalizeFirstLetter(transaction)} details
              </h5>
            ) : (
              ''
            )}
          </Col>
          <Col xs={8} className="d-flex gap-3">
            <div
              style={{
                border: '1px solid',
                borderRadius: '5px',
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingRight: '4px',
                paddingLeft: '4px',
              }}
              className="d-flex gap-3 align-content-center"
            >
              <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                Start Date
              </p>
              <DatePicker
                selectedDate={selectedStartDate}
                handleSelectedDateChange={handleSelectedStartDateChange}
              />
            </div>
            <div
              style={{
                border: '1px solid',
                borderRadius: '5px',
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingRight: '5px',
                paddingLeft: '5px',
              }}
              className="d-flex d-grid gap-3"
            >
              <p className="mt-1" style={{ whiteSpace: 'nowrap' }}>
                End Date
              </p>
              <DatePicker
                selectedDate={selectedEndDate}
                handleSelectedDateChange={handleSelectedEndDateChange}
                className="mx-3"
              />
            </div>
          </Col>
        </div>

        <div className="d-flex justify-content-center">
          {selectedStartDate && selectedEndDate ? (
            <Button
              onClick={onClick}
              style={{
                width: '50%',
              }}
            >
              View Details
            </Button>
          ) : (
            'Please choose the date parameter to view details'
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(UserTransaction);
